import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { FC } from "react";
import { SelectInput, useRecordContext, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { toFixedNumber } from "../../../utils/helpers";
import AroggaDialogActions from "../../AroggaDialogActions";
import FormatedBooleanInput from "../../FormatedBooleanInput";
import QtyLabel from "../../QtyLabel";

type OrderDialogProps = {
    open: boolean;
    checkedInfo?: {
        enableExchange?: boolean;
        enableMissing?: boolean;
        enableRefund?: boolean;
        enableReplacement?: boolean;
        enableReturned?: boolean;
    };
    items: any[];
    selectedItems: object[];
    buttonLabel: () => string;
    handleDialogClose: () => void;
};

const OrderDialog: FC<OrderDialogProps> = ({
    open,
    checkedInfo,
    items,
    selectedItems,
    buttonLabel,
    handleDialogClose,
}) => {
    const redirect = useRedirect();
    const record = useRecordContext();
    const { values } = useFormState();

    let filteredItems: object[];
    let replacementItems: object[];
    let exchangeItems: object[];
    let totalAmount: number = 0;

    if (checkedInfo?.enableReturned || checkedInfo?.enableMissing) {
        filteredItems =
            !!items?.length &&
            items.map(({ m_id, m_multiplier, qty, missing_qty }) => ({
                m_id,
                m_multiplier,
                m_qty: qty,
                return_qty:
                    checkedInfo?.enableReturned && selectedItems?.includes(m_id)
                        ? qty
                        : 0,
                missing_qty:
                    checkedInfo?.enableMissing && missing_qty ? missing_qty : 0,
            }));
    }

    if (checkedInfo?.enableRefund) {
        filteredItems =
            !!items?.length &&
            items.map(
                ({ m_id, m_multiplier, qty, refund_qty, damage_qty }) => ({
                    m_id,
                    m_multiplier,
                    m_qty: qty,
                    refund_qty,
                    damage_qty,
                })
            );
    }

    if (checkedInfo?.enableRefund) {
        !!items?.length &&
            items.forEach((item) => {
                totalAmount +=
                    item.m_d_price * (item.refund_qty + item.damage_qty);
            });
    }

    if (checkedInfo?.enableReplacement) {
        replacementItems =
            !!items?.length &&
            items.map(
                ({
                    m_id,
                    m_multiplier,
                    qty,
                    give_qty,
                    selectedMedicine,
                    take_qty,
                }) => ({
                    m_id,
                    m_multiplier,
                    m_qty: qty,
                    missing_qty: give_qty,
                    replace_id: selectedMedicine?.id,
                    replace_qty: take_qty,
                })
            );
    }

    if (checkedInfo?.enableExchange) {
        exchangeItems =
            !!items?.length &&
            items.map(
                ({
                    m_id,
                    m_multiplier,
                    give_qty,
                    selectedMedicine,
                    take_qty,
                }) => ({
                    m_id,
                    m_multiplier,
                    m_qty: take_qty,
                    exchange_id: selectedMedicine?.id,
                    exchange_qty: give_qty,
                })
            );
    }

    const postBody = () => {
        if (checkedInfo?.enableReturned) return { medicineQty: filteredItems };

        if (checkedInfo?.enableMissing)
            return {
                returnType: values.returnType,
                medicineQty: filteredItems,
            };

        if (checkedInfo?.enableRefund)
            return {
                medicineQty: filteredItems,
                total: totalAmount,
                isCalculate_additions: values.isCalculate_additions,
            };

        if (checkedInfo?.enableReplacement)
            return {
                medicineQty: replacementItems,
            };

        if (checkedInfo?.enableExchange)
            return {
                medicineQty: exchangeItems,
            };
    };

    const { isLoading, refetch } = useRequest(
        `/v1/orders/${
            checkedInfo?.enableRefund ? "refundItems" : "returnItems"
        }/${record?.o_id}/`,
        {
            method: "POST",
            body: postBody(),
        },
        {
            isRefresh: true,
            onSuccess: () => {
                handleDialogClose();
                if (checkedInfo?.enableRefund) {
                    redirect(`/v1/orders/${record?.o_id}/4`);
                } else {
                    redirect(`/v1/orders/${record?.o_id}/3`);
                }
            },
        }
    );

    const dTitle = () => {
        if (checkedInfo?.enableReturned)
            return "Are you sure you want to initiate return?";

        if (checkedInfo?.enableMissing)
            return "Are you sure you want to initiate missing?";

        if (checkedInfo?.enableRefund)
            return "Are you sure you want to refund?";

        if (checkedInfo?.enableReplacement)
            return "Are you sure you want to replace?";

        if (checkedInfo?.enableExchange)
            return "Are you sure you want to exchange?";
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>{dTitle()}</DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sl No</TableCell>
                                <TableCell>Item</TableCell>
                                {checkedInfo?.enableReturned && (
                                    <TableCell>Return Qty</TableCell>
                                )}
                                {checkedInfo?.enableMissing && (
                                    <TableCell>Missing Qty</TableCell>
                                )}
                                {checkedInfo?.enableRefund && (
                                    <>
                                        <TableCell>Refund Qty</TableCell>
                                        <TableCell>Damage Qty</TableCell>
                                    </>
                                )}
                                {checkedInfo?.enableReplacement && (
                                    <>
                                        <TableCell>দিবেন Qty</TableCell>
                                        <TableCell>আনবেন Item</TableCell>
                                        <TableCell>আনবেন Qty</TableCell>
                                    </>
                                )}
                                {checkedInfo?.enableExchange && (
                                    <>
                                        <TableCell>আনবেন Qty</TableCell>
                                        <TableCell>দিবেন Item</TableCell>
                                        <TableCell>দিবেন Qty</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!items?.length &&
                                items.map((item, i) => {
                                    if (!item) return null;

                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>
                                                {item.m_name}{" "}
                                                {item?.m_strength
                                                    ? `(${item.m_strength})`
                                                    : ""}
                                            </TableCell>
                                            {checkedInfo?.enableReturned && (
                                                <TableCell>
                                                    {selectedItems?.includes(
                                                        item.m_id
                                                    ) && (
                                                        <QtyLabel
                                                            qty={item.qty}
                                                            medicine={item}
                                                        />
                                                    )}
                                                </TableCell>
                                            )}
                                            {checkedInfo?.enableMissing && (
                                                <TableCell>
                                                    <QtyLabel
                                                        qty={item.missing_qty}
                                                        medicine={item}
                                                    />
                                                </TableCell>
                                            )}
                                            {checkedInfo?.enableRefund && (
                                                <>
                                                    <TableCell>
                                                        <QtyLabel
                                                            qty={
                                                                item.refund_qty
                                                            }
                                                            medicine={item}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <QtyLabel
                                                            qty={
                                                                item.damage_qty
                                                            }
                                                            medicine={item}
                                                        />
                                                    </TableCell>
                                                </>
                                            )}
                                            {checkedInfo?.enableReplacement && (
                                                <>
                                                    <TableCell>
                                                        <QtyLabel
                                                            qty={item.qty}
                                                            medicine={item}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            item
                                                                .selectedMedicine
                                                                ?.name
                                                        }{" "}
                                                        {item?.selectedMedicine
                                                            ?.m_strength
                                                            ? `(${item.selectedMedicine.m_strength})`
                                                            : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        <QtyLabel
                                                            qty={item?.take_qty}
                                                            medicine={
                                                                item?.selectedMedicine
                                                            }
                                                        />
                                                    </TableCell>
                                                </>
                                            )}
                                            {checkedInfo?.enableExchange && (
                                                <>
                                                    <TableCell>
                                                        <QtyLabel
                                                            qty={item.qty}
                                                            medicine={item}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            item
                                                                .selectedMedicine
                                                                ?.name
                                                        }{" "}
                                                        {item?.selectedMedicine
                                                            ?.m_strength
                                                            ? `(${item.selectedMedicine.m_strength})`
                                                            : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        <QtyLabel
                                                            qty={item?.give_qty}
                                                            medicine={
                                                                item?.selectedMedicine
                                                            }
                                                        />
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {checkedInfo?.enableMissing && (
                    <Box mt={2}>
                        <SelectInput
                            source="returnType"
                            label="কী নিবেন?"
                            variant="outlined"
                            choices={[
                                { id: "money", name: "টাকা" },
                                { id: "medicine", name: "ঔষুধ" },
                            ]}
                        />
                    </Box>
                )}
                {checkedInfo?.enableRefund && (
                    <Box mt={2}>
                        <p>Total Amount: {toFixedNumber(totalAmount)}</p>
                        <FormatedBooleanInput
                            source="isCalculate_additions"
                            label="ডেলিভারি ফি এবং ক্যাশব্যাক বাদ দিয়ে রিফান্ড হবে"
                        />
                    </Box>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                confirmLabel={buttonLabel()}
                disabled={checkedInfo?.enableMissing && !values.returnType}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default OrderDialog;
