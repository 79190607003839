import { makeStyles } from "@material-ui/core";
import { useFormState } from "react-final-form";

import { isInfinity, toFixedNumber } from "../../../utils/helpers";
import {
    discountBasedOnIsDiscountFixed,
    mrpProfitPercent,
    sumOfIndividualPiiDiscountForPiiIsFixedDiscount,
    sumOfIndividualPiiTpPriceForPiiIsFixedDiscount,
} from "./utils";

const MrpProfitField = ({ tpPrice, selectedMedicine }) => {
    const classes = useStyles();
    const { values } = useFormState();

    const piiDiscount = toFixedNumber(
        discountBasedOnIsDiscountFixed({
            tpPrice: tpPrice,
            totalTp: values.pi_tp_price,
            sumOfIndividualPiiTpPriceForPiiIsFixedDiscount:
                sumOfIndividualPiiTpPriceForPiiIsFixedDiscount(values.items),
            totalDiscount:
                values.pi_discount -
                sumOfIndividualPiiDiscountForPiiIsFixedDiscount(values.items),
            pi_round: values.pi_round || 0,
        }),
        4
    );

    const mrpPercent = mrpProfitPercent({
        isMrpPercent: true,
        pu_qty: values.pu_qty,
        m_price: selectedMedicine.m_price,
        pii_tp_price: tpPrice,
        pii_vat: values.pii_vat,
        pii_discount: piiDiscount,
    });

    const profitPercent = mrpProfitPercent({
        isMrpPercent: false,
        pu_qty: values.pu_qty,
        m_d_price: selectedMedicine.m_d_price,
        pii_tp_price: tpPrice,
        pii_vat: values.pii_vat,
        pii_discount: piiDiscount,
    });

    return (
        <>
            {!isInfinity(mrpPercent) && (
                <div
                    className={
                        mrpPercent < 0 || mrpPercent > 50
                            ? classes.fontRed
                            : classes.fontGreen
                    }
                >
                    MRP: {mrpPercent}%
                </div>
            )}
            {!isInfinity(profitPercent) && (
                <div
                    className={
                        profitPercent < 0 || profitPercent > 50
                            ? classes.fontRed
                            : classes.fontGreen
                    }
                >
                    Profit: {profitPercent}%
                </div>
            )}
        </>
    );
};

const useStyles = makeStyles({
    fontRed: {
        color: "#EF1962",
    },
    fontGreen: {
        color: "green",
    },
});

export default MrpProfitField;
