import useRequest from "./useRequest";

const useGetTaxonomiesByVocabulary = (fetchKey: string) => {
    const { data } = useRequest(
        `/v1/taxonomiesByVocabulary/${fetchKey}`,
        {},
        { isPreFetching: true }
    );
    return data;
};

export default useGetTaxonomiesByVocabulary;
