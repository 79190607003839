import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    TextField,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Phone from "@material-ui/icons/Phone";
import { stringify } from "query-string";
import { useState } from "react";
import { Notification, useLogin, useNotify } from "react-admin";

import { useDocumentTitle } from "../hooks";
import { Status } from "../utils/enums";
import { logger } from "../utils/helpers";

const LoginPage = () => {
    useDocumentTitle("Arogga");

    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();

    const [isLoading, setIsLoading] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const mobile = e.target.mobile.value;

        if (!/(^(\+8801|8801|008801))(\d){9}$/.test(mobile)) {
            return notify("Invalid mobile number!", { type: "error" });
        }

        setIsLoading(true);

        if (isVerifying) {
            const otp = e.target.otp.value;

            login({ mobile, otp })
                .then(() =>
                    notify("Successfully signed in!", {
                        type: "success",
                    })
                )
                .catch((err) => notify(err.message, { type: "error" }))
                .finally(() => setIsLoading(false));
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/v1/auth/sms/send/`, {
                method: "POST",
                body: stringify({ mobile }),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.status === Status.SUCCESS) {
                        setIsVerifying(true);
                        notify(res.message, { type: "success" });
                    } else {
                        notify(res.message, { type: "error" });
                    }

                    return res;
                })
                .catch((err) => logger(err))
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop={10}
        >
            <Avatar className={classes.avatarBgColor}>
                <LockOutlinedIcon />
            </Avatar>
            <Box mt={1} />
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} mt={3}>
                <TextField
                    label="Mobile No."
                    id="mobile"
                    type="text"
                    defaultValue="+88"
                    variant="outlined"
                    placeholder="+88***********"
                    disabled={isVerifying || isLoading}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Phone />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
                {isVerifying && (
                    <Box mt={3}>
                        <TextField
                            label="OTP"
                            id="otp"
                            type="number"
                            variant="outlined"
                            placeholder="******"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Fingerprint />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                    </Box>
                )}
                <Box sx={{ mt: 3 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "#008069" }}
                        disabled={isLoading}
                        fullWidth
                    >
                        {!isLoading && !isVerifying && "Next"}
                        {!isLoading && isVerifying && "Sign in"}
                        {isLoading && (
                            <CircularProgress
                                size={25}
                                thickness={2}
                                style={{ color: "white" }}
                            />
                        )}
                    </Button>
                </Box>
            </Box>
            <Notification />
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    avatarBgColor: {
        backgroundColor: "#008069",
    },
}));

export default LoginPage;
