import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Link, MenuItemLink, usePermissions } from "react-admin";

import aroggaWhiteLogo from "../assets/images/logo-white.png";
import MyUserMenu from "./UserMenu";

const MyAppBar = (props) => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    return (
        <AppBar {...props} userMenu={<MyUserMenu />} elevation={1}>
            <Toolbar>
                <Link to="/">
                    <img
                        src={aroggaWhiteLogo}
                        alt="logo"
                        className={classes.logo}
                    />
                </Link>
            </Toolbar>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Toolbar>
                {permissions?.includes("orderMenu") && (
                    <MenuItemLink
                        to="/v1/orders"
                        primaryText="Orders"
                        className={classes.menuItemLink}
                        // @ts-ignore
                        classes={{ active: classes.active }}
                    />
                )}
                {permissions?.includes("purchaseInvoiceMenu") && (
                    <MenuItemLink
                        to="/v1/purchaseInvoice"
                        primaryText="Purchase Invoices"
                        className={classes.menuItemLink}
                        // @ts-ignore
                        classes={{ active: classes.active }}
                    />
                )}
                {permissions?.includes("inventoryMenu") && (
                    <MenuItemLink
                        to="/v1/inventory"
                        primaryText="Inventories"
                        className={classes.menuItemLink}
                        // @ts-ignore
                        classes={{ active: classes.active }}
                    />
                )}
            </Toolbar>
            <span className={classes.spacer} />
        </AppBar>
    );
};

const useStyles = makeStyles({
    logo: {
        marginLeft: -25,
    },
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginLeft: -10,
    },
    menuItemLink: {
        border: "none",
        color: "#FFFFFF",
    },
    active: {
        color: "rgba(0, 0, 0, 0.54)",
        backgroundColor: "rgb(230, 252, 246)",
        borderRadius: "6px",
    },
    spacer: {
        flex: 1,
    },
});

export default MyAppBar;
