import { Box } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    Edit,
    EditProps,
    SimpleForm,
    useDataProvider,
    useEditController,
    useNotify,
    useRedirect,
} from "react-admin";

import AmountMismatchedAlert from "../../../components/managePurchase/purchaseInvoice/AmountMismatchedAlert";
import InvoiceForm from "../../../components/managePurchase/purchaseInvoice/InvoiceForm";
import PurchaseInvoiceSync from "../../../components/managePurchase/purchaseInvoice/PurchaseInvoiceSync";
import SummaryTable from "../../../components/managePurchase/purchaseInvoice/SummaryTable";
import InvoiceTable from "../../../components/managePurchase/purchaseInvoice/invoiceTable";
import { useDocumentTitle, useGetCurrentUser } from "../../../hooks";
import PurchaseInvoiceToolbar from "./PurchaseInvoiceToolbar";

const PurchaseInvoiceEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Purchase Invoice Edit");

    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const { record } = useEditController(rest);
    const currentUser = useGetCurrentUser();

    const [isKeyboardPressed, setIsKeyboardPressed] = useState(false);
    const [tpPrice, setTpPrice] = useState("");
    const [piiVat, setPiiVat] = useState("");
    const [allItems, setAllItems] = useState([]);

    useEffect(() => {
        if (isKeyboardPressed) return;

        document.addEventListener("keydown", () => setIsKeyboardPressed(true));

        return () =>
            document.removeEventListener("keydown", () =>
                setIsKeyboardPressed(false)
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSave = async ({
        pi_discount = 0,
        pi_round = 0,
        items,
        ...rest
    }) => {
        dataProvider
            // @ts-ignore
            .update("v1/purchaseInvoice", {
                id: rest.pi_id,
                data: {
                    ...rest,
                    pi_discount,
                    pi_round,
                    items: JSON.stringify(items),
                },
            })
            .then(() => {
                notify("Successfully updated!", { type: "success" });
                redirect("list", "/v1/purchaseInvoice");
            })
            .catch((err) =>
                notify(
                    err?.message || "Something went wrong, Please try again!",
                    {
                        type: "warning",
                    }
                )
            );
    };

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                toolbar={<PurchaseInvoiceToolbar />}
                save={onSave}
                submitOnEnter={false}
            >
                <InvoiceForm
                    page="edit"
                    tpPrice={tpPrice}
                    setTpPrice={setTpPrice}
                    piiVat={piiVat}
                    setPiiVat={setPiiVat}
                    allItems={allItems}
                    setAllItems={setAllItems}
                />
                {!!allItems?.length && (
                    <>
                        <InvoiceTable
                            isKeyboardPressed={isKeyboardPressed}
                            setIsKeyboardPressed={setIsKeyboardPressed}
                            allItems={allItems}
                            setAllItems={setAllItems}
                        />
                        <Box mt={4} mb={2}>
                            <SummaryTable />
                        </Box>
                        {!isKeyboardPressed &&
                            permissions?.includes("purchaseInvoiceEdit") &&
                            currentUser.u_id !== record?.pi_created_by &&
                            record?.pi_status !== "sync" && (
                                <Box display="flex" justifyContent="center">
                                    <PurchaseInvoiceSync
                                        purchaseInvoiceId={rest.id}
                                    />
                                </Box>
                            )}
                        <AmountMismatchedAlert />
                    </>
                )}
            </SimpleForm>
        </Edit>
    );
};

export default PurchaseInvoiceEdit;
