import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { FC } from "react";
import { ImageField } from "react-admin";

const B2BTab: FC = () => (
    <>
        <MovableImageInput
            label="Carousel For App (750x300 px)"
            source="attachedFilesAppB2b"
            accept="image/*"
            maxSize={5000000}
            multiple
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Carousel For Web (1405x450 px)"
            source="attachedFilesWebB2b"
            accept="image/*"
            maxSize={5000000}
            multiple
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Homepage Banner (1000x1000 px)"
            source="attachedFilesHomepageBannerB2b"
            accept="image/*"
            maxSize={5000000}
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Under Product Banner (750x300 px)"
            source="attachedFilesUnderProductBannerB2b"
            accept="image/*"
            maxSize={5000000}
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Homepage Refer Banner (750x300 px)"
            source="attachedFilesHomepageReferB2b"
            accept="image/*"
            maxSize={5000000}
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
    </>
);

export default B2BTab;
