import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import LocationInput from "../../../components/LocationInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";

const PharmacyFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "approved", name: "Approved" },
                { id: "rejected", name: "Rejected" },
                { id: "blocked", name: "Blocked" },
            ]}
            alwaysOn
        />
        <TextInput source="_name" label="Name" variant="outlined" resettable />
        <ReferenceInput
            source="_user_id"
            label="User"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={(record: { u_name: string; u_mobile: string }) =>
                    !!record ? `${record.u_name} (${record.u_mobile})` : ""
                }
                resettable
            />
        </ReferenceInput>
        <LocationInput
            source="_location_id"
            label="Location"
            variant="outlined"
        />
        <NumberInput
            source="_trade_license_no"
            label="Trade License No"
            variant="outlined"
            resettable
        />
        <NumberInput
            source="_drug_license_no"
            label="Drug License No"
            variant="outlined"
            resettable
        />
    </Filter>
);

export default PharmacyFilter;
