import { FC } from "react";
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import GenericFilter from "./GenericFilter";

const GenericList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Generic List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Generic"
            filters={<GenericFilter children={""} />}
            perPage={25}
            sort={{ field: "g_id", order: "DESC" }}
            bulkActionButtons={false}
            exporter={exporter}
        >
            <Datagrid rowClick="show">
                <TextField source="g_id" label="Id" />
                <TextField source="g_name" label="Name" />
                <BooleanField
                    source="g_is_antibiotics"
                    label="Antibiotics"
                    looseValue
                    FalseIcon={() => null}
                />
                <BooleanField
                    source="g_is_controlled"
                    label="Controlled"
                    looseValue
                    FalseIcon={() => null}
                />
                <TextField source="g_status" label="Status" />
                <TextField source="g_approval_status" label="Approval Status" />
            </Datagrid>
        </List>
    );
};

export default GenericList;
