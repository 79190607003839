import { FileField, FileInput, NumberInput } from "react-admin";

const RedxTab = () => (
    <>
        <NumberInput source="b_redx_qty" label="Quantity" variant="outlined" />
        <FileInput
            source="attachedFiles-redx"
            label="Related files"
            accept="image/*, application/pdf"
            maxSize={5000000}
            multiple
        >
            <FileField source="src" title="title" />
        </FileInput>
    </>
);

export default RedxTab;
