import {
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { useCallback, useRef, useState } from "react";
import { useNotify, useRedirect } from "react-admin";

import { DokaModal } from "../../../lib/arogga-image-editor";
import {
    getBase64ImageFromUrl,
    getPictureUrlFromCamera,
} from "../../../services/camera";
import { convertFileToBase64 } from "../../../utils/helpers";

const WebcamModal = ({ callback }) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const webcamRef = useRef(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEnableDoka, setIsEnableDoka] = useState(false);
    const [isDokaConfirm, setIsDokaConfirm] = useState(false);
    const [cameraImageBase64, setCameraImageBase64] = useState(null);
    const [dokaImage, setDokaImage] = useState<any>({
        src: null,
        title: null,
    });

    const handleOpenCamera = () => {
        const cameraBaseUrl = localStorage.getItem("camera-base-url");

        if (!cameraBaseUrl) {
            notify("You must be set Camera Base URL for opening camera!", {
                type: "warning",
            });
            return redirect("/site-settings");
        }
        setIsDialogOpen(true);
    };

    const handleCapture = useCallback(async () => {
        const pictureUrlFromCamera = await getPictureUrlFromCamera();

        const proxyImageUrl = pictureUrlFromCamera.replace(
            "http://192.168.122.1:60152",
            "/sony-image"
        );

        const base64Image = await getBase64ImageFromUrl(proxyImageUrl);
        setCameraImageBase64(base64Image);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webcamRef]);

    const handleUse = () => setIsEnableDoka(true);

    const handleDokaCancel = () => {
        setDokaImage(null);
        setCameraImageBase64(null);
        setIsEnableDoka(false);
    };

    const handleDokaConfirm = async (output) => {
        const dokaImage = await convertFileToBase64({
            rawFile: output.file,
            title: "WebcamImage-" + Math.random().toString(36).substring(2, 15),
        });
        setDokaImage(dokaImage);
        setCameraImageBase64(null);
        setIsDokaConfirm(true);
        setIsEnableDoka(false);
    };

    const handleConfirm = () => {
        callback(dokaImage);
        setDokaImage(null);
        setCameraImageBase64(null);
        setIsDokaConfirm(false);
        setIsDialogOpen(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenCamera}
            >
                Open Camera
            </Button>
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                maxWidth="lg"
            >
                <DialogTitle>Capture Image</DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    {cameraImageBase64 && (
                        <Card>
                            <CardMedia
                                component="img"
                                alt="RealImage"
                                height={400}
                                image={cameraImageBase64}
                                title="Real Image"
                            />
                        </Card>
                    )}
                    {dokaImage?.src && (
                        <Card>
                            <CardMedia
                                component="img"
                                alt="DokaImage"
                                height={400}
                                image={dokaImage.src}
                                title="Doka Image"
                            />
                        </Card>
                    )}
                    {isEnableDoka && (
                        <DokaModal
                            utils={["crop", "filter", "color"]}
                            src={cameraImageBase64}
                            cropAspectRatio={1}
                            cropMinImageWidth={1000}
                            cropMinImageHeight={1000}
                            onConfirm={handleDokaConfirm}
                            onCancel={handleDokaCancel}
                            cropShowSize
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            setIsDialogOpen(false);
                            setIsDokaConfirm(false);
                            setCameraImageBase64(null);
                            setDokaImage(null);
                        }}
                        style={{
                            border: "1px solid #6C757D",
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#6C757D",
                        }}
                    >
                        Cancel
                    </Button>
                    {!isDokaConfirm && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleCapture}
                            style={{
                                fontWeight: 600,
                                fontSize: 16,
                            }}
                        >
                            {cameraImageBase64 ? "Re Capture" : "Capture"}
                        </Button>
                    )}
                    {(cameraImageBase64 || dokaImage?.src) && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={dokaImage?.src ? handleConfirm : handleUse}
                            style={{
                                fontWeight: 600,
                                fontSize: 16,
                            }}
                        >
                            {dokaImage?.src ? "Confirm" : "Use"}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WebcamModal;
