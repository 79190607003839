import { makeStyles } from "@material-ui/core";
import { CSSProperties, FC, useState } from "react";

type AroggaButtonProps = {
    label: string;
    type?: "primary" | "secondary" | "success" | "danger";
    disabled?: boolean;
    style?: CSSProperties;
    onClick: any;
};

const ButtonType = {
    primary: {
        background: "#A7B1C4",
        border: "1px solid #8F98A8",
        color: "#FFFFFF",
    },
    secondary: {
        background: "#F2F2F2",
        border: "1px solid #BDBDBD",
        color: "#4F4F4F",
    },
    success: {
        background: "#DCFAF2",
        color: "#008069",
        border: "1px solid #3ECBA5",
    },
    danger: {
        background: "#FFDEE9",
        color: "#EF1962",
        border: "1px solid #EF1962",
    },
};

const ButtonHover = {
    primary: {
        background: "#8F98A8",
        border: "1px solid #8F98A8",
        color: "#FFFFFF",
    },
    secondary: {
        background: "#BDBDBD",
        border: "1px solid #BDBDBD",
        color: "#4F4F4F",
    },
    success: {
        background: "#3ECBA5",
        color: "#FFFFFF",
        border: "1px solid #3ECBA5",
    },
    danger: {
        background: "#EF1962",
        color: "#FFFFFF",
        border: "1px solid #EF1962",
    },
};

const AroggaButton: FC<AroggaButtonProps> = ({
    label = "Button",
    type = "primary",
    disabled = false,
    style,
    onClick,
}) => {
    const classes = useStyles();
    const [isHover, setIsHover] = useState(false);

    return (
        <input
            type="button"
            value={label}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={disabled ? undefined : onClick}
            className={classes.button}
            style={{
                ...ButtonType[type],
                ...(isHover && !disabled ? ButtonHover[type] : {}),
                ...style,
            }}
        />
    );
};

const useStyles = makeStyles(() => ({
    button: {
        padding: "5px 10px",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: 16,
    },
}));

export default AroggaButton;
