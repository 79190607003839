import { FC } from "react";
import {
    AutocompleteInput,
    Edit,
    EditProps,
    FormTab,
    NumberInput,
    ReferenceInput,
    TabbedForm,
    TextInput,
} from "react-admin";

import YearSelectInput from "../../../components/YearSelectInput";
import AdjustmentTab from "../../../components/manageHR/salaries/AdjustmentTab";
import { useDocumentTitle } from "../../../hooks";
import SalaryEditActions from "./SalaryEditActions";

const SalaryEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Salary Adjustment Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            // @ts-ignore
            actions={<SalaryEditActions />}
            {...rest}
        >
            <TabbedForm toolbar={null}>
                <FormTab label="Salary">
                    <TextInput
                        source="s_id"
                        label="Id"
                        variant="outlined"
                        disabled
                    />
                    <ReferenceInput
                        source="s_employee_id"
                        label="Employee"
                        variant="outlined"
                        reference="v1/employee"
                        filterToQuery={(searchText) => ({
                            _search: searchText,
                        })}
                        disabled
                    >
                        <AutocompleteInput
                            matchSuggestion={() => true}
                            optionValue="e_id"
                            optionText="e_name"
                            resettable
                        />
                    </ReferenceInput>
                    <YearSelectInput
                        source="s_year"
                        variant="outlined"
                        disabled
                    />
                    <TextInput
                        source="s_month"
                        label="Month"
                        variant="outlined"
                        disabled
                    />
                    <NumberInput
                        source="s_gross_salary"
                        label="Gross Salary"
                        variant="outlined"
                        disabled
                    />
                    <NumberInput
                        source="s_gross_addition"
                        label="Gross Addition"
                        variant="outlined"
                        disabled
                    />
                    <NumberInput
                        source="s_gross_deduction"
                        label="Gross Deduction"
                        variant="outlined"
                        disabled
                    />
                    <NumberInput
                        source="s_tax"
                        label="Tax"
                        variant="outlined"
                        disabled
                    />
                    <NumberInput
                        source="s_net_payable"
                        label="Net Payable"
                        variant="outlined"
                        disabled
                    />
                    <TextInput
                        source="s_payment_mode"
                        label="Payment Mode"
                        variant="outlined"
                        disabled
                    />
                    <TextInput
                        source="s_status"
                        label="Status"
                        variant="outlined"
                        disabled
                    />
                </FormTab>
                <FormTab label="Salary Adjustment">
                    <AdjustmentTab page="edit" />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default SalaryEdit;
