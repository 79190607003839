const Divider = ({
    firstLineWidth,
    secondLineWidth,
    miniLineColor = "#FFAA01",
    style = {},
}) => {
    return (
        <div
            style={{
                ...style,
                marginBottom: "20px",
            }}
        >
            <div
                style={{
                    height: "1px",
                    background: "#E5E5E5",
                    width: `${firstLineWidth}%`,
                }}
            ></div>
            <div
                style={{
                    height: "1px",
                    background: miniLineColor,
                    width: `${secondLineWidth}%`,
                    marginTop: "-1px",
                }}
            ></div>
        </div>
    );
};

export default Divider;
