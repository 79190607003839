const ManageB2BIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 21"
        style={{
            enableBackground: "new 0 0 24 21",
        }}
        xmlSpace="preserve"
        width="1em"
        height="1em"
        fontSize="1.5rem"
        {...props}
    >
        <path
            d="M18.7 8.8c.2 0 .4-.2.4-.4V7.2H24v3.6c0 .6-.6 1.2-1.2 1.2h-8.6v-1.3c0-.6-.6-1.2-1.2-1.2h-2V7.2h4.9v1.2c0 .2.2.4.4.4h2.4zm2-6.4h2c.7 0 1.3.6 1.3 1.2v2.8H10.9V3.6c0-.6.6-1.2 1.2-1.2h2V1.2c0-.6.6-1.2 1.2-1.2h4.1c.6 0 1.2.6 1.2 1.2v1.2zm-5.3 0h4.1V1.2h-4.1v1.2zm-8.1 3L8.6 4c.1-.1.1-.3 0-.4L7.3 2.3c-.1-.1-.3-.1-.4 0-.1.1-.1.3 0 .4l.9.9H5.2c-.2-.1-.3.1-.3.2 0 .2.1.3.3.3h2.6l-.9.9c-.1.1-.1.3 0 .4 0 0 .1.1.2.1s.1-.1.2-.1zm10 9.4-1.4 1.4c-.1.1-.1.3 0 .4l1.4 1.4c.1.1.3.1.4 0 .1-.1.1-.3 0-.4l-.9-.9h2.6c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3h-2.6l.9-.9c.1-.1.1-.3 0-.4-.1-.1-.1-.1-.2-.1s-.2.1-.2.1zM7.8 17c.2 0 .4-.2.4-.4v-1.2h4.9V19c0 .6-.6 1.2-1.2 1.2H1.2C.6 20.2 0 19.6 0 19v-3.6h4.9v1.2c0 .2.2.4.4.4h2.5zm2-6.4h2c.6 0 1.2.6 1.2 1.2v2.8H0v-2.8c0-.6.6-1.2 1.2-1.2h2V9.4c0-.6.6-1.2 1.2-1.2h4.1c.6 0 1.2.6 1.2 1.2v1.2zm-5.3 0h4.1V9.4H4.5v1.2z"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#969bad",
            }}
        />
    </svg>
);

export default ManageB2BIcon;
