import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";

import { toFixedNumber } from "../../../utils/helpers";

type OfflineOrdersFooterProps = {
    allItems: any[];
    discount: number;
};

const OfflineOrdersFooter: FC<OfflineOrdersFooterProps> = ({
    allItems,
    discount,
}) => {
    const classes = useStyles();

    const [totalMRP, setTotalMRP] = useState<any>(0);

    useEffect(() => {
        let totalMRP: number = 0;

        !!allItems?.length &&
            allItems.forEach((item) => {
                const medicine = allItems?.find((m) => m.m_id === item.m_id);

                if (!medicine) return null;

                const quantity = !!item.qty ? item.qty : 0;

                totalMRP = totalMRP + quantity * medicine.m_price;
            });

        setTotalMRP(totalMRP);
    }, [allItems]);

    return (
        <TableContainer>
            {!!allItems?.length && (
                <Table size="small" className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>{toFixedNumber(totalMRP)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Discount</TableCell>
                            <TableCell>
                                -
                                {discount
                                    ? toFixedNumber(
                                          toFixedNumber(discount) +
                                              (toFixedNumber(totalMRP) -
                                                  Math.floor(totalMRP))
                                      )
                                    : 0}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Amount Payable</TableCell>
                            <TableCell>
                                {Math.floor(totalMRP - discount)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
};

const useStyles = makeStyles({
    table: {
        width: 250,
        marginTop: 20,
        margin: "auto",
    },
});

export default OfflineOrdersFooter;
