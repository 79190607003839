const ManageStockIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26"
        style={{
            enableBackground: "new 0 0 26 26",
        }}
        xmlSpace="preserve"
        width="1em"
        height="1em"
        fontSize="1.5rem"
        {...props}
    >
        <path
            fill="#969bad"
            d="M11.4 3.6v3.1c0 .9.7 1.6 1.6 1.6h3.1c.9 0 1.6-.7 1.6-1.6V3.6c0-.9-.7-1.6-1.6-1.6H13c-.9 0-1.6.7-1.6 1.6zm-5.3 7c-.8.4-1 1.4-.6 2.1l1.6 2.7c.4.8 1.4 1 2.1.6l2.7-1.6c.8-.4 1-1.4.6-2.1l-1.6-2.7c-.4-.8-1.4-1-2.1-.6l-2.7 1.6zm10-.7c-.9 0-1.6.7-1.6 1.6v3.1c0 .9.7 1.6 1.6 1.6h3.1c.9 0 1.6-.7 1.6-1.6v-3.1c0-.9-.7-1.6-1.6-1.6h-3.1zm3.2 7.8c-.9 0-1.6.7-1.6 1.6v3.1c0 .9.7 1.6 1.6 1.6h3.1c.9 0 1.6-.7 1.6-1.6v-3.1c0-.9-.7-1.6-1.6-1.6h-3.1zm-9.4 1.6v3.1c0 .9.7 1.6 1.6 1.6h3.1c.9 0 1.6-.7 1.6-1.6v-3.1c0-.9-.7-1.6-1.6-1.6h-3.1c-.9 0-1.6.7-1.6 1.6zm-6.3-1.6c-.9 0-1.6.7-1.6 1.6v3.1c0 .9.7 1.6 1.6 1.6h3.1c.9 0 1.6-.7 1.6-1.6v-3.1c0-.9-.7-1.6-1.6-1.6H3.6z"
        />
    </svg>
);

export default ManageStockIcon;
