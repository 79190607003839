import { SvgIcon } from "@material-ui/core";

const ManagePromotionsIcon = () => (
    <SvgIcon viewBox="0 5 40 38" width="1em" height="1em" fontSize="medium">
        <path
            fill="#969bad"
            d="M36.5 25.5v-3H44v3ZM39 40l-6.05-4.5 1.8-2.4 6.05 4.5Zm-4.1-25.15-1.8-2.4L39 8l1.8 2.4ZM10.5 38v-8H7q-1.25 0-2.125-.875T4 27v-6q0-1.25.875-2.125T7 18h9l10-6v24l-10-6h-2.5v8ZM15 24Zm13 6.7V17.3q1.35 1.2 2.175 2.925Q31 21.95 31 24t-.825 3.775Q29.35 29.5 28 30.7ZM7 21v6h9.8l6.2 3.7V17.3L16.8 21Z"
        />
    </SvgIcon>
);

export default ManagePromotionsIcon;
