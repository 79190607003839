import { Grid } from "@material-ui/core";
import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    FileField,
    FileInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import UserEmployeeOptionTextRenderer from "../../UserEmployeeOptionTextRenderer";

type EmployeeInfoCreateEditProps = {
    page: "create" | "edit";
};

const EmployeeInfoCreateEdit: FC<EmployeeInfoCreateEditProps> = ({ page }) => {
    const form = useForm();
    const { values } = useFormState();

    const { data } = useRequest("/v1/bankNames", {}, { isPreFetching: true });
    const { data: bank } = useRequest(
        `/v1/bank?ids=${values.ei_bank_id}`,
        {},
        { isPreFetching: page === "edit" }
    );

    return (
        <>
            <ReferenceInput
                source="ei_e_id"
                label="Employee"
                variant="outlined"
                reference="v1/employee"
                sort={{ field: "e_id", order: "DESC" }}
                filterToQuery={(searchText) => ({
                    _search: searchText,
                })}
                validate={[required()]}
            >
                <AutocompleteInput
                    matchSuggestion={() => true}
                    optionValue="e_id"
                    optionText={<UserEmployeeOptionTextRenderer isEmployee />}
                    inputText={(record: { e_name: string; e_mobile: string }) =>
                        !!record ? `${record.e_name} (${record.e_mobile})` : ""
                    }
                    resettable
                />
            </ReferenceInput>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={2}>
                    <AutocompleteInput
                        source="bankName"
                        label="Bank"
                        variant="outlined"
                        choices={!!data?.length ? data : []}
                        defaultValue={bank?.[0]?.b_name}
                        onChange={() => form.change("ei_bank_id", undefined)}
                        optionValue="b_name"
                        optionText="b_name"
                        fullWidth
                        resettable
                    />
                </Grid>
                {values.bankName && (
                    <Grid item xs={12} sm={6} md={2}>
                        <ReferenceInput
                            source="ei_bank_id"
                            label="Branch"
                            variant="outlined"
                            reference="v1/bank"
                            sort={{ field: "b_id", order: "DESC" }}
                            filter={{ _name: values.bankName }}
                            filterToQuery={(searchText) => ({
                                _branch: searchText,
                            })}
                            fullWidth
                        >
                            <AutocompleteInput
                                optionValue="b_id"
                                optionText="b_branch"
                                resettable
                            />
                        </ReferenceInput>
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={2}>
                    <TextInput
                        source="ei_bank_account_title"
                        label="Bank Account Title"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <TextInput
                        source="ei_bank_account_number"
                        label="Bank Account Number"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <NumberInput
                        source="ei_card_number"
                        label="Card Number"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                {values.ei_card_number && (
                    <Grid item xs={12} sm={6} md={2}>
                        <NumberInput
                            source="ei_client_id"
                            label="Client Id"
                            variant="outlined"
                            validate={
                                values.ei_card_number ? [required()] : null
                            }
                            fullWidth
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <DateInput
                        source="ei_date_of_birth"
                        label="Date of Birth"
                        variant="outlined"
                        validate={[required()]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SelectInput
                        source="ei_blood_group"
                        label="Blood Group"
                        variant="outlined"
                        choices={[
                            { id: "A+", name: "A+" },
                            { id: "A-", name: "A-" },
                            { id: "B+", name: "B+" },
                            { id: "B-", name: "B-" },
                            { id: "O+", name: "O+" },
                            { id: "O-", name: "O-" },
                            { id: "AB+", name: "AB+" },
                            { id: "AB-", name: "AB-" },
                        ]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                        source="ei_residential_address"
                        label="Residential Address"
                        variant="outlined"
                        validate={[required()]}
                        minRows={2}
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                        source="ei_birth_certificate"
                        label="Birth Certificate Number"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FileInput
                        source="attachedFiles-ei_birth_certificate_photo"
                        label="Files"
                        accept="image/*, application/pdf"
                        maxSize={10000000}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                        source="ei_nid"
                        label="NID"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FileInput
                        source="attachedFiles-ei_nid_photo"
                        label="Files"
                        accept="image/*, application/pdf"
                        maxSize={10000000}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                        source="ei_tin"
                        label="TIN"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FileInput
                        source="attachedFiles-ei_tin_photo"
                        label="Files"
                        accept="image/*, application/pdf"
                        maxSize={10000000}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                        source="ei_license"
                        label="Driving License"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FileInput
                        source="attachedFiles-ei_license_photo"
                        label="Files"
                        accept="image/*, application/pdf"
                        maxSize={10000000}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                        source="ei_passport"
                        label="Passport"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FileInput
                        source="attachedFiles-ei_passport_photo"
                        label="Files"
                        accept="image/*, application/pdf"
                        maxSize={10000000}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </Grid>
            </Grid>
            <FileInput
                source="attachedFiles-ei_cheque_photo"
                label="Cheque Files"
                accept="image/*, application/pdf"
                maxSize={10000000}
                multiple
            >
                <FileField source="src" title="title" />
            </FileInput>
        </>
    );
};

export default EmployeeInfoCreateEdit;
