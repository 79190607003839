import { Box, Button } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FC, useEffect } from "react";
import {
    ArrayInput,
    FileField,
    FileInput,
    FormDataConsumer,
    SimpleFormIterator,
    TextInput,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import {
    convertFileToString,
    isEmpty,
    isJSONParsable,
} from "../../../utils/helpers";

type B2BSecureFormProps = {
    source: string;
};

const B2BSecureForm: FC<B2BSecureFormProps> = ({ source }) => {
    const form = useForm();
    const { values } = useFormState();

    const fileSourceName = `attachedFiles-${source}`;

    useEffect(() => {
        if (isEmpty(values[fileSourceName])) return;

        (async () => {
            const result: any = await convertFileToString(
                values[fileSourceName]
            );
            form.change(
                source,
                isJSONParsable(result) ? JSON.parse(result) : []
            );
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[fileSourceName]]);

    const handleExport = () => {
        const dataURI = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(values[source])
        )}`;
        const anchor = document.createElement("a");
        anchor.href = dataURI;
        anchor.download = `${source}-env.json`;

        anchor.click();
    };

    return (
        <div style={{ position: "relative" }}>
            <FileInput
                source={fileSourceName}
                label="Import Env Variables"
                accept="application/json"
            >
                <FileField source="src" title="title" />
            </FileInput>
            <ArrayInput
                source={source}
                label=""
                style={{
                    marginTop: -12,
                }}
            >
                <SimpleFormIterator
                    // @ts-ignore
                    TransitionProps={{ classNames: "fade-exit" }}
                    addButton={
                        <Box ml={3}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor: "#027bff",
                                    color: "white",
                                    position: "relative",
                                }}
                            >
                                Add
                            </Button>
                        </Box>
                    }
                    removeButton={
                        <Box
                            mt={1}
                            ml={1}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <HighlightOffIcon />
                        </Box>
                    }
                    disableReordering
                >
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => (
                            <Box display="flex" gridGap={10}>
                                <TextInput
                                    source={getSource("key")}
                                    record={scopedFormData}
                                    label="Key"
                                    variant="outlined"
                                    style={{ width: 400 }}
                                    helperText={false}
                                />
                                <TextInput
                                    source={getSource("value")}
                                    record={scopedFormData}
                                    label="Value"
                                    variant="outlined"
                                    style={{ width: 400 }}
                                    helperText={false}
                                />
                            </Box>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
            {!isEmpty(values[source]) && (
                <Button
                    variant="outlined"
                    style={{
                        backgroundColor: "#027bff",
                        color: "white",
                        position: "absolute",
                        bottom: 8,
                        left: 93,
                    }}
                    onClick={handleExport}
                >
                    Export
                </Button>
            )}
        </div>
    );
};

export default B2BSecureForm;
