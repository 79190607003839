import { FC } from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";

const PagesList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Page List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Page"
            perPage={25}
            sort={{ field: "p_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("pageDelete")}
                />
            }
        >
            <Datagrid
                rowClick={permissions?.includes("pageEdit") ? "edit" : ""}
            >
                <TextField source="p_id" label="Id" />
                <TextField source="p_created" label="Created" />
                <TextField source="p_title" label="Title" />
                <TextField source="p_slug" label="Slug" />
                <TextField source="p_status" label="Status" />
            </Datagrid>
        </List>
    );
};

export default PagesList;
