import { Button } from "@material-ui/core";
import { FC, MouseEvent, useState } from "react";
import {
    Datagrid,
    FunctionField,
    NumberField,
    Pagination,
    Record,
    ReferenceManyField,
    Show,
    ShowProps,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
} from "react-admin";
import { Link } from "react-router-dom";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import QtyLabel from "../../../components/QtyLabel";
import AuditDialog from "../../../components/manageStock/inventories/AuditDialog";
import NewCorrectionDialog from "../../../components/manageStock/inventories/NewCorrectionDialog";
import { useDocumentTitle } from "../../../hooks";

const InventoryShow: FC<ShowProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Inventory Show");

    const [isNewCorrectionDialogOpen, setIsNewCorrectionDialogOpen] =
        useState(false);
    const [isAuditDialogOpen, setIsAuditDialogOpen] = useState(false);

    const isRoleAuditor = permissions?.includes("role:auditor");

    return (
        <Show {...rest}>
            <TabbedShowLayout>
                <Tab label="Inventory">
                    <ColumnShowLayout simpleShowLayout={false}>
                        <TextField
                            source="i_warehouse_id"
                            label="Warehouse id"
                        />
                        <TextField source="m_name" label="Name" />
                        <TextField source="m_form" label="Form" />
                        <TextField source="m_strength" label="Strength" />
                        <TextField source="m_unit" label="Unit" />
                        <TextField source="m_company" label="Company" />
                        <NumberField source="i_qty" label="Quantity" />
                        <FunctionField
                            label="In Shelf"
                            render={(record: Record) =>
                                `${
                                    (parseInt(record.i_qty) || 0) +
                                    (parseInt(record?.packing_qty) || 0)
                                }`
                            }
                        />
                        <TextField source="audited_qty" label="Audited Qty" />
                        <TextField source="i_rack_no" label="Rack No" />
                        <FunctionField
                            label="Previous Correction"
                            render={({ prev_correction, ...rest }: Record) => (
                                <QtyLabel
                                    qty={prev_correction}
                                    medicine={rest}
                                />
                            )}
                        />
                        <NumberField source="i_price" label="Price" />
                        {!isRoleAuditor && (
                            <TextField
                                source="i_note"
                                label="Note"
                                component="pre"
                            />
                        )}
                    </ColumnShowLayout>
                    {permissions?.includes("inventoryAuditCreate") && (
                        <Button
                            variant="contained"
                            onClick={() => setIsAuditDialogOpen(true)}
                        >
                            Audit
                        </Button>
                    )}
                    {permissions?.includes("inventoryAuditCreate") && (
                        <SimpleForm toolbar={null}>
                            <AuditDialog
                                open={isAuditDialogOpen}
                                handleDialogClose={() =>
                                    setIsAuditDialogOpen(false)
                                }
                            />
                        </SimpleForm>
                    )}
                    {!!permissions?.includes("role:warehouse") && (
                        <Button
                            variant="outlined"
                            style={{ width: "256px" }}
                            onClick={() => setIsNewCorrectionDialogOpen(true)}
                        >
                            New Correction
                        </Button>
                    )}
                    {!!permissions?.includes("role:warehouse") && (
                        <SimpleForm toolbar={null}>
                            <NewCorrectionDialog
                                open={isNewCorrectionDialogOpen}
                                handleDialogClose={() =>
                                    setIsNewCorrectionDialogOpen(false)
                                }
                            />
                        </SimpleForm>
                    )}
                </Tab>
                {permissions?.includes("historyView") && (
                    <Tab label="History">
                        <ReferenceManyField
                            addLabel={false}
                            reference="v1/histories"
                            target="_obj_id"
                            pagination={<Pagination />}
                            filter={{ _h_obj: "inventory" }}
                            sort={{ field: "h_id", order: "DESC" }}
                        >
                            <Datagrid>
                                <TextField source="u_name" label="Name" />
                                <FunctionField
                                    label="Order Id"
                                    sortBy="o_id"
                                    onClick={(e: MouseEvent) =>
                                        e.stopPropagation()
                                    }
                                    render={({ o_id }: Record) =>
                                        o_id === 0 ? (
                                            o_id
                                        ) : (
                                            <Link
                                                to={`/v1/orders/${o_id}`}
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                {o_id}
                                            </Link>
                                        )
                                    }
                                />
                                <TextField source="p_id" label="Purchase Id" />
                                <TextField source="h_created" label="Date" />
                                <TextField source="h_action" label="Action" />
                                <FunctionField
                                    label="Quantity"
                                    render={(record: Record) => {
                                        const qty = record.h_from - record.h_to;

                                        if (qty < 0) {
                                            return Math.abs(qty);
                                        }
                                        return qty;
                                    }}
                                />
                                <TextField source="h_from" label="From" />
                                <TextField source="h_to" label="To" />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                )}
            </TabbedShowLayout>
        </Show>
    );
};

export default InventoryShow;
