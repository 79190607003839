import { FC, MouseEvent } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    Record,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import CompanyFilter from "./CompanyFilter";

const CompanyList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Company List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Company"
            filters={<CompanyFilter children={""} />}
            perPage={25}
            sort={{ field: "c_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
            {...rest}
        >
            <Datagrid rowClick="show">
                <TextField source="c_id" label="Id" />
                <TextField source="c_name" label="Name" />
                <FunctionField
                    sortBy="c_uid"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    label="User Id"
                    render={(record: Record) => {
                        if (!record.c_uid) return record.c_uid;

                        return (
                            <Link to={`/v1/users/${record.c_uid}`}>
                                {record.c_uid}
                            </Link>
                        );
                    }}
                />
                <TextField source="c_info" label="Information" />
            </Datagrid>
        </List>
    );
};

export default CompanyList;
