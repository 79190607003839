import { FC } from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";

const CurrencyRateList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Currency Rate List");

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Currency Rate"
            perPage={25}
            sort={{ field: "cr_id", order: "DESC" }}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("currencyRateDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid rowClick="edit">
                <TextField source="cr_id" label="Id" />
                <TextField source="cr_currency" label="Currency" />
                <TextField source="cr_rate" label="Rate" />
                <TextField source="cr_date" label="Date" />
            </Datagrid>
        </List>
    );
};

export default CurrencyRateList;
