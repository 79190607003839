import { FC } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    ReferenceInput,
    SimpleForm,
    TextInput,
} from "react-admin";

import AddressTypeInput from "../../../components/AddressTypeInput";
import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import LocationInput from "../../../components/LocationInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { useDocumentTitle } from "../../../hooks";
import { required } from "../../../utils/helpers";

const AddressCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Address Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <ReferenceInput
                    source="u_id"
                    label="User"
                    variant="outlined"
                    reference="v1/users"
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="u_id"
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={(record: {
                            u_name: string;
                            u_mobile: string;
                        }) =>
                            !!record
                                ? `${record.u_name} (${record.u_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
                <TextInput source="ul_name" label="Name" variant="outlined" />
                <TextInput
                    source="ul_mobile"
                    label="Mobile"
                    variant="outlined"
                />
                <AddressTypeInput
                    source="ul_type"
                    variant="outlined"
                    allowEmpty
                />
                <LocationInput
                    source="l_id"
                    variant="outlined"
                    validate={[required()]}
                />
                <TextInput
                    source="ul_address"
                    label="Address"
                    variant="outlined"
                />
                <FormatedBooleanInput source="ul_default" label="Default" />
            </SimpleForm>
        </Create>
    );
};

export default AddressCreate;
