import { FC } from "react";
import {
    DateField,
    NumberField,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const DailyReportShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Daily Report Show");

    return (
        <Show {...props}>
            <ColumnShowLayout>
                <NumberField source="b_id" label="Id" />
                <TextField source="b_collection" label="Collection" />
                <TextField source="b_purchase" label="Purchase" />
                <TextField source="b_expense" label="Expense" />
                <TextField source="b_balance" label="Cash In Hand" />
                <TextField source="b_l_balance" label="Ledger Balance" />
                <TextField
                    source="b_l_b_approved"
                    label="Ledger Balance When Approved"
                />
                <DateField source="b_date" label="Date" />
                <TextField source="b_status" label="Status" />
            </ColumnShowLayout>
        </Show>
    );
};

export default DailyReportShow;
