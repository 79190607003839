import { Button, Grid, makeStyles } from "@material-ui/core";
import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import {
    AutocompleteInput,
    Confirm,
    FormDataConsumer,
    Labeled,
    Link,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    minValue,
    required,
    useNotify,
    useRedirect,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useGetStoreData, useRequest } from "../../../hooks";
import { TINY_MCE_EDITOR_INIT } from "../../../utils/constants";
import {
    capitalizeFirstLetter,
    isEmpty,
    toFixedNumber,
} from "../../../utils/helpers";
import AroggaAccordion from "../../AroggaAccordion";
import AroggaButton from "../../AroggaButton";
import CustomImageField from "../../CustomImageField";
import FormatedBooleanInput from "../../FormatedBooleanInput";
import MedicineCategoryInput from "../../MedicineCategoryInput";
import MedicineTypeInput from "../../MedicineTypeInput";
import ChangeMultiplierDialogContent from "./ChangeMultiplierDialogContent";
import DiscountButtons from "./DiscountButtons";
import ElevatedActionDialog from "./ElevatedActionDialog";
import PrevMultiplierChangeDialog from "./PrevMultiplierChangeDialog";
import WebcamModal from "./WebcamModal";

const MedicineEditForm = ({ page, description, setDescription, ...rest }) => {
    const classes = useStyles();
    const form = useForm();
    const redirect = useRedirect();
    const discountPriceRef = useRef<HTMLInputElement>(null!);
    const wholesalePriceRef = useRef<HTMLInputElement>(null!);
    const genericsData = useGetStoreData("v1/generics");
    const companiesData = useGetStoreData("v1/companies");
    const { values } = useFormState();

    const notify = useNotify();

    const [
        isPrevMultiplierChangeDialogOpen,
        setIsPrevMultiplierChangeDialogOpen,
    ] = useState(false);
    const [action, setAction] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isChangeMultiplierDialogOpen, setIsChangeMultiplierDialogOpen] =
        useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [webCamImage, setWebCamImage] = useState(null);

    const { isLoading, refetch } = useRequest(
        `/v1/medicines/updateMultiplier/${values.m_id}`,
        {
            method: "POST",
            body: {
                m_multiplier: values.m_multiplier,
            },
        },
        {
            onSuccess: () => {
                setIsChangeMultiplierDialogOpen(false);
                redirect("/v1/medicines?_orderBy=supervised_at");
            },
        }
    );

    const keys = [
        "m_name",
        "m_g_id",
        "m_c_id",
        "m_strength",
        "m_manufacturer_id",
        "m_unit",
        "m_min",
        "m_max",
        "m_w_min",
        "m_w_max",
        "m_form",
        "m_price",
        "m_d_price",
        "m_w_price",
        "m_category",
        "m_cat_id",
        "m_yt_key",
        "m_comment",
        "m_i_comment",
        "m_status",
        "m_rob",
        "m_cold",
        "attachedFiles",
    ];

    const categoryChoices = {
        11: "Prescription Medicines",
        12: "OTC Medicines",
        13: "Covid-19 Special",
        14: "Devices",
        15: "Diabetic Care",
        16: "Supplements and Vitamins",
        17: "Personal care",
        18: "Herbal and Homeopathy",
        19: "Baby & Mom care",
        20: "Women Care",
        21: "Sexual Wellness",
        22: "Nutrition and drinks",
        23: "Veterinary",
        24: "Controlled Drugs",
    };

    const { changed_medicine } = rest.record;

    useEffect(() => {
        if (page !== "edit") return;

        setDescription(
            values.m_approval_status === "cancelled" ||
                values.m_approval_status === "rejected"
                ? values?.description
                : changed_medicine?.description || values?.description
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.changed_medicine?.description, values.description]);

    useEffect(() => {
        if (page === "create") return;

        keys.forEach((key) => {
            if (key === "attachedFiles") {
                if (changed_medicine?.attachedFiles?.length > 0) {
                    const attachedFiles = [
                        ...changed_medicine.attachedFiles,
                        ...values.attachedFiles,
                    ];
                    form.change(key, attachedFiles);
                } else {
                    form.change(key, values?.[key]);
                }
            } else if (key === "m_rob" || key === "m_cold") {
                form.change(
                    key,
                    changed_medicine?.hasOwnProperty(key)
                        ? changed_medicine?.[key]
                        : values?.[key]
                );
            } else {
                form.change(key, changed_medicine?.[key] || values?.[key]);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.record]);

    useEffect(() => {
        !isEmpty(rest.record?.changed_medicine?.deletedFiles) &&
            setDeletedFiles(rest.record.changed_medicine.deletedFiles);
    }, [rest.record?.changed_medicine?.deletedFiles]);

    useEffect(() => {
        if (webCamImage) {
            if (values?.attachedFiles?.length > 0) {
                const attachedFiles = [...values?.attachedFiles, webCamImage];
                form.change("attachedFiles", attachedFiles);
                setWebCamImage(null);
            } else {
                form.change("attachedFiles", [webCamImage]);
                setWebCamImage(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webCamImage]);

    if (page === "create") {
        values.description = description;
    }

    if (page === "edit") {
        values.deletedFiles = deletedFiles;
    }

    const compare = (key) => {
        if (
            page === "create" ||
            (!rest.record?.changed_medicine?.[key] &&
                rest.record?.[key] === values?.[key])
        )
            return;

        let orginalText;
        let inputValueArr;
        let unchangedRecordValueArr;

        unchangedRecordValueArr = rest.record?.[key]?.toString().split("");

        if (key === "m_cat_id") {
            orginalText = categoryChoices[rest.record?.[key]];
            inputValueArr = categoryChoices[values[key]]?.split("");
            unchangedRecordValueArr =
                categoryChoices[rest.record?.[key]]?.split("");
        } else if (key === "m_rob" || key === "m_cold") {
            orginalText = !!rest.record?.[key] ? ["true"] : ["false"];
            inputValueArr = !!values?.[key] ? ["true"] : ["false"];
        } else {
            if (key === "m_g_id") {
                orginalText = genericsData[rest?.record?.m_g_id]?.g_name;
                inputValueArr = selectedItems[key]
                    ? selectedItems?.[key]?.split("")
                    : genericsData[
                          rest?.record?.changed_medicine?.m_g_id
                      ]?.g_name?.split("");
            } else if (key === "m_c_id" || key === "m_manufacturer_id") {
                orginalText = companiesData[rest?.record?.[key]]?.c_name;
                inputValueArr = selectedItems[key]
                    ? selectedItems?.[key]?.split("")
                    : companiesData[
                          rest?.record?.changed_medicine?.[key]
                      ]?.c_name?.split("");
            } else {
                orginalText = rest.record?.[key];
                inputValueArr = values?.[key]?.toString().split("");
            }
        }

        const compareText = [];

        inputValueArr?.forEach((_, i) => {
            compareText.push(
                <span
                    key={i}
                    className={
                        inputValueArr?.[i] !== unchangedRecordValueArr?.[i]
                            ? "compare-medicine-highlight"
                            : ""
                    }
                >
                    {inputValueArr[i]}
                </span>
            );
        });

        return (
            <span
                style={{
                    position: "absolute",
                    left: 8,
                    top:
                        key === "m_min" ||
                        key === "m_max" ||
                        key === "m_w_min" ||
                        key === "m_w_max" ||
                        key === "m_price" ||
                        key === "m_d_price" ||
                        key === "m_w_price"
                            ? 75
                            : 62,
                    color: "#7C8AA0",
                    fontSize: 13,
                }}
            >
                {orginalText} &gt; {compareText}
            </span>
        );
    };

    const onSelect = (key, value) => {
        setSelectedItems((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const pricePercentage = (source) =>
        (((+values.m_price || 0) - (+values[source] || 0)) * 100) /
        +values.m_price;

    const isPermissionElevatedMedicineEdit = rest.permissions?.includes(
        "elevatedMedicineEdit"
    );

    const checkPrice = () => {
        // console.log(values);
        if (
            values.m_d_price <= values.m_price &&
            values.m_w_price <= values.m_d_price
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {page === "edit" && (
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item sm={6} md={3}>
                        <span
                            style={{
                                color: "#7C8AA0",
                                fontSize: 22,
                                marginRight: 5,
                            }}
                        >
                            Approval Status:
                        </span>
                        <span
                            style={
                                values.m_approval_status === "approved"
                                    ? { color: "#008069", fontSize: 22 }
                                    : values.m_approval_status === "rejected"
                                    ? { color: "#EF1962", fontSize: 22 }
                                    : { color: "#7C8AA0", fontSize: 22 }
                            }
                        >
                            {capitalizeFirstLetter(values.m_approval_status)}
                        </span>
                    </Grid>
                    {values.m_approval_status === "rejected" && (
                        <Grid item sm={6} md={3}>
                            <span
                                style={{
                                    color: "#7C8AA0",
                                    fontSize: 22,
                                    marginRight: 5,
                                }}
                            >
                                Rejected Reason:
                            </span>
                            <span
                                style={{
                                    color: "#EF1962",
                                    fontSize: 22,
                                }}
                            >
                                {values.changed_medicine?.reason}
                            </span>
                        </Grid>
                    )}
                    {page === "edit" && isPermissionElevatedMedicineEdit && (
                        <Grid item sm={6} md={3}>
                            <AroggaButton
                                label="Change Multiplier"
                                type="success"
                                onClick={() =>
                                    setIsChangeMultiplierDialogOpen(true)
                                }
                            />
                            <Confirm
                                title="Change Multiplier"
                                content={
                                    <ChangeMultiplierDialogContent
                                        record={rest.record}
                                    />
                                }
                                isOpen={isChangeMultiplierDialogOpen}
                                loading={isLoading}
                                onConfirm={refetch}
                                onClose={() =>
                                    setIsChangeMultiplierDialogOpen(false)
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            )}
            <AroggaAccordion title="Info" isGrid={false}>
                <Grid container spacing={2}>
                    {page === "edit" && (
                        <Grid item sm={6} md={3}>
                            <TextInput
                                source="m_id"
                                label="Id"
                                variant="outlined"
                                fullWidth
                                disabled
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <TextInput
                            source="m_name"
                            label="Name"
                            variant="outlined"
                            validate={[required()]}
                            fullWidth
                        />
                        {compare("m_name")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <ReferenceInput
                            source="m_g_id"
                            label="Generic"
                            variant="outlined"
                            reference="v1/generics"
                            filter={{_status:"active"}}
                            filterToQuery={(searchText) => ({
                                _search: searchText,
                            })}
                            onSelect={(data) =>
                                onSelect("m_g_id", data?.g_name)
                            }
                            sort={{ field: "g_name", order: "ASC" }}
                            allowEmpty
                            fullWidth
                        >
                            <AutocompleteInput
                                optionValue="g_id"
                                optionText="g_name"
                                resettable
                            />
                        </ReferenceInput>
                        {compare("m_g_id")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <ReferenceInput
                            source="m_c_id"
                            label="Company"
                            variant="outlined"
                            reference="v1/companies"
                            filterToQuery={(searchText) => ({
                                _search: searchText,
                            })}
                            onSelect={(data) =>
                                onSelect("m_c_id", data?.c_name)
                            }
                            sort={{ field: "c_name", order: "ASC" }}
                            allowEmpty
                            fullWidth
                        >
                            <AutocompleteInput
                                optionValue="c_id"
                                optionText="c_name"
                                resettable
                            />
                        </ReferenceInput>
                        {compare("m_c_id")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <ReferenceInput
                            source="m_manufacturer_id"
                            label="Manufacturer By"
                            variant="outlined"
                            reference="v1/companies"
                            filterToQuery={(searchText) => ({
                                _search: searchText,
                            })}
                            sort={{ field: "c_name", order: "ASC" }}
                            onSelect={(data) =>
                                onSelect("m_manufacturer_id", data?.c_name)
                            }
                            allowEmpty
                            fullWidth
                        >
                            <AutocompleteInput
                                optionValue="c_id"
                                optionText="c_name"
                                resettable
                            />
                        </ReferenceInput>
                        {compare("m_manufacturer_id")}
                    </Grid>

                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <TextInput
                            source="m_strength"
                            label="Strength"
                            variant="outlined"
                            fullWidth
                        />
                        {compare("m_strength")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <TextInput
                            source="m_form"
                            label="Form"
                            variant="outlined"
                            fullWidth
                        />
                        {compare("m_form")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <TextInput
                            source="m_unit"
                            label="Unit"
                            variant="outlined"
                            disabled={
                                page === "edit" &&
                                !!values.m_form &&
                                !!values.m_unit &&
                                !!values.m_unit?.includes(values.m_form)
                            }
                            fullWidth
                        />
                        {compare("m_unit")}
                    </Grid>
                    {page === "create" && (
                        <Grid item sm={6} md={3}>
                            <NumberInput
                                source="m_multiplier"
                                label="Multiplier"
                                variant="outlined"
                                validate={[minValue(1)]}
                                disabled={
                                    !values.m_form ||
                                    !values.m_unit ||
                                    !values.m_unit?.includes(values.m_form)
                                }
                                fullWidth
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <NumberInput
                            source="m_min"
                            label="Minimum Qty"
                            variant="outlined"
                            validate={[minValue(1)]}
                            fullWidth
                        />
                        {compare("m_min")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <NumberInput
                            source="m_max"
                            label="Maximum Qty"
                            variant="outlined"
                            validate={[minValue(1)]}
                            fullWidth
                        />
                        {compare("m_max")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <NumberInput
                            source="m_w_min"
                            label="Minimum Qty (B2B)"
                            variant="outlined"
                            validate={[minValue(1)]}
                            fullWidth
                        />
                        {compare("m_w_min")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <NumberInput
                            source="m_w_max"
                            label="Maximum Qty (B2B)"
                            variant="outlined"
                            validate={[minValue(1)]}
                            fullWidth
                        />
                        {compare("m_w_max")}
                    </Grid>
                </Grid>
            </AroggaAccordion>
            <AroggaAccordion title="Pricing" isGrid={false}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <NumberInput
                            source="m_price"
                            label="MRP Price"
                            variant="outlined"
                            validate={[minValue(1)]}
                            fullWidth
                        />
                        {compare("m_price")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <Grid
                            container
                            spacing={1}
                            justifyContent="space-between"
                        >
                            <Grid
                                item
                                sm={6}
                                md={7}
                                className={classes.positionRelative}
                            >
                                <NumberInput
                                    source="m_d_price"
                                    label={`Discount Price ${`(${toFixedNumber(
                                        pricePercentage("m_d_price")
                                    )}%)`}`}
                                    variant="outlined"
                                    validate={[minValue(1)]}
                                    inputRef={discountPriceRef}
                                    fullWidth
                                />
                                {compare("m_d_price")}
                            </Grid>
                            <Grid item sm={6} md={5} style={{ marginTop: 10 }}>
                                <FormDataConsumer>
                                    {({ formData }) => {
                                        const handleDiscount = (discount) => {
                                            formData.m_d_price = toFixedNumber(
                                                formData.m_price -
                                                    (formData.m_price *
                                                        discount) /
                                                        100
                                            );

                                            discountPriceRef?.current?.focus();
                                        };

                                        return (
                                            <DiscountButtons
                                                discounts={[
                                                    5, 10, 12, 15, 20, 30,
                                                ]}
                                                handleDiscount={handleDiscount}
                                            />
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <Grid
                            container
                            spacing={1}
                            justifyContent="space-between"
                        >
                            <Grid
                                item
                                sm={6}
                                md={7}
                                className={classes.positionRelative}
                            >
                                <NumberInput
                                    source="m_w_price"
                                    label={`Wholesale Price ${`(${toFixedNumber(
                                        pricePercentage("m_w_price")
                                    )}%)`}`}
                                    variant="outlined"
                                    inputRef={wholesalePriceRef}
                                    fullWidth
                                />
                                {compare("m_w_price")}
                            </Grid>
                            <Grid item sm={6} md={5} style={{ marginTop: 10 }}>
                                <FormDataConsumer>
                                    {({ formData }) => {
                                        const handleDiscount = (discount) => {
                                            formData.m_w_price = toFixedNumber(
                                                formData.m_price -
                                                    (formData.m_price *
                                                        discount) /
                                                        100
                                            );

                                            wholesalePriceRef?.current?.focus();
                                        };

                                        return (
                                            <DiscountButtons
                                                discounts={[
                                                    5, 10, 12, 15, 20, 30,
                                                ]}
                                                handleDiscount={handleDiscount}
                                            />
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AroggaAccordion>
            <AroggaAccordion title="Others" isGrid={false}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <MedicineTypeInput
                            source="m_category"
                            variant="outlined"
                            fullWidth
                        />
                        {compare("m_category")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <MedicineCategoryInput
                            source="m_cat_id"
                            variant="outlined"
                            allowEmpty
                            fullWidth
                        />
                        {compare("m_cat_id")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <TextInput
                            source="m_yt_key"
                            label="Youtube Key"
                            variant="outlined"
                            fullWidth
                        />
                        {compare("m_yt_key")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <TextInput
                            source="m_comment"
                            label="Comment"
                            variant="outlined"
                            fullWidth
                        />
                        {compare("m_comment")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <TextInput
                            source="m_i_comment"
                            label="Internal Comment"
                            variant="outlined"
                            fullWidth
                        />
                        {compare("m_i_comment")}
                    </Grid>
                    {((page === "create" &&
                        rest.permissions?.includes("elevatedMedicineCreate")) ||
                        (page === "edit" &&
                            isPermissionElevatedMedicineEdit)) && (
                        <Grid
                            item
                            sm={6}
                            md={3}
                            className={classes.positionRelative}
                        >
                            <SelectInput
                                source="m_status"
                                label="Status"
                                variant="outlined"
                                choices={[
                                    { id: "active", name: "Active" },
                                    {
                                        id: "inactive",
                                        name: "In Active",
                                    },
                                ]}
                                fullWidth
                            />
                            {compare("m_status")}
                        </Grid>
                    )}
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <FormatedBooleanInput
                            source="m_rob"
                            label="Stock"
                            fullWidth
                        />
                        {compare("m_rob")}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        md={3}
                        className={classes.positionRelative}
                    >
                        <FormatedBooleanInput
                            source="m_cold"
                            label="Cold Chain Maintained"
                            fullWidth
                        />
                        {compare("m_cold")}
                    </Grid>
                </Grid>
                {page === "edit" &&
                    rest.permissions?.includes("superAdmin") && (
                        <div
                            style={{
                                padding: "20px 8px 0px 8px",
                                border: "0.5px dashed #112950",
                                borderRadius: 4,
                                width: "max-content",
                                marginBottom: 8,
                            }}
                        >
                            <NumberInput
                                source="prev_multiplier"
                                label="Previous Multiplier"
                                variant="outlined"
                                style={{ width: 256 }}
                            />
                            <TextInput
                                source="prev_unit"
                                label="Previous Unit"
                                variant="outlined"
                                style={{
                                    width: 256,
                                    marginLeft: 10,
                                    marginRight: 10,
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setIsPrevMultiplierChangeDialogOpen(true)
                                }
                                style={{ marginTop: 10 }}
                            >
                                Prev Multiplier Change
                            </Button>
                            <PrevMultiplierChangeDialog
                                open={isPrevMultiplierChangeDialogOpen}
                                handleClose={() =>
                                    setIsPrevMultiplierChangeDialogOpen(false)
                                }
                                formValues={values}
                            />
                        </div>
                    )}
            </AroggaAccordion>
            {values?.m_category === "allopathic" && !!values.m_g_id && (
                <div style={{ marginBottom: 10 }}>
                    <Link to={`/v1/generics/${values.m_g_id}/1`}>
                        To edit description click here
                    </Link>
                </div>
            )}
            {values?.m_category === "healthcare" && (
                <Labeled label="Description" fullWidth>
                    <Editor
                        tinymceScriptSrc={
                            process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                        }
                        init={TINY_MCE_EDITOR_INIT}
                        value={description}
                        onEditorChange={(newValue) => setDescription(newValue)}
                    />
                </Labeled>
            )}
            <Grid
                container
                spacing={2}
                style={{
                    alignItems: "center",
                }}
            >
                <Grid item md={8} spacing={3}>
                    <MovableImageInput
                        source="attachedFiles"
                        label="Pictures"
                        accept="image/*"
                        maxSize={5000000}
                        options={{
                            inputProps: {
                                multiple: true,
                            },
                            onRemove: (file) => {
                                if (
                                    page === "edit" &&
                                    !(file.rawFile instanceof File)
                                ) {
                                    const deletedFiles =
                                        rest?.record?.changed_medicine?.deletedFiles?.find(
                                            (item) => item.s3key === file?.s3key
                                        );

                                    isEmpty(deletedFiles) &&
                                        file?.s3key &&
                                        setDeletedFiles((prevState) => [
                                            ...prevState,
                                            {
                                                title: file.title,
                                                s3key: file.s3key,
                                            },
                                        ]);
                                }
                            },
                        }}
                        multiple
                    >
                        <CustomImageField
                            values={values}
                            classes={classes}
                            source="src"
                            title="title"
                        />
                    </MovableImageInput>
                </Grid>
                <WebcamModal callback={setWebCamImage} />
            </Grid>
            {page === "edit" && isPermissionElevatedMedicineEdit && (
                <>
                    <hr
                        style={{
                            width: "100%",
                            border: "1px dashed #CED4DA",
                        }}
                    />
                    <div
                        style={{
                            width: "100%",
                            textAlign: "right",
                            marginTop: 25,
                        }}
                    >
                        {values.m_approval_status !== "cancelled" &&
                            values.m_approval_status !== "approved" && (
                                <AroggaButton
                                    label="Cancel"
                                    type="secondary"
                                    onClick={() => {
                                        setAction("cancelled");
                                        setIsDialogOpen(true);
                                    }}
                                />
                            )}
                        {values.m_approval_status !== "rejected" &&
                            values.m_approval_status !== "approved" && (
                                <>
                                    <span style={{ marginLeft: 8 }} />
                                    <AroggaButton
                                        label="Reject"
                                        type="danger"
                                        onClick={() => {
                                            setAction("rejected");
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                </>
                            )}
                        <span style={{ marginLeft: 8 }} />
                        <AroggaButton
                            label={
                                values.m_approval_status === "approved"
                                    ? "Save"
                                    : "Approve"
                            }
                            type="success"
                            onClick={() => {
                                if (checkPrice()) {
                                    setAction("approved");
                                    setIsDialogOpen(true);
                                } else {
                                    notify(
                                        "MRP mismatch! Discount price should greather than wholesale price",
                                        "error"
                                    );
                                }
                            }}
                        />
                        <ElevatedActionDialog
                            action={action}
                            description={description}
                            open={isDialogOpen}
                            setIsDialogOpen={setIsDialogOpen}
                        />
                    </div>
                </>
            )}
        </>
    );
};

const useStyles = makeStyles(() => ({
    label: {
        color: "#7C8AA0",
        fontSize: 12,
    },
    labelValue: {
        color: "#112950",
        fontSize: 16,
    },
    positionRelative: {
        position: "relative",
    },
    image: {
        margin: "0.5rem",
        maxHeight: "10rem",
    },
}));

export default MedicineEditForm;
