import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    Filter,
    FilterProps,
    ReferenceInput,
} from "react-admin";

import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import UserLocationInput from "../../../components/UserLocationInput";
import CartStatusInput from "../../../components/manageOrder/carts/CartStatusInput";

const CartFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <CartStatusInput source="_status" variant="outlined" alwaysOn />
        <ReferenceInput
            source="u_id"
            label="User"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={(record: { u_name: string; u_mobile: string }) =>
                    !!record ? `${record.u_name} (${record.u_mobile})` : ""
                }
                resettable
            />
        </ReferenceInput>
        <UserLocationInput source="ul_id" label="Location" variant="outlined" />
        <DateInput source="_c_created" label="Cart Date" variant="outlined" />
        <DateInput
            source="_c_created_end"
            label="Cart Date End"
            variant="outlined"
        />
        <DateInput
            source="_c_updated"
            label="Cart Updated Date"
            variant="outlined"
        />
        <DateInput
            source="_c_updated_end"
            label="Cart Updated End"
            variant="outlined"
        />
    </Filter>
);

export default CartFilter;
