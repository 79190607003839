import { SelectInput } from "react-admin";

const OrderInternalStatusInput = ({ choices = [], ...rest }) => (
    <SelectInput
        label="Internal Status"
        choices={[
            { id: "call", name: "Call" },
            { id: "dncall", name: "Do not call" },
            { id: "processing", name: "Processing" },
            { id: "warehouse_fb", name: "Warehouse Feedback" },
            { id: "packing", name: "Packing" },
            { id: "checking", name: "Checking" },
            { id: "confirmed", name: "Packed" },
            { id: "paid", name: "Paid" },
            ...choices,
        ]}
        {...rest}
    />
);

export default OrderInternalStatusInput;
