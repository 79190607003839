import { FC } from "react";
import {
    Edit,
    EditProps,
    SimpleForm,
    TextInput,
    minLength,
    required,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import { useDocumentTitle } from "../../../hooks";

const VocabularyEdit: FC<EditProps> = (props) => {
    useDocumentTitle("Arogga | Vocabulary Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...props}
        >
            <SimpleForm toolbar={<SaveDeleteToolbar isSave />}>
                <TextInput
                    source="v_title"
                    label="Title"
                    variant="outlined"
                    validate={[
                        required(),
                        minLength(
                            5,
                            "Title must be at least 5 characters long"
                        ),
                    ]}
                />
                <TextInput
                    source="v_description"
                    label="Description"
                    variant="outlined"
                    minRows={2}
                    multiline
                />
            </SimpleForm>
        </Edit>
    );
};

export default VocabularyEdit;
