import { TableCellProps, Typography, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useRecordContext } from "ra-core";
import {
    InjectedFieldProps,
    PublicFieldProps,
    sanitizeFieldRestProps,
} from "react-admin";

type TextAlign = TableCellProps["align"];
type SortOrder = "ASC" | "DESC";

const fieldPropTypes = {
    addLabel: PropTypes.bool,
    sortBy: PropTypes.string,
    sortByOrder: PropTypes.oneOf<SortOrder>(["ASC", "DESC"]),
    source: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    sortable: PropTypes.bool,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    textAlign: PropTypes.oneOf<TextAlign>([
        "inherit",
        "left",
        "center",
        "right",
        "justify",
    ]),
    emptyText: PropTypes.string,
};

export interface ImageFieldProps extends PublicFieldProps, InjectedFieldProps {
    src?: string;
    title?: string;
    classes?: object;
    values?: any;
}

const CustomImageField = (props: ImageFieldProps) => {
    const classes = useStyles(props);
    const record = useRecordContext(props);

    const {
        className,
        classes: classesOverride,
        emptyText,
        source,
        src,
        title,
        values,
        ...rest
    } = props;

    const sourceValue = get(record, source);

    const { changed_medicine = {} } = values;
    const { attachedFiles: newfiles = [], deletedFiles = [] } =
        changed_medicine;

    if (!sourceValue) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : (
            <div className={className} {...sanitizeFieldRestProps(rest)} />
        );
    }

    if (Array.isArray(sourceValue)) {
        return (
            <ul
                className={classnames(classes.list, className)}
                {...sanitizeFieldRestProps(rest)}
            >
                {sourceValue.map((file, index) => {
                    const fileTitleValue = get(file, title) || title;
                    const srcValue = get(file, src) || title;

                    return (
                        <li key={index}>
                            <img
                                alt={fileTitleValue}
                                title={fileTitleValue}
                                src={srcValue}
                                className={classes.image}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    const titleValue = get(record, title) || title;

    const renderStatus = () => {
        if (!record?.s3key) return "";
        if (!newfiles) return "Existing";
        if (deletedFiles.find((file) => file.s3key === record?.s3key))
            return "Deleted";
        if (newfiles.find((file) => file.s3key === record?.s3key)) return "New";

        return "Existing";
    };

    const renderColor = () => {
        if (!record?.s3key) return "rgb(23 128 105 / 0%)";
        if (!newfiles) return "rgb(23 128 105)";
        if (deletedFiles?.find((file) => file.s3key === record?.s3key))
            return "red";
        if (newfiles?.find((file) => file.s3key === record?.s3key))
            return "orange";
        return "rgb(23 128 105)";
    };

    return (
        <div
            className={className}
            style={{
                display: "flex",
                flexDirection: "column",
            }}
            {...sanitizeFieldRestProps(rest)}
        >
            <img
                title={titleValue}
                alt={titleValue}
                src={sourceValue}
                style={{
                    borderRadius: 5,
                }}
                className={classes.image}
            />
            <span
                className={classes.image}
                style={{
                    backgroundColor: renderColor(),
                    padding: "4px 8px",
                    color: "white",
                    borderTopLeftRadius: 5,
                    fontWeight: 600,
                    textAlign: "center",
                    position: "absolute",
                    fontSize: 12,
                }}
            >
                {renderStatus()}
            </span>
        </div>
    );
};

const useStyles = makeStyles(
    {
        list: {
            display: "flex",
            listStyleType: "none",
        },
        image: {
            margin: "0.5rem",
            maxHeight: "10rem",
        },
    },
    { name: "RaImageField" }
);

// What? TypeScript loses the displayName if we don't set it explicitly
CustomImageField.displayName = "ImageField";

CustomImageField.defaultProps = {
    addLabel: true,
};

CustomImageField.propTypes = {
    ...fieldPropTypes,
    src: PropTypes.string,
    title: PropTypes.string,
};

export default CustomImageField;
