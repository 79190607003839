import { FC } from "react";
import { FileField, Show, ShowProps, TextField } from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const LedgerShow: FC<ShowProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Ledger Show");

    return (
        <Show {...rest}>
            <ColumnShowLayout md={6}>
                <TextField source="l_created" label="Date" />
                <TextField source="u_name" label="Added By" />
                <TextField source="l_reason" label="Reason" />
                <TextField source="l_type" label="Type" />
                <TextField source="l_method" label="Method" />
                <TextField source="l_amount" label="Amount" />
                <FileField
                    source="attachedFiles"
                    src="src"
                    title="title"
                    target="_blank"
                    label="Related Files"
                />
            </ColumnShowLayout>
        </Show>
    );
};

export default LedgerShow;
