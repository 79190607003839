import { FC, MouseEvent } from "react";
import {
    BooleanField,
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    Record,
    TextField,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import AddressFilter from "./AddressFilter";

const AddressList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Address List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Address"
            perPage={25}
            sort={{ field: "ul_id", order: "DESC" }}
            filters={<AddressFilter children={""} />}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("userLocationDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid rowClick="edit">
                <TextField source="ul_id" label="Id" />
                <FunctionField
                    sortBy="u_id"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    label="User"
                    render={(record: Record) => (
                        <Link to={`/v1/users/${record.u_id}`}>
                            {record.u_id}
                        </Link>
                    )}
                />
                <TextField source="ul_name" label="Name" />
                <TextField source="ul_mobile" label="Mobile" />
                <TextField source="ul_type" label="Type" />
                <TextField source="ul_address" label="Address" />
                <BooleanField
                    source="ul_default"
                    label="Default"
                    looseValue
                    FalseIcon={() => null}
                />
                <TextField source="ul_lat" label="Latitude" />
                <TextField source="ul_long" label="Longitude" />
                <TextField source="ul_location" label="Location" />
            </Datagrid>
        </List>
    );
};

export default AddressList;
