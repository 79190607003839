import { FC } from "react";
import {
    BooleanField,
    NumberField,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const UserLedgerShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | User Ledger Show");

    return (
        <Show {...props}>
            <ColumnShowLayout>
                <NumberField source="ule_id" label="Id" />
                <TextField source="ule_date" label="Date" />
                <NumberField source="u_id" label="User Id" />
                <NumberField source="o_id" label="Order Id" />
                <TextField source="ule_title" label="Title" />
                <TextField source="ule_from" label="From" />
                <NumberField source="ule_amount" label="Amount" />
                <TextField
                    source="ule_withdraw_instruction"
                    label="Withdraw Instruction"
                />
                <BooleanField
                    source="ule_allow_withdraw"
                    label="Allow Withdraw?"
                    looseValue
                />
                <TextField source="ule_status" label="Status" />
                <TextField source="ule_details" label="Details" />
            </ColumnShowLayout>
        </Show>
    );
};

export default UserLedgerShow;
