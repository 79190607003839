import { FC } from "react";
import { Filter, FilterProps, SelectInput, TextInput } from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import ZonesInput from "../../../components/manageDelivery/bags/ZonesInput";

const BagFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <FormatedBooleanInput
            source="_not_assigned"
            label="Not Assigned"
            alwaysOn
        />
        <FormatedBooleanInput
            source="_hide_empty"
            label="Hide Empty"
            alwaysOn
        />
        <ZonesInput source="_zone" variant="outlined" alwaysOn />
        <SelectInput
            source="_de_option"
            label="Delivery Option"
            variant="outlined"
            choices={[
                { id: "regular", name: "Regular" },
                { id: "express", name: "Express" },
            ]}
            alwaysOn
        />
        <FormatedBooleanInput source="_assigned" label="Assigned" />
    </Filter>
);

export default BagFilter;
