import { Record } from "react-admin";

const UserNameRankOptionTextRenderer = ({ record }: { record?: Record }) => {
    if (record?.u_rank)
        return <span>{`${record?.u_name} (${record?.u_rank})`}</span>;

    return <span>{record?.u_name}</span>;
};

export default UserNameRankOptionTextRenderer;
