import { FC } from "react";
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    NumberField,
    Record,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import CollectionFilter from "./CollectionFilter";

const CollectionList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Collection List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Collection"
            filters={<CollectionFilter children={""} />}
            perPage={25}
            sort={{ field: "co_id", order: "DESC" }}
            bulkActionButtons={false}
            exporter={exporter}
            {...rest}
        >
            <Datagrid rowClick="show">
                <TextField source="co_created" label="Date" />
                <TextField
                    source="fm_name"
                    label="From User"
                    sortable={false}
                />
                <TextField source="to_name" label="To User" sortable={false} />
                <NumberField source="co_amount" label="Amount" />
                <NumberField source="co_s_amount" label="Supplier Amount" />
                <NumberField source="profit" label="Profit" />
                <FunctionField
                    label="Orders Count"
                    render={(record: Record) =>
                        `${JSON.parse(record.o_ids)?.length}`
                    }
                />
                <TextField source="co_status" label="Status" />
            </Datagrid>
        </List>
    );
};

export default CollectionList;
