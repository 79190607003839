import { SelectInput } from "react-admin";

const MedicineTypeInput = (props) => (
    <SelectInput
        label="Type"
        choices={[
            { id: "allopathic", name: "Allopathic" },
            { id: "healthcare", name: "Healthcare" },
        ]}
        {...props}
    />
);

export default MedicineTypeInput;
