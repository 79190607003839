import { toFixedNumber } from "../../../utils/helpers";

export const sumOfIndividualPiiTpPriceForPiiIsFixedDiscount = (
    allItems = []
) => {
    return allItems?.reduce((prevValue, currentValue) => {
        if (currentValue?.pii_is_fixed_discount) {
            return prevValue + +currentValue.pii_tp_price;
        }
        return prevValue;
    }, 0);
};

export const sumOfIndividualPiiDiscountForPiiIsFixedDiscount = (
    allItems = []
) => {
    return allItems?.reduce((prevValue, currentValue) => {
        if (currentValue?.pii_is_fixed_discount) {
            return prevValue + +currentValue.pii_discount;
        }
        return prevValue;
    }, 0);
};

export const mutateShapedDiscount = (newAllItems = [], values) => {
    const sumOfIndividualPiiTpPriceWhenPiiIsFixedDiscount =
        sumOfIndividualPiiTpPriceForPiiIsFixedDiscount(newAllItems);

    return newAllItems.forEach((item) => {
        if (item.isFixedDiscount && !item.isDiscountChanged) {
            item.pii_discount = 0;
        } else if (!item.pii_is_fixed_discount) {
            item.pii_discount = toFixedNumber(
                discountBasedOnIsDiscountFixed({
                    tpPrice: item.pii_tp_price,
                    totalTp: values.pi_tp_price,
                    sumOfIndividualPiiTpPriceForPiiIsFixedDiscount:
                        sumOfIndividualPiiTpPriceWhenPiiIsFixedDiscount,
                    totalDiscount:
                        (values.pi_discount || 0) -
                        sumOfIndividualPiiDiscountForPiiIsFixedDiscount(
                            newAllItems
                        ),
                    pi_round: values.pi_round,
                }),
                4
            );
            item.pu_price =
                +item.pii_tp_price + +item.pii_vat - item.pii_discount;
        }
    });
};

export const discountBasedOnIsDiscountFixed = ({
    tpPrice = 0,
    totalTp = 0,
    sumOfIndividualPiiTpPriceForPiiIsFixedDiscount = 0,
    totalDiscount = 0,
    pi_round = 0,
}) => {
    return (
        ((totalDiscount - pi_round) * tpPrice) /
        (totalTp - sumOfIndividualPiiTpPriceForPiiIsFixedDiscount)
    );
};

// If isMrpPercent true, it returns MRP Percentage otherwise, Profit Percentage
export const mrpProfitPercent = ({
    isMrpPercent,
    pu_qty = 0,
    m_price = 0,
    m_d_price = 0,
    pii_tp_price = 0,
    pii_vat = 0,
    pii_discount = 0,
}) => {
    return Math.round(
        ((pu_qty * (isMrpPercent ? +m_price : +m_d_price) -
            (+pii_tp_price + +pii_vat - +pii_discount)) /
            (pu_qty * (isMrpPercent ? +m_price : +m_d_price))) *
            100
    );
};
