import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

const TaxonomyFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <ReferenceInput
            source="_v_id"
            label="Vocabulary"
            variant="outlined"
            reference="v1/vocabulary"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionText="v_title"
                helperText={false}
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default TaxonomyFilter;
