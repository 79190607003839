import { makeStyles } from "@material-ui/core";
import { FC, MouseEvent, useState } from "react";
import {
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    Record,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport, useRequest } from "../../../hooks";
import PendingReorderFilter from "./PendingReorderFilter";

const PendingReorderList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Pending Reorder List");
    const exporter = useExport(rest);

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [reorderId, setReorderId] = useState("");
    const [action, setAction] = useState("");

    const { isLoading, refetch } = useRequest(
        `/v1/orders/pendingReOrder/${reorderId}`,
        {
            method: "POST",
            body: { action },
        },
        {
            isRefresh: true,
            onSuccess: () => setOpen(false),
        }
    );

    return (
        <>
            <List
                {...rest}
                title="List of Pending Reorder"
                perPage={25}
                sort={{ field: "o_id", order: "DESC" }}
                filters={<PendingReorderFilter children={""} />}
                bulkActionButtons={false}
                exporter={exporter}
                {...rest}
            >
                <Datagrid>
                    <FunctionField
                        label="Id"
                        sortBy="o_id"
                        onClick={(e: MouseEvent) => e.stopPropagation()}
                        render={(record: Record) => (
                            <Link to={`/v1/orders/${record.o_id}`}>
                                {record.o_id}
                            </Link>
                        )}
                    />
                    <TextField source="u_name" label="Name" />
                    <TextField source="u_mobile" label="Mobile" />
                    <TextField source="o_created" label="Created" />
                    <TextField source="o_delivered" label="Delivered" />
                    <TextField source="o_total" label="Total" />
                    <TextField source="o_address" label="Address" />
                    <FunctionField
                        label="Action"
                        render={({ ro_status, ro_id }: Record) => {
                            if (ro_status !== "pending") {
                                return (
                                    <span
                                        style={{
                                            color:
                                                ro_status === "approved"
                                                    ? "green"
                                                    : ro_status === "cancelled"
                                                    ? "#EF1962"
                                                    : "",
                                        }}
                                    >
                                        {ro_status}
                                    </span>
                                );
                            }

                            return (
                                <>
                                    <Button
                                        label="Approve"
                                        variant="contained"
                                        className={classes.approveBtn}
                                        onClick={() => {
                                            setOpen(true);
                                            setReorderId(ro_id);
                                            setAction("approve");
                                        }}
                                    />
                                    <Button
                                        label="Cancel"
                                        variant="contained"
                                        color="secondary"
                                        className={classes.cancelBtn}
                                        onClick={() => {
                                            setOpen(true);
                                            setReorderId(ro_id);
                                            setAction("cancel");
                                        }}
                                    />
                                </>
                            );
                        }}
                    />
                </Datagrid>
            </List>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Order#${reorderId}`}
                content={`Are you sure you want to ${action} this reorder?`}
                onConfirm={refetch}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const useStyles = makeStyles({
    approveBtn: {
        marginTop: 4,
        marginBottom: 4,
        marginRight: 10,
    },
    cancelBtn: {
        marginTop: 4,
        marginBottom: 4,
        backgroundColor: "#6c757d",
    },
});

export default PendingReorderList;
