import { FC } from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import OfflineOrderFilter from "./OfflineOrderFilter";

const OfflineOrderList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Offline Order List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Offline Order"
            filters={<OfflineOrderFilter children={""} />}
            perPage={25}
            sort={{ field: "o_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("offlineOrderDelete")}
                />
            }
        >
            <Datagrid rowClick="show">
                <TextField source="o_id" label="Id" />
                <TextField source="o_subtotal" label="Subtotal" />
                <TextField source="o_deduction" label="Discount" />
                <TextField source="o_total" label="Total" />
                <TextField source="o_created" label="Date" />
                <TextField source="o_warehouse_id" label="Warehouse Id" />
            </Datagrid>
        </List>
    );
};

export default OfflineOrderList;
