import { FC } from "react";
import { Datagrid, ImageField, List, ListProps, TextField } from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import BlogFilter from "./BlogFilter";

const BlogList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Blog List");
    const exporter = useExport(rest);
    return (
        <List
            {...rest}
            title="List of Blog"
            filters={<BlogFilter children={""} />}
            perPage={25}
            sort={{ field: "p_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("blogDelete")}
                />
            }
        >
            <Datagrid
                rowClick={permissions?.includes("blogEdit") ? "edit" : ""}
            >
                <TextField source="bp_id" label="Id" />{" "}
                <ImageField
                    source="attachedFiles-bp_images"
                    src="src"
                    className="small__img"
                    title="title"
                    label="Pictures"
                />
                <TextField source="bp_title" label="Title" />
                <TextField source="bp_type" label="Type" />
            </Datagrid>
        </List>
    );
};

export default BlogList;
