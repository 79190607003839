import { useState } from "react";
import {
    AutocompleteInput,
    Button,
    Confirm,
    ReferenceInput,
    SelectInput,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import UserOptionTextRenderer from "../../components/UserOptionTextRenderer";
import { useDocumentTitle, useRequest, useSwitchTo } from "../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../utils/helpers";

const SwitchTo = () => {
    useDocumentTitle("Arogga | Switch User");

    const form = useForm();
    const { values } = useFormState();
    const { isLoading, refetch } = useSwitchTo(values.userId);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>({});

    const { data: roles } = useRequest(
        "/v1/roles",
        {},
        { isPreFetching: true }
    );

    return (
        <>
            <SelectInput
                source="roleName"
                label="Role"
                variant="outlined"
                style={{ width: "256px" }}
                choices={!!roles?.length ? roles : []}
                optionValue="role_name"
                optionText={(item) =>
                    capitalizeFirstLetterOfEachWord(item.role_name)
                }
                onChange={() => form.change("userId", undefined)}
            />
            {values.roleName && (
                <div style={{ width: "256px", marginBottom: "15px" }}>
                    <ReferenceInput
                        source="userId"
                        label="User"
                        variant="outlined"
                        reference="v1/users"
                        filter={{ _role: values.roleName }}
                        filterToQuery={(searchText) => ({
                            _search: searchText,
                        })}
                        fullWidth
                    >
                        <AutocompleteInput
                            matchSuggestion={() => true}
                            optionValue="u_id"
                            helperText={false}
                            optionText={<UserOptionTextRenderer />}
                            inputText={(record: {
                                u_name: string;
                                u_mobile: string;
                            }) =>
                                !!record
                                    ? `${record.u_name} (${record.u_mobile})`
                                    : ""
                            }
                            onSelect={(item) => setSelectedUser(item)}
                        />
                    </ReferenceInput>
                </div>
            )}
            <Button
                label="Switch"
                variant="contained"
                style={{ display: "block" }}
                onClick={() => setIsDialogOpen(true)}
                disabled={!values.roleName || !values.userId}
            />
            <Confirm
                title={`Switch to ${
                    selectedUser.u_name
                        ? selectedUser.u_name
                        : selectedUser.u_mobile
                } (${values.roleName})`}
                content="Are you sure you want to switch?"
                isOpen={isDialogOpen}
                loading={isLoading}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export default SwitchTo;
