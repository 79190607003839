import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { FC } from "react";
import { useNotify } from "react-admin";

import { useClipboard, useRequest } from "../hooks";
import AroggaDialogActions from "./AroggaDialogActions";

type PurchaseRequestDialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    formValues?: any;
    purchaser: string;
    modifiedSelectedItems: any[];
    setUnselect: (unselect: boolean) => void;
    setIsAssignTo: (isAssignTo: boolean) => void;
};

const PurchaseRequestDialog: FC<PurchaseRequestDialogProps> = ({
    open,
    setOpen,
    formValues,
    purchaser,
    modifiedSelectedItems,
    setUnselect,
    setIsAssignTo,
}) => {
    const notify = useNotify();
    const clipboard = useClipboard();

    const filteredSelectedItemsByQtyText = modifiedSelectedItems?.filter(
        (item) => item.qty_text
    );

    const purchaseRequestItems =
        !!filteredSelectedItemsByQtyText?.length &&
        filteredSelectedItemsByQtyText.map(({ m_id, qty_text }) => ({
            m_id,
            qty_text,
        }));

    const copyToClipboardData =
        !!filteredSelectedItemsByQtyText?.length &&
        filteredSelectedItemsByQtyText.map((item, i) => {
            return `${"\n"}${i + 1}. ${item.m_name}${
                !!item.m_form ? ", " : ""
            }${!!item.m_form ? item.m_form : ""}${
                !!item.m_strength ? ", " : ""
            }${!!item.m_strength ? item.m_strength : ""}${
                !!item.qty_text ? ", " : ""
            }${!!item.qty_text ? item.qty_text : ""}`;
        });

    const { isLoading, refetch } = useRequest(
        "/v1/laterMedicines/savePurchaseRequest/",
        {
            method: "POST",
            body: {
                purchaseRequest: JSON.stringify(purchaseRequestItems),
                u_id: formValues?.u_id,
            },
        },
        {
            onSuccess: () => {
                setUnselect(true);
                setIsAssignTo(false);
                setOpen(false);
            },
        }
    );

    const copyToClipboard = (data) => {
        if (!data) return;

        clipboard.copy(data.toString());
        notify("Copied to clipboard!", { type: "success" });
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between">
                    <Typography>Are you want to save this?</Typography>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => copyToClipboard(copyToClipboardData)}
                    >
                        <FileCopyOutlinedIcon />
                    </div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Form</TableCell>
                            <TableCell>Strength</TableCell>
                            <TableCell>Quantity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!filteredSelectedItemsByQtyText?.length &&
                            filteredSelectedItemsByQtyText.map((item, i) => (
                                <TableRow key={item.id}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>{item.m_name}</TableCell>
                                    <TableCell>{item.m_form}</TableCell>
                                    <TableCell>{item.m_strength}</TableCell>
                                    <TableCell>{item.qty_text}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <Box mt={2} />
                <Typography>Assign to: {purchaser}</Typography>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={() => setOpen(false)}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default PurchaseRequestDialog;
