const QtyLabel = ({ qty, medicine }): any => {
    if (!qty || !medicine) return "";

    if (medicine?.m_form === medicine?.m_unit) {
        const s = qty === 1 ? "" : "s";
        return `${qty} ${medicine?.m_unit}${s}`;
    }

    const match = medicine?.m_unit?.match(/([0-9]+){1,3}/);
    const qty2 = match ? match[0] : 0;

    if (qty2 && `${qty2} ${medicine?.m_form}s` === medicine?.m_unit) {
        return `${qty * qty2} ${medicine?.m_form}s`;
    }

    return `${qty} * ${medicine?.m_unit}`;
};

export default QtyLabel;
