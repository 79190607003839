import {
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FC } from "react";
import { useFormState } from "react-final-form";

import { isInfinity, toFixedNumber } from "../../../../utils/helpers";
import QtyLabel from "../../../QtyLabel";
import { mrpProfitPercent, mutateShapedDiscount } from "../utils";
import TableFooter from "./TableFooter";
import TableHeader from "./TableHeader";

type InvoiceTableProps = {
    isKeyboardPressed?: boolean;
    setIsKeyboardPressed?: (isKeyboardPressed: boolean) => void;
    allItems: any[];
    setAllItems: (allItems) => void;
};

const InvoiceTable: FC<InvoiceTableProps> = ({
    isKeyboardPressed,
    setIsKeyboardPressed,
    allItems,
    setAllItems,
}) => {
    const classes = useStyles();
    const { values } = useFormState();

    let totalIndividualPiiTPPrice = 0;
    let totalIndividualPiiVat = 0;
    let totalIndividualPiiDiscount = 0;
    let totalIndividualPuPrice = 0;
    let totalIndividualMRP = 0;

    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.target.blur();
        } else if (
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight" &&
            e.key !== "." &&
            isNaN(e.key)
        ) {
            e.preventDefault();
        }
    };

    const handleOnBlur = ({ value, medicineId, field }) => {
        const newAllItems = [...allItems];
        const findIndex = newAllItems.findIndex(
            (item) => item.pu_m_id === medicineId
        );
        newAllItems[findIndex][field] = value;
        if (
            field === "pii_tp_price" ||
            field === "pii_vat" ||
            field === "pii_discount"
        ) {
            const { pii_tp_price, pii_vat, pii_discount } =
                newAllItems[findIndex] || {};

            newAllItems[findIndex].pu_price =
                +pii_tp_price + +pii_vat - +pii_discount;
        }
        if (field === "pii_discount") {
            newAllItems[findIndex]["isDiscountChanged"] = true;
            mutateShapedDiscount(newAllItems, values);
        }
        setAllItems(newAllItems);
    };

    const handleDiscountFixed = ({ event, field, index }) => {
        const newAllItems = [...allItems];
        newAllItems[index][field] = event.target.checked ? 1 : 0;
        newAllItems[index]["isFixedDiscount"] = event.target.checked;

        if (!event.target.checked) {
            newAllItems[index]["isDiscountChanged"] = false;
        }
        mutateShapedDiscount(newAllItems, values);
        setAllItems(newAllItems);
        !isKeyboardPressed && setIsKeyboardPressed(true);
    };

    const handleRemove = (i) => {
        let newAllItems = [...allItems];
        newAllItems.splice(i, 1);
        setAllItems(newAllItems);
        !isKeyboardPressed && setIsKeyboardPressed(true);
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHeader />
                <TableBody>
                    {!!allItems?.length &&
                        allItems.map((item, i) => {
                            totalIndividualPiiTPPrice += +item.pii_tp_price;
                            totalIndividualPiiVat += +item.pii_vat;
                            totalIndividualPiiDiscount += +item.pii_discount;
                            totalIndividualPuPrice += +item.pu_price;
                            const mrp = toFixedNumber(
                                item.pu_qty * item.m_price
                            );
                            totalIndividualMRP += +mrp;
                            values.totalIndividualPiiTPPrice =
                                toFixedNumber(totalIndividualPiiTPPrice) || 0;
                            values.totalIndividualPiiVat =
                                toFixedNumber(totalIndividualPiiVat) || 0;
                            values.totalIndividualPiiDiscount =
                                toFixedNumber(totalIndividualPiiDiscount) || 0;
                            values.totalIndividualPuPrice =
                                toFixedNumber(totalIndividualPuPrice) || 0;
                            values.totalIndividualMRP = totalIndividualMRP || 0;

                            values.isAmountMismatched =
                                values.pi_tp_price !==
                                    toFixedNumber(totalIndividualPiiTPPrice) ||
                                (values.pi_vat || 0) !==
                                    toFixedNumber(totalIndividualPiiVat) ||
                                (values.pi_discount || 0) -
                                    (values.pi_round || 0) !==
                                    values.totalIndividualPiiDiscount ||
                                values.pi_inv_price !==
                                    values.totalIndividualPuPrice;

                            const mrpPercent = mrpProfitPercent({
                                isMrpPercent: true,
                                pu_qty: item.pu_qty,
                                m_price: item.m_price,
                                pii_tp_price: item.pii_tp_price,
                                pii_vat: item.pii_vat,
                                pii_discount: item.pii_discount,
                            });

                            const profitPercent = mrpProfitPercent({
                                isMrpPercent: false,
                                pu_qty: item.pu_qty,
                                m_d_price: item.m_d_price,
                                pii_tp_price: item.pii_tp_price,
                                pii_vat: item.pii_vat,
                                pii_discount: item.pii_discount,
                            });

                            return (
                                <TableRow key={allItems.length - i}>
                                    <TableCell>{allItems.length - i}</TableCell>
                                    <TableCell>{item.m_name}</TableCell>
                                    <TableCell>{item.m_form}</TableCell>
                                    <TableCell>{item.m_strength}</TableCell>
                                    <TableCell>{item.m_unit}</TableCell>
                                    <TableCell
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.pu_m_id,
                                                field: "pu_qty",
                                            })
                                        }
                                    >
                                        {item.pu_qty}
                                    </TableCell>
                                    <TableCell>
                                        <QtyLabel
                                            qty={item.pu_qty}
                                            medicine={item}
                                        />
                                    </TableCell>
                                    <TableCell
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.pu_m_id,
                                                field: "pii_tp_price",
                                            })
                                        }
                                    >
                                        {toFixedNumber(item.pii_tp_price)}
                                    </TableCell>
                                    <TableCell
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.pu_m_id,
                                                field: "pii_vat",
                                            })
                                        }
                                    >
                                        {item.pii_vat}
                                    </TableCell>
                                    <TableCell
                                        contentEditable={
                                            !!item.pii_is_fixed_discount
                                        }
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.pu_m_id,
                                                field: "pii_discount",
                                            })
                                        }
                                    >
                                        {item.pii_discount}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                !!item.pii_is_fixed_discount
                                            }
                                            onChange={(e) =>
                                                handleDiscountFixed({
                                                    event: e,
                                                    field: "pii_is_fixed_discount",
                                                    index: i,
                                                })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {toFixedNumber(item.pu_price)}
                                    </TableCell>
                                    <TableCell>{mrp}</TableCell>
                                    <TableCell
                                        className={
                                            mrpPercent < 0 || mrpPercent > 50
                                                ? classes.fontRed
                                                : classes.fontGreen
                                        }
                                    >
                                        {!!mrpPercent && !isInfinity(mrpPercent)
                                            ? `${mrpPercent}%`
                                            : ""}
                                    </TableCell>
                                    <TableCell
                                        className={
                                            profitPercent < 0 ||
                                            profitPercent > 50
                                                ? classes.fontRed
                                                : classes.fontGreen
                                        }
                                    >
                                        {!!profitPercent &&
                                        !isInfinity(profitPercent)
                                            ? `${profitPercent}%`
                                            : ""}
                                    </TableCell>
                                    <TableCell>
                                        {!!item.m_multiplier &&
                                            item.m_multiplier}
                                        {!!item.new_multiplier && (
                                            <span> - </span>
                                        )}
                                        {!!item.new_multiplier && (
                                            <span className={classes.fontRed}>
                                                {item.new_multiplier}
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell>{item.m_expiry}</TableCell>
                                    <TableCell>{item.m_batch}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleRemove(i)}>
                                            <HighlightOffIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
                <TableFooter />
            </Table>
        </TableContainer>
    );
};

const useStyles = makeStyles({
    fontRed: {
        color: "#EF1962",
    },
    fontGreen: {
        color: "green",
    },
});

export default InvoiceTable;
