import { FC } from "react";
import {
    AutocompleteInput,
    Edit,
    EditProps,
    FunctionField,
    Record,
    ReferenceInput,
    SimpleForm,
    TextInput,
    minLength,
    required,
    useDataProvider,
    useNotify,
    useRedirect,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import AllowedRoles from "../../../components/manageTaxonomy/AllowedRoles";
import { useDocumentTitle } from "../../../hooks";

const TaxonomyEdit: FC<EditProps> = (props) => {
    useDocumentTitle("Arogga | Taxonomy Term Edit");

    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const onSave = async (values) => {
        if (!values.t_allowed_roles?.length)
            return notify("Allowed roles is required!", {
                type: "error",
            });

        dataProvider
            // @ts-ignore
            .update("v1/taxonomy", {
                id: values.id,
                data: values,
            })
            .then(() => {
                notify("Successfully updated!", { type: "success" });
                redirect("list", "/v1/taxonomy");
            })
            .catch((err) =>
                notify(
                    err?.message || "Something went wrong, Please try again!",
                    {
                        type: "warning",
                    }
                )
            );
    };

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...props}
        >
            <SimpleForm toolbar={<SaveDeleteToolbar isSave />} save={onSave}>
                <ReferenceInput
                    source="t_v_id"
                    label="Vocabulary"
                    variant="outlined"
                    reference="v1/vocabulary"
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionText="v_title"
                        resettable
                    />
                </ReferenceInput>
                <TextInput
                    source="t_title"
                    label="Title"
                    variant="outlined"
                    validate={[
                        required(),
                        minLength(
                            3,
                            "Title must be at least 3 characters long"
                        ),
                    ]}
                />
                <TextInput
                    source="t_description"
                    label="Description"
                    variant="outlined"
                    validate={[
                        required(),
                        minLength(
                            5,
                            "Description must be at least 5 characters long"
                        ),
                    ]}
                    minRows={2}
                    multiline
                />
                <FunctionField
                    label="Allowed Roles"
                    render={({ t_allowed_roles }: Record) => (
                        <AllowedRoles
                            allowedRolesFromRecord={t_allowed_roles}
                        />
                    )}
                />
            </SimpleForm>
        </Edit>
    );
};

export default TaxonomyEdit;
