import { Box, Grid, Button as MButton, makeStyles } from "@material-ui/core";
import { FC, useState } from "react";
import {
    BooleanInput,
    Confirm,
    DateTimeInput,
    FileField,
    NumberInput,
    TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";

import AroggaButton from "../../../../components/AroggaButton";
import DeliveryOptionInput from "../../../../components/manageOrder/orders/DeliveryOptionInput";
import OrderIssueStatusInput from "../../../../components/manageOrder/orders/OrderIssueStatusInput";
import SendNotifyDialog from "../../../../components/manageOrder/orders/SendNotifyDialog";
import ToProcessingCancelOrderDialog from "../../../../components/manageOrder/orders/ToProcessingCancelOrderDialog";
import { useRequest } from "../../../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../../../utils/helpers";

type SetupTabProps = {
    record?: any;
    permissions: string[];
    [key: string]: any;
};

const SetupTab: FC<SetupTabProps> = ({ permissions, ...rest }) => {
    const classes = useStyles();
    const { values } = useFormState();

    const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
    const [
        isRejectCancelRequestDialogOpen,
        setIsRejectCancelRequestDialogOpen,
    ] = useState(false);
    const [isCancelOrderDialogOpen, setIsCancelOrderDialogOpen] =
        useState(false);
    const [isReturnOrderDialogOpen, setIsReturnOrderDialogOpen] =
        useState(false);
    const [action, setAction] = useState("");

    const { record } = rest;

    const { data: deliveryUsers } = useRequest(
        `/v1/users?ids=${values.o_de_id}`,
        {},
        { isPreFetching: true }
    );

    const { data: bags } = useRequest(
        `/v1/bags?ids=${values.bag_id}`,
        {},
        { isPreFetching: values.bag_id }
    );

    const {
        isLoading: isLoadingRejectCancelRequest,
        refetch: handleRejectCancelRequest,
    } = useRequest(
        `/v1/orders/falseCall/${record?.o_id}`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => setIsRejectCancelRequestDialogOpen(false),
        }
    );

    const { isLoading: isLoadingReturnOrder, refetch: returnOrder } =
        useRequest(
            `/v1/tinyUpdate/order/${values.o_id}`,
            {
                method: "POST",
                body: {
                    o_status: "returned",
                },
            },
            {
                successNotify: "Successfully returned this order",
                isRefresh: true,
                onSuccess: () => setIsReturnOrderDialogOpen(false),
            }
        );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={6} md={3}>
                    <p className={classes.label}>Status</p>
                    <p className={classes.labelValue}>
                        {capitalizeFirstLetterOfEachWord(values.o_status)}
                    </p>
                </Grid>
                <Grid item sm={6} md={3}>
                    <p className={classes.label}>Internal Status</p>
                    <p className={classes.labelValue}>
                        {values.o_i_status === "confirmed"
                            ? "Packed"
                            : capitalizeFirstLetterOfEachWord(
                                  values.o_i_status
                              )}
                    </p>
                </Grid>
                <Grid item sm={6} md={3} style={{ display: "flex" }}>
                    <Box>
                        <p className={classes.label}>Delivery Man</p>
                        <p className={classes.labelValue}>
                            {deliveryUsers?.[0]?.u_name}
                        </p>
                    </Box>
                    {rest.record?.o_status === "delivering" && (
                        <MButton
                            variant="contained"
                            className={classes.sendNotifyBtn}
                            onClick={() => setIsNotifyDialogOpen(true)}
                            disableElevation
                        >
                            Send Notify
                        </MButton>
                    )}
                    <SendNotifyDialog
                        open={isNotifyDialogOpen}
                        handleNotifyDialogClose={() =>
                            setIsNotifyDialogOpen(false)
                        }
                        {...rest}
                    />
                </Grid>
                <Grid item sm={6} md={3}>
                    <p className={classes.label}>Bag</p>
                    <p className={classes.labelValue}>
                        {values.bag_id && !!bags?.[0]?.b_zone && bags?.[0]?.b_no
                            ? `${bags?.[0].b_zone}-${bags?.[0].b_no}`
                            : "Not Assign"}
                    </p>
                </Grid>
                <Grid item sm={6} md={3}>
                    <OrderIssueStatusInput
                        source="o_is_status"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item sm={6} md={3}>
                    <DeliveryOptionInput
                        source="o_de_option"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                {values?.o_de_option === "express" && (
                    <Grid item sm={6} md={3}>
                        <DateTimeInput
                            source="o_de_time"
                            label="Delivery Date"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                )}
                {values.o_status !== "delivered" && (
                    <Grid item sm={6} md={3} style={{ display: "flex" }}>
                        <BooleanInput source="isUrgent" label="Urgent?" />
                        <Box mt={1}>
                            <FileField
                                source="invoiceUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                label="Invoice"
                                title="Invoice"
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2} style={{ position: "relative" }}>
                <Grid item sm={6} md={3}>
                    <TextInput
                        source="o_note"
                        label="Note (Visible to Customer)"
                        variant="outlined"
                        minRows={2}
                        multiline
                        fullWidth
                    />
                </Grid>
                <Grid item sm={6} md={3}>
                    <TextInput
                        source="o_i_note"
                        label="Internal Note (Not visible to Customer)"
                        variant="outlined"
                        minRows={2}
                        multiline
                        fullWidth
                    />
                </Grid>
                {permissions?.includes("processOrderRefund") &&
                    record.o_status === "delivered" && (
                        <Grid item sm={6} md={3}>
                            <NumberInput
                                source="refund"
                                label="Refund"
                                variant="outlined"
                            />
                        </Grid>
                    )}
                <Grid item sm={6} md={3}>
                    <div
                        style={{
                            position: "absolute",
                            bottom: 37,
                            right: 15,
                        }}
                    >
                        {record?.o_status !== "cancelled" &&
                            !!record?.oe_cancel_request_by && (
                                <>
                                    <AroggaButton
                                        label="Reject Cancel Request"
                                        type="danger"
                                        onClick={() =>
                                            setIsRejectCancelRequestDialogOpen(
                                                true
                                            )
                                        }
                                    />
                                    <Confirm
                                        isOpen={isRejectCancelRequestDialogOpen}
                                        loading={isLoadingRejectCancelRequest}
                                        title={`Are you sure you want to reject cancel request
						 this order? #${record?.o_id}`}
                                        content={false}
                                        onConfirm={handleRejectCancelRequest}
                                        onClose={() =>
                                            setIsRejectCancelRequestDialogOpen(
                                                false
                                            )
                                        }
                                    />
                                </>
                            )}
                        {permissions?.includes(
                            "orderStatusCancelledToProcessing"
                        ) &&
                            record?.o_status === "cancelled" && (
                                <AroggaButton
                                    label="Move to processing"
                                    type="success"
                                    onClick={() => {
                                        setAction("toProcessing");
                                        setIsCancelOrderDialogOpen(true);
                                    }}
                                />
                            )}
                        {(permissions?.includes(
                            "orderStatusProcessingToCancelled"
                        ) ||
                            permissions?.includes(
                                "orderStatusConfirmedToCancelled"
                            ) ||
                            permissions?.includes(
                                "orderStatusDeliveringToCancelled"
                            ) ||
                            permissions?.includes(
                                "orderStatusPackedToCancelled"
                            )) &&
                            (record?.o_status === "processing" ||
                                record?.o_status === "confirmed" ||
                                record?.o_status === "delivering" ||
                                record?.o_status === "packed") && (
                                <AroggaButton
                                    label="Cancel this order"
                                    type="danger"
                                    onClick={() => {
                                        setAction("toCancelled");
                                        setIsCancelOrderDialogOpen(true);
                                    }}
                                />
                            )}
                        <ToProcessingCancelOrderDialog
                            action={action}
                            open={isCancelOrderDialogOpen}
                            handleDialogClose={() =>
                                setIsCancelOrderDialogOpen(false)
                            }
                            {...rest}
                        />
                        {record?.o_status === "delivered" && (
                            <AroggaButton
                                label="Return this order"
                                type="success"
                                onClick={() => setIsReturnOrderDialogOpen(true)}
                            />
                        )}
                        <Confirm
                            title="Return Order"
                            content="Are you sure you want to return this order?"
                            isOpen={isReturnOrderDialogOpen}
                            loading={isLoadingReturnOrder}
                            onConfirm={returnOrder}
                            onClose={() => setIsReturnOrderDialogOpen(false)}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles(() => ({
    label: {
        color: "#7C8AA0",
        fontSize: 12,
    },
    labelValue: {
        color: "#112950",
        fontSize: 16,
    },
    sendNotifyBtn: {
        marginTop: 8,
        marginLeft: 5,
        padding: 5,
        height: 30,
        background: "#DCFAF2",
        border: "0.5px solid #3ECBA5",
        borderRadius: "4px",
        color: "#008069",
    },
}));

export default SetupTab;
