import {
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import { FC, useState } from "react";
import {
    AutocompleteInput,
    BulkActionProps,
    BulkDeleteButton,
    Button,
    ReferenceInput,
    SimpleForm,
    Toolbar,
    usePermissions,
    useRefresh,
    useUnselectAll,
} from "react-admin";
import { FormSpy } from "react-final-form";

import BarcodeDialog from "../../../components/manageOrder/orders/BarcodeDialog";
import useRequest from "../../../hooks/useRequest";
import { required } from "../../../utils/helpers";

const OrderListBulkActionButtons: FC<BulkActionProps> = ({
    resource,
    selectedIds,
    ...rest
}) => {
    const { permissions } = usePermissions();
    const classes = useStyles();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    const [isEnableDialogOpen, setIsEnableDialogOpen] = useState(false);
    const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
    const [isChangeDeliverymanDialogOpen, setIsChangeDeliverymanDialogOpen] =
        useState(false);
    const [deliveryManId, setDeliveryManId] = useState("");

    const { refetch } = useRequest(
        "/v1/orders/updateMany",
        {
            method: "POST",
            body: {
                ids: selectedIds,
                "data[action]": "changeDeMan",
                "data[o_de_id]": deliveryManId,
            },
        },
        {
            onSuccess: () => {
                refresh();
                unselectAll(resource);
            },
        }
    );

    const handleDialogConfirm = () => {
        refetch();
        setIsChangeDeliverymanDialogOpen(false);
    };

    const ConfirmToolbar = (props) => (
        <Toolbar {...props} disableGutters className={classes.toolbarPosition}>
            <Button
                label="Cancel"
                onClick={() => setIsChangeDeliverymanDialogOpen(false)}
            />
            <Button
                label="Confirm"
                variant="outlined"
                onClick={handleDialogConfirm}
            />
        </Toolbar>
    );

    return (
        <>
            <Button
                label="Enable Barcode"
                onClick={() => setIsEnableDialogOpen(true)}
            />
            <BarcodeDialog
                resource={resource}
                selectedIds={selectedIds}
                action="enableBarcode"
                open={isEnableDialogOpen}
                handleClose={() => setIsEnableDialogOpen(false)}
            />
            <Button
                label="Disable Barcode"
                onClick={() => setIsDisableDialogOpen(true)}
            />
            <BarcodeDialog
                resource={resource}
                selectedIds={selectedIds}
                action="disableBarcode"
                open={isDisableDialogOpen}
                handleClose={() => setIsDisableDialogOpen(false)}
            />
            <Button
                label="Change Deliveryman"
                onClick={() => setIsChangeDeliverymanDialogOpen(true)}
            />
            {permissions?.includes("orderDelete") && (
                <BulkDeleteButton {...rest} />
            )}
            <Dialog
                open={isChangeDeliverymanDialogOpen}
                onClose={() => setIsChangeDeliverymanDialogOpen(false)}
            >
                <DialogTitle>Change Deliveryman</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        toolbar={<ConfirmToolbar />}
                        classes={{ form: classes.form }}
                    >
                        <ReferenceInput
                            source="o_de_id"
                            label="Delivery Man"
                            variant="outlined"
                            reference="v1/users"
                            filter={{ _role: "delivery" }}
                            filterToQuery={(searchText) => ({
                                _search: searchText,
                            })}
                            validate={[required()]}
                        >
                            <AutocompleteInput
                                optionValue="u_id"
                                optionText="u_name"
                                resettable
                            />
                        </ReferenceInput>
                        <FormSpy
                            subscription={{ values: true }}
                            onChange={({ values }) =>
                                // Fix bad setState() call inside `FormSpy` error using setTimeout
                                setTimeout(() => {
                                    setDeliveryManId(values.o_de_id);
                                }, 0)
                            }
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles({
    form: {
        padding: 0,
    },
    toolbarPosition: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "15px",
    },
});

export default OrderListBulkActionButtons;
