import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import LedgerMethodInput from "../../../components/manageFinance/ledgers/LedgerMethodInput";

const LedgerFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <DateInput source="_created" label="Date" variant="outlined" />
        <DateInput source="_created_end" label="Date End" variant="outlined" />
        <TaxonomiesByVocabularyInput
            fetchKey="ledger_types"
            source="_type"
            label="Type"
            title
        />
        <LedgerMethodInput
            source="_method"
            label="Method"
            variant="outlined"
            defaultValue="Cash"
            choices={[
                {
                    id: "Payable-paid",
                    name: "Payable-paid",
                },
            ]}
        />
        <ReferenceInput
            source="_u_id"
            label="User"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={(record: { u_name: string; u_mobile: string }) =>
                    !!record ? `${record.u_name} (${record.u_mobile})` : ""
                }
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default LedgerFilter;
