import { FC, useState } from "react";
import {
    Show,
    ShowProps,
    SimpleForm,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import MedicineForm from "../../../components/manageOrder/orders/MedicineForm";
import MedicineTable from "../../../components/manageOrder/orders/medicineTable";
import { useDocumentTitle } from "../../../hooks";

const OfflineOrderShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Offline Order Show");

    const [allItems, setAllItems] = useState([]);

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout simpleShowLayout={false}>
                    <TextField source="o_id" label="Id" />
                    <TextField source="o_subtotal" label="Subtotal" />
                    <TextField source="o_addition" label="Addition" />
                    <TextField source="o_deduction" label="Deduction" />
                    <TextField source="o_total" label="Total" />
                </ColumnShowLayout>
                <SimpleForm toolbar={null}>
                    <MedicineTable
                        page="offlineOrderShow"
                        allItems={allItems}
                        setAllItems={setAllItems}
                    />
                    <MedicineForm
                        page="offlineOrderShow"
                        allItems={allItems}
                        setAllItems={setAllItems}
                    />
                </SimpleForm>
            </SimpleShowLayout>
        </Show>
    );
};

export default OfflineOrderShow;
