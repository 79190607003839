import { FC } from "react";
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import WarehouseFilter from "./WarehouseFilter";

const WarehouseList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Warehouse List");

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Warehouse"
            filters={<WarehouseFilter children={""} />}
            perPage={25}
            sort={{ field: "w_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="edit">
                <TextField source="w_id" label="Id" />
                <TextField source="w_title" label="Title" />
                <TextField source="w_lat" label="Latitude" />
                <TextField source="w_lon" label="Longitude" />
                <ReferenceField
                    source="w_l_id"
                    label="Location"
                    reference="v1/locations"
                    sortBy="w_l_id"
                    link={false}
                >
                    <FunctionField
                        render={(record: Record) =>
                            !!record
                                ? `${record.l_division} -> ${record.l_district} -> ${record.l_area}`
                                : ""
                        }
                    />
                </ReferenceField>
                <TextField source="w_address" label="Address" />
            </Datagrid>
        </List>
    );
};

export default WarehouseList;
