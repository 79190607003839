import { FC } from "react";
import { Filter, FilterProps, SelectInput } from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";

const PendingReorderFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <FormatedBooleanInput source="_only_pending" label="Pending" alwaysOn />
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "approved", name: "Approved" },
                { id: "cancelled", name: "Cancelled" },
            ]}
        />
    </Filter>
);

export default PendingReorderFilter;
