import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FC } from "react";
import {
    AutocompleteInput,
    Button,
    ReferenceInput,
    TextInput,
} from "react-admin";
import { FormSpy } from "react-final-form";

import { useDocumentTitle, useFocusNext } from "../hooks";
import { required } from "../utils/helpers";
import QtyLabel from "./QtyLabel";

type MedicinesAssignToProps = {
    selectedItems: any[];
    setSelectedItems: (items: object[]) => void;
    setFormValues: (values: object) => void;
    setPurchaser: (purchaser: string) => void;
};

const MedicinesAssignTo: FC<MedicinesAssignToProps> = ({
    selectedItems,
    setSelectedItems,
    setFormValues,
    setPurchaser,
}) => {
    useDocumentTitle("Arogga | Inventory Assign");

    const focusNextRef = useFocusNext();

    const RemoveRow = ({ m_id }) => {
        const handleClickRowRemove = () => {
            const updatedAllItems =
                !!selectedItems?.length &&
                selectedItems.filter((item) => item.m_id !== m_id);
            setSelectedItems(updatedAllItems);
        };

        return (
            <Button onClick={handleClickRowRemove}>
                <HighlightOffIcon />
            </Button>
        );
    };

    return (
        <TableContainer>
            {!!selectedItems?.length && (
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Form</TableCell>
                            <TableCell>Strenght</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Wkly Req</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!selectedItems?.length &&
                            selectedItems.map((item, i) => (
                                <TableRow key={item.id}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>{item.m_name}</TableCell>
                                    <TableCell>{item.m_form}</TableCell>
                                    <TableCell>{item.m_strength}</TableCell>
                                    <TableCell>
                                        <QtyLabel
                                            qty={item.total_qty}
                                            medicine={item}
                                        />
                                    </TableCell>
                                    <TableCell>{item.m_unit}</TableCell>
                                    <TableCell>
                                        <QtyLabel
                                            qty={item.wkly_req}
                                            medicine={item}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            source={`qty_text-${item.id}`}
                                            label=""
                                            variant="outlined"
                                            inputRef={focusNextRef}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RemoveRow m_id={item.m_id} />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            )}
            <Box mt={2} mb={1}>
                <Typography>Assign to:</Typography>
            </Box>
            <ReferenceInput
                source="u_id"
                label="Purchaser"
                variant="outlined"
                reference="v1/users"
                filter={{ _role: "purchaser" }}
                filterToQuery={(searchText) => ({
                    _search: searchText,
                })}
                onSelect={(item) => setPurchaser(item?.u_name)}
                validate={[required()]}
            >
                <AutocompleteInput
                    optionValue="u_id"
                    optionText="u_name"
                    resettable
                />
            </ReferenceInput>
            <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) =>
                    // Fix bad setState() call inside `FormSpy` error using setTimeout
                    setTimeout(() => {
                        setFormValues(values);
                    }, 0)
                }
            />
        </TableContainer>
    );
};

export default MedicinesAssignTo;
