import { FC, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { useIdleTimer } from "react-idle-timer";

import packageJson from "../package.json";
import authProvider from "./authProvider";
import {
    DashIcon,
    ManageB2BIcon,
    ManageDatabaseIcon,
    ManageDeliveryIcon,
    ManageFinanceIcon,
    ManageHRIcon,
    ManageOrderIcon,
    ManagePromotionsIcon,
    ManagePurchaseIcon,
    ManageStockIcon,
    ManageTaxonomyIcon,
    ManageUserIcon,
    OtherSettingsIcon,
} from "./components/icons";
import customRoutes from "./customRoutes";
import dataProvider from "./dataProviderFileUpload";
import { useCacheBuster, useKeyboardShortcut } from "./hooks";
import MyLayout from "./layout";
import ShowMenu from "./layout/ShowMenu";
import Dashboard from "./pages/dashboard";
import MyLoginPage from "./pages/loginPage";
import {
    PharmacyCreate,
    PharmacyEdit,
    PharmacyList,
    PharmacyShow,
} from "./pages/manageB2B/pharmacy";
import {
    CompanyCreate,
    CompanyEdit,
    CompanyList,
    CompanyShow,
} from "./pages/manageDatabase/companies";
import {
    GenericCreate,
    GenericEdit,
    GenericList,
    GenericShow,
} from "./pages/manageDatabase/generics";
import {
    MedicineCreate,
    MedicineEdit,
    MedicineList,
    MedicineShow,
} from "./pages/manageDatabase/medicines";
import {
    BagCreate,
    BagEdit,
    BagList,
    BagShow,
} from "./pages/manageDelivery/bags";
import {
    CollectionList,
    CollectionShow,
} from "./pages/manageFinance/collections";
import {
    DailyReportCreate,
    DailyReportEdit,
    DailyReportList,
    DailyReportShow,
} from "./pages/manageFinance/dailyReports";
import {
    LedgerCreate,
    LedgerEdit,
    LedgerList,
    LedgerShow,
} from "./pages/manageFinance/ledgers";
import {
    UserLedgerCreate,
    UserLedgerEdit,
    UserLedgerList,
    UserLedgerShow,
} from "./pages/manageFinance/userLedgers";
import {
    ApplicantCreate,
    ApplicantEdit,
    ApplicantList,
    ApplicantShow,
} from "./pages/manageHR/applicants";
import {
    AttendanceEdit,
    AttendanceList,
    AttendanceShow,
} from "./pages/manageHR/attendances";
import {
    BankCreate,
    BankEdit,
    BankList,
    BankShow,
} from "./pages/manageHR/banks";
import {
    EmployeeInfoCreate,
    EmployeeInfoEdit,
    EmployeeInfoList,
    EmployeeInfoShow,
} from "./pages/manageHR/employeeInfos";
import {
    EmployeeLeaveCreate,
    EmployeeLeaveList,
} from "./pages/manageHR/employeeLeaves";
import {
    EmployeeLoanCreate,
    EmployeeLoanEdit,
    EmployeeLoanList,
    EmployeeLoanShow,
} from "./pages/manageHR/employeeLoans";
import {
    EmployeeCreate,
    EmployeeEdit,
    EmployeeList,
    EmployeeShow,
} from "./pages/manageHR/employees";
import {
    HolidayCreate,
    HolidayEdit,
    HolidayList,
    HolidayShow,
} from "./pages/manageHR/holidays";
import { JobCreate, JobEdit, JobList, JobShow } from "./pages/manageHR/jobs";
import {
    SalaryCreate,
    SalaryEdit,
    SalaryList,
    SalaryShow,
} from "./pages/manageHR/salaries";
import {
    ShiftScheduleEdit,
    ShiftScheduleList,
    ShiftScheduleShow,
} from "./pages/manageHR/shiftSchedules";
import {
    ShiftCreate,
    ShiftEdit,
    ShiftList,
    ShiftShow,
} from "./pages/manageHR/shifts";
import {
    WarehouseCreate,
    WarehouseEdit,
    WarehouseList,
    WarehouseShow,
} from "./pages/manageHR/warehouses";
import {
    CartCreate,
    CartEdit,
    CartList,
    CartShow,
} from "./pages/manageOrder/carts";
import { LaterMedicinesList } from "./pages/manageOrder/laterMedicines";
import {
    OfflineOrderCreate,
    OfflineOrderEdit,
    OfflineOrderList,
    OfflineOrderShow,
} from "./pages/manageOrder/offlineOrders";
import {
    OrderMedicinesEdit,
    OrderMedicinesList,
} from "./pages/manageOrder/orderMedicines";
import {
    OrderCreate,
    OrderEdit,
    OrderList,
    OrderShow,
} from "./pages/manageOrder/orders";
import { PendingReorderList } from "./pages/manageOrder/pendingReorders";
import { PurchaseInvoiceItemList } from "./pages/managePurchase/purchaseInvoiceItems";
import {
    PurchaseInvoiceCreate,
    PurchaseInvoiceEdit,
    PurchaseInvoiceList,
} from "./pages/managePurchase/purchaseInvoices";
import { PurchaseRequestList } from "./pages/managePurchase/purchasesRequests";
import {
    InventoryEdit,
    InventoryList,
    InventoryShow,
} from "./pages/manageStock/inventories";
import {
    TaxonomyCreate,
    TaxonomyEdit,
    TaxonomyList,
    TaxonomyShow,
} from "./pages/manageTaxonomy/taxonomies";
import {
    VocabularyCreate,
    VocabularyEdit,
    VocabularyList,
    VocabularyShow,
} from "./pages/manageTaxonomy/vocabularies";
import {
    AddressCreate,
    AddressEdit,
    AddressList,
    AddressShow,
} from "./pages/manageUser/addresses";
import {
    UserCreate,
    UserEdit,
    UserList,
    UserShow,
} from "./pages/manageUser/users";
import {
    BlogCreate,
    BlogEdit,
    BlogList,
    BlogShow,
} from "./pages/otherSettings/blogs";
import {
    CurrencyRateCreate,
    CurrencyRateEdit,
    CurrencyRateList,
    CurrencyRateShow,
} from "./pages/otherSettings/currencyRates";
import {
    PagesCreate,
    PagesEdit,
    PagesList,
    PagesShow,
} from "./pages/otherSettings/pages";
import { SocketServer } from "./utils/enums";
import { isJSONParsable, logger } from "./utils/helpers";
import { axiosInstance } from "./utils/http";
import { initiateSocket, socket } from "./utils/socketio";

const App: FC = () => {
    const {
        isLatestVersion,
        refreshCacheAndReload,
        refreshCacheAndReloadDeps,
        startCheckCacheStatus,
        stopCheckCacheStatus,
    } = useCacheBuster({
        isEnabled: true,
        currentVersion: packageJson.version,
        timeout: 10 * 60 * 1000, // 10 minutes
    });

    useKeyboardShortcut();

    // User activity detection
    useIdleTimer({
        timeout: 10 * 60 * 1000, // 10 minutes
        onActive: startCheckCacheStatus,
        onIdle: stopCheckCacheStatus,
        debounce: 500,
    });

    useEffect(() => {
        if (!isLatestVersion) refreshCacheAndReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCacheAndReloadDeps]);

    useEffect(() => {
        if (process.env.REACT_APP_SOCKET_SERVER === SocketServer.ON) {
            initiateSocket(
                {
                    userId: userInfo?.u_id,
                    userName: userInfo?.u_name,
                },
                userInfo?.expressToken
            );

            socket.on("connect", () => {
                logger("Socket Connected", false);

                axiosInstance
                    .post("/users/signup", {
                        userId: userInfo?.u_id,
                        userName: userInfo?.u_name,
                    })
                    .catch((err) => logger(err));
            });

            socket.on("disconnect", () => logger("Socket Disconnected", false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const localUser = localStorage.getItem("user-info");
    const userInfo = isJSONParsable(localUser) ? JSON.parse(localUser) : {};

    return (
        <Admin
            layout={MyLayout}
            // @ts-ignore
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            customRoutes={customRoutes}
            loginPage={MyLoginPage}
            disableTelemetry
        >
            {(permissions) => [
                <Resource name="v1/employeeHistory" />,
                <Resource name="v1/rank" />,
                <Resource name="v1/locations" />,
                <Resource name="v1/roles" />,
                <Resource name="v1/histories" />,
                <Resource name="v1/template" />,
                <Resource name="v1/medicines/history" />,
                <Resource name="v1/generics/history" />,
                permissions?.includes("manageOrderMenu") ? (
                    <Resource
                        name="manageOrder"
                        options={{
                            label: "Manage Order",
                            isMenuParent: true,
                        }}
                        icon={ManageOrderIcon}
                    />
                ) : null,
                <Resource
                    name="v1/orders"
                    options={{
                        label: "Orders",
                        menuParent: "manageOrder",
                    }}
                    list={
                        permissions?.includes("orderMenu") &&
                        permissions?.includes("orderView")
                            ? OrderList
                            : null
                    }
                    create={
                        permissions?.includes("orderCreate")
                            ? OrderCreate
                            : null
                    }
                    edit={permissions?.includes("orderEdit") ? OrderEdit : null}
                    show={permissions?.includes("orderView") ? OrderShow : null}
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/pendingReOrder"
                    options={{
                        label: "Pending Reorders",
                        menuParent: "manageOrder",
                    }}
                    list={
                        permissions?.includes("pendingReOrderMenu") &&
                        permissions?.includes("pendingReOrderView")
                            ? PendingReorderList
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/offlineOrders"
                    options={{
                        label: "Offline Orders",
                        menuParent: "manageOrder",
                    }}
                    list={
                        permissions?.includes("offlineOrderMenu") &&
                        permissions?.includes("offlineOrderView")
                            ? OfflineOrderList
                            : null
                    }
                    create={
                        permissions?.includes("offlineOrderCreate")
                            ? OfflineOrderCreate
                            : null
                    }
                    edit={
                        permissions?.includes("offlineOrderEdit")
                            ? OfflineOrderEdit
                            : null
                    }
                    show={
                        permissions?.includes("offlineOrderView")
                            ? OfflineOrderShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/carts"
                    options={{
                        label: "Carts",
                        menuParent: "manageOrder",
                    }}
                    list={
                        permissions?.includes("cartMenu") &&
                        permissions?.includes("cartView")
                            ? CartList
                            : null
                    }
                    create={
                        permissions?.includes("cartCreate") ? CartCreate : null
                    }
                    edit={permissions?.includes("cartEdit") ? CartEdit : null}
                    show={permissions?.includes("cartView") ? CartShow : null}
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/orderMedicines"
                    options={{
                        label: "Order Medicines",
                        menuParent: "manageOrder",
                    }}
                    list={
                        permissions?.includes("orderMedicineMenu") &&
                        permissions?.includes("orderMedicineView")
                            ? OrderMedicinesList
                            : null
                    }
                    edit={
                        permissions?.includes("orderMedicineEdit")
                            ? OrderMedicinesEdit
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/laterMedicines"
                    options={{
                        label: "Pending Medicines",
                        menuParent: "manageOrder",
                    }}
                    list={
                        permissions?.includes("laterMedicineMenu") &&
                        permissions?.includes("laterMedicineView")
                            ? LaterMedicinesList
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/carts"
                    options={{
                        label: "Carts",
                        menuParent: "manageOrder",
                    }}
                    list={
                        permissions?.includes("cartMenu") &&
                        permissions?.includes("cartView")
                            ? CartList
                            : null
                    }
                    create={
                        permissions?.includes("cartCreate") ? CartCreate : null
                    }
                    edit={permissions?.includes("cartEdit") ? CartEdit : null}
                    show={permissions?.includes("cartView") ? CartShow : null}
                    icon={DashIcon}
                />,
                permissions?.includes("managePurchaseMenu") ? (
                    <Resource
                        name="managePurchase"
                        options={{
                            label: "Manage Purchase",
                            isMenuParent: true,
                        }}
                        icon={ManagePurchaseIcon}
                    />
                ) : null,
                <Resource
                    name="v1/purchaseInvoice"
                    options={{
                        label: "Purchase Invoices",
                        menuParent: "managePurchase",
                    }}
                    list={
                        permissions?.includes("purchaseInvoiceMenu") &&
                        permissions?.includes("purchaseInvoiceView")
                            ? PurchaseInvoiceList
                            : null
                    }
                    create={
                        permissions?.includes("purchaseInvoiceCreate")
                            ? PurchaseInvoiceCreate
                            : null
                    }
                    edit={
                        permissions?.includes("purchaseInvoiceEdit")
                            ? PurchaseInvoiceEdit
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/purchaseInvoiceItems"
                    options={{
                        label: "Purchase Items",
                        menuParent: "managePurchase",
                    }}
                    list={
                        permissions?.includes("purchaseInvoiceItemMenu") &&
                        permissions?.includes("purchaseInvoiceItemView")
                            ? PurchaseInvoiceItemList
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/purchasesRequests"
                    options={{
                        label: "Purchase Requests",
                        menuParent: "managePurchase",
                    }}
                    list={
                        permissions?.includes("purchaseRequestMenu") &&
                        permissions?.includes("purchaseRequestView")
                            ? PurchaseRequestList
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("manageDeliveryMenu") ? (
                    <Resource
                        name="manageDelivery"
                        options={{
                            label: "Manage Delivery",
                            isMenuParent: true,
                        }}
                        icon={ManageDeliveryIcon}
                    />
                ) : null,
                <Resource
                    name="v1/bags"
                    options={{
                        label: "Bags",
                        menuParent: "manageDelivery",
                    }}
                    list={
                        permissions?.includes("bagMenu") &&
                        permissions?.includes("bagView")
                            ? BagList
                            : null
                    }
                    create={
                        permissions?.includes("bagCreate") ? BagCreate : null
                    }
                    edit={permissions?.includes("bagEdit") ? BagEdit : null}
                    show={permissions?.includes("bagView") ? BagShow : null}
                    icon={DashIcon}
                />,
                permissions?.includes("manageDatabaseMenu") ? (
                    <Resource
                        name="manageDatabase"
                        options={{
                            label: "Manage Database",
                            isMenuParent: true,
                        }}
                        icon={ManageDatabaseIcon}
                    />
                ) : null,
                <Resource
                    name="v1/medicines"
                    options={{
                        label: "Medicines",
                        menuParent: "manageDatabase",
                    }}
                    list={
                        permissions?.includes("medicineMenu") &&
                        permissions?.includes("medicineView")
                            ? MedicineList
                            : null
                    }
                    create={
                        permissions?.includes("medicineCreate")
                            ? MedicineCreate
                            : null
                    }
                    edit={
                        permissions?.includes("medicineEdit") ||
                        permissions?.includes("elevatedMedicineEdit")
                            ? MedicineEdit
                            : null
                    }
                    show={
                        permissions?.includes("medicineView")
                            ? MedicineShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/companies"
                    options={{
                        label: "Companies",
                        menuParent: "manageDatabase",
                    }}
                    list={
                        permissions?.includes("companyMenu") &&
                        permissions?.includes("companyView")
                            ? CompanyList
                            : null
                    }
                    create={
                        permissions?.includes("companyCreate")
                            ? CompanyCreate
                            : null
                    }
                    edit={
                        permissions?.includes("companyEdit")
                            ? CompanyEdit
                            : null
                    }
                    show={
                        permissions?.includes("companyView")
                            ? CompanyShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/generics"
                    options={{
                        label: "Generics",
                        menuParent: "manageDatabase",
                    }}
                    list={
                        permissions?.includes("genericMenu") &
                        permissions?.includes("genericView")
                            ? GenericList
                            : null
                    }
                    create={
                        permissions?.includes("genericCreate")
                            ? GenericCreate
                            : null
                    }
                    edit={
                        permissions?.includes("genericEdit")
                            ? GenericEdit
                            : null
                    }
                    show={
                        permissions?.includes("genericView")
                            ? GenericShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("manageUserMenu") ? (
                    <Resource
                        name="manageUser"
                        options={{
                            label: "Manage User",
                            isMenuParent: true,
                        }}
                        icon={ManageUserIcon}
                    />
                ) : null,
                <Resource
                    name="v1/users"
                    options={{
                        label: "Users",
                        menuParent: "manageUser",
                    }}
                    list={
                        permissions?.includes("userMenu") &&
                        permissions?.includes("userView")
                            ? UserList
                            : null
                    }
                    create={
                        permissions?.includes("userCreate") ? UserCreate : null
                    }
                    edit={permissions?.includes("userEdit") ? UserEdit : null}
                    show={permissions?.includes("userView") ? UserShow : null}
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/userLocations"
                    options={{
                        label: "Addresses",
                        menuParent: "manageUser",
                    }}
                    list={
                        permissions?.includes("userLocationMenu") &&
                        permissions?.includes("userLocationView")
                            ? AddressList
                            : null
                    }
                    create={
                        permissions?.includes("userLocationCreate")
                            ? AddressCreate
                            : null
                    }
                    edit={
                        permissions?.includes("userLocationEdit")
                            ? AddressEdit
                            : null
                    }
                    show={
                        permissions?.includes("userLocationView")
                            ? AddressShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("permissionMenu") ? (
                    <Resource
                        name="permissions"
                        options={{
                            label: "Permissions",
                            menuParent: "manageUser",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ) : null,
                permissions?.includes("manageHrMenu") ? (
                    <Resource
                        name="manageHr"
                        options={{
                            label: "Manage HR",
                            isMenuParent: true,
                        }}
                        icon={ManageHRIcon}
                    />
                ) : null,
                <Resource
                    name="v1/employee"
                    options={{
                        label: "Employees",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("employeeMenu") &&
                        permissions?.includes("employeeView")
                            ? EmployeeList
                            : null
                    }
                    create={
                        permissions?.includes("employeeCreate")
                            ? EmployeeCreate
                            : null
                    }
                    edit={
                        permissions?.includes("employeeEdit")
                            ? EmployeeEdit
                            : null
                    }
                    show={
                        permissions?.includes("employeeView")
                            ? EmployeeShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/employeeInfo"
                    options={{
                        label: "Employee Infos",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("employeeInfoMenu") &&
                        permissions?.includes("employeeInfoView")
                            ? EmployeeInfoList
                            : null
                    }
                    create={
                        permissions?.includes("employeeInfoCreate")
                            ? EmployeeInfoCreate
                            : null
                    }
                    edit={
                        permissions?.includes("employeeInfoEdit")
                            ? EmployeeInfoEdit
                            : null
                    }
                    show={
                        permissions?.includes("employeeInfoView")
                            ? EmployeeInfoShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/employeeLeave"
                    options={{
                        label: "Leaves",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("employeeLeaveMenu") &&
                        permissions?.includes("employeeLeaveView")
                            ? EmployeeLeaveList
                            : null
                    }
                    create={
                        permissions?.includes("employeeLeaveCreate")
                            ? EmployeeLeaveCreate
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/salary"
                    options={{
                        label: "Salaries",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("salaryMenu") &&
                        permissions?.includes("salaryView")
                            ? SalaryList
                            : null
                    }
                    create={
                        permissions?.includes("salaryAdjustmentCreate")
                            ? SalaryCreate
                            : null
                    }
                    edit={
                        permissions?.includes("salaryAdjustmentEdit")
                            ? SalaryEdit
                            : null
                    }
                    show={
                        permissions?.includes("salaryView") ? SalaryShow : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/employeeLoan"
                    options={{
                        label: "Loans",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("employeeLoanMenu") &&
                        permissions?.includes("employeeLoanView")
                            ? EmployeeLoanList
                            : null
                    }
                    create={
                        permissions?.includes("employeeLoanCreate")
                            ? EmployeeLoanCreate
                            : null
                    }
                    edit={
                        permissions?.includes("employeeLoanEdit")
                            ? EmployeeLoanEdit
                            : null
                    }
                    show={
                        permissions?.includes("employeeLoanView")
                            ? EmployeeLoanShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/holiday"
                    options={{
                        label: "Holidays",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("holidayMenu") &&
                        permissions?.includes("holidayView")
                            ? HolidayList
                            : null
                    }
                    create={
                        permissions?.includes("holidayCreate")
                            ? HolidayCreate
                            : null
                    }
                    edit={
                        permissions?.includes("holidayEdit")
                            ? HolidayEdit
                            : null
                    }
                    show={
                        permissions?.includes("holidayView")
                            ? HolidayShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/shift"
                    options={{
                        label: "Shifts",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("shiftMenu") &&
                        permissions?.includes("shiftView")
                            ? ShiftList
                            : null
                    }
                    create={
                        permissions?.includes("shiftCreate")
                            ? ShiftCreate
                            : null
                    }
                    edit={permissions?.includes("shiftEdit") ? ShiftEdit : null}
                    show={permissions?.includes("shiftView") ? ShiftShow : null}
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/shiftSchedule"
                    options={{
                        label: "Shift Schedules",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("shiftScheduleMenu") &&
                        permissions?.includes("shiftScheduleView")
                            ? ShiftScheduleList
                            : null
                    }
                    edit={
                        permissions?.includes("shiftScheduleEdit")
                            ? ShiftScheduleEdit
                            : null
                    }
                    show={
                        permissions?.includes("shiftScheduleView")
                            ? ShiftScheduleShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("departmentMenu") && (
                    <Resource
                        name="departments"
                        options={{
                            label: "Departments",
                            menuParent: "manageHr",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ),
                permissions?.includes("rankMenu") && (
                    <Resource
                        name="designations"
                        options={{
                            label: "Designations",
                            menuParent: "manageHr",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ),
                <Resource
                    name="v1/bank"
                    options={{
                        label: "Banks",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("bankMenu") &&
                        permissions?.includes("bankView")
                            ? BankList
                            : null
                    }
                    create={
                        permissions?.includes("bankCreate") ? BankCreate : null
                    }
                    edit={permissions?.includes("bankEdit") ? BankEdit : null}
                    show={permissions?.includes("bankView") ? BankShow : null}
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/employeeAttendance"
                    options={{
                        label: "Attendances",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("employeeAttendanceMenu") &&
                        permissions?.includes("employeeAttendanceView")
                            ? AttendanceList
                            : null
                    }
                    edit={
                        permissions?.includes("employeeAttendanceEdit")
                            ? AttendanceEdit
                            : null
                    }
                    show={
                        permissions?.includes("employeeAttendanceView")
                            ? AttendanceShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/warehouse"
                    options={{
                        label: "Warehouses",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("warehouseMenu") &&
                        permissions?.includes("warehouseView")
                            ? WarehouseList
                            : null
                    }
                    create={
                        permissions?.includes("warehouseCreate")
                            ? WarehouseCreate
                            : null
                    }
                    edit={
                        permissions?.includes("warehouseEdit")
                            ? WarehouseEdit
                            : null
                    }
                    show={
                        permissions?.includes("warehouseView")
                            ? WarehouseShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/job"
                    options={{ label: "Jobs", menuParent: "manageHr" }}
                    list={
                        permissions?.includes("jobMenu") &&
                        permissions?.includes("jobView")
                            ? JobList
                            : null
                    }
                    create={
                        permissions?.includes("jobCreate") ? JobCreate : null
                    }
                    edit={permissions?.includes("jobEdit") ? JobEdit : null}
                    show={permissions?.includes("jobView") ? JobShow : null}
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/jobApplications"
                    options={{
                        label: "Applicants",
                        menuParent: "manageHr",
                    }}
                    list={
                        permissions?.includes("jobApplicationMenu") &&
                        permissions?.includes("jobApplicationView")
                            ? ApplicantList
                            : null
                    }
                    create={
                        permissions?.includes("jobApplicationCreate")
                            ? ApplicantCreate
                            : null
                    }
                    edit={
                        permissions?.includes("jobApplicationEdit")
                            ? ApplicantEdit
                            : null
                    }
                    show={
                        permissions?.includes("jobApplicationView")
                            ? ApplicantShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("manageB2BMenu") ? (
                    <Resource
                        name="manageB2B"
                        options={{
                            label: "Manage B2B",
                            isMenuParent: true,
                        }}
                        icon={ManageB2BIcon}
                    />
                ) : null,
                <Resource
                    name="v1/pharmacy"
                    options={{
                        label: "Pharmacy",
                        menuParent: "manageB2B",
                    }}
                    list={
                        permissions?.includes("pharmacyMenu") &&
                        permissions?.includes("pharmacyView")
                            ? PharmacyList
                            : null
                    }
                    create={
                        permissions?.includes("pharmacyCreate")
                            ? PharmacyCreate
                            : null
                    }
                    edit={
                        permissions?.includes("pharmacyEdit")
                            ? PharmacyEdit
                            : null
                    }
                    show={
                        permissions?.includes("pharmacyView")
                            ? PharmacyShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("manageTaxonomyMenu") ? (
                    <Resource
                        name="manageTaxonomy"
                        options={{
                            label: "Manage Taxonomy",
                            isMenuParent: true,
                        }}
                        icon={ManageTaxonomyIcon}
                    />
                ) : null,
                <Resource
                    name="v1/vocabulary"
                    options={{
                        label: "Vocabularies",
                        menuParent: "manageTaxonomy",
                    }}
                    list={
                        permissions?.includes("vocabularyMenu") &&
                        permissions?.includes("vocabularyView")
                            ? VocabularyList
                            : null
                    }
                    create={
                        permissions?.includes("vocabularyCreate")
                            ? VocabularyCreate
                            : null
                    }
                    edit={
                        permissions?.includes("vocabularyEdit")
                            ? VocabularyEdit
                            : null
                    }
                    show={
                        permissions?.includes("vocabularyView")
                            ? VocabularyShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/taxonomy"
                    options={{
                        label: "Taxonomy Terms",
                        menuParent: "manageTaxonomy",
                    }}
                    list={
                        permissions?.includes("taxonomyMenu") &&
                        permissions?.includes("taxonomyView")
                            ? TaxonomyList
                            : null
                    }
                    create={
                        permissions?.includes("taxonomyCreate")
                            ? TaxonomyCreate
                            : null
                    }
                    edit={
                        permissions?.includes("taxonomyEdit")
                            ? TaxonomyEdit
                            : null
                    }
                    show={
                        permissions?.includes("taxonomyView")
                            ? TaxonomyShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("manageStockMenu") ? (
                    <Resource
                        name="manageStock"
                        options={{
                            label: "Manage Stock",
                            isMenuParent: true,
                        }}
                        icon={ManageStockIcon}
                    />
                ) : null,
                <Resource
                    name="v1/inventory"
                    options={{
                        label: "Inventories",
                        menuParent: "manageStock",
                    }}
                    list={
                        permissions?.includes("inventoryMenu") &&
                        permissions?.includes("inventoryView")
                            ? InventoryList
                            : null
                    }
                    edit={
                        permissions?.includes("inventoryEdit")
                            ? InventoryEdit
                            : null
                    }
                    show={
                        permissions?.includes("inventoryView")
                            ? InventoryShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("inventoryFlagMenu") ? (
                    <Resource
                        name="inventory-flags"
                        options={{
                            label: "Inventory Flags",
                            menuParent: "manageStock",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ) : null,
                permissions?.includes("manageFinanceMenu") ? (
                    <Resource
                        name="manageFinance"
                        options={{
                            label: "Manage Finance",
                            isMenuParent: true,
                        }}
                        icon={ManageFinanceIcon}
                    />
                ) : null,
                <Resource
                    name="v1/collections"
                    options={{
                        label: "Collections",
                        menuParent: "manageFinance",
                    }}
                    list={
                        permissions?.includes("collectionMenu") &&
                        permissions?.includes("collectionView")
                            ? CollectionList
                            : null
                    }
                    show={
                        permissions?.includes("collectionView")
                            ? CollectionShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/ledger"
                    options={{
                        label: "Ledgers",
                        menuParent: "manageFinance",
                    }}
                    list={
                        permissions?.includes("ledgerMenu") &&
                        permissions?.includes("ledgerView")
                            ? LedgerList
                            : null
                    }
                    create={
                        permissions?.includes("ledgerCreate")
                            ? LedgerCreate
                            : null
                    }
                    edit={
                        permissions?.includes("ledgerEdit") ? LedgerEdit : null
                    }
                    show={
                        permissions?.includes("ledgerView") ? LedgerShow : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/userLedgers"
                    options={{
                        label: "User Ledgers",
                        menuParent: "manageFinance",
                    }}
                    list={
                        permissions?.includes("userLedgerMenu") &&
                        permissions?.includes("userLedgerView")
                            ? UserLedgerList
                            : null
                    }
                    create={
                        permissions?.includes("userLedgerCreate")
                            ? UserLedgerCreate
                            : null
                    }
                    edit={
                        permissions?.includes("userLedgerEdit")
                            ? UserLedgerEdit
                            : null
                    }
                    show={
                        permissions?.includes("userLedgerView")
                            ? UserLedgerShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/daily-report"
                    options={{
                        label: "Daily Reports",
                        menuParent: "manageFinance",
                    }}
                    list={
                        permissions?.includes("dailyReportMenu") &&
                        permissions?.includes("dailyReportView")
                            ? DailyReportList
                            : null
                    }
                    create={
                        permissions?.includes("dailyReportCreate")
                            ? DailyReportCreate
                            : null
                    }
                    edit={
                        permissions?.includes("dailyReportEdit")
                            ? DailyReportEdit
                            : null
                    }
                    show={
                        permissions?.includes("dailyReportView")
                            ? DailyReportShow
                            : null
                    }
                    icon={DashIcon}
                />,
                permissions?.includes("managePromotionsMenu") && (
                    <Resource
                        name="promotions"
                        options={{
                            label: "Manage Promotion",
                            isMenuParent: true,
                        }}
                        icon={ManagePromotionsIcon}
                    />
                ),
                permissions?.includes("promotionalMessageMenu") && (
                    <Resource
                        name="promotional-messages"
                        options={{
                            label: "Promotional Messages",
                            menuParent: "promotions",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ),
                permissions?.includes("otherSettingsMenu") ? (
                    <Resource
                        name="otherSettings"
                        options={{
                            label: "Other Settings",
                            isMenuParent: true,
                        }}
                        icon={OtherSettingsIcon}
                    />
                ) : null,
                <Resource
                    name="v1/adminPages"
                    options={{
                        label: "Pages",
                        menuParent: "otherSettings",
                    }}
                    list={
                        permissions?.includes("pageMenu") &&
                        permissions?.includes("pageView")
                            ? PagesList
                            : null
                    }
                    create={
                        permissions?.includes("pageCreate") ? PagesCreate : null
                    }
                    edit={permissions?.includes("pageEdit") ? PagesEdit : null}
                    show={permissions?.includes("pageView") ? PagesShow : null}
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/currencyRate"
                    options={{
                        label: "Currency Rates",
                        menuParent: "otherSettings",
                    }}
                    list={
                        permissions?.includes("currencyRateMenu") &&
                        permissions?.includes("currencyRateView")
                            ? CurrencyRateList
                            : null
                    }
                    create={
                        permissions?.includes("currencyRateCreate")
                            ? CurrencyRateCreate
                            : null
                    }
                    edit={
                        permissions?.includes("currencyRateEdit")
                            ? CurrencyRateEdit
                            : null
                    }
                    show={
                        permissions?.includes("currencyRateView")
                            ? CurrencyRateShow
                            : null
                    }
                    icon={DashIcon}
                />,
                <Resource
                    name="v1/blogPost"
                    options={{
                        label: "Blogs",
                        menuParent: "otherSettings",
                    }}
                    list={
                        permissions?.includes("blogMenu") &&
                        permissions?.includes("blogView")
                            ? BlogList
                            : null
                    }
                    create={
                        permissions?.includes("blogCreate") ? BlogCreate : null
                    }
                    edit={permissions?.includes("blogEdit") ? BlogEdit : null}
                    show={permissions?.includes("blogView") ? BlogShow : null}
                    icon={DashIcon}
                />,
                permissions?.includes("barcodeMenu") ? (
                    <Resource
                        name="barcodes"
                        options={{
                            label: "Barcodes",
                            menuParent: "otherSettings",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ) : null,
                permissions?.includes("prescriptionMenu") ? (
                    <Resource
                        name="prescriptions"
                        options={{
                            label: "Prescriptions",
                            menuParent: "otherSettings",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ) : null,
                permissions?.includes("settingsMenu") ? (
                    <Resource
                        name="settings"
                        options={{
                            label: "Settings",
                            menuParent: "otherSettings",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ) : null,
                permissions?.includes("superAdmin") ? (
                    <Resource
                        name="sa-settings"
                        options={{
                            label: "SA Settings",
                            menuParent: "otherSettings",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ) : null,
                permissions?.includes("liveInfoMenu") ? (
                    <Resource
                        name="live-info"
                        options={{
                            label: "Live Info",
                            menuParent: "otherSettings",
                        }}
                        list={ShowMenu}
                        icon={DashIcon}
                    />
                ) : null,
            ]}
        </Admin>
    );
};

export default App;
