import { FC, MouseEvent } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    NumberField,
    Record,
    TextField,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import UserLedgerFilter from "./UserLedgerFilter";

const UserLedgerList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | User Ledger List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of User Ledger"
            filters={<UserLedgerFilter children={""} />}
            perPage={25}
            sort={{ field: "ule_id", order: "DESC" }}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("userLedgerDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid rowClick="edit">
                <TextField source="ule_id" label="Id" />
                <TextField source="ule_date" label="Date" />
                <FunctionField
                    label="User"
                    sortBy="u_id"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={(record: Record) => (
                        <Link to={`/v1/users/${record.u_id}`}>
                            {record.u_id}
                        </Link>
                    )}
                />
                <FunctionField
                    label="Order"
                    sortBy="o_id"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={(record: Record) => {
                        if (!record.o_id) return record.o_id;

                        return (
                            <Link to={`/v1/orders/${record.o_id}`}>
                                {record.o_id}
                            </Link>
                        );
                    }}
                />
                <TextField source="ule_title" label="Title" />
                <TextField source="ule_from" label="From" />
                <NumberField source="ule_amount" label="Amount" />
                <TextField
                    source="ule_withdraw_instruction"
                    label="Withdraw Instruction"
                />
                <TextField source="withdraw_method" label="Withdraw Method" />
                <TextField source="withdraw_mobile" label="Withdraw Mobile" />
                <TextField source="ule_status" label="Status" />
            </Datagrid>
        </List>
    );
};

export default UserLedgerList;
