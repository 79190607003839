import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { TextInput } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { required } from "../../../utils/helpers";
import AroggaDialogActions from "../../AroggaDialogActions";

type SendNotifyDialogProps = {
    open: boolean;
    handleNotifyDialogClose: () => void;
    [key: string]: any;
};

const SendNotifyDialog: FC<SendNotifyDialogProps> = ({
    open,
    handleNotifyDialogClose,
    ...rest
}) => {
    const { values } = useFormState();

    const { message } = values;

    const { isLoading, refetch } = useRequest(
        `/${rest.resource}/sendDeNotification/${rest?.record?.o_id}/`,
        {
            method: "POST",
            body: { message },
        },
        {
            onSuccess: () => handleNotifyDialogClose(),
        }
    );

    return (
        <Dialog open={open} onClose={handleNotifyDialogClose}>
            <DialogTitle>Send Notification</DialogTitle>
            <DialogContent>
                <TextInput
                    source="message"
                    label="Message"
                    variant="outlined"
                    initialValue={`এই অর্ডারটি #${rest?.record?.o_id} দ্রুত ডেলিভারি করুন অথবা কাস্টমারকে ফোন দিয়ে জানান কখন যাচ্ছেন।`}
                    validate={[required()]}
                    minRows={2}
                    multiline
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                confirmLabel="SEND"
                disabled={!message}
                onDialogClose={handleNotifyDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default SendNotifyDialog;
