import { Link as MuiLink } from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FC, MouseEvent } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    Pagination,
    Record,
    ReferenceManyField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import { useDocumentTitle } from "../../../hooks";

const BagShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Bag Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="b_warehouse_id" label="Warehouse" />
                <TextField source="b_zone" label="Zone" />
                <TextField source="b_no" label="Bag No" />
                <TextField source="o_count" label="Order Count" />
                <ReferenceManyField
                    label="Orders"
                    reference="v1/orders"
                    target="_bag_id"
                    pagination={
                        <Pagination rowsPerPageOptions={[5, 10, 25, 50]} />
                    }
                    perPage={50}
                    sort={{ field: "o_id", order: "ASC" }}
                >
                    <Datagrid>
                        <FunctionField
                            onClick={(e: MouseEvent) => e.stopPropagation()}
                            label="Order Id"
                            sortBy="o_id"
                            render={(record: Record) => (
                                <Link to={`/v1/orders/${record.o_id}`}>
                                    {record.o_id}
                                </Link>
                            )}
                        />
                        <TextField source="o_total" label="Total" />
                        <TextField source="o_status" label="Status" />
                        <TextField
                            source="o_i_status"
                            label="Internal Status"
                        />
                        <TextField source="o_is_status" label="Issue status" />
                        <FunctionField
                            label="Is Cold"
                            sortBy="m_cold"
                            render={(record: Record) => {
                                if (!record.m_cold) return;
                                return <AcUnitIcon />;
                            }}
                        />
                        <TextField
                            source="o_de_option"
                            label="Delivery Option"
                        />
                        <FunctionField
                            label="Address"
                            sortBy="o_address"
                            render={({ o_status, o_address }: Record) => {
                                if (o_status === "processing")
                                    return (
                                        <span style={{ color: "#EF1962" }}>
                                            {o_address}
                                        </span>
                                    );
                                if (o_status === "confirmed")
                                    return (
                                        <span style={{ color: "blue" }}>
                                            {o_address}
                                        </span>
                                    );
                                if (o_status === "delivered")
                                    return (
                                        <span style={{ color: "green" }}>
                                            {o_address}
                                        </span>
                                    );
                                return o_address;
                            }}
                        />
                        <FunctionField
                            onClick={(e: MouseEvent) => e.stopPropagation()}
                            label="Redx Link"
                            render={(record: Record) => {
                                if (!record.redx_tracking_id) return;

                                return (
                                    <MuiLink
                                        href={`https://redx.com.bd/track-global-parcel/?trackingId=${record.redx_tracking_id}`}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Redx Link
                                    </MuiLink>
                                );
                            }}
                        />
                        <TextField source="o_i_note" label="Internal Notes" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
};

export default BagShow;
