import { FC } from "react";
import {
    BulkActionProps,
    BulkDeleteButton,
    Button,
    useListContext,
    usePermissions,
} from "react-admin";

interface IMedicineListBulkActionButtonsProps extends BulkActionProps {
    setIsAssignTo: (value: boolean) => void;
    setSelectedItems: (value: object[]) => void;
    [key: string]: any;
}

const MedicineListBulkActionButtons: FC<
    IMedicineListBulkActionButtonsProps
> = ({ selectedIds, setIsAssignTo, setSelectedItems, ...rest }) => {
    const { permissions } = usePermissions();
    const { data } = useListContext();

    const handleAssignTo = () => {
        setIsAssignTo(true);

        const selectedItems =
            !!Object.values(data)?.length &&
            Object.values(data).filter((item) => selectedIds.includes(item.id));

        setSelectedItems(selectedItems);
    };

    return (
        <>
            <Button label="Assign to" onClick={handleAssignTo} />
            {permissions?.includes("medicineDelete") && (
                <BulkDeleteButton {...rest} />
            )}
        </>
    );
};

export default MedicineListBulkActionButtons;
