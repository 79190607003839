import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { FC } from "react";
import { useNotify } from "react-admin";
import { useFormState } from "react-final-form";

import { useClipboard, useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type GenerateOrderSaveDialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    medicines: any[];
    setIsGenerateOrder: (isGenerateOrder: boolean) => void;
};

const GenerateOrderSaveDialog: FC<GenerateOrderSaveDialogProps> = ({
    open,
    setOpen,
    medicines,
    setIsGenerateOrder,
}) => {
    const notify = useNotify();
    const clipboard = useClipboard();
    const { values } = useFormState();

    const modifiedMedicines = medicines
        ?.map((item) => {
            if (Object.keys(values)?.includes(`qty_text-${item?.i_m_id}`))
                return {
                    i_m_id: item?.i_m_id,
                    m_name: item?.m_name,
                    m_strength: item?.m_strength,
                    m_form: item?.m_form,
                    qty_text: values[`qty_text-${item?.i_m_id}`],
                };

            return item;
        })
        ?.filter((medicine) => medicine.qty_text);

    const purchaseRequestItems =
        !!modifiedMedicines?.length &&
        modifiedMedicines.map(({ i_m_id, qty_text }) => ({
            m_id: i_m_id,
            qty_text,
        }));

    const { isLoading, refetch } = useRequest(
        "/v1/requireMedicines",
        {
            method: "POST",
            body: {
                purchaseRequest: JSON.stringify(purchaseRequestItems),
            },
        },
        {
            onSuccess: () => {
                setIsGenerateOrder(false);
                setOpen(false);
            },
        }
    );

    let random: string = "";
    let index: number = 0;

    const copyToClipboardData =
        !!modifiedMedicines?.length &&
        modifiedMedicines.map((item) => {
            let company = "";

            if (random !== item.m_company) {
                random = item.m_company;
                index = 0;
                company = random;
            }

            index++;

            return `${"\n"}${
                company ? `${"\n"}${company}${"\n"}${"\n"}` : ""
            }${index}. ${item.m_name}${!!item.m_form ? ", " : ""}${
                !!item.m_form ? item.m_form : ""
            }${!!item.m_strength ? ", " : ""}${
                !!item.m_strength ? item.m_strength : ""
            }${!!item.qty_text ? ", " : ""}${
                !!item.qty_text ? item.qty_text : ""
            }`;
        });

    const copyToClipboard = (data) => {
        if (!data) return;

        clipboard.copy(data.toString());
        notify("Copied to clipboard!", { type: "success" });
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
            <DialogTitle>
                <Box display="flex" justifyContent="space-between">
                    <Typography>Are you want to save this?</Typography>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => copyToClipboard(copyToClipboardData)}
                    >
                        <FileCopyOutlinedIcon />
                    </div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Form</TableCell>
                            <TableCell>Strength</TableCell>
                            <TableCell>Quantity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!modifiedMedicines?.length &&
                            modifiedMedicines.map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell
                                        style={
                                            !!item.lm_id
                                                ? { color: "#EF1962" }
                                                : {}
                                        }
                                    >
                                        {item.m_name}
                                    </TableCell>
                                    <TableCell>{item.m_form}</TableCell>
                                    <TableCell>{item.m_strength}</TableCell>
                                    <TableCell>{item.qty_text}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={() => setOpen(false)}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default GenerateOrderSaveDialog;
