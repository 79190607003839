import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { NumberInput, TextInput } from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";

const AdminTab: FC = () => {
    const styles = useStyles();

    return (
        <>
            <TextInput
                source="yt_video_key"
                label="Youtube Video Id"
                variant="outlined"
                className={styles.dBlock}
            />
            <TextInput
                source="yt_video_title"
                label="Youtube Video Title"
                variant="outlined"
                className={styles.dBlock}
            />
            <NumberInput
                source="prescription_percent"
                label="Prescription Discount"
                variant="outlined"
                className={styles.dBlock}
            />
            <TextInput
                source="call_time"
                label="Call to Order Time"
                variant="outlined"
                className={styles.dBlock}
            />
            <NumberInput
                source="call_percent"
                label="Call Discount"
                variant="outlined"
                className={styles.dBlock}
            />
            <NumberInput
                source="healthcare_percent"
                label="Healthcare Discount"
                variant="outlined"
                className={styles.dBlock}
            />
            <NumberInput
                source="b2b_discount_percent"
                label="B2B Discount"
                variant="outlined"
            />
            <FormatedBooleanInput
                source="req_order_prescription"
                label="Require Order Prescription Check"
            />
        </>
    );
};

const useStyles = makeStyles({
    dBlock: {
        display: "block",
    },
});

export default AdminTab;
