import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FC, useEffect } from "react";
import { AutocompleteInput, SelectArrayInput, SelectInput } from "react-admin";
import { Field, useFormState } from "react-final-form";

import { useGetTaxonomiesByVocabulary, useRequest } from "../hooks";
import { capitalizeFirstLetterOfEachWord } from "../utils/helpers";

type TaxonomiesByVocabularyInputProps = {
    fetchKey: string;
    inputType?:
        | "selectInput"
        | "selectArrayInput"
        | "autoCompleteInput"
        | "autoCompleteInputMui";
    title?: boolean;
    [rest: string]: any;
};

const TaxonomiesByVocabularyInput: FC<TaxonomiesByVocabularyInputProps> = ({
    fetchKey,
    inputType = "selectInput",
    // TODO:
    createable = 0,
    title = false,
    ...rest
}) => {
    const { values } = useFormState();

    const data = useGetTaxonomiesByVocabulary(fetchKey);
    // TODO: Why this is here?
    const { data: taxData, refetch } = useRequest(`/v1/taxonomy`, {
        method: "POST",
    });

    const choices = !!data?.length
        ? data.map(({ t_title, t_machine_name }) => ({
              id: title ? t_title : t_machine_name,
              name: capitalizeFirstLetterOfEachWord(t_title),
          }))
        : [];

    const filterChoice = choices.filter(
        (item) => item.id === values[rest.source]
    );

    if (
        values[rest.source] &&
        typeof values[rest.source] === "string" &&
        !filterChoice.length
    ) {
        choices.push({
            id: values[rest.source],
            name: capitalizeFirstLetterOfEachWord(values[rest.source]),
        });
    }

    useEffect(() => {
        if (taxData) {
            choices.push({
                id: taxData.t_machine_name,
                name: capitalizeFirstLetterOfEachWord(taxData.t_title),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxData]);

    if (inputType === "selectArrayInput")
        return (
            <SelectArrayInput choices={choices} {...rest} variant="outlined" />
        );

    if (inputType === "autoCompleteInput") {
        return (
            <AutocompleteInput
                variant="outlined"
                choices={choices}
                setFilter={(filter) => filter}
                {...rest}
                {...(createable && {
                    onCreate: (value) => {
                        if (!value) return;
                        refetch({
                            body: {
                                t_v_id: createable,
                                t_title: value,
                                t_description: value,
                            },
                        });
                    },
                })}
                matchSuggestion={() => true}
                freeSolo
            />
        );
    }

    if (inputType === "autoCompleteInputMui") {
        return (
            <Field
                name={rest.source}
                render={({ input }) => (
                    <Autocomplete
                        id={rest.source}
                        options={choices}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...input}
                                className={rest.className}
                                variant="outlined"
                            />
                        )}
                        onChange={(_, value) => input.onChange(value?.id)}
                        freeSolo
                    />
                )}
                {...rest}
            />
        );
    }

    return <SelectInput choices={choices} {...rest} variant="outlined" />;
};

export default TaxonomiesByVocabularyInput;
