import { Filter, TextInput } from "react-admin";

import TreeDropdownInput from "../../../components/TreeDropdownInput";

const JobFilter = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
        />
        <TreeDropdownInput
            reference="/v1/taxonomiesByVocabulary/department"
            source="_department"
            label="Department"
            variant="outlined"
            keyId="t_id"
            keyParent="t_parent_id"
            optionValue="t_machine_name"
            optionTextValue="t_title"
            alwaysOn
        />
    </Filter>
);

export default JobFilter;
