import { FC } from "react";
import {
    Datagrid,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import TaxonomyFilter from "./TaxonomyFilter";

const TaxonomyList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Taxonomy Term List");

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Taxonomy Term"
            perPage={25}
            filters={<TaxonomyFilter children={""} />}
            sort={{ field: "t_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="edit">
                <TextField source="t_id" label="Id" />
                <TextField source="t_title" label="Title" />
                <TextField source="t_machine_name" label="Machine Name" />
                <ReferenceField
                    source="t_v_id"
                    label="Vocabulary"
                    reference="v1/vocabulary"
                    sortBy="t_v_id"
                    link="show"
                >
                    <TextField source="v_title" />
                </ReferenceField>
                <TextField source="t_created_at" label="Created" />
                <ReferenceField
                    source="t_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="t_created_by"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default TaxonomyList;
