import { FC } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    ReferenceInput,
    SimpleForm,
    required,
} from "react-admin";

import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { useDocumentTitle } from "../../../hooks";

const CartCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Cart Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <ReferenceInput
                    source="u_id"
                    label="User"
                    variant="outlined"
                    reference="v1/users"
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="u_id"
                        helperText={false}
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={(record: {
                            u_name: string;
                            u_mobile: string;
                        }) =>
                            !!record
                                ? `${record.u_name} (${record.u_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default CartCreate;
