import {
    Box,
    FormControlLabel,
    FormGroup,
    Switch,
    makeStyles,
} from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import EditIcon from "@material-ui/icons/Edit";
import { DateTime } from "luxon";
import { ChangeEvent, FC, useEffect, useState } from "react";
import {
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    List,
    ListProps,
    Record,
    ReferenceField,
    TextField,
    useRefresh,
} from "react-admin";

import OrderDurationField from "../../../components/manageOrder/orders/OrderDurationField";
import UserNameField from "../../../components/manageOrder/orders/UserNameField";
import {
    useDocumentTitle,
    useExport,
    useRecursiveTimeout,
    useRequest,
} from "../../../hooks";
import { EmitTypes, SocketServer } from "../../../utils/enums";
import { logger, toFilterObj } from "../../../utils/helpers";
import { axiosInstance } from "../../../utils/http";
import { socket } from "../../../utils/socketio";
import OrderFilter from "./OrderFilter";
import OrderListBulkActionButtons from "./OrderListBulkActionButtons";

const OrderList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Order List");

    const exporter = useExport(rest);
    const refresh = useRefresh();
    const classes = useStyles();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCalculateAdditions, setIsCalculateAdditions] = useState(false);
    const [editingOrderIds, setEditingOrderIds] = useState([]);
    const [orderId, setOrderId] = useState("");
    const [action, setAction] = useState("");
    const [orderNote, setOrderNote] = useState("");

    useRecursiveTimeout(refresh, 1000 * 60 * 2);

    useEffect(() => {
        if (process.env.REACT_APP_SOCKET_SERVER === SocketServer.ON) {
            axiosInstance
                .get("/orders")
                .then(({ data }) => setEditingOrderIds(data?.data))
                .catch((err) => logger(err));

            socket.on(EmitTypes.EDITING_ORDERS, (data) =>
                setEditingOrderIds(data)
            );
        }
    }, []);

    let updateMissingMedicinesPayload = { action };

    if (action === "approve" && isCalculateAdditions) {
        updateMissingMedicinesPayload["isCalculate_additions"] = 1;
    } else {
        updateMissingMedicinesPayload = { action };
    }

    const {
        isLoading: isLoadingUpdateMissingMedicines,
        refetch: handleUpdateMissingMedicines,
    } = useRequest(
        `/v1/orders/updateMissingMedicines/${orderId}`,
        {
            method: "POST",
            body: updateMissingMedicinesPayload,
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    const orderRowClick = (_id, _resource, record) =>
        record?.u_id === 0 ? "" : "edit";

    const orderRowStyle = (record: Record) => {
        if (record?.o_is_status === "paymentPending")
            return {
                backgroundColor: "rgb(255 0 0 / 19%)",
            };

        return {
            backgroundColor: !!record?.o_is_status
                ? record?.o_is_status === "solved"
                    ? "rgb(212 239 223 / 29%)"
                    : "rgb(255 0 0 / 7%)"
                : "white",
        };
    };

    const filterObj = toFilterObj(rest.location.search);

    const ConfirmContent = () => {
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            setIsCalculateAdditions(e.target.checked);
        };

        return (
            <>
                {orderNote}
                {action === "approve" && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isCalculateAdditions}
                                    onChange={handleChange}
                                />
                            }
                            label="ডেলিভারি ফি এবং ক্যাশব্যাক বাদ দিয়ে রিফান্ড হবে"
                        />
                    </FormGroup>
                )}
            </>
        );
    };

    return (
        <>
            <List
                title="List of Order"
                filters={<OrderFilter children={""} />}
                perPage={25}
                sort={{ field: "o_id", order: "DESC" }}
                bulkActionButtons={<OrderListBulkActionButtons />}
                exporter={exporter}
                {...rest}
            >
                <Datagrid rowClick={orderRowClick} rowStyle={orderRowStyle}>
                    <FunctionField
                        label="Id"
                        sortBy="o_id"
                        render={(record: Record) => {
                            if (!record) return "";

                            return (
                                <span>
                                    <span>{record?.o_id}</span>
                                    {!!record?.laterCount && (
                                        <span className={classes.textRed}>
                                            ({record?.laterCount})
                                        </span>
                                    )}
                                    {record?.o_de_option === "express" && (
                                        <span className={classes.textRed}>
                                            (E)
                                        </span>
                                    )}
                                    {!!record?.isUrgent && (
                                        <span className={classes.textRed}>
                                            (Urgent)
                                        </span>
                                    )}
                                    {!!editingOrderIds?.includes(
                                        record?.o_id
                                    ) && <EditIcon style={{ fontSize: 16 }} />}
                                </span>
                            );
                        }}
                    />
                    <UserNameField sortBy="u_name" />
                    <TextField source="o_total" label="Total" />
                    <TextField source="o_created" label="Date" />
                    <OrderDurationField />
                    <FunctionField
                        label="Status"
                        sortBy="o_status"
                        render={(record: Record) => (
                            <span
                                style={{
                                    color:
                                        record?.o_status === "processing" &&
                                        "#FF0000",
                                }}
                            >
                                {record?.o_status}
                            </span>
                        )}
                    />
                    <TextField source="o_i_status" label="Internal Status" />
                    {filterObj._issue && (
                        <TextField
                            source="o_is_created"
                            label="Issue Created"
                        />
                    )}
                    <FunctionField
                        label="Is Cold"
                        sortBy="m_cold"
                        render={(record: Record) => {
                            if (!record.m_cold) return;
                            return <AcUnitIcon />;
                        }}
                    />
                    {filterObj._issue && (
                        <FunctionField
                            label="Issue Duration"
                            render={({ o_is_created, o_is_solved }: Record) => {
                                if (o_is_created === "0000-00-00 00:00:00")
                                    return "";

                                const date =
                                    o_is_solved !== "0000-00-00 00:00:00"
                                        ? DateTime.fromSQL(o_is_solved)
                                        : DateTime.local();

                                const diff = date
                                    .diff(DateTime.fromSQL(o_is_created), [
                                        "days",
                                        "hours",
                                        "minutes",
                                    ])
                                    .toObject();

                                return (
                                    <span
                                        style={{
                                            color:
                                                diff.days > 1
                                                    ? "#FF0000"
                                                    : "#000000",
                                        }}
                                    >
                                        {diff.days}d-{diff.hours}h
                                    </span>
                                );
                            }}
                        />
                    )}
                    <TextField source="o_is_status" label="Issue Status" />
                    <TextField source="o_payment_method" label="Payment" />
                    <TextField source="o_i_note" label="Internal Note" />
                    {filterObj._issue_approval && (
                        <FunctionField
                            label="Action"
                            render={({ o_id, o_i_note }: Record) => (
                                <Box display="flex">
                                    <Button
                                        label="Cancel"
                                        variant="contained"
                                        style={{
                                            backgroundColor: "#6c757d",
                                        }}
                                        color="secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOrderId(o_id);
                                            setAction("cancel");
                                            setOrderNote(o_i_note);
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                    <Box ml={1} />
                                    <Button
                                        label="Issue"
                                        variant="contained"
                                        style={{
                                            backgroundColor: "#dc3545",
                                        }}
                                        color="secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOrderId(o_id);
                                            setAction("issue");
                                            setOrderNote(o_i_note);
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                    <Box ml={1} />
                                    <Button
                                        label="Approve"
                                        variant="contained"
                                        style={{
                                            backgroundColor: "#008069",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOrderId(o_id);
                                            setAction("approve");
                                            setOrderNote(o_i_note);
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                </Box>
                            )}
                        />
                    )}
                    {filterObj._cancel_request && (
                        <ReferenceField
                            source="oe_cancel_request_by"
                            label="Cancel Request By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                    )}
                </Datagrid>
            </List>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoadingUpdateMissingMedicines}
                title={`Are you sure you want to ${action} this order? #${orderId}`}
                content={<ConfirmContent />}
                onConfirm={handleUpdateMissingMedicines}
                onClose={() => {
                    isCalculateAdditions && setIsCalculateAdditions(false);
                    setIsDialogOpen(false);
                }}
            />
        </>
    );
};

const useStyles = makeStyles({
    textRed: {
        color: "#FF0000",
    },
    textBlue: {
        color: "#0000FF",
    },
});

export default OrderList;
