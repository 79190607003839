import { makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFormState } from "react-final-form";

const AmountMismatchedAlert = () => {
    const classes = useStyles();
    const { values } = useFormState();

    if (!values.isAmountMismatched) return;

    return (
        <Alert classes={{ root: classes.mismatchedWarning }} severity="warning">
            Amount mismatched!
        </Alert>
    );
};

const useStyles = makeStyles({
    mismatchedWarning: {
        width: "300px",
        margin: "0 auto",
    },
});

export default AmountMismatchedAlert;
