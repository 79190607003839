import { FC } from "react";
import { AutocompleteInput, useRecordContext } from "react-admin";
import { useFormState } from "react-final-form";

type AreaInputProps = {
    locations: any;
    setLocations: (locations) => void;
    [key: string]: any;
};

const AreaInput: FC<AreaInputProps> = ({
    locations,
    setLocations,
    ...rest
}) => {
    const { values } = useFormState();
    const record = useRecordContext();

    const toChoices = (items) =>
        items?.map((item) => ({ id: item, name: item }));

    if (!locations) return null;

    return (
        <AutocompleteInput
            shouldRenderSuggestions={(value) =>
                !value.trim().length || record?.s_address?.area !== value.trim()
            }
            choices={
                !!values.s_address &&
                !!values.s_address.division &&
                !!values.s_address.district &&
                !!locations[values.s_address.division] &&
                !!locations[values.s_address.division][
                    values.s_address.district
                ]
                    ? toChoices(
                          Object.keys(
                              locations[values.s_address.division][
                                  values.s_address.district
                              ]
                          )
                      )
                    : []
            }
            resettable
            {...rest}
        />
    );
};

export default AreaInput;
