import { Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Title } from "react-admin";
import { useParams } from "react-router-dom";

import PermissionCard from "../../../components/PermissionCard";
import {
    useDocumentTitle,
    useGetCurrentUser,
    useRequest,
    useSwitchTo,
} from "../../../hooks";

const DesignationPermission = () => {
    useDocumentTitle("Arogga | Designation Permissions");

    const currentUser = useGetCurrentUser();
    const { designationId } = useParams<{ designationId: string }>();
    const { refetch: fetchSwitchTo } = useSwitchTo(currentUser.u_id);

    const [permissionState, setPermissionState] = useState([]);

    const { data: permissionsData } = useRequest(
        "/v1/roles/permissions",
        {},
        { isPreFetching: true }
    );

    const {
        data: designationPermissionData,
        refetch: refetchDesignationPermission,
    } = useRequest(
        `/v1/getRankPermission/${designationId}`,
        {},
        { isPreFetching: true }
    );

    const { isLoading, refetch: updatePermission } = useRequest(
        "",
        {
            method: "POST",
        },
        {
            successNotify: "Successfully updated!",
            onSuccess: () => {
                refetchDesignationPermission();
                process.env.REACT_APP_NODE_ENV === "development" &&
                    fetchSwitchTo();
            },
        }
    );
    useEffect(() => {
        if (permissionsData && designationPermissionData?.permissions) {
            const formattedPermissionState = permissionsData.map(
                ({ perm_id, perm_name, perm_desc }) => ({
                    id: perm_id,
                    isChecked:
                        designationPermissionData.permissions.includes(
                            perm_name
                        ),
                    permName: perm_name,
                    permDesc: perm_desc,
                })
            );
            setPermissionState(formattedPermissionState);
        }
    }, [permissionsData, designationPermissionData?.permissions]);

    const handleAll = (param) => {
        if (!permissionState?.length) return;

        const modifiedPermissionState = permissionState
            .filter(({ permName }) => permName !== "onlyGET")
            .map((permission) => ({
                ...permission,
                isChecked: param === "selectAll",
            }));

        setPermissionState(modifiedPermissionState);
    };

    const handlePermissionChange = ({ permId }) => {
        const findPermissionStateIndex = permissionState?.findIndex(
            (item) => item.id === permId
        );
        const newPermissionState = [...permissionState];

        newPermissionState[findPermissionStateIndex].isChecked =
            !newPermissionState[findPermissionStateIndex].isChecked;

        setPermissionState(newPermissionState);
    };

    const handleUpdatePermission = () => {
        const formattedCheckedPermissions = permissionState
            ?.filter((permission) => permission.isChecked)
            ?.map((permission) => permission.permName);

        updatePermission({
            endpoint: `/v1/rank/${designationId}`,
            body: {
                r_permission: formattedCheckedPermissions,
            },
        });
    };

    return (
        <Paper style={{ padding: 20, marginTop: 25 }}>
            <Title title="Designation Permission" />
            <PermissionCard
                id={designationId}
                title={
                    designationPermissionData?.name
                        ? `Permission as ${designationPermissionData?.name}`
                        : ""
                }
                isLoading={isLoading}
                permissionState={permissionState}
                handlePermissionChange={handlePermissionChange}
                handleUpdatePermission={handleUpdatePermission}
                handleAll={handleAll}
            />
        </Paper>
    );
};

export default DesignationPermission;
