import { SelectInput } from "react-admin";

const OrderPaymentMethodInput = ({ choices = [], ...rest }) => (
    <SelectInput
        label="Payment Method"
        choices={[
            { id: "cod", name: "Cash on delivery" },
            { id: "online", name: "Online" },
            ...choices,
        ]}
        {...rest}
    />
);

export default OrderPaymentMethodInput;
