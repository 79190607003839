import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type WithdrawDialogProps = {
    open?: boolean;
    setOpen?: (value: boolean) => void;
    item?: any;
};

const WithdrawDialog: FC<WithdrawDialogProps> = ({ open, setOpen, item }) => {
    const [withdrawMethod, setWithdrawMethod] = useState("bKash");
    const [withdrawMobile, setWithdrawMobile] = useState("");

    const {
        ule_id,
        withdraw_method,
        withdraw_mobile,
        ule_withdraw_instruction,
    } = item || {};

    useEffect(() => {
        !!withdraw_method && setWithdrawMethod(withdraw_method);
        !!withdraw_mobile && setWithdrawMobile(withdraw_mobile);
    }, [withdraw_method, withdraw_mobile]);

    const { isLoading, refetch } = useRequest(
        `/v1/cashWithdraw/${ule_id}`,
        {
            method: "POST",
            body: {
                ule_id,
                withdraw_method: withdrawMethod,
                withdraw_mobile: withdrawMobile,
            },
        },
        {
            isBaseUrl: true,
            isRefresh: true,
            onSuccess: () => setOpen(false),
        }
    );

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Withdraw</DialogTitle>
            <DialogContent>
                {withdraw_method === "sslCommerz" ? (
                    <Typography>{ule_withdraw_instruction}</Typography>
                ) : (
                    <>
                        <Typography style={{ marginBottom: 10 }}>
                            Please select your withdrawl method to withdraw your
                            fund
                        </Typography>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="method"
                                value={withdrawMethod}
                                onChange={(e) =>
                                    setWithdrawMethod(e.target.value)
                                }
                                row
                            >
                                <FormControlLabel
                                    value="bKash"
                                    control={<Radio />}
                                    label="bKash"
                                />
                            </RadioGroup>
                        </FormControl>
                        {withdrawMethod !== "sslCommerz" && (
                            <TextField
                                name="mobile"
                                size="small"
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                value={withdrawMobile}
                                onChange={(e) =>
                                    setWithdrawMobile(e.target.value)
                                }
                                placeholder={`Enter your ${withdrawMethod} number`}
                                required={true}
                                minRows={2}
                                multiline
                                fullWidth
                            />
                        )}
                    </>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                disabled={!ule_withdraw_instruction && !withdrawMobile}
                onDialogClose={() => setOpen(false)}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default WithdrawDialog;
