import { Box, Button, makeStyles } from "@material-ui/core";
import { FC } from "react";

type CacheTabProps = {
    setOpen: (open: boolean) => void;
    setKey: (key: string) => void;
};

const CacheTab: FC<CacheTabProps> = ({ setOpen, setKey }) => {
    const classes = useStyles();

    return (
        <>
            <Button
                variant="contained"
                className={classes.marginRight}
                onClick={() => {
                    setKey("flush");
                    setOpen(true);
                }}
                disableElevation
            >
                Flush
            </Button>
            <Button
                variant="contained"
                className={classes.marginRight}
                onClick={() => {
                    setKey("get");
                    setOpen(true);
                }}
                disableElevation
            >
                Get
            </Button>
            <Button
                variant="contained"
                className={classes.marginRight}
                onClick={() => {
                    setKey("set");
                    setOpen(true);
                }}
                disableElevation
            >
                Set
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    setKey("delete");
                    setOpen(true);
                }}
                disableElevation
            >
                Delete
            </Button>
            <Box mt={1} />
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    marginRight: {
        marginRight: theme.spacing(1),
    },
}));

export default CacheTab;
