import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { cloneElement, FC } from "react";
import {
    Button,
    ExportButton,
    ListActionsProps,
    TopToolbar,
    usePermissions,
} from "react-admin";

interface IInventoryListActionsProps extends ListActionsProps {
    setIsGenerateOrderDialogOpen: (isGenerateOrderDialogOpen: boolean) => void;
}

const InventoryListActions: FC<IInventoryListActionsProps> = ({
    filters,
    setIsGenerateOrderDialogOpen,
}) => {
    const { permissions } = usePermissions();

    return (
        <TopToolbar>
            {cloneElement(filters, { context: "button" })}
            {!permissions?.includes("role:auditor") && (
                <Button
                    label="Generate Order"
                    onClick={() => setIsGenerateOrderDialogOpen(true)}
                >
                    <ShoppingCartIcon />
                </Button>
            )}
            <ExportButton />
        </TopToolbar>
    );
};

export default InventoryListActions;
