import { Button } from "@material-ui/core";
import { useState } from "react";
import { Confirm, useRedirect } from "react-admin";

import { useRequest } from "../../../hooks";

const PurchaseInvoiceSync = ({ purchaseInvoiceId }) => {
    const redirect = useRedirect();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { isLoading, refetch } = useRequest(
        `/v1/purchaseInvoice/sync/${purchaseInvoiceId}`,
        {},
        {
            onSuccess: () => {
                setIsDialogOpen(false);
                redirect("list", "/v1/purchaseInvoice");
            },
        }
    );

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsDialogOpen(true)}
                style={{
                    textTransform: "none",
                }}
            >
                Sync
            </Button>
            <Confirm
                title="Sync Purchase Invoice"
                content="Are you sure you want to sync purchase invoice?"
                isOpen={isDialogOpen}
                loading={isLoading}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export default PurchaseInvoiceSync;
