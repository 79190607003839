import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { required } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";

type ChangeStatusDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (isDialogOpen) => void;
    salaryId: number;
};

const ChangeStatusDialog: FC<ChangeStatusDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    salaryId,
}) => {
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        `/v1/employee/change-salary-status/${salaryId}`,
        {
            method: "POST",
            body: {
                s_status: values.s_status,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => handleDialogClose(),
        }
    );

    const handleDialogClose = () => {
        values.s_status = undefined;
        setIsDialogOpen(false);
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>
                Are you sure you want to change salary status?
            </DialogTitle>
            <DialogContent>
                <TaxonomiesByVocabularyInput
                    fetchKey="salary_status_type"
                    source="s_status"
                    label="Status"
                    validate={[required()]}
                    fullWidth
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                disabled={!values.s_status}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default ChangeStatusDialog;
