import { makeStyles, Paper } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    BooleanField,
    Button,
    CloneButton,
    FunctionField,
    ImageField,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
    Toolbar,
    ToolbarProps,
    useRefresh,
    useUnselectAll,
} from "react-admin";

import "../../../assets/style.css";
import MedicinesAssignTo from "../../../components/MedicinesAssignTo";
import PurchaseRequestDialog from "../../../components/PurchaseRequestDialog";
import { useDocumentTitle, useExport } from "../../../hooks";
import CustomizableDatagrid from "../../../lib/ra-customizable-datagrid";
import { toFilterObj } from "../../../utils/helpers";
import MedicineFilter from "./MedicineFilter";
import MedicineListBulkActionButtons from "./MedicineListBulkActionButtons";

const MedicineList: FC<ListProps> = ({
    permissions,
    syncWithLocation,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    ...rest
}) => {
    useDocumentTitle("Arogga | Medicine List");
    const exporter = useExport(rest);

    const classes = useStyles();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    const [open, setOpen] = useState(false);
    const [unselect, setUnselect] = useState(false);
    const [isAssignTo, setIsAssignTo] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [purchaser, setPurchaser] = useState("");

    useEffect(() => {
        if (unselect) {
            unselectAll(rest.resource);
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unselect]);

    const modifiedSelectedItems = selectedItems?.map((item) => {
        if (Object.keys(formValues)?.includes(`qty_text-${item?.id}`))
            return {
                m_id: item?.m_id,
                m_name: item?.m_name,
                m_strength: item?.m_strength,
                m_form: item?.m_form,
                qty_text: formValues[`qty_text-${item?.id}`],
            };

        return item;
    });

    interface ISaveToolbarProps extends ToolbarProps {
        setOpen: (value: boolean) => void;
    }

    const SaveToolbar = ({ setOpen, ...rest }: ISaveToolbarProps) => {
        return (
            <Toolbar
                {...rest}
                disableGutters
                className={classes.toolbarPosition}
            >
                <Button label="Cancel" onClick={() => setIsAssignTo(false)} />
                <Button
                    label="Save"
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    disabled={isAssignTo && !purchaser}
                />
            </Toolbar>
        );
    };

    const { _status } = toFilterObj(rest.location.search);

    const UserField = (props) => {
        const {
            record: { u_name, u_mobile },
        } = props;
        if (u_name && u_mobile) return <span>{`${u_name} (${u_mobile})`}</span>;
        return <span>{u_mobile}</span>;
    };

    const orderBy = window.location.href?.split("_orderBy=")[1];

    return (
        <>
            {!isAssignTo ? (
                <List
                    {...rest}
                    title="List of Medicine"
                    filters={<MedicineFilter children={""} />}
                    perPage={25}
                    sort={{ field: orderBy || "m_id", order: "DESC" }}
                    exporter={exporter}
                    bulkActionButtons={
                        <MedicineListBulkActionButtons
                            setIsAssignTo={setIsAssignTo}
                            setSelectedItems={setSelectedItems}
                        />
                    }
                    syncWithLocation
                >
                    <CustomizableDatagrid
                        rowClick={
                            permissions?.includes("medicineEdit") ||
                            permissions?.includes("elevatedMedicineEdit")
                                ? "edit"
                                : ""
                        }
                        hidableColumns={[
                            "attachedFiles",
                            "modified_at",
                            "m_r_count",
                        ]}
                    >
                        <TextField source="m_id" label="Id" />
                        {_status === "suggested" ? (
                            <ReferenceField
                                source="created_by"
                                label="User"
                                reference="v1/users"
                                sortBy="created_by"
                                link="show"
                            >
                                <UserField />
                            </ReferenceField>
                        ) : (
                            <></>
                        )}
                        <TextField source="modified_at" label="Modified At" />
                        <TextField source="m_name" label="Name" />
                        <TextField source="m_generic" label="Generic" />
                        <TextField source="m_company" label="Company" />
                        <TextField
                            source="m_manufacturer"
                            label="Manufactured By"
                        />
                        <TextField source="m_form" label="Form" />
                        <TextField source="m_strength" label="Strength" />
                        <TextField source="m_unit" label="Unit" />
                        <TextField source="m_price" label="MRP Price" />
                        <TextField source="m_d_price" label="Discount Price" />
                        <TextField source="m_w_price" label="Wholesale Price" />
                        <TextField source="m_status" label="Status" />
                        <BooleanField
                            source="m_rob"
                            label="Stock"
                            looseValue
                            FalseIcon={() => null}
                        />
                        <NumberField source="m_r_count" label="Request Count" />
                        <ImageField
                            source="attachedFiles"
                            src="src"
                            className="small__img"
                            title="title"
                            label="Pictures"
                        />
                        {permissions?.includes("medicineCreate") ? (
                            <FunctionField
                                label="Clone"
                                render={(record: Record) => {
                                    const { attachedFiles, ...Rest } = record;

                                    return (
                                        // @ts-ignore
                                        <CloneButton {...rest} record={Rest} />
                                    );
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </CustomizableDatagrid>
                </List>
            ) : (
                <Paper
                    style={{ marginTop: 25 }}
                    classes={{
                        root: classes.root,
                    }}
                >
                    <SimpleForm toolbar={<SaveToolbar setOpen={setOpen} />}>
                        <>
                            <MedicinesAssignTo
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                setFormValues={setFormValues}
                                setPurchaser={setPurchaser}
                            />
                            <PurchaseRequestDialog
                                open={open}
                                setOpen={setOpen}
                                setUnselect={setUnselect}
                                formValues={formValues}
                                purchaser={purchaser}
                                setIsAssignTo={setIsAssignTo}
                                modifiedSelectedItems={modifiedSelectedItems}
                            />
                        </>
                    </SimpleForm>
                </Paper>
            )}
        </>
    );
};

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    toolbarPosition: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "15px",
    },
});

export default MedicineList;
