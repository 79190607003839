const ManageFinanceIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        style={{
            enableBackground: "new 0 0 24 24",
        }}
        xmlSpace="preserve"
        width="1em"
        height="1em"
        fontSize="1.5rem"
        {...props}
    >
        <path
            fill="#969bad"
            d="M14.5 5.8h-5L7.6 3c-.3-.4 0-1 .5-1h7.7c.5 0 .8.6.5 1l-1.8 2.8zM9.5 7h5c.1.1.3.2.5.3 2.2 1.4 7 4.5 7 10.9 0 2.1-1.7 3.8-3.8 3.8H5.8C3.7 22 2 20.3 2 18.2c0-6.4 4.8-9.5 7-10.9.2-.1.4-.2.5-.3zm3.3 3.8c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.2c-.2 0-.4.1-.6.2-.6.3-1.1.8-1.2 1.5-.1.4 0 .8.1 1.1.2.3.4.6.7.8.5.3 1.1.5 1.5.6h.1c.5.2.9.3 1.1.5.1.1.1.1.1.2v.3c0 .1-.1.2-.3.3-.2.1-.6.2-1.1.1-.2 0-.7-.2-1-.3-.1 0-.2-.1-.2-.1-.4-.1-.9.1-1 .5-.1.4.1.9.5 1 0 0 .1 0 .2.1.3.1.8.3 1.2.4v.2c0 .4.4.8.8.8s.8-.4.8-.8V18c.2 0 .4-.1.6-.2.6-.3 1.1-.8 1.2-1.5.1-.4 0-.8-.1-1.1-.2-.4-.4-.6-.7-.8-.5-.3-1.1-.5-1.6-.7l-1.2-.4-.1-.1V13c0-.1.1-.2.3-.3.2-.1.6-.2 1.1-.1.2 0 .7.1.8.2.4.1.8-.1 1-.6.1-.4-.1-.8-.6-1-.2 0-.6-.1-.8-.2v-.2z"
        />
    </svg>
);

export default ManageFinanceIcon;
