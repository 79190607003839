import { useEffect, useState } from "react";
import { useFormState } from "react-final-form";
import { useHistory } from "react-router-dom";

import { useRequest } from "../../../hooks";
import PermissionCard from "../../PermissionCard";

const PermissionsTab = ({ record }: any) => {
    const { values } = useFormState();
    const history = useHistory();

    const [permissionState, setPermissionState] = useState([]);

    const { data: permissionsData, refetch } = useRequest(
        "/v1/roles/permissions"
    );

    useEffect(() => {
        if (
            history.location.pathname.includes("/permissions") &&
            !permissionState?.length
        ) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname]);

    const ownPermissions = record?.permissions;

    useEffect(() => {
        if (!permissionsData?.length) return;

        const modifiedPermissionState = permissionsData.map(
            ({ perm_id, perm_name, perm_desc }) => ({
                id: perm_id,
                isChecked: ownPermissions?.includes(perm_name),
                permName: perm_name,
                permDesc: perm_desc,
            })
        );
        setPermissionState(modifiedPermissionState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionsData]);

    const handleAll = (param) => {
        if (!permissionState?.length) return;

        const modifiedPermissionState = permissionState
            .filter(({ permName }) => permName !== "onlyGET")
            .map((permission) => ({
                ...permission,
                isChecked: param === "selectAll",
            }));
        setPermissionState(modifiedPermissionState);
    };

    const handlePermissionChange = ({ permId }) => {
        const findIndex = permissionState?.findIndex(
            (permission) => permission.id === permId
        );
        const newPermissionState = [...permissionState];
        newPermissionState[findIndex].isChecked =
            !newPermissionState[findIndex].isChecked;
        setPermissionState(newPermissionState);
    };

    const formattedCheckedPermissions = permissionState
        ?.filter((permission) => permission.isChecked)
        ?.map((permission) => permission.permName);

    values.permissions = !!permissionsData?.length
        ? formattedCheckedPermissions
        : ownPermissions;

    return (
        <PermissionCard
            hasUpdateBtn={false}
            permissionState={permissionState}
            handlePermissionChange={handlePermissionChange}
            handleAll={handleAll}
        />
    );
};

export default PermissionsTab;
