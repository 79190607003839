import { Box } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { FC, useEffect, useState } from "react";
import {
    AutocompleteInput,
    Edit,
    EditProps,
    ImageField,
    ImageInput,
    Labeled,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
} from "react-admin";
import { useFormState } from "react-final-form";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import MedicineOptionTextRenderer from "../../../components/MedicineOptionTextRenderer";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { TINY_MCE_EDITOR_INIT } from "../../../utils/constants";
import { isEmpty, medicineInputTextRenderer } from "../../../utils/helpers";

const BlogEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Blog Edit");
    const notify = useNotify();
    const MedicineData = ({ newSelIds }: any) => {
        const { values } = useFormState();
        const newArr = [...values?.bp_medicine, ...newSelIds];
        values.bp_medicine = [...new Set(newArr)];
        const { data } = useRequest(
            `/admin/v1/medicines?ids=${newArr?.map((medicine) => medicine)}`,
            {},
            { isBaseUrl: true, isPreFetching: true }
        );

        const [updateData, setUpdateData] = useState([]);

        useEffect(() => {
            setUpdateData(data);
        }, [data]);

        useEffect(() => {
            if (updateData) {
                values.bp_medicine = updateData.map((data) => data.m_id);
            }
        }, [updateData, values]);

        const handleRemoveMedicine = async (id) => {
            if (updateData.length > 1) {
                setUpdateData(updateData.filter((d) => d?.m_id !== id));
            } else {
                notify("This vlaue should not be empty!", { type: "error" });
            }
        };

        return (
            <>
                {updateData?.map((item, i) => (
                    <div
                        style={{
                            marginBottom: "10px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 20,
                        }}
                    >
                        <span
                            onClick={() => handleRemoveMedicine(item?.m_id)}
                            style={{
                                color: "white",
                                background: "red",
                                marginRight: 7,
                                height: "10px",
                                width: "10px",
                                borderRadius: "100%",
                                display: "flex",
                                padding: 5,
                                fontSize: 15,
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            X
                        </span>
                        {i + 1}. {item?.m_name}
                    </div>
                ))}
            </>
        );
    };

    const Content = () => {
        const { values } = useFormState();

        const [content, setContent] = useState(values.bp_description);

        values.bp_description = content;

        return (
            <Labeled label="Description" fullWidth>
                <Editor
                    tinymceScriptSrc={
                        process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                    }
                    init={TINY_MCE_EDITOR_INIT}
                    value={content}
                    onEditorChange={(newValue) => setContent(newValue)}
                />
            </Labeled>
        );
    };
    const [medicines, setMedicines] = useState([]);
    const [newSelIds, setNewSelIds] = useState([]);

    const Medicine = (props) => {
        const { values } = useFormState();

        useEffect(() => {
            values.m_id = undefined;
        }, [values]);

        return (
            <ReferenceInput
                label="Medicine"
                reference="v1/medicines"
                filterToQuery={(searchText) => ({
                    _search: searchText,
                })}
                filter={{
                    _status: "active",
                    _genericStat: 1,
                }}
                sort={{
                    field: "m_name",
                    order: "ASC",
                }}
                {...props}
            >
                <AutocompleteInput
                    matchSuggestion={() => true}
                    helperText={false}
                    optionValue="m_id"
                    onSelect={(selectedMedicine) => {
                        const exitsMedicine = medicines.find(
                            (medicine) =>
                                medicine.m_id === selectedMedicine.m_id
                        );
                        if (isEmpty(exitsMedicine))
                            setMedicines((prev) => [...prev, selectedMedicine]);
                        setNewSelIds((prev) => [
                            ...prev,
                            selectedMedicine?.m_id,
                        ]);
                    }}
                    optionText={<MedicineOptionTextRenderer />}
                    inputText={medicineInputTextRenderer}
                    shouldRenderSuggestions={(val) =>
                        !!val && val.trim().length > 0
                    }
                    resettable
                />
            </ReferenceInput>
        );
    };

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                submitOnEnter={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
            >
                <TextInput
                    source="bp_id"
                    label="Id"
                    variant="outlined"
                    disabled
                />

                <TaxonomiesByVocabularyInput
                    fetchKey="blog_type"
                    source="bp_type"
                    label="Type"
                />

                <Medicine source="m_id" variant="outlined" />
                <MedicineData newSelIds={newSelIds} />
                <Box mt={2} />
                <NumberInput
                    source="bp_reading_time"
                    label="Reading Time"
                    variant="outlined"
                />
                <TextInput source="bp_title" label="Title" variant="outlined" />
                <FormatedBooleanInput
                    source="bp_is_feature"
                    label="Feature"
                    fullWidth
                />
                <ImageInput
                    source="attachedFiles-bp_images"
                    label="Pictures (1800*945 px)"
                    accept="image/*"
                    maxSize={5000000}
                    multiple
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
                <Content />
            </SimpleForm>
        </Edit>
    );
};

export default BlogEdit;
