import { FC, useCallback, useState } from "react";
import { SaveButton, Toolbar } from "react-admin";

import BagDialog from "../../../components/manageDelivery/bags/BagDialog";

type BagEditToolbarProps = {
    isChecked: boolean;
    formValues?: any;
};

const BagEditToolbar: FC<BagEditToolbarProps> = ({
    isChecked,
    formValues,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const ConfirmButton = ({ handleSubmitWithRedirect, ...rest }: any) => {
        const handleClick = useCallback(() => {
            if (
                (isChecked &&
                    formValues?.move_ids.length &&
                    formValues?.move_zone &&
                    formValues?.move_bag) ||
                (!isChecked && rest?.record?.b_de_id !== formValues?.b_de_id)
            ) {
                setIsOpen(true);
            } else {
                handleSubmitWithRedirect("list");
            }
        }, [handleSubmitWithRedirect, rest?.record?.b_de_id]);

        const disabled = () => {
            if (
                (isChecked &&
                    formValues?.move_ids.length &&
                    formValues?.move_zone &&
                    formValues?.move_bag) ||
                rest?.record?.b_is_full !== formValues?.b_is_full ||
                rest?.record?.b_de_id !== formValues?.b_de_id
            ) {
                return false;
            }

            return true;
        };

        return (
            <>
                <SaveButton
                    handleSubmitWithRedirect={handleClick}
                    disabled={disabled()}
                    {...rest}
                />
                <BagDialog
                    isChecked={isChecked}
                    formValues={formValues}
                    record={rest?.record}
                    open={isOpen}
                    handleDialogClose={() => setIsOpen(false)}
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                />
            </>
        );
    };

    return (
        <Toolbar {...rest}>
            <ConfirmButton />
        </Toolbar>
    );
};

export default BagEditToolbar;
