import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { useFormState } from "react-final-form";

import { toFixedNumber } from "../../../utils/helpers";

const SummaryTable = () => {
    const classes = useStyles();
    const { values } = useFormState();

    return (
        <TableContainer>
            <Table size="small" className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell>Total Item</TableCell>
                        <TableCell align="right">
                            {values?.items?.length}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total TP</TableCell>
                        <TableCell
                            align="right"
                            className={
                                values.pi_tp_price !==
                                values.totalIndividualPiiTPPrice
                                    ? classes.fontRed
                                    : ""
                            }
                        >
                            {toFixedNumber(values.pi_tp_price)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total Vat</TableCell>
                        <TableCell
                            align="right"
                            className={
                                (values.pi_vat || 0) !==
                                values.totalIndividualPiiVat
                                    ? classes.fontRed
                                    : ""
                            }
                        >
                            {toFixedNumber(values.pi_vat) || 0}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total Discount</TableCell>
                        <TableCell
                            align="right"
                            className={
                                (values.pi_discount || 0) -
                                    (values.pi_round || 0) !==
                                values.totalIndividualPiiDiscount
                                    ? classes.fontRed
                                    : ""
                            }
                        >
                            {toFixedNumber(
                                (values.pi_discount || 0) -
                                    (values.pi_round || 0)
                            ) || 0}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                            Amount Payable
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{
                                fontWeight: "bold",
                                color:
                                    values.pi_inv_price !==
                                    values.totalIndividualPuPrice
                                        ? "#EF1962"
                                        : "",
                            }}
                        >
                            {toFixedNumber(values.pi_inv_price, 2)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const useStyles = makeStyles({
    fontRed: {
        color: "#EF1962",
    },
    table: {
        width: 250,
        margin: "auto",
    },
});

export default SummaryTable;
