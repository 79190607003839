import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { SelectInput } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type CreditPaidDialogProps = {
    open: boolean;
    handleClose: () => void;
    permissions: string[];
    [key: string]: any;
};

const CreditPaidDialog: FC<CreditPaidDialogProps> = ({
    open,
    handleClose,
    permissions,
}) => {
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        `/v1/purchaseInvoice/creditPaid/${values.pi_id}`,
        {
            method: "POST",
            body: {
                pi_payment_method: values.piPaymentMethod,
            },
        },
        {
            onSuccess: () => handleClose(),
        }
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Are you sure you paid this?</DialogTitle>
            <DialogContent>
                <SelectInput
                    source="piPaymentMethod"
                    label="Method"
                    variant="outlined"
                    choices={
                        permissions?.includes("role:warehouse")
                            ? [{ id: "Cash", name: "Cash" }]
                            : [
                                  { id: "Cash", name: "Cash" },
                                  { id: "Bank", name: "Bank" },
                              ]
                    }
                    fullWidth
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                confirmLabel="Paid"
                disabled={!values.piPaymentMethod}
                onDialogClose={handleClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default CreditPaidDialog;
