import { Box, Button, CircularProgress } from "@material-ui/core";
import { FC, useState } from "react";
import {
    BooleanField,
    EmailField,
    FileField,
    FunctionField,
    Link,
    NumberField,
    Record,
    Show,
    ShowProps,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    useNotify,
    useRedirect,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import CreateTokenDialog from "../../../components/manageUser/users/CreateTokenDialog";
import { useClipboard, useDocumentTitle, useRequest } from "../../../hooks";
import OrdersTab from "./tabs/OrdersTab";
import UserReferralHistoryTab from "./tabs/UserReferralHistoryTab";
import UserTransactionHistoryTab from "./tabs/UserTransactionHistoryTab";

const UserShow: FC<ShowProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | User Show");

    const redirect = useRedirect();
    const notify = useNotify();
    const clipboard = useClipboard();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const {
        data,
        isLoading,
        refetch: createToken,
    } = useRequest(
        `/v1/users/createToken/${rest.id}`,
        {},
        {
            onSuccess: () => setIsDialogOpen(true),
        }
    );

    const handleCreateEmployee = (userRecord) => {
        redirect("create", "/v1/employee", undefined, undefined, {
            userRecord,
        });
    };

    const copyOTPToClipboard = (otp) => {
        if (!otp) return;

        clipboard.copy(otp);
        notify("OTP copied to clipboard!", { type: "success" });
    };

    return (
        <Show {...rest}>
            <TabbedShowLayout>
                <Tab label="User">
                    <ColumnShowLayout simpleShowLayout={false}>
                        <TextField source="u_id" label="Id" />
                        <FunctionField
                            label="Name"
                            render={({ u_name, u_employee_id }: Record) => {
                                if (!u_employee_id) return u_name;

                                return (
                                    <Link
                                        to={`/v1/employee/${u_employee_id}/show`}
                                    >
                                        {u_name}
                                    </Link>
                                );
                            }}
                        />
                        <TextField source="u_mobile" label="Mobile" />
                        <EmailField source="u_email" label="Email" />
                        <TextField source="u_role" label="Role" />
                        <TextField source="u_status" label="Status" />
                        <NumberField source="u_lat" label="Latitude" />
                        <NumberField source="u_long" label="Longitude" />
                        <TextField source="u_created" label="User Created" />
                        <TextField source="u_updated" label="User Updated" />
                        <TextField source="u_cash" label="Cash" />
                        <TextField source="u_p_cash" label="Pending Cash" />
                        <FunctionField
                            label="OTP"
                            render={({ u_otp }: Record) => (
                                <span
                                    style={
                                        u_otp
                                            ? {
                                                  padding: 5,
                                                  cursor: "pointer",
                                                  border: "1px dotted #EF1962",
                                              }
                                            : {}
                                    }
                                    onClick={() => copyOTPToClipboard(u_otp)}
                                >
                                    {u_otp}
                                </span>
                            )}
                        />
                        <TextField source="u_otp_time" label="OTP Time" />
                        <TextField source="u_referrer" label="Referrer" />
                        <NumberField source="u_r_uid" label="Referrer User" />
                        <TextField source="i_help_id" label="I Help BD Id" />
                        <BooleanField
                            source="is_influencer"
                            label="Is Influencer?"
                        />
                        <TextField source="u_note" label="Note" />
                        <TextField source="fcm_token" label="FCM Token" />
                        <FileField
                            source="attachedFiles"
                            src="src"
                            title="title"
                            target="_blank"
                            label="Related Files"
                        />
                    </ColumnShowLayout>
                    <FunctionField
                        addLabel={false}
                        render={(record: Record) => {
                            if (record.u_employee_id) return;

                            return (
                                <Box mb={2}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                            handleCreateEmployee(record)
                                        }
                                    >
                                        Create Employee
                                    </Button>
                                </Box>
                            );
                        }}
                    />
                    {permissions?.includes("userTokenCreate") && (
                        <>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={createToken}
                            >
                                {isLoading ? (
                                    <CircularProgress
                                        size={20}
                                        color="inherit"
                                    />
                                ) : (
                                    "Create Token"
                                )}
                            </Button>
                            <SimpleForm toolbar={false}>
                                <CreateTokenDialog
                                    open={isDialogOpen}
                                    handleClose={() => setIsDialogOpen(false)}
                                    data={data}
                                />
                            </SimpleForm>
                        </>
                    )}
                </Tab>
                <Tab label="Orders">
                    <OrdersTab />
                </Tab>
                <Tab label="Transaction History">
                    <UserTransactionHistoryTab />
                </Tab>
                <Tab label="Referral History">
                    <UserReferralHistoryTab />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default UserShow;
