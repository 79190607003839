import {
    Button,
    Grid,
    Paper,
    TablePagination,
    TextField,
    makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FC, useRef, useState } from "react";
import { Title } from "react-admin";

import AroggaProgress from "../../../components/AroggaProgress";
import BarcodeCard from "../../../components/BarcodeCard";
import { useDocumentTitle, useRequest } from "../../../hooks";

const BarcodesPage: FC = () => {
    useDocumentTitle("Arogga | Barcodes");

    const classes = useStyles();
    const contentRef = useRef(null);

    const [page, setPage] = useState(0);
    const [value, setValue] = useState<any>({});

    const { data, total, isLoading, refetch } = useRequest(
        `/v1/orders/barcodes?${
            value?.name ? `_zone=${value?.name}&` : ""
        }_page=${page + 1}&_perPage=${12}`,
        {},
        { isPreFetching: true, refreshDeps: [page, value?.name] }
    );

    const { data: zones, refetch: refetchLocation } = useRequest(
        "/v1/zones",
        {},
        { isBaseUrl: true, isPreFetching: true }
    );

    const { refetch: barcodesPrinted } = useRequest(
        "/v1/orders/barcodesPrinted",
        {
            method: "POST",
            body: {
                o_ids: data?.map((item: { o_id: number }) => item.o_id),
            },
        },
        {
            isSuccessNotify: false,
            onSuccess: () => refetch(),
        }
    );

    const options =
        !!zones?.zones &&
        Object.values(zones.zones)?.map((zone) => ({
            id: zone,
            name: zone,
        }));

    const handlePrint = () => {
        html2canvas(contentRef.current, {
            windowWidth: 1800,
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 0.82);
            const doc = new jsPDF("p", "pt", "a4", true);
            doc.addImage(imgData, "JPEG", 0, 0, 595, 0, "", "FAST");
            doc.autoPrint();
            doc.output("dataurlnewwindow");

            barcodesPrinted();
        });
    };

    if (isLoading) return <AroggaProgress />;

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="List of Barcode" />
            <Autocomplete
                options={!!options ? options : []}
                getOptionLabel={(option) => option.id || ""}
                style={{ width: 150, marginTop: 20, marginLeft: 20 }}
                value={value}
                onChange={(_, newValue) => setValue(newValue)}
                onFocus={refetchLocation}
                renderInput={(params) => (
                    <TextField {...params} label="Zone" variant="outlined" />
                )}
            />
            <Grid
                container
                ref={contentRef}
                style={{ padding: 20 }}
                className={classes["MuiGrid-container"]}
            >
                {!!data?.length &&
                    data.map((item) => (
                        <Grid
                            key={item.o_id}
                            item
                            xs={6}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "60px 0",
                            }}
                        >
                            <BarcodeCard item={item} page="barcodes" />
                        </Grid>
                    ))}
            </Grid>
            {!!data?.length && (
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={total}
                    rowsPerPage={12}
                    page={page}
                    onPageChange={(_, newPage) => setPage(newPage)}
                />
            )}
            {!!data?.length && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        marginBottom: 20,
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handlePrint}
                    >
                        Print
                    </Button>
                </div>
            )}
        </Paper>
    );
};

const useStyles = makeStyles({
    "MuiGrid-container": {
        width: "1634px",
    },
});

export default BarcodesPage;
