import { Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const MedicineOptionTextRenderer = (choice) => (
    <>
        {choice?.record?.m_status === "inactive" && (
            <Box mt={1}>
                <CloseIcon color="error" />
            </Box>
        )}
        <span style={!choice?.record?.m_rob ? { color: "#EF1962" } : {}}>
            {!!choice
                ? `${choice?.record?.m_name} (${choice?.record?.m_form}) (${choice?.record?.m_strength}) (${choice?.record?.m_price}Tk) (${choice?.record?.m_d_price}Tk/${choice?.record?.m_unit}) (${choice?.record?.m_company})`
                : ""}
        </span>
    </>
);

export default MedicineOptionTextRenderer;
