import { FC } from "react";
import {
    AutocompleteInput,
    Edit,
    EditProps,
    ReferenceInput,
    SimpleForm,
    TextInput,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { useDocumentTitle } from "../../../hooks";

const CompanyEdit: FC<EditProps> = ({ hasEdit, ...rest }) => {
    useDocumentTitle("Arogga | Company Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm redirect="list" toolbar={<SaveDeleteToolbar isSave />}>
                <TextInput source="c_name" label="Company" variant="outlined" />
                <ReferenceInput
                    source="c_uid"
                    label="User"
                    variant="outlined"
                    reference="v1/users"
                    filter={{ _role: "purchaser" }}
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="u_id"
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={(record: {
                            u_name: string;
                            u_mobile: string;
                        }) =>
                            !!record
                                ? `${record.u_name} (${record.u_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
                <TextInput
                    source="c_info"
                    label="Information"
                    variant="outlined"
                    minRows={2}
                    multiline
                />
            </SimpleForm>
        </Edit>
    );
};

export default CompanyEdit;
