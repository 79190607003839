import { Redirect, Route } from "react-router-dom";

import { useGetCurrentUser } from "../hooks";

const PrivateRoute = ({ path, component: Component, ...rest }) => {
    // React admin usePermissions hook is not used here because of value is undefined when initial load.
    const { permissions } = useGetCurrentUser();

    if (
        (path === "/departments" && !permissions?.includes("departmentMenu")) ||
        (path === "/designations" && !permissions?.includes("rankMenu")) ||
        (path === "/designation-permission/:designationId" &&
            !permissions?.includes("rankMenu")) ||
        (path === "/inventory-flags" &&
            !permissions?.includes("inventoryFlagMenu")) ||
        (path === "/settings" && !permissions?.includes("settingsMenu")) ||
        (path === "/sa-settings" && !permissions?.includes("superAdmin")) ||
        (path === "/barcodes" && !permissions?.includes("barcodeMenu")) ||
        (path === "/prescriptions" &&
            !permissions?.includes("prescriptionMenu")) ||
        (path === "/live-info" && !permissions?.includes("liveInfoMenu")) ||
        (path === "/permissions" && !permissions?.includes("permissionMenu")) ||
        (path === "/switch-to" && !permissions?.includes("switchUser"))
    ) {
        return <Redirect to="/not-found" />;
    }

    // @ts-ignore
    return <Route {...rest} path component={Component} />;
};

export default PrivateRoute;
