import { FC, MouseEvent } from "react";
import {
    BooleanField,
    FunctionField,
    Link,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const AddressShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Address Show");

    return (
        <Show {...props}>
            <ColumnShowLayout>
                <TextField source="ul_id" label="Id" />
                <FunctionField
                    sortBy="u_id"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    label="User Id"
                    render={(record) => (
                        <Link to={`/v1/users/${record.u_id}`}>
                            {record.u_id}
                        </Link>
                    )}
                />
                <TextField source="ul_name" label="Name" />
                <TextField source="ul_mobile" label="Mobile" />
                <TextField source="ul_type" label="Type" />
                <TextField source="ul_address" label="Address" />
                <BooleanField source="ul_default" label="Default" looseValue />
                <TextField source="ul_lat" label="Latitude" />
                <TextField source="ul_long" label="Longitude" />
                <TextField source="ul_location" label="Location" />
            </ColumnShowLayout>
        </Show>
    );
};

export default AddressShow;
