import { FC } from "react";
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    minLength,
    required,
} from "react-admin";

import LocationInput from "../../../components/LocationInput";
import { useDocumentTitle } from "../../../hooks";

const WarehouseCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Warehouse Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    source="w_title"
                    label="Title"
                    variant="outlined"
                    validate={[
                        required(),
                        minLength(
                            3,
                            "Title must be at least 3 characters long"
                        ),
                    ]}
                />
                <TextInput source="w_lat" label="Latitude" variant="outlined" />
                <TextInput
                    source="w_lon"
                    label="Longitude"
                    variant="outlined"
                />
                <LocationInput
                    source="w_l_id"
                    variant="outlined"
                    validate={[required()]}
                />
                <TextInput
                    source="w_address"
                    label="Address"
                    variant="outlined"
                    minRows={2}
                    multiline
                />
            </SimpleForm>
        </Create>
    );
};

export default WarehouseCreate;
