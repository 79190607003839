import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    ReferenceInput,
    SelectInput,
} from "react-admin";
import { useFormState } from "react-final-form";

import {
    autoGeneratedLedgerReason,
    toFixedNumber,
} from "../../../utils/helpers";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";
import UserNameRankOptionTextRenderer from "../../UserNameRankOptionTextRenderer";

type LedgerMultipleEntryTableProps = {
    allItems: any[];
    setAllItems: (items: object[]) => void;
};

const LedgerMultipleEntryTable: FC<LedgerMultipleEntryTableProps> = ({
    allItems,
    setAllItems,
}) => {
    const classes = useStyles();
    const { values } = useFormState();

    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.target.blur();
        } else if (
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight" &&
            e.key !== "." &&
            isNaN(e.key)
        ) {
            e.preventDefault();
        }
    };

    const handleTypeOnFocus = (index: number) => {
        const findIndex = allItems?.findIndex((_, i) => i === index);
        const newAllItems = [...allItems];
        newAllItems[findIndex].l_type = values[`l_type-${index}`];
        if (values[`l_type-${index}`] !== "Salary and Allowances") {
            newAllItems[findIndex].l_a_uid = 0;
            newAllItems[findIndex].l_a_date = 0;
        } else {
            newAllItems[findIndex].l_a_uid = values[`l_a_uid-${index}`];
            newAllItems[findIndex].l_a_date = values[`l_a_date-${index}`];
        }
        setAllItems(newAllItems);
    };

    const handleMethodOnFocus = (index) => {
        const findIndex = allItems?.findIndex((_, i) => i === index);
        const newAllItems = [...allItems];
        newAllItems[findIndex].l_method = values[`l_method-${index}`];
        setAllItems(newAllItems);
    };

    const handleAutoGeneratedReason = (index, user) => {
        const { u_name, u_id, u_rank } = user || {};

        const findIndex = allItems?.findIndex((_, i) => i === index);
        const newAllItems = [...allItems];
        newAllItems[findIndex].l_a_uid = values[`user-${index}`]?.u_id;
        newAllItems[findIndex].l_a_date = values[`l_a_date-${index}`];
        newAllItems[findIndex].l_reason = autoGeneratedLedgerReason({
            l_a_date: values[`l_a_date-${index}`],
            username: u_name || values[`user-${index}`]?.u_name,
            rank: u_rank || values[`user-${index}`]?.u_rank,
            l_a_uid: u_id || values[`user-${index}`]?.u_id,
        });
        setAllItems(newAllItems);
    };

    const handleReasonOnBlur = (newValue: string, index: number) => {
        const findIndex = allItems?.findIndex((_, i) => i === index);
        const newAllItems = [...allItems];
        newAllItems[findIndex].l_reason = newValue;
        setAllItems(newAllItems);
    };

    const handleAmountOnBlur = (newValue, index) => {
        const findIndex = allItems?.findIndex((_, i) => i === index);
        const newAllItems = [...allItems];
        newAllItems[findIndex].l_amount = newValue * 1;
        setAllItems(newAllItems);
    };

    const RemoveRow = ({ index }) => {
        const handleClickRowRemove = () => {
            let items = [...allItems];
            items.splice(index, 1);
            setAllItems(items);
        };

        return (
            <Button onClick={handleClickRowRemove}>
                <HighlightOffIcon />
            </Button>
        );
    };

    return (
        <>
            {!!allItems?.length && (
                <TableContainer>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sl No</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Method</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!allItems?.length &&
                                allItems.map((item, i) => (
                                    <TableRow key={allItems.length - i}>
                                        <TableCell>
                                            {allItems.length - i}
                                        </TableCell>
                                        <TableCell>
                                            <TaxonomiesByVocabularyInput
                                                fetchKey="ledger_types"
                                                source={`l_type-${i}`}
                                                label={false}
                                                initialValue={item.l_type}
                                                onFocus={() =>
                                                    handleTypeOnFocus(i)
                                                }
                                                title
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <SelectInput
                                                source={`l_method-${i}`}
                                                label="Method"
                                                variant="outlined"
                                                initialValue={item.l_method}
                                                choices={[
                                                    {
                                                        id: "Cash",
                                                        name: "Cash",
                                                    },
                                                    {
                                                        id: "Bank",
                                                        name: "Bank",
                                                    },
                                                ]}
                                                onFocus={() =>
                                                    handleMethodOnFocus(i)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {item.l_type ===
                                                "Salary and Allowances" && (
                                                <ReferenceInput
                                                    initialValue={item.l_a_uid}
                                                    source={`l_a_uid-${i}`}
                                                    label=""
                                                    reference="v1/users"
                                                    filter={{
                                                        _isEmployee: true,
                                                    }}
                                                    filterToQuery={(
                                                        searchText
                                                    ) => ({
                                                        _search: searchText,
                                                    })}
                                                >
                                                    <AutocompleteInput
                                                        matchSuggestion={() =>
                                                            true
                                                        }
                                                        optionValue="u_id"
                                                        inputText={(value) =>
                                                            value?.u_name
                                                        }
                                                        optionText={
                                                            <UserNameRankOptionTextRenderer />
                                                        }
                                                        onSelect={({
                                                            u_name,
                                                            u_id,
                                                            u_rank,
                                                        }) => {
                                                            values[
                                                                `user-${i}`
                                                            ] = {
                                                                u_name,
                                                                u_id,
                                                                u_rank,
                                                            };
                                                            handleAutoGeneratedReason(
                                                                i,
                                                                {
                                                                    u_name,
                                                                    u_id,
                                                                    u_rank,
                                                                }
                                                            );
                                                        }}
                                                        resettable
                                                        fullWidth
                                                    />
                                                </ReferenceInput>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {item.l_type ===
                                                "Salary and Allowances" && (
                                                <DateInput
                                                    source={`l_a_date-${i}`}
                                                    label=""
                                                    defaultValue={item.l_a_date}
                                                    onFocus={() =>
                                                        handleAutoGeneratedReason(
                                                            i,
                                                            {}
                                                        )
                                                    }
                                                    fullWidth
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            contentEditable={
                                                item.l_type !==
                                                "Salary and Allowances"
                                            }
                                            suppressContentEditableWarning={
                                                true
                                            }
                                            onBlur={(e) =>
                                                handleReasonOnBlur(
                                                    e.currentTarget.innerText.trim(),
                                                    i
                                                )
                                            }
                                        >
                                            {item.l_reason}
                                        </TableCell>
                                        <TableCell
                                            contentEditable
                                            suppressContentEditableWarning={
                                                true
                                            }
                                            onKeyDown={handleOnKeyDown}
                                            onBlur={(e) =>
                                                handleAmountOnBlur(
                                                    e.currentTarget.innerText.trim(),
                                                    i
                                                )
                                            }
                                        >
                                            {toFixedNumber(item.l_amount)}
                                        </TableCell>
                                        <TableCell>
                                            <RemoveRow index={i} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default LedgerMultipleEntryTable;
