import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FC } from "react";
import { Labeled } from "react-admin";

import { capitalizeFirstLetterOfEachWord } from "../utils/helpers";

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type AroggaAccordionProps = {
    title: string;
    isGrid?: boolean;
    children: any;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
};

const AroggaAccordion: FC<AroggaAccordionProps> = ({
    title,
    isGrid = true,
    children,
    xs = 12,
    sm = 6,
    md = 3,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isGrid ? (
                        <Grid container spacing={1}>
                            {children
                                ?.filter((item) => !!item)
                                ?.map((item, index) => (
                                    // @ts-ignore
                                    <Grid
                                        item
                                        xs={xs}
                                        sm={sm}
                                        md={md}
                                        key={index}
                                    >
                                        {item?.props?.addLabel ? (
                                            <Labeled
                                                label={
                                                    item.props.label ||
                                                    capitalizeFirstLetterOfEachWord(
                                                        item.props.source
                                                    )
                                                }
                                            >
                                                {item}
                                            </Labeled>
                                        ) : (
                                            item
                                        )}
                                    </Grid>
                                ))}
                        </Grid>
                    ) : (
                        children
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginBottom: 20,
        },
        heading: {
            fontSize: theme.typography.pxToRem(22),
            // @ts-ignore
            fontWeight: theme.typography.fontWeightRegular,
        },
    })
);

export default AroggaAccordion;
