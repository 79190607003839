import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FC } from "react";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type ShippingDialogProps = {
    open: boolean;
    formValues: any;
    handleShippingDialogClose: () => void;
    setIsLocationEditBtnClick: (isLocationEditBtnClick: boolean) => void;
    [key: string]: any;
};

const ShippingDialog: FC<ShippingDialogProps> = ({
    open,
    formValues,
    handleShippingDialogClose,
    setIsLocationEditBtnClick,
    ...rest
}) => {
    const classes = useStyles();

    const { isLoading, refetch } = useRequest(
        `/v1/orders/shippingAddress/${rest.record?.o_id}/`,
        {
            method: "POST",
            body: { s_address: formValues?.s_address },
        },
        {
            isRefresh: true,
            onSuccess: () => {
                handleShippingDialogClose();
                setIsLocationEditBtnClick(false);
            },
        }
    );

    return (
        <Dialog open={open} onClose={handleShippingDialogClose}>
            <DialogTitle>Are you sure this address is correct?</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                Division
                            </TableCell>
                            <TableCell>
                                {formValues?.s_address?.division}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                City
                            </TableCell>
                            <TableCell>
                                {formValues?.s_address?.district}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                Area
                            </TableCell>
                            <TableCell>{formValues?.s_address?.area}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                Home Address
                            </TableCell>
                            <TableCell>
                                {formValues?.s_address?.homeAddress}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleShippingDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

const useStyles = makeStyles({
    headingLabel: {
        fontWeight: 600,
    },
});

export default ShippingDialog;
