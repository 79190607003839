import { Box, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FC, Fragment } from "react";
import {
    ArrayInput,
    AutocompleteInput,
    ReferenceInput,
    SimpleFormIterator,
} from "react-admin";

import MedicineOptionTextRenderer from "../../../components/MedicineOptionTextRenderer";
import { medicineInputTextRenderer } from "../../../utils/helpers";

type SideScrollTabProps = {
    categories: any[];
};

const SideScrollTab: FC<SideScrollTabProps> = ({ categories }) => (
    <>
        {categories?.map((item) => (
            <Fragment key={item.c_id}>
                <Typography>{item.c_name}</Typography>
                <ArrayInput
                    source={`categories_sidescroll-${item.c_id}`}
                    label=""
                >
                    <SimpleFormIterator
                        // @ts-ignore
                        TransitionProps={{ classNames: "fade-exit" }}
                        removeButton={
                            <Box mt={1} ml={1} style={{ cursor: "pointer" }}>
                                <HighlightOffIcon />
                            </Box>
                        }
                    >
                        <ReferenceInput
                            source="m_id"
                            label="Medicine"
                            variant="outlined"
                            reference="v1/medicines"
                            filterToQuery={(searchText) => ({
                                _search: searchText,
                            })}
                            filter={{
                                _status: "active",
                            }}
                            sort={{
                                field: "m_name",
                                order: "ASC",
                            }}
                        >
                            <AutocompleteInput
                                matchSuggestion={() => true}
                                helperText={false}
                                optionValue="m_id"
                                optionText={<MedicineOptionTextRenderer />}
                                inputText={medicineInputTextRenderer}
                                shouldRenderSuggestions={(value) =>
                                    !!value && value.trim().length > 0
                                }
                                resettable
                            />
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </Fragment>
        ))}
    </>
);

export default SideScrollTab;
