import { DateTime } from "luxon";
import { useRecordContext } from "react-admin";

const DurationField = (props) => {
    const record = useRecordContext(props);

    if (
        !record ||
        !["processing", "confirmed", "delivering", "delivered"].includes(
            record.o_status
        )
    )
        return null;

    const date =
        record.o_status === "delivered"
            ? DateTime.fromSQL(record.o_delivered)
            : DateTime.local();

    const diff = date
        .diff(DateTime.fromSQL(record.o_created), ["days", "hours", "minutes"])
        .toObject();

    return (
        <span
            style={{
                color: diff.days >= 1 ? "#FF0000" : "#000000",
            }}
        >
            {diff.days}d-{diff.hours}h
        </span>
    );
};

export default DurationField;

DurationField.defaultProps = { label: "Duration" };
