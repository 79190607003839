import { SelectInput } from "react-admin";

const DeliveryOptionInput = (props) => (
    <SelectInput
        label="Delivery Option"
        choices={[
            { id: "regular", name: "Regular" },
            { id: "express", name: "Express" },
        ]}
        {...props}
    />
);

export default DeliveryOptionInput;
