import { Grid, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { NumberInput, SelectInput, TextInput } from "react-admin";
import { useFormState } from "react-final-form";

type OrderTabProps = {
    permissions: string[];
    [key: string]: any;
};

const OrderTab: FC<OrderTabProps> = ({ permissions }) => {
    const classes = useStyles();
    const { values } = useFormState();

    const isRoleAdministrator = permissions?.includes("role:administrator");

    return (
        <Grid container spacing={2}>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>ID</p>
                <p className={classes.labelValue}>{values.o_id}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>Order Created</p>
                <p className={classes.labelValue}>{values.o_created}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>Order Updated</p>
                <p className={classes.labelValue}>{values.o_updated}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>Payment Method</p>
                <p className={classes.labelValue}>{values.o_payment_method}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>Order Subtotal</p>
                <p className={classes.labelValue}>{values.o_subtotal}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>Order Addition</p>
                <p className={classes.labelValue}>{values.o_addition}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>Order Deduction</p>
                <p className={classes.labelValue}>{values.o_deduction}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <p className={classes.label}>Order Total</p>
                <p className={classes.labelValue}>{values.o_total}</p>
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="d_code"
                    label="Discount Code"
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <SelectInput
                    source="subscription_freq"
                    label="Subscription Frequency"
                    variant="outlined"
                    choices={[{ id: "monthly", name: "Monthly" }]}
                    allowEmpty
                    fullWidth
                />
            </Grid>
            {isRoleAdministrator && (
                <Grid item sm={6} md={3}>
                    <NumberInput
                        source="man_addition"
                        label="Manual Addition"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            )}
            {isRoleAdministrator && (
                <Grid item sm={6} md={3}>
                    <NumberInput
                        source="man_discount"
                        label="Manual Discount"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            )}
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    label: {
        color: "#7C8AA0",
        fontSize: 12,
    },
    labelValue: {
        color: "#112950",
        fontSize: 16,
    },
}));

export default OrderTab;
