import { Box } from "@material-ui/core";
import { FC } from "react";
import {
    Create,
    CreateProps,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    email,
    maxLength,
    minLength,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import UserRoleInput from "../../../components/manageUser/users/UserRoleInput";
import UserStatusInput from "../../../components/manageUser/users/UserStatusInput";
import { useDocumentTitle } from "../../../hooks";
import { required } from "../../../utils/helpers";

const UserCreate: FC<CreateProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | User Create");

    return (
        <Create {...rest}>
            <SimpleForm>
                <TextInput
                    source="u_name"
                    label="Name"
                    variant="outlined"
                    validate={[required()]}
                />
                <TextInput
                    source="u_mobile"
                    label="Mobile No"
                    variant="outlined"
                    initialValue="+88"
                    validate={[required()]}
                />
                <TextInput
                    source="u_email"
                    label="Email"
                    variant="outlined"
                    validate={email("Invalid email address")}
                />
                {permissions?.includes("role:administrator") && (
                    <Box>
                        <TextInput
                            source="u_referrer"
                            label="Refer Code"
                            variant="outlined"
                            validate={[minLength(4), maxLength(20)]}
                            fullWidth
                        />
                        <FormDataConsumer>
                            {({ formData }) => {
                                if (
                                    !formData.is_influencer ||
                                    !formData.u_referrer
                                )
                                    return null;
                                return (
                                    <Box mb={3}>
                                        {`https://www.arogga.com/s/${formData.u_referrer}/ari`}
                                    </Box>
                                );
                            }}
                        </FormDataConsumer>
                    </Box>
                )}
                <TextInput
                    source="i_help_id"
                    label="I Help BD Id"
                    variant="outlined"
                />
                {permissions?.includes("userChangeRole") && (
                    <UserRoleInput
                        source="u_role"
                        label="Role"
                        variant="outlined"
                        initialValue="user"
                    />
                )}
                <UserStatusInput
                    source="u_status"
                    variant="outlined"
                    initialValue="active"
                />
                {permissions?.includes("addInfluencer") && (
                    <FormatedBooleanInput
                        source="is_influencer"
                        label="Is Influencer?"
                    />
                )}
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;
