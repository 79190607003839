import {
    Button,
    Checkbox,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Popover,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import ColumnIcon from "@material-ui/icons/ViewColumn";
import filter from "lodash/filter";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import T from "prop-types";
import React, { Component } from "react";
import { Datagrid } from "react-admin";

import LocalStorage from "./LocalStorage";

const arrayToSelection = (values) =>
    values.reduce((selection, columnName) => {
        selection[columnName] = true;
        return selection;
    }, {});

const arrayToSelections = (values, allColumns) =>
    allColumns.reduce((selection, columnName) => {
        selection[columnName] = !values.includes(columnName);
        return selection;
    }, {});

class CustomizableDatagrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpened: false,
            selection: this.getInitialSelection(),
            anchorEl: null,
        };
    }

    getColumnNames() {
        const { children } = this.props;
        return filter(
            React.Children.map(children, (field) =>
                get(field, ["props", "source"])
            )
        );
    }

    getColumnLabels() {
        const { children } = this.props;

        return filter(
            React.Children.map(
                children,
                (field) =>
                    field && {
                        source: get(field, ["props", "source"]),
                        label: get(field, ["props", "label"]),
                    }
            ),
            (item) => item && item.source
        );
    }

    getInitialSelection() {
        const { hidableColumns, resource, storage } = this.props;

        const previousSelection = storage.get(resource);

        // if we have a previously stored value, let's return it
        if (!isEmpty(previousSelection)) return previousSelection;

        // if hidableColumns are set let's return them
        if (!isEmpty(hidableColumns)) {
            return arrayToSelections(hidableColumns, this.getColumnNames());
        }

        // otherwise we fallback on the default behaviour : display all columns
        return arrayToSelections([], this.getColumnNames());
    }

    updateStorage = () => {
        this.props.storage.set(this.props.resource, this.state.selection);
    };

    toggleColumn = (columnName) => {
        const previousSelection = this.state.selection;
        const selection = {
            ...previousSelection,
            [columnName]: !previousSelection[columnName],
        };
        this.setState({ selection }, this.updateStorage);
    };

    toggleAllColumns = () => {
        const previousSelection = this.state.selection;
        const allColumns = this.getColumnNames();
        const allSelected =
            Object.keys(previousSelection)
                .map((key) => previousSelection[key])
                .filter((item) => item).length === allColumns.length;
        const selection = allSelected
            ? arrayToSelection([])
            : arrayToSelection(allColumns);
        this.setState({ selection }, this.updateStorage);
    };

    resetColumns = () => {
        const { hidableColumns } = this.props;
        const allColumns = this.getColumnNames();
        const selection = arrayToSelections(hidableColumns, allColumns);
        this.setState({ selection }, this.updateStorage);
    };

    handleOpen = (e) =>
        this.setState({
            modalOpened: true,
            anchorEl: e.currentTarget,
        });

    handleClose = () =>
        this.setState({
            modalOpened: false,
            anchorEl: null,
        });

    renderChild = (child) => {
        const source = get(child, ["props", "source"]);
        const { selection } = this.state;
        // Show children without source, or children explicitly visible
        if (!source || selection[source]) {
            return React.cloneElement(child, {});
        }

        return null;
    };

    render() {
        const { children, hidableColumns, ...rest } = this.props;
        const { selection } = this.state;
        const open = Boolean(this.state.anchorEl);
        const isSelectAll =
            Object.keys(selection)
                .map((key) => selection[key])
                .filter((item) => item).length === this.getColumnNames().length;

        return (
            <>
                <div style={{ float: "right", marginRight: "1rem" }}>
                    <Button
                        variant=""
                        mini={true}
                        onClick={this.resetColumns}
                        style={{ marginRight: "1rem" }}
                    >
                        <ReplayIcon />
                    </Button>
                    <Button
                        variant=""
                        mini={true}
                        aria-describedby={open ? "simple-popper" : undefined}
                        onClick={this.handleOpen}
                    >
                        <ColumnIcon />
                    </Button>
                    <Popover
                        open={open}
                        id={open ? "simple-popper" : undefined}
                        anchorEl={this.state.anchorEl}
                        placement={"bottom-end"}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <Paper
                            style={{ padding: "1rem" }}
                            anchorEl={this.state.anchorEl}
                        >
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                    style: {
                                        width: "20ch",
                                    },
                                }}
                            >
                                <MenuItem onClick={this.toggleAllColumns}>
                                    <Checkbox
                                        checked={isSelectAll}
                                        onChange={this.toggleAllColumns}
                                        value="checkedB"
                                        color="primary"
                                    />
                                    <ListItemText primary="Show All" />
                                </MenuItem>
                                {this.getColumnLabels().map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={() =>
                                            this.toggleColumn(item.source)
                                        }
                                    >
                                        <Checkbox
                                            checked={selection[item.source]}
                                            onChange={() =>
                                                this.toggleColumn(item.source)
                                            }
                                            color="primary"
                                        />
                                        <ListItemText primary={item.label} />
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Paper>
                    </Popover>
                </div>
                <Datagrid {...rest}>
                    {React.Children.map(children, this.renderChild)}
                </Datagrid>
            </>
        );
    }
}

CustomizableDatagrid.propTypes = {
    hidableColumns: T.arrayOf(T.string),
    storage: T.shape({
        get: T.func.isRequired,
        set: T.func.isRequired,
    }),
};

CustomizableDatagrid.defaultProps = {
    hidableColumns: [],
    storage: LocalStorage,
};

export default CustomizableDatagrid;
