import { makeStyles } from "@material-ui/core";
import { FC, useState } from "react";

type DiscountButtonsProps = {
    discounts: number[];
    handleDiscount: (discount: number) => void;
};

const DiscountButtons: FC<DiscountButtonsProps> = ({
    discounts,
    handleDiscount,
}) => {
    const classes = useStyles();
    const [isHover, setIsHover] = useState({});

    return (
        <>
            {discounts.map((discount) => (
                <div
                    key={discount}
                    onMouseEnter={() =>
                        setIsHover({
                            [discount]: true,
                        })
                    }
                    onMouseLeave={() =>
                        setIsHover({
                            [discount]: false,
                        })
                    }
                    onClick={() => handleDiscount(discount)}
                    style={{
                        display: "inline-block",
                        color: "#FFFFFF",
                    }}
                >
                    <span
                        className={
                            isHover[discount] ? classes.btnHover : classes.btn
                        }
                    >
                        {discount}%
                    </span>
                </div>
            ))}
        </>
    );
};

const useStyles = makeStyles(() => ({
    btn: {
        fontSize: 14,
        fontWeight: "bold",
        cursor: "pointer",
        padding: "5px 5px",
        borderRadius: 4,
        color: "#3ECBA5",
    },
    btnHover: {
        fontSize: 14,
        fontWeight: "bold",
        cursor: "pointer",
        padding: "5px 5px",
        borderRadius: 4,
        color: "#FFFFFF",
        backgroundColor: "#3ECBA5",
    },
}));

export default DiscountButtons;
