import { FC } from "react";
import { Filter, FilterProps, SelectInput, TextInput } from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import UserLedgerStatusInput from "../../../components/manageFinance/userLedgers/UserLedgerStatusInput";

const UserLedgerFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <TextInput
            source="_o_id"
            label="Order Id"
            variant="outlined"
            alwaysOn
            resettable
        />
        <FormatedBooleanInput
            source="_only_withdrawing"
            label="Withdrawing"
            alwaysOn
        />
        <TextInput
            source="_u_id"
            label="User Id"
            variant="outlined"
            resettable
        />
        <UserLedgerStatusInput source="_status" variant="outlined" />
        <SelectInput
            source="_withdraw_method"
            label="Withdraw Method"
            variant="outlined"
            choices={[
                {
                    id: "bKash",
                    name: "bKash",
                },
                {
                    id: "sslCommerz",
                    name: "SSL Commerz",
                },
            ]}
        />
        <SelectInput
            source="_ule_title"
            label="Title"
            variant="outlined"
            choices={[
                {
                    id: "Order cancel",
                    name: "Order cancel",
                },
                {
                    id: "Payment",
                    name: "Payment",
                },
                {
                    id: "Arogga cash used",
                    name: "Arogga cash used",
                },
                {
                    id: "Promotional bonus",
                    name: "Promotional bonus",
                },
                {
                    id: "deducted",
                    name: "deducted",
                },
                {
                    id: "Cashback",
                    name: "Cashback",
                },
                {
                    id: "Referral bonus",
                    name: "Referral bonus",
                },
                {
                    id: "Refund",
                    name: "Refund",
                },
                {
                    id: "Arogga cash received",
                    name: "Arogga cash received",
                },
                {
                    id: "Order return",
                    name: "Order return",
                },
                {
                    id: "Arogga cash deducted",
                    name: "Arogga cash deducted",
                },
            ]}
        />
    </Filter>
);

export default UserLedgerFilter;
