import jsonExport from "jsonexport/dist";
import { FC } from "react";
import {
    BooleanField,
    Datagrid,
    EmailField,
    FunctionField,
    Link,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
    downloadCSV,
} from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import { logger, toFilterObj } from "../../../utils/helpers";
import EmployeeFilter from "./EmployeeFilter";

const EmployeeList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Employee List");

    const { _shiftType } = toFilterObj(rest.location.search);

    const rowStyle = (record: Record) => {
        if (record?.e_date_of_release !== "0000-00-00")
            return {
                backgroundColor: "rgb(255 229 229)",
            };
        if (record?.e_date_of_leaving !== "0000-00-00")
            return {
                backgroundColor: "rgb(255 229 229 / 35%)",
            };

        return null;
    };

    const exporter = (records, fetchRelatedRecords) => {
        fetchRelatedRecords(records, "e_rank_id", "v1/rank")
            .then((ranks) => {
                const data = records.map(
                    ({ id, e_rank_id, ...restRecord }) => ({
                        ...restRecord,
                        designation: ranks[e_rank_id]?.r_title,
                    })
                );

                return jsonExport(data, {}, (_, csv) =>
                    downloadCSV(csv, "employees")
                );
            })
            .catch((err) => logger(err));
    };

    return (
        <List
            {...rest}
            title="List of Employee"
            perPage={25}
            filters={<EmployeeFilter children={""} />}
            sort={{ field: "e_id", order: "DESC" }}
            exporter={permissions?.includes("export") ? exporter : false}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="show" rowStyle={rowStyle}>
                <TextField source="e_id" label="Id" />
                <ReferenceField
                    source="e_warehouse_id"
                    label="Warehouse"
                    reference="v1/warehouse"
                    link="show"
                    sortBy="e_warehouse_id"
                >
                    <TextField source="w_title" />
                </ReferenceField>
                <FunctionField
                    label="Name"
                    sortBy="e_name"
                    // @ts-ignore
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={(record) => (
                        <Link
                            to={{
                                pathname: "/v1/employeeInfo",
                                search: `filter=${JSON.stringify({
                                    _e_id: record.e_id,
                                })}`,
                            }}
                        >
                            {record.e_name}
                        </Link>
                    )}
                />
                {_shiftType && <EmailField source="u_email" label="Email" />}
                <TextField source="e_mobile" label="Mobile" />
                <TextField source="e_type" label="Type" />
                <NumberField source="e_salary" label="Salary" />
                <NumberField source="e_salary_tax" label="Salary Tax" />
                <NumberField source="e_payment_mode" label="Payment Mode" />
                <TextField source="e_department" label="Department" />
                <ReferenceField
                    source="e_rank_id"
                    label="Designation"
                    reference="v1/rank"
                    link="show"
                    sortBy="e_rank_id"
                >
                    <TextField source="r_title" />
                </ReferenceField>
                <TextField source="e_sick_leaves" label="Sick Leaves" />
                <TextField source="e_casual_leaves" label="Casual Leaves" />
                <TextField source="e_annual_leaves" label="Annual Leaves" />
                <TextField
                    source="e_compensatory_leaves"
                    label="Compensatory Leaves"
                />
                <TextField
                    source="e_maternity_leaves"
                    label="Maternity Leaves"
                />
                <FunctionField
                    label="Weekend Leaves"
                    sortBy="e_weekend_leaves"
                    render={({ e_weekend_leaves }: Record) => {
                        if (e_weekend_leaves === 0) return "All Fridays";
                        return e_weekend_leaves;
                    }}
                />
                <TextField source="e_date_of_joining" label="Joining Date" />
                <TextField
                    source="e_confirmation_date"
                    label="Confirmation Date"
                />
                <TextField source="e_date_of_leaving" label="Leaving Date" />
                <TextField source="e_date_of_release" label="Releasing Date" />
                <BooleanField
                    source="e_salary_addition_mode"
                    label="Auto Salary Addition Mode"
                    looseValue
                    FalseIcon={() => null}
                />
                <BooleanField
                    source="e_salary_deduction_mode"
                    label="Auto Salary Deduction Mode"
                    looseValue
                    FalseIcon={() => null}
                />
                <BooleanField
                    source="e_dynamic_leave_mode"
                    label="Dynamic Leave Mode"
                    looseValue
                    FalseIcon={() => null}
                />
                <FunctionField
                    // @ts-ignore
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    label="View"
                    render={({ e_user_id }: Record) => (
                        <Link to={`/v1/users/${e_user_id}/show`}>User</Link>
                    )}
                />
            </Datagrid>
        </List>
    );
};

export default EmployeeList;
