import { Box, Paper, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Title, usePermissions } from "react-admin";

import { useDocumentTitle } from "../../hooks";

const SiteSettingsPage = () => {
    useDocumentTitle("Arogga | Site Settings");

    const { permissions } = usePermissions();

    const [printerUrl, setPrinterUrl] = useState(
        localStorage.getItem("printer-url") || ""
    );

    const [cameraBaseUrl, setCameraBaseUrl] = useState(
        localStorage.getItem("camera-base-url") || ""
    );

    useEffect(() => {
        localStorage.setItem("printer-url", printerUrl);
    }, [printerUrl]);

    useEffect(() => {
        localStorage.setItem("camera-base-url", cameraBaseUrl);
    }, [cameraBaseUrl]);

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Site Settings" />
            <Box
                display="flex"
                flexDirection="column"
                width={270}
                gridRowGap={15}
                p={2}
            >
                <TextField
                    name="printerUrl"
                    label="Printer URL"
                    placeholder="Printer URL"
                    size="small"
                    variant="outlined"
                    value={printerUrl}
                    onChange={(e) => setPrinterUrl(e.target.value)}
                />
                {permissions?.includes("medicineEdit") && (
                    <TextField
                        name="cameraBaseUrl"
                        label="Camera Base URL"
                        placeholder="Camera Base URL"
                        size="small"
                        variant="outlined"
                        value={cameraBaseUrl}
                        onChange={(e) => setCameraBaseUrl(e.target.value)}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default SiteSettingsPage;
