import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { Link, Title, useNotify } from "react-admin";

import AroggaProgress from "../../../components/AroggaProgress";
import QtyLabel from "../../../components/QtyLabel";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { Status } from "../../../utils/enums";
import { logger } from "../../../utils/helpers";
import { httpClient } from "../../../utils/http";

const InventoryFlagsPage = () => {
    useDocumentTitle("Arogga | Inventory Flags");

    const classes = useStyles();
    const notify = useNotify();

    const [order, setOrder] = useState("DESC");
    const [orderBy, setOrderBy] = useState("id");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const { data, isLoading, total, refetch } = useRequest(
        `/v1/inventory/flags?_order=${order}&_orderBy=${orderBy}&_page=${
            page + 1
        }&_perPage=${rowsPerPage}`,
        {},
        {
            isPreFetching: true,
            refreshDeps: [order, page, rowsPerPage],
        }
    );

    const handleCorrection = (id) => {
        httpClient(`/v1/inventory/correction/${id}`, {
            method: "POST",
        })
            .then(({ json }: any) => {
                if (json?.status === Status.SUCCESS) {
                    refetch(); // For refresh data
                    notify(json?.message || "Successfully corrected!", {
                        type: "success",
                    });
                } else {
                    notify(json.message || "Correction failed!", {
                        type: "warning",
                    });
                }
            })
            .catch((err) => {
                notify("Something went wrong, Please try again!", {
                    type: "error",
                });
                logger(err);
            });
    };

    const handleRequestSort = (orderBy) => {
        setOrder((prevState) => (prevState === "ASC" ? "DESC" : "ASC"));
        setOrderBy(orderBy);
    };

    const handleChangePage = (_, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    if (isLoading) return <AroggaProgress />;

    return (
        <Paper style={{ padding: "20px 20px 0px 20px" }}>
            <Title title="List of Inventory Flag" />

            {!!data?.length && (
                <>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Form</TableCell>
                                    <TableCell>Strength</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={
                                                order === "ASC" ? "asc" : "desc"
                                            }
                                            onClick={() =>
                                                handleRequestSort("i_qty")
                                            }
                                        >
                                            Qty
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={
                                                order === "ASC" ? "asc" : "desc"
                                            }
                                            onClick={() =>
                                                handleRequestSort(
                                                    "expected_qty"
                                                )
                                            }
                                        >
                                            Expected Qty
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={
                                                order === "ASC" ? "asc" : "desc"
                                            }
                                            onClick={() =>
                                                handleRequestSort("difference")
                                            }
                                        >
                                            Difference
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Correction</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!data?.length &&
                                    data.map((item) => (
                                        <TableRow key={item.i_id}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Link
                                                    to={`/v1/inventory/${item.i_id}`}
                                                >
                                                    {item.i_id}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    to={`/v1/medicines/${item.m_id}`}
                                                >
                                                    {item.m_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>{item.m_form}</TableCell>
                                            <TableCell>
                                                {item.m_strength}
                                            </TableCell>
                                            <TableCell>{item.m_unit}</TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <QtyLabel
                                                    qty={item.i_qty}
                                                    medicine={item}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <QtyLabel
                                                    qty={item.expected_qty}
                                                    medicine={item}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <QtyLabel
                                                    qty={
                                                        item.expected_qty -
                                                        item.i_qty
                                                    }
                                                    medicine={item}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {+item.expected_qty !==
                                                    item.i_qty && (
                                                    <Button
                                                        variant="contained"
                                                        onClick={() =>
                                                            handleCorrection(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        Correction
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default InventoryFlagsPage;
