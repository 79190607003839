import { FC } from "react";
import {
    AutocompleteInput,
    Edit,
    EditProps,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    maxValue,
    minValue,
    required,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { useDocumentTitle } from "../../../hooks";

const EmployeeLoanEdit: FC<EditProps> = (props) => {
    useDocumentTitle("Arogga | Employee Loan Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...props}
        >
            <SimpleForm toolbar={<SaveDeleteToolbar isSave />}>
                <ReferenceInput
                    source="el_employee_id"
                    label="Employee"
                    variant="outlined"
                    reference="v1/employee"
                    sort={{ field: "e_id", order: "DESC" }}
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="e_id"
                        optionText={
                            <UserEmployeeOptionTextRenderer isEmployee />
                        }
                        inputText={(record: {
                            e_name: string;
                            e_mobile: string;
                        }) =>
                            !!record
                                ? `${record.e_name} (${record.e_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
                <NumberInput
                    source="el_amount"
                    label="Amount"
                    variant="outlined"
                    validate={[required()]}
                />
                <NumberInput
                    source="el_installment"
                    label="Installment"
                    variant="outlined"
                    min={1}
                    max={6}
                    validate={[required(), minValue(1), maxValue(6)]}
                />
                <TextInput
                    source="el_reason"
                    label="Reason"
                    variant="outlined"
                    validate={[required()]}
                    minRows={2}
                    multiline
                />
            </SimpleForm>
        </Edit>
    );
};

export default EmployeeLoanEdit;
