import { FC } from "react";
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import ShiftFilter from "./ShiftFilter";

const ShiftList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Shift List");

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Shift"
            filters={<ShiftFilter children={""} />}
            perPage={25}
            sort={{ field: "s_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="edit">
                <TextField source="s_id" label="Id" />
                <TextField source="s_title" label="Title" />
                <TextField source="s_time_start" label="Start Time" />
                <TextField source="s_time_end" label="End Time" />
                <TextField source="s_shift_type" label="Type" />
                <BooleanField
                    source="s_is_active"
                    label="Active"
                    looseValue
                    FalseIcon={() => null}
                />
            </Datagrid>
        </List>
    );
};

export default ShiftList;
