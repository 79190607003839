import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import { FC } from "react";
import {
    Datagrid,
    EditButton,
    FileField,
    FunctionField,
    List,
    ListProps,
    Record,
    ShowButton,
    TextField,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import BagFilter from "./BagFilter";

const BagList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Bag List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Bag"
            filters={<BagFilter children={""} />}
            perPage={25}
            sort={{ field: "b_zone", order: "ASC" }}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("bagDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid>
                <TextField source="b_zone" label="Zone" />
                <TextField source="b_no" label="Bag No" />
                <TextField source="o_count" label="Order Count" />
                <FunctionField
                    label="Assign"
                    render={(record: Record) => {
                        if (record?.assign_name) {
                            return (
                                <TextField
                                    source="assign_name"
                                    label="Assign"
                                />
                            );
                        } else if (record?.o_count) {
                            return (
                                <EditButton
                                    record={record}
                                    label="Assign"
                                    icon={<DirectionsBikeIcon />}
                                />
                            );
                        } else {
                            return null;
                        }
                    }}
                />
                <FunctionField
                    label="Action"
                    render={(record: Record) => {
                        if (!record?.o_count) return null;

                        return (
                            <>
                                <ShowButton label="View" record={record} />
                                <EditButton label="Edit" record={record} />
                                <FileField
                                    source="invoiceUrl"
                                    label="Invoice"
                                    title="Invoice"
                                    // @ts-ignore
                                    onClick={(e) => e.stopPropagation()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginLeft: "10px" }}
                                />
                            </>
                        );
                    }}
                />
            </Datagrid>
        </List>
    );
};

export default BagList;
