/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { logger } from "../utils/helpers";

interface IUseCacheBuster {
    currentVersion: string;
    timeout: number;
    isEnabled: boolean;
    isAuto?: boolean;
}

const useCacheBuster = ({
    currentVersion,
    timeout = 10 * 60 * 1000, // 10 minutes
    isEnabled = false,
    isAuto = false,
}: IUseCacheBuster) => {
    const [cacheStatus, setCacheStatus] = useState({
        isLoading: false,
        isLatestVersion: true,
        refreshCacheAndReloadDeps: false,
    });

    const { isLoading, isLatestVersion, refreshCacheAndReloadDeps } =
        cacheStatus;

    const timeoutRef = useRef<number | null>(null);
    const startCheckCacheStatus = useRef(() => {});
    const stopCheckCacheStatus = useRef(() => {});

    useEffect(() => {
        checkCacheStatus();
    }, []);

    useEffect(() => {
        if (!isEnabled) return logger("Cache Buster is disabled.", false);

        timeoutRef.current = window.setInterval(checkCacheStatus, timeout);

        return () => {
            if (timeoutRef.current) {
                window.clearInterval(timeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isEnabled) return logger("Cache Buster is disabled.", false);

        window.addEventListener("focus", startCheckCacheStatus.current);
        window.addEventListener("blur", stopCheckCacheStatus.current);

        return () => {
            window.removeEventListener("focus", startCheckCacheStatus.current);
            window.removeEventListener("blur", stopCheckCacheStatus.current);
        };
    }, []);

    startCheckCacheStatus.current = () => {
        if (timeoutRef.current) {
            window.clearInterval(timeoutRef.current);
        }
        if (window.navigator.onLine) {
            timeoutRef.current = window.setInterval(checkCacheStatus, timeout);
        }
    };

    stopCheckCacheStatus.current = () => {
        if (timeoutRef.current) {
            window.clearInterval(timeoutRef.current);
        }
    };

    const checkCacheStatus = async () => {
        setCacheStatus((prevState) => ({ ...prevState, isLoading: true }));

        try {
            const res = await fetch("/meta.json", { cache: "no-store" });
            const { version: metaVersion } = await res.json();

            const shouldForceRefresh = isThereNewVersion(
                metaVersion,
                currentVersion
            );

            if (shouldForceRefresh) {
                console.log(
                    `There is a new version (v${metaVersion}). Should force refresh.`
                );
                setCacheStatus((prevState) => ({
                    ...prevState,
                    isLatestVersion: false,
                    refreshCacheAndReloadDeps:
                        !prevState.refreshCacheAndReloadDeps,
                }));
            } else {
                setCacheStatus((prevState) => ({
                    ...prevState,
                    isLatestVersion: true,
                }));
            }
        } catch (err) {
            logger(err);
            setCacheStatus((prevState) => ({
                ...prevState,
                isLatestVersion: true,
            }));
        } finally {
            setCacheStatus((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const isThereNewVersion = (metaVersion: string, currentVersion: string) => {
        if (!currentVersion) return false;

        const metaVersions = metaVersion.split(/\./g);
        const currentVersions = currentVersion.split(/\./g);

        while (metaVersions.length || currentVersions.length) {
            const a = +metaVersions.shift();
            const b = +currentVersions.shift();

            if (a === b) {
                continue;
            }

            return a > b || isNaN(b);
        }

        return false;
    };

    const refreshCacheAndReload = async () => {
        try {
            if (window?.caches) {
                const { caches } = window;
                const cacheKeys = await caches.keys();

                // Clear service worker cache
                await Promise.all(cacheKeys.map((key) => caches.delete(key)));
            }

            // Clear browser cache and reload page
            window.location.reload();
        } catch (err) {
            logger(err);
        }
    };

    if (isEnabled && isAuto && !isLoading && !isLatestVersion) {
        refreshCacheAndReload();
    }

    return {
        isLoading,
        isLatestVersion,
        refreshCacheAndReloadDeps,
        refreshCacheAndReload,
        startCheckCacheStatus: startCheckCacheStatus.current,
        stopCheckCacheStatus: stopCheckCacheStatus.current,
    };
};

export default useCacheBuster;
