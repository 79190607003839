import { FC } from "react";
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import ShiftFilter from "./ShiftScheduleFilter";

const ShiftScheduleList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Shift Schedule List");

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Shift Schedule"
            filters={<ShiftFilter children={""} />}
            perPage={25}
            sort={{ field: "ss_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="edit">
                <TextField source="ss_id" label="Schedule Id" />
                <TextField source="s_id" label="Shift Id" />
                <ReferenceField
                    source="s_id"
                    label="Shift Type"
                    reference="v1/shift"
                    link={false}
                    sortBy="s_id"
                >
                    <TextField source="s_shift_type" />
                </ReferenceField>
                <ReferenceField
                    source="s_id"
                    label="Shift Title"
                    reference="v1/shift"
                    link={false}
                    sortBy="s_id"
                >
                    <TextField source="s_title" />
                </ReferenceField>
                <TextField source="ss_date" label="Date" />
                <TextField source="s_time_start" label="Start Time" />
                <TextField source="s_time_end" label="End Time" />
                <BooleanField
                    source="ss_is_active"
                    label="Active"
                    looseValue
                    FalseIcon={() => null}
                />
            </Datagrid>
        </List>
    );
};

export default ShiftScheduleList;
