import { stringify } from "query-string";
import { fetchUtils } from "react-admin";

import { Status } from "./utils/enums";
import { httpClient } from "./utils/http";

export const toQueryString = (obj, prefix: string = ""): string => {
    var str = [],
        k,
        v;
    for (var p in obj) {
        if (!obj.hasOwnProperty(p)) {
            continue;
        }
        // Skip things from the prototype.
        if (~p.indexOf("[")) {
            k = prefix
                ? prefix +
                  "[" +
                  p.substring(0, p.indexOf("[")) +
                  "]" +
                  p.substring(p.indexOf("["))
                : p;
            // Only put whatever is before the bracket into new brackets; append the rest.
        } else {
            k = prefix ? prefix + "[" + p + "]" : p;
        }
        v = obj[p];
        if (
            null === v ||
            false === v ||
            undefined === v ||
            (typeof v === "object" && !Object.keys(v).length)
        ) {
            v = "";
        }
        str.push(
            typeof v === "object"
                ? toQueryString(v, k)
                : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
    }

    return str.join("&");
};

const dataProvider = {
    getList: (
        resource: string,
        params: {
            pagination: { page: number; perPage: number };
            sort: { field: string; order: string };
            filter: object;
        }
    ) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _orderBy: field,
            _order: order,
            _page: page,
            _perPage: perPage,
        };

        if (
            "v1/medicines" === resource &&
            query.hasOwnProperty("_search") &&
            !query._search
        ) {
            return Promise.resolve({
                data: [],
                total: 0,
            });
        }

        const url = `/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return json;
        });
    },
    getOne: (resource: string, params: { id: number }) =>
        httpClient(`/${resource}/${params.id}`).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                throw new Error(
                    !!json?.message
                        ? json.message
                        : "Something went wrong! Please try again!"
                );
            }
            return json;
        }),
    getMany: (resource: string, params: { ids: number[] }) => {
        const query = {
            ids: `${params.ids}`,
        };

        const url = `/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                throw new Error(
                    !!json?.message
                        ? json.message
                        : "Something went wrong! Please try again!"
                );
            }
            return json;
        });
    },
    getManyReference: (
        resource: string,
        params: {
            pagination: { page: number; perPage: number };
            sort: { field: string; order: string };
            filter: object;
            target;
            id: number;
        }
    ) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id, //not tested
            _orderBy: field,
            _order: order,
            _page: page,
            _perPage: perPage,
        };

        const url = `/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                throw new Error(
                    !!json?.message
                        ? json.message
                        : "Something went wrong! Please try again!"
                );
            }
            return json;
        });
    },
    update: (resource: string, params: { id: number; data }) => {
        const url = `/${resource}/${params.id}`;

        return httpClient(url, {
            method: "POST",
            body: toQueryString(params.data),
        }).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                throw new Error(
                    !!json?.message
                        ? json.message
                        : "Something went wrong! Please try again!"
                );
            }
            return {
                data: json.data,
            };
        });
    },
    updateMany: (resource: string, params: { ids: number[]; data: object }) => {
        const url = `/${resource}/updateMany`;

        const body = {
            ids: params.ids,
            data: params.data,
        };

        return httpClient(url, {
            method: "POST",
            body: toQueryString(body),
        }).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                throw new Error(
                    !!json?.message
                        ? json.message
                        : "Something went wrong! Please try again!"
                );
            }
            return {
                data: json.data,
                message: json?.message,
            };
        });
    },
    create: (resource: string, params: { data: object }) => {
        const url = `/${resource}`;

        return httpClient(url, {
            method: "POST",
            body: toQueryString(params.data),
        }).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                throw new Error(
                    !!json?.message
                        ? json.message
                        : "Something went wrong! Please try again!"
                );
            }
            return {
                data: json.data,
            };
        });
    },
    delete: (resource: string, params: { id: number }) =>
        httpClient(`/${resource}/${params.id}`, {
            method: "DELETE",
        }).then(({ json }: any) => {
            if (json?.status !== Status.SUCCESS) {
                throw new Error(
                    !!json?.message
                        ? json.message
                        : "Something went wrong! Please try again!"
                );
            }
            return {
                data: json.data,
            };
        }),
    // Json server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead.
    deleteMany: (resource: string, params: { ids: number[] }) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`/${resource}/${id}`, {
                    method: "DELETE",
                })
            )
        ).then((responses) => ({
            data: responses?.map(({ json }: any) => json.data?.id),
        })),
};

export default dataProvider;
