import { FC, SetStateAction, useState } from "react";
import {
    Create,
    CreateProps,
    NumberInput,
    SimpleForm,
    Toolbar,
} from "react-admin";

import MedicineForm from "../../../components/manageOrder/orders/MedicineForm";
import OfflineOrdersFooter from "../../../components/manageOrder/orders/OfflineOrdersFooter";
import MedicineTable from "../../../components/manageOrder/orders/medicineTable";
import { useDocumentTitle } from "../../../hooks";

const OfflineOrderCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Offline Order Create");

    const [allItems, setAllItems] = useState([]);
    const [discount, setDiscount] = useState(0);

    const handleOnChange = (e: { target: { value: SetStateAction<number> } }) =>
        setDiscount(e.target.value);

    return (
        <Create {...props}>
            <SimpleForm
                redirect="list"
                submitOnEnter={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
            >
                <NumberInput
                    source="man_discount"
                    label="Discount"
                    variant="outlined"
                    onChange={handleOnChange}
                />
                <MedicineTable allItems={allItems} setAllItems={setAllItems} />
                <MedicineForm
                    createForm
                    allItems={allItems}
                    setAllItems={setAllItems}
                />
                <OfflineOrdersFooter allItems={allItems} discount={discount} />
            </SimpleForm>
        </Create>
    );
};

export default OfflineOrderCreate;
