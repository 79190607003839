import { SelectInput } from "react-admin";

const OrderStatusInput = ({ choices = [], ...rest }) => (
    <SelectInput
        label="Status"
        choices={[
            { id: "processing", name: "Processing" },
            { id: "confirmed", name: "Confirmed" },
            { id: "delivering", name: "Delivering" },
            { id: "delivered", name: "Delivered" },
            { id: "returned", name: "Returned" },
            { id: "cancelled", name: "Cancelled" },
            ...choices,
        ]}
        {...rest}
    />
);

export default OrderStatusInput;
