import { MouseEvent } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    Pagination,
    Record,
    ReferenceManyField,
    TextField,
} from "react-admin";

import DurationField from "../../../../components/manageOrder/orders/DurationField";

const OrdersTab = () => (
    <ReferenceManyField
        label="Orders"
        reference="v1/orders"
        target="u_id"
        pagination={<Pagination />}
        sort={{ field: "o_id", order: "DESC" }}
    >
        <Datagrid>
            <FunctionField
                onClick={(e: MouseEvent) => e.stopPropagation()}
                label="Id"
                sortBy="o_id"
                render={(record: Record) => (
                    <Link to={`/v1/orders/${record.o_id}`}>{record.o_id}</Link>
                )}
            />
            <TextField source="o_created" label="Created" />
            <DurationField />
            <TextField source="o_total" label="Total" />
            <TextField source="o_status" label="Status" />
            <TextField source="o_is_status" label="Issue Status" />
            <TextField source="o_address" label="Address" />
        </Datagrid>
    </ReferenceManyField>
);

export default OrdersTab;
