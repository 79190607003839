import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { TextInput, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";
import CancelReasonInput from "./CancelReasonInput";

type ToProcessingCancelOrderDialogProps = {
    record?: any;
    action: string;
    open: boolean;
    handleDialogClose: () => void;
    [key: string]: any;
};

const ToProcessingCancelOrderDialog: FC<ToProcessingCancelOrderDialogProps> = ({
    record,
    action,
    open,
    handleDialogClose,
    ...rest
}) => {
    const redirect = useRedirect();
    const { values } = useFormState();

    const { cancel_reason, note } = values;

    const isToProcessing = action === "toProcessing";

    const { isLoading, refetch } = useRequest(
        `/${rest.resource}/${isToProcessing ? "cancel2processing" : "cancel"}/${
            record?.o_id
        }/`,
        {
            method: "POST",
            body: !isToProcessing ? { reason: note } : null,
        },
        {
            isRefresh: isToProcessing,
            onSuccess: () => {
                handleDialogClose();
                !isToProcessing && redirect("list", "/v1/orders");
            },
        }
    );

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>
                {isToProcessing
                    ? "Are you sure you want move to processing this order?"
                    : "Are you sure you want to cancel this order?"}
            </DialogTitle>
            {!isToProcessing && (
                <DialogContent>
                    <CancelReasonInput />
                    {cancel_reason && (
                        <TextInput
                            source="note"
                            label="Note (Visible to Customer)"
                            variant="outlined"
                            minRows={2}
                            multiline
                            fullWidth
                        />
                    )}
                </DialogContent>
            )}
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default ToProcessingCancelOrderDialog;
