import { makeStyles } from "@material-ui/core";
import { useCallback } from "react";
import {
    FormDataConsumer,
    SaveButton,
    Toolbar,
    useRecordContext,
} from "react-admin";
import { useForm } from "react-final-form";

const OrderEditToolbar = (props) => {
    const classes = useStyles();
    const record = useRecordContext();

    const disabled = () =>
        !record?.address_checked &&
        record?.o_status === "processing" &&
        record?.o_i_status === "processing";

    const ConfirmButton = ({ handleSubmitWithRedirect, ...rest }: any) => {
        const form = useForm();

        const handleClick = useCallback(() => {
            form.change("o_status", "confirmed");
            form.change("o_i_status", "warehouse_fb");

            handleSubmitWithRedirect("list");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [form]);

        return (
            <SaveButton
                {...rest}
                handleSubmitWithRedirect={handleClick}
                className={classes.button}
                disabled={disabled()}
            />
        );
    };

    return (
        <Toolbar {...props}>
            <FormDataConsumer>
                {({ formData }) =>
                    !formData.isAddressConfirmButtonShow && (
                        <SaveButton disabled={disabled()} {...props} />
                    )
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => {
                    if (
                        !formData.isAddressConfirmButtonShow &&
                        formData.medicineQty?.length &&
                        formData.o_status === "processing" &&
                        formData.o_i_status === "processing" &&
                        formData.o_de_id &&
                        formData.s_address?.area
                    )
                        return (
                            <ConfirmButton label="Confirm Order" {...props} />
                        );
                }}
            </FormDataConsumer>
        </Toolbar>
    );
};

const useStyles = makeStyles({
    button: {
        marginLeft: 10,
    },
});

export default OrderEditToolbar;
