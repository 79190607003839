import { Grid, makeStyles } from "@material-ui/core";
import { FC, useEffect, useRef, useState } from "react";
import { AutocompleteInput, NumberInput, TextInput } from "react-admin";
import { useForm } from "react-final-form";

type ExpensesFormProps = {
    choices: { id: string; name: string }[];
    setAllItems: (items) => void;
};

const ExpensesForm: FC<ExpensesFormProps> = ({ choices, setAllItems }) => {
    const classes = useStyles();
    const form = useForm();
    const values = form.getState().values;

    const fieldRef1 = useRef<HTMLInputElement>(null!);
    const fieldRef2 = useRef<HTMLInputElement>(null!);
    const fieldRef3 = useRef<HTMLInputElement>(null!);

    const [ledgerType, setLedgerType] = useState("");

    const handleOnKeyDown = () => {
        const { l_type, l_a_uid, l_a_date, l_reason, l_amount } = values;

        setAllItems((prevState: object[]) => [
            {
                l_type,
                l_a_uid,
                l_a_date,
                l_reason,
                l_amount,
            },
            ...prevState,
        ]);

        form.change("l_type", undefined);
        l_a_uid && form.change("l_a_uid", undefined);
        form.change("l_reason", undefined);
        form.change("l_amount", undefined);

        setTimeout(() => {
            fieldRef1.current.focus();
        }, 1);
    };

    useEffect(() => {
        if (!ledgerType) return;

        fieldRef2.current?.focus();
    }, [ledgerType]);

    return (
        <div className={classes.root}>
            <div className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item md={2}>
                        <AutocompleteInput
                            source="l_type"
                            label="Type"
                            variant="outlined"
                            options={{
                                InputProps: { inputRef: fieldRef1 },
                            }}
                            onSelect={(item) => setLedgerType(item.name)}
                            choices={choices}
                            resettable
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <TextInput
                            source="l_reason"
                            label="Reason"
                            variant="outlined"
                            inputRef={fieldRef2}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setTimeout(() => {
                                        fieldRef3.current.focus();
                                    }, 1);
                                }
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <NumberInput
                            source="l_amount"
                            label="Amount"
                            variant="outlined"
                            inputRef={fieldRef3}
                            onKeyDown={(e: any) => {
                                if (e.key === "Enter" && e.target.value)
                                    handleOnKeyDown();
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
    },
    form: {
        flexGrow: 8,
    },
});

export default ExpensesForm;
