import { Box, Button, makeStyles } from "@material-ui/core";
import { FC, useState } from "react";
import {
    Edit,
    EditProps,
    FileField,
    FileInput,
    FormDataConsumer,
    FormTab,
    NumberInput,
    TabbedForm,
    TextInput,
    Toolbar,
    email,
    maxLength,
    minLength,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import SendSmsDialog from "../../../components/SendSmsDialog";
import BonusDialog from "../../../components/manageUser/users/BonusDialog";
import PermissionsTab from "../../../components/manageUser/users/PermissionsTab";
import UserRoleInput from "../../../components/manageUser/users/UserRoleInput";
import UserStatusInput from "../../../components/manageUser/users/UserStatusInput";
import { useDocumentTitle } from "../../../hooks";
import { required } from "../../../utils/helpers";

const UserEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | User Edit");

    const classes = useStyles();

    const [openSendSmsDialog, setOpenSendSmsDialog] = useState(false);
    const [openBonusDialog, setOpenBonusDialog] = useState(false);
    const [bonusAmount, setBonusAmount] = useState(0);

    const isRoleAdministrator = permissions?.includes("role:administrator");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <TabbedForm toolbar={<Toolbar alwaysEnableSaveButton />}>
                <FormTab label="User">
                    <TextInput
                        source="u_id"
                        label="Id"
                        variant="outlined"
                        disabled
                    />
                    <TextInput
                        source="u_name"
                        label="Name"
                        variant="outlined"
                        validate={[required()]}
                    />
                    <Box display="flex" width="100%" gridGap={5}>
                        <TextInput
                            source="u_mobile"
                            label="Mobile"
                            variant="outlined"
                            style={{ width: "256px" }}
                            validate={[required()]}
                        />
                        <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={() => setOpenSendSmsDialog(true)}
                            disableElevation
                        >
                            Send SMS
                        </Button>
                    </Box>
                    <SendSmsDialog
                        pageName="users"
                        open={openSendSmsDialog}
                        handleClose={() => setOpenSendSmsDialog(false)}
                    />
                    <TextInput
                        source="u_email"
                        label="Email"
                        variant="outlined"
                        validate={email("Invalid email address")}
                    />
                    <TextInput
                        source="u_token"
                        label="Token"
                        variant="outlined"
                    />
                    <TextInput
                        source="u_lat"
                        label="Latitude"
                        variant="outlined"
                    />
                    <TextInput
                        source="u_long"
                        label="Longitude"
                        variant="outlined"
                    />
                    <TextInput
                        source="u_created"
                        label="Created"
                        variant="outlined"
                    />
                    <TextInput
                        source="u_updated"
                        label="Updated"
                        variant="outlined"
                        disabled
                    />
                    <Box display="flex" width="100%" gridGap={5}>
                        <NumberInput
                            source="u_cash"
                            label="Cash"
                            variant="outlined"
                            style={{ width: "256px" }}
                            disabled={!isRoleAdministrator}
                        />
                        <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={() => {
                                setBonusAmount(20);
                                setOpenBonusDialog(true);
                            }}
                            disableElevation
                        >
                            Bonus 20
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={() => {
                                setBonusAmount(50);
                                setOpenBonusDialog(true);
                            }}
                            disableElevation
                        >
                            Bonus 50
                        </Button>
                    </Box>
                    <BonusDialog
                        open={openBonusDialog}
                        handleClose={() => setOpenBonusDialog(false)}
                        bonusAmount={bonusAmount}
                    />
                    <NumberInput
                        source="u_p_cash"
                        label="Pending Cash"
                        variant="outlined"
                        disabled
                    />
                    <NumberInput
                        source="u_otp"
                        label="OTP"
                        variant="outlined"
                    />
                    <TextInput
                        source="u_otp_time"
                        label="OTP Time"
                        variant="outlined"
                    />
                    <TextInput
                        source="u_referrer"
                        label="Referrer Code"
                        variant="outlined"
                        validate={[minLength(4), maxLength(20)]}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (!formData.is_influencer || !formData.u_referrer)
                                return null;
                            return (
                                <Box mb={3}>
                                    {`https://www.arogga.com/s/${formData.u_referrer}/ari`}
                                </Box>
                            );
                        }}
                    </FormDataConsumer>
                    <NumberInput
                        source="u_r_uid"
                        label="Referrer Id"
                        variant="outlined"
                    />
                    <TextInput
                        source="i_help_id"
                        label="I Help BD Id"
                        variant="outlined"
                    />
                    {permissions?.includes("userChangeRole") && (
                        <UserRoleInput
                            source="u_role"
                            label="Role"
                            variant="outlined"
                        />
                    )}
                    <UserStatusInput source="u_status" variant="outlined" />
                    <TextInput
                        source="u_note"
                        label="Note"
                        variant="outlined"
                        minRows={2}
                        multiline
                    />
                    {permissions?.includes("addInfluencer") && (
                        <FormatedBooleanInput
                            source="is_influencer"
                            label="Is Influencer?"
                        />
                    )}
                    <FileInput
                        source="attachedFiles"
                        label="Related Files"
                        accept="image/*, application/pdf"
                        maxSize={10000000}
                        options={{ maxSize: 5 }}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </FormTab>
                {permissions?.includes("permissionMenu") && (
                    <FormTab label="Permissions" path="permissions">
                        <PermissionsTab />
                    </FormTab>
                )}
            </TabbedForm>
        </Edit>
    );
};

const useStyles = makeStyles((theme) => ({
    btn: {
        width: "100px",
        height: "40px",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

export default UserEdit;
