import { FC } from "react";
import {
    NumberField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import { useDocumentTitle } from "../../../hooks";

const CompanyShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Company Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="c_name" label="Name" />
                <NumberField source="c_uid" label="User Id" />
                <TextField source="c_info" label="Information" />
            </SimpleShowLayout>
        </Show>
    );
};

export default CompanyShow;
