import { FC } from "react";
import {
    Datagrid,
    FileField,
    FunctionField,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import PharmacyFilter from "./PharmacyFilter";

const PharmacyList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Pharmacy List");
    const exporter = useExport(rest);

    const rowStyle = ({ p_status }: Record) => ({
        backgroundColor:
            p_status === 1
                ? "rgb(0 128 105 / 6%)"
                : p_status === 2
                ? "rgb(255 229 229)"
                : "",
    });

    return (
        <List
            {...rest}
            title="List of Pharmacy"
            perPage={25}
            filters={<PharmacyFilter children={""} />}
            sort={{ field: "p_id", order: "DESC" }}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("pharmacyDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid rowClick="show" rowStyle={rowStyle}>
                <TextField source="p_id" label="Id" />
                <TextField source="p_name" label="Name" />
                <ReferenceField
                    source="p_user_id"
                    label="User"
                    reference="v1/users"
                    link="show"
                    sortBy="p_user_id"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <NumberField
                    source="p_drug_license_no"
                    label="Drug License No"
                />
                <FileField
                    source="attachedFiles-p_drug_license_file"
                    src="src"
                    title="Drug License Files"
                    target="_blank"
                    label="Drug License Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
                <NumberField
                    source="p_trade_license_no"
                    label="Trade License No"
                />
                <FileField
                    source="attachedFiles-p_trade_license_file"
                    src="src"
                    title="Trade License Files"
                    target="_blank"
                    label="Trade License Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
                <ReferenceField
                    source="p_location_id"
                    label="Location"
                    reference="v1/locations"
                    link="show"
                    sortBy="p_location_id"
                >
                    <FunctionField
                        render={(record) => {
                            if (!record) return "";
                            return `${record.l_division} -> ${record.l_district} -> ${record.l_area}`;
                        }}
                    />
                </ReferenceField>
                <TextField source="p_address" label="Address" />
                <TextField source="p_status" label="Status" />
            </Datagrid>
        </List>
    );
};

export default PharmacyList;
