import { FC } from "react";
import {
    FunctionField,
    NumberField,
    Record,
    ReferenceArrayField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
} from "react-admin";

import OrdersDatagrid from "../../../components/manageFinance/collections/OrdersDatagrid";
import { useDocumentTitle } from "../../../hooks";

const CollectionShow: FC<ShowProps> = ({
    hasList,
    hasEdit,
    hasShow,
    ...rest
}) => {
    useDocumentTitle("Arogga | Collection Show");

    return (
        <Show {...rest}>
            <TabbedShowLayout>
                <Tab label="Information">
                    <TextField source="co_created" label="Date" />
                    <TextField
                        source="fm_name"
                        label="From User"
                        sortable={false}
                    />
                    <TextField
                        source="to_name"
                        label="To User"
                        sortable={false}
                    />
                    <NumberField source="co_amount" label="Amount" />
                    <NumberField source="co_s_amount" label="Supplier Amount" />
                    <NumberField source="profit" label="Profit" />
                    <TextField source="co_status" label="Status" />
                    <FunctionField
                        label="Bag"
                        render={(record: Record) => (
                            <>
                                {!!record?.co_bag?.f_bag && (
                                    <span>{record?.co_bag?.f_bag}</span>
                                )}

                                {!!record?.co_bag?.t_bag && (
                                    <span>{` -> ${record?.co_bag?.t_bag}`}</span>
                                )}

                                {!!record?.co_bag?.o_ids && (
                                    <span>{` (${record?.co_bag?.o_ids})`}</span>
                                )}
                            </>
                        )}
                    />
                    <FunctionField
                        label="Cancel Ids"
                        render={(record: Record) => (
                            <>
                                {!!record?.co_bag?.c_o_ids && (
                                    <span>{`${record?.co_bag?.c_o_ids}`}</span>
                                )}
                            </>
                        )}
                    />
                </Tab>
                <Tab label="Delivered Orders">
                    <FunctionField
                        addLabel={false}
                        render={(record: Record) => (
                            <ReferenceArrayField
                                source="o_ids"
                                reference="v1/orders"
                                record={{
                                    ...record,
                                    o_ids: JSON.parse(record.o_ids),
                                }}
                                sort={{
                                    field: "o_delivered",
                                    order: "ASC",
                                }}
                                {...rest}
                            >
                                <OrdersDatagrid />
                            </ReferenceArrayField>
                        )}
                    />
                </Tab>
                <Tab label="Move Orders">
                    <FunctionField
                        addLabel={false}
                        render={(record: Record) => (
                            <ReferenceArrayField
                                source="c_o_ids"
                                reference="v1/orders"
                                record={{
                                    ...record,
                                    c_o_ids: record?.co_bag?.o_ids,
                                }}
                                {...rest}
                            >
                                <OrdersDatagrid />
                            </ReferenceArrayField>
                        )}
                    />
                </Tab>
                <Tab label="Cancel Orders">
                    <FunctionField
                        addLabel={false}
                        render={(record: Record) => (
                            <ReferenceArrayField
                                source="o_ids"
                                reference="v1/orders"
                                record={{
                                    ...record,
                                    o_ids: record?.co_bag?.c_o_ids,
                                }}
                                {...rest}
                            >
                                <OrdersDatagrid />
                            </ReferenceArrayField>
                        )}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default CollectionShow;
