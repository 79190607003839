import { Box } from "@material-ui/core";
import { useState } from "react";
import { NumberInput } from "react-admin";
import { useFormState } from "react-final-form";

import QtyLabel from "../../QtyLabel";
import AuditedPercentage from "./AuditedPercentage";

const NewNumberInput = (props) => {
    const { values } = useFormState();

    const [auditedQty, setAuditedQty] = useState(0);

    const inShelfDefaultValue =
        (parseInt(values.i_qty) || 0) + (parseInt(values?.packing_qty) || 0);

    const handleChange = (e) =>
        props.source === "audited_qty" && setAuditedQty(e.target.value);

    let inShelf;

    if (auditedQty) {
        // If auditedQty is set, thats mean user editing the qty, so we should use the in_shelf instead of shelf_qty.
        inShelf = values.in_shelf;
    } else if (values.shelf_qty) {
        inShelf = values.shelf_qty;
    } else {
        inShelf = values.in_shelf;
    }

    return (
        <Box display="flex" width="100%">
            <NumberInput
                defaultValue={
                    props.source === "in_shelf" ? inShelfDefaultValue : ""
                }
                onChange={handleChange}
                {...props}
            />
            <Box display="flex" ml={2} mt={3}>
                <QtyLabel qty={values[props.source]} medicine={values} />
                {props.source === "audited_qty" && (
                    <>
                        <Box ml={1} />
                        <AuditedPercentage
                            inShelf={inShelf}
                            auditedQty={values.audited_qty}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default NewNumberInput;
