import { useEffect } from "react";

const useKeyboardShortcut = () => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            const key = e.key;

            if (e.ctrlKey && e.shiftKey && (key === "p" || key === "P")) {
                window.location.assign(
                    `${window.location.pathname}#/v1/purchaseInvoice/create`
                );
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, []);
};

export default useKeyboardShortcut;
