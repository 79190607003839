/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button as MuiButton, Paper, makeStyles } from "@material-ui/core";
import { FC, MouseEvent, useEffect, useState } from "react";
import {
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    ImageField,
    List,
    ListProps,
    NumberField,
    Record,
    SimpleForm,
    TextField,
    Toolbar,
    ToolbarProps,
    useRefresh,
    useUnselectAll,
} from "react-admin";
import { Link } from "react-router-dom";

import "../../../assets/style.css";
import MedicinesAssignTo from "../../../components/MedicinesAssignTo";
import PurchaseRequestDialog from "../../../components/PurchaseRequestDialog";
import QtyLabel from "../../../components/QtyLabel";
import AuditedPercentage from "../../../components/manageStock/inventories/AuditedPercentage";
import GenerateOrderDialog from "../../../components/manageStock/inventories/GenerateOrderDialog";
import GenerateOrderSaveDialog from "../../../components/manageStock/inventories/GenerateOrderSaveDialog";
import GenerateOrderSheet from "../../../components/manageStock/inventories/GenerateOrderSheet";
import { useDocumentTitle, useExport, useRequest } from "../../../hooks";
import { toFilterObj } from "../../../utils/helpers";
import InventoryFilter from "./InventoryFilter";
import InventoryListActions from "./InventoryListActions";
import InventoryListBulkActionButtons from "./InventoryListBulkActionButtons";
import InventoryPagination from "./InventoryPagination";

const InventoryList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Inventory List");
    const exporter = useExport(rest);

    const classes = useStyles();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    const [open, setOpen] = useState(false);
    const [action, setAction] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isGenerateOrderDialogOpen, setIsGenerateOrderDialogOpen] =
        useState(false);
    const [unselect, setUnselect] = useState(false);
    const [isAssignTo, setIsAssignTo] = useState(false);
    const [isGenerateOrder, setIsGenerateOrder] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [requestDays, setRequestDays] = useState("");
    const [requestCount, setRequestCount] = useState("");
    const [isIncludeLaterMedicine, setIsIncludeLaterMedicine] = useState(0);
    const [inventoryId, setInventoryId] = useState("");
    const [dialogInfo, setDialogInfo] = useState<any>({});
    const [formValues, setFormValues] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [purchaser, setPurchaser] = useState("");
    const [medicines, setMedicines] = useState([]);

    const { isLoading, refetch } = useRequest(
        `/v1/inventory/correctionRequestAction/${inventoryId}`,
        {
            method: "POST",
            body: { action },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    useEffect(() => {
        if (unselect) {
            unselectAll(rest.resource);
            refresh();
        }
    }, [unselect]);

    const modifiedSelectedItems = selectedItems?.map((item) => {
        if (Object.keys(formValues)?.includes(`qty_text-${item?.id}`))
            return {
                m_id: item?.m_id,
                m_name: item?.m_name,
                m_strength: item?.m_strength,
                m_form: item?.m_form,
                qty_text: formValues[`qty_text-${item?.id}`],
            };

        return item;
    });

    const isRoleAuditor = permissions?.includes("role:auditor");

    const inventoryRowClick = () =>
        permissions?.includes("role:auditor") ? "edit" : "show";

    interface ISaveToolbarProps extends ToolbarProps {
        setOpen: (value: boolean) => void;
    }

    const SaveToolbar = ({ setOpen, ...rest }: ISaveToolbarProps) => {
        const classes = useStyles();

        return (
            <Toolbar
                {...rest}
                className={classes.toolbarPosition}
                disableGutters
            >
                <MuiButton
                    color="primary"
                    onClick={() => {
                        setIsAssignTo(false);
                        setIsGenerateOrder(false);
                    }}
                >
                    Cancel
                </MuiButton>
                <MuiButton
                    color="primary"
                    variant="contained"
                    onClick={() => setOpen(true)}
                    disabled={isAssignTo && !purchaser}
                >
                    Save
                </MuiButton>
            </Toolbar>
        );
    };

    const DialogContent = () => {
        const { new_correction, m_form, m_unit, m_name, m_strength } =
            dialogInfo;

        return (
            <>
                {m_name} - {m_form} - {m_strength}
                {": "}
                <QtyLabel
                    qty={new_correction}
                    medicine={{ m_form, m_unit }}
                />{" "}
                {new_correction > 0 ? "(Found)" : "(Lost)"}
            </>
        );
    };

    const filterObj = toFilterObj(rest.location.search);

    return (
        <>
            {!isAssignTo && !isGenerateOrder ? (
                <List
                    {...rest}
                    title="List of Inventory"
                    pagination={<InventoryPagination />}
                    filters={<InventoryFilter children={""} />}
                    perPage={25}
                    sort={{ field: "i_id", order: "DESC" }}
                    actions={
                        <InventoryListActions
                            setIsGenerateOrderDialogOpen={
                                setIsGenerateOrderDialogOpen
                            }
                        />
                    }
                    bulkActionButtons={
                        <InventoryListBulkActionButtons
                            setIsAssignTo={setIsAssignTo}
                            setSelectedItems={setSelectedItems}
                            deletePermissionKey="inventoryDelete"
                        />
                    }
                    exporter={exporter}
                >
                    <Datagrid rowClick={inventoryRowClick}>
                        <TextField
                            source="warehouse_name"
                            label="Warehouse"
                            sortable={false}
                        />
                        {!isRoleAuditor && (
                            <TextField
                                label="Purchase Assigned"
                                source="purchaseAssigned"
                                sortBy="u_id"
                            />
                        )}
                        <FunctionField
                            sortBy="m_name"
                            onClick={(e: MouseEvent) =>
                                !isRoleAuditor && e.stopPropagation()
                            }
                            label="Medicine"
                            render={(record: Record) => {
                                if (isRoleAuditor) return record.m_name;

                                return (
                                    <Link to={`/v1/medicines/${record.m_id}`}>
                                        {record.m_name}
                                    </Link>
                                );
                            }}
                        />
                        <TextField source="m_form" label="Form" />
                        <TextField source="m_strength" label="Strength" />
                        <TextField source="m_unit" label="Unit" />
                        <FunctionField
                            label="Quantity"
                            sortBy="i_qty"
                            render={({ i_qty, m_form, m_unit }: Record) => (
                                <QtyLabel
                                    qty={i_qty}
                                    medicine={{ m_form, m_unit }}
                                />
                            )}
                        />
                        {!!filterObj._auditStatus && (
                            <FunctionField
                                label="Audited Qty"
                                render={({
                                    audited_qty,
                                    m_form,
                                    m_unit,
                                }: Record) => (
                                    <QtyLabel
                                        qty={audited_qty}
                                        medicine={{ m_form, m_unit }}
                                    />
                                )}
                            />
                        )}
                        {!!filterObj._auditStatus && (
                            <FunctionField
                                label="Diff"
                                render={({
                                    i_qty,
                                    packing_qty,
                                    shelf_qty,
                                    audited_qty,
                                }: Record) => {
                                    const inShelfDefaultValue =
                                        (parseInt(i_qty) || 0) +
                                        (parseInt(packing_qty) || 0);
                                    return (
                                        <AuditedPercentage
                                            inShelf={
                                                shelf_qty || inShelfDefaultValue
                                            }
                                            auditedQty={audited_qty}
                                        />
                                    );
                                }}
                            />
                        )}
                        {!!filterObj._auditStatus && (
                            <FunctionField
                                label="In Shelf When Audited"
                                render={({
                                    shelf_qty,
                                    m_form,
                                    m_unit,
                                }: Record) => (
                                    <QtyLabel
                                        qty={shelf_qty}
                                        medicine={{ m_form, m_unit }}
                                    />
                                )}
                            />
                        )}
                        {!!filterObj._auditStatus && (
                            <TextField
                                source="audited_by_name"
                                label="Audited By"
                                sortable={false}
                            />
                        )}
                        {!!filterObj._auditStatus && (
                            <TextField
                                source="a_audited"
                                label="Audited Time"
                            />
                        )}
                        {!isRoleAuditor && (
                            <FunctionField
                                label="Wkly Req"
                                sortBy="wkly_req"
                                render={({
                                    wkly_req,
                                    m_form,
                                    m_unit,
                                }: Record) => (
                                    <QtyLabel
                                        qty={wkly_req}
                                        medicine={{ m_form, m_unit }}
                                    />
                                )}
                            />
                        )}
                        {!isRoleAuditor && (
                            <FunctionField
                                label="Stock (days)"
                                sortBy="stock_days"
                                render={({ stock_days }: Record) => (
                                    <span
                                        style={
                                            stock_days && stock_days < 7
                                                ? { color: "#EF1962" }
                                                : {}
                                        }
                                    >
                                        {stock_days}
                                    </span>
                                )}
                            />
                        )}
                        {!isRoleAuditor && (
                            <NumberField source="i_price" label="TP Price" />
                        )}
                        {!isRoleAuditor && (
                            <NumberField
                                source="m_d_price"
                                label="Sell Price"
                            />
                        )}
                        {!isRoleAuditor && (
                            <NumberField source="m_price" label="MRP" />
                        )}
                        {!isRoleAuditor && (
                            <NumberField source="i_price_total" label="Total" />
                        )}
                        {!isRoleAuditor && (
                            <FunctionField
                                label="MRP Percent"
                                sortBy="discount_percent"
                                render={({ discount_percent }: Record) => (
                                    <span
                                        style={
                                            discount_percent < 0 ||
                                            discount_percent > 50
                                                ? { color: "#EF1962" }
                                                : { color: "green" }
                                        }
                                    >
                                        {discount_percent}%
                                    </span>
                                )}
                            />
                        )}
                        {!isRoleAuditor && (
                            <FunctionField
                                label="Profit Percent"
                                sortBy="profit_percent"
                                render={({ profit_percent }: Record) => (
                                    <span
                                        style={
                                            profit_percent < 0 ||
                                            profit_percent > 50
                                                ? { color: "#EF1962" }
                                                : { color: "green" }
                                        }
                                    >
                                        {profit_percent}%
                                    </span>
                                )}
                            />
                        )}
                        {!isRoleAuditor && (
                            <FunctionField
                                label="Wholesale Profit Percent"
                                sortBy="wh_profit_percent"
                                render={({ wh_profit_percent }: Record) => (
                                    <span
                                        style={
                                            wh_profit_percent < 0 ||
                                            wh_profit_percent > 50
                                                ? { color: "#EF1962" }
                                                : { color: "green" }
                                        }
                                    >
                                        {wh_profit_percent}%
                                    </span>
                                )}
                            />
                        )}

                        {!isRoleAuditor && (
                            <NumberField
                                source="m_r_count"
                                label="Request Count"
                            />
                        )}
                        <FunctionField
                            label="Previous Correction"
                            sortBy="qty_correction"
                            render={({ prev_correction, ...rest }: Record) => {
                                if (!prev_correction) return;

                                return (
                                    <>
                                        <QtyLabel
                                            qty={prev_correction}
                                            medicine={rest}
                                        />
                                        <Box ml={1} />
                                        <span>
                                            {prev_correction > 0
                                                ? "(Found)"
                                                : "(Lost)"}
                                        </span>
                                    </>
                                );
                            }}
                        />
                        {!!filterObj._correctionPending && (
                            <FunctionField
                                label="New Correction"
                                render={({
                                    new_correction,
                                    ...rest
                                }: Record) => {
                                    if (!new_correction) return;

                                    return (
                                        <>
                                            <QtyLabel
                                                qty={new_correction}
                                                medicine={rest}
                                            />
                                            <Box ml={1} />
                                            <span>
                                                {+new_correction > 0
                                                    ? "(Found)"
                                                    : "(Lost)"}
                                            </span>
                                        </>
                                    );
                                }}
                            />
                        )}
                        {permissions?.includes("role:administrator") &&
                            !!filterObj._correctionPending && (
                                <FunctionField
                                    label="Action"
                                    render={({
                                        i_id,
                                        new_correction,
                                        m_form,
                                        m_unit,
                                        m_name,
                                        m_strength,
                                    }: Record) => (
                                        <Box display="flex">
                                            <Button
                                                label="Approve"
                                                variant="outlined"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setAction("approve");
                                                    setInventoryId(i_id);
                                                    setDialogInfo({
                                                        new_correction,
                                                        m_form,
                                                        m_unit,
                                                        m_name,
                                                        m_strength,
                                                    });
                                                    setIsDialogOpen(true);
                                                }}
                                            />
                                            <Box ml={1} />
                                            <Button
                                                label="Reject"
                                                variant="outlined"
                                                color="secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setAction("reject");
                                                    setInventoryId(i_id);
                                                    setDialogInfo({
                                                        new_correction,
                                                        m_form,
                                                        m_unit,
                                                        m_name,
                                                        m_strength,
                                                    });
                                                    setIsDialogOpen(true);
                                                }}
                                            />
                                        </Box>
                                    )}
                                />
                            )}
                        <ImageField
                            source="attachedFiles"
                            src="src"
                            className="small__img"
                            title="title"
                            label="Pictures"
                        />
                    </Datagrid>
                </List>
            ) : (
                <Paper
                    style={{ marginTop: 25 }}
                    classes={{
                        root: classes.root,
                    }}
                >
                    <SimpleForm toolbar={<SaveToolbar setOpen={setOpen} />}>
                        {isAssignTo ? (
                            <>
                                <MedicinesAssignTo
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    setFormValues={setFormValues}
                                    setPurchaser={setPurchaser}
                                />
                                <PurchaseRequestDialog
                                    open={open}
                                    setOpen={setOpen}
                                    setUnselect={setUnselect}
                                    formValues={formValues}
                                    purchaser={purchaser}
                                    setIsAssignTo={setIsAssignTo}
                                    modifiedSelectedItems={
                                        modifiedSelectedItems
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <GenerateOrderSheet
                                    companyId={companyId}
                                    requestDays={requestDays}
                                    requestCount={requestCount}
                                    isIncludeLaterMedicine={
                                        isIncludeLaterMedicine
                                    }
                                    medicines={medicines}
                                    setMedicines={setMedicines}
                                    setFormValues={setFormValues}
                                />
                                <GenerateOrderSaveDialog
                                    open={open}
                                    setOpen={setOpen}
                                    medicines={medicines}
                                    setIsGenerateOrder={setIsGenerateOrder}
                                />
                            </>
                        )}
                    </SimpleForm>
                </Paper>
            )}
            <SimpleForm toolbar={false}>
                <GenerateOrderDialog
                    open={isGenerateOrderDialogOpen}
                    setOpen={setIsGenerateOrderDialogOpen}
                    setCompanyId={setCompanyId}
                    setRequestDays={setRequestDays}
                    setRequestCount={setRequestCount}
                    setIsIncludeLaterMedicine={setIsIncludeLaterMedicine}
                    setIsGenerateOrder={setIsGenerateOrder}
                />
            </SimpleForm>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to ${action} this pending correction?`}
                content={<DialogContent />}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    toolbarPosition: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "15px",
    },
});

export default InventoryList;
