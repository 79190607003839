import { Box, Button, Typography } from "@material-ui/core";
import { FC, useState } from "react";
import { Confirm, SelectInput, TextInput } from "react-admin";
import { useForm } from "react-final-form";

import { useRequest } from "../../../hooks";

const CallTab: FC = () => {
    const form = useForm();

    const [isOpenDialog, setIsOpenDialog] = useState(false);

    const { method, base, text } = form.getState().values;

    const { isLoading, refetch } = useRequest(
        `/${base}/${text}`,
        {
            method,
        },
        {
            isBaseUrl: true,
            onSuccess: () => {
                form.change("method", undefined);
                form.change("base", undefined);
                form.change("text", undefined);
                setIsOpenDialog(false);
            },
        }
    );

    const DialogContent = () => (
        <>
            <Typography>Method: {method}</Typography>
            <Typography>
                URL:{" "}
                {`${
                    process.env.REACT_APP_API_URL.split("/admin")[0]
                }/${base}/${text}`}
            </Typography>
        </>
    );

    return (
        <Box display="flex" gridGap={8}>
            <SelectInput
                source="method"
                label="Method"
                variant="outlined"
                choices={[
                    {
                        id: "GET",
                        name: "GET",
                    },
                    {
                        id: "POST",
                        name: "POST",
                    },
                ]}
            />
            <Box mt={2}>
                <Typography>
                    {process.env.REACT_APP_API_URL.split("/admin")[0]}/
                </Typography>
            </Box>
            <SelectInput
                source="base"
                label="Base"
                variant="outlined"
                choices={[
                    {
                        id: "onetime",
                        name: "onetime",
                    },
                    {
                        id: "cron",
                        name: "cron",
                    },
                ]}
            />
            <Box mt={3}>
                <Typography>/</Typography>
            </Box>
            <TextInput source="text" label="Text" variant="outlined" />
            <Button
                variant="contained"
                onClick={() => setIsOpenDialog(true)}
                style={{
                    marginTop: "8px",
                    height: "40px",
                }}
                disabled={!method || !base || !text}
            >
                Call
            </Button>
            <Confirm
                isOpen={isOpenDialog}
                loading={isLoading}
                title="Are you sure you want to call this?"
                content={<DialogContent />}
                onConfirm={refetch}
                onClose={() => setIsOpenDialog(false)}
            />
        </Box>
    );
};

export default CallTab;
