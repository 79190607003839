import { SelectInput } from "react-admin";

const UserLedgerStatusInput = (props) => (
    <SelectInput
        label="Status"
        choices={[
            { id: "pending", name: "Pending" },
            { id: "confirmed", name: "Confirmed" },
            { id: "withdrawing", name: "Withdrawing" },
            { id: "withdrawn", name: "Withdrawn" },
        ]}
        {...props}
    />
);

export default UserLedgerStatusInput;
