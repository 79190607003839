import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";

const PurchaseInvoiceFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <FormatedBooleanInput source="_not_paid" label="Not Paid" alwaysOn />
        <ReferenceInput
            source="_c_id"
            label="Company"
            variant="outlined"
            reference="v1/companies"
            filterToQuery={(searchText) => ({ _search: searchText })}
            sort={{ field: "c_name", order: "ASC" }}
        >
            <AutocompleteInput
                optionValue="c_id"
                optionText="c_name"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_warehouse_id"
            label="Warehouse"
            variant="outlined"
            reference="v1/warehouse"
            filter={{ _role: "warehouse" }}
            filterToQuery={(searchText) => ({ _search: searchText })}
        >
            <AutocompleteInput
                optionValue="w_id"
                optionText="w_title"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_checked_by"
            label="Checked By"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({ _search: searchText })}
        >
            <AutocompleteInput
                optionValue="u_id"
                optionText="u_name"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_created_by"
            label="Created By"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({ _search: searchText })}
        >
            <AutocompleteInput
                optionValue="u_id"
                optionText="u_name"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_shelved_by"
            label="Shelved By"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({ _search: searchText })}
        >
            <AutocompleteInput
                optionValue="u_id"
                optionText="u_name"
                resettable
            />
        </ReferenceInput>
        <SelectInput
            source="_payment_method"
            label="Payment Method"
            variant="outlined"
            choices={[
                { id: "cash", name: "Cash" },
                {
                    id: "bank",
                    name: "Bank",
                },
                {
                    id: "payable",
                    name: "Payable",
                },
            ]}
        />
        <SelectInput
            source="_status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "sync", name: "Sync" },
            ]}
        />
    </Filter>
);

export default PurchaseInvoiceFilter;
