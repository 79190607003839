import {
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles,
} from "@material-ui/core";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { BooleanInput, FunctionField, ImageField, Record } from "react-admin";
import { useFormState } from "react-final-form";
import { useHistory } from "react-router-dom";

import AroggaButton from "../../../../components/AroggaButton";
import MedicineCalculate from "../../../../components/manageOrder/orders/MedicineCalculate";
import MedicineForm from "../../../../components/manageOrder/orders/MedicineForm";
import MedicineTable from "../../../../components/manageOrder/orders/medicineTable";
import Preview from "../../../../components/manageOrder/orders/prescription/Preview";
import { useRequest } from "../../../../hooks";

type MedicinesTabProps = {
    permissions: string[];
    userCash: any;
    [key: string]: any;
};

const MedicinesTab: FC<MedicinesTabProps> = ({
    permissions,
    userCash,
    ...rest
}) => {
    const { values } = useFormState();
    const classes = useStyles();
    const classesImg = useStylesImg();
    const history = useHistory();

    const [allItems, setAllItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState<Record[]>([]);
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [checkedInfo, setCheckedInfo] = useState({
        enableReturned: false,
        enableMissing: false,
        enableRefund: false,
        enableReplacement: false,
        enableExchange: false,
    });

    // Check current prescription is generated or not for this order or not
    const { data: prescriptionData, refetch } = useRequest(
        `/v1/digitalPrescription/${values?.o_dp_id}`,
        {},
        { isWarningNotify: false }
    );

    useEffect(() => {
        if (values.o_dp_id) refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.o_dp_id]);

    const currentEPrescription = prescriptionData || false;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setCheckedInfo({
            [e.target.name]: e.target.checked,
        });
    };

    const handlePrecription = () => {
        history.push({
            pathname: `/manageOrder/orders/${values?.o_id}/generate-prescription`,
            search: `?m=${selectedItems.join(",")}`,
        });
    };

    return (
        <Grid className={classes.medicinesTabWidth}>
            <div
                style={{
                    display: "flex",
                    justifyContent: !!values?.prescriptions?.length
                        ? "space-between"
                        : "flex-end",
                    alignItems: "center",
                    gap: 10,
                }}
            >
                {!!values?.prescriptions?.length && (
                    <BooleanInput
                        label="Hide Prescription"
                        source="isPrescriptionHide"
                        helperText={false}
                    />
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 10,
                    }}
                >
                    <AroggaButton
                        type="success"
                        label="Generate E-Prescription"
                        onClick={handlePrecription}
                        style={{
                            display:
                                values?.o_dp_id || selectedItems?.length === 0
                                    ? "none"
                                    : "block",
                            justifyItems: "flex-end",
                            height: 40,
                        }}
                    />
                    {!!values.o_dp_id && (
                        <Preview
                            label="View E-Prescription"
                            data={currentEPrescription}
                            open={isShowPreview}
                            setOpen={setIsShowPreview}
                            style={{
                                height: 40,
                            }}
                        />
                    )}
                    {!!values.o_dp_id && (
                        <AroggaButton
                            type="success"
                            label="Edit E-Prescription"
                            onClick={() => {
                                history.push({
                                    pathname: `/manageOrder/orders/${values?.o_id}/generate-prescription`,
                                    search: `dp_id=${currentEPrescription?.id}`,
                                });
                            }}
                            style={{
                                height: 40,
                            }}
                            disabled={!currentEPrescription?.id}
                        />
                    )}
                </div>
            </div>
            <Grid container>
                {!values.isPrescriptionHide && (
                    <Grid item lg={6} xl={6}>
                        <ImageField
                            source="attachedFiles"
                            src="src"
                            title="title"
                            classes={classesImg}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    lg={
                        !!values?.prescriptions?.length &&
                        !values.isPrescriptionHide
                            ? 6
                            : 12
                    }
                    xl={
                        !!values?.prescriptions?.length &&
                        !values.isPrescriptionHide
                            ? 6
                            : 12
                    }
                >
                    <FunctionField
                        render={(record: Record) => {
                            if (record?.o_status !== "delivered") return;
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                checkedInfo?.enableReturned
                                            }
                                            onChange={handleChange}
                                            name="enableReturned"
                                            color="primary"
                                        />
                                    }
                                    label="Return"
                                />
                            );
                        }}
                    />
                    <FunctionField
                        render={(record: Record) => {
                            if (record?.o_status !== "delivered") return;
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedInfo?.enableMissing}
                                            onChange={handleChange}
                                            name="enableMissing"
                                            color="primary"
                                        />
                                    }
                                    label="Missing"
                                />
                            );
                        }}
                    />
                    <FunctionField
                        render={(record: Record) => {
                            if (record?.o_status !== "delivered") return;
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                checkedInfo?.enableReplacement
                                            }
                                            onChange={handleChange}
                                            name="enableReplacement"
                                            color="primary"
                                        />
                                    }
                                    label="Replacement (আমাদের ভূল)"
                                />
                            );
                        }}
                    />
                    <FunctionField
                        render={(record: Record) => {
                            if (record?.o_status !== "delivered") {
                                return null;
                            } else if (
                                permissions?.includes("processOrderRefund")
                            )
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    checkedInfo?.enableRefund
                                                }
                                                onChange={handleChange}
                                                name="enableRefund"
                                                color="primary"
                                            />
                                        }
                                        label="Refund / Damage"
                                    />
                                );
                        }}
                    />
                    <MedicineTable
                        checkBoxEnable={!currentEPrescription?.id}
                        checkBoxItems={setSelectedItems}
                        allItems={allItems}
                        setAllItems={setAllItems}
                        checkedInfo={checkedInfo}
                    />
                    <MedicineCalculate
                        orderId={rest.record.id}
                        userCash={userCash}
                        formValues={values}
                        allItems={allItems}
                        setAllItems={setAllItems}
                    />
                    <MedicineForm
                        allItems={allItems}
                        setAllItems={setAllItems}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    medicinesTabWidth: {
        width: "100%",
    },
});

const useStylesImg = makeStyles({
    list: {
        display: "block",
        margin: 0,
        padding: 0,
        marginRight: 20,
    },
    image: {
        width: "100%",
        maxHeight: "100%",
    },
});

export default MedicinesTab;
