import { FC } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    ReferenceInput,
    SimpleForm,
} from "react-admin";

import ZonesInput from "../../../components/manageDelivery/bags/ZonesInput";
import { useDocumentTitle } from "../../../hooks";
import { required } from "../../../utils/helpers";

const BagCreate: FC<CreateProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Bag Create");

    return (
        <Create {...rest}>
            <SimpleForm redirect="list">
                <ReferenceInput
                    source="b_warehouse_id"
                    label="Warehouse"
                    variant="outlined"
                    reference="v1/warehouse"
                    filter={{ _role: "warehouse" }}
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        optionValue="w_id"
                        optionText="w_title"
                        resettable
                    />
                </ReferenceInput>
                <ZonesInput source="b_zone" variant="outlined" />
            </SimpleForm>
        </Create>
    );
};

export default BagCreate;
