import { Box } from "@material-ui/core";
import { FC, useState } from "react";
import {
    Create,
    CreateProps,
    SimpleForm,
    useDataProvider,
    useNotify,
    useRedirect,
} from "react-admin";

import AmountMismatchedAlert from "../../../components/managePurchase/purchaseInvoice/AmountMismatchedAlert";
import InvoiceForm from "../../../components/managePurchase/purchaseInvoice/InvoiceForm";
import SummaryTable from "../../../components/managePurchase/purchaseInvoice/SummaryTable";
import InvoiceTable from "../../../components/managePurchase/purchaseInvoice/invoiceTable";
import { useDocumentTitle } from "../../../hooks";
import { aroggaLocalforage } from "../../../services";
import PurchaseInvoiceToolbar from "./PurchaseInvoiceToolbar";

const PurchaseInvoiceCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Purchase Invoice Create");

    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [tpPrice, setTpPrice] = useState("");
    const [piiVat, setPiiVat] = useState("");
    const [allItems, setAllItems] = useState([]);

    const onSave = async ({
        pi_discount = 0,
        pi_round = 0,
        items,
        ...rest
    }) => {
        dataProvider
            .create("v1/purchaseInvoice", {
                data: {
                    ...rest,
                    pi_discount,
                    pi_round,
                    items: JSON.stringify(items),
                },
            })
            .then(() => {
                aroggaLocalforage.removeItem("purchase_invoices");
                aroggaLocalforage.removeItem("purchase_invoice_items");
                notify("Successfully created!", { type: "success" });
                redirect("list", "/v1/purchaseInvoice");
            })
            .catch((err) => {
                notify(
                    err?.message || "Something went wrong, Please try again!",
                    {
                        type: "warning",
                    }
                );
            });
    };

    return (
        <Create {...props}>
            <SimpleForm
                toolbar={
                    <PurchaseInvoiceToolbar
                        page="create"
                        setTpPrice={setTpPrice}
                        allItems={allItems}
                        setAllItems={setAllItems}
                    />
                }
                save={onSave}
                submitOnEnter={false}
            >
                <InvoiceForm
                    page="create"
                    tpPrice={tpPrice}
                    setTpPrice={setTpPrice}
                    piiVat={piiVat}
                    setPiiVat={setPiiVat}
                    allItems={allItems}
                    setAllItems={setAllItems}
                />
                {!!allItems?.length && (
                    <>
                        <InvoiceTable
                            allItems={allItems}
                            setAllItems={setAllItems}
                        />
                        <Box mt={4} mb={2}>
                            <SummaryTable />
                        </Box>
                        <AmountMismatchedAlert />
                    </>
                )}
            </SimpleForm>
        </Create>
    );
};

export default PurchaseInvoiceCreate;
