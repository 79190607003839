/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { FC, useEffect, useState } from "react";
import { useRecordContext } from "react-admin";

import { useRequest } from "../../../hooks";
import { isEmpty } from "../../../utils/helpers";
import FormatedBooleanInput from "../../FormatedBooleanInput";
import LoaderOrButton from "../../LoaderOrButton";

type OrderMedicineCalculateProps = {
    orderId?: string;
    userCash?: any;
    formValues: any;
    allItems: object[];
    setAllItems?: (items: object[]) => void;
};

const OrderMedicineCalculate: FC<OrderMedicineCalculateProps> = ({
    orderId = 0,
    userCash,
    formValues,
    allItems,
    setAllItems,
}) => {
    const record = useRecordContext();
    const classes = useStyles();

    const [isShowTable, setIsShowTable] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [apiCall, setApiCall] = useState(false);

    const { data, isSuccess, isLoading, refetch } = useRequest(
        `/v1/orders/orderDetails/${orderId}`,
        {
            method: "POST",
            body: {
                ...formValues,
            },
        }
    );

    useEffect(() => {
        if (!apiCall) return;
        refetch();
    }, [apiCall]);

    useEffect(() => {
        if (!apiCall && !isShowTable && !isWarning) return;

        setApiCall(false);
        setIsShowTable(false);
        setIsWarning(false);
    }, [formValues]);

    useEffect(() => {
        if (!isSuccess) return;
        setIsShowTable(true);
        if (!formValues.o_addition && data?.a_amount && orderId) {
            setIsWarning(true);
        }
    }, [isSuccess]);

    return (
        <>
            {!isShowTable && !!allItems?.length && (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 40,
                        }}
                    >
                        {!isEmpty(record?.price_increase) && (
                            <FormatedBooleanInput
                                source="price_increase_resolved"
                                label="Price increase resolved?"
                            />
                        )}
                        {!!orderId && !!userCash && (
                            <FormatedBooleanInput
                                source="include_u_cash"
                                label="Include Arogga Cash (if any)"
                            />
                        )}
                    </div>
                    <LoaderOrButton
                        label="Calculate"
                        isLoading={isLoading}
                        display="flex"
                        justifyContent="center"
                        mt={0}
                        mb={2}
                        onClick={() => setApiCall(true)}
                    />
                </>
            )}
            {isShowTable && (
                <TableContainer>
                    <Table
                        size="small"
                        className={classes.table}
                        align="center"
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>Subtotal</TableCell>
                                <TableCell align="right">
                                    {data?.subtotal}
                                </TableCell>
                            </TableRow>
                            {!!data?.deductions &&
                                Object.values(data.deductions).length !== 0 &&
                                Object.values(data.deductions)?.map(
                                    (item: any, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                {item.text} <br />
                                                {item.info}
                                            </TableCell>
                                            <TableCell align="right">
                                                -{Math.abs(item.amount)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            {!!data?.additions &&
                                Object.values(data.additions).length !== 0 &&
                                Object.values(data.additions)?.map(
                                    (item: any, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                {item.text} <br />
                                                {item.info}
                                            </TableCell>
                                            <TableCell align="right">
                                                {item.amount}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            {/* {!!data?.payments?.length && (
								<TableRow>
									<TableCell
										style={{
											fontWeight: "bold",
											textAlign: "center",
										}}
										colSpan={2}>
										Payments
									</TableCell>
								</TableRow>
							)}
							{data?.payments?.map((item, i) => (
								<TableRow key={i}>
									<TableCell>{item.details}</TableCell>
									<TableCell align="right">
										-{item?.amount}
									</TableCell>
								</TableRow>
							))} */}
                            <TableRow>
                                <TableCell style={{ fontWeight: "bold" }}>
                                    Amount Payable
                                </TableCell>
                                <TableCell
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                >
                                    {/* {record?.o_due_amount} */}
                                    {data?.total}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {!!isWarning && (
                <Alert
                    classes={{ root: classes.root }}
                    severity="warning"
                    action={
                        <>
                            <Button
                                color="inherit"
                                size="small"
                                onClick={() => setIsWarning(false)}
                            >
                                Ok
                            </Button>
                            <Button
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setIsWarning(false);
                                    setAllItems(record?.medicineQty);
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    }
                >
                    ওয়ার্নিং, {data?.a_amount} টাকা ডেলিভারি চার্জ এড হবে এই
                    অর্ডারে।
                </Alert>
            )}
        </>
    );
};

const useStyles = makeStyles({
    table: {
        width: 362,
        marginTop: 20,
        marginBottom: 18,
    },
    root: {
        width: "50%",
        margin: "0 auto",
    },
});
export default OrderMedicineCalculate;
