import { Button } from "@material-ui/core";
import { useState } from "react";
import {
    Datagrid,
    FunctionField,
    NumberField,
    Pagination,
    ReferenceManyField,
    TextField,
} from "react-admin";

import WithdrawDialog from "../../../../components/manageUser/users/WithdrawDialog";

const UserTransactionHistoryTab = () => {
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState({});

    const handleClick = (data) => {
        setItem(data);
        setOpen(true);
    };

    return (
        <>
            <ReferenceManyField
                label="Transactions"
                reference="v1/userLedgers"
                target="_u_id"
                pagination={<Pagination />}
                sort={{ field: "ule_id", order: "DESC" }}
            >
                <Datagrid>
                    <TextField source="ule_id" label="Id" />
                    <TextField source="ule_date" label="Date" />
                    <TextField source="ule_title" label="Title" />
                    <TextField source="ule_from" label="Note" />
                    <NumberField source="ule_amount" label="Amount" />
                    <TextField source="ule_status" label="Status" />
                    <FunctionField
                        label="Withdraw"
                        render={(record) => {
                            if (!record.withdrawable) return;

                            return (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleClick(record)}
                                >
                                    Withdraw
                                </Button>
                            );
                        }}
                    />
                </Datagrid>
            </ReferenceManyField>
            <WithdrawDialog open={open} setOpen={setOpen} item={item} />
        </>
    );
};

export default UserTransactionHistoryTab;
