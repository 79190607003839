import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { FC } from "react";

import { useGetStoreData } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type BagDialogProps = {
    formValues: any;
    record: any;
    isChecked: boolean;
    open: boolean;
    handleDialogClose: () => void;
    handleSubmitWithRedirect: () => void;
};

const BagDialog: FC<BagDialogProps> = ({
    formValues,
    record,
    isChecked,
    open,
    handleDialogClose,
    handleSubmitWithRedirect,
}) => {
    const users = useGetStoreData("v1/users");

    const deliveryMan = users?.[formValues?.b_de_id]?.u_name;

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>
                {isChecked
                    ? "Are you sure you want to move this orders to this bag?"
                    : "Are you sure you want to assign this bag to this delivery man?"}
            </DialogTitle>
            <DialogContent>
                {isChecked ? (
                    <>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>From Zone</TableCell>
                                    <TableCell>Fron Bag</TableCell>
                                    <TableCell>To Zone</TableCell>
                                    <TableCell>To Bag</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{record?.b_zone}</TableCell>
                                    <TableCell>{record?.b_no}</TableCell>
                                    <TableCell>
                                        {formValues?.move_zone}
                                    </TableCell>
                                    <TableCell>
                                        {formValues?.move_bag}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Box mt={2} />
                        <Typography>
                            Order ids:{" "}
                            {!!formValues?.move_ids?.length &&
                                formValues.move_ids.join(", ")}
                        </Typography>
                    </>
                ) : (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Zone</TableCell>
                                <TableCell>Bag No</TableCell>
                                <TableCell>Delivery Man</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{formValues?.b_zone}</TableCell>
                                <TableCell>{formValues?.b_no}</TableCell>
                                <TableCell>{deliveryMan}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={false}
                onDialogClose={handleDialogClose}
                onConfirm={() => {
                    handleDialogClose();
                    // @ts-ignore
                    handleSubmitWithRedirect("list");
                }}
            />
        </Dialog>
    );
};

export default BagDialog;
