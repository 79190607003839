import { FC } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import VocabularyFilter from "./VocabularyFilter";

const VocabularyList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Vocabulary List");

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Vocabulary"
            perPage={25}
            filters={<VocabularyFilter children={""} />}
            sort={{ field: "v_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="edit">
                <TextField source="v_id" label="Id" />
                <FunctionField
                    label="Title"
                    sortBy="v_title"
                    // @ts-ignore
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={(record) => (
                        <Link
                            to={{
                                pathname: "/v1/taxonomy",
                                search: `filter=${JSON.stringify({
                                    _v_id: record.v_id,
                                })}`,
                            }}
                        >
                            {record.v_title}
                        </Link>
                    )}
                />
                <TextField source="v_description" label="Description" />
                <TextField source="v_machine_name" label="Machine Name" />
                <TextField source="v_created_at" label="Created" />
                <ReferenceField
                    source="v_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="v_created_by"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default VocabularyList;
