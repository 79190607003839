import { Button, Table, TableContainer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FC, useEffect, useState } from "react";
import { useNotify } from "react-admin";

import OrderDialog from "../OrderDialog";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

type MedicineTableProps = {
    page?: string;
    allItems: any[];
    checkedInfo?: {
        enableExchange?: boolean;
        enableMissing?: boolean;
        enableRefund?: boolean;
        enableReplacement?: boolean;
        enableReturned?: boolean;
    };
    setAllItems: (items: object[]) => void;
    checkBoxEnable?: boolean;
    checkBoxItems?: any;
};

const MedicineTable: FC<MedicineTableProps> = ({
    page,
    allItems,
    checkedInfo,
    setAllItems,
    checkBoxEnable,
    checkBoxItems,
}) => {
    const classes = useStyles();
    const notify = useNotify();

    const [selectedItems, setSelectedItems] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (checkBoxItems) checkBoxItems(selectedItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.target.blur();
        } else if (
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight" &&
            isNaN(e.key)
        ) {
            e.preventDefault();
        }
    };

    const handleOnBlur = ({
        value,
        medicineId,
        fieldName,
    }: {
        value: any;
        medicineId: number;
        fieldName?: string;
    }) => {
        const isNum = /^\d+$/.test(value);

        const newAllItems =
            !!allItems?.length &&
            allItems.map((item) => {
                const copyItem = { ...item };

                if (item.m_id === medicineId && isNum) {
                    if (fieldName === "refund_qty") {
                        if (
                            value > item.qty ||
                            item.qty < value + copyItem.damage_qty
                        ) {
                            notify(
                                "Refund Qty cann't be greater than total qty!",
                                { type: "warning" }
                            );
                        } else {
                            copyItem.refund_qty = value;
                            copyItem.isNewEditableItem = true;
                        }
                    } else if (fieldName === "damage_qty") {
                        if (
                            value > item.qty ||
                            item.qty < value + copyItem.refund_qty
                        ) {
                            notify(
                                "Damage Qty cann't be greater than total qty!",
                                { type: "warning" }
                            );
                        } else {
                            copyItem.damage_qty = value;
                            copyItem.isNewEditableItem = true;
                        }
                    } else if (fieldName === "missing_qty") {
                        if (value > item.qty) {
                            notify(
                                "Missing Qty cann't be greater than total qty!",
                                { type: "warning" }
                            );
                        } else {
                            copyItem.missing_qty = value;
                        }
                    } else if (fieldName === "give_qty") {
                        if (checkedInfo.enableReplacement) {
                            if (value > item.qty) {
                                notify(
                                    "Give Qty cann't be greater than total qty!",
                                    { type: "warning" }
                                );
                            } else {
                                copyItem.give_qty = value;
                            }
                        } else {
                            copyItem.give_qty = value;
                        }
                    } else if (fieldName === "take_qty") {
                        copyItem.take_qty = value;
                    } else {
                        copyItem.qty = value;
                    }
                }
                return copyItem;
            });
        setAllItems(newAllItems);
    };

    const handleOnChange = (e, selectedItem) => {
        if (e.target.checked) {
            const isItemExits =
                !!selectedItems?.length &&
                selectedItems.includes(selectedItem?.m_id);

            if (!isItemExits)
                setSelectedItems((prevState) => [
                    ...prevState,
                    selectedItem?.m_id,
                ]);
        } else {
            const filteredData =
                !!selectedItems?.length &&
                selectedItems.filter((m_id) => m_id !== selectedItem?.m_id);
            setSelectedItems(filteredData);
        }
    };

    const newReturnItems = allItems?.filter(
        (item) => !!selectedItems?.length && selectedItems.includes(item.m_id)
    );

    const newMissingItems =
        !!allItems?.length && allItems?.filter((item) => item?.missing_qty);

    const newRefundOrDamageItems =
        !!allItems?.length &&
        allItems.filter((item) => item?.isNewEditableItem);

    const replacementItems =
        !!allItems?.length &&
        allItems.filter(
            (item) =>
                item?.qty > 0 &&
                item?.selectedMedicine?.id > 0 &&
                item?.take_qty > 0
        );

    const exchangetItems =
        !!allItems?.length &&
        allItems.filter(
            (item) =>
                item?.qty > 0 &&
                item?.selectedMedicine?.id > 0 &&
                item?.give_qty > 0
        );

    const items = () => {
        if (checkedInfo?.enableReturned) return newReturnItems;
        if (checkedInfo?.enableMissing) return newMissingItems;
        if (checkedInfo?.enableRefund) return newRefundOrDamageItems;
        if (checkedInfo?.enableReplacement) return replacementItems;
        if (checkedInfo?.enableExchange) return exchangetItems;
        return [];
    };

    const returnItems =
        !!allItems?.length &&
        allItems.filter((item) => selectedItems?.includes(item.m_id));

    const missingItems = allItems?.filter((item) => item?.missing_qty);

    const disabled = () => {
        if (checkedInfo?.enableReturned && !returnItems.length) return true;
        if (checkedInfo?.enableMissing && !missingItems.length) return true;
        if (checkedInfo?.enableRefund && !newRefundOrDamageItems.length)
            return true;

        if (checkedInfo?.enableReplacement) {
            const isItemExits =
                !!allItems?.length &&
                allItems.find(
                    (item) =>
                        item?.selectedMedicine &&
                        item?.take_qty > 0 &&
                        item?.qty > 0
                );
            if (!isItemExits) return true;
        }
        if (checkedInfo?.enableExchange) {
            const isItemExits =
                !!allItems?.length &&
                allItems.find(
                    (item) =>
                        item?.selectedMedicine &&
                        item?.give_qty > 0 &&
                        item?.qty > 0
                );
            if (!isItemExits) return true;
        }
        return false;
    };

    const buttonLabel = () => {
        if (checkedInfo?.enableReturned) return "Return";
        if (checkedInfo?.enableMissing) return "Missing";
        if (checkedInfo?.enableRefund) return "Refund";
        if (checkedInfo?.enableReplacement) return "Replace";
        if (checkedInfo?.enableExchange) return "Exchange";
    };

    const handleRemove = (medicineId) => {
        const updatedAllItems = allItems?.filter(
            (item) => item.m_id !== medicineId
        );
        setAllItems(updatedAllItems);
    };

    const selectAllChecked = (e) => {
        if (e.target.checked) {
            const allItemsIds = allItems?.map((item) => item?.m_id);
            setSelectedItems(allItemsIds);
        } else {
            setSelectedItems([]);
        }
    };

    return (
        <>
            {!!allItems?.length && (
                <TableContainer>
                    <Table size="small" className={classes.table}>
                        <TableHeader
                            page={page}
                            checkedInfo={checkedInfo}
                            checkBoxEnable={checkBoxEnable}
                            checkBoxProps={{
                                selectAllChecked,
                                selectedItems,
                                allItems,
                            }}
                        />
                        <TableBody
                            page={page}
                            checkBoxEnable={checkBoxEnable}
                            checkedInfo={checkedInfo}
                            allItems={allItems}
                            selectedItems={selectedItems}
                            handleOnKeyDown={handleOnKeyDown}
                            handleOnChange={handleOnChange}
                            handleOnBlur={handleOnBlur}
                            handleRemove={handleRemove}
                        />
                    </Table>
                </TableContainer>
            )}
            {(checkedInfo?.enableReturned ||
                checkedInfo?.enableMissing ||
                checkedInfo?.enableRefund ||
                checkedInfo?.enableReplacement ||
                checkedInfo?.enableExchange) &&
                !!allItems?.length && (
                    <Button
                        color="primary"
                        variant="contained"
                        style={{
                            textTransform: "none",
                            marginTop: "10px",
                        }}
                        onClick={() => setOpenDialog(true)}
                        disabled={disabled()}
                    >
                        {buttonLabel()}
                    </Button>
                )}
            <OrderDialog
                checkedInfo={checkedInfo}
                selectedItems={selectedItems}
                buttonLabel={buttonLabel}
                items={items()}
                open={openDialog}
                handleDialogClose={() => setOpenDialog(false)}
            />
        </>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        marginTop: 20,
    },
});

export default MedicineTable;
