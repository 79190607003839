import { FC, useState } from "react";
import {
    Create,
    CreateProps,
    SimpleForm,
    useNotify,
    useRedirect,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import MedicineEditForm from "../../../components/manageDatabase/medicines/MedicineEditForm";
import { useDocumentTitle } from "../../../hooks";

const MedicineCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Medicine Create");

    const notify = useNotify();
    const redirect = useRedirect();

    const [description, setDescription] = useState("");

    const onSuccess = () => {
        redirect("/v1/medicines?_orderBy=created_at");
        notify("Successfully created medicine!", { type: "success" });
    };

    return (
        <Create onSuccess={onSuccess} {...rest}>
            <SimpleForm
                toolbar={
                    <SaveDeleteToolbar
                        isSave={
                            rest.permissions?.includes("medicineCreate") ||
                            rest.permissions?.includes("elevatedMedicineCreate")
                        }
                    />
                }
            >
                <MedicineEditForm
                    page="create"
                    description={description}
                    setDescription={setDescription}
                    {...rest}
                />
            </SimpleForm>
        </Create>
    );
};

export default MedicineCreate;
