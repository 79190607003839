import { Paper } from "@material-ui/core";
import { FC, useState } from "react";
import {
    FormTab,
    TabbedForm,
    TextInput,
    Title,
    Toolbar,
    useNotify,
} from "react-admin";
import uploadDataProvider from "../../../dataProviderFileUpload";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { logger } from "../../../utils/helpers";
import AdminTab from "./AdminTab";
import B2BSecureForm from "./B2BSecureForm";
import BkashTab from "./BkashTab";
import CacheTab from "./CacheTab";
import CacheTabDialog from "./CacheTabDialog";
import CallTab from "./CallTab";
import ElasticSearchTab from "./ElasticSearchTab";
import ElasticSearchTabDialog from "./ElasticSearchTabDialog";

const SuperAdminSettingsPage: FC = () => {
    useDocumentTitle("Arogga | Super Admin Settings");

    const notify = useNotify();

    const [key, setKey] = useState("");
    const [openCacheDialog, setOpenCacheDialog] = useState(false);
    const [openElasticSearchDialog, setOpenElasticSearchDialog] =
        useState(false);

    const { data } = useRequest(
        "/v1/superOptionsMultiple",
        {},
        { isPreFetching: true }
    );

    const formattedData = {
        ...data,
    };

    ["b2b_settings", "secureSettings", "settings"].forEach(
        (key) =>
            (formattedData[key] = Object.keys(data?.[key] || {})?.map(
                (item) => ({
                    key: item,
                    value: data?.[key]?.[item],
                })
            ))
    );

    const onSave = async (data) => {
        const formattedB2bSettingsData = {};
        const formattedSecureSettingsData = {};
        const formattedSettingsData = {};

        data?.b2b_settings?.forEach(
            (item) => (formattedB2bSettingsData[item.key] = item.value)
        );
        data?.secureSettings?.forEach(
            (item) => (formattedSecureSettingsData[item.key] = item.value)
        );
        data?.settings?.forEach(
            (item) => (formattedSettingsData[item.key] = item.value)
        );

        const formattedPayload = {
            ...data,
            b2b_settings: formattedB2bSettingsData,
            secureSettings: formattedSecureSettingsData,
            settings: formattedSettingsData,
        };

        try {
            await uploadDataProvider.create("v1/superOptionsMultiple", {
                data: formattedPayload,
            });
            notify("Successfully save!", { type: "success" });
        } catch (err) {
            logger(err);
            notify("Something wrong, Please try again!", { type: "warning" });
        }
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Super Admin Settings" />
            <TabbedForm
                initialValues={formattedData}
                syncWithLocation={false}
                submitOnEnter={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
                save={onSave}
            >
                <FormTab label="Cache">
                    <CacheTab setOpen={setOpenCacheDialog} setKey={setKey} />
                    <CacheTabDialog
                        keyName={key}
                        open={openCacheDialog}
                        handleClose={() => setOpenCacheDialog(false)}
                    />
                </FormTab>
                <FormTab label="ElasticSearch">
                    <ElasticSearchTab
                        setKey={setKey}
                        setOpen={setOpenElasticSearchDialog}
                    />
                    <ElasticSearchTabDialog
                        keyName={key}
                        open={openElasticSearchDialog}
                        handleClose={() => setOpenElasticSearchDialog(false)}
                    />
                </FormTab>
                <FormTab label="Bkash">
                    <BkashTab />
                </FormTab>
                <FormTab label="Call">
                    <CallTab />
                </FormTab>
                <FormTab label="Admin">
                    <AdminTab />
                </FormTab>
                <FormTab label="Versions">
                    <TextInput
                        source="android_app_current_version"
                        label="Android App Current Version"
                        variant="outlined"
                    />
                    <TextInput
                        source="android_app_min_version"
                        label="Android App Min Version"
                        variant="outlined"
                    />
                    <TextInput
                        source="ios_app_current_version"
                        label="IOS App Current Version"
                        variant="outlined"
                    />
                    <TextInput
                        source="ios_app_min_version"
                        label="IOS App Min Version"
                        variant="outlined"
                    />
                </FormTab>
                <FormTab label="B2B">
                    <B2BSecureForm source="b2b_settings" />
                </FormTab>
                <FormTab label="Secure">
                    <B2BSecureForm source="secureSettings" />
                </FormTab>
                <FormTab label="Config">
                    <B2BSecureForm source="settings" />
                </FormTab>
            </TabbedForm>
        </Paper>
    );
};

export default SuperAdminSettingsPage;
