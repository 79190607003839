import { FC } from "react";
import {
    Edit,
    EditProps,
    NumberInput,
    SimpleForm,
    TextInput,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import UserLedgerStatusInput from "../../../components/manageFinance/userLedgers/UserLedgerStatusInput";
import { useDocumentTitle } from "../../../hooks";
import { required } from "../../../utils/helpers";

const UserLedgerEdit: FC<EditProps> = ({ permissions, hasEdit, ...rest }) => {
    useDocumentTitle("Arogga | User Ledger Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm redirect="list">
                <NumberInput
                    source="u_id"
                    label="User Id"
                    variant="outlined"
                    validate={[required()]}
                />
                <NumberInput
                    source="o_id"
                    label="Order Id"
                    variant="outlined"
                    validate={[required()]}
                />
                <TextInput
                    source="ule_title"
                    label="Title"
                    variant="outlined"
                />
                <TextInput source="ule_from" label="From" variant="outlined" />
                <NumberInput
                    source="ule_amount"
                    label="Amount"
                    variant="outlined"
                />
                <TextInput
                    source="ule_withdraw_instruction"
                    label="Withdraw Instruction"
                    variant="outlined"
                    minRows={2}
                    multiline
                />
                <FormatedBooleanInput
                    source="ule_allow_withdraw"
                    label="Allow Withdraw?"
                />
                <UserLedgerStatusInput source="ule_status" variant="outlined" />
                <TextInput
                    source="ule_details"
                    label="Details"
                    variant="outlined"
                    minRows={2}
                    multiline
                />
            </SimpleForm>
        </Edit>
    );
};

export default UserLedgerEdit;
