import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
    usePermissions,
} from "react-admin";

import MedicineCategoryInput from "../../../components/MedicineCategoryInput";
import MedicineTypeInput from "../../../components/MedicineTypeInput";

const MedicineFilter: FC<FilterProps> = (props) => {
    const { permissions } = usePermissions();

    return (
        <Filter {...props}>
            <TextInput
                label="Search"
                variant="outlined"
                source="_search"
                alwaysOn
                resettable
            />
            <SelectInput
                source="_status"
                label="Status"
                variant="outlined"
                choices={[
                    { id: "suggested", name: "Suggested" },
                    { id: "inactive", name: "Inactive" },
                    { id: "active", name: "Active" },
                ]}
                alwaysOn
            />
            <SelectInput
                source="_approvalStatus"
                label="Approval Status"
                variant="outlined"
                choices={[
                    ...(permissions?.includes("elevatedMedicineEdit")
                        ? [{ id: "new,edited", name: "Approval Request" }]
                        : []),
                    ...(permissions?.includes("medicineEdit")
                        ? [{ id: "rejected", name: "Change Needed" }]
                        : []),
                    {
                        id: "new",
                        name: "New",
                    },
                    {
                        id: "approved",
                        name: "Approved",
                    },
                    {
                        id: "edited",
                        name: "Edited",
                    },
                    {
                        id: "cancelled",
                        name: "Cancelled",
                    },
                    {
                        id: "rejected",
                        name: "Rejected",
                    },
                ]}
                alwaysOn
            />
            <ReferenceInput
                source="_c_id"
                label="Company"
                variant="outlined"
                reference="v1/companies"
                filterToQuery={(searchText) => ({ _search: searchText })}
                sort={{ field: "c_name", order: "ASC" }}
            >
                <AutocompleteInput
                    optionValue="c_id"
                    optionText="c_name"
                    resettable
                />
            </ReferenceInput>
            <ReferenceInput
                source="_manufacturer"
                label="Manufacturer By"
                variant="outlined"
                reference="v1/companies"
                filterToQuery={(searchText) => ({ _search: searchText })}
                sort={{ field: "c_name", order: "ASC" }}
            >
                <AutocompleteInput
                    optionValue="c_id"
                    optionText="c_name"
                    resettable
                />
            </ReferenceInput>
            <ReferenceInput
                source="_g_id"
                label="Generic"
                variant="outlined"
                reference="v1/generics"
                filterToQuery={(searchText) => ({ _search: searchText })}
                sort={{ field: "g_name", order: "ASC" }}
            >
                <AutocompleteInput
                    optionValue="g_id"
                    optionText="g_name"
                    resettable
                />
            </ReferenceInput>
            <MedicineTypeInput
                source="_category"
                label="Type"
                variant="outlined"
            />
            <SelectInput
                source="_image"
                label="Image"
                variant="outlined"
                choices={[
                    { id: "yes", name: "Yes" },
                    { id: "no", name: "No" },
                ]}
            />
            <MedicineCategoryInput
                source="_cat_id"
                label="Category"
                variant="outlined"
            />
            <TextInput
                source="_m_r_count"
                label="Request Count"
                variant="outlined"
                resettable
            />
        </Filter>
    );
};

export default MedicineFilter;
