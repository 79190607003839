import { FC, useEffect, useState } from "react";
import { Edit, EditProps, FormTab, TabbedForm, Toolbar } from "react-admin";
import { FormSpy, useFormState } from "react-final-form";

import Calculations from "../../../components/manageFinance/dailyReports/Calculations";
import CollectionsPurchases from "../../../components/manageFinance/dailyReports/CollectionsPurchases";
import Expenses from "../../../components/manageFinance/dailyReports/Expenses";
import Loans from "../../../components/manageFinance/dailyReports/Loans";
import Salaries from "../../../components/manageFinance/dailyReports/Salaries";
import { useDocumentTitle, useRequest } from "../../../hooks";

const DailyReportEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Daily Report Edit");

    const [formValues, setFormValues] = useState<any>([]);

    const { data: collectionData, refetch: refetchCollection } = useRequest(
        `/v1/ledger?ids=${formValues?.collection_ids}`
    );

    const { data: collectionDataByCoIDS, refetch: refetchCollectionByCoIDS } =
        useRequest(`/v1/collections?ids=${formValues?.co_ids}`);

    const { data: purchaseData, refetch: refetchPurchase } = useRequest(
        `/v1/ledger?ids=${formValues?.purchase_ids}`
    );

    const { data: purchaseDataByPiIDS, refetch: refetchPurchaseByPiIDS } =
        useRequest(`/v1/purchaseInvoice?ids=${formValues?.pi_ids}`);

    const { data: salaryData, refetch: refetchSalary } = useRequest(
        `/v1/salary?ids=${formValues?.es_ids}`
    );

    const { data: loanData, refetch: refetchLoan } = useRequest(
        `/v1/employeeLoan?ids=${formValues?.el_ids}`
    );

    useEffect(() => {
        if (formValues?.collection_ids?.length) refetchCollection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues?.collection_ids?.length]);

    useEffect(() => {
        if (!formValues?.collection_ids?.length && formValues?.co_ids?.length)
            refetchCollectionByCoIDS();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues?.collection_ids?.length, formValues?.co_ids?.length]);

    useEffect(() => {
        if (formValues?.purchase_ids?.length) refetchPurchase();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues?.purchase_ids?.length]);

    useEffect(() => {
        if (!formValues?.purchase_ids?.length && formValues?.pi_ids?.length)
            refetchPurchaseByPiIDS();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues?.purchase_ids?.length, formValues?.pi_ids?.length]);

    useEffect(() => {
        if (formValues?.es_ids?.length) refetchSalary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues?.es_ids?.length]);
    useEffect(() => {
        if (formValues?.el_ids?.length) refetchLoan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues?.el_ids?.length]);

    const CollectionsTab = () => {
        const { values } = useFormState();

        values.total_collections = values?.b_details?.total_collections || 0;

        return (
            <CollectionsPurchases
                tab="collections"
                data={collectionData}
                collectionDataByCoIDS={collectionDataByCoIDS}
            />
        );
    };

    const PurchasesTab = () => {
        const { values } = useFormState();

        values.total_purchases = values?.b_details?.total_purchases || 0;

        return (
            <CollectionsPurchases
                tab="purchases"
                data={purchaseData}
                purchaseDataByPiIDS={purchaseDataByPiIDS}
            />
        );
    };

    const SalariesTab = () => {
        const { values } = useFormState();

        values.total_salaries = values?.b_details?.total_salaries || 0;

        return <Salaries data={salaryData} />;
    };
    const LoansTab = () => {
        const { values } = useFormState();
        values.total_loans = values?.b_details?.total_loans || 0;
        return <Loans data={loanData} />;
    };

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <TabbedForm
                redirect="list"
                toolbar={<Toolbar alwaysEnableSaveButton />}
                submitOnEnter={false}
            >
                <FormTab label="Collections">
                    <CollectionsTab />
                </FormTab>
                <FormTab label="Purchases">
                    <PurchasesTab />
                </FormTab>
                <FormTab label="Salaries">
                    <SalariesTab />
                </FormTab>
                <FormTab label="Loans">
                    <LoansTab />
                </FormTab>
                <FormTab label="Expenses">
                    <Expenses page="edit" />
                </FormTab>
                <FormTab label="Calculations">
                    <Calculations page="edit" />
                </FormTab>
                <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) =>
                        // Fix bad setState() call inside `FormSpy` error using setTimeout
                        setTimeout(() => {
                            setFormValues(values?.b_details);
                        }, 0)
                    }
                />
            </TabbedForm>
        </Edit>
    );
};

export default DailyReportEdit;
