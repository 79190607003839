import { TableBody, TableCell, TableRow, makeStyles } from "@material-ui/core";
import { useFormState } from "react-final-form";

import { toFixedNumber } from "../../../../utils/helpers";

const TableFooter = () => {
    const classes = useStyles();
    const { values } = useFormState();

    return (
        <TableBody>
            <TableRow>
                <TableCell className={classes.fontBold}>Total</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell className={classes.fontBold}>
                    {toFixedNumber(values.totalIndividualPiiTPPrice)}
                </TableCell>
                <TableCell className={classes.fontBold}>
                    {toFixedNumber(values.totalIndividualPiiVat)}
                </TableCell>
                <TableCell className={classes.fontBold}>
                    {toFixedNumber(values.totalIndividualPiiDiscount)}
                </TableCell>
                <TableCell></TableCell>
                <TableCell className={classes.fontBold}>
                    {toFixedNumber(values.totalIndividualPuPrice)}
                </TableCell>
                <TableCell className={classes.fontBold}>
                    {toFixedNumber(values.totalIndividualMRP)}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableBody>
    );
};

const useStyles = makeStyles({
    fontBold: {
        fontWeight: "bold",
    },
});

export default TableFooter;
