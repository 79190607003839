import { Grid, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { FC, useRef, useState } from "react";
import {
    AutocompleteInput,
    DateInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";
import { useForm } from "react-final-form";

import { autoGeneratedLedgerReason } from "../../../utils/helpers";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";
import UserNameRankOptionTextRenderer from "../../UserNameRankOptionTextRenderer";

type LedgerMultipleEntryFormProps = {
    setAllItems: (items) => void;
};

const LedgerMultipleEntryForm: FC<LedgerMultipleEntryFormProps> = ({
    setAllItems,
}) => {
    const classes = useStyles();
    const form = useForm();
    const values = form.getState().values;

    const fieldRef1 = useRef<HTMLInputElement>(null!);
    const fieldRef2 = useRef<HTMLInputElement>(null!);
    const fieldRef3 = useRef<HTMLInputElement>(null!);
    const fieldRef4 = useRef<HTMLInputElement>(null!);
    const fieldRef5 = useRef<HTMLInputElement>(null!);

    const [ledgerType, setLedgerType] = useState("");
    const [rank, setRank] = useState("");
    const [username, setUsername] = useState("");

    const handleOnKeyDown = () => {
        const { l_type, l_method, l_a_uid, l_a_date, l_reason, l_amount } =
            values.multiple_entry;

        setAllItems((prevState: object[]) => [
            {
                l_type,
                l_method,
                l_a_uid,
                l_a_date,
                l_reason:
                    ledgerType === "Salary and Allowances"
                        ? autoGeneratedLedgerReason({
                              l_a_date,
                              username,
                              rank,
                              l_a_uid,
                          })
                        : l_reason,
                l_amount,
            },
            ...prevState,
        ]);

        form.change("multiple_entry.l_type", undefined);
        form.change("multiple_entry.l_method", "Bank");
        l_a_uid && form.change("multiple_entry.l_a_uid", undefined);
        form.change("multiple_entry.l_reason", undefined);
        form.change("multiple_entry.l_amount", undefined);

        setTimeout(() => {
            fieldRef1.current.focus();
        }, 1);
    };

    return (
        <div className={classes.root}>
            <div className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item md={2}>
                        <TaxonomiesByVocabularyInput
                            fetchKey="ledger_types"
                            inputType="autoCompleteInput"
                            source="multiple_entry.l_type"
                            label="Type"
                            options={{
                                InputProps: { inputRef: fieldRef1 },
                            }}
                            onSelect={(item) => {
                                setLedgerType(item.name);
                                if (ledgerType === "Salary and Allowances") {
                                    fieldRef2.current.focus();
                                } else {
                                    fieldRef4.current.focus();
                                }
                            }}
                            title
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <SelectInput
                            source="multiple_entry.l_method"
                            label="Method"
                            variant="outlined"
                            defaultValue="Bank"
                            choices={[
                                { id: "Cash", name: "Cash" },
                                {
                                    id: "Bank",
                                    name: "Bank",
                                },
                            ]}
                            fullWidth
                        />
                    </Grid>
                    {ledgerType === "Salary and Allowances" ? (
                        <>
                            <Grid item lg={2}>
                                <ReferenceInput
                                    source="multiple_entry.l_a_uid"
                                    label="User"
                                    variant="outlined"
                                    reference="v1/users"
                                    filter={{
                                        _isEmployee: true,
                                    }}
                                    filterToQuery={(searchText) => ({
                                        _search: searchText,
                                    })}
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        matchSuggestion={() => true}
                                        optionValue="u_id"
                                        inputText={(value) => value?.u_name}
                                        optionText={
                                            <UserNameRankOptionTextRenderer />
                                        }
                                        options={{
                                            InputProps: { inputRef: fieldRef2 },
                                        }}
                                        onSelect={(item) => {
                                            setRank(item.u_rank);
                                            setUsername(item?.u_name);
                                            fieldRef3.current.focus();
                                        }}
                                        resettable
                                        fullWidth
                                    />
                                </ReferenceInput>
                            </Grid>
                            <Grid item lg={2}>
                                <DateInput
                                    source="multiple_entry.l_a_date"
                                    label="Date"
                                    variant="outlined"
                                    inputRef={fieldRef3}
                                    defaultValue={DateTime.now().toFormat(
                                        "yyyy-MM-dd"
                                    )}
                                    onSelect={() => fieldRef5.current.focus()}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item lg={2}>
                            <TextInput
                                source="multiple_entry.l_reason"
                                label="Reason"
                                variant="outlined"
                                inputRef={fieldRef4}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setTimeout(() => {
                                            fieldRef5?.current?.focus();
                                        }, 1);
                                    }
                                }}
                                fullWidth
                            />
                        </Grid>
                    )}
                    <Grid item lg={2}>
                        <NumberInput
                            source="multiple_entry.l_amount"
                            label="Amount"
                            variant="outlined"
                            inputRef={fieldRef5}
                            onKeyDown={(e: any) => {
                                if (e.key === "Enter" && e.target.value)
                                    handleOnKeyDown();
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
    },
    form: {
        flexGrow: 8,
    },
});

export default LedgerMultipleEntryForm;
