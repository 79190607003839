import imageCompression from "browser-image-compression";
import queryString from "query-string";

import { monthsWithId } from "./constants";

export const required =
    (message: string = "Required") =>
    (value: string): string | undefined =>
        value ? undefined : message;

export const logger = (message, isError: boolean = true): void => {
    if (isError) {
        throw new Error(message);
    }

    if (process.env.REACT_APP_NODE_ENV === "development") {
        if (isError) {
            console.error(message);
        } else {
            console.log(message);
        }
    }
};

export const isInfinity = (value) => !isFinite(value);

export const isObject = (value) =>
    !!(value && typeof value === "object" && !Array.isArray(value));

export const isEmpty = (value) => {
    if (value === undefined || value === null || value === "") return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (isObject(value) && Object.keys(value).length === 0) return true;
    return false;
};

export const isJSONParsable = (data: string) => {
    if (!data) return false;

    try {
        JSON.parse(data);
        return true;
    } catch {
        return false;
    }
};

export const toFixedNumber = (num: number, digits: number = 2): number => {
    if (isNaN(parseFloat(num?.toString())) || isNaN(num - 0)) return 0;

    let pow = Math.pow(10, digits);
    const result = Math.round(num * pow) / pow;
    return isInfinity(result) ? 0 : result;
};

export const numberFormat = (number) => new Intl.NumberFormat().format(number);

export const toFilterObj = (locationSearch: string) => {
    const filterString = queryString.parse(locationSearch).filter?.toString();
    const filterObj = isJSONParsable(filterString)
        ? JSON.parse(filterString)
        : {};
    return filterObj;
};

export const capitalizeFirstLetter = (text: string) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const capitalizeFirstLetterOfEachWord = (sentence: string) => {
    if (!sentence) return "";

    const modifiedSentence = sentence.replace(/([A-Z])(?=[a-z])/g, " $1");
    return modifiedSentence.charAt(0).toUpperCase() + modifiedSentence.slice(1);
};

export const convertToSnakeCase = (str: string) =>
    str
        .split(/(?=[A-Z])/)
        .join("_")
        .toLowerCase();

type toFormattedDateTimeProps = {
    isDate?: boolean;
    isPreviousDate?: boolean;
    isHyphen?: boolean;
    dateString: string;
};

export const toFormattedDateTime = ({
    isDate,
    isPreviousDate,
    isHyphen,
    dateString,
}: toFormattedDateTimeProps) => {
    if (!dateString || dateString === "0000-00-00 00:00:00")
        return "0000-00-00 00:00:00";

    let date;

    if (isPreviousDate) {
        date = new Date();
        date.setDate(date.getDate() - 1);
    } else {
        date = new Date(dateString);
    }

    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hour = ("0" + date.getHours()).slice(-2);
    const minute = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);

    if (isDate || isPreviousDate)
        return `${date.getFullYear()}-${month}-${day}`;
    if (isHyphen)
        return `${date.getFullYear()}-${month}-${day}_${hour}-${minute}-${second}`;

    return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const toFormattedDateString = (dateString) => {
    if (!dateString) return;

    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
    });

    const [month, day, year] = formattedDate.split(" ");

    const rearrangedDate = `${day.split(",")[0]} ${month} ${year}`;
    return rearrangedDate;
};

export const convertToSlug = (text: string) =>
    text.toLowerCase().trim().replace(/ /g, "-");

const transformFile = (file: File, source) => {
    if (!(file instanceof File)) return file;

    const preview = URL.createObjectURL(file);
    const transformedFile = {
        rawFile: file,
        [source]: preview,
        title: file.name,
    };

    return transformedFile;
};

export const transformFiles = (files: File[], source) => {
    if (!files) return [];

    if (Array.isArray(files))
        return files.map((file) => transformFile(file, source));

    return transformFile(files, source);
};

export const convertFileToBase64 = (file: {
    rawFile: Blob;
    title: string;
}): Promise<unknown> => {
    if (!(file?.rawFile instanceof File) && !(file?.rawFile instanceof Blob)) {
        return Promise.resolve(file);
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () =>
            resolve({ src: reader.result, title: file.title });

        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });
};

export const convertFileToString = (file: {
    rawFile: Blob;
    title: string;
}): Promise<unknown> => {
    if (!(file.rawFile instanceof File) && !(file.rawFile instanceof Blob)) {
        return Promise.resolve(file);
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsText(file.rawFile);
    });
};

export const convertImageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
};

export const convertAttachmentsToBase64 = async (key, data = []) => {
    const convertedData = [];

    for (const item of data) {
        const attachments = Array.isArray(item[key]) ? item[key] : [item[key]];

        if (attachments && attachments.length > 0) {
            const convertedAttachments = [];

            for (const attachment of attachments) {
                if (isEmpty(attachment)) {
                    continue;
                }
                const compressedAttachment = await imageCompress(
                    attachment,
                    "",
                    null
                );
                const base64Attachment = await convertFileToBase64(
                    compressedAttachment
                );
                convertedAttachments.push(base64Attachment);
            }
            item[key] = convertedAttachments;
        }
        convertedData.push(item);
    }

    return convertedData;
};

export const imageCompress = (file, resource: string, item): Promise<any> => {
    if (!(file?.rawFile instanceof File) && !(file?.rawFile instanceof Blob)) {
        return Promise.resolve(file);
    }

    let maxWidthOrHeight: number = 1920;

    if (
        item?.key === "attachedFilesApp" ||
        item?.key === "attachedFilesUnderProductBanner"
    ) {
        maxWidthOrHeight = 750;
    } else if (item?.key === "attachedFilesWeb") {
        maxWidthOrHeight = 2700;
    } else if (
        item?.key === "attachedFilesHomepageBanner" ||
        resource === "v1/medicines"
    ) {
        maxWidthOrHeight = 1000;
    }

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight,
        useWebWorker: true,
        initialQuality: 0.8,
    };

    if (file.rawFile?.type?.split("/")[0] === "image") {
        return imageCompression(file.rawFile, options)
            .then((compressedFile) => {
                file.rawFile = compressedFile;
                return file;
            })
            .catch((err) => logger(err));
    } else {
        return Promise.resolve(file);
    }
};

export const autoGeneratedLedgerReason = (obj) => {
    const { l_a_date, username, rank, l_a_uid } = obj || {};

    const month = l_a_date?.split("-")[1];
    const year = l_a_date?.split("-")[0];

    return `Salary to ${username} (${rank}, ID=${l_a_uid}), ${
        monthsWithId.map((item) => item.name)[parseInt((month - 1).toString())]
    }, ${year}`;
};

export const medicineInputTextRenderer = (choice) =>
    !!choice
        ? `${choice?.m_name} (${choice?.m_form}) (${choice?.m_strength}) (${choice?.m_price}Tk) (${choice?.m_d_price}Tk/${choice?.m_unit}) (${choice?.m_company})`
        : "";

export const formSetValues = (form, values) => {
    Object.keys(values).forEach((key) => {
        form.change(key, values[key]);
    });
};

// TODO:
export const isArrayFilter = (value, deep = false) => {
    if (Array.isArray(value)) {
        if (deep) {
            const xvalue = value.filter((nx) => {
                if (isObject(nx)) {
                    const nxx = Object.values(nx).filter((n) => n);

                    if (nxx.length) return true;
                }
                return false;
            });
            if (xvalue) {
                return xvalue;
            } else {
                return [];
            }
        }
        return value.filter((n) => n);
    } else {
        return [value].filter((n) => n);
    }
};

export const buildTreeFromList = (
    list: object[] = [],
    {
        keyId,
        keyParent,
        marginLeft = 5,
        isExpand = true,
        isOpen = true,
    }: {
        keyId: string;
        keyParent: string;
        marginLeft?: number;
        isExpand?: boolean;
        isOpen?: boolean;
    }
) => {
    const map = {};
    const tree = [];

    list?.forEach(
        (item) =>
            (map[item[keyId]] = {
                ...item,
                level: 0,
                marginLeft: 0,
                isExpand,
                isOpen,
                children: [],
            })
    );

    list?.forEach((item) => {
        if (!item[keyParent]) {
            tree.push(map[item[keyId]]);
        } else {
            map[item[keyParent]]?.children.push(map[item[keyId]]);
        }
    });

    const setMarginLeft = (items, level: number) => {
        items.forEach((item) => {
            item.level = level;
            item.marginLeft = level * marginLeft;
            if (item.children.length > 0) {
                setMarginLeft(item.children, level + 1);
            }
        });
    };

    tree.forEach((item) => setMarginLeft(item.children, 1));

    return tree;
};

export const flattenTree = (tree, keyWeight = null) => {
    return tree.flatMap(({ children, ...rest }) => {
        if (children && children.length > 0) {
            if (keyWeight) {
                children.sort((a, b) => a[keyWeight] - b[keyWeight]);
            }
            return [
                { ...rest, hasChildren: true },
                ...flattenTree(children, keyWeight),
            ];
        } else {
            return { ...rest, hasChildren: false };
        }
    });
};
