import { FC } from "react";
import {
    NumberField,
    ReferenceField,
    Show,
    ShowProps,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
} from "react-admin";

import AdjustmentTab from "../../../components/manageHR/salaries/AdjustmentTab";
import { useDocumentTitle } from "../../../hooks";

const SalaryShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Salary Show");

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="Salary">
                    <TextField source="s_id" label="Id" />
                    <ReferenceField
                        source="s_employee_id"
                        label="Employee"
                        reference="v1/employee"
                        link="show"
                        sortBy="s_employee_id"
                    >
                        <TextField source="e_name" />
                    </ReferenceField>
                    <TextField source="s_year" label="Year" />
                    <TextField source="s_month" label="Month" />
                    <NumberField source="s_gross_salary" label="Gross Salary" />
                    <NumberField
                        source="s_gross_addition"
                        label="Gross Addition"
                    />
                    <NumberField
                        source="s_gross_deduction"
                        label="Gross Deduction"
                    />
                    <NumberField source="s_tax" label="Tax" />
                    <NumberField source="s_net_payable" label="Net Payable" />
                    <TextField source="s_payment_mode" label="Payment Mode" />
                    <TextField source="s_status" label="Status" />
                </Tab>
                <Tab label="Salary Adjustment">
                    <SimpleForm toolbar={false}>
                        <AdjustmentTab />
                    </SimpleForm>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default SalaryShow;
