import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import MedicineTypeInput from "../../../components/MedicineTypeInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import OrderInternalStatusInput from "../../../components/manageOrder/orders/OrderInternalStatusInput";
import OrderStatusInput from "../../../components/manageOrder/orders/OrderStatusInput";
import OrderMedicineAvailableStatusInput from "../../../components/manageOrder/ordersMedicines/OrderMedicineAvailableStatusInput";

const OrderMedicinesFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <ReferenceInput
            source="_m_id"
            label="Medicine"
            variant="outlined"
            reference="v1/medicines"
            filterToQuery={(searchText) => ({ _search: searchText })}
            filter={{
                _status: "active",
            }}
            sort={{
                field: "m_name",
                order: "ASC",
            }}
            alwaysOn
        >
            <AutocompleteInput
                optionValue="m_id"
                optionText="m_name"
                resettable
            />
        </ReferenceInput>
        <OrderStatusInput
            source="_status"
            variant="outlined"
            choices={[{ id: "damaged", name: "Damaged" }]}
            alwaysOn
        />
        <FormatedBooleanInput
            source="_is_b2b_order"
            label="B2B Orders"
            alwaysOn
        />
        <OrderMedicineAvailableStatusInput
            source="_om_status"
            label="Available Status"
            variant="outlined"
        />
        <MedicineTypeInput source="_category" label="Type" variant="outlined" />
        <ReferenceInput
            source="_c_id"
            label="Company"
            variant="outlined"
            reference="v1/companies"
            filterToQuery={(searchText) => ({ _search: searchText })}
            sort={{ field: "c_name", order: "ASC" }}
        >
            <AutocompleteInput
                optionValue="c_id"
                optionText="c_name"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_warehouse_id"
            label="Warehouse"
            variant="outlined"
            reference="v1/warehouse"
            filter={{ _role: "warehouse" }}
            filterToQuery={(searchText) => ({ _search: searchText })}
        >
            <AutocompleteInput
                optionValue="w_id"
                optionText="w_title"
                resettable
            />
        </ReferenceInput>
        <DateInput
            source="_o_delivered"
            label="Delivery Date"
            variant="outlined"
        />
        <OrderInternalStatusInput
            source="_i_status"
            label="Internal Status"
            variant="outlined"
            choices={[
                { id: "later", name: "Later" },
                { id: "never", name: "Never" },
            ]}
        />
        <ReferenceInput
            source="_u_id"
            label="User"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={(record: { u_name: string; u_mobile: string }) =>
                    !!record ? `${record.u_name} (${record.u_mobile})` : ""
                }
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default OrderMedicinesFilter;
