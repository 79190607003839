import { FC } from "react";
import {
    ImageField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const BlogShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Blog Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={6} simpleShowLayout={false}>
                    <TextField source="bp_id" label="Id" />{" "}
                    <TextField source="bp_type" label="Type" />
                    <TextField source="bp_title" label="Title" />
                    <TextField source="bp_description" label="Description" />
                    <ImageField
                        source="attachedFiles-bp_images"
                        src="src"
                        className="small__img"
                        title="title"
                        label="Pictures"
                    />
                </ColumnShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};

export default BlogShow;
