import { Box, Button, makeStyles } from "@material-ui/core";
import { FC } from "react";

type ElasticSearchTabProps = {
    setKey: (key: string) => void;
    setOpen: (open: boolean) => void;
};

const ElasticSearchTab: FC<ElasticSearchTabProps> = ({ setKey, setOpen }) => {
    const classes = useStyles();

    return (
        <>
            <Button
                variant="contained"
                className={classes.marginRight}
                onClick={() => {
                    setKey("create");
                    setOpen(true);
                }}
                disableElevation
            >
                Create
            </Button>
            <Button
                variant="contained"
                className={classes.marginRight}
                onClick={() => {
                    setKey("bulkIndex");
                    setOpen(true);
                }}
                disableElevation
            >
                Bulk Index
            </Button>
            <Button
                variant="contained"
                className={classes.marginRight}
                onClick={() => {
                    setKey("setIndex");
                    setOpen(true);
                }}
                disableElevation
            >
                Set Index
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    setKey("delete");
                    setOpen(true);
                }}
                disableElevation
            >
                Delete
            </Button>
            <Box mt={1} />
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    marginRight: {
        marginRight: theme.spacing(1),
    },
}));

export default ElasticSearchTab;
