import { FC } from "react";
import {
    RichTextField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const PagesShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Page Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={6} simpleShowLayout={false}>
                    <TextField source="p_id" label="Id" />
                    <TextField source="p_created" label="Created" />
                    <TextField source="p_updated" label="Updated" />
                    <TextField source="p_title" label="Title" />
                    <TextField source="p_slug" label="Slug" />
                    <TextField source="p_status" label="Status" />
                </ColumnShowLayout>
                <RichTextField source="p_content" label="Content" />
            </SimpleShowLayout>
        </Show>
    );
};

export default PagesShow;
