import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FC } from "react";
import { FileField } from "react-admin";
import { useFormState } from "react-final-form";

import Footer from "./Footer";
import UploadFile from "./UploadFile";

type CollectionsPurchasesProps = {
    tab: "collections" | "purchases";
    data?: any[];
    collectionDataByCoIDS?: any[];
    purchaseDataByPiIDS?: any[];
    refresh?: () => void;
};

const CollectionsPurchases: FC<CollectionsPurchasesProps> = ({
    tab,
    data,
    collectionDataByCoIDS,
    purchaseDataByPiIDS,
    refresh,
}) => {
    const classes = useStyles();
    const { values } = useFormState();

    return (
        <TableContainer>
            <Table size="small" className={classes.table}>
                {!!data?.length && (
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Reason</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Related Files</TableCell>
                            <TableCell>Upload</TableCell>
                        </TableRow>
                    </TableHead>
                )}
                {!data?.length && collectionDataByCoIDS?.length && (
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Confirmed At</TableCell>
                            <TableCell>From Name</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                )}
                {!data?.length && purchaseDataByPiIDS?.length && (
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Paid At</TableCell>
                            <TableCell>Invoice Id</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Related Files</TableCell>
                            <TableCell>Upload</TableCell>
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {!!data?.length &&
                        data.map((item, i) => (
                            <TableRow key={item.id}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.l_reason}</TableCell>
                                <TableCell>{Math.abs(item.l_amount)}</TableCell>
                                <TableCell>
                                    <FileField
                                        source="attachedFiles"
                                        src="src"
                                        title="title"
                                        target="_blank"
                                        record={item}
                                        // @ts-ignore
                                        multiple
                                    />
                                </TableCell>
                                <TableCell>
                                    <UploadFile
                                        id={item.id}
                                        endpointKey="ledger"
                                        refresh={refresh}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    {!data?.length &&
                        collectionDataByCoIDS?.length &&
                        collectionDataByCoIDS.map((item, i) => (
                            <TableRow key={item.id}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.co_created}</TableCell>
                                <TableCell>{item.co_confirmed_at}</TableCell>
                                <TableCell>{item.fm_name}</TableCell>
                                <TableCell>{item.co_amount}</TableCell>
                            </TableRow>
                        ))}
                    {!data?.length &&
                        purchaseDataByPiIDS?.length &&
                        purchaseDataByPiIDS.map((item, i) => (
                            <TableRow key={item.id}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.pi_created_at}</TableCell>
                                <TableCell>{item.pi_paid_at}</TableCell>
                                <TableCell>{item.pi_id}</TableCell>
                                <TableCell>{item.pi_inv_price}</TableCell>
                                <TableCell>
                                    <FileField
                                        source="attachedFiles"
                                        src="src"
                                        title="title"
                                        target="_blank"
                                        record={item}
                                        // @ts-ignore
                                        multiple
                                    />
                                </TableCell>
                                <TableCell>
                                    <UploadFile
                                        id={item.id}
                                        endpointKey="purchaseInvoice"
                                        refresh={refresh}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Footer
                title={
                    tab === "collections"
                        ? "Total Collection"
                        : "Total Purchase"
                }
                amount={
                    tab === "collections"
                        ? values.total_collections
                        : values.total_purchases
                }
            />
        </TableContainer>
    );
};

const useStyles = makeStyles({
    table: {
        width: "60%",
        margin: "auto",
    },
});

export default CollectionsPurchases;
