import { TableCell, TableHead, TableRow } from "@material-ui/core";

const TableHeader = () => (
    <TableHead>
        <TableRow>
            <TableCell>Sl No</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Form</TableCell>
            <TableCell>Strenght</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Total Qty</TableCell>
            <TableCell>TP Price</TableCell>
            <TableCell>Vat</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Discount Fixed</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>MRP</TableCell>
            <TableCell>MRP Percent</TableCell>
            <TableCell>Profit Percent</TableCell>
            <TableCell>Multiplier</TableCell>
            <TableCell>Expiry</TableCell>
            <TableCell>Batch</TableCell>
            <TableCell>Remove</TableCell>
        </TableRow>
    </TableHead>
);

export default TableHeader;
