import { FC, useEffect, useState } from "react";
import {
    Button,
    Datagrid,
    FunctionField,
    List,
    ListProps,
    NumberField,
    Record,
    TextField,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport, useRequest } from "../../../hooks";

const DailyReportList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Daily Report List");
    const exporter = useExport(rest);

    const [id, setId] = useState();

    const { refetch } = useRequest(
        `/v1/daily-report/balanceApprove/${id}`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            successNotify: "Successfully Approved",
        }
    );

    useEffect(() => {
        if (id) refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <List
            {...rest}
            title="List of Daily Report"
            perPage={25}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("dailyReportDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid rowClick="show">
                <NumberField source="b_id" label="Id" sortable={false} />
                <TextField source="b_date" label="Date" sortable={false} />
                <TextField
                    source="b_collection"
                    label="Collection"
                    sortable={false}
                />
                <TextField
                    source="b_purchase"
                    label="Purchase"
                    sortable={false}
                />
                <TextField
                    source="b_expense"
                    label="Expense"
                    sortable={false}
                />
                <TextField
                    source="b_received"
                    label="Received"
                    sortable={false}
                />
                <TextField
                    source="b_balance"
                    label="Cash In Hand"
                    sortable={false}
                />
                <TextField
                    source="b_l_balance"
                    label="Ledger Balance"
                    sortable={false}
                />
                <TextField
                    source="b_l_b_approved"
                    label="Ledger Balance When Approved"
                    sortable={false}
                />
                <TextField source="b_redx_qty" label="Redx Qty" />
                <FunctionField
                    label="Status"
                    sortBy="b_status"
                    sortable={false}
                    render={(record: Record) => {
                        if (
                            record.b_status === "pending" &&
                            permissions?.includes("dailyReportApprove")
                        )
                            return (
                                <Button
                                    label="Approve"
                                    variant="contained"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setId(record.b_id);
                                    }}
                                />
                            );

                        return record.b_status;
                    }}
                />
            </Datagrid>
        </List>
    );
};

export default DailyReportList;
