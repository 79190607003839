import { FC } from "react";
import {
    Datagrid,
    FileField,
    List,
    ListProps,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import EmployeeInfoFilter from "./EmployeeInfoFilter";

const EmployeeInfoList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Employee Info List");

    const exporter = useExport(rest);

    const rowStyle = ({ ei_approved }: Record) => {
        if (ei_approved)
            return {
                backgroundColor: "rgb(0 128 105 / 6%)",
            };
    };

    return (
        <List
            {...rest}
            title="List of Employee Info"
            perPage={25}
            filters={<EmployeeInfoFilter children={""} />}
            sort={{ field: "ei_e_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="show" rowStyle={rowStyle}>
                <TextField source="ei_e_id" label="Employee Id" />
                <ReferenceField
                    source="ei_e_id"
                    label="Employee"
                    reference="v1/employee"
                    link="show"
                    sortBy="ei_e_id"
                >
                    <TextField source="e_name" />
                </ReferenceField>
                <TextField source="ei_date_of_birth" label="Birth Date" />
                <TextField source="ei_blood_group" label="Blood Group" />
                <TextField source="ei_nid" label="NID" />
                <TextField source="ei_tin" label="TIN" />
                <TextField source="ei_license" label="Driving License" />
                <TextField source="ei_passport" label="Passport" />
                <ReferenceField
                    source="ei_bank_id"
                    label="Bank"
                    reference="v1/bank"
                    link="show"
                    sortBy="ei_e_id"
                >
                    <TextField source="b_name" />
                </ReferenceField>
                <TextField
                    source="ei_bank_account_title"
                    label="Bank Account Title"
                />
                <TextField
                    source="ei_bank_account_number"
                    label="Bank Account Number"
                />
                <TextField source="ei_card_number" label="Card Number" />
                <TextField source="ei_client_id" label="Client Id" />
                <FileField
                    source="attachedFiles-ei_cheque_photo"
                    src="src"
                    title="Cheque Files"
                    target="_blank"
                    label="Cheque Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
                <FileField
                    source="attachedFiles-ei_tin_photo"
                    src="src"
                    title="Tin Files"
                    target="_blank"
                    label="Tin Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
                <TextField
                    source="ei_birth_certificate"
                    label="Birth Certificate Number"
                />
                <FileField
                    source="attachedFiles-ei_birth_certificate_photo"
                    src="src"
                    title="Birth Certificate"
                    target="_blank"
                    label="Birth Certificate Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
                <FileField
                    source="attachedFiles-ei_nid_photo"
                    src="src"
                    title="NID Files"
                    target="_blank"
                    label="NID Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
                <FileField
                    source="attachedFiles-ei_license_photo"
                    src="src"
                    title="License Files"
                    target="_blank"
                    label="License Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
                <FileField
                    source="attachedFiles-ei_passport_photo"
                    src="src"
                    title="Passport Files"
                    target="_blank"
                    label="Passport Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                />
            </Datagrid>
        </List>
    );
};

export default EmployeeInfoList;
