import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";

const EmployeeLeaveFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <TaxonomiesByVocabularyInput
            fetchKey="leave_status_type?_authFilter=1"
            source="_status"
            label="Status"
            alwaysOn
        />
        <TaxonomiesByVocabularyInput
            fetchKey="leave_type?_authFilter=1"
            source="_type"
            label="Type"
            alwaysOn
        />
        <ReferenceInput
            source="_employee_id"
            label="Employee"
            variant="outlined"
            reference="v1/employee"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="e_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer isEmployee />}
                inputText={(record: { e_name: string; e_mobile: string }) =>
                    !!record ? `${record.e_name} (${record.e_mobile})` : ""
                }
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default EmployeeLeaveFilter;
