import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { NumberInput, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";
import QtyLabel from "../../QtyLabel";
import NewNumberInput from "./NewNumberInput";

type NewCorrectionDialogProps = {
    open: boolean;
    handleDialogClose: () => void;
};

const NewCorrectionDialog: FC<NewCorrectionDialogProps> = ({
    open,
    handleDialogClose,
}) => {
    const redirect = useRedirect();
    const { values } = useFormState();

    const { i_id, prev_correction, new_correction, counted_value } = values;

    const inShelfDefaultValue =
        (parseInt(values.i_qty) || 0) + (parseInt(values?.packing_qty) || 0);

    const { isLoading, refetch } = useRequest(
        `/v1/inventory/correctionRequest/${i_id}`,
        {
            method: "POST",
            body: {
                new_correction: counted_value - inShelfDefaultValue,
            },
        },
        {
            onSuccess: () => {
                handleDialogClose();
                redirect("/v1/inventory");
            },
        }
    );

    const Result = () => {
        if (inShelfDefaultValue === counted_value) return null;

        return inShelfDefaultValue > counted_value ? (
            <span>
                Lost (কম আছে):{" "}
                <QtyLabel
                    qty={counted_value - inShelfDefaultValue}
                    medicine={values}
                />
            </span>
        ) : (
            <span>
                Found (বেশি আছে):{" "}
                <QtyLabel
                    qty={counted_value - inShelfDefaultValue}
                    medicine={values}
                />
            </span>
        );
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>New Correction</DialogTitle>
            <DialogContent>
                <NewNumberInput
                    source="in_shelf"
                    label="In Shelf (থাকার কথা)"
                    variant="outlined"
                    disabled
                />
                {!!prev_correction && (
                    <Box display="flex" width="100%">
                        <NumberInput
                            source="prev_correction"
                            label="Previous Correction"
                            variant="outlined"
                            disabled
                        />
                        <Box mt={3} ml={1}>
                            <QtyLabel qty={prev_correction} medicine={values} />
                        </Box>
                    </Box>
                )}
                <Box display="flex" width="100%">
                    <NumberInput
                        source="counted_value"
                        variant="outlined"
                        defaultValue={inShelfDefaultValue + new_correction}
                        label="Counted Value (আছে)"
                    />
                    <Box mt={3} ml={1}>
                        <QtyLabel qty={counted_value} medicine={values} />
                    </Box>
                </Box>
                <Result />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default NewCorrectionDialog;
