import { Box, Typography } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    Edit,
    EditProps,
    FormTab,
    TabbedForm,
    TransformData,
    useRedirect,
} from "react-admin";

import { useDocumentTitle, useGetCurrentUser } from "../../../hooks";
import { EmitTypes, SocketServer } from "../../../utils/enums";
import { logger, toFormattedDateTime } from "../../../utils/helpers";
import { axiosInstance } from "../../../utils/http";
import { socket } from "../../../utils/socketio";
import OrderEditActions from "./OrderEditActions";
import OrderEditToolbar from "./OrderEditToolbar";
import {
    AdminTab,
    HistoryTab,
    MedicineTab,
    OrderTab,
    SetupTab,
    UserTab,
} from "./tabs";

const OrderEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Order Edit");

    const redirect = useRedirect();
    const currentUser = useGetCurrentUser();
    const [editingBy, setEditingBy] = useState<any>({});
    const [userCash, setUserCash] = useState("");

    useEffect(() => {
        if (process.env.REACT_APP_SOCKET_SERVER === SocketServer.ON) {
            axiosInstance
                .post("/orders", {
                    userName: currentUser?.u_name,
                    orderId: parseInt(rest.id),
                })
                .then(({ data }) => setEditingBy(data?.data))
                .catch((err) => logger(err));

            socket.on(EmitTypes.ORDER_ID, (data) => setEditingBy(data));
            socket.emit(EmitTypes.SEND_ORDER_ID, parseInt(rest.id));

            return () =>
                socket.emit(EmitTypes.REMOVE_EDITING_ORDER, parseInt(rest.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const othersEditor =
        !!editingBy?.resource?.length &&
        editingBy.resource.filter((item) => item?.userId !== currentUser?.u_id);

    const modifiedEditor =
        !!othersEditor?.length &&
        othersEditor.map((item, i, array: any[]) => (
            <span
                key={item?.userId}
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => redirect(`/v1/users/${item?.userId}/show`)}
            >
                {i === 0 ? "" : i === array?.length - 1 ? " & " : ", "}
                {item?.userName}
            </span>
        ));

    const transform: TransformData = ({
        o_ul_id,
        o_de_time,
        medicineQty,
        ...rest
    }) => {
        const payload = {
            ...rest,
            o_de_time: toFormattedDateTime({
                dateString: o_de_time,
            }),
            medicineQty: JSON.stringify(
                medicineQty?.map(
                    ({ m_id, m_qty, om_id, refund_qty, damage_qty, qty }) => ({
                        m_id,
                        qty: m_qty || qty,
                        om_id,
                        refund_qty,
                        damage_qty,
                    })
                )
            ),
        };
        !!o_ul_id && (payload.o_ul_id = o_ul_id);
        return payload;
    };

    return (
        <>
            {!!modifiedEditor?.length && (
                <Box mt={2}>
                    <Typography>Editing by {modifiedEditor}</Typography>
                </Box>
            )}
            <Edit
                mutationMode={
                    process.env.REACT_APP_NODE_ENV === "development"
                        ? "pessimistic"
                        : "optimistic"
                }
                actions={<OrderEditActions />}
                transform={transform}
                {...rest}
            >
                <TabbedForm
                    submitOnEnter={false}
                    toolbar={<OrderEditToolbar />}
                >
                    <FormTab label="User">
                        <UserTab
                            permissions={permissions}
                            setUserCash={setUserCash}
                        />
                    </FormTab>
                    <FormTab label="Medicine">
                        <MedicineTab
                            permissions={permissions}
                            userCash={userCash}
                        />
                    </FormTab>
                    <FormTab label="Order">
                        <OrderTab permissions={permissions} />
                    </FormTab>
                    <FormTab label="Setup">
                        <SetupTab permissions={permissions} />
                    </FormTab>
                    <FormTab label="Admin">
                        <AdminTab permissions={permissions} />
                    </FormTab>
                    {permissions?.includes("historyView") && (
                        <FormTab label="History">
                            <HistoryTab />
                        </FormTab>
                    )}
                </TabbedForm>
            </Edit>
        </>
    );
};

export default OrderEdit;
