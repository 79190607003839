import { FC, MouseEvent } from "react";
import {
    FunctionField,
    Link,
    NumberField,
    Record,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const CartShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Cart Show");

    return (
        <Show {...props}>
            <ColumnShowLayout>
                <FunctionField
                    label="User Id"
                    sortBy="u_id"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={(record: Record) => (
                        <Link to={`/v1/users/${record.u_id}`}>
                            {record.u_id}
                        </Link>
                    )}
                />
                <TextField source="c_created" label="Created" />
                <TextField source="c_updated" label="Updated" />
                <TextField source="c_d_code" label="Discount Code" />
                <NumberField source="c_count" label="Count" />
                <NumberField source="c_total" label="Total" />
                <TextField source="c_status" label="Status" />
            </ColumnShowLayout>
        </Show>
    );
};

export default CartShow;
