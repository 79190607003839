/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    makeStyles,
    TextField,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { FC, useEffect, useRef, useState } from "react";
import {
    AutocompleteInput,
    FileField,
    FileInput,
    FunctionField,
    maxValue,
    minValue,
    NumberInput,
    Record,
    ReferenceInput,
    TextInput,
    useNotify,
    usePermissions,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useClipboard } from "../../../hooks";
import { aroggaLocalforage } from "../../../services";
import {
    isEmpty,
    logger,
    medicineInputTextRenderer,
    toFixedNumber,
} from "../../../utils/helpers";
import MedicineOptionTextRenderer from "../../MedicineOptionTextRenderer";
import QtyLabel from "../../QtyLabel";
import CreditPaidDialog from "./CreditPaidDialog";
import MrpProfitField from "./MrpProfitField";
import { mutateShapedDiscount } from "./utils";

type InvoiceFormProps = {
    page?: "create" | "edit";
    tpPrice: string;
    setTpPrice: (tpPrice: string) => void;
    piiVat: string;
    setPiiVat: (piiVat: string) => void;
    allItems: any[];
    setAllItems: (items) => void;
    [key: string]: any;
};

const InvoiceForm: FC<InvoiceFormProps> = ({
    page,
    tpPrice,
    setTpPrice,
    piiVat,
    setPiiVat,
    allItems,
    setAllItems,
    ...rest
}) => {
    const classes = useStyles();
    const notify = useNotify();
    const clipboard = useClipboard();
    const form = useForm();
    const { values } = useFormState();
    const { permissions } = usePermissions();

    const fieldRef1 = useRef<HTMLInputElement>(null);
    const fieldRef2 = useRef<HTMLInputElement>(null);
    const fieldRef3 = useRef<HTMLInputElement>(null);
    const fieldRef4 = useRef<HTMLInputElement>(null);
    const fieldRef5 = useRef<HTMLInputElement>(null);
    const fieldRef6 = useRef<HTMLInputElement>(null);
    const fieldRef7 = useRef<HTMLInputElement>(null);
    const fieldRef8 = useRef<HTMLInputElement>(null);
    const fieldRef9 = useRef<HTMLInputElement>(null);
    const fieldRef10 = useRef<HTMLInputElement>(null);
    const fieldRef11 = useRef<HTMLInputElement>(null);
    const fieldRef12 = useRef<HTMLInputElement>(null);
    const fieldRef13 = useRef<HTMLInputElement>(null);
    const fieldRef14 = useRef<HTMLInputElement>(null);
    const fieldRef15 = useRef<HTMLInputElement>(null);

    const [isCreditPaidDialogOpen, setIsCreditPaidDialogOpen] = useState(false);
    const [isCheckedUnitVat, setIsCheckedUnitVat] = useState(false);
    const [warehouseId, setWarehouseId] = useState(0);
    const [companyId, setCompanyId] = useState(0);
    const [selectedMedicine, setSelectedMedicine] = useState<any>({});
    const [multiplier, setMultiplier] = useState(0);

    const isMultiplierInputDisabled =
        !!selectedMedicine.multiplierChanged ||
        !selectedMedicine.m_form ||
        !selectedMedicine.m_unit ||
        !selectedMedicine.m_unit.includes(selectedMedicine.m_form);

    const storeKeys = [
        "pi_warehouse_id",
        "pi_c_id",
        "pi_tp_price",
        "pi_vat",
        "pi_discount",
        "pi_round",
        "pi_payment_method",
    ];

    useEffect(() => {
        if (page === "edit") return;

        aroggaLocalforage.getItem("purchase_invoices").then((value: any) => {
            fieldRef1.current?.focus();
            storeKeys.forEach((key) => form.change(key, value?.[key]));
        });
        aroggaLocalforage
            .getItem("purchase_invoice_items")
            .then((value: any) => setAllItems(!!value?.length ? value : []))
            .catch((err) => logger(err));
    }, []);

    useEffect(() => {
        if (page === "edit") return;

        const storePurchaseInvoices = {};

        storeKeys.forEach((key) => (storePurchaseInvoices[key] = values[key]));
        aroggaLocalforage.setItem("purchase_invoices", storePurchaseInvoices);
    }, [values]);

    useEffect(() => {
        if (page === "edit") return;
        aroggaLocalforage.setItem(
            "purchase_invoice_items",
            !!allItems?.length ? allItems : []
        );
    }, [allItems]);

    useEffect(() => {
        if (page === "edit" && rest?.record?.items?.length) {
            setAllItems(rest.record.items);
        }
    }, [rest?.record?.items]);

    useEffect(() => {
        if (!allItems?.length) return;
        const newAllItems = [...allItems];
        mutateShapedDiscount(newAllItems, values);
        setAllItems(newAllItems);
    }, [allItems?.length, values.pi_round, values.pi_discount]);

    const handleOnKeyDown = (): void => {
        if (selectedMedicine.m_d_price === 0 || selectedMedicine.m_price === 0)
            return notify("Price error!", {
                type: "warning",
            });
        if (multiplier <= 0)
            return notify("Multiplier should be start from 1!", {
                type: "warning",
            });

        const medicine = allItems?.find(
            (item) => item.pu_m_id === values.pu_m_id
        );

        if (medicine)
            return notify("Medicine already added!", { type: "warning" });

        const formValues = {
            ...values,
            ...selectedMedicine,
        };

        const {
            m_batch,
            m_c_id,
            m_company,
            m_d_price,
            m_form,
            m_id,
            m_multiplier,
            m_name,
            m_price,
            m_strength,
            m_unit,
            new_multiplier,
            pi_tp_price,
            pi_warehouse_id,
            pu_qty,
            pu_m_id,
            tpPrice,
            expMonth,
            expYear,
        } = formValues;

        if (!pi_warehouse_id)
            return notify("Warehouse is required field!", { type: "warning" });
        if (!pi_tp_price)
            return notify("Total TP is required field!", {
                type: "warning",
            });
        if (!pu_m_id)
            return notify("Medicine is required field!", { type: "warning" });
        if (!pu_qty)
            return notify("Quantity is required field!", { type: "warning" });
        if (!tpPrice)
            return notify("TP Price is required field!", { type: "warning" });
        if (!multiplier)
            return notify("Multiplier is required field!", { type: "warning" });
        if (!expMonth)
            return notify("Exp month is required field!", { type: "warning" });
        if (!expYear)
            return notify("Exp year is required field!", { type: "warning" });
        if (!(expYear >= 2000 && expYear <= 3000))
            return notify("Must be between 2000 to 3000!", { type: "warning" });
        if (!m_batch)
            return notify("Batch is required field!", { type: "warning" });

        const mrpPercent = Math.round(
            ((pu_qty * selectedMedicine.m_price - (+tpPrice + +piiVat)) /
                (pu_qty * selectedMedicine.m_price)) *
                100
        );

        if (+mrpPercent < 0)
            return notify("MRP Percentage is negative!", { type: "warning" });

        setAllItems((prevState) => [
            {
                m_batch,
                m_c_id,
                m_company,
                m_d_price,
                m_expiry:
                    !!expMonth && !!expYear ? `${expYear}-${expMonth}-01` : "",
                m_form,
                m_id,
                m_multiplier,
                m_name,
                m_price,
                m_strength,
                m_unit,
                new_multiplier,
                pu_price: +tpPrice + +piiVat,
                pu_m_id,
                pu_qty,
                pii_tp_price: tpPrice || 0,
                pii_vat: isCheckedUnitVat ? pu_qty * +piiVat : +piiVat,
                pii_discount: 0,
                isFixedDiscount: false,
                pii_is_fixed_discount: 0,
                isDiscountChanged: false,
                tpPrice,
            },
            ...prevState,
        ]);

        values.pu_m_id = undefined;
        values.pu_qty = undefined;
        setTpPrice("");
        setPiiVat("");
        setMultiplier(0);
        values.expMonth = undefined;
        values.expYear = undefined;
        values.m_batch = undefined;

        // Focus Medicine field
        setTimeout(() => {
            fieldRef8.current?.focus();
        }, 0);
    };

    const handleCopySelectedMedicine = () => {
        clipboard.copy(medicineInputTextRenderer(selectedMedicine));
        notify("Selected medicine copied to clipboard!", {
            type: "success",
        });
    };

    values.pi_total = values.totalIndividualPuPrice;
    values.pi_inv_price = toFixedNumber(
        values.pi_tp_price +
            (values.pi_vat || 0) -
            (values.pi_discount || 0) +
            (values.pi_round || 0),
        2
    );
    values.tpPrice = toFixedNumber(+tpPrice) || 0;
    values.pii_vat = toFixedNumber(+piiVat) || 0;
    values.new_multiplier =
        multiplier === selectedMedicine.m_multiplier ? "" : multiplier;
    values.items = allItems;

    return (
        <>
            <Grid container spacing={1}>
                {page === "edit" && (
                    <Grid item xs={2}>
                        <TextInput
                            source="pi_id"
                            label="Invoice Id"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                )}
                <Grid item xs={2}>
                    <ReferenceInput
                        source="pi_warehouse_id"
                        label="Warehouse"
                        variant="outlined"
                        reference="v1/warehouse"
                        filter={{ _role: "warehouse" }}
                        filterToQuery={(searchText) => ({
                            _search: searchText,
                        })}
                        onChange={(value) => setWarehouseId(value)}
                        onSelect={() => fieldRef2.current?.focus()}
                        fullWidth
                    >
                        <AutocompleteInput
                            options={{
                                InputProps: { inputRef: fieldRef1 },
                            }}
                            optionValue="w_id"
                            optionText="w_title"
                            resettable
                        />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                    <ReferenceInput
                        source="pi_c_id"
                        label="Company"
                        variant="outlined"
                        reference="v1/companies"
                        filterToQuery={(searchText) => ({
                            _search: searchText,
                        })}
                        sort={{ field: "c_name", order: "ASC" }}
                        onChange={(value) => setCompanyId(value)}
                        onSelect={() => fieldRef3.current?.focus()}
                        allowEmpty
                        fullWidth
                    >
                        <AutocompleteInput
                            options={{
                                InputProps: { inputRef: fieldRef2 },
                            }}
                            optionValue="c_id"
                            optionText="c_name"
                            resettable
                        />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={page === "create" ? 2 : 1}>
                    <NumberInput
                        source="pi_tp_price"
                        label="Total TP"
                        variant="outlined"
                        inputRef={fieldRef3}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") fieldRef4.current?.focus();
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <NumberInput
                        source="pi_vat"
                        label="Total Vat"
                        variant="outlined"
                        inputRef={fieldRef4}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") fieldRef5.current?.focus();
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={page === "create" ? 2 : 1}>
                    <NumberInput
                        source="pi_discount"
                        label="Total Discount"
                        variant="outlined"
                        inputRef={fieldRef5}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") fieldRef6.current?.focus();
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <NumberInput
                        source="pi_round"
                        label="Round"
                        variant="outlined"
                        inputRef={fieldRef6}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") fieldRef7.current?.focus();
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <AutocompleteInput
                        source="pi_payment_method"
                        label="Payment Mode"
                        variant="outlined"
                        options={{ InputProps: { inputRef: fieldRef7 } }}
                        choices={[
                            { id: "Cash", name: "Cash" },
                            { id: "Bank", name: "Bank" },
                            { id: "Payable", name: "Payable" },
                            {
                                id: "Short Term Credit",
                                name: "Short Term Credit",
                            },
                            {
                                id: "Long Term Credit",
                                name: "Long Term Credit",
                            },
                        ]}
                        onSelect={() => fieldRef8.current?.focus()}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <ReferenceInput
                        source="pu_m_id"
                        label="Medicine"
                        variant="outlined"
                        reference="v1/medicines"
                        filterToQuery={(searchText) => ({
                            _search: searchText,
                        })}
                        filter={{
                            _purchase: 1,
                            _status: "active,inactive",
                            _c_id: companyId,
                            _warehouse_id: warehouseId,
                        }}
                        sort={{ field: "m_name", order: "ASC" }}
                        fullWidth
                    >
                        <AutocompleteInput
                            optionValue="m_id"
                            optionText={<MedicineOptionTextRenderer />}
                            inputText={medicineInputTextRenderer}
                            matchSuggestion={() => true}
                            shouldRenderSuggestions={(val) =>
                                !!val && val.trim().length > 0
                            }
                            options={{
                                InputProps: {
                                    inputRef: fieldRef8,
                                    startAdornment: !!values.pu_m_id ? (
                                        <InputAdornment
                                            position="start"
                                            onClick={handleCopySelectedMedicine}
                                        >
                                            <FileCopyIcon
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#CED4DA",
                                                }}
                                            />
                                        </InputAdornment>
                                    ) : null,
                                },
                            }}
                            onSelect={(item) => {
                                setSelectedMedicine(item);
                                setTpPrice(item.pii_tp_price);
                                setPiiVat(item.pii_vat);
                                setMultiplier(
                                    item.new_multiplier || item.m_multiplier
                                );
                                fieldRef9.current?.focus();
                            }}
                            resettable
                        />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={1}>
                    <NumberInput
                        source="pu_qty"
                        label="Quantity"
                        variant="outlined"
                        inputRef={fieldRef9}
                        onChange={(e) => {
                            setTpPrice(
                                toFixedNumber(
                                    e.target.value *
                                        (selectedMedicine?.pii_tp_price || 0)
                                ).toString()
                            );
                            setPiiVat(
                                toFixedNumber(
                                    e.target.value *
                                        (selectedMedicine?.pii_vat || 0)
                                ).toString()
                            );
                        }}
                        onKeyDown={(e) => {
                            if (e.key !== "Enter") return;
                            if (!values.pu_qty) return;
                            fieldRef10.current?.focus();
                            fieldRef10.current?.select();
                        }}
                        fullWidth
                    />
                    {!isEmpty(selectedMedicine) && !!values.pu_qty && (
                        <QtyLabel
                            qty={values.pu_qty}
                            medicine={selectedMedicine}
                        />
                    )}
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        label="TP Price"
                        variant="outlined"
                        type="number"
                        inputRef={fieldRef10}
                        value={toFixedNumber(+tpPrice) || ""}
                        size="small"
                        style={{ marginTop: "8px" }}
                        onChange={(e) => setTpPrice(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key !== "Enter") return;
                            if (!values.tpPrice) return;

                            fieldRef11.current?.select();
                            fieldRef11.current?.focus();
                        }}
                        fullWidth
                    />
                    <Box mt={3}>
                        {!!(
                            selectedMedicine.m_d_price === 0 ||
                            selectedMedicine.m_price === 0
                        ) && <div className={classes.fontRed}>Price Error</div>}
                        {!!(
                            values.pu_qty &&
                            tpPrice &&
                            selectedMedicine.m_price
                        ) && (
                            <MrpProfitField
                                tpPrice={tpPrice}
                                selectedMedicine={selectedMedicine}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        label="Vat"
                        variant="outlined"
                        type="number"
                        inputRef={fieldRef11}
                        value={toFixedNumber(+piiVat) || ""}
                        size="small"
                        style={{ marginTop: "8px" }}
                        onChange={(e) => setPiiVat(e.target.value)}
                        onKeyDown={(e) => {
                            if (
                                e.key === "Enter" &&
                                isMultiplierInputDisabled
                            ) {
                                fieldRef13.current?.focus();
                            } else if (e.key === "Enter") {
                                fieldRef12.current?.select();
                                fieldRef12.current?.focus();
                            }
                        }}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCheckedUnitVat}
                                onChange={(e) =>
                                    setIsCheckedUnitVat(e.target.checked)
                                }
                            />
                        }
                        label="Unit VAT?"
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        label="Multiplier"
                        variant="outlined"
                        type="number"
                        inputRef={fieldRef12}
                        value={multiplier === 0 ? "" : multiplier}
                        size="small"
                        style={{ marginTop: "8px" }}
                        onChange={(e) => setMultiplier(+e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key !== "Enter") return;
                            if (!multiplier) return;
                            fieldRef13.current?.focus();
                        }}
                        disabled={isMultiplierInputDisabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <AutocompleteInput
                        source="expMonth"
                        label="Exp Month"
                        variant="outlined"
                        options={{ InputProps: { inputRef: fieldRef13 } }}
                        choices={[
                            { id: "01", name: "Jan" },
                            { id: "02", name: "Feb" },
                            { id: "03", name: "Mar" },
                            { id: "04", name: "Apr" },
                            { id: "05", name: "May" },
                            { id: "06", name: "Jun" },
                            { id: "07", name: "Jul" },
                            { id: "08", name: "Aug" },
                            { id: "09", name: "Sep" },
                            { id: "10", name: "Oct" },
                            { id: "11", name: "Nov" },
                            { id: "12", name: "Dec" },
                        ]}
                        onSelect={() => fieldRef14.current?.focus()}
                        fullWidth
                        resettable
                    />
                </Grid>
                <Grid item xs={1}>
                    <NumberInput
                        source="expYear"
                        label="Exp Year"
                        variant="outlined"
                        inputRef={fieldRef14}
                        validate={[minValue(2000), maxValue(3000)]}
                        onKeyDown={(e) =>
                            e.key === "Enter" &&
                            values.expYear &&
                            fieldRef15.current?.select()
                        }
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextInput
                        source="m_batch"
                        label="Batch"
                        variant="outlined"
                        inputRef={fieldRef15}
                        onKeyDown={(e) =>
                            e.key === "Enter" &&
                            values.m_batch &&
                            handleOnKeyDown()
                        }
                        fullWidth
                    />
                </Grid>
            </Grid>
            {page === "edit" &&
                permissions?.includes("purchaseInvoiceCreditPaid") && (
                    <Box mb={2}>
                        <FunctionField
                            addLabel={false}
                            render={({ pi_payment_method }: Record) => {
                                if (pi_payment_method !== "Payable")
                                    return null;

                                return (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            setIsCreditPaidDialogOpen(true)
                                        }
                                        disableElevation
                                    >
                                        Credit Paid
                                    </Button>
                                );
                            }}
                        />
                        <CreditPaidDialog
                            open={isCreditPaidDialogOpen}
                            handleClose={() => setIsCreditPaidDialogOpen(false)}
                            permissions={permissions}
                        />
                    </Box>
                )}
            <FileInput
                source="attachedFiles"
                label="Attached Files"
                accept="image/*, application/pdf"
                maxSize={5000000}
                multiple
            >
                <FileField source="src" title="title" target="_blank" />
            </FileInput>
        </>
    );
};

const useStyles = makeStyles({
    fontRed: {
        color: "#EF1962",
    },
});

export default InvoiceForm;
