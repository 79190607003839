import { FC } from "react";
import {
    BooleanField,
    Datagrid,
    FunctionField,
    ImageField,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    RichTextField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const MedicineShow: FC<ShowProps> = ({ hasShow, ...rest }) => {
    useDocumentTitle("Arogga | Medicine Show");

    return (
        <Show {...rest}>
            <TabbedShowLayout>
                <Tab label="Medicine">
                    <ColumnShowLayout simpleShowLayout={false}>
                        <TextField
                            source="m_approval_status"
                            label="Approval Status"
                        />
                        <TextField
                            source="changed_medicine.reason"
                            label="Rejected Reason"
                        />
                        <TextField source="m_id" label="Id" />
                        <TextField source="m_name" label="Name" />
                        <TextField source="m_generic" label="Generic" />
                        <TextField source="m_company" label="Company" />
                        <TextField
                            source="m_manufacturer"
                            label="Manufactured By"
                        />
                        <TextField source="m_form" label="Form" />
                        <TextField source="m_strength" label="Strength" />
                        <TextField source="m_unit" label="Unit" />
                        <TextField source="m_min" label="Minimum Qty" />
                        <TextField source="m_max" label="Maximum Qty" />
                        <TextField source="m_w_min" label="Minimum Qty (B2B)" />
                        <TextField source="m_w_max" label="Maximum Qty (B2B)" />
                        <TextField source="m_w_price" label="Wholesale Price" />
                        <TextField source="m_price" label="MRP Price" />
                        <TextField source="m_d_price" label="Discount Price" />
                        <BooleanField source="m_rob" label="Stock" looseValue />
                        <BooleanField
                            source="m_cold"
                            label="Cold Chain Maintained"
                            looseValue
                        />
                        <TextField source="m_category" label="Category" />
                        <TextField source="m_status" label="Status" />
                        <TextField source="m_comment" label="Comment" />
                        <TextField
                            source="m_i_comment"
                            label="Internal Comment"
                        />
                        <TextField source="created_by" label="User ID" />
                    </ColumnShowLayout>
                    <RichTextField source="description" />
                    <FunctionField
                        label="Video"
                        render={({ m_yt_key, m_yt_title }: Record) => {
                            if (!m_yt_key) return null;

                            return (
                                <iframe
                                    title={m_yt_title}
                                    src={`https://www.youtube.com/embed/${m_yt_key}`}
                                    width={640}
                                    height={360}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            );
                        }}
                    />
                    <ImageField
                        source="attachedFiles"
                        src="src"
                        title="title"
                        label="Pictures"
                    />
                </Tab>
                <Tab label="History">
                    <ReferenceManyField
                        addLabel={false}
                        reference="v1/medicines/history"
                        target="_m_id"
                        pagination={<Pagination />}
                        sort={{ field: "mh_m_id", order: "DESC" }}
                    >
                        <Datagrid>
                            <TextField source="mh_id" label="Id" />
                            <TextField
                                source="mh_modified_at"
                                label="Modified At"
                            />
                            <ReferenceField
                                source="mh_modified_by"
                                label="Modified By"
                                reference="v1/users"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <TextField
                                source="mh_supervised_at"
                                label="Supervised At"
                            />
                            <ReferenceField
                                source="mh_supervised_by"
                                label="Supervised By"
                                reference="v1/users"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <TextField source="mh_action" label="Action" />
                            <FunctionField
                                label="From"
                                render={({ mh_action, mh_from }: Record) => {
                                    if (mh_action !== "images") return mh_from;

                                    return (
                                        <ImageField
                                            source="mh_from"
                                            src="src"
                                            title="title"
                                        />
                                    );
                                }}
                            />
                            <FunctionField
                                label="To"
                                render={({ mh_action, mh_to }: Record) => {
                                    if (mh_action !== "images") return mh_to;

                                    return (
                                        <ImageField
                                            source="mh_to"
                                            src="src"
                                            title="title"
                                        />
                                    );
                                }}
                            />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default MedicineShow;
