import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
    usePermissions,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import DeliveryOptionInput from "../../../components/manageOrder/orders/DeliveryOptionInput";
import OrderInternalStatusInput from "../../../components/manageOrder/orders/OrderInternalStatusInput";
import OrderIssueStatusInput from "../../../components/manageOrder/orders/OrderIssueStatusInput";
import OrderPaymentMethodInput from "../../../components/manageOrder/orders/OrderPaymentMethodInput";
import OrderStatusInput from "../../../components/manageOrder/orders/OrderStatusInput";

const OrderFilter: FC<FilterProps> = (props) => {
    const { permissions } = usePermissions();

    const IssueApprovalInput = (props) => {
        if (
            permissions?.includes("role:administrator") ||
            permissions?.includes("role:warehouse")
        ) {
            return <FormatedBooleanInput {...props} />;
        }
    };

    const isRoleOperator = permissions?.includes("role:operator");

    return (
        <Filter {...props}>
            <TextInput
                label="Search"
                source="_search"
                variant="outlined"
                alwaysOn
                resettable
            />
            <DeliveryOptionInput
                source="_de_option"
                label="Delivery Option"
                variant="outlined"
                alwaysOn
            />
            <FormatedBooleanInput source="_issue" label="Issue" alwaysOn />
            <IssueApprovalInput
                source="_issue_approval"
                label="Payment Pending"
                alwaysOn
            />
            <FormatedBooleanInput
                source="_cancel_request"
                label="Cancel Request"
                alwaysOn
            />
            <FormatedBooleanInput
                source="_required_rx"
                label="Need Prescription"
                alwaysOn
            />
            <FormatedBooleanInput
                source="_price_increase"
                label="Price Increase"
                alwaysOn
            />
            <FormatedBooleanInput
                source="_only_processing"
                label="Processing"
                alwaysOn={isRoleOperator}
            />
            <FormatedBooleanInput
                source="_only_prescription"
                label="Prescription"
                alwaysOn={isRoleOperator}
            />
            <ReferenceInput
                source="_ref_partner"
                label="Partner"
                variant="outlined"
                reference="v1/users"
                filter={{ _role: "partner" }}
                filterToQuery={(searchText) => ({ _search: searchText })}
            >
                <AutocompleteInput
                    optionValue="u_id"
                    optionText="u_name"
                    resettable
                />
            </ReferenceInput>
            <FormatedBooleanInput
                source="_o_is_pharmacy_order"
                label="Is B2B Order?"
            />
            <DateInput
                source="_o_created"
                label="Order Date"
                variant="outlined"
            />
            <DateInput
                source="_o_created_end"
                label="Order Date End"
                variant="outlined"
            />
            <DateInput
                source="_o_delivered"
                label="Delivery Date"
                variant="outlined"
            />
            <DateInput
                source="_o_delivered_end"
                label="Delivery Date End"
                variant="outlined"
            />
            <OrderStatusInput
                source="_status"
                choices={[{ id: "damaged", name: "Damaged" }]}
                variant="outlined"
            />
            <OrderInternalStatusInput
                source="_i_status"
                label="Internal Status"
                variant="outlined"
                choices={[
                    { id: "later", name: "Later" },
                    { id: "never", name: "Never" },
                ]}
            />
            <OrderIssueStatusInput
                source="_is_status"
                label="Issue Status"
                variant="outlined"
                allowEmpty
            />
            <FormatedBooleanInput source="_priority" label="Urgent" />
            <OrderStatusInput
                source="_ex_status"
                label="Exclude status"
                variant="outlined"
                choices={[{ id: "damaged", name: "Damaged" }]}
            />
            <ReferenceInput
                source="_de_id"
                label="Delivery Man"
                variant="outlined"
                reference="v1/users"
                filter={{ _role: "delivery" }}
                filterToQuery={(searchText) => ({ _search: searchText })}
            >
                <AutocompleteInput
                    optionValue="u_id"
                    optionText="u_name"
                    resettable
                />
            </ReferenceInput>
            <OrderPaymentMethodInput
                source="_payment_method"
                label="Payment Method"
                variant="outlined"
                choices={[
                    { id: "cash", name: "Cash" },
                    { id: "fosterPayment", name: "Foster Payment" },
                    { id: "nagad", name: "Nagad" },
                    { id: "bKash", name: "bKash" },
                    { id: "sslCommerz", name: "SSL Commerz" },
                    { id: "Redx", name: "Redx" },
                ]}
            />
            <SelectInput
                source="_b_printed"
                label="Barcode Printed"
                variant="outlined"
                choices={[
                    { id: "YES", name: "Printed" },
                    { id: "NO", name: "Not Printed" },
                ]}
            />
        </Filter>
    );
};

export default OrderFilter;
