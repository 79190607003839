/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { FC, MouseEvent, useEffect, useState } from "react";
import {
    Button,
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    Record,
    SimpleForm,
    TextField,
    Toolbar,
    ToolbarProps,
    useRefresh,
    useUnselectAll,
} from "react-admin";

import MedicinesAssignTo from "../../../components/MedicinesAssignTo";
import PurchaseRequestDialog from "../../../components/PurchaseRequestDialog";
import QtyLabel from "../../../components/QtyLabel";
import { useDocumentTitle, useExport } from "../../../hooks";
import InventoryListBulkActionButtons from "../../manageStock/inventories/InventoryListBulkActionButtons";
import LaterMedicinesFilter from "./LaterMedicinesFilter";

const LaterMedicinesList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Pending Medicine List");
    const exporter = useExport(rest);

    const classes = useStyles();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    const [open, setOpen] = useState(false);
    const [unselect, setUnselect] = useState(false);
    const [isAssignTo, setIsAssignTo] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [purchaser, setPurchaser] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (unselect) {
            unselectAll(rest.resource);
            refresh();
        }
    }, [unselect]);

    const modifiedSelectedItems = selectedItems?.map((item) => {
        if (Object.keys(formValues)?.includes(`qty_text-${item?.id}`))
            return {
                m_id: item?.m_id,
                m_name: item?.m_name,
                m_strength: item?.m_strength,
                m_form: item?.m_form,
                qty_text: formValues[`qty_text-${item?.id}`],
            };

        return item;
    });

    interface ISaveToolbarProps extends ToolbarProps {
        setOpen: (open: boolean) => void;
    }

    const SaveToolbar = ({ setOpen, ...rest }: ISaveToolbarProps) => {
        const classes = useStyles();

        return (
            <Toolbar
                {...rest}
                disableGutters
                className={classes.toolbarPosition}
            >
                <Button label="Cancel" onClick={() => setIsAssignTo(false)} />
                <Button
                    label="Save"
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    disabled={!purchaser}
                />
            </Toolbar>
        );
    };

    return (
        <>
            {!isAssignTo ? (
                <List
                    {...rest}
                    title="List of Pending Medicine"
                    filters={<LaterMedicinesFilter children={""} />}
                    perPage={25}
                    sort={{ field: "o_id", order: "DESC" }}
                    bulkActionButtons={
                        <InventoryListBulkActionButtons
                            setIsAssignTo={setIsAssignTo}
                            setSelectedItems={setSelectedItems}
                            deletePermissionKey="laterMedicineDelete"
                        />
                    }
                    exporter={exporter}
                >
                    <Datagrid>
                        <FunctionField
                            label="Order Id"
                            onClick={(e: MouseEvent) => e.stopPropagation()}
                            sortBy="o_id"
                            render={(record: Record) => (
                                <Link
                                    to={`/v1/orders/${record.o_id}`}
                                    style={{ textDecoration: "none" }}
                                >
                                    {record.o_id}
                                </Link>
                            )}
                        />
                        <FunctionField
                            label="Name"
                            onClick={(e: MouseEvent) => e.stopPropagation()}
                            render={(record: Record) => (
                                <Link
                                    to={{
                                        pathname: "/v1/orderMedicines",
                                        search: `filter=${JSON.stringify({
                                            _m_id: record.m_id,
                                            _om_status: "later",
                                            _order: "DESC",
                                            _orderBy: "o_id",
                                            _page: 1,
                                            _perPage: 25,
                                        })}`,
                                    }}
                                >
                                    {record.m_name}
                                </Link>
                            )}
                        />
                        <TextField
                            label="Purchase Assigned"
                            source="purchaseAssigned"
                            sortable={false}
                        />
                        <FunctionField
                            label="Duration"
                            sortBy="o_created"
                            render={(record: Record) => {
                                if (!record) return null;

                                const date = DateTime.local();
                                const diff = date
                                    .diff(DateTime.fromSQL(record.o_created), [
                                        "days",
                                        "hours",
                                        "minutes",
                                    ])
                                    .toObject();

                                return (
                                    <span
                                        style={{
                                            color:
                                                diff.days >= 1
                                                    ? "#FF0000"
                                                    : "#000000",
                                        }}
                                    >
                                        {diff.days}d-{diff.hours}h
                                    </span>
                                );
                            }}
                        />
                        <TextField source="m_form" label="Form" />
                        <TextField source="m_strength" label="Strength" />
                        <TextField source="m_unit" label="Unit" />
                        <FunctionField
                            label="Quantity"
                            sortBy="total_qty"
                            render={({ total_qty, m_form, m_unit }: Record) => (
                                <QtyLabel
                                    qty={total_qty}
                                    medicine={{ m_form, m_unit }}
                                />
                            )}
                        />
                        <FunctionField
                            label="Wkly Req"
                            sortBy="wkly_req"
                            render={({ wkly_req, m_form, m_unit }: Record) => (
                                <QtyLabel
                                    qty={wkly_req}
                                    medicine={{ m_form, m_unit }}
                                />
                            )}
                        />
                        <FunctionField
                            label="Inventory Qty"
                            render={({ i_qty, m_form, m_unit }: Record) => (
                                <QtyLabel
                                    qty={i_qty}
                                    medicine={{ m_form, m_unit }}
                                />
                            )}
                        />
                        <TextField
                            source="m_generic"
                            label="Generic"
                            sortBy="m_g_id"
                        />
                        <TextField
                            source="m_company"
                            label="Company"
                            sortBy="m_c_id"
                        />
                    </Datagrid>
                </List>
            ) : (
                <Paper
                    style={{ marginTop: 25 }}
                    classes={{
                        root: classes.root,
                    }}
                >
                    <SimpleForm toolbar={<SaveToolbar setOpen={setOpen} />}>
                        <MedicinesAssignTo
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            setFormValues={setFormValues}
                            setPurchaser={setPurchaser}
                        />
                        <PurchaseRequestDialog
                            open={open}
                            setOpen={setOpen}
                            setUnselect={setUnselect}
                            formValues={formValues}
                            purchaser={purchaser}
                            setIsAssignTo={setIsAssignTo}
                            modifiedSelectedItems={modifiedSelectedItems}
                        />
                    </SimpleForm>
                </Paper>
            )}
        </>
    );
};

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    toolbarPosition: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "15px",
    },
});

export default LaterMedicinesList;
