import { Box } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { FC, useEffect, useState } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    FormDataConsumer,
    ImageField,
    ImageInput,
    Labeled,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    required,
} from "react-admin";
import { useFormState } from "react-final-form";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import MedicineOptionTextRenderer from "../../../components/MedicineOptionTextRenderer";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import { useDocumentTitle } from "../../../hooks";
import { TINY_MCE_EDITOR_INIT } from "../../../utils/constants";
import { isEmpty, medicineInputTextRenderer } from "../../../utils/helpers";

const BlogCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Blog Create");

    const [content, setContent] = useState("");
    const [medicines, setMedicines] = useState([]);

    const Medicine = (props) => {
        const { values } = useFormState();

        useEffect(() => {
            values.m_id = undefined;
        }, [values]);

        values.bp_medicine = medicines.map((medicine) => medicine.m_id);

        return (
            <ReferenceInput
                label="Medicine"
                reference="v1/medicines"
                filterToQuery={(searchText) => ({
                    _search: searchText,
                })}
                filter={{
                    _status: "active",
                    _genericStat: 1,
                }}
                sort={{
                    field: "m_name",
                    order: "ASC",
                }}
                {...props}
            >
                <AutocompleteInput
                    matchSuggestion={() => true}
                    helperText={false}
                    optionValue="m_id"
                    onSelect={(selectedMedicine) => {
                        const exitsMedicine = medicines.find(
                            (medicine) =>
                                medicine.m_id === selectedMedicine.m_id
                        );
                        if (isEmpty(exitsMedicine))
                            setMedicines((prev) => [...prev, selectedMedicine]);
                    }}
                    optionText={<MedicineOptionTextRenderer />}
                    inputText={medicineInputTextRenderer}
                    shouldRenderSuggestions={(val) =>
                        !!val && val.trim().length > 0
                    }
                    resettable
                />
            </ReferenceInput>
        );
    };

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    source="bp_title"
                    label="Title"
                    variant="outlined"
                    validate={[required()]}
                />
                <TaxonomiesByVocabularyInput
                    fetchKey="blog_type"
                    source="bp_type"
                    label="Blog Type"
                />
                <Medicine source="m_id" variant="outlined" />
                {!!medicines?.length && (
                    <div style={{ margin: 20 }}>
                        {medicines.map((item, i) => (
                            <div>
                                {i + 1}. {item.m_name}
                            </div>
                        ))}
                    </div>
                )}
                <Box mt={2} />
                <NumberInput
                    source="bp_reading_time"
                    label="Reading Time"
                    variant="outlined"
                    validate={[required()]}
                />
                <FormatedBooleanInput
                    source="bp_is_feature"
                    label="Feature"
                    fullWidth
                />
                <ImageInput
                    source="attachedFiles-bp_images"
                    label="Pictures (1800*945 px)"
                    accept="image/*"
                    maxSize={5000000}
                    multiple
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
                <FormDataConsumer>
                    {({ formData }) => {
                        formData.bp_description = content;

                        return (
                            <Labeled label="Description" fullWidth>
                                <Editor
                                    tinymceScriptSrc={
                                        process.env.PUBLIC_URL +
                                        "/tinymce/tinymce.min.js"
                                    }
                                    init={TINY_MCE_EDITOR_INIT}
                                    value={content}
                                    onEditorChange={(newValue) =>
                                        setContent(newValue)
                                    }
                                />
                            </Labeled>
                        );
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default BlogCreate;
