import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { FC, useEffect } from "react";
import { AutocompleteInput, NumberInput, ReferenceInput } from "react-admin";
import { useFormState } from "react-final-form";

import FormatedBooleanInput from "../../FormatedBooleanInput";

type GenerateOrderDialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    setCompanyId: (companyId: string) => void;
    setRequestDays: (requestDays: string) => void;
    setRequestCount: (requestCount: string) => void;
    setIsIncludeLaterMedicine: (isIncludeLaterMedicine: number) => void;
    setIsGenerateOrder: (isGenerateOrder: boolean) => void;
};

const GenerateOrderDialog: FC<GenerateOrderDialogProps> = ({
    open,
    setOpen,
    setCompanyId,
    setRequestDays,
    setRequestCount,
    setIsIncludeLaterMedicine,
    setIsGenerateOrder,
}) => {
    const { values } = useFormState();

    useEffect(() => {
        setCompanyId(values.companyId);
        setRequestDays(values.requestDays);
        setRequestCount(values.requestCount);
        setIsIncludeLaterMedicine(values.isIncludeLaterMedicine);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const handleDialogClose = () => {
        setOpen(false);
        values.companyId = undefined;
        values.requestDays = undefined;
        values.requestCount = undefined;
        values.isIncludeLaterMedicine = true;
    };

    const handleConfirm = () => {
        setIsGenerateOrder(true);
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>Generate Order</DialogTitle>
            <DialogContent>
                <ReferenceInput
                    source="companyId"
                    label="Company"
                    variant="outlined"
                    reference="v1/companies"
                    filterToQuery={(searchText) => ({ _search: searchText })}
                    sort={{ field: "c_name", order: "ASC" }}
                    fullWidth
                >
                    <AutocompleteInput
                        optionValue="c_id"
                        optionText="c_name"
                        resettable
                        fullWidth
                    />
                </ReferenceInput>
                <NumberInput
                    source="requestDays"
                    Label="Days"
                    variant="outlined"
                    defaultValue={7}
                    fullWidth
                />
                <NumberInput
                    source="requestCount"
                    Label="Count"
                    variant="outlined"
                    defaultValue={2}
                    fullWidth
                />
                <FormatedBooleanInput
                    source="isIncludeLaterMedicine"
                    label="Include Later Medicine"
                    defaultValue={true}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleDialogClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleConfirm}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GenerateOrderDialog;
