import DownloadIcon from "@material-ui/icons/GetApp";
import { FC, useState } from "react";
import {
    Button,
    CreateButton,
    Datagrid,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
    TopToolbar,
} from "react-admin";

import ChangeStatusDialog from "../../../components/manageHR/salaries/ChangeStatusDialog";
import TypedExportDialog from "../../../components/manageHR/salaries/TypedExportDialog";
import { useDocumentTitle, useExport } from "../../../hooks";
import salaryFilter from "./salaryFilter";

const ListActions = ({ permissions, setIsTypedExportDialogOpen }) => (
    <TopToolbar>
        <FilterButton />
        <CreateButton label="Create Adjustment" />
        <ExportButton label="Export" />
        {permissions?.includes("salaryExport") && (
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "30px",
                    color: "#008069",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    lineHeight: 1.75,
                    letterSpacing: "0.02857em",
                    fontSize: "0.8125rem",
                    cursor: "pointer",
                }}
                onClick={() => setIsTypedExportDialogOpen(true)}
            >
                <DownloadIcon fontSize="small" color="primary" />
                TYPED EXPORT
            </span>
        )}
    </TopToolbar>
);

const SalaryList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Salary List");

    const exporter = useExport(rest);

    const [isTypedExportDialogOpen, setIsTypedExportDialogOpen] =
        useState(false);
    const [isChangeStatusDialogOpen, setIsChangeStatusDialogOpen] =
        useState(false);
    const [salaryId, setSalaryId] = useState(null);

    return (
        <>
            <List
                {...rest}
                title="List of Salary"
                perPage={25}
                sort={{ field: "s_id", order: "DESC" }}
                filters={salaryFilter}
                bulkActionButtons={false}
                exporter={exporter}
                actions={
                    <ListActions
                        permissions={permissions}
                        setIsTypedExportDialogOpen={setIsTypedExportDialogOpen}
                    />
                }
            >
                <Datagrid rowClick="show">
                    <TextField source="s_id" label="Id" />
                    <ReferenceField
                        source="s_employee_id"
                        label="Employee"
                        reference="v1/employee"
                        link="show"
                        sortBy="s_employee_id"
                    >
                        <TextField source="e_name" />
                    </ReferenceField>
                    <TextField source="s_year" label="Year" />
                    <TextField source="s_month" label="Month" />
                    <TextField
                        source="s_working_days"
                        label="Total Working Day"
                    />
                    <TextField
                        source="s_employee_shift_count"
                        label="Employee Shift"
                    />
                    <TextField
                        source="s_available_leave"
                        label="Payable Leave"
                    />
                    <TextField source="s_leave_taken" label="Spent Leave" />
                    <TextField source="s_absent" label="Absent" />
                    <NumberField
                        source="s_per_shift_salary"
                        label="Per Shift Salary"
                    />
                    <NumberField source="s_gross_salary" label="Salary" />
                    <NumberField
                        source="s_gross_payable"
                        label="Gross Payable"
                    />
                    <NumberField
                        source="s_gross_addition"
                        label="Gross Addition"
                    />
                    <NumberField
                        source="s_gross_deduction"
                        label="Gross Deduction"
                    />
                    <NumberField source="s_tax" label="Tax" />
                    <NumberField source="s_net_payable" label="Net Payable" />
                    <TextField source="s_payment_mode" label="Payment Mode" />
                    <TextField source="s_status" label="Status" />
                    {permissions?.includes("changeSalaryStatus") && (
                        <FunctionField
                            label="Action"
                            render={({ s_id, s_status }: Record) => {
                                if (s_status === "paid") return;

                                return (
                                    <Button
                                        label="Change Status"
                                        variant="contained"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSalaryId(s_id);
                                            setIsChangeStatusDialogOpen(true);
                                        }}
                                    />
                                );
                            }}
                        />
                    )}
                </Datagrid>
            </List>
            <SimpleForm toolbar={false}>
                <TypedExportDialog
                    permissions={permissions}
                    isDialogOpen={isTypedExportDialogOpen}
                    setIsDialogOpen={setIsTypedExportDialogOpen}
                />
                <ChangeStatusDialog
                    isDialogOpen={isChangeStatusDialogOpen}
                    setIsDialogOpen={setIsChangeStatusDialogOpen}
                    salaryId={salaryId}
                />
            </SimpleForm>
        </>
    );
};

export default SalaryList;
