import { DateTime } from "luxon";
import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import TreeDropdownInput from "../../../components/TreeDropdownInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";

const EmployeeFilter: FC<FilterProps> = (props) => (
     
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <FormatedBooleanInput
            source="_requireRelease"
            label="Require Release"
            alwaysOn
        />
        <FormatedBooleanInput
            source="_currentEmployee"
            label="Current Employee"
            alwaysOn
        />
        <FormatedBooleanInput
            source="_releaseEmployee"
            label="Release Employee"
            alwaysOn
        />
        <ReferenceInput
            source="_u_id"
            label="User"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={(record: { u_name: string; u_mobile: string }) =>
                    !!record ? `${record.u_name} (${record.u_mobile})` : ""
                }
                resettable
            />
        </ReferenceInput>
        <TaxonomiesByVocabularyInput
            fetchKey="shift_type?_authFilter=1"
            source="_shiftType"
            label="Shift Type"
        />

        <DateInput
            label="Confirmation"
            source="_confimration_date"
            variant="outlined"
            defaultValue={DateTime.now().toFormat("yyyy-MM")}
            disableDay
        />
        <TreeDropdownInput
            reference="/v1/taxonomiesByVocabulary/department"
            filter={{ _authFilter: 1 }}
            source="_department"
            label="Department"
            variant="outlined"
            keyId="t_id"
            keyParent="t_parent_id"
            optionValue="t_machine_name"
            optionTextValue="t_title"
        />
    </Filter>
);

export default EmployeeFilter;
