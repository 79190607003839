import {
    Box,
    Button,
    Paper,
    TablePagination,
    Typography,
    makeStyles,
} from "@material-ui/core";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Title } from "react-admin";
import { useHistory } from "react-router-dom";

import { useDocumentTitle, useGetCurrentUser } from "../../../hooks";
import { EmitTypes, SocketServer } from "../../../utils/enums";
import { logger } from "../../../utils/helpers";
import { axiosInstance } from "../../../utils/http";
import { socket } from "../../../utils/socketio";

dayjs.extend(relativeTime);

const LiveInfoPage: FC = () => {
    useDocumentTitle("Arogga | Live Info");

    const classes = useStyles();
    const history = useHistory();
    const currentUser = useGetCurrentUser();

    const [state, setState] = useState<any>({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        if (process.env.REACT_APP_SOCKET_SERVER === SocketServer.ON) {
            axiosInstance
                .get(
                    `/users?userName=${
                        currentUser?.u_name
                    }&sort=desc&sortBy=isOnline&page=${
                        page + 1
                    }&limit=${rowsPerPage}&fields=userId,userName,isOnline,offlineAt`
                )
                .then((res) => setState(res?.data))
                .catch((err) => logger(err));

            socket.on(EmitTypes.ALL_USER, (data) => setState(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const onlineUsers =
        !!state?.data?.length && state.data.filter((item) => item.isOnline);

    const offlineUsers =
        !!state?.data?.length && state.data.filter((item) => !item.isOnline);

    const handleChangePage = (_, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Live Info" />
            <Box p={2}>
                {state?.total ? (
                    <Typography>Online: {state?.total}</Typography>
                ) : (
                    <Typography>No User Online</Typography>
                )}
                <Box my={1}>
                    {!!onlineUsers?.length &&
                        onlineUsers.map((user) => (
                            <Box
                                key={user?.userId}
                                display="flex"
                                alignItems="center"
                            >
                                <div className={classes.greenCircle}></div>
                                <div style={{ padding: "0 6px" }}>
                                    <Typography>{user?.userName}</Typography>
                                </div>
                                <Button
                                    onClick={() =>
                                        history.push(
                                            `/user-history/${user.userId}`
                                        )
                                    }
                                >
                                    History
                                </Button>
                            </Box>
                        ))}
                </Box>
                {!!offlineUsers?.length && (
                    <Typography>Last Active Users</Typography>
                )}
                <Box mt={1}>
                    {!!offlineUsers?.length &&
                        offlineUsers.map((user) => (
                            <Box
                                key={user?.userId}
                                display="flex"
                                alignItems="center"
                            >
                                <div style={{ padding: "0 6px" }}>
                                    <Typography>{user?.userName}</Typography>
                                </div>
                                <Typography>
                                    <span
                                        style={{
                                            color: "#4B5563",
                                            fontSize: "14px",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        - {dayjs(user.offlineAt).fromNow()}
                                    </span>
                                </Typography>
                                <div style={{ paddingLeft: "6px" }}>
                                    <Button
                                        size="small"
                                        onClick={() =>
                                            history.push(
                                                `/user-history/${user.userId}`
                                            )
                                        }
                                    >
                                        History
                                    </Button>
                                </div>
                            </Box>
                        ))}
                </Box>
                {!!state?.total && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={state?.total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Box>
        </Paper>
    );
};

const useStyles = makeStyles({
    greenCircle: {
        width: 9,
        height: 9,
        borderRadius: "50%",
        backgroundColor: "#10B981",
    },
});

export default LiveInfoPage;
