import { Record } from "react-admin";

const UserOptionTextRenderer = ({ record }: { record?: Record }) => (
    <span>
        {!!record
            ? `${!!record?.u_name ? record.u_name : ""} ${
                  !!record?.u_mobile ? `(${record.u_mobile})` : ""
              }`
            : ""}
    </span>
);

export default UserOptionTextRenderer;
