import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { useRedirect } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";
import NewNumberInput from "./NewNumberInput";

type AuditDialogProps = {
    open: boolean;
    handleDialogClose: () => void;
};

const AuditDialog: FC<AuditDialogProps> = ({ open, handleDialogClose }) => {
    const redirect = useRedirect();
    const { values } = useFormState();

    const { i_id, in_shelf, shelf_qty, audited_qty } = values;

    const { isLoading, refetch } = useRequest(
        `/v1/inventory/audit/${i_id}/`,
        {
            method: "POST",
            body: {
                audited_qty,
                in_shelf,
            },
        },
        {
            onSuccess: () => {
                handleDialogClose();
                redirect("/v1/inventory");
            },
        }
    );

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>Are you sure you want to audit this?</DialogTitle>
            <DialogContent>
                <NewNumberInput
                    source="in_shelf"
                    label="In Shelf"
                    variant="outlined"
                    disabled
                />
                {!!shelf_qty && (
                    <NewNumberInput
                        source="shelf_qty"
                        label="In Shelf When Audited"
                        variant="outlined"
                        disabled
                    />
                )}
                <NewNumberInput
                    source="audited_qty"
                    label="Audited Qty"
                    variant="outlined"
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default AuditDialog;
