import { CircularProgress } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { FC, useEffect, useState } from "react";
import {
    Edit,
    EditProps,
    SimpleForm,
    useMutation,
    useNotify,
    useRedirect,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import MedicineEditForm from "../../../components/manageDatabase/medicines/MedicineEditForm";
import { useDocumentTitle } from "../../../hooks";
import { logger } from "../../../utils/helpers";

const MedicineEdit: FC<EditProps> = ({ hasEdit, ...rest }) => {
    useDocumentTitle("Arogga | Medicine Edit");

    const redirect = useRedirect();
    const notify = useNotify();
    const [mutate, { data, loading, error }] = useMutation();

    const [description, setDescription] = useState("");

    useEffect(() => {
        if (data) {
            redirect("/v1/medicines?_orderBy=modified_at");
            notify("Successfully updated!", { type: "success" });
        }

        !!error?.message && notify(error.message, "error");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    const onSave = async ({
        id,
        m_company,
        m_generic,
        m_comment,
        changed_medicine,
        ...restValues
    }) => {
        const data = {
            ...restValues,
            m_comment: m_comment || "",
            description,
        };
        try {
            await mutate({
                type: "update",
                resource: "v1/medicines",
                payload: {
                    id: rest.id,
                    data,
                },
            });
        } catch (err) {
            logger(err);
            notify("Something wrong, Please try again!", { type: "warning" });
        }
    };

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                toolbar={
                    <SaveDeleteToolbar
                        isSave={
                            rest.permissions?.includes("medicineEdit") &&
                            !rest.permissions?.includes("elevatedMedicineEdit")
                        }
                        isDelete={rest.permissions?.includes("medicineDelete")}
                    />
                }
                save={onSave}
            >
                <MedicineEditForm
                    page="edit"
                    description={description}
                    setDescription={setDescription}
                    {...rest}
                />
                <Backdrop
                    open={loading}
                    style={{
                        zIndex: 9999,
                        color: "#fff",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </SimpleForm>
        </Edit>
    );
};

export default MedicineEdit;
