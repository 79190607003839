import { AutocompleteInput } from "react-admin";

import { useRequest } from "../../../hooks";

const ZonesInput = (props) => {
    const { data, isLoading } = useRequest(
        "/v1/zones",
        {},
        { isBaseUrl: true, isPreFetching: true }
    );

    const choices =
        data?.zones &&
        Object.values(data.zones)?.map((zone) => ({
            id: zone,
            name: zone,
        }));

    return (
        <AutocompleteInput
            label="Zone"
            choices={choices}
            loading={isLoading}
            resettable
            {...props}
        />
    );
};

export default ZonesInput;
