import { Box } from "@material-ui/core";
import { FC } from "react";
import {
    Edit,
    EditProps,
    FormDataConsumer,
    NumberInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from "react-admin";

import QtyLabel from "../../../components/QtyLabel";
import NewNumberInput from "../../../components/manageStock/inventories/NewNumberInput";
import { useDocumentTitle } from "../../../hooks";

const InventoryEdit: FC<EditProps> = (props) => {
    useDocumentTitle("Arogga | Inventory Edit");

    const isRoleAuditor = props.permissions?.includes("role:auditor");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...props}
        >
            <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
                <TextInput
                    source="i_warehouse_id"
                    label="Warehouse id"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_name"
                    label="Name"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_form"
                    label="Form"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_strength"
                    label="Strength"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_unit"
                    label="Unit"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_company"
                    label="Company"
                    variant="outlined"
                    disabled
                />
                <NumberInput
                    source="i_qty"
                    label="Quantity"
                    variant="outlined"
                    disabled
                />
                <NewNumberInput
                    source="in_shelf"
                    label="In Shelf"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="i_rack_no"
                    label="Rack No"
                    variant="outlined"
                />
                {!isRoleAuditor && (
                    <NumberInput
                        source="i_price"
                        label="Price"
                        variant="outlined"
                        disabled={isRoleAuditor}
                    />
                )}
                {!isRoleAuditor && (
                    <NumberInput
                        source="qty_damage"
                        label="Damage"
                        variant="outlined"
                        disabled
                    />
                )}
                {!isRoleAuditor && (
                    <NumberInput
                        source="qty_lost"
                        label="Lost"
                        variant="outlined"
                        disabled
                    />
                )}
                {!isRoleAuditor && (
                    <NumberInput
                        source="qty_found"
                        label="Found"
                        variant="outlined"
                        disabled
                    />
                )}
                {!isRoleAuditor && (
                    <FormDataConsumer>
                        {({ formData }) => (
                            <Box display="flex">
                                <NumberInput
                                    source="prev_correction"
                                    label="Previous Correction"
                                    variant="outlined"
                                    style={{ width: "256px" }}
                                    disabled
                                />
                                <Box mt={3} ml={1}>
                                    <QtyLabel
                                        qty={formData.prev_correction}
                                        medicine={formData}
                                    />
                                </Box>
                            </Box>
                        )}
                    </FormDataConsumer>
                )}
                {!isRoleAuditor && (
                    <NewNumberInput
                        source="new_damage"
                        label="New Damage"
                        variant="outlined"
                        disabled={isRoleAuditor}
                    />
                )}
                {!isRoleAuditor && (
                    <NewNumberInput
                        source="new_lost"
                        label="New Lost"
                        variant="outlined"
                        disabled={isRoleAuditor}
                    />
                )}
                {!isRoleAuditor && (
                    <NewNumberInput
                        source="new_found"
                        label="New Found"
                        variant="outlined"
                        disabled={isRoleAuditor}
                    />
                )}
                {!isRoleAuditor && (
                    <TextField source="i_note" label="Note" component="pre" />
                )}
            </SimpleForm>
        </Edit>
    );
};

export default InventoryEdit;
