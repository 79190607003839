import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { FC } from "react";
import { ImageField } from "react-admin";

const ImagesTab: FC = () => (
    <>
        <MovableImageInput
            label="Carousel For App (750x300 px)"
            source="attachedFilesApp"
            accept="image/*"
            maxSize={5000000}
            multiple
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Carousel For Web (1405x450 px)"
            source="attachedFilesWeb"
            accept="image/*"
            maxSize={5000000}
            multiple
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Homepage Banner (1000x1000 px)"
            source="attachedFilesHomepageBanner"
            accept="image/*"
            maxSize={5000000}
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Under Product Banner (750x300 px)"
            source="attachedFilesUnderProductBanner"
            accept="image/*"
            maxSize={5000000}
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
        <MovableImageInput
            label="Homepage Refer Banner (750x300 px)"
            source="attachedFilesHomepageRefer"
            accept="image/*"
            maxSize={5000000}
        >
            <ImageField source="src" title="title" />
        </MovableImageInput>
    </>
);

export default ImagesTab;
