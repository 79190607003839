import { Typography } from "@material-ui/core";
import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { FC, Fragment } from "react";
import { ImageField } from "react-admin";

type CategoryBannerTabProps = {
    permissions: string[];
    categories: any[];
};

const CategoryBannerTab: FC<CategoryBannerTabProps> = ({
    permissions,
    categories,
}) => {
    return (
        <>
            {!!categories?.length &&
                categories.map((item) => (
                    <Fragment key={item.c_id}>
                        <Typography>{item.c_name}</Typography>
                        {permissions?.includes("bannerEdit") && (
                            <MovableImageInput
                                source={`attachedFilesCategoryBanner-${item.c_id}`}
                                label=""
                                accept="image/*"
                                maxSize={5000000}
                            >
                                <ImageField source="src" title="title" />
                            </MovableImageInput>
                        )}
                    </Fragment>
                ))}
        </>
    );
};

export default CategoryBannerTab;
