import { Checkbox, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ChangeEvent, FC } from "react";

type TableHeaderProps = {
    page?: string;
    checkedInfo?: {
        enableExchange?: boolean;
        enableMissing?: boolean;
        enableRefund?: boolean;
        enableReplacement?: boolean;
        enableReturned?: boolean;
    };
    checkBoxEnable?: boolean;
    checkBoxProps?: {
        selectAllChecked: (e: ChangeEvent<HTMLInputElement>) => void;
        selectedItems: any[];
        allItems: any[];
    };
};

const border = "1px solid #EF1962";

const TableHeader: FC<TableHeaderProps> = ({
    page,
    checkedInfo,
    checkBoxEnable,
    checkBoxProps,
}) => (
    <TableHead>
        <TableRow>
            {checkBoxEnable && (
                <TableCell padding="checkbox">
                    <Checkbox
                        onChange={checkBoxProps?.selectAllChecked}
                        indeterminate={
                            checkBoxProps?.selectedItems?.length > 0 &&
                            checkBoxProps?.selectedItems?.length <
                                checkBoxProps?.allItems?.length
                        }
                        checked={
                            checkBoxProps?.selectedItems?.length ===
                            checkBoxProps?.allItems?.length
                        }
                        color="primary"
                    />
                </TableCell>
            )}
            <TableCell>Sl No</TableCell>
            {checkedInfo?.enableReturned && (
                <TableCell style={{ border: border }}>Return</TableCell>
            )}
            <TableCell>Name</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Form</TableCell>
            <TableCell>Strenght</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell>Qty</TableCell>
            {checkedInfo?.enableMissing && (
                <TableCell style={{ border: border }}>Missing Qty</TableCell>
            )}
            {checkedInfo?.enableReplacement && (
                <>
                    <TableCell
                        style={{
                            borderLeft: border,
                            borderTop: border,
                            borderBottom: border,
                        }}
                    >
                        দিবেন Qty
                    </TableCell>
                    <TableCell
                        style={{
                            borderTop: border,
                            borderBottom: border,
                        }}
                    >
                        আনবেন Item
                    </TableCell>
                    <TableCell
                        style={{
                            borderTop: border,
                            borderBottom: border,
                            borderRight: border,
                        }}
                    >
                        আনবেন Qty
                    </TableCell>
                </>
            )}
            {checkedInfo?.enableExchange && (
                <>
                    <TableCell
                        style={{
                            borderLeft: border,
                            borderTop: border,
                            borderBottom: border,
                        }}
                    >
                        আনবেন Qty
                    </TableCell>
                    <TableCell
                        style={{
                            borderTop: border,
                            borderBottom: border,
                        }}
                    >
                        দিবেন Item
                    </TableCell>
                    <TableCell
                        style={{
                            borderTop: border,
                            borderBottom: border,
                            borderRight: border,
                        }}
                    >
                        দিবেন Qty
                    </TableCell>
                </>
            )}
            {checkedInfo?.enableRefund && (
                <TableCell
                    style={{
                        borderLeft: border,
                        borderTop: border,
                        borderBottom: border,
                    }}
                >
                    Refund Qty (Item received)
                </TableCell>
            )}
            {checkedInfo?.enableRefund && (
                <TableCell
                    style={{
                        borderTop: border,
                        borderBottom: border,
                        borderRight: border,
                    }}
                >
                    Damage Qty (Item not received)
                </TableCell>
            )}
            <TableCell>Total Qty</TableCell>
            <TableCell>MRP</TableCell>
            <TableCell>Discount Price</TableCell>
            {page !== "offlineOrderShow" && <TableCell>Remove</TableCell>}
        </TableRow>
    </TableHead>
);

export default TableHeader;
