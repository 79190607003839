import FileCopyIcon from "@material-ui/icons/FileCopy";
import { FC, useState } from "react";
import {
    Button,
    Confirm,
    EditActionsProps,
    ListButton,
    ShowButton,
    TopToolbar,
} from "react-admin";
import { useHistory } from "react-router-dom";

import { useRequest } from "../../../hooks";

const OrderEditActions: FC<EditActionsProps> = ({ basePath, data }) => {
    const history = useHistory();

    const [dialogOpen, setDialogOpen] = useState(false);

    const { isLoading, refetch } = useRequest(
        `/v1/orders/reOrder/${data?.id}`,
        {
            method: "POST",
        },
        {
            onSuccess: () => {
                history.push(basePath);
                handleDialogClose();
            },
        }
    );

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    return (
        <TopToolbar>
            <ListButton basePath={basePath} />
            <ShowButton basePath={basePath} record={data} />
            <Button label="Re-Order" onClick={handleDialogOpen}>
                <FileCopyIcon />
            </Button>
            <Confirm
                title="Re-Order"
                content={`Are you sure you want to re-order "${
                    !!data ? data.id : 0
                }"?`}
                isOpen={dialogOpen}
                loading={isLoading}
                onConfirm={refetch}
                onClose={handleDialogClose}
            />
        </TopToolbar>
    );
};

export default OrderEditActions;
