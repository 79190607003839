import { FC, MouseEvent } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    NumberField,
    Record,
    TextField,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import CartFilter from "./CartFilter";

const CartList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Cart List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Cart"
            perPage={25}
            sort={{ field: "c_id", order: "DESC" }}
            filters={<CartFilter children={""} />}
            filterDefaultValues={{ _status: "current" }}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("cartDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid rowClick="edit">
                <FunctionField
                    label="User Id"
                    sortBy="u_id"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={(record: Record) => (
                        <Link to={`/v1/users/${record.u_id}`}>
                            {record.u_id}
                        </Link>
                    )}
                />
                <TextField source="c_created" label="Created" />
                <TextField source="c_updated" label="Updated" />
                <TextField source="c_d_code" label="Discount Code" />
                <NumberField source="c_count" label="Count" />
                <NumberField source="c_total" label="Total" />
                <TextField source="c_status" label="Status" />
            </Datagrid>
        </List>
    );
};

export default CartList;
