import { FC, MouseEvent } from "react";
import {
    Datagrid,
    FunctionField,
    ImageField,
    List,
    ListProps,
    NumberField,
    Record,
    TextField,
} from "react-admin";
import { Link } from "react-router-dom";

import "../../../assets/style.css";
import QtyLabel from "../../../components/QtyLabel";
import { useDocumentTitle, useExport } from "../../../hooks";
import OrderMedicinesFilter from "./OrderMedicinesFilter";

const OrderMedicinesList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Order Medicines List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Order Medicine"
            filters={<OrderMedicinesFilter children={""} />}
            filterDefaultValues={{ _status: "confirmed" }}
            perPage={25}
            sort={{ field: "o_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
            {...rest}
        >
            <Datagrid rowClick="edit">
                <FunctionField
                    label="Order Id"
                    sortBy="o_id"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={(record: Record) => (
                        <Link
                            to={`/v1/orders/${record.o_id}`}
                            style={{ textDecoration: "none" }}
                        >
                            {record.o_id}
                        </Link>
                    )}
                />
                <TextField source="o_created" label="Order Date" />
                <TextField source="o_status" label="Order Status" />
                <TextField source="o_i_status" label="Internal Status" />
                <FunctionField
                    sortBy="m_name"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    label="Medicine"
                    render={(record: Record) => (
                        <Link to={`/v1/medicines/${record.m_id}`}>
                            {record.m_name}
                        </Link>
                    )}
                />
                <TextField source="m_form" label="Form" />
                <TextField source="m_strength" label="Strength" />
                <TextField source="m_unit" label="Unit" />
                <FunctionField
                    label="Quantity"
                    sortBy="m_qty"
                    render={({ m_qty, m_form, m_unit }: Record) => (
                        <QtyLabel qty={m_qty} medicine={{ m_form, m_unit }} />
                    )}
                />
                <NumberField
                    source="s_price_total"
                    label="TP Price"
                    sortable={false}
                />
                <NumberField
                    source="m_d_price_total"
                    label="Sell Price"
                    sortable={false}
                />
                <NumberField
                    source="m_price_total"
                    label="MRP"
                    sortable={false}
                />
                <FunctionField
                    label="Profit Percent"
                    sortBy="supplier_percent"
                    render={({ supplier_percent }: Record) => (
                        <span
                            style={
                                supplier_percent < 0
                                    ? { color: "#EF1962" }
                                    : { color: "green" }
                            }
                        >
                            {supplier_percent}%
                        </span>
                    )}
                />
                <TextField source="om_status" label="Available Status" />
                <ImageField
                    source="attachedFiles"
                    src="src"
                    className="small__img"
                    title="title"
                    label="Pictures"
                />
            </Datagrid>
        </List>
    );
};

export default OrderMedicinesList;
