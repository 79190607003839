import { Button, CircularProgress, DialogActions } from "@material-ui/core";
import { FC } from "react";

type AroggaDialogActionsProps = {
    isLoading: boolean;
    cancelLabel?: string;
    confirmLabel?: string;
    disabled?: boolean;
    onDialogClose: () => void;
    onConfirm: () => void;
};

const AroggaDialogActions: FC<AroggaDialogActionsProps> = ({
    isLoading,
    cancelLabel = "Cancel",
    confirmLabel = "Confirm",
    disabled = false,
    onDialogClose,
    onConfirm,
}) => (
    <DialogActions>
        <Button
            color="primary"
            variant="outlined"
            onClick={onDialogClose}
            style={{
                border: "1px solid #6C757D",
                fontWeight: 600,
                fontSize: "16px",
                color: "#6C757D",
            }}
        >
            {cancelLabel}
        </Button>
        {isLoading ? (
            <CircularProgress size={20} />
        ) : (
            <Button
                color="primary"
                variant="contained"
                onClick={onConfirm}
                style={{
                    fontWeight: 600,
                    fontSize: "16px",
                }}
                disabled={disabled}
            >
                {confirmLabel}
            </Button>
        )}
    </DialogActions>
);

export default AroggaDialogActions;
