import { SelectInput } from "react-admin";

const OrderMedicineAvailableStatusInput = (props) => (
    <SelectInput
        label="Available Status"
        choices={[
            { id: "pending", name: "Pending" },
            { id: "available", name: "Available" },
            { id: "later", name: "Later" },
            { id: "never", name: "Naver" },
            { id: "packed", name: "Packed" },
        ]}
        {...props}
    />
);

export default OrderMedicineAvailableStatusInput;
