import AttachFileIcon from "@material-ui/icons/AttachFile";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import {
    DateField,
    FunctionField,
    IconButtonWithTooltip,
    ImageField,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
    useRefresh,
} from "react-admin";

import QtyLabel from "../../../components/QtyLabel";
import { useDocumentTitle, useExport } from "../../../hooks";
import CustomizableDatagrid from "../../../lib/ra-customizable-datagrid";
import { AroggaUploader } from "../../../services/uploader";
import PurchaseInvoiceItemFilter from "./PurchaseInvoiceItemFilter";

const PurchaseInvoiceItemList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Purchase Item List");
    const exporter = useExport(rest);
    const UploadFile = ({ id }: { id: number }) => {
        const refresh = useRefresh();
        const [open, setOpen] = useState(false);

        return (
            <SimpleForm toolbar={false}>
                <IconButtonWithTooltip
                    label="Upload File"
                    children={<AttachFileIcon />}
                    onClick={() => setOpen(true)}
                    style={{
                        height: 30,
                        width: 30,
                    }}
                />
                <AroggaUploader
                    open={open}
                    accept="image/*, application/pdf"
                    source={`v1/medicines/image/${id}`}
                    refresh={refresh}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            </SimpleForm>
        );
    };

    return (
        <List
            {...rest}
            title="List of Purchase Item"
            filters={<PurchaseInvoiceItemFilter children={""} />}
            perPage={25}
            sort={{ field: "pii_id", order: "DESC" }}
            exporter={exporter}
        >
            <CustomizableDatagrid
                hidableColumns={[
                    "m_price",
                    "pu_price_total",
                    "discount_percent",
                    "profit_percent",
                    "m_expiry",
                    "m_batch",
                    "attachedFiles",
                ]}
            >
                <TextField source="pii_id" label="Id" />
                <ReferenceField
                    source="pi_id"
                    label="Invoice Id"
                    reference="v1/purchaseInvoice"
                    link="edit"
                >
                    <TextField source="pi_id" />
                </ReferenceField>
                <ReferenceField
                    source="m_id"
                    label="Name"
                    reference="v1/medicines"
                    link="show"
                    sortable={false}
                >
                    <TextField source="m_name" />
                </ReferenceField>
                <TextField source="m_form" label="Form" />
                <TextField source="m_strength" label="Strength" />
                <TextField source="m_unit" label="Unit" />
                <FunctionField
                    source="pu_qty"
                    label="Quantity"
                    sortBy="pu_qty"
                    render={({ pu_qty, m_form, m_unit }: Record) => (
                        <QtyLabel qty={pu_qty} medicine={{ m_form, m_unit }} />
                    )}
                />
                <NumberField source="pu_price" label="TP Price" />
                <NumberField source="m_d_price" label="Sell Price" />
                <NumberField source="m_price" label="MRP" />
                <NumberField source="pu_price_total" label="Total" />
                <FunctionField
                    source="discount_percent"
                    label="MRP Percent"
                    sortBy="discount_percent"
                    render={({ discount_percent }: Record) => {
                        if (!discount_percent) return "";

                        return (
                            <span
                                style={
                                    discount_percent < 0 ||
                                    discount_percent > 50
                                        ? { color: "#EF1962" }
                                        : { color: "green" }
                                }
                            >
                                {discount_percent}%
                            </span>
                        );
                    }}
                />
                <FunctionField
                    source="profit_percent"
                    label="Profit Percent"
                    sortBy="profit_percent"
                    render={({ profit_percent }: Record) => {
                        if (!profit_percent) return "";

                        return (
                            <span
                                style={
                                    profit_percent < 0 || profit_percent > 50
                                        ? { color: "#EF1962" }
                                        : { color: "green" }
                                }
                            >
                                {profit_percent}%
                            </span>
                        );
                    }}
                />
                <FunctionField
                    source="m_expiry"
                    label="Expiry"
                    sortBy="m_expiry"
                    render={(record: Record) => {
                        if (!record || "0000-00-00" === record.m_expiry) {
                            return "";
                        }

                        const date = DateTime.fromSQL(record.m_expiry);
                        const diff = date
                            .diff(DateTime.local(), ["months"])
                            .toObject();
                        let color = "black";

                        if (diff.months <= 0) {
                            color = "#EF1962";
                        } else if (diff.months <= 6) {
                            color = "blue";
                        }

                        return (
                            <DateField
                                source="m_expiry"
                                label="Expiry"
                                style={{ color }}
                                record={record}
                                options={{ year: "numeric", month: "short" }}
                            />
                        );
                    }}
                />
                <TextField source="m_batch" label="Batch" />
                <ImageField
                    source="attachedFiles"
                    src="src"
                    className="small__img"
                    title="title"
                    label="Pictures"
                />

                <FunctionField
                    label="Upload"
                    onClick={(e: { stopPropagation: () => void }) =>
                        e.stopPropagation()
                    }
                    render={({ m_id }) => <UploadFile id={+m_id} />}
                />
            </CustomizableDatagrid>
        </List>
    );
};

export default PurchaseInvoiceItemList;
