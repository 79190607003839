import { FC } from "react";
import {
    AutocompleteInput,
    Edit,
    EditProps,
    FileField,
    FileInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    email,
    maxLength,
    required,
} from "react-admin";

import UserOptionTextRenderer from "../../../components/UserOptionTextRenderer";
import ApplicantStatusInput from "../../../components/manageHR/hiring/ApplicantStatusInput";
import { useDocumentTitle } from "../../../hooks";

const ApplicantEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Applicants Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm submitOnEnter={false}>
                <TextInput
                    source="a_id"
                    label="Id"
                    variant="outlined"
                    disabled
                />
                <ReferenceInput
                    source="a_u_id"
                    label="User"
                    variant="outlined"
                    reference="v1/users"
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="u_id"
                        optionText={<UserOptionTextRenderer />}
                        inputText={(record: {
                            u_name: string;
                            u_mobile: string;
                        }) =>
                            !!record
                                ? `${record.u_name} (${record.u_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
                <TextInput
                    source="a_name"
                    label="Name"
                    variant="outlined"
                    validate={[required()]}
                />
                <TextInput
                    source="a_email"
                    label="Email"
                    variant="outlined"
                    validate={[required(), email("Invalid email address")]}
                />
                <TextInput
                    source="a_phone"
                    label="Phone No"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="a_cover_letter"
                    label="Cover Letter"
                    variant="outlined"
                    validate={[maxLength(1000)]}
                    minRows={2}
                    multiline
                />
                <ApplicantStatusInput source="a_status" variant="outlined" />
                <TextInput
                    source="a_note"
                    label="Internal Notes"
                    variant="outlined"
                />
                <FileInput
                    source="attachedFiles"
                    label="Upload CV"
                    accept="application/pdf"
                    multiple
                >
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Edit>
    );
};

export default ApplicantEdit;
