import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import _ from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import { Field, useFormState } from "react-final-form";

import { useRequest } from "../hooks";
import { isObject } from "../utils/helpers";

const filter = createFilterOptions();

type CustomAutoCompleteProps = {
    source: string;
    variant?: "standard" | "outlined" | "filled";
    label?: string;
    fullWidth?: boolean;
    required?: boolean;
    disabled?: boolean;
    reference?: string;
    placeholder?: string;
    style?: any;
    children?: any;
    paranetName?: string;
    filteredBy?: string;
    optionText?: string;
    optionValue?: string;
    joinFields?: {
        src: string;
        dst: string;
    };
};

const CustomAutoComplete = (props: CustomAutoCompleteProps) => {
    const {
        source,
        paranetName,
        filteredBy,
        optionText,
        optionValue,
        joinFields,
        ...rest
    } = props;

    const { values } = useFormState();
    const currentValue = _.get(values, source);

    const [options, setOptions] = useState<any>([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState<any>(null);

    const { data, isLoading, isSuccess, refetch } = useRequest(
        `/${props.reference}?${filteredBy}=${currentValue}`,
        {},
        {
            isWarningNotify: false,
        }
    );

    const autoComplete = useRef(null);

    let loading = isLoading;

    useEffect(() => {
        if (isSuccess) {
            setOptions(
                data?.map((item) => ({
                    label: optionText ? item[optionText] : item?.name,
                    value: optionValue ? item[optionValue] : item?.id,
                    jointValue: isObject(joinFields)
                        ? item[joinFields.dst]
                        : {},
                })) || []
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (inputValue !== "" && inputValue !== null && inputValue) {
                refetch({
                    endpoint: `/${props.reference}?${filteredBy}=${inputValue}`,
                });
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        if (currentValue) {
            refetch({
                endpoint: `/${props.reference}?${filteredBy}=${currentValue}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

    useEffect(() => {
        if (isObject(selectedValue)) {
            if (!selectedValue?.isNew) {
                _.set(
                    values,
                    `${paranetName}.${joinFields?.src}`,
                    selectedValue?.jointValue
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [joinFields, selectedValue, setSelectedValue]);

    const defaultValue = options?.find(
        (option) => option.value === currentValue
    );

    return (
        <Field name={source} {...rest}>
            {(props) => (
                <Autocomplete
                    ref={autoComplete}
                    value={props.input.value ? defaultValue : selectedValue}
                    loading={loading}
                    loadingText="Loading..."
                    noOptionsText="No options"
                    style={props.style}
                    size="small"
                    placeholder={props.placeholder}
                    options={options}
                    onChange={(event, newValue, reason) => {
                        setSelectedValue(newValue);
                        newValue?.isNew
                            ? _.set(values, `${paranetName}.isNew`, true)
                            : _.set(values, `${paranetName}.isNew`, false);
                        if (reason === "clear") {
                            paranetName &&
                                _.omit(values, [`${paranetName}.isNew`]);
                        }
                        props.input.onChange(newValue?.value);
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                            filtered.push({
                                value: params.inputValue,
                                label: `Add "${params.inputValue}"`,
                                isNew: true,
                            });
                        }
                        return filtered;
                    }}
                    onInputChange={(e: any) =>
                        e && setInputValue(e?.target?.value)
                    }
                    getOptionLabel={(option) => {
                        if (typeof option === "string") return option;
                        if (option.inputValue) return option.value;
                        return option.value;
                    }}
                    renderOption={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={props.input.name}
                            label={props.label}
                            variant={props.variant}
                            value={inputValue}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Fragment>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                ),
                            }}
                        />
                    )}
                    autoHighlight
                    freeSolo
                />
            )}
        </Field>
    );
};

export default CustomAutoComplete;
