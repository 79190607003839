import { FC } from "react";
import {
    AutocompleteInput,
    Edit,
    EditProps,
    ReferenceInput,
    SimpleForm,
    TextInput,
} from "react-admin";

import AddressTypeInput from "../../../components/AddressTypeInput";
import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import LocationInput from "../../../components/LocationInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { useDocumentTitle } from "../../../hooks";
import { required } from "../../../utils/helpers";

const AddressEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Address Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm submitOnEnter={false}>
                <ReferenceInput
                    source="u_id"
                    label="User"
                    variant="outlined"
                    reference="v1/users"
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="u_id"
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={(record: {
                            u_name: string;
                            u_mobile: string;
                        }) =>
                            !!record
                                ? `${record.u_name} (${record.u_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
                <TextInput source="ul_name" label="Name" variant="outlined" />
                <TextInput
                    source="ul_mobile"
                    label="Mobile"
                    variant="outlined"
                />
                <AddressTypeInput
                    source="ul_type"
                    variant="outlined"
                    allowEmpty
                />
                <LocationInput
                    source="l_id"
                    variant="outlined"
                    validate={[required()]}
                />
                <TextInput
                    source="ul_address"
                    label="Address"
                    variant="outlined"
                />
                <FormatedBooleanInput source="ul_default" label="Default" />
                <TextInput
                    source="ul_location"
                    label="Full Address"
                    variant="outlined"
                    disabled
                />
            </SimpleForm>
        </Edit>
    );
};

export default AddressEdit;
