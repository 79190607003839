import {
    Button,
    Checkbox,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ChangeEvent, FC, KeyboardEvent, useState } from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    useRecordContext,
} from "react-admin";

import {
    isEmpty,
    medicineInputTextRenderer,
    toFixedNumber,
} from "../../../../utils/helpers";
import MedicineOptionTextRenderer from "../../../MedicineOptionTextRenderer";
import QtyLabel from "../../../QtyLabel";
import Tag from "../../../Tag";

type MedicineTableBodyProps = {
    page?: string;
    checkedInfo?: {
        enableExchange?: boolean;
        enableMissing?: boolean;
        enableRefund?: boolean;
        enableReplacement?: boolean;
        enableReturned?: boolean;
    };
    allItems: any[];
    selectedItems: number[];
    handleOnKeyDown: (e: KeyboardEvent) => void;
    handleOnChange: (e: ChangeEvent, item) => void;
    handleOnBlur: ({
        value,
        medicineId,
        fieldName,
    }: {
        value: any;
        medicineId: number;
        fieldName?: string;
    }) => void;
    handleRemove: (medicineId: number) => void;
    checkBoxEnable?: boolean;
};

const MedicineTableBody: FC<MedicineTableBodyProps> = ({
    page,
    checkedInfo,
    allItems,
    selectedItems,
    handleOnKeyDown,
    handleOnChange,
    handleOnBlur,
    handleRemove,
    checkBoxEnable,
}) => {
    const classes = useStyles();
    const record = useRecordContext();

    const [takeMedicine, setTakeMedicine] = useState(0);
    const [giveMedicine, setGiveMedicine] = useState(0);

    const border = "1px solid #EF1962";

    const priceIncreases = record?.price_increase;

    const CurrentPriceAfterPriceIncrease = ({ source, qty, medicineId }) => {
        if (!priceIncreases?.[medicineId]) return;

        return (
            <span style={{ color: "#EF1962" }}>
                ({toFixedNumber(qty * priceIncreases?.[medicineId]?.[source])})
            </span>
        );
    };

    if (typeof record?.medicineQty === "string") {
        record.medicineQty = JSON.parse(record?.medicineQty);
    } else if (typeof record?.medicineQty === "object") {
        record.medicineQty = Object.values(record?.medicineQty);
    }

    return (
        <TableBody>
            {!!allItems?.length &&
                allItems.map((item, i) => {
                    if (!item) return null;

                    const recordForCurrentItem =
                        !!record?.medicineQty?.length &&
                        record?.medicineQty?.find(
                            (element) => element.m_id === item.m_id
                        );

                    return (
                        <TableRow
                            key={i}
                            className={
                                item?.om_status === "later"
                                    ? classes.tableRowRed
                                    : ""
                            }
                        >
                            {checkBoxEnable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={selectedItems?.includes(
                                            item.m_id
                                        )}
                                        onChange={(e) =>
                                            handleOnChange(e, item)
                                        }
                                    />
                                </TableCell>
                            )}
                            <TableCell>{i + 1}</TableCell>
                            {checkedInfo?.enableReturned && (
                                <TableCell
                                    style={{
                                        borderLeft: border,
                                        borderRight: border,
                                    }}
                                >
                                    <Checkbox
                                        onChange={(e) =>
                                            handleOnChange(e, item)
                                        }
                                        checked={selectedItems?.includes(
                                            item.m_id
                                        )}
                                        name={item?.m_id}
                                        color="primary"
                                    />
                                </TableCell>
                            )}
                            <TableCell>
                                {item.m_name}
                                <br />
                                <span style={{ color: "#EF1962" }}>
                                    {item.is_antibiotics && item.is_controlled
                                        ? "(Antibiotics, Controlled Drug)"
                                        : item.is_antibiotics
                                        ? "(Antibiotics)"
                                        : item.is_controlled
                                        ? "(Controlled Drug)"
                                        : ""}
                                    {!isEmpty(priceIncreases) &&
                                        priceIncreases[item.m_id] && (
                                            <Tag name="PRICE INCREASE" />
                                        )}
                                </span>
                            </TableCell>
                            <TableCell>{item.m_company}</TableCell>
                            <TableCell>{item.m_form}</TableCell>
                            <TableCell>{item.m_strength}</TableCell>
                            <TableCell>{item.m_unit}</TableCell>
                            <TableCell
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                onKeyDown={handleOnKeyDown}
                                onBlur={(e) =>
                                    handleOnBlur({
                                        value: +e.currentTarget.innerText.trim(),
                                        medicineId: item.m_id,
                                    })
                                }
                            >
                                {item.qty}
                            </TableCell>
                            {checkedInfo?.enableMissing && (
                                <TableCell
                                    contentEditable="true"
                                    suppressContentEditableWarning={true}
                                    onKeyDown={handleOnKeyDown}
                                    onBlur={(e) =>
                                        handleOnBlur({
                                            value: +e.currentTarget.innerText.trim(),
                                            medicineId: item.m_id,
                                            fieldName: "missing_qty",
                                        })
                                    }
                                    style={{
                                        borderLeft: border,
                                        borderRight: border,
                                    }}
                                >
                                    {item.missing_qty}
                                </TableCell>
                            )}
                            {checkedInfo?.enableReplacement && (
                                <>
                                    <TableCell
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.m_id,
                                                fieldName: "give_qty",
                                            })
                                        }
                                        style={{
                                            borderLeft: border,
                                        }}
                                    >
                                        {item.give_qty}
                                    </TableCell>
                                    <TableCell>
                                        {takeMedicine === item.m_id ? (
                                            <ReferenceInput
                                                source={`replace_medicine_${item.m_id}`}
                                                label="Medicine"
                                                variant="outlined"
                                                reference="v1/medicines"
                                                filterToQuery={(
                                                    searchText
                                                ) => ({
                                                    _search: searchText,
                                                })}
                                                filter={{
                                                    _status: "active",
                                                }}
                                                sort={{
                                                    field: "m_name",
                                                    order: "ASC",
                                                }}
                                                fullWidth
                                            >
                                                <AutocompleteInput
                                                    matchSuggestion={() => true}
                                                    helperText={false}
                                                    optionValue="m_id"
                                                    onSelect={(element) => {
                                                        item.selectedMedicine =
                                                            {
                                                                id: element?.m_id,
                                                                name: element?.m_name,
                                                                m_form: element?.m_form,
                                                                m_unit: element?.m_unit,
                                                                m_strength:
                                                                    element?.m_strength,
                                                            };

                                                        if (
                                                            item.give_qty ===
                                                            undefined
                                                        )
                                                            item.give_qty =
                                                                item.qty;
                                                        if (
                                                            item.take_qty ===
                                                            undefined
                                                        )
                                                            item.take_qty =
                                                                item.qty;

                                                        setTakeMedicine(0);
                                                    }}
                                                    optionText={
                                                        <MedicineOptionTextRenderer />
                                                    }
                                                    inputText={
                                                        medicineInputTextRenderer
                                                    }
                                                    shouldRenderSuggestions={(
                                                        val: string
                                                    ) => {
                                                        return (
                                                            !!val &&
                                                            val.trim().length >
                                                                0
                                                        );
                                                    }}
                                                    resettable
                                                />
                                            </ReferenceInput>
                                        ) : (
                                            <TextField
                                                label="Medicine"
                                                defaultValue={
                                                    item?.selectedMedicine?.name
                                                }
                                                variant="outlined"
                                                onClick={() =>
                                                    setTakeMedicine(item?.m_id)
                                                }
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.m_id,
                                                fieldName: "take_qty",
                                            })
                                        }
                                        style={{
                                            borderRight: border,
                                        }}
                                    >
                                        {item.take_qty}
                                    </TableCell>
                                </>
                            )}
                            {checkedInfo?.enableExchange && (
                                <>
                                    <TableCell
                                        contentEditable="false"
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.m_id,
                                                fieldName: "take_qty",
                                            })
                                        }
                                        style={{
                                            borderLeft: border,
                                        }}
                                    >
                                        {item.take_qty}
                                    </TableCell>
                                    <TableCell>
                                        {giveMedicine === item.m_id ? (
                                            <ReferenceInput
                                                source={`replace_medicine_${item.m_id}`}
                                                label="Medicine"
                                                reference="v1/medicines"
                                                filterToQuery={(
                                                    searchText
                                                ) => ({
                                                    _search: searchText,
                                                })}
                                                filter={{
                                                    _status: "active",
                                                }}
                                                sort={{
                                                    field: "m_name",
                                                    order: "ASC",
                                                }}
                                                fullWidth
                                            >
                                                <AutocompleteInput
                                                    matchSuggestion={() => true}
                                                    helperText={false}
                                                    optionValue="m_id"
                                                    onSelect={(element) => {
                                                        item.selectedMedicine =
                                                            {
                                                                id: element?.m_id,
                                                                name: element?.m_name,
                                                                m_form: element?.m_form,
                                                                m_unit: element?.m_unit,
                                                            };

                                                        if (
                                                            item.give_qty ===
                                                            undefined
                                                        )
                                                            item.give_qty =
                                                                item.qty;
                                                        if (
                                                            item.take_qty ===
                                                            undefined
                                                        )
                                                            item.take_qty =
                                                                item.qty;

                                                        setGiveMedicine(0);
                                                    }}
                                                    optionText={
                                                        <MedicineOptionTextRenderer />
                                                    }
                                                    inputText={
                                                        medicineInputTextRenderer
                                                    }
                                                    shouldRenderSuggestions={(
                                                        val
                                                    ) => {
                                                        return (
                                                            !!val &&
                                                            val.trim().length >
                                                                0
                                                        );
                                                    }}
                                                    resettable
                                                />
                                            </ReferenceInput>
                                        ) : (
                                            <TextField
                                                label="Medicine"
                                                defaultValue={
                                                    item?.selectedMedicine?.name
                                                }
                                                variant="filled"
                                                onClick={() =>
                                                    setGiveMedicine(item?.m_id)
                                                }
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                        onKeyDown={handleOnKeyDown}
                                        onBlur={(e) =>
                                            handleOnBlur({
                                                value: +e.currentTarget.innerText.trim(),
                                                medicineId: item.m_id,
                                                fieldName: "give_qty",
                                            })
                                        }
                                        style={{
                                            borderRight: border,
                                        }}
                                    >
                                        {item.give_qty}
                                    </TableCell>
                                </>
                            )}
                            {checkedInfo?.enableRefund && (
                                <TableCell
                                    contentEditable={
                                        recordForCurrentItem?.refund_qty > 0 ||
                                        recordForCurrentItem?.damage_qty > 0
                                            ? "false"
                                            : "true"
                                    }
                                    suppressContentEditableWarning={true}
                                    onKeyDown={handleOnKeyDown}
                                    onBlur={(e) =>
                                        handleOnBlur({
                                            value: +e.currentTarget.innerText.trim(),
                                            medicineId: item.m_id,
                                            fieldName: "refund_qty",
                                        })
                                    }
                                    style={{
                                        borderLeft: border,
                                    }}
                                >
                                    {item?.refund_qty}
                                </TableCell>
                            )}
                            {checkedInfo?.enableRefund && (
                                <TableCell
                                    contentEditable={
                                        recordForCurrentItem?.refund_qty > 0 ||
                                        recordForCurrentItem?.damage_qty > 0
                                            ? "false"
                                            : "true"
                                    }
                                    suppressContentEditableWarning={true}
                                    onKeyDown={handleOnKeyDown}
                                    onBlur={(e) =>
                                        handleOnBlur({
                                            value: +e.currentTarget.innerText.trim(),
                                            medicineId: item.m_id,
                                            fieldName: "damage_qty",
                                        })
                                    }
                                    style={{
                                        borderRight: border,
                                    }}
                                >
                                    {item?.damage_qty}
                                </TableCell>
                            )}
                            <TableCell>
                                <QtyLabel qty={item.qty} medicine={item} />
                            </TableCell>
                            <TableCell>
                                {toFixedNumber(item.qty * item.m_price)}{" "}
                                <CurrentPriceAfterPriceIncrease
                                    source="m_price"
                                    qty={item.qty}
                                    medicineId={item.m_id}
                                />
                            </TableCell>
                            <TableCell>
                                {toFixedNumber(item.qty * item.m_d_price)}{" "}
                                <CurrentPriceAfterPriceIncrease
                                    source="m_d_price"
                                    qty={item.qty}
                                    medicineId={item.m_id}
                                />
                            </TableCell>
                            {page !== "offlineOrderShow" && (
                                <TableCell>
                                    <Button
                                        onClick={() => handleRemove(item.m_id)}
                                    >
                                        <ClearIcon
                                            style={
                                                item?.om_status === "later"
                                                    ? { color: "#EF1962" }
                                                    : {}
                                            }
                                        />
                                    </Button>
                                </TableCell>
                            )}
                        </TableRow>
                    );
                })}
        </TableBody>
    );
};

const useStyles = makeStyles({
    tableRowRed: {
        "& > .MuiTableCell-root": {
            color: "#EF1962",
        },
    },
});

export default MedicineTableBody;
