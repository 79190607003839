import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FC, useEffect, useRef, useState } from "react";
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    useNotify,
    useRecordContext,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { isEmpty, medicineInputTextRenderer } from "../../../utils/helpers";
import AroggaButton from "../../AroggaButton";
import MedicineOptionTextRenderer from "../../MedicineOptionTextRenderer";
import QtyLabel from "../../QtyLabel";

type MedicineFormProps = {
    page?: string;
    allItems: any[];
    setAllItems: (items: object[]) => void;
    createForm?: boolean;
};

const MedicineForm: FC<MedicineFormProps> = ({
    page,
    createForm,
    allItems,
    setAllItems,
}) => {
    const record = useRecordContext();
    const classes = useStyles();
    const form = useForm();
    const notify = useNotify();
    const { values } = useFormState();

    const fieldRef1 = useRef<HTMLInputElement>(null!);
    const fieldRef2 = useRef<HTMLInputElement>(null!);

    const [currentItem, setCurrentItem] = useState<any>({});

    useEffect(() => {
        if (!createForm && !allItems?.length && record?.medicineQty) {
            setAllItems(record.medicineQty);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    useEffect(() => {
        const updatedAllItems =
            !!allItems?.length &&
            allItems.map(({ m_id, qty }) => ({
                m_id,
                qty,
            }));
        form.change("medicineQty", updatedAllItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allItems]);

    const {
        m_id,
        m_name,
        m_form,
        m_strength,
        m_unit,
        m_price,
        m_d_price,
        m_company,
        is_antibiotics,
        is_controlled,
    } = currentItem || {};

    const addNewMedicine = (e) => {
        if (values?.m_id !== undefined && e.target.value === "") {
            return fieldRef2.current?.focus();
        }

        if (
            (values?.m_id === undefined && e.target.value !== "") ||
            (values?.m_id === undefined && e.target.value === "")
        ) {
            return fieldRef1.current?.focus();
        }

        const isItemExits =
            !!allItems?.length && allItems.find((item) => item.m_id === m_id);

        if (isItemExits) {
            form.change("m_id", undefined);
            form.change("qty", undefined);

            fieldRef1.current?.focus();

            return notify(`Medicine already exits!`, { type: "warning" });
        }

        if (e.target.value !== "" || values?.m_id !== undefined) {
            const newAllItems = [
                ...allItems,
                {
                    m_id,
                    qty: values.qty,
                    is_antibiotics,
                    is_controlled,
                    om_status: "pending",
                    s_price: "0.00",
                    // add additional info for rendering
                    m_name,
                    m_form,
                    m_strength,
                    m_unit,
                    m_price,
                    m_d_price,
                    m_company,
                },
            ];

            setAllItems(newAllItems);

            form.change("m_id", undefined);
            form.change("qty", undefined);

            setTimeout(() => {
                fieldRef1.current?.focus();
            }, 1);
        }
    };

    if (page === "offlineOrderShow") return;

    return (
        <div className={classes.root}>
            <div className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <ReferenceInput
                            source="m_id"
                            label="Medicine"
                            variant="outlined"
                            reference="v1/medicines"
                            filterToQuery={(searchText) => ({
                                _search: searchText,
                            })}
                            filter={{
                                _status: "active",
                                _genericStat: 1,
                            }}
                            sort={{
                                field: "m_name",
                                order: "ASC",
                            }}
                            fullWidth
                        >
                            <AutocompleteInput
                                matchSuggestion={() => true}
                                helperText={false}
                                optionValue="m_id"
                                options={{
                                    InputProps: { inputRef: fieldRef1 },
                                }}
                                onSelect={(item) => {
                                    setCurrentItem(item);
                                    fieldRef2.current?.focus();
                                }}
                                optionText={<MedicineOptionTextRenderer />}
                                inputText={medicineInputTextRenderer}
                                shouldRenderSuggestions={(val) =>
                                    !!val && val.trim().length > 0
                                }
                                resettable
                            />
                        </ReferenceInput>
                    </Grid>
                    <Grid item md={4}>
                        <NumberInput
                            source="qty"
                            label="Quantity"
                            variant="outlined"
                            helperText={false}
                            inputRef={fieldRef2}
                            onKeyDown={(e) => {
                                if (e.key !== "Enter") return;
                                addNewMedicine(e);
                            }}
                            fullWidth
                        />
                        {!isEmpty(currentItem) && values.qty && (
                            <Box pl={2}>
                                <QtyLabel
                                    qty={values.qty}
                                    medicine={currentItem}
                                />
                            </Box>
                        )}
                    </Grid>
                    <Grid item md={2}>
                        <AroggaButton
                            label="Add New"
                            onClick={addNewMedicine}
                            style={{
                                marginTop: 9,
                                width: "93px",
                                height: "38px",
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
    },
    form: {
        flexGrow: 8,
    },
});

export default MedicineForm;
