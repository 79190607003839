const Tag = ({ name = "tag" }) => (
    <span
        style={{
            color: "white",
            padding: "2px 4px",
            fontSize: "0.6vw",
            fontWeight: "bold",
            backgroundColor: "#F27979",
            borderRadius: 2,
        }}
    >
        {name.toUpperCase()}
    </span>
);

export default Tag;
