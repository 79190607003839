import { SelectInput } from "react-admin";

import { useGetTaxonomiesByVocabulary } from "../../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";

const choiceFormatter = (types, label) => {
    return !!types?.length
        ? types.map(({ t_machine_name, t_title }) => ({
              id: t_machine_name,
              name: `${label} - ${capitalizeFirstLetterOfEachWord(t_title)}`,
          }))
        : [];
};

const StatusInput = (props) => {
    const attendanceStatusTypes =
        useGetTaxonomiesByVocabulary("attendance_status");
    const holidayTypes = useGetTaxonomiesByVocabulary("holiday_type");
    const leaveTypes = useGetTaxonomiesByVocabulary("leave_type");

    return (
        <SelectInput
            {...props}
            label="Status"
            choices={[
                ...choiceFormatter(attendanceStatusTypes, "Attendance"),
                ...choiceFormatter(holidayTypes, "Holiday"),
                ...choiceFormatter(leaveTypes, "Leave"),
            ]}
        />
    );
};

export default StatusInput;
