import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { FC } from "react";
import { TextInput, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { convertFileToBase64, isEmpty } from "../../../utils/helpers";
import AroggaDialogActions from "../../AroggaDialogActions";

type ElevatedActionDialogProps = {
    action: string;
    description: string;
    open: boolean;
    setIsDialogOpen: (isDialogOpen: boolean) => void;
};

const ElevatedActionDialog: FC<ElevatedActionDialogProps> = ({
    action,
    description: tinyMceDescription,
    open,
    setIsDialogOpen,
}) => {
    const redirect = useRedirect();
    const { values } = useFormState();

    const {
        m_g_id = null,
        m_c_id = null,
        m_min,
        m_max,
        m_w_min,
        m_w_max,
        m_price,
        m_d_price,
        m_w_price,
        m_comment,
        description,
        deletedFiles,
        ...rest
    } = values;

    let body;
    let attachedFilesWithoutDeletedFiles;

    if (action === "cancelled") {
        body = {
            status: action,
        };
    } else if (action === "rejected") {
        body = {
            status: action,
            reason: values.reason,
        };
    } else {
        body = {
            status: action,
            m_g_id,
            m_c_id,
            m_min: m_min < 1 ? null : m_min,
            m_max: m_max < 1 ? null : m_max,
            m_w_min: m_w_min < 1 ? null : m_w_min,
            m_w_max: m_w_max < 1 ? null : m_w_max,
            m_price: m_price < 1 ? null : m_price,
            m_d_price: m_d_price < 1 ? null : m_d_price,
            m_w_price: m_w_price < 1 ? null : m_w_price,
            m_comment: m_comment || "",
            description: tinyMceDescription,
            deletedFiles,
            ...rest,
        };

        body?.attachedFiles?.map(async (file, i) => {
            if (file?.rawFile) {
                const result = await convertFileToBase64(file);
                body.attachedFiles[i] = result;
            }
            return body;
        });

        if (!isEmpty(deletedFiles)) {
            attachedFilesWithoutDeletedFiles = body?.attachedFiles?.filter(
                (file) => deletedFiles.some((item) => item.s3key !== file.s3key)
            );
        }
    }

    const { isLoading, refetch } = useRequest(
        `/v1/medicines/approval/${values.m_id}`,
        {
            method: "POST",
            body:
                action === "approved"
                    ? {
                          ...body,
                          attachedFiles: !isEmpty(
                              attachedFilesWithoutDeletedFiles
                          )
                              ? attachedFilesWithoutDeletedFiles
                              : body.attachedFiles,
                      }
                    : body,
        },
        {
            onSuccess: () => {
                onDialogClose();
                redirect("/v1/medicines?_orderBy=supervised_at");
            },
        }
    );

    const onDialogClose = () => {
        values.reason = undefined;
        setIsDialogOpen(false);
    };

    return (
        <Dialog open={open} onClose={onDialogClose}>
            <DialogTitle>
                <Typography>{`Are you sure you want to ${
                    values.m_approval_status === "approved" ? "saved" : action
                } this medicine? #${values.m_id}`}</Typography>
            </DialogTitle>
            {action === "rejected" && (
                <DialogContent>
                    <TextInput
                        source="reason"
                        label="Reason"
                        variant="outlined"
                        fullWidth
                    />
                </DialogContent>
            )}
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={onDialogClose}
                onConfirm={refetch}
                disabled={action === "rejected" && !values.reason}
            />
        </Dialog>
    );
};

export default ElevatedActionDialog;
