import { SelectInput } from "react-admin";

const OrderIssueStatusInput = (props) => (
    <SelectInput
        label="Issue Status"
        choices={[
            { id: "packing", name: "Packing" },
            { id: "checking", name: "Checking" },
            { id: "packed", name: "Packed" },
            { id: "delivering", name: "Delivering" },
            { id: "delivered", name: "Delivered" },
            { id: "paymentPending", name: "Payment Pending" },
            { id: "redx issue", name: "Redx Issue" },
            { id: "solved", name: "Solved" },
        ]}
        {...props}
    />
);

export default OrderIssueStatusInput;
