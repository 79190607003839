import { Box, Button, Paper, TextField } from "@material-ui/core";
import { FC, useState } from "react";
import {
    FormTab,
    TabbedForm,
    Title,
    Toolbar,
    useNotify,
    usePermissions,
} from "react-admin";
import { JsonInput } from "react-admin-json-view";

import uploadDataProvider from "../../../dataProviderFileUpload";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { logger } from "../../../utils/helpers";
import B2BTab from "./B2BTab";
import CategoryBannerTab from "./CategoryBannerTab";
import ImagesTab from "./ImagesTab";
import SideScrollTab from "./SideScrollTab";

const SettingsPage: FC = () => {
    useDocumentTitle("Arogga | Settings");

    const notify = useNotify();
    const { permissions } = usePermissions();

    const [ip, setIp] = useState("");

    const { data, refetch: refetchOptionsMultiple } = useRequest(
        "/v1/optionsMultiple",
        {},
        { isPreFetching: true }
    );

    const { data: categoriesData } = useRequest(
        "/v1/categories",
        {},
        { isBaseUrl: true, isPreFetching: true }
    );

    const { refetch: handleUnblockIp } = useRequest(
        "/v1/adminActions/ip/unblock/",
        {
            method: "POST",
            body: {
                ip,
            },
        }
    );

    const onSave = async (data: object) => {
        try {
            await uploadDataProvider.create("v1/optionsMultiple", {
                data,
            });
            notify("Successfully save!", { type: "success" });
            refetchOptionsMultiple();
        } catch (err) {
            logger(err);
            notify("Something wrong, Please try again!", { type: "warning" });
        }
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Settings" />
            <TabbedForm
                initialValues={data}
                syncWithLocation={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
                save={onSave}
            >
                <FormTab label="B2C">
                    <ImagesTab />
                </FormTab>
                <FormTab label="B2B">
                    <B2BTab />
                </FormTab>
                {permissions?.includes("bannerView") && (
                    <FormTab label="Category Banner">
                        <CategoryBannerTab
                            permissions={permissions}
                            categories={categoriesData?.categories}
                        />
                    </FormTab>
                )}
                <FormTab label="Side Scroll">
                    <SideScrollTab categories={categoriesData?.categories} />
                </FormTab>
                <FormTab label="FAQs">
                    <JsonInput
                        source="faqs"
                        reactJsonOptions={{
                            name: null,
                            collapsed: false,
                            enableClipboard: false,
                            displayDataTypes: false,
                        }}
                    />
                </FormTab>
                <FormTab label="Unblock IP">
                    <Box
                        style={{ display: "flex", width: "100%" }}
                        gridGap={8}
                        mb={1}
                    >
                        <TextField
                            name="ipAddress"
                            label="IP Address"
                            placeholder="Enter ip address"
                            size="small"
                            variant="outlined"
                            value={ip}
                            onChange={(e) => setIp(e.target.value)}
                        />

                        <Button
                            variant="contained"
                            onClick={handleUnblockIp}
                            disableElevation
                            disabled={!ip}
                        >
                            Unblock IP
                        </Button>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Paper>
    );
};

export default SettingsPage;
