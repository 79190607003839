import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import MedicineCategoryInput from "../../../components/MedicineCategoryInput";

const LaterMedicinesFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <ReferenceInput
            source="_c_id"
            label="Company"
            variant="outlined"
            reference="v1/companies"
            filterToQuery={(searchText) => ({ _search: searchText })}
            sort={{ field: "c_name", order: "ASC" }}
            resettable
            alwaysOn
        >
            <AutocompleteInput
                optionValue="c_id"
                optionText="c_name"
                resettable
            />
        </ReferenceInput>
        <FormatedBooleanInput
            source="_not_assigned"
            label="Not Assigned"
            alwaysOn
        />
        <ReferenceInput
            source="_warehouse_id"
            label="Warehouse"
            variant="outlined"
            reference="v1/warehouse"
            filter={{ _role: "warehouse" }}
            filterToQuery={(searchText) => ({ _search: searchText })}
            resettable
        >
            <AutocompleteInput
                optionValue="w_id"
                optionText="w_title"
                resettable
            />
        </ReferenceInput>
        <MedicineCategoryInput
            source="_cat_id"
            label="Category"
            variant="outlined"
        />
        <ReferenceInput
            source="_g_id"
            label="Generic"
            variant="outlined"
            reference="v1/generics"
            filterToQuery={(searchText) => ({ _search: searchText })}
            sort={{ field: "g_name", order: "ASC" }}
            resettable
        >
            <AutocompleteInput
                optionValue="g_id"
                optionText="g_name"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_u_id"
            label="Purchaser"
            variant="outlined"
            reference="v1/users"
            filter={{ _role: "purchaser" }}
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
            resettable
        >
            <AutocompleteInput
                optionValue="u_id"
                optionText="u_name"
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default LaterMedicinesFilter;
