/* eslint-disable */ "use strict";

Object.defineProperty(exports, "__esModule", {
    value: true,
});

var _typeof2 =
    typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
        ? function (obj) {
              return typeof obj;
          }
        : function (obj) {
              return obj &&
                  typeof Symbol === "function" &&
                  obj.constructor === Symbol &&
                  obj !== Symbol.prototype
                  ? "symbol"
                  : typeof obj;
          };

/*!
 * Doka 5.9.0
 * Copyright 2019 PQINA Inc - All Rights Reserved
 * Please visit https://pqina.nl/doka/ for further information
 */
/* eslint-disable */

function _typeof(e) {
    return (_typeof =
        "function" == typeof Symbol && "symbol" == _typeof2(Symbol.iterator)
            ? function (e) {
                  return typeof e === "undefined" ? "undefined" : _typeof2(e);
              }
            : function (e) {
                  return e &&
                      "function" == typeof Symbol &&
                      e.constructor === Symbol &&
                      e !== Symbol.prototype
                      ? "symbol"
                      : typeof e === "undefined"
                      ? "undefined"
                      : _typeof2(e);
              })(e);
}
function _defineProperty(e, t, r) {
    return (
        t in e
            ? Object.defineProperty(e, t, {
                  value: r,
                  enumerable: !0,
                  configurable: !0,
                  writable: !0,
              })
            : (e[t] = r),
        e
    );
}
function _objectSpread(e) {
    for (var t = 1; t < arguments.length; t++) {
        var r = null != arguments[t] ? arguments[t] : {},
            n = Object.keys(r);
        "function" == typeof Object.getOwnPropertySymbols &&
            (n = n.concat(
                Object.getOwnPropertySymbols(r).filter(function (e) {
                    return Object.getOwnPropertyDescriptor(r, e).enumerable;
                })
            )),
            n.forEach(function (t) {
                _defineProperty(e, t, r[t]);
            });
    }
    return e;
}
function _slicedToArray(e, t) {
    return (
        _arrayWithHoles(e) || _iterableToArrayLimit(e, t) || _nonIterableRest()
    );
}
function _toConsumableArray(e) {
    return _arrayWithoutHoles(e) || _iterableToArray(e) || _nonIterableSpread();
}
function _arrayWithoutHoles(e) {
    if (Array.isArray(e)) {
        for (var t = 0, r = new Array(e.length); t < e.length; t++) {
            r[t] = e[t];
        }
        return r;
    }
}
function _arrayWithHoles(e) {
    if (Array.isArray(e)) return e;
}
function _iterableToArray(e) {
    if (
        Symbol.iterator in Object(e) ||
        "[object Arguments]" === Object.prototype.toString.call(e)
    )
        return Array.from(e);
}
function _iterableToArrayLimit(e, t) {
    var r = [],
        n = !0,
        i = !1,
        o = void 0;
    try {
        for (
            var a, c = e[Symbol.iterator]();
            !(n = (a = c.next()).done) &&
            (r.push(a.value), !t || r.length !== t);
            n = !0
        ) {}
    } catch (e) {
        (i = !0), (o = e);
    } finally {
        try {
            n || null == c.return || c.return();
        } finally {
            if (i) throw o;
        }
    }
    return r;
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance");
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
}
var isNode = function isNode(e) {
        return e instanceof HTMLElement;
    },
    _insertBefore = function _insertBefore(e, t) {
        return t.parentNode.insertBefore(e, t);
    },
    _insertAfter = function _insertAfter(e, t) {
        return t.parentNode.insertBefore(e, t.nextSibling);
    },
    isObject = function isObject(e) {
        return "object" === _typeof(e) && null !== e;
    },
    createStore = function createStore(e) {
        var t =
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : [],
            r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : [],
            n = _objectSpread({}, e),
            i = [],
            o = [],
            a = function a(e, t, r) {
                r
                    ? o.push({ type: e, data: t })
                    : (s[e] && s[e](t), i.push({ type: e, data: t }));
            },
            c = function c(e) {
                for (
                    var t,
                        r = arguments.length,
                        n = new Array(r > 1 ? r - 1 : 0),
                        i = 1;
                    i < r;
                    i++
                ) {
                    n[i - 1] = arguments[i];
                }
                return u[e] ? (t = u)[e].apply(t, n) : null;
            },
            l = {
                getState: function getState() {
                    return _objectSpread({}, n);
                },
                processActionQueue: function processActionQueue() {
                    var e = [].concat(i);
                    return (i.length = 0), e;
                },
                processDispatchQueue: function processDispatchQueue() {
                    var e = [].concat(o);
                    (o.length = 0),
                        e.forEach(function (e) {
                            var t = e.type,
                                r = e.data;
                            a(t, r);
                        });
                },
                dispatch: a,
                query: c,
            },
            u = {};
        t.forEach(function (e) {
            u = _objectSpread({}, e(n), u);
        });
        var s = {};
        return (
            r.forEach(function (e) {
                s = _objectSpread({}, e(a, c, n), s);
            }),
            l
        );
    },
    defineProperty = function defineProperty(e, t, r) {
        "function" != typeof r ? Object.defineProperty(e, t, r) : (e[t] = r);
    },
    forin = function forin(e, t) {
        for (var r in e) {
            e.hasOwnProperty(r) && t(r, e[r]);
        }
    },
    createObject = function createObject(e) {
        var t = {};
        return (
            forin(e, function (r) {
                defineProperty(t, r, e[r]);
            }),
            t
        );
    },
    attr = function attr(e, t) {
        var r =
            arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : null;
        if (null === r) return e.getAttribute(t) || e.hasAttribute(t);
        e.setAttribute(t, r);
    },
    ns = "http://www.w3.org/2000/svg",
    svgElements = ["svg", "path"],
    isSVGElement = function isSVGElement(e) {
        return svgElements.includes(e);
    },
    createElement = function createElement(e, t) {
        var r =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
        "object" === _typeof(t) && ((r = t), (t = null));
        var n = isSVGElement(e)
            ? document.createElementNS(ns, e)
            : document.createElement(e);
        return (
            t && (isSVGElement(e) ? attr(n, "class", t) : (n.className = t)),
            forin(r, function (e, t) {
                attr(n, e, t);
            }),
            n
        );
    },
    appendChild = function appendChild(e) {
        return function (t, r) {
            void 0 !== r && e.children[r]
                ? e.insertBefore(t, e.children[r])
                : e.appendChild(t);
        };
    },
    appendChildView = function appendChildView(e, t) {
        return function (e, r) {
            return void 0 !== r ? t.splice(r, 0, e) : t.push(e), e;
        };
    },
    removeChildView = function removeChildView(e, t) {
        return function (r) {
            return (
                t.splice(t.indexOf(r), 1),
                r.element.parentNode && e.removeChild(r.element),
                r
            );
        };
    },
    getViewRect = function getViewRect(e, t, r, n) {
        var i = r[0] || e.left,
            o = r[1] || e.top,
            a = i + e.width,
            c = o + e.height * (n[1] || 1),
            l = {
                element: _objectSpread({}, e),
                inner: {
                    left: e.left,
                    top: e.top,
                    right: e.right,
                    bottom: e.bottom,
                },
                outer: { left: i, top: o, right: a, bottom: c },
            };
        return (
            t
                .filter(function (e) {
                    return !e.isRectIgnored();
                })
                .map(function (e) {
                    return e.rect;
                })
                .forEach(function (e) {
                    expandRect(l.inner, _objectSpread({}, e.inner)),
                        expandRect(l.outer, _objectSpread({}, e.outer));
                }),
            calculateRectSize(l.inner),
            (l.outer.bottom += l.element.marginBottom),
            (l.outer.right += l.element.marginRight),
            calculateRectSize(l.outer),
            l
        );
    },
    expandRect = function expandRect(e, t) {
        (t.top += e.top),
            (t.right += e.left),
            (t.bottom += e.top),
            (t.left += e.left),
            t.bottom > e.bottom && (e.bottom = t.bottom),
            t.right > e.right && (e.right = t.right);
    },
    calculateRectSize = function calculateRectSize(e) {
        (e.width = e.right - e.left), (e.height = e.bottom - e.top);
    },
    isNumber = function isNumber(e) {
        return "number" == typeof e;
    },
    thereYet = function thereYet(e, t, r) {
        var n =
            arguments.length > 3 && void 0 !== arguments[3]
                ? arguments[3]
                : 0.001;
        return Math.abs(e - t) < n && Math.abs(r) < n;
    },
    spring = function spring() {
        var e =
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {},
            t = e.stiffness,
            r = void 0 === t ? 0.5 : t,
            n = e.damping,
            i = void 0 === n ? 0.75 : n,
            o = e.mass,
            a = void 0 === o ? 10 : o,
            c = e.delay,
            l = void 0 === c ? 0 : c,
            u = null,
            s = null,
            d = 0,
            f = !1,
            p = null,
            h = createObject({
                interpolate: function interpolate(e) {
                    if ((null === p && (p = e), !(e - l < p || f))) {
                        if (!isNumber(u) || !isNumber(s))
                            return (f = !0), void (d = 0);
                        thereYet((s += d += (-(s - u) * r) / a), u, (d *= i))
                            ? ((s = u),
                              (d = 0),
                              (f = !0),
                              h.onupdate(s),
                              h.oncomplete(s))
                            : h.onupdate(s);
                    }
                },
                target: {
                    set: function set(e) {
                        if (
                            (isNumber(e) &&
                                !isNumber(s) &&
                                ((s = e), (p = null)),
                            null === u && ((u = e), (s = e), (p = null)),
                            f && (p = null),
                            s === (u = e) || void 0 === u)
                        )
                            return (
                                (f = !0),
                                (d = 0),
                                (p = null),
                                h.onupdate(s),
                                void h.oncomplete(s)
                            );
                        f = !1;
                    },
                    get: function get() {
                        return u;
                    },
                },
                resting: {
                    get: function get() {
                        return f;
                    },
                },
                onupdate: function onupdate() {},
                oncomplete: function oncomplete() {},
                position: {
                    get: function get() {
                        return s;
                    },
                },
            });
        return h;
    },
    easeInOutQuad = function easeInOutQuad(e) {
        return e < 0.5 ? 2 * e * e : (4 - 2 * e) * e - 1;
    },
    tween = function tween() {
        var e,
            t,
            r =
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {},
            n = r.duration,
            i = void 0 === n ? 500 : n,
            o = r.easing,
            a = void 0 === o ? easeInOutQuad : o,
            c = r.delay,
            l = void 0 === c ? 0 : c,
            u = null,
            s = !0,
            d = !1,
            f = null,
            p = createObject({
                interpolate: function interpolate(r) {
                    s ||
                        null === f ||
                        (null === u && (u = r),
                        r - u < l ||
                            ((e = r - u - l) < i
                                ? ((t = e / i),
                                  p.onupdate(
                                      (e >= 0 ? a(d ? 1 - t : t) : 0) * f
                                  ))
                                : ((e = 1),
                                  (t = d ? 0 : 1),
                                  p.onupdate(t * f),
                                  p.oncomplete(t * f),
                                  (s = !0))));
                },
                target: {
                    get: function get() {
                        return d ? 0 : f;
                    },
                    set: function set(e) {
                        if (null === f)
                            return (f = e), p.onupdate(e), void p.oncomplete(e);
                        e < f ? ((f = 1), (d = !0)) : ((d = !1), (f = e)),
                            (s = !1),
                            (u = null);
                    },
                },
                resting: {
                    get: function get() {
                        return s;
                    },
                },
                onupdate: function onupdate() {},
                oncomplete: function oncomplete() {},
            });
        return p;
    },
    animator = { spring: spring, tween: tween },
    createAnimator = function createAnimator(e, t, r) {
        var n = e[t] && "object" === _typeof(e[t][r]) ? e[t][r] : e[t] || e,
            i = "string" == typeof n ? n : n.type,
            o = "object" === _typeof(n) ? _objectSpread({}, n) : {};
        return animator[i] ? animator[i](o) : null;
    },
    addGetSet = function addGetSet(e, t, r) {
        var n = arguments.length > 3 && void 0 !== arguments[3] && arguments[3];
        (t = Array.isArray(t) ? t : [t]).forEach(function (t) {
            e.forEach(function (e) {
                var i = e,
                    o = function o() {
                        return r[e];
                    },
                    a = function a(t) {
                        return (r[e] = t);
                    };
                "object" === _typeof(e) &&
                    ((i = e.key), (o = e.getter || o), (a = e.setter || a)),
                    (t[i] && !n) || (t[i] = { get: o, set: a });
            });
        });
    },
    animations = function animations(e) {
        var t = e.mixinConfig,
            r = e.viewProps,
            n = e.viewInternalAPI,
            i = e.viewExternalAPI,
            o = _objectSpread({}, r),
            a = [];
        return (
            forin(t, function (e, t) {
                var c = createAnimator(t);
                c &&
                    ((c.onupdate = function (t) {
                        r[e] = t;
                    }),
                    (c.target = o[e]),
                    addGetSet(
                        [
                            {
                                key: e,
                                setter: function setter(e) {
                                    c.target !== e && (c.target = e);
                                },
                                getter: function getter() {
                                    return r[e];
                                },
                            },
                        ],
                        [n, i],
                        r,
                        !0
                    ),
                    a.push(c));
            }),
            {
                write: function write(e) {
                    var t = !0;
                    return (
                        a.forEach(function (r) {
                            r.resting || (t = !1), r.interpolate(e);
                        }),
                        t
                    );
                },
                destroy: function destroy() {},
            }
        );
    },
    addEvent = function addEvent(e) {
        return function (t, r) {
            e.addEventListener(t, r);
        };
    },
    removeEvent = function removeEvent(e) {
        return function (t, r) {
            e.removeEventListener(t, r);
        };
    },
    listeners = function listeners(e) {
        var t = e.viewExternalAPI,
            r = e.view,
            n = [],
            i = addEvent(r.element),
            o = removeEvent(r.element);
        return (
            (t.on = function (e, t) {
                n.push({ type: e, fn: t }), i(e, t);
            }),
            (t.off = function (e, t) {
                n.splice(
                    n.findIndex(function (r) {
                        return r.type === e && r.fn === t;
                    }),
                    1
                ),
                    o(e, t);
            }),
            {
                write: function write() {
                    return !0;
                },
                destroy: function destroy() {
                    n.forEach(function (e) {
                        o(e.type, e.fn);
                    });
                },
            }
        );
    },
    apis = function apis(e) {
        var t = e.mixinConfig,
            r = e.viewProps,
            n = e.viewExternalAPI;
        addGetSet(t, n, r);
    },
    defaults = {
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        translateX: 0,
        translateY: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        originX: 0,
        originY: 0,
    },
    styles = function styles(e) {
        var t = e.mixinConfig,
            r = e.viewProps,
            n = e.viewInternalAPI,
            i = e.viewExternalAPI,
            o = e.view,
            a = _objectSpread({}, r),
            c = {};
        addGetSet(t, [n, i], r);
        var l = function l() {
            return o.rect
                ? getViewRect(
                      o.rect,
                      o.childViews,
                      [r.translateX || 0, r.translateY || 0],
                      [r.scaleX || 0, r.scaleY || 0]
                  )
                : null;
        };
        return (
            (n.rect = { get: l }),
            (i.rect = { get: l }),
            t.forEach(function (e) {
                r[e] = void 0 === a[e] ? defaults[e] : a[e];
            }),
            {
                write: function write() {
                    if (propsHaveChanged(c, r))
                        return (
                            applyStyles(o.element, r),
                            Object.assign(c, _objectSpread({}, r)),
                            !0
                        );
                },
                destroy: function destroy() {},
            }
        );
    },
    propsHaveChanged = function propsHaveChanged(e, t) {
        if (Object.keys(e).length !== Object.keys(t).length) return !0;
        for (var r in t) {
            if (t[r] !== e[r]) return !0;
        }
        return !1;
    },
    applyStyles = function applyStyles(e, t) {
        var r = t.opacity,
            n = t.perspective,
            i = t.translateX,
            o = t.translateY,
            a = t.scaleX,
            c = t.scaleY,
            l = t.rotateX,
            u = t.rotateY,
            s = t.rotateZ,
            d = t.originX,
            f = t.originY,
            p = t.width,
            h = t.height,
            g = "",
            m = "";
        (null == d && null == f) ||
            (m += "transform-origin: "
                .concat(d || 0, "px ")
                .concat(f || 0, "px;")),
            null != n && (g += "perspective(".concat(n, "px) ")),
            (null == i && null == o) ||
                (g += "translate3d("
                    .concat(i || 0, "px, ")
                    .concat(o || 0, "px, 0) ")),
            (null == a && null == c) ||
                (g += "scale3d("
                    .concat(null != a ? a : 1, ", ")
                    .concat(null != c ? c : 1, ", 1) ")),
            null != s && (g += "rotateZ(".concat(s, "rad) ")),
            null != l && (g += "rotateX(".concat(l, "rad) ")),
            null != u && (g += "rotateY(".concat(u, "rad) ")),
            "" != g && (m += "transform:".concat(g, ";")),
            null != r &&
                ((m += "opacity:".concat(r, ";")),
                r < 1 && (m += "pointer-events:none;"),
                0 === r &&
                    "BUTTON" === e.nodeName &&
                    (m += "visibility:hidden;")),
            null != p && (m += "width:".concat(p, "px;")),
            null != h && (m += "height:".concat(h, "px;"));
        var v = e.elementCurrentStyle || "";
        (m.length === v.length && m === v) ||
            ((e.style.cssText = m), (e.elementCurrentStyle = m));
    },
    Mixins = {
        styles: styles,
        listeners: listeners,
        animations: animations,
        apis: apis,
    },
    updateRect = function updateRect() {
        var e =
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {},
            t =
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : {},
            r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : {};
        return (
            t.layoutCalculated ||
                ((e.paddingTop = parseInt(r.paddingTop, 10) || 0),
                (e.marginTop = parseInt(r.marginTop, 10) || 0),
                (e.marginRight = parseInt(r.marginRight, 10) || 0),
                (e.marginBottom = parseInt(r.marginBottom, 10) || 0),
                (e.marginLeft = parseInt(r.marginLeft, 10) || 0),
                (t.layoutCalculated = !0)),
            (e.left = t.offsetLeft || 0),
            (e.top = t.offsetTop || 0),
            (e.width = t.offsetWidth || 0),
            (e.height = t.offsetHeight || 0),
            (e.right = e.left + e.width),
            (e.bottom = e.top + e.height),
            (e.scrollTop = t.scrollTop),
            (e.hidden = null === t.offsetParent && "fixed" !== r.position),
            e
        );
    },
    createView = function createView() {
        var e =
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {},
            t = e.tag,
            r = void 0 === t ? "div" : t,
            n = e.name,
            i = void 0 === n ? null : n,
            o = e.attributes,
            a = void 0 === o ? {} : o,
            c = e.read,
            l = void 0 === c ? function () {} : c,
            u = e.write,
            s = void 0 === u ? function () {} : u,
            d = e.create,
            f = void 0 === d ? function () {} : d,
            p = e.destroy,
            h = void 0 === p ? function () {} : p,
            g = e.filterFrameActionsForChild,
            m =
                void 0 === g
                    ? function (e, t) {
                          return t;
                      }
                    : g,
            v = e.didCreateView,
            y = void 0 === v ? function () {} : v,
            E = e.didWriteView,
            T = void 0 === E ? function () {} : E,
            _ = e.shouldUpdateChildViews,
            R =
                void 0 === _
                    ? function () {
                          return !0;
                      }
                    : _,
            w = e.ignoreRect,
            I = void 0 !== w && w,
            A = e.ignoreRectUpdate,
            C = void 0 !== A && A,
            S = e.mixins,
            x = void 0 === S ? [] : S;
        return function (e) {
            var t =
                    arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : {},
                n = createElement(r, i ? "doka--".concat(i) : null, a),
                o = window.getComputedStyle(n, null),
                c = updateRect(),
                u = null,
                d = !1,
                p = [],
                g = [],
                v = {},
                E = {},
                _ = [s],
                w = [l],
                A = [h],
                S = function S() {
                    return n;
                },
                O = function O() {
                    return [].concat(p);
                },
                b = function b() {
                    return u || (u = getViewRect(c, p, [0, 0], [1, 1]));
                },
                M = function M() {
                    return (n.layoutCalculated = !1);
                },
                L = {
                    element: { get: S },
                    style: {
                        get: function get() {
                            return o;
                        },
                    },
                    childViews: { get: O },
                },
                P = _objectSpread({}, L, {
                    rect: { get: b },
                    ref: {
                        get: function get() {
                            return v;
                        },
                    },
                    is: function is(e) {
                        return i === e;
                    },
                    appendChild: appendChild(n),
                    createChildView: (function (e) {
                        return function (t, r) {
                            return t(e, r);
                        };
                    })(e),
                    linkView: function linkView(e) {
                        return p.push(e), e;
                    },
                    unlinkView: function unlinkView(e) {
                        p.splice(p.indexOf(e), 1);
                    },
                    appendChildView: appendChildView(n, p),
                    removeChildView: removeChildView(n, p),
                    registerWriter: function registerWriter(e) {
                        return _.push(e);
                    },
                    registerReader: function registerReader(e) {
                        return w.push(e);
                    },
                    registerDestroyer: function registerDestroyer(e) {
                        return A.push(e);
                    },
                    invalidateLayout: M,
                    dispatch: e.dispatch,
                    query: e.query,
                }),
                G = {
                    element: { get: S },
                    childViews: { get: O },
                    rect: { get: b },
                    resting: {
                        get: function get() {
                            return d;
                        },
                    },
                    isRectIgnored: function isRectIgnored() {
                        return I;
                    },
                    invalidateLayout: M,
                    _read: function _read() {
                        (u = null),
                            R({ root: D, props: t }) &&
                                p.forEach(function (e) {
                                    return e._read();
                                }),
                            !(C && c.width && c.height) && updateRect(c, n, o);
                        var e = { root: D, props: t, rect: c };
                        w.forEach(function (t) {
                            return t(e);
                        });
                    },
                    _write: function _write(e) {
                        var r =
                                arguments.length > 1 && void 0 !== arguments[1]
                                    ? arguments[1]
                                    : [],
                            n = 0 === r.length;
                        return (
                            _.forEach(function (i) {
                                !1 ===
                                    i({
                                        props: t,
                                        root: D,
                                        actions: r,
                                        timestamp: e,
                                    }) && (n = !1);
                            }),
                            g.forEach(function (t) {
                                !1 === t.write(e) && (n = !1);
                            }),
                            R({
                                props: t,
                                root: D,
                                actions: r,
                                timestamp: e,
                            }) &&
                                (p
                                    .filter(function (e) {
                                        return !!e.element.parentNode;
                                    })
                                    .forEach(function (t) {
                                        t._write(e, m(t, r)) || (n = !1);
                                    }),
                                p.forEach(function (t, i) {
                                    t.element.parentNode ||
                                        (D.appendChild(t.element, i),
                                        t._read(),
                                        t._write(e, m(t, r)),
                                        (n = !1));
                                })),
                            (d = n),
                            T({ props: t, root: D, actions: r, timestamp: e }),
                            n
                        );
                    },
                    _destroy: function _destroy() {
                        g.forEach(function (e) {
                            return e.destroy();
                        }),
                            A.forEach(function (e) {
                                e({ root: D });
                            }),
                            p.forEach(function (e) {
                                return e._destroy();
                            });
                    },
                },
                k = _objectSpread({}, L, {
                    rect: {
                        get: function get() {
                            return c;
                        },
                    },
                });
            Object.keys(x)
                .sort(function (e, t) {
                    return "styles" === e ? 1 : "styles" === t ? -1 : 0;
                })
                .forEach(function (e) {
                    var r = Mixins[e]({
                        mixinConfig: x[e],
                        viewProps: t,
                        viewState: E,
                        viewInternalAPI: P,
                        viewExternalAPI: G,
                        view: createObject(k),
                    });
                    r && g.push(r);
                });
            var D = createObject(P);
            f({ root: D, props: t });
            var V = (n.children || []).length;
            return (
                p.forEach(function (e, t) {
                    D.appendChild(e.element, V + t);
                }),
                y(D),
                createObject(G, t)
            );
        };
    },
    createPainter = function createPainter(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 60,
            n = "__framePainter";
        if (window[n])
            return window[n].readers.push(e), void window[n].writers.push(t);
        window[n] = { readers: [e], writers: [t] };
        var i = window[n],
            o = 1e3 / r,
            a = null,
            c = null,
            l = null,
            u = null,
            s = function s() {
                document.hidden
                    ? ((l = function l() {
                          return window.setTimeout(function () {
                              return d(performance.now());
                          }, o);
                      }),
                      (u = function u() {
                          return window.clearTimeout(c);
                      }))
                    : ((l = function l() {
                          return window.requestAnimationFrame(d);
                      }),
                      (u = function u() {
                          return window.cancelAnimationFrame(c);
                      }));
            };
        document.addEventListener("visibilitychange", function () {
            u && u(), s(), d(performance.now());
        });
        var d = function e(t) {
            (c = l(e)), a || (a = t);
            var r = t - a;
            r <= o ||
                ((a = t - (r % o)),
                i.readers.forEach(function (e) {
                    return e();
                }),
                i.writers.forEach(function (e) {
                    return e(t);
                }));
        };
        return (
            s(),
            d(performance.now()),
            {
                pause: function pause() {
                    u(c);
                },
            }
        );
    },
    createRoute = function createRoute(e, t) {
        return function (r) {
            var n = r.root,
                i = r.props,
                o = r.actions,
                a = void 0 === o ? [] : o,
                c = r.timestamp;
            if (
                (a
                    .filter(function (t) {
                        return e[t.type];
                    })
                    .forEach(function (t) {
                        return e[t.type]({
                            root: n,
                            props: i,
                            action: t.data,
                            timestamp: c,
                        });
                    }),
                t)
            )
                return t({ root: n, props: i, actions: a, timestamp: c });
        };
    },
    isArray = function isArray(e) {
        return Array.isArray(e);
    },
    isEmpty = function isEmpty(e) {
        return null == e;
    },
    trim = function trim(e) {
        return e.trim();
    },
    toString = function toString(e) {
        return "" + e;
    },
    toArray = function toArray(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : ",";
        return isEmpty(e)
            ? []
            : isArray(e)
            ? e
            : toString(e)
                  .split(t)
                  .map(trim)
                  .filter(function (e) {
                      return e.length;
                  });
    },
    isBoolean = function isBoolean(e) {
        return "boolean" == typeof e;
    },
    toBoolean = function toBoolean(e) {
        return isBoolean(e) ? e : "true" === e;
    },
    isString = function isString(e) {
        return "string" == typeof e;
    },
    toNumber = function toNumber(e) {
        return isNumber(e)
            ? e
            : isString(e)
            ? toString(e).replace(/[a-z]+/gi, "")
            : 0;
    },
    toInt = function toInt(e) {
        return parseInt(toNumber(e), 10);
    },
    toFloat = function toFloat(e) {
        return parseFloat(toNumber(e));
    },
    isInt = function isInt(e) {
        return isNumber(e) && isFinite(e) && Math.floor(e) === e;
    },
    toBytes = function toBytes(e) {
        if (isInt(e)) return e;
        var t = toString(e).trim();
        return /MB$/i.test(t)
            ? ((t = t.replace(/MB$i/, "").trim()), 1e3 * toInt(t) * 1e3)
            : /KB/i.test(t)
            ? ((t = t.replace(/KB$i/, "").trim()), 1e3 * toInt(t))
            : toInt(t);
    },
    isFunction = function isFunction(e) {
        return "function" == typeof e;
    },
    toFunctionReference = function toFunctionReference(e) {
        for (var t = self, r = e.split("."), n = null; (n = r.shift()); ) {
            if (!(t = t[n])) return null;
        }
        return t;
    },
    isNull = function isNull(e) {
        return null === e;
    },
    getType = function getType(e) {
        return isArray(e)
            ? "array"
            : isNull(e)
            ? "null"
            : isInt(e)
            ? "int"
            : /^[0-9]+ ?(?:GB|MB|KB)$/gi.test(e)
            ? "bytes"
            : _typeof(e);
    },
    replaceSingleQuotes = function replaceSingleQuotes(e) {
        return e
            .replace(/{\s*'/g, '{"')
            .replace(/'\s*}/g, '"}')
            .replace(/'\s*:/g, '":')
            .replace(/:\s*'/g, ':"')
            .replace(/,\s*'/g, ',"')
            .replace(/'\s*,/g, '",');
    },
    conversionTable = {
        array: toArray,
        boolean: toBoolean,
        int: function int(e) {
            return "bytes" === getType(e) ? toBytes(e) : toInt(e);
        },
        float: toFloat,
        bytes: toBytes,
        string: function string(e) {
            return isFunction(e) ? e : toString(e);
        },
        object: function object(e) {
            try {
                return JSON.parse(replaceSingleQuotes(e));
            } catch (t) {
                return e;
            }
        },
        file: function file(e) {
            return e;
        },
        function: function _function(e) {
            return toFunctionReference(e);
        },
    },
    convertTo = function convertTo(e, t) {
        return conversionTable[t](e);
    },
    getValueByType = function getValueByType(e, t, r) {
        if (e === t) return e;
        var n = getType(e);
        if (n !== r) {
            var i = convertTo(e, r);
            if (((n = getType(i)), null === i))
                throw 'Trying to assign value with incorrect type, allowed type: "'.concat(
                    r,
                    '"'
                );
            e = i;
        }
        return e;
    },
    createOption = function createOption(e, t) {
        var r = e;
        return {
            enumerable: !0,
            get: function get() {
                return r;
            },
            set: function set(n) {
                r = getValueByType(n, e, t);
            },
        };
    },
    createOptions = function createOptions(e) {
        var t = {};
        return (
            forin(e, function (r) {
                var n = isString(e[r]) ? e[r] : r,
                    i = e[n];
                t[r] = n === r ? createOption(i[0], i[1]) : t[n];
            }),
            createObject(t)
        );
    },
    resetState = function resetState(e) {
        (e.file = null),
            (e.activeView = null),
            (e.markup = []),
            (e.rootRect = { x: 0, y: 0, left: 0, top: 0, width: 0, height: 0 }),
            (e.stage = null),
            (e.stageOffset = null),
            (e.image = null),
            (e.zoomTimeoutId = null),
            (e.instantUpdate = !1),
            (e.filePromise = null),
            (e.fileLoader = null),
            (e.instructions = {
                size: null,
                crop: null,
                filter: null,
                color: null,
            }),
            (e.filter = null),
            (e.filterName = null),
            (e.filterValue = null),
            (e.colorValues = {}),
            (e.colorMatrices = {}),
            (e.size = {
                width: !1,
                height: !1,
                aspectRatioLocked: !0,
                aspectRatioPrevious: !1,
            }),
            (e.crop = {
                rectangle: null,
                transforms: null,
                rotation: null,
                flip: null,
                aspectRatio: null,
                isRotating: !1,
                isDirty: !1,
                limitToImageBounds: !0,
                draft: { rectangle: null, transforms: null },
            });
    },
    createInitialState = function createInitialState(e) {
        var t = { noImageTimeout: null, options: createOptions(e) };
        return resetState(t), t;
    },
    fromCamels = function fromCamels(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : "-";
        return e
            .split(/(?=[A-Z])/)
            .map(function (e) {
                return e.toLowerCase();
            })
            .join(t);
    },
    createOptionAPI = function createOptionAPI(e, t) {
        var r = {};
        return (
            forin(t, function (n) {
                var i = isString(t[n]) ? t[n] : n;
                r[n] = {
                    get: function get() {
                        return e.getState().options[i];
                    },
                    set: function set(t) {
                        e.dispatch(
                            "SET_".concat(fromCamels(i, "_").toUpperCase()),
                            { value: t }
                        );
                    },
                };
            }),
            r
        );
    },
    createOptionActions = function createOptionActions(e) {
        return function (t, r, n) {
            var i = {};
            return (
                forin(e, function (e) {
                    var r = fromCamels(e, "_").toUpperCase();
                    i["SET_".concat(r)] = function (i) {
                        var o;
                        try {
                            (o = n.options[e]), (n.options[e] = i.value);
                        } catch (e) {}
                        t("DID_SET_".concat(r), {
                            value: n.options[e],
                            prevValue: o,
                        });
                    };
                }),
                i
            );
        };
    },
    createOptionQueries = function createOptionQueries(e) {
        return function (t) {
            var r = {};
            return (
                forin(e, function (e) {
                    r["GET_".concat(fromCamels(e, "_").toUpperCase())] =
                        function () {
                            return t.options[e];
                        };
                }),
                r
            );
        };
    },
    getUniqueId = function getUniqueId() {
        return Math.random().toString(36).substr(2, 9);
    },
    arrayRemove = function arrayRemove(e, t) {
        return e.splice(t, 1);
    },
    on = function on() {
        var e = [],
            t = function t(_t, r) {
                arrayRemove(
                    e,
                    e.findIndex(function (e) {
                        return e.event === _t && (e.cb === r || !r);
                    })
                );
            };
        return {
            fire: function fire(t) {
                for (
                    var r = arguments.length,
                        n = new Array(r > 1 ? r - 1 : 0),
                        i = 1;
                    i < r;
                    i++
                ) {
                    n[i - 1] = arguments[i];
                }
                e.filter(function (e) {
                    return e.event === t;
                })
                    .map(function (e) {
                        return e.cb;
                    })
                    .forEach(function (e) {
                        setTimeout(function () {
                            e.apply(void 0, n);
                        }, 0);
                    });
            },
            on: function on(t, r) {
                e.push({ event: t, cb: r });
            },
            onOnce: function onOnce(r, n) {
                e.push({
                    event: r,
                    cb: function cb() {
                        t(r, n), n.apply(void 0, arguments);
                    },
                });
            },
            off: t,
        };
    },
    Type = {
        BOOLEAN: "boolean",
        INT: "int",
        NUMBER: "number",
        STRING: "string",
        ARRAY: "array",
        OBJECT: "object",
        FUNCTION: "function",
        ACTION: "action",
        SERVER_API: "serverapi",
        REGEX: "regex",
        FILE: "file",
    },
    IS_BROWSER = "undefined" != typeof window && void 0 !== window.document,
    isBrowser = function isBrowser() {
        return IS_BROWSER;
    },
    testResult = null,
    isIOS = function isIOS() {
        return (
            null === testResult &&
                (testResult =
                    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                    !window.MSStream),
            testResult
        );
    },
    getOptions = function getOptions() {
        return _objectSpread({}, defaultOptions);
    },
    setOptions = function setOptions(e) {
        forin(e, function (e, t) {
            defaultOptions[e] && setOption(e, t);
        });
    },
    correctDeprecatedOption = function correctDeprecatedOption(e) {
        return isString(defaultOptions[e]) ? defaultOptions[e] : e;
    },
    setOption = function setOption(e, t) {
        (e = correctDeprecatedOption(e)),
            (defaultOptions[e][0] = getValueByType(
                t,
                defaultOptions[e][0],
                defaultOptions[e][1]
            ));
    },
    defaultOptions = {
        id: [null, Type.STRING],
        className: [null, Type.STRING],
        src: [null, Type.FILE],
        maxImagePreviewWidth: [1500, Type.INT],
        maxImagePreviewHeight: [1500, Type.INT],
        allowPreviewFitToView: [!0, Type.BOOLEAN],
        allowButtonCancel: [!0, Type.BOOLEAN],
        allowButtonConfirm: [!0, Type.BOOLEAN],
        allowDropFiles: [!1, Type.BOOLEAN],
        allowBrowseFiles: [!0, Type.BOOLEAN],
        allowAutoClose: [!0, Type.BOOLEAN],
        allowAutoDestroy: [!1, Type.BOOLEAN],
        utils: [["crop", "filter", "color", "markup"], Type.ARRAY],
        initialState: [null, Type.OBJECT],
        outputData: [!1, Type.BOOLEAN],
        outputFile: [!0, Type.BOOLEAN],
        outputCorrectImageExifOrientation: [!0, Type.BOOLEAN],
        outputStripImageHead: [!0, Type.BOOLEAN],
        outputType: [null, Type.STRING],
        outputQuality: [null, Type.INT],
        outputFit: ["cover", Type.STRING],
        outputUpscale: [!0, Type.BOOLEAN],
        outputWidth: [null, Type.INT],
        outputHeight: [null, Type.INT],
        outputCanvasBackgroundColor: [null, Type.STRING],
        outputCanvasMemoryLimit: [
            isBrowser() && isIOS() ? 16777216 : null,
            Type.INT,
        ],
        size: [null, Type.OBJECT],
        sizeMin: [{ width: 1, height: 1 }, Type.OBJECT],
        sizeMax: [{ width: 9999, height: 9999 }, Type.OBJECT],
        filter: [null, Type.OBJECT],
        filters: [
            {
                original: {
                    label: "Original",
                    matrix: function matrix() {
                        return null;
                    },
                },
                chrome: {
                    label: "Chrome",
                    matrix: function matrix() {
                        return [
                            1.398, -0.316, 0.065, -0.273, 0.201, -0.051, 1.278,
                            -0.08, -0.273, 0.201, -0.051, 0.119, 1.151, -0.29,
                            0.215, 0, 0, 0, 1, 0,
                        ];
                    },
                },
                fade: {
                    label: "Fade",
                    matrix: function matrix() {
                        return [
                            1.073, -0.015, 0.092, -0.115, -0.017, 0.107, 0.859,
                            0.184, -0.115, -0.017, 0.015, 0.077, 1.104, -0.115,
                            -0.017, 0, 0, 0, 1, 0,
                        ];
                    },
                },
                mono: {
                    label: "Mono",
                    matrix: function matrix() {
                        return [
                            0.212, 0.715, 0.114, 0, 0, 0.212, 0.715, 0.114, 0,
                            0, 0.212, 0.715, 0.114, 0, 0, 0, 0, 0, 1, 0,
                        ];
                    },
                },
                noir: {
                    label: "Noir",
                    matrix: function matrix() {
                        return [
                            0.15, 1.3, -0.25, 0.1, -0.2, 0.15, 1.3, -0.25, 0.1,
                            -0.2, 0.15, 1.3, -0.25, 0.1, -0.2, 0, 0, 0, 1, 0,
                        ];
                    },
                },
            },
            Type.OBJECT,
        ],
        crop: [null, Type.OBJECT],
        cropShowSize: [!1, Type.BOOLEAN],
        cropZoomTimeout: [null, Type.INT],
        cropMask: [null, Type.FUNCTION],
        cropMaskInset: [0, Type.INT],
        cropAllowImageTurnLeft: [!0, Type.BOOLEAN],
        cropAllowImageTurnRight: [!1, Type.BOOLEAN],
        cropAllowImageFlipHorizontal: [!0, Type.BOOLEAN],
        cropAllowImageFlipVertical: [!0, Type.BOOLEAN],
        cropAllowToggleLimit: [!1, Type.BOOLEAN],
        cropLimitToImageBounds: [!0, Type.BOOLEAN],
        cropResizeMatchImageAspectRatio: [!1, Type.BOOLEAN],
        cropResizeKeyCodes: [[18, 91, 92, 93], Type.ARRAY],
        cropResizeScrollRectOnly: [!1, Type.BOOLEAN],
        cropAspectRatio: [null, Type.STRING],
        cropAspectRatioOptions: [null, Type.ARRAY],
        cropMinImageWidth: [1, Type.INT],
        cropMinImageHeight: [1, Type.INT],
        colorBrightness: [0, Type.NUMBER],
        colorBrightnessRange: [[-0.25, 0.25], Type.ARRAY],
        colorContrast: [1, Type.NUMBER],
        colorContrastRange: [[0.5, 1.5], Type.ARRAY],
        colorExposure: [1, Type.NUMBER],
        colorExposureRange: [[0.5, 1.5], Type.ARRAY],
        colorSaturation: [1, Type.NUMBER],
        colorSaturationRange: [[0, 2], Type.ARRAY],
        markup: [null, Type.ARRAY],
        markupFilter: [
            function () {
                return !0;
            },
            Type.FUNCTION,
        ],
        markupAllowAddMarkup: [!0, Type.BOOLEAN],
        markupFontSizeDefault: [2, Type.NUMBER],
        markupFontSizeOptions: [
            [
                ["XL", 0.15],
                ["L", 0.125],
                ["M", 0.1],
                ["S", 0.075],
                ["XS", 0.05],
            ],
            Type.ARRAY,
        ],
        markupFontFamilyDefault: [1, Type.INT],
        markupFontFamilyOptions: [
            [
                ["Serif", "Palatino, 'Times New Roman', serif"],
                ["Sans Serif", "Helvetica, Arial, Verdana"],
                ["Monospaced", "Monaco, 'Lucida Console', monospaced"],
            ],
            Type.ARRAY,
        ],
        markupShapeStyleDefault: [2, Type.INT],
        markupShapeStyleOptions: [
            [
                ["Fill", 0, null, 0],
                ["Outline thick", 0.025, null, 4],
                ["Outline default", 0.015, null, 2],
                ["Outline thin", 0.005, null, 1],
                ["Outline dashed", 0.005, [0.01], 1],
            ],
            Type.ARRAY,
        ],
        markupLineStyleDefault: [1, Type.INT],
        markupLineStyleOptions: [
            [
                ["Thick", 0.025, null, 4],
                ["Default", 0.015, null, 2],
                ["Thin", 0.005, null, 1],
                ["Dashed", 0.005, [0.01], 1],
            ],
            Type.ARRAY,
        ],
        markupLineDecorationDefault: [1, Type.INT],
        markupLineDecorationOptions: [
            [
                ["None", []],
                ["Single arrow", ["arrow-end"]],
                ["Double arrow", ["arrow-begin", "arrow-end"]],
            ],
            Type.ARRAY,
        ],
        markupColorDefault: [0, Type.INT],
        markupColorOptions: [
            [
                ["White", "#fff", "#f6f6f6"],
                ["Silver", "#9e9e9e"],
                ["Black", "#000", "#333"],
                ["Red", "#f44336"],
                ["Orange", "#ff9800"],
                ["Yellow", "#ffeb3b"],
                ["Green", "#4caf50"],
                ["Blue", "#2196f3"],
                ["Violet", "#3f51b5"],
                ["Purple", "#9c27b0"],
            ],
            Type.ARRAY,
        ],
        beforeCreateBlob: [null, Type.FUNCTION],
        afterCreateBlob: [null, Type.FUNCTION],
        onconfirm: [null, Type.FUNCTION],
        oncancel: [null, Type.FUNCTION],
        onclose: [null, Type.FUNCTION],
        onloadstart: [null, Type.FUNCTION],
        onload: [null, Type.FUNCTION],
        onloaderror: [null, Type.FUNCTION],
        onupdate: [null, Type.FUNCTION],
        oninit: [null, Type.FUNCTION],
        ondestroy: [null, Type.FUNCTION],
        labelButtonReset: ["Reset", Type.STRING],
        labelButtonCancel: ["Cancel", Type.STRING],
        labelButtonConfirm: ["Done", Type.STRING],
        labelButtonUtilCrop: ["Crop", Type.STRING],
        labelButtonUtilResize: ["Resize", Type.STRING],
        labelButtonUtilFilter: ["Filter", Type.STRING],
        labelButtonUtilColor: ["Colors", Type.STRING],
        labelButtonUtilMarkup: ["Markup", Type.STRING],
        labelStatusMissingWebGL: [
            "WebGL is required but is disabled on your browser",
            Type.STRING,
        ],
        labelStatusAwaitingImage: ["Waiting for image…", Type.STRING],
        labelStatusLoadImageError: ["Error loading image…", Type.STRING],
        labelStatusLoadingImage: ["Loading image…", Type.STRING],
        labelStatusProcessingImage: ["Processing image…", Type.STRING],
        labelColorBrightness: ["Brightness", Type.STRING],
        labelColorContrast: ["Contrast", Type.STRING],
        labelColorExposure: ["Exposure", Type.STRING],
        labelColorSaturation: ["Saturation", Type.STRING],
        labelMarkupTypeRectangle: ["Square", Type.STRING],
        labelMarkupTypeEllipse: ["Circle", Type.STRING],
        labelMarkupTypeText: ["Text", Type.STRING],
        labelMarkupTypeLine: ["Arrow", Type.STRING],
        labelMarkupSelectFontSize: ["Size", Type.STRING],
        labelMarkupSelectFontFamily: ["Font", Type.STRING],
        labelMarkupSelectLineDecoration: ["Decoration", Type.STRING],
        labelMarkupSelectLineStyle: ["Style", Type.STRING],
        labelMarkupSelectShapeStyle: ["Style", Type.STRING],
        labelMarkupRemoveShape: ["Remove", Type.STRING],
        labelButtonCropZoom: ["Zoom", Type.STRING],
        labelButtonCropRotateLeft: ["Rotate left", Type.STRING],
        labelButtonCropRotateRight: ["Rotate right", Type.STRING],
        labelButtonCropRotateCenter: ["Center rotation", Type.STRING],
        labelButtonCropFlipHorizontal: ["Flip horizontal", Type.STRING],
        labelButtonCropFlipVertical: ["Flip vertical", Type.STRING],
        labelButtonCropAspectRatio: ["Aspect ratio", Type.STRING],
        labelButtonCropToggleLimit: ["Crop selection", Type.STRING],
        labelButtonCropToggleLimitEnable: ["Limited to image", Type.STRING],
        labelButtonCropToggleLimitDisable: [
            "Select outside image",
            Type.STRING,
        ],
        pointerEventsPolyfillScope: ["root", Type.STRING],
        styleCropCorner: ["circle", Type.STRING],
        styleFullscreenSafeArea: [
            isBrowser() &&
            /iPad|iPhone|iPod/.test(navigator.userAgent) &&
            !window.MSStream
                ? "bottom"
                : "none",
            Type.STRING,
        ],
        styleLayoutMode: [null, Type.STRING],
    },
    limit = function limit(e) {
        var t =
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : 0,
            r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 1;
        return Math.min(r, Math.max(t, e));
    },
    roundFloat = function roundFloat(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 10;
        return parseFloat(e.toFixed(t));
    },
    vectorEqual = function vectorEqual(e, t) {
        return (
            roundFloat(e.x) === roundFloat(t.x) &&
            roundFloat(e.y) === roundFloat(t.y)
        );
    },
    roundVector = function roundVector(e, t) {
        return { x: roundFloat(e.x, t), y: roundFloat(e.y, t) };
    },
    vectorSubtract = function vectorSubtract(e, t) {
        return createVector(e.x - t.x, e.y - t.y);
    },
    vectorDot = function vectorDot(e, t) {
        return e.x * t.x + e.y * t.y;
    },
    vectorDistanceSquared = function vectorDistanceSquared(e, t) {
        return vectorDot(vectorSubtract(e, t), vectorSubtract(e, t));
    },
    vectorDistance = function vectorDistance(e, t) {
        return Math.sqrt(vectorDistanceSquared(e, t));
    },
    vectorLimit = function vectorLimit(e, t) {
        return createVector(
            limit(e.x, t.x, t.x + t.width),
            limit(e.y, t.y, t.y + t.height)
        );
    },
    vectorRotate = function vectorRotate(e, t, r) {
        var n = Math.cos(t),
            i = Math.sin(t),
            o = createVector(e.x - r.x, e.y - r.y);
        return createVector(r.x + n * o.x - i * o.y, r.y + i * o.x + n * o.y);
    },
    createVector = function createVector() {
        return {
            x:
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : 0,
            y:
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : 0,
        };
    },
    rectEqualsRect = function rectEqualsRect(e, t) {
        return (
            e.x === t.x &&
            e.y === t.y &&
            e.width === t.width &&
            e.height === t.height
        );
    },
    rectFitsInRect = function rectFitsInRect(e, t) {
        var r = rectBounds(e),
            n = rectBounds(t);
        return (
            r.left >= n.left &&
            r.top >= n.top &&
            r.bottom <= n.bottom &&
            r.right <= n.right
        );
    },
    rotateRectCorners = function rotateRectCorners(e, t, r) {
        return 0 === t
            ? { tl: e.tl, tr: e.tr, br: e.br, bl: e.bl }
            : {
                  tl: vectorRotate(e.tl, t, r),
                  tr: vectorRotate(e.tr, t, r),
                  br: vectorRotate(e.br, t, r),
                  bl: vectorRotate(e.bl, t, r),
              };
    },
    rectRotate = function rectRotate(e, t, r) {
        var n = rotateRectCorners(rectCorners(e), t, r),
            i = n.tl,
            o = n.tr,
            a = n.br,
            c = n.bl,
            l = Math.min(i.x, o.x, a.x, c.x),
            u = Math.min(i.y, o.y, a.y, c.y),
            s = Math.max(i.x, o.x, a.x, c.x),
            d = Math.max(i.y, o.y, a.y, c.y);
        return createRect(l, u, s - l, d - u);
    },
    rectScale = function rectScale(e, t, r) {
        return createRect(
            t * (e.x - r.x) + r.x,
            t * (e.y - r.y) + r.y,
            t * e.width,
            t * e.height
        );
    },
    rectTranslate = function rectTranslate(e, t) {
        return createRect(e.x + t.x, e.y + t.y, e.width, e.height);
    },
    TRANSFORM_MAP = {
        translate: rectTranslate,
        rotate: rectRotate,
        scale: rectScale,
    },
    rectTransform = function rectTransform(e, t, r) {
        return t.reduce(function (e, t) {
            return (0, TRANSFORM_MAP[t[0]])(e, t[1], r);
        }, e);
    },
    rectClone = function rectClone(e) {
        return createRect(e.x, e.y, e.width, e.height);
    },
    rectBounds = function rectBounds(e) {
        return {
            top: e.y,
            right: e.x + e.width,
            bottom: e.y + e.height,
            left: e.x,
        };
    },
    rectFromBounds = function rectFromBounds(e) {
        var t = e.top,
            r = e.right,
            n = e.bottom,
            i = e.left;
        return { x: i, y: t, width: r - i, height: n - t };
    },
    rectCenter = function rectCenter(e) {
        return createVector(e.x + 0.5 * e.width, e.y + 0.5 * e.height);
    },
    rectCorners = function rectCorners(e) {
        return {
            tl: { x: e.x, y: e.y },
            tr: { x: e.x + e.width, y: e.y },
            br: { x: e.x + e.width, y: e.y + e.height },
            bl: { x: e.x, y: e.y + e.height },
        };
    },
    createRect = function createRect(e, t, r, n) {
        return { x: e, y: t, width: r, height: n };
    },
    getNumericAspectRatioFromString = function getNumericAspectRatioFromString(
        e
    ) {
        if (isEmpty(e)) return e;
        if (/:/.test(e)) {
            var t = e.split(":"),
                r = t[0];
            return t[1] / r;
        }
        return parseFloat(e);
    },
    getCenteredCropRect = function getCenteredCropRect(e, t) {
        var r = e.width,
            n = r * t;
        return (
            n > e.height && (r = (n = e.height) / t),
            {
                x: 0.5 * (e.width - r),
                y: 0.5 * (e.height - n),
                width: r,
                height: n,
            }
        );
    },
    calculateCanvasSize = function calculateCanvasSize(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 1,
            n = e.height / e.width,
            i = t,
            o = 1,
            a = n;
        a > i && (o = (a = i) / n);
        var c = Math.max(1 / o, i / a),
            l = e.width / (r * c * o);
        return { width: l, height: l * t };
    },
    createVector$1 = function createVector$1(e, t) {
        return { x: e, y: t };
    },
    vectorDot$1 = function vectorDot$1(e, t) {
        return e.x * t.x + e.y * t.y;
    },
    vectorSubtract$1 = function vectorSubtract$1(e, t) {
        return createVector$1(e.x - t.x, e.y - t.y);
    },
    vectorDistanceSquared$1 = function vectorDistanceSquared$1(e, t) {
        return vectorDot$1(vectorSubtract$1(e, t), vectorSubtract$1(e, t));
    },
    vectorDistance$1 = function vectorDistance$1(e, t) {
        return Math.sqrt(vectorDistanceSquared$1(e, t));
    },
    getOffsetPointOnEdge = function getOffsetPointOnEdge(e, t) {
        var r = e,
            n = t,
            i = 1.5707963267948966 - t,
            o = Math.sin(1.5707963267948966),
            a = Math.sin(n),
            c = Math.sin(i),
            l = Math.cos(i),
            u = r / o;
        return createVector$1(l * (u * a), l * (u * c));
    },
    getRotatedRectSize = function getRotatedRectSize(e, t) {
        var r = e.width,
            n = e.height,
            i = getOffsetPointOnEdge(r, t),
            o = getOffsetPointOnEdge(n, t),
            a = createVector$1(e.x + Math.abs(i.x), e.y - Math.abs(i.y)),
            c = createVector$1(
                e.x + e.width + Math.abs(o.y),
                e.y + Math.abs(o.x)
            ),
            l = createVector$1(
                e.x - Math.abs(o.y),
                e.y + e.height - Math.abs(o.x)
            );
        return {
            width: vectorDistance$1(a, c),
            height: vectorDistance$1(a, l),
        };
    },
    getImageRectZoomFactor = function getImageRectZoomFactor(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 0,
            n =
                arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : { x: 0.5, y: 0.5 },
            i = n.x > 0.5 ? 1 - n.x : n.x,
            o = n.y > 0.5 ? 1 - n.y : n.y,
            a = 2 * i * e.width,
            c = 2 * o * e.height,
            l = getRotatedRectSize(t, r);
        return Math.max(l.width / a, l.height / c);
    },
    getAxisAlignedImageRect = function getAxisAlignedImageRect(e, t) {
        var r = t.origin,
            n = t.translation,
            i = t.scale;
        return rectTransform(
            e,
            [
                ["scale", i],
                ["translate", n],
            ],
            r
        );
    },
    getOffsetPointOnEdge$1 = function getOffsetPointOnEdge$1(e, t) {
        var r = e,
            n = t,
            i = 1.5707963267948966 - t,
            o = Math.sin(1.5707963267948966),
            a = Math.sin(n),
            c = Math.sin(i),
            l = Math.cos(i),
            u = r / o;
        return createVector(l * (u * a), l * (u * c));
    },
    getRotatedRectCorners = function getRotatedRectCorners(e, t) {
        var r = e.width,
            n = e.height,
            i = t % (Math.PI / 2),
            o = getOffsetPointOnEdge$1(r, i),
            a = getOffsetPointOnEdge$1(n, i),
            c = rectCorners(e);
        return {
            tl: createVector(c.tl.x + Math.abs(o.x), c.tl.y - Math.abs(o.y)),
            tr: createVector(c.tr.x + Math.abs(a.y), c.tr.y + Math.abs(a.x)),
            br: createVector(c.br.x - Math.abs(o.x), c.br.y + Math.abs(o.y)),
            bl: createVector(c.bl.x - Math.abs(a.y), c.bl.y - Math.abs(a.x)),
        };
    },
    getCropFromView = function getCropFromView(e, t, r) {
        var n =
                !(arguments.length > 3 && void 0 !== arguments[3]) ||
                arguments[3],
            i = r.origin,
            o = r.translation,
            a = getAxisAlignedImageRect(e, r),
            c = { x: i.x + o.x, y: i.y + o.y },
            l = 2 * Math.PI + (r.rotation % (2 * Math.PI)),
            u = getRotatedRectCorners(t, l),
            s = vectorRotate(u.tl, -l, c),
            d = vectorRotate(u.tr, -l, c),
            f = vectorRotate(u.br, -l, c),
            p = {
                x: Math.min(s.x, d.x, f.x),
                y: Math.min(s.y, d.y, f.y),
                width: Math.max(s.x, d.x, f.x) - Math.min(s.x, d.x, f.x),
                height: Math.max(s.y, d.y, f.y) - Math.min(s.y, d.y, f.y),
            },
            h = createRect(p.x, p.y, p.width, p.height),
            g = rectCenter(h),
            m = t.height / t.width,
            v = { x: (g.x - a.x) / a.width, y: (g.y - a.y) / a.height },
            y = v.y > 0.5 ? 1 - v.y : v.y,
            E = 2 * (v.x > 0.5 ? 1 - v.x : v.x) * a.width,
            T = 2 * y * a.height;
        return {
            center: v,
            zoom: n
                ? Math.min(E / h.width, T / h.height)
                : Math.min(a.width / h.width, a.height / h.height),
            rotation: r.rotation,
            aspectRatio: m,
            scaleToFit: n,
        };
    },
    getCropFromStateRounded = function getCropFromStateRounded(e, t) {
        var r = getCropFromState(e, t);
        return {
            center: { x: roundFloat(r.center.x), y: roundFloat(r.center.y) },
            rotation: roundFloat(r.rotation),
            zoom: roundFloat(r.zoom),
            aspectRatio: roundFloat(r.aspectRatio),
            flip: _objectSpread({}, t.flip),
            scaleToFit: r.scaleToFit,
        };
    },
    getCropFromState = function getCropFromState(e, t) {
        var r = getCropFromView(
            e,
            t.rectangle,
            t.transforms,
            t.limitToImageBounds
        );
        return {
            center: { x: r.center.x, y: r.center.y },
            rotation: r.rotation,
            zoom: r.zoom,
            aspectRatio: r.aspectRatio,
            flip: _objectSpread({}, t.flip),
            scaleToFit: r.scaleToFit,
        };
    },
    limitSize = function limitSize(e, t, r, n) {
        var i =
                arguments.length > 4 && void 0 !== arguments[4]
                    ? arguments[4]
                    : "width",
            o = e.width,
            a = e.height;
        if (!o && !a) return { width: o, height: a };
        if (
            ((o = o && limit(o, t.width, r.width)),
            (a = a && limit(a, t.height, r.height)),
            !n)
        )
            return { width: o, height: a };
        if (a) {
            if (o)
                "width" === i
                    ? (a = o / n)
                    : "height" === i
                    ? (o = a * n)
                    : (a * n < t.width
                          ? (a = (o = t.width) / n)
                          : o / n < t.height && (o = (a = t.height) * n),
                      a * n > r.width
                          ? (a = (o = r.width) / n)
                          : o / n > r.height && (o = (a = r.height) * n));
            else {
                a = limit(a * n, t.width, r.width) / n;
            }
        } else o = limit(o / n, t.height, r.height) * n;
        return { width: o, height: a };
    },
    dotColorMatrix = function dotColorMatrix(e, t) {
        var r = new Array(20);
        return (
            (r[0] = e[0] * t[0] + e[1] * t[5] + e[2] * t[10] + e[3] * t[15]),
            (r[1] = e[0] * t[1] + e[1] * t[6] + e[2] * t[11] + e[3] * t[16]),
            (r[2] = e[0] * t[2] + e[1] * t[7] + e[2] * t[12] + e[3] * t[17]),
            (r[3] = e[0] * t[3] + e[1] * t[8] + e[2] * t[13] + e[3] * t[18]),
            (r[4] =
                e[0] * t[4] + e[1] * t[9] + e[2] * t[14] + e[3] * t[19] + e[4]),
            (r[5] = e[5] * t[0] + e[6] * t[5] + e[7] * t[10] + e[8] * t[15]),
            (r[6] = e[5] * t[1] + e[6] * t[6] + e[7] * t[11] + e[8] * t[16]),
            (r[7] = e[5] * t[2] + e[6] * t[7] + e[7] * t[12] + e[8] * t[17]),
            (r[8] = e[5] * t[3] + e[6] * t[8] + e[7] * t[13] + e[8] * t[18]),
            (r[9] =
                e[5] * t[4] + e[6] * t[9] + e[7] * t[14] + e[8] * t[19] + e[9]),
            (r[10] =
                e[10] * t[0] + e[11] * t[5] + e[12] * t[10] + e[13] * t[15]),
            (r[11] =
                e[10] * t[1] + e[11] * t[6] + e[12] * t[11] + e[13] * t[16]),
            (r[12] =
                e[10] * t[2] + e[11] * t[7] + e[12] * t[12] + e[13] * t[17]),
            (r[13] =
                e[10] * t[3] + e[11] * t[8] + e[12] * t[13] + e[13] * t[18]),
            (r[14] =
                e[10] * t[4] +
                e[11] * t[9] +
                e[12] * t[14] +
                e[13] * t[19] +
                e[14]),
            (r[15] =
                e[15] * t[0] + e[16] * t[5] + e[17] * t[10] + e[18] * t[15]),
            (r[16] =
                e[15] * t[1] + e[16] * t[6] + e[17] * t[11] + e[18] * t[16]),
            (r[17] =
                e[15] * t[2] + e[16] * t[7] + e[17] * t[12] + e[18] * t[17]),
            (r[18] =
                e[15] * t[3] + e[16] * t[8] + e[17] * t[13] + e[18] * t[18]),
            (r[19] =
                e[15] * t[4] +
                e[16] * t[9] +
                e[17] * t[14] +
                e[18] * t[19] +
                e[19]),
            r
        );
    },
    toRGBColorArray = function toRGBColorArray(e) {
        var t;
        if (/^#/.test(e)) {
            if (4 === e.length) {
                var r = e.split("");
                e = "#" + r[1] + r[1] + r[2] + r[2] + r[3] + r[3];
            }
            var n = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e);
            t = [parseInt(n[1], 16), parseInt(n[2], 16), parseInt(n[3], 16)];
        } else if (/^rgb/.test(e)) {
            (t = e.match(/\d+/g).map(function (e) {
                return parseInt(e, 10);
            })).length = 3;
        }
        return t;
    },
    viewCache = [],
    getColorMatrixFromMatrices = function getColorMatrixFromMatrices(e) {
        var t = [];
        return (
            forin(e, function (e, r) {
                return t.push(r);
            }),
            t.length
                ? t.reduce(function (e, t) {
                      return dotColorMatrix(_toConsumableArray(e), t);
                  }, t.shift())
                : []
        );
    },
    getImageScalar = function getImageScalar(e) {
        return e.crop.draft.transforms
            ? e.crop.draft.transforms.scale
            : e.crop.transforms.scale;
    },
    getMinCropSize = function getMinCropSize(e) {
        var t = e.image.width / e.image.naturalWidth,
            r = getImageScalar(e);
        return {
            width: e.options.cropMinImageWidth * r * t,
            height: e.options.cropMinImageHeight * r * t,
        };
    },
    getMaxCropSize = function getMaxCropSize(e) {
        var t = getImageScalar(e);
        return { width: e.image.width * t, height: e.image.height * t };
    },
    getWidth = function getWidth(e) {
        return e.options.size ? e.options.size.width : null;
    },
    getHeight = function getHeight(e) {
        return e.options.size ? e.options.size.height : null;
    },
    getOutputSizeWidth = function getOutputSizeWidth(e) {
        return !1 === e.size.width ? getWidth(e) : e.size.width;
    },
    getOutputSizeHeight = function getOutputSizeHeight(e) {
        return !1 === e.size.height ? getHeight(e) : e.size.height;
    },
    getAspectRatioOptions = function getAspectRatioOptions(e) {
        return e.options.cropAspectRatioOptions
            ? e.options.cropAspectRatioOptions.map(function (e) {
                  var t = { aspectRatio: null, width: null, height: null };
                  return (
                      "number" == typeof e.value && (t.aspectRatio = e.value),
                      "string" == typeof e.value &&
                          (t.aspectRatio = getNumericAspectRatioFromString(
                              e.value
                          )),
                      "object" === _typeof(e.value) &&
                          null !== e.value &&
                          ((t.width = e.value.width),
                          (t.height = e.value.height),
                          (t.aspectRatio = t.height / t.width)),
                      { label: e.label, value: t }
                  );
              })
            : null;
    },
    queries = function queries(e) {
        return {
            ALLOW_MANUAL_RESIZE: function ALLOW_MANUAL_RESIZE() {
                return e.options.utils.includes("resize");
            },
            GET_SIZE: function GET_SIZE() {
                return !1 !== e.size.width && !1 !== e.size.height
                    ? { width: e.size.width, height: e.size.height }
                    : { width: null, height: null };
            },
            GET_SIZE_INPUT: function GET_SIZE_INPUT() {
                return { width: e.size.width, height: e.size.height };
            },
            GET_SIZE_ASPECT_RATIO_LOCK: function GET_SIZE_ASPECT_RATIO_LOCK() {
                return e.size.aspectRatioLocked;
            },
            IS_ACTIVE_VIEW: function IS_ACTIVE_VIEW(t) {
                return e.activeView === t;
            },
            GET_ACTIVE_VIEW: function GET_ACTIVE_VIEW() {
                return e.activeView;
            },
            GET_STYLES: function GET_STYLES() {
                return Object.keys(e.options)
                    .filter(function (e) {
                        return /^style/.test(e);
                    })
                    .map(function (t) {
                        return { name: t, value: e.options[t] };
                    });
            },
            GET_FILE: function GET_FILE() {
                return e.file;
            },
            GET_IMAGE: function GET_IMAGE() {
                return e.image;
            },
            GET_STAGE: function GET_STAGE() {
                return _objectSpread({}, e.stage, e.stageOffset);
            },
            GET_ROOT: function GET_ROOT() {
                return e.rootRect;
            },
            GET_ROOT_SIZE: function GET_ROOT_SIZE() {
                return { width: e.rootRect.width, height: e.rootRect.height };
            },
            GET_MIN_IMAGE_SIZE: function GET_MIN_IMAGE_SIZE() {
                return {
                    width: e.options.cropMinImageWidth,
                    height: e.options.cropMinImageHeight,
                };
            },
            GET_IMAGE_PREVIEW_SCALE_FACTOR:
                function GET_IMAGE_PREVIEW_SCALE_FACTOR() {
                    return e.image.width / e.image.naturalWidth;
                },
            GET_MIN_PREVIEW_IMAGE_SIZE: function GET_MIN_PREVIEW_IMAGE_SIZE() {
                var t = e.image.width / e.image.naturalWidth;
                return {
                    width: e.options.cropMinImageWidth * t,
                    height: e.options.cropMinImageHeight * t,
                };
            },
            GET_MIN_CROP_SIZE: function GET_MIN_CROP_SIZE() {
                return getMinCropSize(e);
            },
            GET_MAX_CROP_SIZE: function GET_MAX_CROP_SIZE() {
                return getMaxCropSize(e);
            },
            GET_MIN_PIXEL_CROP_SIZE: function GET_MIN_PIXEL_CROP_SIZE() {
                var t = e.crop.transforms.scale,
                    r = getMinCropSize(e);
                return { width: r.width / t, height: r.height / t };
            },
            GET_MAX_PIXEL_CROP_SIZE: function GET_MAX_PIXEL_CROP_SIZE() {
                var t = e.crop.transforms.scale,
                    r = getMaxCropSize(e);
                return { width: r.width / t, height: r.height / t };
            },
            GET_CROP_ASPECT_RATIO_OPTIONS:
                function GET_CROP_ASPECT_RATIO_OPTIONS() {
                    return getAspectRatioOptions(e);
                },
            GET_ACTIVE_CROP_ASPECT_RATIO:
                function GET_ACTIVE_CROP_ASPECT_RATIO() {
                    var t = e.crop.aspectRatio;
                    return isString(t) ? getNumericAspectRatioFromString(t) : t;
                },
            GET_CROP_ASPECT_RATIO: function GET_CROP_ASPECT_RATIO() {
                var t = isString(e.options.cropAspectRatio)
                        ? getNumericAspectRatioFromString(
                              e.options.cropAspectRatio
                          )
                        : e.options.cropAspectRatio,
                    r = getAspectRatioOptions(e);
                return !r || (r && !r.length)
                    ? t
                    : r.find(function (e) {
                          return e.value.aspectRatio === t;
                      })
                    ? t
                    : r[0].value.aspectRatio;
            },
            GET_CROP_RECTANGLE_ASPECT_RATIO:
                function GET_CROP_RECTANGLE_ASPECT_RATIO() {
                    var t = e.crop,
                        r = t.rectangle,
                        n = t.aspectRatio;
                    return r ? r.width / r.height : n;
                },
            GET_CROP_FLIP: function GET_CROP_FLIP() {
                return e.crop.flip
                    ? _objectSpread({}, e.crop.flip)
                    : _objectSpread({}, e.instructions.crop.flip);
            },
            GET_CROP_ROTATION: function GET_CROP_ROTATION() {
                return _objectSpread({}, e.crop.rotation);
            },
            GET_CROP: function GET_CROP(t, r) {
                var n = viewCache[t];
                if (n && n.ts === r) return n;
                var i = getCropView(e);
                return i && ((i.ts = r), (viewCache[t] = i)), i;
            },
            GET_COLOR_MATRIX: function GET_COLOR_MATRIX() {
                return getColorMatrixFromMatrices(e.colorMatrices);
            },
            GET_COLOR_VALUES: function GET_COLOR_VALUES() {
                return _objectSpread(
                    {
                        exposure: e.options.colorExposure,
                        brightness: e.options.colorBrightness,
                        contrast: e.options.colorContrast,
                        saturation: e.options.colorSaturation,
                    },
                    e.colorValues
                );
            },
            GET_PREVIEW_IMAGE_DATA: function GET_PREVIEW_IMAGE_DATA() {
                return e.file.preview;
            },
            GET_THUMB_IMAGE_DATA: function GET_THUMB_IMAGE_DATA() {
                return e.file.thumb;
            },
            GET_FILTER: function GET_FILTER() {
                return e.filter;
            },
            GET_UID: function GET_UID() {
                return e.uid;
            },
            GET_MARKUP_BY_ID: function GET_MARKUP_BY_ID(t) {
                return e.markup.find(function (e) {
                    return e[1].id === t;
                });
            },
            GET_BACKGROUND_COLOR: function GET_BACKGROUND_COLOR() {
                var t = e.options.outputCanvasBackgroundColor;
                if (!t) return COLOR_TRANSPARENT;
                if (ColorTable[t]) return ColorTable[t];
                var r = toRGBColorArray(t);
                return (ColorTable[t] = r.concat(1)), ColorTable[t];
            },
        };
    },
    ColorTable = {},
    COLOR_TRANSPARENT = [0, 0, 0, 0],
    getCurrentImageSize = function getCurrentImageSize(e, t) {
        var r = getOutputSizeWidth(e),
            n = getOutputSizeHeight(e),
            i = t.width / t.height;
        return limitSize(
            { width: r, height: n },
            e.options.sizeMin,
            e.options.sizeMax,
            i
        );
    },
    getCurrentCropSize = function getCurrentCropSize(e) {
        var t =
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : {},
            r =
                !(arguments.length > 2 && void 0 !== arguments[2]) ||
                arguments[2],
            n = t.zoom,
            i = t.rotation,
            o = t.center,
            a = t.aspectRatio,
            c = calculateCanvasSize(e, a, n),
            l = { x: 0.5 * c.width, y: 0.5 * c.height },
            u = { x: 0, y: 0, width: c.width, height: c.height, center: l },
            s =
                n *
                getImageRectZoomFactor(
                    e,
                    getCenteredCropRect(u, a),
                    i,
                    r ? o : { x: 0.5, y: 0.5 }
                );
        return {
            widthFloat: c.width / s,
            heightFloat: c.height / s,
            width: Math.round(c.width / s),
            height: Math.round(c.height / s),
        };
    },
    canZoom = function canZoom(e, t) {
        var r = rectCenter(t),
            n = rectCenter(e);
        return !vectorEqual(n, r);
    },
    getCropView = function getCropView(e) {
        if (!e.stage || !e.image) return null;
        var t = e.crop.draft.rectangle || {
                free: e.crop.rectangle,
                limited: e.crop.rectangle,
            },
            r = e.crop.draft.transforms || e.crop.transforms,
            n = r.origin,
            i = r.translation,
            o = r.scale,
            a = r.interaction,
            c = e.crop.rotation,
            l = e.crop.flip,
            u = !(!e.crop.draft.rectangle && !e.crop.draft.transforms),
            s = u || e.instantUpdate,
            d = canZoom(t.limited, e.stage),
            f = e.crop.isDirty || u,
            p = e.crop.isRotating,
            h =
                void 0 === e.crop.limitToImageBounds ||
                e.crop.limitToImageBounds,
            g = { width: e.image.naturalWidth, height: e.image.naturalHeight },
            m = getColorMatrixFromMatrices(e.colorMatrices),
            v = {
                crop: getCurrentCropSize(
                    g,
                    getCropFromState(e.image, {
                        rectangle: t.limited,
                        transforms: {
                            origin: n,
                            translation: i,
                            scale: o,
                            rotation: c.main + c.sub,
                        },
                        flip: l,
                        limitToImageBounds: e.crop.limitToImageBounds,
                    }),
                    e.crop.limitToImageBounds
                ),
                image: getCurrentImageSize(e, t.limited),
            },
            y = v.image,
            E = v.crop,
            T = E.width,
            _ = E.height,
            R = E.widthFloat / E.heightFloat;
        y.width && y.height
            ? ((T = y.width), (_ = y.height))
            : y.width && !y.height
            ? ((T = y.width), (_ = y.width / R))
            : y.height && !y.width && ((_ = y.height), (T = y.height * R)),
            (v.currentWidth = Math.round(T)),
            (v.currentHeight = Math.round(_));
        var w = { x: 0, y: 0 },
            I = 0,
            A = 0;
        if (s && a) {
            if (a.translation) {
                var C = a.translation.x - i.x,
                    S = a.translation.y - i.y;
                (w.x = 100 * Math.sign(C) * Math.log10(1 + Math.abs(C) / 100)),
                    (w.y =
                        100 * Math.sign(S) * Math.log10(1 + Math.abs(S) / 100));
            }
            if (a.scale) {
                var x = a.scale - o;
                I = 0.25 * Math.sign(x) * Math.log10(1 + Math.abs(x) / 0.25);
            }
            if (a.rotation) {
                var O = a.rotation - (c.main + c.sub);
                A = 0.05 * Math.sign(O) * Math.log10(1 + Math.abs(O) / 0.05);
            }
        }
        var b = {},
            M = t.free,
            L = rectBounds(M),
            P = rectBounds(t.limited);
        return (
            forin(L, function (e) {
                var t = L[e] - P[e];
                b[e] =
                    P[e] + 5 * Math.sign(t) * Math.log10(1 + Math.abs(t) / 5);
            }),
            {
                canRecenter: d,
                canReset: f,
                isDraft: s,
                isRotating: p,
                isLimitedToImageBounds: h,
                cropRect: {
                    x: b.left,
                    y: b.top,
                    width: b.right - b.left,
                    height: b.bottom - b.top,
                },
                origin: n,
                translation: i,
                translationBand: w,
                scale: o,
                scaleBand: I,
                rotation: c,
                rotationBand: A,
                flip: l,
                interaction: a,
                cropStatus: v,
                colorMatrix: m,
                markup: e.markup,
            }
        );
    },
    readExif = function readExif(e, t) {
        if (1165519206 !== e.getUint32((t += 2), !1)) return -1;
        var r = 18761 === e.getUint16((t += 6), !1);
        t += e.getUint32(t + 4, r);
        var n = e.getUint16(t, r);
        t += 2;
        for (var i = 0; i < n; i++) {
            if (274 === e.getUint16(t + 12 * i, r))
                return e.getUint16(t + 12 * i + 8, r);
        }
    },
    readData = function readData(e) {
        var t = new DataView(e);
        if (65496 != t.getUint16(0, !1)) return null;
        for (var r, n = t.byteLength, i = 2; i < n; ) {
            if (t.getUint16(i + 2, !1) <= 8) return -1;
            if (((r = t.getUint16(i, !1)), (i += 2), 65505 === r))
                return readExif(t, i);
            if (65280 != (65280 & r)) return null;
            i += t.getUint16(i, !1);
        }
    },
    getImageOrientation = function getImageOrientation(e) {
        return new Promise(function (t) {
            var r = new FileReader();
            (r.onload = function () {
                return t(readData(r.result) || -1);
            }),
                r.readAsArrayBuffer(e.slice(0, 262144));
        });
    },
    Direction = { HORIZONTAL: 1, VERTICAL: 2 },
    getImageTransformsFromCrop = function getImageTransformsFromCrop(e, t, r) {
        var n =
                !(arguments.length > 3 && void 0 !== arguments[3]) ||
                arguments[3],
            i = e.center,
            o = e.zoom,
            a = e.aspectRatio,
            c = rectCenter(t),
            l = { x: c.x - r.width * i.x, y: c.y - r.height * i.y },
            u = 2 * Math.PI + (e.rotation % (2 * Math.PI)),
            s =
                o *
                getImageRectZoomFactor(
                    r,
                    getCenteredCropRect(t, a || r.height / r.width),
                    u,
                    n ? i : { x: 0.5, y: 0.5 }
                );
        return {
            origin: { x: i.x * r.width, y: i.y * r.height },
            translation: l,
            scale: s,
            rotation: e.rotation,
        };
    },
    copyImageTransforms = function copyImageTransforms(e) {
        return {
            origin: _objectSpread({}, e.origin),
            translation: _objectSpread({}, e.translation),
            rotation: e.rotation,
            scale: e.scale,
        };
    },
    limitImageTransformsToCropRect = function limitImageTransformsToCropRect(
        e,
        t,
        r,
        n
    ) {
        var i = r.translation,
            o = r.scale,
            a = r.rotation,
            c = r.origin,
            l = {
                origin: _objectSpread({}, c),
                translation: _objectSpread({}, i),
                scale: o,
                rotation: 2 * Math.PI + (a % (2 * Math.PI)),
            },
            u = e.height / e.width,
            s = { x: c.x + i.x, y: c.y + i.y },
            d = getRotatedRectCorners(t, l.rotation),
            f = vectorRotate(d.tl, -l.rotation, s),
            p = vectorRotate(d.tr, -l.rotation, s),
            h = vectorRotate(d.br, -l.rotation, s),
            g = vectorRotate(d.bl, -l.rotation, s),
            m = {
                x: Math.min(f.x, p.x, h.x, g.x),
                y: Math.min(f.y, p.y, h.y, g.y),
                width:
                    Math.max(f.x, p.x, h.x, g.x) - Math.min(f.x, p.x, h.x, g.x),
                height:
                    Math.max(f.y, p.y, h.y, g.y) - Math.min(f.y, p.y, h.y, g.y),
            },
            v = createRect(m.x, m.y, m.width, m.height),
            y = rectCenter(v),
            E = rectBounds(v),
            T = getAxisAlignedImageRect(e, r),
            _ = rectCenter(T),
            R = { x: T.x, y: T.y },
            w = { x: _.x, y: _.y },
            I = y.x,
            A = y.y,
            C = { x: R.x, y: R.y, width: T.width, height: T.height };
        if (!rectFitsInRect(v, T))
            if ("moving" === n) {
                C.y > v.y
                    ? (C.y = v.y)
                    : C.y + C.height < E.bottom && (C.y = E.bottom - C.height),
                    C.x > v.x
                        ? (C.x = v.x)
                        : C.x + C.width < E.right && (C.x = E.right - C.width);
                var S = getAxisAlignedImageRect(
                        e,
                        _objectSpread({}, r, { scale: l.scale })
                    ),
                    x = rectCenter(S);
                (w.x = x.x),
                    (w.y = x.y),
                    (R.x = S.x),
                    (R.y = S.y),
                    (C.x = w.x - 0.5 * C.width),
                    (C.y = w.y - 0.5 * C.height),
                    C.y > v.y
                        ? (C.y = v.y)
                        : C.y + C.height < E.bottom &&
                          (C.y = E.bottom - C.height),
                    C.x > v.x
                        ? (C.x = v.x)
                        : C.x + C.width < E.right && (C.x = E.right - C.width);
                var O = { x: C.x - R.x, y: C.y - R.y },
                    b = {
                        x:
                            O.x * Math.cos(l.rotation) -
                            O.y * Math.sin(l.rotation),
                        y:
                            O.x * Math.sin(l.rotation) +
                            O.y * Math.cos(l.rotation),
                    };
                (l.translation.x += b.x), (l.translation.y += b.y);
            } else if ("resizing" === n) {
                T.width < v.width &&
                    ((C.width = v.width),
                    (C.height = C.width * u),
                    C.height < v.height &&
                        ((C.height = v.height), (C.width = C.height / u))),
                    T.height < v.height &&
                        ((C.height = v.height),
                        (C.width = C.height / u),
                        C.width < v.width &&
                            ((C.width = v.width), (C.height = C.width * u))),
                    (C.x = w.x - 0.5 * C.width),
                    (C.y = w.y - 0.5 * C.height),
                    C.y > v.y
                        ? (C.y = v.y)
                        : C.y + C.height < E.bottom &&
                          (C.y = E.bottom - C.height),
                    C.x > v.x
                        ? (C.x = v.x)
                        : C.x + C.width < E.right && (C.x = E.right - C.width),
                    (l.scale = getImageRectZoomFactor(e, t, l.rotation, {
                        x: (I - C.x) / C.width,
                        y: (A - C.y) / C.height,
                    }));
                var M = getAxisAlignedImageRect(
                        e,
                        _objectSpread({}, r, { scale: l.scale })
                    ),
                    L = rectCenter(M);
                (w.x = L.x),
                    (w.y = L.y),
                    (R.x = M.x),
                    (R.y = M.y),
                    (C.x = w.x - 0.5 * C.width),
                    (C.y = w.y - 0.5 * C.height),
                    C.y > v.y
                        ? (C.y = v.y)
                        : C.y + C.height < E.bottom &&
                          (C.y = E.bottom - C.height),
                    C.x > v.x
                        ? (C.x = v.x)
                        : C.x + C.width < E.right && (C.x = E.right - C.width);
                var P = { x: C.x - R.x, y: C.y - R.y },
                    G = {
                        x:
                            P.x * Math.cos(l.rotation) -
                            P.y * Math.sin(l.rotation),
                        y:
                            P.x * Math.sin(l.rotation) +
                            P.y * Math.cos(l.rotation),
                    };
                (l.translation.x += G.x), (l.translation.y += G.y);
            } else if ("rotating" === n) {
                var k = !1;
                if (C.y > v.y) {
                    var D = C.y - v.y;
                    (C.y = v.y), (C.height += 2 * D), (k = !0);
                }
                if (C.y + C.height < E.bottom) {
                    var V = E.bottom - (C.y + C.height);
                    (C.y = E.bottom - C.height), (C.height += 2 * V), (k = !0);
                }
                if (C.x > v.x) {
                    var B = C.x - v.x;
                    (C.x = v.x), (C.width += 2 * B), (k = !0);
                }
                if (C.x + C.width < E.right) {
                    var N = E.right - (C.x + C.width);
                    (C.x = E.right - C.width), (C.width += 2 * N), (k = !0);
                }
                k &&
                    (l.scale = getImageRectZoomFactor(e, t, l.rotation, {
                        x: (I - T.x) / T.width,
                        y: (A - T.y) / T.height,
                    }));
            }
        return _objectSpread({}, l, { rotation: r.rotation });
    },
    getTransformOrigin = function getTransformOrigin(e, t, r) {
        var n = r.origin,
            i = r.translation,
            o = r.scale,
            a = 2 * Math.PI + (r.rotation % (2 * Math.PI)),
            c = { x: n.x + i.x, y: n.y + i.y },
            l = getRotatedRectCorners(t, a),
            u = vectorRotate(l.tl, -a, c),
            s = vectorRotate(l.tr, -a, c),
            d = vectorRotate(l.br, -a, c),
            f = vectorRotate(l.bl, -a, c),
            p = createRect(
                Math.min(u.x, s.x, d.x, f.x),
                Math.min(u.y, s.y, d.y, f.y),
                Math.max(u.x, s.x, d.x, f.x) - Math.min(u.x, s.x, d.x, f.x),
                Math.max(u.y, s.y, d.y, f.y) - Math.min(u.y, s.y, d.y, f.y)
            ),
            h = createRect(p.x, p.y, p.width, p.height),
            g = getAxisAlignedImageRect(e, r),
            m = rectCorners(g),
            v = rectCenter(g),
            y = vectorRotate(m.tl, a, c),
            E = vectorRotate(m.br, a, c),
            T = y.x + 0.5 * (E.x - y.x),
            _ = y.y + 0.5 * (E.y - y.y),
            R = rectTranslate(g, { x: T - v.x, y: _ - v.y }),
            w = rectTranslate(h, { x: T - v.x, y: _ - v.y }),
            I = rectCenter(w),
            A = { x: R.x, y: R.y },
            C = R.width,
            S = R.height,
            x = (I.x - A.x) / C,
            O = (I.y - A.y) / S,
            b = { x: x * e.width, y: O * e.height },
            M = 1 - o,
            L = b.x * M,
            P = b.y * M,
            G = { x: A.x + C * x, y: A.y + S * O },
            k = vectorRotate(A, a, { x: A.x + 0.5 * C, y: A.y + 0.5 * S }),
            D = vectorRotate(A, a, G),
            V = k.x - D.x,
            B = k.y - D.y;
        return {
            origin: roundVector(b),
            translation: roundVector({ x: A.x - L + V, y: A.y - P + B }),
        };
    },
    EdgeMap = {
        n: function n(e) {
            return { x: e.x + 0.5 * e.width, y: e.y };
        },
        e: function e(_e) {
            return { x: _e.x + _e.width, y: _e.y + 0.5 * _e.height };
        },
        s: function s(e) {
            return { x: e.x + 0.5 * e.width, y: e.y + e.height };
        },
        w: function w(e) {
            return { x: e.x, y: e.y + 0.5 * e.height };
        },
    },
    getEdgeCenterCoordinates = function getEdgeCenterCoordinates(e, t) {
        return EdgeMap[e](t);
    },
    getImageTransformsFromRect = function getImageTransformsFromRect(e, t, r) {
        var n = r.origin,
            i = r.translation,
            o = 2 * Math.PI + (r.rotation % (2 * Math.PI)),
            a = getAxisAlignedImageRect(e, r),
            c = { x: n.x + i.x, y: n.y + i.y },
            l = getRotatedRectCorners(t, o),
            u = vectorRotate(l.tl, -o, c),
            s = vectorRotate(l.tr, -o, c),
            d = vectorRotate(l.br, -o, c),
            f = {
                x: Math.min(u.x, s.x, d.x),
                y: Math.min(u.y, s.y, d.y),
                width: Math.max(u.x, s.x, d.x) - Math.min(u.x, s.x, d.x),
                height: Math.max(u.y, s.y, d.y) - Math.min(u.y, s.y, d.y),
            },
            p = createRect(f.x, f.y, f.width, f.height),
            h = rectBounds(p),
            g = rectBounds(a),
            m = a;
        if (
            h.top < g.top ||
            h.right > g.right ||
            h.bottom > g.bottom ||
            h.left < g.left
        ) {
            var v = _objectSpread({}, g);
            if (h.top <= v.top) {
                var y = v.bottom - v.top,
                    E = v.right - v.left,
                    T = Math.max(1, p.height / y),
                    _ = y * T,
                    R = E * T - E;
                (v.bottom = h.top + _),
                    (v.top = h.top),
                    (v.left -= 0.5 * R),
                    (v.right += 0.5 * R);
            }
            if (h.bottom >= v.bottom) {
                var w = v.bottom - v.top,
                    I = v.right - v.left,
                    A = Math.max(1, p.height / w),
                    C = w * A,
                    S = I * A - I;
                (v.bottom = h.bottom),
                    (v.top = h.bottom - C),
                    (v.left -= 0.5 * S),
                    (v.right += 0.5 * S);
            }
            if (h.left <= v.left) {
                var x = v.bottom - v.top,
                    O = v.right - v.left,
                    b = Math.max(1, p.width / O),
                    M = O * b,
                    L = x * b - x;
                (v.right = h.left + M),
                    (v.left = h.left),
                    (v.top -= 0.5 * L),
                    (v.bottom += 0.5 * L);
            }
            if (h.right >= v.right) {
                var P = v.bottom - v.top,
                    G = v.right - v.left,
                    k = Math.max(1, p.width / G),
                    D = G * k,
                    V = P * k - P;
                (v.right = h.right),
                    (v.left = h.right - D),
                    (v.top -= 0.5 * V),
                    (v.bottom += 0.5 * V);
            }
            m = createRect(v.left, v.top, v.right - v.left, v.bottom - v.top);
        }
        var B = rectCorners(m),
            N = rectCenter(m),
            U = vectorRotate(B.tl, o, c),
            F = vectorRotate(B.br, o, c),
            z = U.x + 0.5 * (F.x - U.x),
            W = U.y + 0.5 * (F.y - U.y),
            q = rectTranslate(m, { x: z - N.x, y: W - N.y }),
            Y = rectTranslate(p, { x: z - N.x, y: W - N.y }),
            j = rectCenter(Y),
            H = { x: q.x, y: q.y },
            Z = q.width,
            $ = q.height,
            X = (j.x - H.x) / Z,
            K = (j.y - H.y) / $,
            Q = Z / e.width,
            J = { x: X * e.width, y: K * e.height },
            ee = 1 - Q,
            te = J.x * ee,
            re = J.y * ee,
            ne = { x: H.x + Z * X, y: H.y + $ * K },
            ie = vectorRotate(H, o, { x: H.x + 0.5 * Z, y: H.y + 0.5 * $ }),
            oe = vectorRotate(H, o, ne),
            ae = ie.x - oe.x,
            ce = ie.y - oe.y;
        return {
            origin: J,
            translation: { x: H.x - te + ae, y: H.y - re + ce },
            scale: Q,
            rotation: r.rotation,
        };
    },
    getEdgeTargetRect = function getEdgeTargetRect(e, t, r, n, i, o, a, c, l) {
        var u = o.left,
            s = o.right,
            d = o.top,
            f = o.bottom,
            p = s - u,
            h = f - d,
            g = i.left,
            m = i.right,
            v = i.top,
            y = i.bottom;
        if (r === Direction.VERTICAL) {
            if (
                ((v = e.y > 0 ? n.y : Math.min(n.y, Math.max(t.y, d))),
                (y = e.y > 0 ? Math.max(n.y, Math.min(t.y, f)) : n.y),
                a)
            ) {
                var E = (y - v) / a;
                (g = n.x - 0.5 * E), (m = n.x + 0.5 * E);
            }
        } else if (
            ((g = e.x > 0 ? n.x : Math.min(n.x, Math.max(t.x, u))),
            (m = e.x > 0 ? Math.max(n.x, Math.min(t.x, s)) : n.x),
            a)
        ) {
            var T = (m - g) * a;
            (v = n.y - 0.5 * T), (y = n.y + 0.5 * T);
        }
        var _,
            R,
            w,
            I,
            A = c.width,
            C = c.height;
        if (
            (r === Direction.VERTICAL
                ? ((_ = n.x - 0.5 * A),
                  (R = n.x + 0.5 * A),
                  e.y < 0
                      ? ((w = n.y - C), (I = n.y))
                      : e.y > 0 && ((w = n.y), (I = n.y + C)))
                : ((w = n.y - 0.5 * C),
                  (I = n.y + 0.5 * C),
                  e.x < 0
                      ? ((_ = n.x - A), (R = n.x))
                      : e.x > 0 && ((_ = n.x), (R = n.x + A))),
            a)
        )
            if (r === Direction.VERTICAL) {
                var S = Math.min((y - v) / a, p),
                    x = S * a;
                g < u && (m = (g = u) + S),
                    m > s && (g = (m = s) - S),
                    (n.x = g + 0.5 * S),
                    e.y < 0 ? (v = n.y - x) : e.y > 0 && (y = n.y + x);
            } else {
                var O = Math.min((m - g) * a, h),
                    b = O / a;
                v < d && (y = (v = d) + O),
                    y > f && (v = (y = f) - O),
                    (n.y = v + 0.5 * O),
                    e.x < 0 ? (g = n.x - b) : e.x > 0 && (m = n.x + b);
            }
        var M = rectFromBounds({ top: v, right: m, bottom: y, left: g }),
            L = function L() {
                var t = A * a;
                r === Direction.HORIZONTAL
                    ? ((v = n.y - 0.5 * t), (y = n.y + 0.5 * t))
                    : e.y < 0
                    ? ((y = n.y), (v = y - t))
                    : e.y > 0 && ((v = n.y), (y = v + t));
            },
            P = function P() {
                var t = C / a;
                r === Direction.VERTICAL
                    ? ((g = n.x - 0.5 * t), (m = n.x + 0.5 * t))
                    : e.x < 0
                    ? ((m = n.x), (g = m - t))
                    : e.x > 0 && ((g = n.x), (m = g + t));
            };
        m < R && ((m = R), (g = R - A), a && L()),
            g > _ && ((g = _), (m = _ + A), a && L()),
            v > w && ((v = w), (y = w + C), a && P()),
            y < I && ((y = I), (v = I - C), a && P());
        var G = l.width,
            k = l.height;
        if (
            (a && (a < 1 ? (G = k / a) : (k = G * a)),
            m - g > G && (e.x < 0 ? (g = n.x - G) : (m = n.x + G)),
            y - v > k && (e.y < 0 ? (v = n.y - k) : (y = n.y + k)),
            m - g == 0 && (e.x > 0 ? (m = n.x + 2) : (g = n.x - 2)),
            y - v == 0 && (e.y > 0 ? (y = n.y + 2) : (v = n.y - 2)),
            Math.round(g) < u ||
                Math.round(m) > s ||
                Math.round(v) < d ||
                Math.round(y) > f)
        ) {
            var D = f - d,
                V = s - u;
            if (g < u) {
                g = u;
                var B = Math.min(m - g, V);
                m = g + B;
            }
            if (m > s) {
                m = s;
                var N = Math.min(m - g, V);
                g = m - N;
            }
            if (v < d) {
                v = d;
                var U = Math.min(y - v, D);
                y = v + U;
            }
            if (y > f) {
                y = f;
                var F = Math.min(y - v, D);
                v = y - F;
            }
            M = rectFromBounds({ top: v, right: m, bottom: y, left: g });
        }
        return {
            free: M,
            limited: rectFromBounds({ top: v, right: m, bottom: y, left: g }),
        };
    },
    CornerMap = {
        nw: function nw(e) {
            return { x: e.x, y: e.y };
        },
        ne: function ne(e) {
            return { x: e.x + e.width, y: e.y };
        },
        se: function se(e) {
            return { x: e.x + e.width, y: e.y + e.height };
        },
        sw: function sw(e) {
            return { x: e.x, y: e.y + e.height };
        },
    },
    getCornerCoordinates = function getCornerCoordinates(e, t) {
        return CornerMap[e](t);
    },
    getCornerTargetRect = function getCornerTargetRect(e, t, r, n, i, o, a) {
        var c = rectBounds(n),
            l = c.left,
            u = c.right,
            s = c.top,
            d = c.bottom,
            f = vectorLimit({ x: t.x, y: t.y }, n),
            p = e.x > 0 ? r.x : Math.min(f.x, r.x),
            h = e.x > 0 ? Math.max(r.x, f.x) : r.x,
            g = e.y > 0 ? r.y : Math.min(f.y, r.y),
            m = e.y > 0 ? Math.max(r.y, f.y) : r.y;
        if (i) {
            var v = f.x - r.x;
            e.x > 0
                ? (h = Math.max(r.x, r.x + e.x * v))
                : (p = Math.min(r.x, r.x - e.x * v)),
                e.y > 0
                    ? (m = Math.max(r.y, r.y + e.x * v * i))
                    : (g = Math.min(r.y, r.y - e.x * v * i));
        }
        var y = rectFromBounds({ top: g, right: h, bottom: m, left: p });
        rectFromBounds({ top: g, right: h, bottom: m, left: p });
        if (o.width && o.height) {
            var E = o.width,
                T = o.height;
            i &&
                (1 === i
                    ? (T = E = Math.max(E, T))
                    : E < T
                    ? (E = T / i)
                    : E > T
                    ? (T = E * i)
                    : (E = T / i)),
                h - p < E && (e.x > 0 ? (h = r.x + E) : (p = r.x - E)),
                m - g < T && (e.y > 0 ? (m = r.y + T) : (g = r.y - T));
            var _ = a.width,
                R = a.height;
            i && (i < 1 ? (_ = R / i) : (R = _ * i)),
                h - p > _ && (e.x < 0 ? (p = r.x - _) : (h = r.x + _)),
                m - g > R && (e.y < 0 ? (g = r.y - R) : (m = r.y + R));
        }
        if (
            (h - p == 0 && (e.x > 0 ? (h = r.x + 2) : (p = r.x - 2)),
            m - g == 0 && (e.y > 0 ? (m = r.y + 2) : (g = r.y - 2)),
            Math.round(p) < l ||
                Math.round(h) > u ||
                Math.round(g) < s ||
                Math.round(m) > d)
        ) {
            var w = d - s,
                I = u - l;
            if (p < l) {
                p = l;
                var A = Math.min(h - p, I);
                (h = p + A),
                    i &&
                        (e.y > 0 && (m = r.y + A * i),
                        e.y < 0 && (g = r.y - A * i));
            }
            if (h > u) {
                h = u;
                var C = Math.min(h - p, I);
                (p = h - C),
                    i &&
                        (e.y > 0 && (m = r.y + C * i),
                        e.y < 0 && (g = r.y - C * i));
            }
            if (g < s) {
                g = s;
                var S = Math.min(m - g, w);
                (m = g + S),
                    i &&
                        (e.x > 0 && (h = r.x + S / i),
                        e.x < 0 && (p = r.x - S / i));
            }
            if (m > d) {
                m = d;
                var x = Math.min(m - g, w);
                (g = m - x),
                    i &&
                        (e.x > 0 && (h = r.x + x / i),
                        e.x < 0 && (p = r.x - x / i));
            }
            y = rectFromBounds({ top: g, right: h, bottom: m, left: p });
        }
        return {
            free: y,
            limited: rectFromBounds({ top: g, right: h, bottom: m, left: p }),
        };
    },
    getTargetRect = function getTargetRect(e, t, r) {
        var n = rectClone(e);
        return (
            (n.width = Math.min(n.height, n.width)),
            (n.height = n.width),
            (n.height = n.width * t),
            n.height < r.height &&
                ((n.height = r.height), (n.width = n.height / t)),
            n.width < r.width &&
                ((n.width = r.width), (n.height = n.width * t)),
            n
        );
    },
    TURN = Math.PI / 2,
    PI_QUARTER = Math.PI / 4,
    splitRotation = function splitRotation(e) {
        var t = roundFloat(PI_QUARTER),
            r = roundFloat(TURN),
            n = e / r,
            i = Math.floor(n) * r,
            o = e - i;
        return o > t && ((o -= r), (i += r)), { main: i, sub: o };
    },
    getImageSize = function getImageSize(e) {
        return new Promise(function (t, r) {
            var n = new Image();
            (n.src = URL.createObjectURL(e)),
                (n.onerror = function (e) {
                    clearInterval(i), r(e);
                });
            var i = setInterval(function () {
                n.naturalWidth &&
                    n.naturalHeight &&
                    (clearInterval(i),
                    URL.revokeObjectURL(n.src),
                    t({ width: n.naturalWidth, height: n.naturalHeight }));
            }, 1);
        });
    },
    scaleImageSize = function scaleImageSize(e, t) {
        var r = { width: e.width, height: e.height };
        if (e.width > t.width || e.height > t.height) {
            var n = e.height / e.width,
                i = t.width / e.width,
                o = t.height / e.height;
            i < o
                ? ((r.width = e.width * i), (r.height = r.width * n))
                : ((r.height = e.height * o), (r.width = r.height / n));
        }
        return r;
    },
    isImage = function isImage(e) {
        return /^image/.test(e.type);
    },
    MATRICES = {
        1: function _() {
            return [1, 0, 0, 1, 0, 0];
        },
        2: function _(e) {
            return [-1, 0, 0, 1, e, 0];
        },
        3: function _(e, t) {
            return [-1, 0, 0, -1, e, t];
        },
        4: function _(e, t) {
            return [1, 0, 0, -1, 0, t];
        },
        5: function _() {
            return [0, 1, 1, 0, 0, 0];
        },
        6: function _(e, t) {
            return [0, 1, -1, 0, t, 0];
        },
        7: function _(e, t) {
            return [0, -1, -1, 0, t, e];
        },
        8: function _(e) {
            return [0, -1, 1, 0, 0, e];
        },
    },
    getImageOrientationMatrix = function getImageOrientationMatrix(e, t, r) {
        return -1 === r && (r = 1), MATRICES[r](e, t);
    },
    canvasRelease = function canvasRelease(e) {
        (e.width = 1), (e.height = 1), e.getContext("2d").clearRect(0, 0, 1, 1);
    },
    isFlipped = function isFlipped(e) {
        return e && (e.horizontal || e.vertical);
    },
    getBitmap = function getBitmap(e, t, r) {
        if (t <= 1 && !isFlipped(r))
            return (e.width = e.naturalWidth), (e.height = e.naturalHeight), e;
        var n = document.createElement("canvas"),
            i = e.naturalWidth,
            o = e.naturalHeight,
            a = t >= 5 && t <= 8;
        a ? ((n.width = o), (n.height = i)) : ((n.width = i), (n.height = o));
        var c = n.getContext("2d");
        if (
            (t && c.transform.apply(c, getImageOrientationMatrix(i, o, t)),
            isFlipped(r))
        ) {
            var l = [1, 0, 0, 1, 0, 0];
            ((!a && r.horizontal) || a & r.vertical) &&
                ((l[0] = -1), (l[4] = i)),
                ((!a && r.vertical) || (a && r.horizontal)) &&
                    ((l[3] = -1), (l[5] = o)),
                c.transform.apply(c, l);
        }
        return c.drawImage(e, 0, 0, i, o), n;
    },
    imageToImageData = function imageToImageData(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : {},
            n =
                arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : {},
            i = n.canvasMemoryLimit,
            o = n.background,
            a = void 0 === o ? null : o,
            c = r.zoom || 1,
            l = getBitmap(e, t, r.flip),
            u = { width: l.width, height: l.height },
            s = r.aspectRatio || u.height / u.width,
            d = calculateCanvasSize(u, s, c);
        if (i) {
            var f = d.width * d.height;
            if (f > i) {
                var p = Math.sqrt(i) / Math.sqrt(f);
                (u.width = Math.floor(u.width * p)),
                    (u.height = Math.floor(u.height * p)),
                    (d = calculateCanvasSize(u, s, c));
            }
        }
        var h = document.createElement("canvas"),
            g = { x: 0.5 * d.width, y: 0.5 * d.height },
            m = { x: 0, y: 0, width: d.width, height: d.height, center: g },
            v = void 0 === r.scaleToFit || r.scaleToFit,
            y =
                c *
                getImageRectZoomFactor(
                    u,
                    getCenteredCropRect(m, s),
                    r.rotation,
                    v ? r.center : { x: 0.5, y: 0.5 }
                );
        (h.width = Math.round(d.width / y)),
            (h.height = Math.round(d.height / y)),
            (g.x /= y),
            (g.y /= y);
        var E = g.x - u.width * (r.center ? r.center.x : 0.5),
            T = g.y - u.height * (r.center ? r.center.y : 0.5),
            _ = h.getContext("2d");
        a && ((_.fillStyle = a), _.fillRect(0, 0, h.width, h.height)),
            _.translate(g.x, g.y),
            _.rotate(r.rotation || 0),
            _.drawImage(l, E - g.x, T - g.y, u.width, u.height);
        var R = _.getImageData(0, 0, h.width, h.height);
        return canvasRelease(h), R;
    },
    IS_BROWSER$1 = "undefined" != typeof window && void 0 !== window.document;
IS_BROWSER$1 &&
    (HTMLCanvasElement.prototype.toBlob ||
        Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
            value: function value(e, t, r) {
                var n = this.toDataURL(t, r).split(",")[1];
                setTimeout(function () {
                    for (
                        var r = atob(n),
                            i = r.length,
                            o = new Uint8Array(i),
                            a = 0;
                        a < i;
                        a++
                    ) {
                        o[a] = r.charCodeAt(a);
                    }
                    e(new Blob([o], { type: t || "image/png" }));
                });
            },
        }));
var canvasToBlob = function canvasToBlob(e, t) {
        var r =
            arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : null;
        return new Promise(function (n) {
            var i = r ? r(e) : e;
            Promise.resolve(i).then(function (e) {
                e.toBlob(n, t.type, t.quality);
            });
        });
    },
    vectorMultiply$1 = function vectorMultiply$1(e, t) {
        return createVector$2(e.x * t, e.y * t);
    },
    vectorAdd$1 = function vectorAdd$1(e, t) {
        return createVector$2(e.x + t.x, e.y + t.y);
    },
    vectorNormalize$1 = function vectorNormalize$1(e) {
        var t = Math.sqrt(e.x * e.x + e.y * e.y);
        return 0 === t ? { x: 0, y: 0 } : createVector$2(e.x / t, e.y / t);
    },
    vectorRotate$1 = function vectorRotate$1(e, t, r) {
        var n = Math.cos(t),
            i = Math.sin(t),
            o = createVector$2(e.x - r.x, e.y - r.y);
        return createVector$2(r.x + n * o.x - i * o.y, r.y + i * o.x + n * o.y);
    },
    createVector$2 = function createVector$2() {
        return {
            x:
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : 0,
            y:
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : 0,
        };
    },
    getMarkupValue = function getMarkupValue(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 1,
            n = arguments.length > 3 ? arguments[3] : void 0;
        return "string" == typeof e
            ? parseFloat(e) * r
            : "number" == typeof e
            ? e * (n ? t[n] : Math.min(t.width, t.height))
            : void 0;
    },
    getMarkupStyles = function getMarkupStyles(e, t, r) {
        var n = e.borderStyle || e.lineStyle || "solid",
            i = e.backgroundColor || e.fontColor || "transparent",
            o = e.borderColor || e.lineColor || "transparent",
            a = getMarkupValue(e.borderWidth || e.lineWidth, t, r);
        return {
            "stroke-linecap": e.lineCap || "round",
            "stroke-linejoin": e.lineJoin || "round",
            "stroke-width": a || 0,
            "stroke-dasharray":
                "string" == typeof n
                    ? ""
                    : n
                          .map(function (e) {
                              return getMarkupValue(e, t, r);
                          })
                          .join(","),
            stroke: o,
            fill: i,
            opacity: e.opacity || 1,
        };
    },
    isDefined = function isDefined(e) {
        return null != e;
    },
    getMarkupRect = function getMarkupRect(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 1,
            n =
                getMarkupValue(e.x, t, r, "width") ||
                getMarkupValue(e.left, t, r, "width"),
            i =
                getMarkupValue(e.y, t, r, "height") ||
                getMarkupValue(e.top, t, r, "height"),
            o = getMarkupValue(e.width, t, r, "width"),
            a = getMarkupValue(e.height, t, r, "height"),
            c = getMarkupValue(e.right, t, r, "width"),
            l = getMarkupValue(e.bottom, t, r, "height");
        return (
            isDefined(i) ||
                (i = isDefined(a) && isDefined(l) ? t.height - a - l : l),
            isDefined(n) ||
                (n = isDefined(o) && isDefined(c) ? t.width - o - c : c),
            isDefined(o) ||
                (o = isDefined(n) && isDefined(c) ? t.width - n - c : 0),
            isDefined(a) ||
                (a = isDefined(i) && isDefined(l) ? t.height - i - l : 0),
            { x: n || 0, y: i || 0, width: o || 0, height: a || 0 }
        );
    },
    setAttributes = function setAttributes(e, t) {
        return Object.keys(t).forEach(function (r) {
            return e.setAttribute(r, t[r]);
        });
    },
    ns$1 = "http://www.w3.org/2000/svg",
    svg = function svg(e, t) {
        var r = document.createElementNS(ns$1, e);
        return t && setAttributes(r, t), r;
    },
    updateRect$1 = function updateRect$1(e) {
        return setAttributes(e, _objectSpread({}, e.rect, e.styles));
    },
    updateEllipse = function updateEllipse(e) {
        var t = e.rect.x + 0.5 * e.rect.width,
            r = e.rect.y + 0.5 * e.rect.height,
            n = 0.5 * e.rect.width,
            i = 0.5 * e.rect.height;
        return setAttributes(
            e,
            _objectSpread({ cx: t, cy: r, rx: n, ry: i }, e.styles)
        );
    },
    IMAGE_FIT_STYLE = { contain: "xMidYMid meet", cover: "xMidYMid slice" },
    updateImage = function updateImage(e, t) {
        setAttributes(
            e,
            _objectSpread({}, e.rect, e.styles, {
                preserveAspectRatio: IMAGE_FIT_STYLE[t.fit] || "none",
            })
        );
    },
    TEXT_ANCHOR = { left: "start", center: "middle", right: "end" },
    updateText = function updateText(e, t, r, n) {
        var i = getMarkupValue(t.fontSize, r, n),
            o = t.fontFamily || "sans-serif",
            a = t.fontWeight || "normal",
            c = TEXT_ANCHOR[t.textAlign] || "start";
        setAttributes(
            e,
            _objectSpread({}, e.rect, e.styles, {
                "stroke-width": 0,
                "font-weight": a,
                "font-size": i,
                "font-family": o,
                "text-anchor": c,
            })
        ),
            e.text !== t.text &&
                ((e.text = t.text),
                (e.textContent = t.text.length ? t.text : " "));
    },
    updateLine = function updateLine(e, t, r, n) {
        setAttributes(e, _objectSpread({}, e.rect, e.styles, { fill: "none" }));
        var i = e.childNodes[0],
            o = e.childNodes[1],
            a = e.childNodes[2],
            c = e.rect,
            l = { x: e.rect.x + e.rect.width, y: e.rect.y + e.rect.height };
        if (
            (setAttributes(i, { x1: c.x, y1: c.y, x2: l.x, y2: l.y }),
            t.lineDecoration)
        ) {
            (o.style.display = "none"), (a.style.display = "none");
            var u = vectorNormalize$1({ x: l.x - c.x, y: l.y - c.y }),
                s = getMarkupValue(0.05, r, n);
            if (-1 !== t.lineDecoration.indexOf("arrow-begin")) {
                var d = vectorMultiply$1(u, s),
                    f = vectorAdd$1(c, d),
                    p = vectorRotate$1(c, 2, f),
                    h = vectorRotate$1(c, -2, f);
                setAttributes(o, {
                    style: "display:block;",
                    d: "M"
                        .concat(p.x, ",")
                        .concat(p.y, " L")
                        .concat(c.x, ",")
                        .concat(c.y, " L")
                        .concat(h.x, ",")
                        .concat(h.y),
                });
            }
            if (-1 !== t.lineDecoration.indexOf("arrow-end")) {
                var g = vectorMultiply$1(u, -s),
                    m = vectorAdd$1(l, g),
                    v = vectorRotate$1(l, 2, m),
                    y = vectorRotate$1(l, -2, m);
                setAttributes(a, {
                    style: "display:block;",
                    d: "M"
                        .concat(v.x, ",")
                        .concat(v.y, " L")
                        .concat(l.x, ",")
                        .concat(l.y, " L")
                        .concat(y.x, ",")
                        .concat(y.y),
                });
            }
        }
    },
    createShape = function createShape(e) {
        return function (t) {
            return svg(e, { id: t.id });
        };
    },
    createImage = function createImage(e) {
        var t = svg("image", {
            id: e.id,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            opacity: "0",
        });
        return (
            (t.onload = function () {
                t.setAttribute("opacity", e.opacity || 1);
            }),
            t.setAttributeNS(
                "http://www.w3.org/1999/xlink",
                "xlink:href",
                e.src
            ),
            t
        );
    },
    createLine = function createLine(e) {
        var t = svg("g", {
                id: e.id,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
            }),
            r = svg("line");
        t.appendChild(r);
        var n = svg("path");
        t.appendChild(n);
        var i = svg("path");
        return t.appendChild(i), t;
    },
    CREATE_TYPE_ROUTES = {
        image: createImage,
        rect: createShape("rect"),
        ellipse: createShape("ellipse"),
        text: createShape("text"),
        line: createLine,
    },
    UPDATE_TYPE_ROUTES = {
        rect: updateRect$1,
        ellipse: updateEllipse,
        image: updateImage,
        text: updateText,
        line: updateLine,
    },
    createMarkupByType = function createMarkupByType(e, t) {
        return CREATE_TYPE_ROUTES[e](t);
    },
    updateMarkupByType = function updateMarkupByType(e, t, r, n, i) {
        (e.rect = getMarkupRect(r, n, i)),
            (e.styles = getMarkupStyles(r, n, i)),
            UPDATE_TYPE_ROUTES[t](e, r, n, i);
    },
    sortMarkupByZIndex = function sortMarkupByZIndex(e, t) {
        return e[1].zIndex > t[1].zIndex
            ? 1
            : e[1].zIndex < t[1].zIndex
            ? -1
            : 0;
    },
    cropSVG = function cropSVG(e, t, r, n) {
        return new Promise(function (i) {
            var o = n.background,
                a = void 0 === o ? null : o,
                c = new FileReader();
            (c.onloadend = function () {
                var e = c.result,
                    n = document.createElement("div");
                (n.style.cssText =
                    "position:absolute;pointer-events:none;width:0;height:0;visibility:hidden;"),
                    (n.innerHTML = e);
                var o = n.querySelector("svg");
                document.body.appendChild(n);
                var l = o.getBBox();
                n.parentNode.removeChild(n);
                var u = n.querySelector("title"),
                    s = o.getAttribute("viewBox") || "",
                    d = o.getAttribute("width") || "",
                    f = o.getAttribute("height") || "",
                    p = parseFloat(d) || null,
                    h = parseFloat(f) || null,
                    g = (d.match(/[a-z]+/) || [])[0] || "",
                    m = (f.match(/[a-z]+/) || [])[0] || "",
                    v = s.split(" ").map(parseFloat),
                    y = v.length
                        ? { x: v[0], y: v[1], width: v[2], height: v[3] }
                        : l,
                    E = null != p ? p : y.width,
                    T = null != h ? h : y.height;
                (o.style.overflow = "visible"),
                    o.setAttribute("width", E),
                    o.setAttribute("height", T);
                var _ = "";
                if (r && r.length) {
                    var R = { width: E, height: T };
                    (_ = r.sort(sortMarkupByZIndex).reduce(function (e, t) {
                        var r = createMarkupByType(t[0], t[1]);
                        return (
                            updateMarkupByType(r, t[0], t[1], R),
                            r.removeAttribute("id"),
                            1 === r.getAttribute("opacity") &&
                                r.removeAttribute("opacity"),
                            e + "\n" + r.outerHTML + "\n"
                        );
                    }, "")),
                        (_ = "\n\n<g>".concat(
                            _.replace(/&nbsp;/g, " "),
                            "</g>\n\n"
                        ));
                }
                var w = t.aspectRatio || T / E,
                    I = E,
                    A = I * w,
                    C = void 0 === t.scaleToFit || t.scaleToFit,
                    S = getImageRectZoomFactor(
                        { width: E, height: T },
                        getCenteredCropRect({ width: I, height: A }, w),
                        t.rotation,
                        C ? t.center : { x: 0.5, y: 0.5 }
                    ),
                    x = t.zoom * S,
                    O = t.rotation * (180 / Math.PI),
                    b = { x: 0.5 * I, y: 0.5 * A },
                    M = { x: b.x - E * t.center.x, y: b.y - T * t.center.y },
                    L = [
                        "rotate("
                            .concat(O, " ")
                            .concat(b.x, " ")
                            .concat(b.y, ")"),
                        "translate(".concat(b.x, " ").concat(b.y, ")"),
                        "scale(".concat(x, ")"),
                        "translate(".concat(-b.x, " ").concat(-b.y, ")"),
                        "translate(".concat(M.x, " ").concat(M.y, ")"),
                    ],
                    P = [
                        "scale("
                            .concat(t.flip.horizontal ? -1 : 1, " ")
                            .concat(t.flip.vertical ? -1 : 1, ")"),
                        "translate("
                            .concat(t.flip.horizontal ? -E : 0, " ")
                            .concat(t.flip.vertical ? -T : 0, ")"),
                    ],
                    G = '<?xml version="1.0" encoding="UTF-8"?>\n<svg width="'
                        .concat(I)
                        .concat(g, '" height="')
                        .concat(A)
                        .concat(m, '" \nviewBox="0 0 ')
                        .concat(I, " ")
                        .concat(A, '" ')
                        .concat(
                            a ? 'style="background:' + a + '" ' : "",
                            '\npreserveAspectRatio="xMinYMin"\nxmlns:xlink="http://www.w3.org/1999/xlink"\nxmlns="http://www.w3.org/2000/svg">\n\x3c!-- Generated by PQINA - https://pqina.nl/ --\x3e\n<title>'
                        )
                        .concat(
                            u ? u.textContent : "",
                            '</title>\n<g transform="'
                        )
                        .concat(L.join(" "), '">\n<g transform="')
                        .concat(P.join(" "), '">\n')
                        .concat(o.outerHTML)
                        .concat(_, "\n</g>\n</g>\n</svg>");
                i(G);
            }),
                c.readAsText(e);
        });
    },
    objectToImageData = function objectToImageData(e) {
        var t;
        try {
            t = new ImageData(e.width, e.height);
        } catch (r) {
            t = document
                .createElement("canvas")
                .getContext("2d")
                .createImageData(e.width, e.height);
        }
        return t.data.set(e.data), t;
    },
    TransformWorker = function TransformWorker() {
        var e = {
                resize: function resize(e, t) {
                    var n = t.mode,
                        a = void 0 === n ? "contain" : n,
                        c = t.upscale,
                        l = void 0 !== c && c,
                        u = t.width,
                        s = t.height,
                        d = t.matrix;
                    if (((d = !d || i(d) ? null : d), !u && !s)) return o(e, d);
                    null === u ? (u = s) : null === s && (s = u);
                    if ("force" !== a) {
                        var f = u / e.width,
                            p = s / e.height,
                            h = 1;
                        if (
                            ("cover" === a
                                ? (h = Math.max(f, p))
                                : "contain" === a && (h = Math.min(f, p)),
                            h > 1 && !1 === l)
                        )
                            return o(e, d);
                        (u = e.width * h), (s = e.height * h);
                    }
                    for (
                        var g = e.width,
                            m = e.height,
                            v = Math.round(u),
                            y = Math.round(s),
                            E = e.data,
                            T = new Uint8ClampedArray(v * y * 4),
                            _ = g / v,
                            R = m / y,
                            w = Math.ceil(0.5 * _),
                            I = Math.ceil(0.5 * R),
                            A = 0;
                        A < y;
                        A++
                    ) {
                        for (var C = 0; C < v; C++) {
                            for (
                                var S = 4 * (C + A * v),
                                    x = 0,
                                    O = 0,
                                    b = 0,
                                    M = 0,
                                    L = 0,
                                    P = 0,
                                    G = 0,
                                    k = (A + 0.5) * R,
                                    D = Math.floor(A * R);
                                D < (A + 1) * R;
                                D++
                            ) {
                                for (
                                    var V = Math.abs(k - (D + 0.5)) / I,
                                        B = (C + 0.5) * _,
                                        N = V * V,
                                        U = Math.floor(C * _);
                                    U < (C + 1) * _;
                                    U++
                                ) {
                                    var F = Math.abs(B - (U + 0.5)) / w,
                                        z = Math.sqrt(N + F * F);
                                    if (
                                        z >= -1 &&
                                        z <= 1 &&
                                        (x = 2 * z * z * z - 3 * z * z + 1) > 0
                                    ) {
                                        var W = E[(F = 4 * (U + D * g)) + 3];
                                        (G += x * W),
                                            (b += x),
                                            W < 255 && (x = (x * W) / 250),
                                            (M += x * E[F]),
                                            (L += x * E[F + 1]),
                                            (P += x * E[F + 2]),
                                            (O += x);
                                    }
                                }
                            }
                            (T[S] = M / O),
                                (T[S + 1] = L / O),
                                (T[S + 2] = P / O),
                                (T[S + 3] = G / b),
                                d && r(S, T, d);
                        }
                    }
                    return { data: T, width: v, height: y };
                },
                filter: o,
            },
            t = function t(_t2, r) {
                var n = _t2.transforms,
                    i = null;
                if (
                    (n.forEach(function (e) {
                        "filter" === e.type && (i = e);
                    }),
                    i)
                ) {
                    var o = null;
                    n.forEach(function (e) {
                        "resize" === e.type && (o = e);
                    }),
                        o &&
                            ((o.data.matrix = i.data),
                            (n = n.filter(function (e) {
                                return "filter" !== e.type;
                            })));
                }
                r(
                    (function (t, r) {
                        return (
                            t.forEach(function (t) {
                                r = e[t.type](r, t.data);
                            }),
                            r
                        );
                    })(n, _t2.imageData)
                );
            };
        function r(e, t, r) {
            for (
                var n = 0,
                    i = 0,
                    o = 0,
                    a = t[e] / 255,
                    c = t[e + 1] / 255,
                    l = t[e + 2] / 255,
                    u = t[e + 3] / 255;
                n < 4;
                n++
            ) {
                (o =
                    255 *
                    (a * r[(i = 5 * n)] +
                        c * r[i + 1] +
                        l * r[i + 2] +
                        u * r[i + 3] +
                        r[i + 4])),
                    (t[e + n] = Math.max(0, Math.min(o, 255)));
            }
        }
        self.onmessage = function (e) {
            t(e.data.message, function (t) {
                self.postMessage({ id: e.data.id, message: t }, [
                    t.data.buffer,
                ]);
            });
        };
        var n = self.JSON.stringify([
            1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0,
        ]);
        function i(e) {
            return self.JSON.stringify(e || []) === n;
        }
        function o(e, t) {
            if (!t || i(t)) return e;
            for (
                var r = e.data,
                    n = r.length,
                    o = t[0],
                    a = t[1],
                    c = t[2],
                    l = t[3],
                    u = t[4],
                    s = t[5],
                    d = t[6],
                    f = t[7],
                    p = t[8],
                    h = t[9],
                    g = t[10],
                    m = t[11],
                    v = t[12],
                    y = t[13],
                    E = t[14],
                    T = t[15],
                    _ = t[16],
                    R = t[17],
                    w = t[18],
                    I = t[19],
                    A = 0,
                    C = 0,
                    S = 0,
                    x = 0,
                    O = 0;
                A < n;
                A += 4
            ) {
                (C = r[A] / 255),
                    (S = r[A + 1] / 255),
                    (x = r[A + 2] / 255),
                    (O = r[A + 3] / 255),
                    (r[A] = Math.max(
                        0,
                        Math.min(255 * (C * o + S * a + x * c + O * l + u), 255)
                    )),
                    (r[A + 1] = Math.max(
                        0,
                        Math.min(255 * (C * s + S * d + x * f + O * p + h), 255)
                    )),
                    (r[A + 2] = Math.max(
                        0,
                        Math.min(255 * (C * g + S * m + x * v + O * y + E), 255)
                    )),
                    (r[A + 3] = Math.max(
                        0,
                        Math.min(255 * (C * T + S * _ + x * R + O * w + I), 255)
                    ));
            }
            return e;
        }
    },
    correctOrientation = function correctOrientation(e, t) {
        if (1165519206 === e.getUint32(t + 4, !1)) {
            t += 4;
            var r = 18761 === e.getUint16((t += 6), !1);
            t += e.getUint32(t + 4, r);
            var n = e.getUint16(t, r);
            t += 2;
            for (var i = 0; i < n; i++) {
                if (274 === e.getUint16(t + 12 * i, r))
                    return e.setUint16(t + 12 * i + 8, 1, r), !0;
            }
            return !1;
        }
    },
    readData$1 = function readData$1(e) {
        var t = new DataView(e);
        if (65496 !== t.getUint16(0)) return null;
        for (var r, n, i = 2, o = !1; i < t.byteLength; ) {
            if (
                ((r = t.getUint16(i, !1)),
                (n = t.getUint16(i + 2, !1) + 2),
                !((r >= 65504 && r <= 65519) || 65534 === r))
            )
                break;
            if ((o || (o = correctOrientation(t, i, n)), i + n > t.byteLength))
                break;
            i += n;
        }
        return e.slice(0, i);
    },
    getImageHead = function getImageHead(e) {
        return new Promise(function (t) {
            var r = new FileReader();
            (r.onload = function () {
                return t(readData$1(r.result) || null);
            }),
                r.readAsArrayBuffer(e.slice(0, 262144));
        });
    },
    getBlobBuilder = function getBlobBuilder() {
        return (window.BlobBuilder =
            window.BlobBuilder ||
            window.WebKitBlobBuilder ||
            window.MozBlobBuilder ||
            window.MSBlobBuilder);
    },
    createBlob = function createBlob(e, t) {
        var r = getBlobBuilder();
        if (r) {
            var n = new r();
            return n.append(e), n.getBlob(t);
        }
        return new Blob([e], { type: t });
    },
    getUniqueId$1 = function getUniqueId$1() {
        return Math.random().toString(36).substr(2, 9);
    },
    createWorker = function createWorker(e) {
        var t = new Blob(["(", e.toString(), ")()"], {
                type: "application/javascript",
            }),
            r = URL.createObjectURL(t),
            n = new Worker(r),
            i = [];
        return {
            transfer: function transfer() {},
            post: function post(e, t, r) {
                var o = getUniqueId$1();
                (i[o] = t),
                    (n.onmessage = function (e) {
                        var t = i[e.data.id];
                        t && (t(e.data.message), delete i[e.data.id]);
                    }),
                    n.postMessage({ id: o, message: e }, r);
            },
            terminate: function terminate() {
                n.terminate(), URL.revokeObjectURL(r);
            },
        };
    },
    loadImage = function loadImage(e) {
        return new Promise(function (t, r) {
            var n = new Image();
            (n.onload = function () {
                t(n);
            }),
                (n.onerror = function (e) {
                    r(e);
                }),
                (n.src = e);
        });
    },
    chain = function chain(e) {
        return e.reduce(function (e, t) {
            return e.then(function (e) {
                return t().then(Array.prototype.concat.bind(e));
            });
        }, Promise.resolve([]));
    },
    canvasApplyMarkup = function canvasApplyMarkup(e, t) {
        return new Promise(function (r) {
            var n = { width: e.width, height: e.height },
                i = e.getContext("2d"),
                o = t.sort(sortMarkupByZIndex).map(function (e) {
                    return function () {
                        return new Promise(function (t) {
                            TYPE_DRAW_ROUTES[e[0]](i, n, e[1], t) && t();
                        });
                    };
                });
            chain(o).then(function () {
                return r(e);
            });
        });
    },
    applyMarkupStyles = function applyMarkupStyles(e, t) {
        e.beginPath(),
            (e.lineCap = t["stroke-linecap"]),
            (e.lineJoin = t["stroke-linejoin"]),
            (e.lineWidth = t["stroke-width"]),
            t["stroke-dasharray"].length &&
                e.setLineDash(t["stroke-dasharray"].split(",")),
            (e.fillStyle = t.fill),
            (e.strokeStyle = t.stroke),
            (e.globalAlpha = t.opacity || 1);
    },
    drawMarkupStyles = function drawMarkupStyles(e) {
        e.fill(), e.stroke(), (e.globalAlpha = 1);
    },
    drawRect = function drawRect(e, t, r) {
        var n = getMarkupRect(r, t),
            i = getMarkupStyles(r, t);
        return (
            applyMarkupStyles(e, i),
            e.rect(n.x, n.y, n.width, n.height),
            drawMarkupStyles(e, i),
            !0
        );
    },
    drawEllipse = function drawEllipse(e, t, r) {
        var n = getMarkupRect(r, t),
            i = getMarkupStyles(r, t);
        applyMarkupStyles(e, i);
        var o = n.x,
            a = n.y,
            c = n.width,
            l = n.height,
            u = (c / 2) * 0.5522848,
            s = (l / 2) * 0.5522848,
            d = o + c,
            f = a + l,
            p = o + c / 2,
            h = a + l / 2;
        return (
            e.moveTo(o, h),
            e.bezierCurveTo(o, h - s, p - u, a, p, a),
            e.bezierCurveTo(p + u, a, d, h - s, d, h),
            e.bezierCurveTo(d, h + s, p + u, f, p, f),
            e.bezierCurveTo(p - u, f, o, h + s, o, h),
            drawMarkupStyles(e, i),
            !0
        );
    },
    drawImage = function drawImage(e, t, r, n) {
        var i = getMarkupRect(r, t),
            o = getMarkupStyles(r, t);
        applyMarkupStyles(e, o);
        var a = new Image();
        (a.onload = function () {
            if ("cover" === r.fit) {
                var t = i.width / i.height,
                    c = t > 1 ? a.width : a.height * t,
                    l = t > 1 ? a.width / t : a.height,
                    u = 0.5 * a.width - 0.5 * c,
                    s = 0.5 * a.height - 0.5 * l;
                e.drawImage(a, u, s, c, l, i.x, i.y, i.width, i.height);
            } else if ("contain" === r.fit) {
                var d = Math.min(i.width / a.width, i.height / a.height),
                    f = d * a.width,
                    p = d * a.height,
                    h = i.x + 0.5 * i.width - 0.5 * f,
                    g = i.y + 0.5 * i.height - 0.5 * p;
                e.drawImage(a, 0, 0, a.width, a.height, h, g, f, p);
            } else
                e.drawImage(
                    a,
                    0,
                    0,
                    a.width,
                    a.height,
                    i.x,
                    i.y,
                    i.width,
                    i.height
                );
            drawMarkupStyles(e, o), n();
        }),
            (a.src = r.src);
    },
    drawText = function drawText(e, t, r) {
        var n = getMarkupRect(r, t),
            i = getMarkupStyles(r, t);
        applyMarkupStyles(e, i);
        var o = getMarkupValue(r.fontSize, t),
            a = r.fontFamily || "sans-serif",
            c = r.fontWeight || "normal",
            l = r.textAlign || "left";
        return (
            (e.font = "".concat(c, " ").concat(o, "px ").concat(a)),
            (e.textAlign = l),
            e.fillText(r.text, n.x, n.y),
            drawMarkupStyles(e, i),
            !0
        );
    },
    drawLine = function drawLine(e, t, r) {
        var n = getMarkupRect(r, t),
            i = getMarkupStyles(r, t);
        applyMarkupStyles(e, i), e.beginPath();
        var o = { x: n.x, y: n.y },
            a = { x: n.x + n.width, y: n.y + n.height };
        e.moveTo(o.x, o.y), e.lineTo(a.x, a.y);
        var c = vectorNormalize$1({ x: a.x - o.x, y: a.y - o.y }),
            l = 0.04 * Math.min(t.width, t.height);
        if (-1 !== r.lineDecoration.indexOf("arrow-begin")) {
            var u = vectorMultiply$1(c, l),
                s = vectorAdd$1(o, u),
                d = vectorRotate$1(o, 2, s),
                f = vectorRotate$1(o, -2, s);
            e.moveTo(d.x, d.y), e.lineTo(o.x, o.y), e.lineTo(f.x, f.y);
        }
        if (-1 !== r.lineDecoration.indexOf("arrow-end")) {
            var p = vectorMultiply$1(c, -l),
                h = vectorAdd$1(a, p),
                g = vectorRotate$1(a, 2, h),
                m = vectorRotate$1(a, -2, h);
            e.moveTo(g.x, g.y), e.lineTo(a.x, a.y), e.lineTo(m.x, m.y);
        }
        return drawMarkupStyles(e, i), !0;
    },
    TYPE_DRAW_ROUTES = {
        rect: drawRect,
        ellipse: drawEllipse,
        image: drawImage,
        text: drawText,
        line: drawLine,
    },
    imageDataToCanvas = function imageDataToCanvas(e) {
        var t = document.createElement("canvas");
        return (
            (t.width = e.width),
            (t.height = e.height),
            t.getContext("2d").putImageData(e, 0, 0),
            t
        );
    },
    transformImage = function transformImage(e, t) {
        var r =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
        return new Promise(function (n, i) {
            if (!e || !isImage(e))
                return i({ status: "not an image file", file: e });
            var o = r.stripImageHead,
                a = r.beforeCreateBlob,
                c = r.afterCreateBlob,
                l = r.canvasMemoryLimit,
                u = t.crop,
                s = t.size,
                d = t.filter,
                f = t.markup,
                p = t.output,
                h =
                    t.image && t.image.orientation
                        ? Math.max(1, Math.min(8, t.image.orientation))
                        : null,
                g = p && p.quality,
                m = null === g ? null : g / 100,
                v = (p && p.type) || null,
                y = (p && p.background) || null,
                E = [];
            !s ||
                ("number" != typeof s.width && "number" != typeof s.height) ||
                E.push({ type: "resize", data: s }),
                d && 20 === d.length && E.push({ type: "filter", data: d });
            var T = function T(e) {
                    var t = c ? c(e) : e;
                    Promise.resolve(t).then(n);
                },
                _ = function _(e, t) {
                    var r = imageDataToCanvas(e),
                        n = f.length ? canvasApplyMarkup(r, f) : r;
                    Promise.resolve(n).then(function (e) {
                        canvasToBlob(e, t, a)
                            .then(function (t) {
                                if ((canvasRelease(e), o)) return T(t);
                                getImageHead(t).then(function (e) {
                                    null !== e &&
                                        (t = new Blob([e, t.slice(20)], {
                                            type: t.type,
                                        })),
                                        T(t);
                                });
                            })
                            .catch(i);
                    });
                };
            if (/svg/.test(e.type) && null === v)
                return cropSVG(e, u, f, { background: y }).then(function (e) {
                    n(createBlob(e, "image/svg+xml"));
                });
            var R = URL.createObjectURL(e);
            loadImage(R)
                .then(function (t) {
                    URL.revokeObjectURL(R);
                    var r = imageToImageData(t, h, u, {
                            canvasMemoryLimit: l,
                            background: y,
                        }),
                        n = { quality: m, type: v || e.type };
                    if (!E.length) return _(r, n);
                    var i = createWorker(TransformWorker);
                    i.post(
                        { transforms: E, imageData: r },
                        function (e) {
                            _(objectToImageData(e), n), i.terminate();
                        },
                        [r.data.buffer]
                    );
                })
                .catch(i);
        });
    },
    leftPad = function leftPad(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
        return (t + e).slice(-t.length);
    },
    getDateString = function getDateString() {
        var e =
            arguments.length > 0 && void 0 !== arguments[0]
                ? arguments[0]
                : new Date();
        return ""
            .concat(e.getFullYear(), "-")
            .concat(leftPad(e.getMonth() + 1, "00"), "-")
            .concat(leftPad(e.getDate(), "00"), "_")
            .concat(leftPad(e.getHours(), "00"), "-")
            .concat(leftPad(e.getMinutes(), "00"), "-")
            .concat(leftPad(e.getSeconds(), "00"));
    },
    getBaseCropInstructions = function getBaseCropInstructions(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : {},
            n =
                arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : {},
            i = e("GET_CROP_ASPECT_RATIO"),
            o = {
                center: { x: 0.5, y: 0.5 },
                flip: { horizontal: !1, vertical: !1 },
                zoom: 1,
                rotation: 0,
                aspectRatio: null,
            };
        r
            ? Object.assign(o, r)
            : t.options.crop
            ? Object.assign(o, t.options.crop)
            : (o.aspectRatio = i);
        var a = n.width,
            c = n.height;
        if (a && c) o.aspectRatio = c / a;
        else if (t.instructions.size) {
            var l = t.instructions.size,
                u = l.width,
                s = l.height;
            u && s && (o.aspectRatio = s / u);
        }
        return o;
    },
    capitalizeFirstLetter = function capitalizeFirstLetter(e) {
        return e.charAt(0).toUpperCase() + e.slice(1);
    },
    getExtensionFromFilename = function getExtensionFromFilename(e) {
        return e.split(".").pop();
    },
    guesstimateExtension = function guesstimateExtension(e) {
        if ("string" != typeof e) return "";
        var t = e.split("/").pop();
        return /svg/.test(t)
            ? "svg"
            : /zip|compressed/.test(t)
            ? "zip"
            : /plain/.test(t)
            ? "txt"
            : /msword/.test(t)
            ? "doc"
            : /[a-z]+/.test(t)
            ? "jpeg" === t
                ? "jpg"
                : t
            : "";
    },
    getFileFromBlob = function getFileFromBlob(e, t) {
        var r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : null,
            n =
                arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : null,
            i =
                "string" == typeof r
                    ? e.slice(0, e.size, r)
                    : e.slice(0, e.size, e.type);
        return (
            (i.lastModifiedDate = new Date()),
            isString(t) || (t = getDateString()),
            t && null === n && getExtensionFromFilename(t)
                ? (i.name = t)
                : ((n = n || guesstimateExtension(i.type)),
                  (i.name = t + (n ? "." + n : ""))),
            i
        );
    },
    getFilenameWithoutExtension = function getFilenameWithoutExtension(e) {
        return e.substr(0, e.lastIndexOf(".")) || e;
    },
    ExtensionMap = { jpeg: "jpg", "svg+xml": "svg" },
    renameFileToMatchMimeType = function renameFileToMatchMimeType(e, t) {
        var r = getFilenameWithoutExtension(e),
            n = t.split("/")[1],
            i = ExtensionMap[n] || n;
        return "".concat(r, ".").concat(i);
    },
    getValidOutputMimeType = function getValidOutputMimeType(e) {
        return /jpeg|png|svg\+xml/.test(e) ? e : "image/jpeg";
    },
    isColorMatrix = function isColorMatrix(e) {
        return Array.isArray(e) && 20 === e.length;
    },
    brightness = function brightness(e) {
        return [1, 0, 0, 0, e, 0, 1, 0, 0, e, 0, 0, 1, 0, e, 0, 0, 0, 1, 0];
    },
    contrast = function contrast(e) {
        return [
            e,
            0,
            0,
            0,
            0.5 * (1 - e),
            0,
            e,
            0,
            0,
            0.5 * (1 - e),
            0,
            0,
            e,
            0,
            0.5 * (1 - e),
            0,
            0,
            0,
            1,
            0,
        ];
    },
    saturation = function saturation() {
        var e =
            arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0;
        return [
            0.213 + 0.787 * e,
            0.715 - 0.715 * e,
            0.072 - 0.072 * e,
            0,
            0,
            0.213 - 0.213 * e,
            0.715 + 0.285 * e,
            0.072 - 0.072 * e,
            0,
            0,
            0.213 - 0.213 * e,
            0.715 - 0.715 * e,
            0.072 + 0.928 * e,
            0,
            0,
            0,
            0,
            0,
            1,
            0,
        ];
    },
    exposure = function exposure(e) {
        return [e, 0, 0, 0, 0, 0, e, 0, 0, 0, 0, 0, e, 0, 0, 0, 0, 0, 1, 0];
    },
    MARKUP_RECT = [
        "x",
        "y",
        "left",
        "top",
        "right",
        "bottom",
        "width",
        "height",
    ],
    toOptionalFraction = function toOptionalFraction(e) {
        return "string" == typeof e && /%/.test(e) ? parseFloat(e) / 100 : e;
    },
    getUniqueId$2 = function getUniqueId$2() {
        return Math.random().toString(36).substr(2, 9);
    },
    prepareMarkup = function prepareMarkup(e) {
        var t = _slicedToArray(e, 2),
            r = t[0],
            n = t[1],
            i = !1 !== n.allowSelect,
            o = !1 !== n.allowMove,
            a = !1 !== n.allowResize,
            c = !1 !== n.allowInput,
            l = !1 !== n.allowDestroy,
            u = void 0 === n.allowEdit || n.allowEdit;
        return (
            (!0 === n.allowResize ||
                !0 === n.allowMove ||
                !0 === n.allowInput ||
                n.allowEdit) &&
                (i = !0),
            !1 === n.allowMove && (a = !1),
            !0 === n.allowResize && (o = !0),
            [
                r,
                _objectSpread(
                    { zIndex: 0, id: getUniqueId$2() },
                    n,
                    MARKUP_RECT.reduce(function (e, t) {
                        return (e[t] = toOptionalFraction(n[t])), e;
                    }, {}),
                    {
                        isDestroyed: !1,
                        isSelected: !1,
                        isDirty: !0,
                        allowDestroy: l,
                        allowSelect: i,
                        allowMove: o,
                        allowResize: a,
                        allowInput: c,
                        allowEdit: u,
                    }
                ),
            ]
        );
    },
    COLOR_TOOLS = {
        contrast: contrast,
        exposure: exposure,
        brightness: brightness,
        saturation: saturation,
    },
    TURN$1 = Math.PI / 2,
    getOutputSize = function getOutputSize(e) {
        var t = {
                upscale: e("GET_OUTPUT_UPSCALE"),
                mode: e("GET_OUTPUT_FIT"),
                width: e("GET_OUTPUT_WIDTH"),
                height: e("GET_OUTPUT_HEIGHT"),
            },
            r = e("GET_SIZE_INPUT");
        if (e("ALLOW_MANUAL_RESIZE") && (r.width || r.height)) {
            var n = r.width,
                i = r.height,
                o = e("GET_CROP_RECTANGLE_ASPECT_RATIO");
            n && !i ? (i = n / o) : i && !n && (n = i * o),
                (t.width = n),
                (t.height = i),
                (t.upscale = !0),
                (t.mode = "force");
        }
        return t;
    },
    getPreparedImageSize = function getPreparedImageSize(e, t) {
        var r = t("GET_UID"),
            n = t("GET_CROP", r, Date.now()),
            i = {
                width: n.cropStatus.currentWidth,
                height: n.cropStatus.currentHeight,
            },
            o = e.mode,
            a = e.width,
            c = e.height,
            l = e.upscale;
        if (!a && !c) return i;
        if ((null === a ? (a = c) : null === c && (c = a), "force" !== o)) {
            var u = a / i.width,
                s = c / i.height,
                d = 1;
            if (
                ("cover" === o
                    ? (d = Math.max(u, s))
                    : "contain" === o && (d = Math.min(u, s)),
                d > 1 && !1 === l)
            )
                return i;
            (a = i.width * d), (c = i.height * d);
        }
        return { width: Math.round(a), height: Math.round(c) };
    },
    getActiveMarkupFromState = function getActiveMarkupFromState(e) {
        return e.markup.filter(function (e) {
            return !e[1].isDestroyed;
        });
    },
    prepareOutput = function prepareOutput(e, t, r) {
        return new Promise(function (n, i) {
            var o = { data: null, file: null },
                a = getCropFromStateRounded(t.image, t.crop),
                c = getOutputSize(r),
                l = {
                    crop: a,
                    image: _objectSpread({}, getPreparedImageSize(c, r), {
                        orientation: t.file.orientation,
                    }),
                    size: c,
                    output: {
                        type: r("GET_OUTPUT_TYPE"),
                        quality: r("GET_OUTPUT_QUALITY"),
                        background: t.options.outputCanvasBackgroundColor,
                    },
                    filter: t.colorMatrices.filter
                        ? {
                              id: t.filterName,
                              value: t.filterValue,
                              matrix: t.colorMatrices.filter,
                          }
                        : null,
                    color: Object.keys(t.colorValues).length
                        ? Object.keys(t.colorValues).reduce(function (e, r) {
                              return (
                                  (e[r] = {
                                      value: t.colorValues[r],
                                      matrix: t.colorMatrices[r].map(function (
                                          e
                                      ) {
                                          return roundFloat(e, 5);
                                      }),
                                  }),
                                  e
                              );
                          }, {})
                        : null,
                    markup: getActiveMarkupFromState(t).map(function (e) {
                        return [e[0], _objectSpread({}, e[1])];
                    }),
                    colorMatrix: r("GET_COLOR_MATRIX"),
                };
            if ((e.data && (o.data = l), e.file)) {
                var u = {
                        beforeCreateBlob: r("GET_BEFORE_CREATE_BLOB"),
                        afterCreateBlob: r("GET_AFTER_CREATE_BLOB"),
                        stripImageHead: r("GET_OUTPUT_STRIP_IMAGE_HEAD"),
                        canvasMemoryLimit: r("GET_OUTPUT_CANVAS_MEMORY_LIMIT"),
                    },
                    s = t.file.data,
                    d = _objectSpread({}, l, {
                        filter: l.colorMatrix,
                        markup: l.markup,
                    });
                transformImage(s, d, u)
                    .then(function (e) {
                        (o.file = getFileFromBlob(
                            e,
                            renameFileToMatchMimeType(
                                s.name,
                                getValidOutputMimeType(e.type)
                            )
                        )),
                            n(o);
                    })
                    .catch(i);
            } else n(o);
        });
    },
    resetRotationScale = function resetRotationScale(e) {
        e.crop.draft.rotateMinScale = null;
    },
    storeRotationScale = function storeRotationScale(e) {
        e.crop.draft.rotateMinScale ||
            (e.crop.draft.rotateMinScale = e.crop.transforms.scale);
    },
    rotate = function rotate(e, t, r) {
        var n = arguments.length > 3 && void 0 !== arguments[3] && arguments[3],
            i =
                !(arguments.length > 4 && void 0 !== arguments[4]) ||
                arguments[4];
        storeRotationScale(e);
        var o = _objectSpread({}, e.crop.transforms, {
            scale: e.crop.draft.rotateMinScale,
        });
        (e.crop.draft.transforms = getRotateTransforms(
            e.image,
            e.crop.rectangle,
            o,
            t.main + t.sub,
            r,
            e.crop.draft.transforms
                ? e.crop.draft.transforms.rotation
                : e.crop.rotation.main + e.crop.rotation.sub,
            n,
            i
        )),
            (e.crop.rotation = splitRotation(e.crop.draft.transforms.rotation));
    },
    reset = function reset(e, t, r) {
        if (null !== e.stage) {
            clearCenterTimeout(e),
                (e.size.width =
                    !!e.instructions.size && e.instructions.size.width),
                (e.size.height =
                    !!e.instructions.size && e.instructions.size.height),
                (e.size.aspectRatioLocked = !0),
                (e.size.aspectRatioPrevious = !1),
                (e.crop.rectangle = getCenteredCropRect(
                    e.stage,
                    e.instructions.crop.aspectRatio || e.image.aspectRatio
                )),
                (e.crop.draft.rectangle = null);
            var n =
                void 0 === e.instructions.crop.scaleToFit
                    ? void 0 === e.crop.limitToImageBounds
                        ? e.options.cropLimitToImageBounds
                        : e.crop.limitToImageBounds
                    : e.instructions.crop.scaleToFit;
            (e.crop.transforms = getImageTransformsFromCrop(
                e.instructions.crop,
                e.stage,
                e.image,
                n
            )),
                (e.crop.draft.transforms = null),
                (e.crop.rotation = splitRotation(e.instructions.crop.rotation)),
                (e.crop.flip = _objectSpread({}, e.instructions.crop.flip));
            var i = t("GET_CROP_ASPECT_RATIO_OPTIONS") || [],
                o = i
                    .map(function (e) {
                        return e.value.aspectRatio;
                    })
                    .find(function (t) {
                        return t === e.instructions.crop.aspectRatio;
                    }),
                a = i.find(function (e) {
                    return null === e.value.aspectRatio;
                });
            o
                ? (e.crop.aspectRatio = o)
                : a && i.length
                ? (e.crop.aspectRatio = null)
                : (e.crop.aspectRatio = t("GET_CROP_ASPECT_RATIO")),
                (e.crop.isDirty = !1),
                e.instructions.markup &&
                    r("MARKUP_SET_VALUE", {
                        value: e.instructions.markup
                            .map(prepareMarkup)
                            .sort(sortMarkupByZIndex),
                    }),
                r("CROP_SET_LIMIT", { value: n, silent: !0 }),
                Object.keys(e.instructions.color).forEach(function (t) {
                    return r("COLOR_SET_VALUE", {
                        key: t,
                        value: e.instructions.color[t],
                    });
                }),
                r("FILTER_SET_VALUE", { value: e.instructions.filter }),
                resetRotationScale(e);
        }
    },
    recenter = function recenter(e, t) {
        if (e.stage) {
            clearCenterTimeout(e);
            var r = e.crop.rectangle,
                n = r.height / r.width,
                i = e.crop.aspectRatio;
            if (null !== i && roundFloat(n, 3) !== i) {
                var o = t("GET_MIN_CROP_SIZE");
                (o.width = roundFloat(o.width)),
                    (o.height = roundFloat(o.height));
                var a = Math.min(r.width, r.height);
                Math.min(a * i, a / i) < Math.max(o.width, o.height) &&
                    ((e.crop.rectangle = getTargetRect(
                        _objectSpread({}, e.crop.rectangle),
                        i,
                        o
                    )),
                    (e.crop.draft.transforms = getImageTransformsFromRect(
                        e.image,
                        e.crop.rectangle,
                        e.crop.transforms
                    )));
            }
            var c = e.crop.draft.transforms || e.crop.transforms,
                l = getCropFromView(
                    e.image,
                    e.crop.rectangle,
                    c,
                    e.crop.limitToImageBounds
                );
            e.crop.aspectRatio && (l.aspectRatio = e.crop.aspectRatio),
                (e.crop.transforms = getImageTransformsFromCrop(
                    l,
                    e.stage,
                    e.image,
                    l.scaleToFit
                )),
                (e.crop.draft.transforms = null);
            var u =
                e.crop.aspectRatio ||
                e.crop.rectangle.height / e.crop.rectangle.width;
            (e.crop.rectangle = getCenteredCropRect(e.stage, u)),
                (e.crop.draft.rectangle = null),
                resetRotationScale(e);
        }
    },
    confirmImageDraft = function confirmImageDraft(e) {
        (e.crop.draft.rectangle = null),
            (e.crop.transforms = e.crop.draft.transforms || e.crop.transforms),
            (e.crop.transforms.interaction = null),
            (e.crop.draft.transforms = null),
            (e.crop.transforms = _objectSpread(
                {},
                e.crop.transforms,
                getTransformOrigin(e.image, e.crop.rectangle, e.crop.transforms)
            )),
            (e.crop.isRotating = !1),
            (e.crop.isDirty = !0);
    },
    startCenterTimeout = function startCenterTimeout(e, t, r) {
        var n = t("GET_CROP_ZOOM_TIMEOUT");
        n &&
            (clearTimeout(e.zoomTimeoutId),
            (e.zoomTimeoutId = setTimeout(function () {
                r("CROP_ZOOM");
            }, n)));
    },
    resetCenterTimeout = function resetCenterTimeout(e, t, r) {
        clearCenterTimeout(e), startCenterTimeout(e, t, r);
    },
    clearCenterTimeout = function clearCenterTimeout(e) {
        clearTimeout(e.zoomTimeoutId);
    },
    confirmCropDraft = function confirmCropDraft(e) {
        (e.crop.rectangle = e.crop.draft.rectangle.limited),
            (e.crop.draft.rectangle = null),
            confirmImageDraft(e),
            resetRotationScale(e);
    },
    copyConfirmed = function copyConfirmed(e) {
        (e.crop.draft.transforms = copyImageTransforms(e.crop.transforms)),
            (e.crop.draft.rectangle = {
                limited: rectClone(e.crop.rectangle),
                free: rectClone(e.crop.rectangle),
            }),
            clearCenterTimeout(e);
    },
    getMinScale = function getMinScale(e, t) {
        return Math.min(e.width / t.width, e.height / t.height);
    },
    getRotateTransforms = function getRotateTransforms(e, t, r, n, i, o, a, c) {
        var l = _objectSpread({}, copyImageTransforms(r), { rotation: n }),
            u = c ? limitImageTransformsToCropRect(e, t, l, "rotating") : l,
            s = getMinScale(t, i);
        return roundFloat(u.scale, 5) > roundFloat(s, 5)
            ? (a && (o += 2 * a),
              _objectSpread({}, copyImageTransforms(r), {
                  rotation: o,
                  interaction: { rotation: u.rotation },
              }))
            : ((u.scale = Math.min(s, u.scale)),
              (u.interaction = { rotation: u.rotation }),
              u);
    },
    getResizeTransforms = function getResizeTransforms(e, t, r, n, i, o) {
        var a = Math.max(1e-10, n),
            c = _objectSpread({}, copyImageTransforms(r), { scale: a }),
            l = o ? limitImageTransformsToCropRect(e, t, c, "resizing") : c,
            u = getMinScale(t, i);
        return (
            (l.scale = Math.min(u, l.scale)), (l.interaction = { scale: a }), l
        );
    },
    getTranslateTransforms = function getTranslateTransforms(e, t, r, n, i) {
        var o = { x: r.translation.x + n.x, y: r.translation.y + n.y },
            a = _objectSpread({}, copyImageTransforms(r), { translation: o }),
            c = i ? limitImageTransformsToCropRect(e, t, a, "moving") : a;
        return (c.interaction = { translation: o }), c;
    },
    correctCropRectangleByResize = function correctCropRectangleByResize(e) {
        if (e.crop.draft.targetSize >= e.crop.draft.transforms.scale) return !1;
        if (null !== e.crop.aspectRatio) return !1;
        if (!1 === e.crop.limitToImageBounds) return !1;
        if (0 !== roundFloat(e.crop.rotation.sub, 5)) return !1;
        var t = !(roundFloat(e.crop.rotation.main / TURN$1, 5) % 2 == 0)
                ? e.image.width / e.image.height
                : e.image.height / e.image.width,
            r = e.crop.rectangle.height / e.crop.rectangle.width;
        if (t === r) return !1;
        var n = getCenteredCropRect(e.stage, r);
        return (
            !!rectEqualsRect(n, e.crop.rectangle) &&
            ((e.crop.rectangle = getCenteredCropRect(e.stage, t)), !0)
        );
    },
    loadImage$1 = function loadImage$1(e) {
        return new Promise(function (t, r) {
            if (isString(e)) {
                var n = null;
                fetch(e)
                    .then(function (e) {
                        if (
                            (e.headers && (n = e.headers.get("Content-Type")),
                            e.ok)
                        )
                            return e.blob();
                        r(e);
                    })
                    .then(function (i) {
                        if (!i) return r(e);
                        !i.type.length && n && (i = i.slice(0, i.size, n)),
                            t(i);
                    })
                    .catch(r);
            } else if (e instanceof Blob) t(e);
            else {
                if ("IMG" === e.nodeName) {
                    var i = function i(e) {
                        var r = document.createElement("canvas");
                        (r.width = e.naturalWidth),
                            (r.height = e.naturalHeight),
                            r.getContext("2d").drawImage(e, 0, 0),
                            r.toBlob(t);
                    };
                    return e.complete
                        ? void i(e)
                        : void (e.onload = function () {
                              return i(e);
                          });
                }
                "CANVAS" !== e.nodeName ? r(e) : e.toBlob(t);
            }
        });
    },
    shouldAbortImageLoad = function shouldAbortImageLoad(e) {
        return !1 === e.file;
    },
    actions = function actions(e, t, r) {
        return _objectSpread(
            {
                SET_UID: function SET_UID(e) {
                    var t = e.id;
                    r.uid = t;
                },
                AWAIT_IMAGE: function AWAIT_IMAGE() {
                    r.file ||
                        (r.noImageTimeout = setTimeout(function () {
                            e("AWAITING_IMAGE");
                        }, 250));
                },
                REQUEST_REMOVE_IMAGE: function REQUEST_REMOVE_IMAGE() {
                    e("UNLOAD_IMAGE"),
                        (r.file = !1),
                        (r.noImageTimeout = setTimeout(function () {
                            e("AWAITING_IMAGE");
                        }, 500));
                },
                DID_UNLOAD_IMAGE: function DID_UNLOAD_IMAGE() {
                    e("ABORT_IMAGE");
                },
                REQUEST_ABORT_IMAGE: function REQUEST_ABORT_IMAGE(t) {
                    e("UNLOAD_IMAGE"), (r.file = !1), (r.queuedFile = t);
                },
                DID_SET_SRC: function DID_SET_SRC(t) {
                    t.value !== t.prevValue &&
                        (clearTimeout(r.noImageTimeout),
                        e("REQUEST_LOAD_IMAGE", { source: t.value }));
                },
                ABORT_IMAGE: function ABORT_IMAGE() {
                    if (((r.file = null), r.queuedFile)) {
                        var t = r.queuedFile;
                        (r.queuedFile = null), e("REQUEST_LOAD_IMAGE", t);
                    }
                },
                REQUEST_LOAD_IMAGE: function REQUEST_LOAD_IMAGE(t) {
                    var n = t.source,
                        i = t.success,
                        o = void 0 === i ? function () {} : i,
                        a = t.failure,
                        c = void 0 === a ? function (e) {} : a,
                        l = t.options,
                        u = t.resolveOnConfirm,
                        s = void 0 !== u && u;
                    if ((clearTimeout(r.noImageTimeout), !n))
                        return c("no-image-source");
                    null === r.file
                        ? (resetState(r),
                          (r.file = { uid: getUniqueId() }),
                          e("DID_REQUEST_LOAD_IMAGE", { source: n }),
                          loadImage$1(n)
                              .then(function (t) {
                                  if (shouldAbortImageLoad(r))
                                      return e("ABORT_IMAGE");
                                  t.name || (t.name = getDateString()),
                                      (r.file.orientation = -1),
                                      (r.file.data = t),
                                      e(
                                          "LOAD_IMAGE",
                                          {
                                              success: o,
                                              failure: c,
                                              options: l,
                                              resolveOnConfirm: s,
                                          },
                                          !0
                                      ),
                                      e("KICK");
                              })
                              .catch(function (t) {
                                  if (shouldAbortImageLoad(r))
                                      return e("ABORT_IMAGE");
                                  e("DID_LOAD_IMAGE_ERROR", {
                                      error: {
                                          status: "IMAGE_LOAD_ERROR",
                                          data: t,
                                      },
                                  }),
                                      c(t);
                              }))
                        : e("REQUEST_ABORT_IMAGE", {
                              source: n,
                              success: o,
                              failure: c,
                              options: l,
                              resolveOnConfirm: s,
                          });
                },
                LOAD_IMAGE: function LOAD_IMAGE(n) {
                    var i = n.success,
                        o = n.failure,
                        a = n.options,
                        c = void 0 === a ? {} : a,
                        l = n.resolveOnConfirm;
                    if (shouldAbortImageLoad(r)) return e("ABORT_IMAGE");
                    var u = r.file.data;
                    Promise.all([getImageSize(u), getImageOrientation(u)])
                        .then(function (n) {
                            var a = _slicedToArray(n, 2),
                                u = a[0],
                                s = a[1];
                            if (shouldAbortImageLoad(r))
                                return e("ABORT_IMAGE");
                            if (
                                ((r.file.orientation = t(
                                    "GET_OUTPUT_CORRECT_IMAGE_EXIF_ORIENTATION"
                                )
                                    ? s
                                    : -1),
                                r.file.orientation > -1)
                            ) {
                                var d = u.width,
                                    f = u.height;
                                s >= 5 &&
                                    s <= 8 &&
                                    ((u.width = f), (u.height = d));
                            }
                            var p = t("GET_MIN_IMAGE_SIZE");
                            if (u.width < p.width || u.height < p.height)
                                return (
                                    e("DID_LOAD_IMAGE_ERROR", {
                                        error: {
                                            status: "IMAGE_MIN_SIZE_VALIDATION_ERROR",
                                            data: { size: u, minImageSize: p },
                                        },
                                    }),
                                    resetState(r),
                                    void o()
                                );
                            var h = scaleImageSize(u, {
                                width: t("GET_MAX_IMAGE_PREVIEW_WIDTH"),
                                height: t("GET_MAX_IMAGE_PREVIEW_HEIGHT"),
                            });
                            if (
                                ((r.image = {
                                    x: 0,
                                    y: 0,
                                    width: h.width,
                                    height: h.height,
                                    naturalWidth: u.width,
                                    naturalHeight: u.height,
                                    aspectRatio: u.height / u.width,
                                }),
                                t("ALLOW_MANUAL_RESIZE") &&
                                    c.size &&
                                    ((r.size.width = c.size.width),
                                    (r.size.height = c.size.height),
                                    (r.size.aspectRatioLocked = !0),
                                    (r.size.aspectRatioPrevious = !1),
                                    (r.instructions.size = {
                                        width: c.size.width,
                                        height: c.size.height,
                                    })),
                                (r.instructions.crop = getBaseCropInstructions(
                                    t,
                                    r,
                                    c.crop ? _objectSpread({}, c.crop) : null,
                                    r.size
                                )),
                                (r.crop.limitToImageBounds =
                                    r.options.cropLimitToImageBounds),
                                !1 === r.instructions.crop.scaleToFit &&
                                    (r.crop.limitToImageBounds =
                                        r.instructions.crop.scaleToFit),
                                void 0 === c.filter)
                            )
                                r.instructions.filter = r.options.filter;
                            else {
                                var g = c.filter;
                                r.instructions.filter =
                                    null === g ? g : g.id || g.matrix || g;
                            }
                            var m = r.options.markup || [];
                            (r.instructions.markup = m.concat(c.markup || [])),
                                (r.instructions.color = Object.keys(
                                    COLOR_TOOLS
                                ).reduce(function (e, t) {
                                    return (
                                        (e[t] =
                                            c.color && void 0 !== c.color[t]
                                                ? "number" == typeof c.color[t]
                                                    ? c.color[t]
                                                    : c.color[t].value
                                                : r.options[
                                                      "color".concat(
                                                          capitalizeFirstLetter(
                                                              t
                                                          )
                                                      )
                                                  ]),
                                        e
                                    );
                                }, {})),
                                e("DID_LOAD_IMAGE", {
                                    image: _objectSpread(
                                        {
                                            size: r.file.data.size,
                                            name: r.file.data.name,
                                            type: r.file.data.type,
                                            orientation: s,
                                        },
                                        u
                                    ),
                                }),
                                (r.filePromise = {
                                    resolveOnConfirm: l,
                                    success: i,
                                    failure: o,
                                });
                        })
                        .catch(function (t) {
                            if (shouldAbortImageLoad(r))
                                return e("ABORT_IMAGE");
                            e("DID_LOAD_IMAGE_ERROR", {
                                error: {
                                    status: "IMAGE_UNKNOWN_ERROR",
                                    data: t,
                                },
                            }),
                                resetState(r),
                                o();
                        });
                },
                CHANGE_VIEW: function CHANGE_VIEW(t) {
                    var n = t.id;
                    (r.activeView = n), e("SHOW_VIEW", { id: n });
                },
                UPDATE_ROOT_RECT: function UPDATE_ROOT_RECT(e) {
                    var t = e.rect;
                    r.rootRect = t;
                },
                DID_RESIZE_STAGE: function DID_RESIZE_STAGE(n) {
                    var i = n.size,
                        o = n.offset,
                        a = n.animate,
                        c = null === r.stage;
                    if (
                        ((r.stage = createRect(0, 0, i.width, i.height)),
                        (r.stageOffset = createVector(o.x, o.y)),
                        !t("GET_ALLOW_PREVIEW_FIT_TO_VIEW"))
                    ) {
                        var l = Math.min(i.width, r.image.naturalWidth),
                            u = Math.min(i.height, r.image.naturalHeight);
                        (r.stage = createRect(0, 0, l, u)),
                            (r.stageOffset = createVector(
                                o.x + 0.5 * i.width - 0.5 * l,
                                o.y + 0.5 * i.height - 0.5 * u
                            ));
                    }
                    if (c) {
                        if ((reset(r, t, e), !r.filePromise.resolveOnConfirm)) {
                            var s = getCropFromStateRounded(r.image, r.crop),
                                d = getOutputSize(t);
                            r.filePromise.success({
                                crop: s,
                                image: { orientation: r.file.orientation },
                                size: d,
                                output: {
                                    type: t("GET_OUTPUT_TYPE"),
                                    quality: t("GET_OUTPUT_QUALITY"),
                                },
                            });
                        }
                    } else
                        (r.instantUpdate = !a),
                            recenter(r, t),
                            setTimeout(function () {
                                r.instantUpdate = !1;
                            }, 16);
                },
                RESIZE_SET_OUTPUT_SIZE_ASPECT_RATIO_LOCK:
                    function RESIZE_SET_OUTPUT_SIZE_ASPECT_RATIO_LOCK(e) {
                        var t = e.value;
                        r.size.aspectRatioLocked = t;
                    },
                RESIZE_SET_OUTPUT_SIZE: function RESIZE_SET_OUTPUT_SIZE(n) {
                    var i = n.width,
                        o = n.height,
                        a = limitSize(
                            { width: (i = i || null), height: (o = o || null) },
                            t("GET_SIZE_MIN"),
                            t("GET_SIZE_MAX"),
                            t("GET_CROP_RECTANGLE_ASPECT_RATIO")
                        );
                    if (
                        ((r.size.width = a.width ? Math.round(a.width) : null),
                        (r.size.height = a.height
                            ? Math.round(a.height)
                            : null),
                        i && o)
                    ) {
                        var c = o / i;
                        if (c === r.crop.aspectRatio) return;
                        !1 === r.size.aspectRatioPrevious &&
                            (r.size.aspectRatioPrevious = r.crop.aspectRatio),
                            e("CROP_SET_ASPECT_RATIO", { value: c });
                    } else
                        !1 !== r.size.aspectRatioPrevious &&
                            (e("CROP_SET_ASPECT_RATIO", {
                                value: r.size.aspectRatioPrevious,
                            }),
                            (r.size.aspectRatioPrevious = !1));
                },
                CROP_SET_ASPECT_RATIO: function CROP_SET_ASPECT_RATIO(e) {
                    var n = e.value;
                    if (
                        (clearCenterTimeout(r),
                        (r.crop.aspectRatio = n),
                        r.crop.aspectRatio && recenter(r, t),
                        (r.crop.isDirty = !0),
                        r.size.width && r.size.height)
                    )
                        if (r.crop.aspectRatio) {
                            var i = r.size.width * r.crop.aspectRatio,
                                o = limit(
                                    i,
                                    t("GET_SIZE_MIN").height,
                                    t("GET_SIZE_MAX").height
                                );
                            (r.size.height = o),
                                (r.size.width = o / r.crop.aspectRatio);
                        } else r.size.height = null;
                },
                DID_SET_CROP_ASPECT_RATIO: function DID_SET_CROP_ASPECT_RATIO(
                    t
                ) {
                    var r = t.value,
                        n = t.prevValue;
                    getNumericAspectRatioFromString(r) !==
                        getNumericAspectRatioFromString(n) &&
                        e("CROP_SET_ASPECT_RATIO", { value: r });
                },
                CROP_ZOOM: function CROP_ZOOM() {
                    r.stage && (clearCenterTimeout(r), recenter(r, t));
                },
                DID_SET_CROP_LIMIT_TO_IMAGE_BOUNDS:
                    function DID_SET_CROP_LIMIT_TO_IMAGE_BOUNDS(t) {
                        var n = t.value,
                            i = t.prevValue;
                        (r.crop.limitToImageBounds = n),
                            !1 === i &&
                                n &&
                                e("CROP_ENABLED_LIMIT_TO_IMAGE_BOUNDS");
                    },
                CROP_ENABLED_LIMIT_TO_IMAGE_BOUNDS:
                    function CROP_ENABLED_LIMIT_TO_IMAGE_BOUNDS() {
                        var e = r.stage,
                            n = r.image,
                            i =
                                r.crop.rectangle.height /
                                r.crop.rectangle.width,
                            o = getCenteredCropRect(e, i);
                        (r.crop.rectangle = o),
                            (r.crop.transforms = limitImageTransformsToCropRect(
                                n,
                                r.crop.rectangle,
                                r.crop.transforms,
                                "moving"
                            )),
                            (r.crop.transforms = limitImageTransformsToCropRect(
                                n,
                                r.crop.rectangle,
                                r.crop.transforms,
                                "resizing"
                            )),
                            (r.crop.transforms = limitImageTransformsToCropRect(
                                n,
                                r.crop.rectangle,
                                r.crop.transforms,
                                "rotating"
                            )),
                            (r.crop.draft.rectangle = null),
                            (r.crop.draft.transforms = null),
                            recenter(r, t);
                    },
                CROP_SET_LIMIT: function CROP_SET_LIMIT(t) {
                    var n = t.value,
                        i = t.silent,
                        o = void 0 !== i && i,
                        a = r.crop.limitToImageBounds !== n;
                    (r.crop.limitToImageBounds = n),
                        a && !o && (r.crop.isDirty = !0),
                        a && n && e("CROP_ENABLED_LIMIT_TO_IMAGE_BOUNDS");
                },
                CROP_IMAGE_RESIZE_GRAB: function CROP_IMAGE_RESIZE_GRAB() {
                    copyConfirmed(r), clearCenterTimeout(r);
                },
                CROP_IMAGE_ROTATE_GRAB: function CROP_IMAGE_ROTATE_GRAB() {
                    copyConfirmed(r),
                        clearCenterTimeout(r),
                        (r.crop.isRotating = !0);
                },
                CROP_RECT_DRAG_GRAB: function CROP_RECT_DRAG_GRAB() {
                    copyConfirmed(r), clearCenterTimeout(r);
                },
                CROP_RECT_DRAG_RELEASE: function CROP_RECT_DRAG_RELEASE() {
                    confirmCropDraft(r), startCenterTimeout(r, t, e);
                },
                CROP_RECT_EDGE_DRAG: function CROP_RECT_EDGE_DRAG(e) {
                    var n = e.offset,
                        i = e.origin,
                        o = e.anchor,
                        a = r.image,
                        c = r.stage,
                        l = /n|s/.test(i)
                            ? Direction.VERTICAL
                            : Direction.HORIZONTAL,
                        u = getEdgeCenterCoordinates(i, r.crop.rectangle),
                        s = getEdgeCenterCoordinates(o, r.crop.rectangle),
                        d = vectorLimit(
                            {
                                x: u.x + (l === Direction.HORIZONTAL ? n.x : 0),
                                y: u.y + (l === Direction.VERTICAL ? n.y : 0),
                            },
                            c
                        ),
                        f = t("GET_MIN_CROP_SIZE"),
                        p = t("GET_MAX_CROP_SIZE");
                    (f.width = roundFloat(f.width)),
                        (f.height = roundFloat(f.height));
                    var h =
                        getMinScale(
                            r.crop.rectangle,
                            t("GET_MIN_PREVIEW_IMAGE_SIZE")
                        ) /
                        (r.crop.draft.transforms.scale ||
                            r.crop.transforms.scale);
                    (p.width = roundFloat(p.width * h)),
                        (p.height = roundFloat(p.height * h));
                    var g = {
                        x: Math.sign(u.x - s.x),
                        y: Math.sign(u.y - s.y),
                    };
                    (r.crop.draft.rectangle = getEdgeTargetRect(
                        g,
                        d,
                        l,
                        s,
                        rectBounds(r.crop.rectangle),
                        rectBounds(c),
                        r.crop.aspectRatio,
                        f,
                        p
                    )),
                        r.crop.limitToImageBounds &&
                            (r.crop.draft.transforms =
                                getImageTransformsFromRect(
                                    a,
                                    r.crop.draft.rectangle.limited,
                                    r.crop.transforms
                                ));
                },
                CROP_RECT_CORNER_DRAG: function CROP_RECT_CORNER_DRAG(e) {
                    var n = e.offset,
                        i = e.origin,
                        o = e.anchor,
                        a = r.image,
                        c = r.stage,
                        l = getCornerCoordinates(i, r.crop.rectangle),
                        u = getCornerCoordinates(o, r.crop.rectangle),
                        s = { x: l.x + n.x, y: l.y + n.y },
                        d = t("GET_MIN_CROP_SIZE"),
                        f = t("GET_MAX_CROP_SIZE");
                    (d.width = roundFloat(d.width)),
                        (d.height = roundFloat(d.height));
                    var p =
                        getMinScale(
                            r.crop.rectangle,
                            t("GET_MIN_PREVIEW_IMAGE_SIZE")
                        ) /
                        (r.crop.draft.transforms.scale ||
                            r.crop.transforms.scale);
                    (f.width = roundFloat(f.width * p)),
                        (f.height = roundFloat(f.height * p));
                    var h = {
                        x: Math.sign(l.x - u.x),
                        y: Math.sign(l.y - u.y),
                    };
                    (r.crop.draft.rectangle = getCornerTargetRect(
                        h,
                        s,
                        u,
                        c,
                        r.crop.aspectRatio,
                        d,
                        f
                    )),
                        r.crop.limitToImageBounds &&
                            (r.crop.draft.transforms =
                                getImageTransformsFromRect(
                                    a,
                                    r.crop.draft.rectangle.limited,
                                    r.crop.transforms
                                ));
                },
                CROP_IMAGE_DRAG_GRAB: function CROP_IMAGE_DRAG_GRAB() {
                    return copyConfirmed(r) || clearCenterTimeout(r);
                },
                CROP_IMAGE_DRAG_RELEASE: function CROP_IMAGE_DRAG_RELEASE() {
                    confirmImageDraft(r),
                        resetRotationScale(r),
                        startCenterTimeout(r, t, e);
                },
                CROP_IMAGE_ROTATE_RELEASE:
                    function CROP_IMAGE_ROTATE_RELEASE() {
                        confirmImageDraft(r), startCenterTimeout(r, t, e);
                    },
                CROP_IMAGE_DRAG: function CROP_IMAGE_DRAG(e) {
                    var t = e.value;
                    clearCenterTimeout(r),
                        (r.crop.draft.transforms = getTranslateTransforms(
                            r.image,
                            r.crop.rectangle,
                            r.crop.transforms,
                            t,
                            r.crop.limitToImageBounds
                        ));
                },
                CROP_IMAGE_RESIZE_RELEASE:
                    function CROP_IMAGE_RESIZE_RELEASE() {
                        t("GET_CROP_RESIZE_MATCH_IMAGE_ASPECT_RATIO") &&
                            correctCropRectangleByResize(r),
                            confirmImageDraft(r),
                            resetRotationScale(r),
                            startCenterTimeout(r, t, e);
                    },
                CROP_IMAGE_RESIZE: function CROP_IMAGE_RESIZE(e) {
                    var n = e.value;
                    clearCenterTimeout(r);
                    var i = r.crop.transforms;
                    (r.crop.draft.targetSize = i.scale + i.scale * n),
                        (r.crop.draft.transforms = getResizeTransforms(
                            r.image,
                            r.crop.rectangle,
                            i,
                            r.crop.draft.targetSize,
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            r.crop.limitToImageBounds
                        ));
                },
                CROP_IMAGE_RESIZE_MULTIPLY: function CROP_IMAGE_RESIZE_MULTIPLY(
                    e
                ) {
                    var n = e.value;
                    clearCenterTimeout(r);
                    var i = r.crop.transforms;
                    (r.crop.draft.targetSize = i.scale * n),
                        (r.crop.draft.transforms = getResizeTransforms(
                            r.image,
                            r.crop.rectangle,
                            i,
                            r.crop.draft.targetSize,
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            r.crop.limitToImageBounds
                        ));
                },
                CROP_IMAGE_RESIZE_AMOUNT: function CROP_IMAGE_RESIZE_AMOUNT(e) {
                    var n = e.value;
                    clearCenterTimeout(r);
                    var i = r.crop.transforms;
                    (r.crop.draft.targetSize =
                        (r.crop.draft.transforms
                            ? r.crop.draft.transforms.scale
                            : i.scale) + n),
                        (r.crop.draft.transforms = getResizeTransforms(
                            r.image,
                            r.crop.rectangle,
                            i,
                            r.crop.draft.targetSize,
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            r.crop.limitToImageBounds
                        ));
                },
                CROP_IMAGE_ROTATE: function CROP_IMAGE_ROTATE(e) {
                    var n = e.value;
                    clearCenterTimeout(r),
                        (r.crop.isRotating = !0),
                        rotate(
                            r,
                            { main: r.crop.rotation.main, sub: n },
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            !1,
                            r.crop.limitToImageBounds
                        );
                },
                CROP_IMAGE_ROTATE_ADJUST: function CROP_IMAGE_ROTATE_ADJUST(e) {
                    var n = e.value;
                    clearCenterTimeout(r),
                        rotate(
                            r,
                            {
                                main: r.crop.rotation.main,
                                sub: Math.min(
                                    Math.PI / 4,
                                    Math.max(
                                        -Math.PI / 4,
                                        r.crop.rotation.sub + n
                                    )
                                ),
                            },
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            !1,
                            r.crop.limitToImageBounds
                        ),
                        confirmImageDraft(r);
                },
                CROP_IMAGE_ROTATE_CENTER: function CROP_IMAGE_ROTATE_CENTER() {
                    clearCenterTimeout(r),
                        rotate(
                            r,
                            { main: r.crop.rotation.main, sub: 0 },
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            !1,
                            r.crop.limitToImageBounds
                        ),
                        confirmImageDraft(r);
                },
                CROP_IMAGE_ROTATE_LEFT: function CROP_IMAGE_ROTATE_LEFT() {
                    resetCenterTimeout(r, t, e),
                        rotate(
                            r,
                            {
                                main: r.crop.rotation.main - TURN$1,
                                sub: r.crop.rotation.sub,
                            },
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            -TURN$1,
                            r.crop.limitToImageBounds
                        ),
                        confirmImageDraft(r),
                        t("GET_CROP_FORCE_LETTERBOX") &&
                            e("CROP_UPDATE_LETTERBOX");
                },
                CROP_IMAGE_ROTATE_RIGHT: function CROP_IMAGE_ROTATE_RIGHT() {
                    resetCenterTimeout(r, t, e),
                        rotate(
                            r,
                            {
                                main: r.crop.rotation.main + TURN$1,
                                sub: r.crop.rotation.sub,
                            },
                            t("GET_MIN_PREVIEW_IMAGE_SIZE"),
                            TURN$1,
                            r.crop.limitToImageBounds
                        ),
                        confirmImageDraft(r),
                        t("GET_CROP_FORCE_LETTERBOX") &&
                            e("CROP_UPDATE_LETTERBOX");
                },
                CROP_IMAGE_FLIP_HORIZONTAL:
                    function CROP_IMAGE_FLIP_HORIZONTAL() {
                        resetCenterTimeout(r, t, e),
                            0 ===
                            roundFloat((r.crop.rotation.main % Math.PI) / 2, 5)
                                ? (r.crop.flip.horizontal =
                                      !r.crop.flip.horizontal)
                                : (r.crop.flip.vertical =
                                      !r.crop.flip.vertical),
                            (r.crop.isDirty = !0);
                    },
                CROP_IMAGE_FLIP_VERTICAL: function CROP_IMAGE_FLIP_VERTICAL() {
                    resetCenterTimeout(r, t, e),
                        0 ===
                        roundFloat((r.crop.rotation.main % Math.PI) / 2, 5)
                            ? (r.crop.flip.vertical = !r.crop.flip.vertical)
                            : (r.crop.flip.horizontal =
                                  !r.crop.flip.horizontal),
                        (r.crop.isDirty = !0);
                },
                DID_RECEIVE_IMAGE_DATA: function DID_RECEIVE_IMAGE_DATA(e) {
                    var t = e.previewData,
                        n = e.thumbData;
                    (r.file.preview = t), (r.file.thumb = n);
                },
                MARKUP_SET_VALUE: function MARKUP_SET_VALUE(e) {
                    var t = e.value;
                    r.markup = t;
                },
                MARKUP_ADD: function MARKUP_ADD(t) {
                    r.markup.forEach(function (e) {
                        return (e[1].isSelected = !1);
                    }),
                        (r.markup = r.markup.filter(function (e) {
                            return !e[1].isDestroyed;
                        }));
                    var n = prepareMarkup(t);
                    r.markup.push(n),
                        r.markup.sort(sortMarkupByZIndex),
                        e("MARKUP_SELECT", { id: n[1].id }),
                        (r.crop.isDirty = !0);
                },
                MARKUP_SELECT: function MARKUP_SELECT(e) {
                    var t = e.id;
                    r.markup.forEach(function (e) {
                        (e[1].isSelected = e[1].id === t), (e[1].isDirty = !0);
                    });
                },
                MARKUP_ELEMENT_DRAG: function MARKUP_ELEMENT_DRAG(e) {
                    var t = e.id,
                        n = e.origin,
                        i = e.offset,
                        o = e.size,
                        a = r.markup.find(function (e) {
                            return e[1].id === t;
                        });
                    if (a) {
                        var c = a[1],
                            l = n.x / o.width,
                            u = n.y / o.height,
                            s = n.width / o.width,
                            d = n.height / o.height,
                            f = i.x / o.width,
                            p = i.y / o.height;
                        (c.x = l + f),
                            (c.y = u + p),
                            (c.width = s),
                            (c.height = d),
                            (c.left = void 0),
                            (c.top = void 0),
                            (c.right = void 0),
                            (c.bottom = void 0),
                            (c.isDirty = !0),
                            (r.crop.isDirty = !0);
                    }
                },
                MARKUP_ELEMENT_RESIZE: function MARKUP_ELEMENT_RESIZE(e) {
                    var t = e.id,
                        n = e.corner,
                        i = e.origin,
                        o = e.offset,
                        a = e.size,
                        c = r.markup.find(function (e) {
                            return e[1].id === t;
                        });
                    if (c) {
                        var l = _slicedToArray(c, 2),
                            u = l[0],
                            s = l[1],
                            d = (i.x + o.x) / a.width,
                            f = (i.y + o.y) / a.height;
                        if (/n/.test(n))
                            if ("line" === u)
                                (s.height = s.height - (f - s.y)), (s.y = f);
                            else {
                                var p = s.y + s.height;
                                f > p && (f = p),
                                    (s.height = s.height - (f - s.y)),
                                    (s.y = f);
                            }
                        if (/w/.test(n))
                            if ("line" === u)
                                (s.width = s.width - (d - s.x)), (s.x = d);
                            else {
                                var h = s.x + s.width;
                                d > h && (d = h),
                                    (s.width = s.width - (d - s.x)),
                                    (s.x = d);
                            }
                        /s/.test(n) &&
                            (s.height =
                                "line" === u ? f - s.y : Math.max(0, f - s.y)),
                            /e/.test(n) &&
                                (s.width =
                                    "line" === u
                                        ? d - s.x
                                        : Math.max(0, d - s.x)),
                            (s.left = void 0),
                            (s.top = void 0),
                            (s.right = void 0),
                            (s.bottom = void 0),
                            (s.isDirty = !0),
                            (r.crop.isDirty = !0);
                    }
                },
                MARKUP_DELETE: function MARKUP_DELETE(t) {
                    var n = t.id,
                        i = r.markup.find(function (e) {
                            return e[1].id === n;
                        });
                    if (i) {
                        var o = i[1];
                        o.allowDestroy &&
                            ((o.isDestroyed = !0),
                            (o.isSelected = !1),
                            (o.isDirty = !0));
                        for (var a = null, c = r.markup.length; c > 0; ) {
                            c--;
                            var l = r.markup[c][1];
                            if (!l.isDestroyed && l.allowDestroy) {
                                a = l.id;
                                break;
                            }
                        }
                        e("MARKUP_SELECT", { id: a });
                    }
                },
                MARKUP_UPDATE: function MARKUP_UPDATE(e) {
                    var t = e.id,
                        n = e.props,
                        i = r.markup.find(function (e) {
                            return e[1].id === t;
                        });
                    if (i) {
                        var o = i[1];
                        Object.keys(n).forEach(function (e) {
                            o[e] = n[e];
                        }),
                            (o.isDirty = !0),
                            (r.crop.isDirty = !0);
                    }
                },
                COLOR_SET_COLOR_VALUE: function COLOR_SET_COLOR_VALUE(t) {
                    var n = t.key,
                        i = t.value;
                    (r.crop.isDirty = !0),
                        e("COLOR_SET_VALUE", { key: n, value: i });
                },
                COLOR_SET_VALUE: function COLOR_SET_VALUE(t) {
                    var n = t.key,
                        i = t.value;
                    (r.colorValues[n] = i),
                        e("SET_COLOR_MATRIX", {
                            key: n,
                            matrix: COLOR_TOOLS[n](i),
                        });
                },
            },
            Object.keys(COLOR_TOOLS).reduce(function (n, i) {
                var o = i.toUpperCase(),
                    a = capitalizeFirstLetter(i);
                return (
                    (n["SET_COLOR_".concat(o)] = function (n) {
                        var c = n.value;
                        if (c !== n.prevValue) {
                            var l = _slicedToArray(
                                    t("GET_COLOR_".concat(o, "_RANGE")),
                                    2
                                ),
                                u = l[0],
                                s = l[1],
                                d = limit(c, u, s);
                            (r.options["color".concat(a)] = d),
                                r.instructions.color ||
                                    (r.instructions.color = {}),
                                (r.instructions.color[i] = d),
                                e("COLOR_SET_VALUE", { key: i, value: d });
                        }
                    }),
                    n
                );
            }, {}),
            {
                SET_COLOR_MATRIX: function SET_COLOR_MATRIX(t) {
                    var n = t.key,
                        i = t.matrix;
                    i
                        ? (r.colorMatrices[n] = _toConsumableArray(i))
                        : delete r.colorMatrices[n],
                        e("DID_SET_COLOR_MATRIX", { key: n, matrix: i });
                },
                FILTER_SET_FILTER: function FILTER_SET_FILTER(t) {
                    var n = t.value;
                    (r.crop.isDirty = !0), e("FILTER_SET_VALUE", { value: n });
                },
                FILTER_SET_VALUE: function FILTER_SET_VALUE(n) {
                    var i = n.value,
                        o = isColorMatrix(i) ? i : null;
                    if (isString(i)) {
                        var a = t("GET_FILTERS");
                        forin(a, function (e, t) {
                            e === i && (o = t.matrix());
                        });
                    }
                    (r.filter = i),
                        (r.filterName = isString(i) ? i : null),
                        e("SET_COLOR_MATRIX", { key: "filter", matrix: o });
                },
                DID_SET_FILTER: function DID_SET_FILTER(t) {
                    var r = t.value;
                    r !== t.prevValue &&
                        (e("FILTER_SET_VALUE", { value: r }),
                        e("SET_DATA", { filter: r }));
                },
                DID_SET_SIZE: function DID_SET_SIZE(t) {
                    var r = t.value;
                    r !== t.prevValue && e("SET_DATA", { size: r });
                },
                DID_SET_CROP: function DID_SET_CROP(t) {
                    var r = t.value;
                    r !== t.prevValue && e("SET_DATA", { crop: r });
                },
                DID_SET_MARKUP: function DID_SET_MARKUP(t) {
                    var r = t.value,
                        n = t.prevValue;
                    (r !== n && JSON.stringify(r) === JSON.stringify(n)) ||
                        e("SET_DATA", { markup: r });
                },
                SET_DATA: function SET_DATA(n) {
                    if (n.size && t("ALLOW_MANUAL_RESIZE")) {
                        var i = _objectSpread(
                                { width: null, height: null },
                                n.size
                            ),
                            o = limitSize(
                                i,
                                t("GET_SIZE_MIN"),
                                t("GET_SIZE_MAX"),
                                null
                            );
                        (r.instructions.size = _objectSpread({}, o)),
                            e("RESIZE_SET_OUTPUT_SIZE", o);
                    }
                    n.filter && (r.instructions.filter = n.filter),
                        (r.instructions.markup = n.markup || []),
                        (r.instructions.color = Object.keys(COLOR_TOOLS).reduce(
                            function (e, t) {
                                return (
                                    (e[t] =
                                        void 0 === n.color ||
                                        void 0 === n.color[t]
                                            ? r.options[
                                                  "color".concat(
                                                      capitalizeFirstLetter(t)
                                                  )
                                              ]
                                            : n.color[t]),
                                    e
                                );
                            },
                            {}
                        )),
                        n.crop &&
                            ((r.instructions.crop = getBaseCropInstructions(
                                t,
                                r,
                                n.crop,
                                r.size
                            )),
                            (r.crop.limitToImageBounds =
                                r.options.cropLimitToImageBounds),
                            !1 === r.instructions.crop.scaleToFit &&
                                (r.crop.limitToImageBounds =
                                    r.instructions.crop.scaleToFit),
                            e("EDIT_RESET"));
                },
                DID_SET_INITIAL_STATE: function DID_SET_INITIAL_STATE(e) {
                    var n = e.value || {},
                        i = n.crop,
                        o = n.filter,
                        a = n.color,
                        c = n.size,
                        l = void 0 === c ? {} : c,
                        u = n.markup,
                        s = void 0 === u ? [] : u,
                        d = _objectSpread({ width: null, height: null }, l),
                        f = limitSize(
                            d,
                            t("GET_SIZE_MIN"),
                            t("GET_SIZE_MAX"),
                            null
                        );
                    (r.instructions.size = _objectSpread({}, f)),
                        (r.instructions.crop = getBaseCropInstructions(
                            t,
                            r,
                            i
                        )),
                        (r.crop.limitToImageBounds =
                            r.options.cropLimitToImageBounds),
                        !1 === r.instructions.crop.scaleToFit &&
                            (r.crop.limitToImageBounds =
                                r.instructions.crop.scaleToFit),
                        (r.instructions.filter = o || null),
                        (r.instructions.color = Object.keys(COLOR_TOOLS).reduce(
                            function (e, t) {
                                return (
                                    (e[t] =
                                        void 0 === a || void 0 === a[t]
                                            ? r.options[
                                                  "color".concat(
                                                      capitalizeFirstLetter(t)
                                                  )
                                              ]
                                            : a[t]),
                                    e
                                );
                            },
                            {}
                        )),
                        (r.instructions.markup = s),
                        (r.crop.isDirty = !0);
                },
                GET_DATA: function GET_DATA(n) {
                    var i = n.success,
                        o = n.failure,
                        a = n.file,
                        c = n.data;
                    if (!r.file) return o("no-image-source");
                    if (!r.stage) return o("image-not-fully-loaded");
                    var l = {
                        file: isBoolean(a) ? a : t("GET_OUTPUT_FILE"),
                        data: isBoolean(c) ? c : t("GET_OUTPUT_DATA"),
                        success: i,
                        failure: o,
                    };
                    e(l.file ? "REQUEST_PREPARE_OUTPUT" : "PREPARE_OUTPUT", l);
                },
                REQUEST_PREPARE_OUTPUT: function REQUEST_PREPARE_OUTPUT(t) {
                    var r = t.file,
                        n = t.data,
                        i = t.success,
                        o = t.failure;
                    e(
                        "PREPARE_OUTPUT",
                        { file: r, data: n, success: i, failure: o },
                        !0
                    ),
                        e("DID_REQUEST_PREPARE_OUTPUT");
                },
                PREPARE_OUTPUT: function PREPARE_OUTPUT(n) {
                    var i = n.file,
                        o = n.data,
                        a = n.success,
                        c = void 0 === a ? function () {} : a,
                        l = n.failure,
                        u = void 0 === l ? function () {} : l;
                    if (shouldAbortImageLoad(r)) return e("ABORT_IMAGE");
                    prepareOutput({ file: i, data: o }, r, t)
                        .then(function (t) {
                            if (
                                (e("DID_PREPARE_OUTPUT"),
                                shouldAbortImageLoad(r))
                            )
                                return e("ABORT_IMAGE");
                            c(t);
                        })
                        .catch(function (t) {
                            if (shouldAbortImageLoad(r))
                                return e("ABORT_IMAGE");
                            u(t);
                        });
                },
                EDIT_RESET: function EDIT_RESET() {
                    clearCenterTimeout(r), reset(r, t, e);
                },
                EDIT_CONFIRM: function EDIT_CONFIRM() {
                    if (r.file && r.stage) {
                        clearCenterTimeout(r), e("CROP_ZOOM");
                        var n = {
                            file: t("GET_OUTPUT_FILE"),
                            data: t("GET_OUTPUT_DATA"),
                            success: function success(t) {
                                r.filePromise.resolveOnConfirm &&
                                    r.filePromise.success(t),
                                    e("DID_CONFIRM", { output: t });
                            },
                            failure: console.error,
                        };
                        e(
                            n.file
                                ? "REQUEST_PREPARE_OUTPUT"
                                : "PREPARE_OUTPUT",
                            n
                        );
                    }
                },
                EDIT_CANCEL: function EDIT_CANCEL() {
                    r.filePromise && r.filePromise.success(null),
                        e("DID_CANCEL");
                },
                EDIT_CLOSE: function EDIT_CLOSE() {
                    clearCenterTimeout(r);
                },
                EDIT_DESTROY: function EDIT_DESTROY() {
                    resetState(r);
                },
                SET_OPTIONS: function SET_OPTIONS(t) {
                    var r = t.options;
                    forin(r, function (t, r) {
                        e("SET_".concat(fromCamels(t, "_").toUpperCase()), {
                            value: r,
                        });
                    });
                },
            }
        );
    },
    wrapper = function wrapper(e, t) {
        return createView({
            ignoreRect: !0,
            name: e,
            mixins: t,
            create: function create(e) {
                var t = e.root,
                    r = e.props;
                r.className && t.element.classList.add(r.className),
                    r.controls.map(function (e) {
                        var r = t.createChildView(e.view, e);
                        e.didCreateView && e.didCreateView(r),
                            t.appendChildView(r);
                    });
            },
        });
    },
    button = createView({
        ignoreRect: !0,
        ignoreRectUpdate: !0,
        name: "button",
        mixins: {
            styles: ["opacity"],
            animations: { opacity: { type: "tween", duration: 250 } },
            apis: ["id"],
            listeners: !0,
        },
        tag: "button",
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (t.element.innerHTML = ""
                .concat(r.icon || "", "<span>")
                .concat(r.label, "</span>")),
                t.element.setAttribute("type", r.type || "button"),
                r.name &&
                    r.name.split(" ").forEach(function (e) {
                        t.element.className += " doka--button-".concat(e);
                    }),
                (t.ref.handleClick = function () {
                    "string" == typeof r.action
                        ? t.dispatch(r.action)
                        : r.action();
                }),
                t.element.addEventListener("click", t.ref.handleClick),
                r.create && r.create({ root: t, props: r });
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.element.removeEventListener("click", t.ref.handleClick);
        },
    }),
    createIcon = function createIcon(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 24;
        return '<svg width="'
            .concat(t, '" height="')
            .concat(t, '" viewBox="0 0 ')
            .concat(t, " ")
            .concat(
                t,
                '" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">'
            )
            .concat(e, "</svg>");
    },
    textNode = function textNode(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
        return createView({
            ignoreRect: !0,
            tag: e,
            create: function create(e) {
                e.root.element.textContent = t;
            },
        });
    },
    hideBusyIndicators = function hideBusyIndicators(e) {
        (e.element.dataset.viewStatus = "idle"), hideBusyIndicatorsAnimated(e);
    },
    hideBusyIndicatorsAnimated = function hideBusyIndicatorsAnimated(e) {
        e.ref.busyIndicators.forEach(function (e) {
            (e.translateY = -10), (e.opacity = 0), (e.markedForRemoval = !0);
        });
    },
    showBusyIndicator = function showBusyIndicator(e, t, r) {
        e.element.dataset.viewStatus = "busy";
        var n = addBusyIndicator(e, t, r);
        hideBusyIndicatorsAnimated(e),
            e.ref.busyIndicators.push(n),
            (n.markedForRemoval = !1),
            (n.translateY = 0),
            (n.opacity = 1);
    },
    addBusyIndicator = function addBusyIndicator(e, t) {
        var r = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
        return e.appendChildView(
            e.createChildView(
                wrapper("status-bubble", {
                    styles: ["opacity", "translateY"],
                    apis: ["markedForRemoval"],
                    animations: {
                        opacity: { type: "tween", duration: 500 },
                        translateY: { type: "spring", mass: 20 },
                    },
                }),
                {
                    translateY: 20,
                    opacity: 0,
                    controls: [
                        {
                            view: wrapper("status-bubble-inner"),
                            controls: r
                                ? [
                                      {
                                          view: button,
                                          label: "Close",
                                          name: "icon-only status-bubble-close",
                                          icon: createIcon(
                                              '<g fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12"/></g>'
                                          ),
                                          action: r,
                                      },
                                      { view: textNode("p", t) },
                                  ]
                                : [{ view: textNode("p", t) }],
                        },
                    ],
                }
            )
        );
    },
    editStatus = createView({
        name: "edit-status",
        ignoreRect: !0,
        create: function create(e) {
            var t = e.root;
            (t.ref.busyIndicators = []), t.element.setAttribute("tabindex", -1);
        },
        write: createRoute({
            MISSING_WEBGL: function MISSING_WEBGL(e) {
                var t = e.root,
                    r = /fullscreen/.test(t.query("GET_STYLE_LAYOUT_MODE"));
                showBusyIndicator(
                    t,
                    t.query("GET_LABEL_STATUS_MISSING_WEB_G_L"),
                    r
                        ? function () {
                              t.dispatch("EDIT_CANCEL");
                          }
                        : null
                );
            },
            AWAITING_IMAGE: function AWAITING_IMAGE(e) {
                var t = e.root;
                showBusyIndicator(
                    t,
                    t.query("GET_LABEL_STATUS_AWAITING_IMAGE")
                );
            },
            DID_PRESENT_IMAGE: function DID_PRESENT_IMAGE(e) {
                var t = e.root;
                hideBusyIndicators(t);
            },
            DID_LOAD_IMAGE_ERROR: function DID_LOAD_IMAGE_ERROR(e) {
                var t = e.root,
                    r = e.action,
                    n = /fullscreen/.test(t.query("GET_STYLE_LAYOUT_MODE")),
                    i = t.query("GET_LABEL_STATUS_LOAD_IMAGE_ERROR"),
                    o = "function" == typeof i ? i(r.error) : i;
                showBusyIndicator(
                    t,
                    o,
                    n
                        ? function () {
                              t.dispatch("EDIT_CANCEL");
                          }
                        : null
                );
            },
            DID_REQUEST_LOAD_IMAGE: function DID_REQUEST_LOAD_IMAGE(e) {
                var t = e.root;
                showBusyIndicator(t, t.query("GET_LABEL_STATUS_LOADING_IMAGE"));
            },
            DID_REQUEST_PREPARE_OUTPUT: function DID_REQUEST_PREPARE_OUTPUT(e) {
                var t = e.root;
                showBusyIndicator(
                    t,
                    t.query("GET_LABEL_STATUS_PROCESSING_IMAGE")
                );
            },
            DID_PREPARE_OUTPUT: function DID_PREPARE_OUTPUT(e) {
                var t = e.root;
                hideBusyIndicators(t);
            },
        }),
        didWriteView: function didWriteView(e) {
            var t = e.root;
            t.ref.busyIndicators = t.ref.busyIndicators.filter(function (e) {
                return (
                    !e.markedForRemoval ||
                    0 !== e.opacity ||
                    (t.removeChildView(e), !1)
                );
            });
        },
    }),
    Interaction = { down: "pointerdown", move: "pointermove", up: "pointerup" },
    createPointerRegistry = function createPointerRegistry() {
        var e = [],
            t = function t(_t3) {
                return e.findIndex(function (e) {
                    return e.pointerId === _t3.pointerId;
                });
            };
        return {
            update: function update(r) {
                var n = t(r);
                n < 0 || (e[n] = r);
            },
            multiple: function multiple() {
                return e.length > 1;
            },
            count: function count() {
                return e.length;
            },
            active: function active() {
                return e.concat();
            },
            push: function push(r) {
                (function (e) {
                    return t(e) >= 0;
                })(r) || e.push(r);
            },
            pop: function pop(r) {
                var n = t(r);
                n < 0 || e.splice(n, 1);
            },
        };
    },
    addEvent$1 = function addEvent$1(e, t, r, n) {
        return e.addEventListener(Interaction[t], r, n);
    },
    removeEvent$1 = function removeEvent$1(e, t, r) {
        return e.removeEventListener(Interaction[t], r);
    },
    contains = function contains(e, t) {
        "contains" in e && e.contains(t);
        var r = t;
        do {
            if (r === e) return !0;
        } while ((r = r.parentNode));
        return !1;
    },
    createDragger = function createDragger(e, t, r, n) {
        var i =
                arguments.length > 4 && void 0 !== arguments[4]
                    ? arguments[4]
                    : { stopPropagation: !0, cancelOnMultiple: !1 },
            o = { x: 0, y: 0 },
            a = {
                enabled: !0,
                origin: null,
                cancel: !1,
                cancelled: !1,
                pointers: createPointerRegistry(),
            },
            c = null,
            l = function l(e, t) {
                t &&
                    (c || u(e, t),
                    cancelAnimationFrame(c),
                    (c = requestAnimationFrame(function () {
                        u(e, t), (c = null);
                    })));
            },
            u = function u(e, t) {
                return t.apply(null, [
                    e,
                    (function (e) {
                        return { x: e.pageX - o.x, y: e.pageY - o.y };
                    })(e),
                ]);
            },
            s = function s(r) {
                var n = 0 === a.pointers.count();
                n && ((a.active = !1), (a.cancel = !1), (a.cancelled = !1)),
                    a.pointers.push(r),
                    addEvent$1(document.documentElement, "up", f),
                    n
                        ? (e === r.target || contains(e, r.target)) &&
                          r.isPrimary &&
                          (r.preventDefault(),
                          i.stopPropagation &&
                              (r.stopPropagation(),
                              r.stopImmediatePropagation()),
                          (a.active = !0),
                          (o.x = r.pageX),
                          (o.y = r.pageY),
                          addEvent$1(document.documentElement, "move", d),
                          t(r))
                        : i.cancelOnMultiple && (a.cancel = !0);
            },
            d = function d(e) {
                e.isPrimary &&
                    (a.cancelled ||
                        (e.preventDefault(),
                        i.stopPropagation && e.stopPropagation(),
                        l(e, r),
                        a.cancel && ((a.cancelled = !0), l(e, n))));
            },
            f = function e(t) {
                a.pointers.pop(t),
                    0 === a.pointers.count() &&
                        (removeEvent$1(document.documentElement, "move", d),
                        removeEvent$1(document.documentElement, "up", e)),
                    a.active &&
                        (a.cancelled ||
                            (t.preventDefault(),
                            i.stopPropagation && t.stopPropagation(),
                            l(t, r),
                            l(t, n)));
            };
        return (
            addEvent$1(document.documentElement, "down", s),
            {
                enable: function enable() {
                    a.enabled ||
                        addEvent$1(document.documentElement, "down", s),
                        (a.enabled = !0);
                },
                disable: function disable() {
                    a.enabled &&
                        removeEvent$1(document.documentElement, "down", s),
                        (a.enabled = !1);
                },
                destroy: function destroy() {
                    removeEvent$1(document.documentElement, "up", f),
                        removeEvent$1(document.documentElement, "move", d),
                        removeEvent$1(document.documentElement, "down", s);
                },
            }
        );
    },
    imageOverlaySpring = {
        type: "spring",
        stiffness: 0.4,
        damping: 0.65,
        mass: 7,
    },
    MARKUP_MARGIN = 10,
    setAttributes$1 = function setAttributes$1(e, t) {
        return Object.keys(t).forEach(function (r) {
            e.setAttribute(r, t[r]);
        });
    },
    ns$2 = "http://www.w3.org/2000/svg",
    svg$1 = function svg$1(e, t) {
        var r = document.createElementNS(ns$2, e);
        return t && setAttributes$1(r, t), r;
    },
    LINE_CORNERS = ["nw", "se"],
    RECT_CORNERS = ["nw", "n", "ne", "w", "e", "sw", "s", "se"],
    CORNER_CURSOR = {
        nw: "nwse",
        n: "ns",
        ne: "nesw",
        w: "ew",
        e: "ew",
        sw: "nesw",
        s: "ns",
        se: "nwse",
    },
    CORNER_COORDINATES = {
        nw: function nw(e) {
            return { x: e.x, y: e.y };
        },
        n: function n(e) {
            return { x: e.x + 0.5 * e.width, y: e.y };
        },
        ne: function ne(e) {
            return { x: e.x + e.width, y: e.y };
        },
        w: function w(e) {
            return { x: e.x, y: e.y + 0.5 * e.height };
        },
        e: function e(_e2) {
            return { x: _e2.x + _e2.width, y: _e2.y + 0.5 * _e2.height };
        },
        sw: function sw(e) {
            return { x: e.x, y: e.y + e.height };
        },
        s: function s(e) {
            return { x: e.x + 0.5 * e.width, y: e.y + e.height };
        },
        se: function se(e) {
            return { x: e.x + e.width, y: e.y + e.height };
        },
    },
    imageMarkup = createView({
        tag: "div",
        name: "image-markup",
        ignoreRect: !0,
        mixins: {
            styles: ["opacity"],
            animations: {
                opacity: "spring",
                markupX: imageOverlaySpring,
                markupY: imageOverlaySpring,
                markupWidth: imageOverlaySpring,
                markupHeight: imageOverlaySpring,
            },
            listeners: !0,
            apis: [
                "toolsReference",
                "onSelect",
                "onDrag",
                "markupX",
                "markupY",
                "markupWidth",
                "markupHeight",
                "allowInteraction",
            ],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props,
                n = r.onSelect,
                i = void 0 === n ? function () {} : n,
                o = r.onUpdate,
                a = void 0 === o ? function () {} : o,
                c = svg$1("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                });
            t.ref.canvas = c;
            var l = t.query("GET_ROOT_SIZE");
            c.setAttribute("width", l.width),
                c.setAttribute("height", l.height);
            var u = document.createElement("input");
            setAttributes$1(u, {
                type: "text",
                autocomplete: "off",
                autocapitalize: "off",
            }),
                u.addEventListener("keydown", function (e) {
                    e.stopPropagation(),
                        13 === e.keyCode || 9 === e.keyCode
                            ? (e.target.blur(), d())
                            : 8 !== e.keyCode ||
                              t.ref.input.value.length ||
                              t.dispatch("MARKUP_DELETE", {
                                  id: t.ref.selected.id,
                              });
                }),
                t.appendChild(u),
                (t.ref.input = u),
                (t.ref.elements = []),
                (t.ref.viewSize = {}),
                (t.ref.resetSelected = function () {
                    return (
                        (t.ref.selected = {
                            id: null,
                            type: null,
                            settings: {},
                        }),
                        t.ref.selected
                    );
                }),
                t.ref.resetSelected();
            var s = function s(e) {
                    return e.id ? e : e.parentNode;
                },
                d = function d() {
                    t.ref.resetSelected(), i(null);
                };
            (t.ref.handleDeselect = function (e) {
                var n;
                t.query("IS_ACTIVE_VIEW", "markup") &&
                    t.ref.selected.id &&
                    e.target !== t.ref.removeButton.element &&
                    ((n = e.target),
                    t.ref.selected.id !== s(n).id &&
                        ((function (e) {
                            return (
                                contains(t.ref.manipulatorGroup, e) ||
                                e === t.ref.input
                            );
                        })(e.target) ||
                            r.isMarkupUtil(e.target) ||
                            d()));
            }),
                addEvent$1(document.body, "down", t.ref.handleDeselect),
                (t.ref.handleTextInput = function () {
                    return a(t.ref.selected.id, { text: t.ref.input.value });
                }),
                t.ref.input.addEventListener("input", t.ref.handleTextInput),
                (t.ref.handleAttemptDelete = function (e) {
                    t.query("IS_ACTIVE_VIEW", "markup") &&
                        (!t.ref.selected.id ||
                            (8 !== e.keyCode && 46 !== e.keyCode) ||
                            (e.stopPropagation(),
                            e.preventDefault(),
                            t.dispatch("MARKUP_DELETE", {
                                id: t.ref.selected.id,
                            })));
                }),
                document.body.addEventListener(
                    "keydown",
                    t.ref.handleAttemptDelete
                );
            var f = svg$1("g"),
                p = svg$1("g");
            f.appendChild(p), (t.ref.shapeGroup = p);
            var h = svg$1("g", {
                    fill: "none",
                    class: "doka--manipulator-group",
                }),
                g = svg$1("rect", {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    fill: "none",
                }),
                m = svg$1("line", { x1: 0, y1: 0, x2: 0, y2: 0 });
            h.appendChild(m),
                h.appendChild(g),
                (t.ref.manipulatorLine = m),
                (t.ref.manipulatorRect = g),
                (t.ref.manipulators = []);
            for (var v = 0; v < 10; v++) {
                var y = svg$1("circle", {
                    r: 6,
                    "stroke-width": 2,
                    style: "display:none",
                });
                h.appendChild(y), t.ref.manipulators.push(y);
            }
            f.appendChild(h),
                (t.ref.manipulatorGroup = h),
                c.appendChild(f),
                t.element.appendChild(c),
                (t.ref.shapeOffsetGroup = f),
                (t.ref.removeButton = t.appendChildView(
                    t.createChildView(button, {
                        label: t.query("GET_LABEL_MARKUP_REMOVE_SHAPE"),
                        name: "destroy-shape",
                        action: function action() {
                            t.dispatch("MARKUP_DELETE", {
                                id: t.ref.selected.id,
                            });
                        },
                    })
                )),
                t.query("IS_ACTIVE_VIEW", "markup") &&
                    (t.element.dataset.active = !0);
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.ref.elements.concat(t.ref.manipulators).forEach(function (e) {
                e.dragger && e.dragger.destroy();
            }),
                t.ref.input.removeEventListener("input", t.ref.handleTextInput),
                document.body.removeEventListener(
                    "keydown",
                    t.ref.handleAttemptDelete
                ),
                removeEvent$1(document.body, "down", t.ref.handleDeselect);
        },
        read: function read(e) {
            var t = e.root;
            if (!t.rect.element.hidden)
                for (var r in t.ref.elements) {
                    var n = t.ref.elements[r];
                    if (n && "text" === n.nodeName && n.parentNode) {
                        var i = n.getBBox();
                        n.bbox = {
                            x: i.x,
                            y: i.y,
                            width: i.width,
                            height: i.height,
                        };
                    }
                }
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root;
                    "markup" === e.action.id
                        ? (t.element.dataset.active = !0)
                        : (t.element.dataset.active = !1);
                },
                MARKUP_SET_VALUE: function MARKUP_SET_VALUE(e) {
                    e.root.ref.shapeGroup.innerHTML = "";
                },
                UPDATE_ROOT_RECT: function UPDATE_ROOT_RECT(e) {
                    var t = e.root,
                        r = e.action,
                        n = t.ref.canvas;
                    n.setAttribute("width", r.rect.width),
                        n.setAttribute("height", r.rect.height),
                        (t.ref.previousScale = null);
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp;
                if (!(t.opacity <= 0)) {
                    var i = t.query("GET_CROP", r.id, n);
                    if (i) {
                        var o = i.markup,
                            a = i.cropStatus,
                            c = r.onSelect,
                            l = r.onDrag,
                            u = t.ref,
                            s = u.viewSize,
                            d = u.shapeOffsetGroup,
                            f = u.manipulators,
                            p = u.manipulatorLine,
                            h = u.manipulatorRect,
                            g = u.removeButton,
                            m = t.query("GET_OUTPUT_WIDTH"),
                            v = t.query("GET_OUTPUT_HEIGHT"),
                            y = a.image,
                            E = a.crop,
                            T = E.width,
                            _ = E.height,
                            R = E.widthFloat / E.heightFloat;
                        if (m || v) {
                            var w = t.query("GET_OUTPUT_FIT");
                            m && !v && (v = m), v && !m && (m = v);
                            var I,
                                A = m / T,
                                C = v / _;
                            if ("force" === w) (T = m), (_ = v);
                            else
                                "cover" === w
                                    ? (I = Math.max(A, C))
                                    : "contain" === w && (I = Math.min(A, C)),
                                    (T *= I),
                                    (_ *= I);
                        } else
                            y.width && y.height
                                ? ((T = y.width), (_ = y.height))
                                : y.width && !y.height
                                ? ((T = y.width), (_ = y.width / R))
                                : y.height &&
                                  !y.width &&
                                  ((_ = y.height), (T = y.height * R));
                        var S = roundFloat(t.markupX, 3),
                            x = roundFloat(t.markupY, 3),
                            O = roundFloat(t.markupWidth, 3),
                            b = roundFloat(t.markupHeight, 3),
                            M = roundFloat(
                                Math.min(t.markupWidth / T, t.markupHeight / _),
                                4
                            );
                        if (
                            ((s.width = O),
                            (s.height = b),
                            stateHasChanged(t, {
                                markupX: S,
                                markupY: x,
                                scale: M,
                                markup: o,
                                currentWidth: T,
                                currentHeight: _,
                            }) &&
                                (d.setAttribute(
                                    "transform",
                                    "translate(".concat(S, " ").concat(x, ")")
                                ),
                                (t.ref.previousX = S),
                                (t.ref.previousY = x),
                                (t.ref.previousScale = M),
                                (t.ref.previousCurrentHeight = _),
                                (t.ref.previousCurrentWidth = T),
                                (t.ref.previousMarkupLength = o.length),
                                !(s.width < 1 || s.height < 1)))
                        ) {
                            var L,
                                P = o.find(function (e) {
                                    return e[1].isSelected;
                                }),
                                G =
                                    (P && t.ref.selected.id !== P[1].id) ||
                                    (t.ref.selected.id && !P);
                            (L = P
                                ? (t.ref.selected = {
                                      id: P[1].id,
                                      type: P[0],
                                      settings: P[1],
                                  })
                                : t.ref.resetSelected()),
                                (t.ref.input.hidden =
                                    "text" !== t.ref.selected.type),
                                (g.element.dataset.active =
                                    null !== t.ref.selected.id),
                                p.setAttribute("style", "opacity:0"),
                                h.setAttribute("style", "opacity:0"),
                                f.forEach(function (e) {
                                    return e.setAttribute(
                                        "style",
                                        "opacity:0;pointer-events:none;"
                                    );
                                });
                            var k = t.query("GET_MARKUP_FILTER");
                            o
                                .filter(k)
                                .sort(sortMarkupByZIndex)
                                .forEach(function (e, n) {
                                    var i = _slicedToArray(e, 2),
                                        o = i[0],
                                        a = i[1],
                                        u = a.id,
                                        d = a.isDestroyed,
                                        m = a.isDirty,
                                        v = a.isSelected,
                                        y = a.allowSelect,
                                        E = a.allowMove,
                                        T = a.allowResize,
                                        _ = a.allowInput;
                                    if (d) {
                                        var R = t.ref.elements[u];
                                        R &&
                                            (R.dragger && R.dragger.destroy(),
                                            (t.ref.elements[u] = null),
                                            R.parentNode.removeChild(R));
                                    } else {
                                        var w,
                                            I,
                                            A,
                                            C = t.ref.elements[u];
                                        if (!C)
                                            if (
                                                ((C = createMarkupByType(o, a)),
                                                (t.ref.elements[u] = C),
                                                y)
                                            )
                                                (C.dragger = createDragger(
                                                    C,
                                                    function () {
                                                        (I = Date.now()),
                                                            (w = _objectSpread(
                                                                {},
                                                                C.rect
                                                            )),
                                                            (A =
                                                                u ===
                                                                t.ref.selected
                                                                    .id) ||
                                                                c(u);
                                                    },
                                                    function (e, t) {
                                                        E && l(u, w, t, s, M);
                                                    },
                                                    function (e, r) {
                                                        if (
                                                            _ &&
                                                            "text" === o &&
                                                            A
                                                        ) {
                                                            var n =
                                                                    vectorDistanceSquared(
                                                                        {
                                                                            x: 0,
                                                                            y: 0,
                                                                        },
                                                                        r
                                                                    ),
                                                                i =
                                                                    Date.now() -
                                                                    I;
                                                            if (
                                                                !(
                                                                    n > 10 ||
                                                                    i > 750
                                                                )
                                                            ) {
                                                                t.ref.input.focus();
                                                                var a =
                                                                        t.markupX +
                                                                        C.bbox
                                                                            .x,
                                                                    c =
                                                                        C.bbox
                                                                            .width,
                                                                    l =
                                                                        (e.offsetX -
                                                                            a) /
                                                                        c,
                                                                    u =
                                                                        Math.round(
                                                                            t
                                                                                .ref
                                                                                .input
                                                                                .value
                                                                                .length *
                                                                                l
                                                                        );
                                                                t.ref.input.setSelectionRange(
                                                                    u,
                                                                    u
                                                                );
                                                            }
                                                        }
                                                    }
                                                )),
                                                    C.dragger.disable();
                                            else
                                                C.setAttribute(
                                                    "style",
                                                    "pointer-events:none;"
                                                );
                                        if (
                                            (C.dragger &&
                                                (r.allowInteraction
                                                    ? C.dragger.enable()
                                                    : C.dragger.disable()),
                                            n !== C.index)
                                        ) {
                                            C.index = n;
                                            var S = t.ref.shapeGroup;
                                            S.insertBefore(
                                                C,
                                                S.childNodes[n + 1]
                                            );
                                        }
                                        if (
                                            (m &&
                                                updateMarkupByType(
                                                    C,
                                                    o,
                                                    a,
                                                    s,
                                                    M
                                                ),
                                            v)
                                        ) {
                                            var x = g.rect.element.width,
                                                O = g.rect.element.height,
                                                b = t.markupX - 0.5 * x,
                                                L = t.markupY - O - 15,
                                                P =
                                                    "text" === o
                                                        ? C.bbox
                                                        : C.rect;
                                            "line" === o
                                                ? ((b += P.x),
                                                  (L += P.y),
                                                  setAttributes$1(p, {
                                                      x1: P.x,
                                                      y1: P.y,
                                                      x2: P.x + P.width,
                                                      y2: P.y + P.height,
                                                      style: "opacity:1",
                                                  }))
                                                : P &&
                                                  ((L += P.y),
                                                  (b += P.x + 0.5 * P.width),
                                                  setAttributes$1(h, {
                                                      x:
                                                          P.x -
                                                          ("text" === o
                                                              ? 5
                                                              : 0),
                                                      y: P.y,
                                                      width:
                                                          P.width +
                                                          ("text" === o
                                                              ? 10
                                                              : 0),
                                                      height: P.height,
                                                      style: "opacity:1",
                                                  }));
                                            var G = t.markupY + MARKUP_MARGIN,
                                                k =
                                                    t.markupY +
                                                    t.markupHeight -
                                                    MARKUP_MARGIN,
                                                D = t.markupX + MARKUP_MARGIN,
                                                V =
                                                    t.markupX +
                                                    t.markupWidth -
                                                    MARKUP_MARGIN;
                                            if (
                                                (L < G
                                                    ? (L = G)
                                                    : L + O > k && (L = k - O),
                                                b < D
                                                    ? (b = D)
                                                    : b + x > V && (b = V - x),
                                                P ||
                                                    (g.element.dataset.active =
                                                        "false"),
                                                g.element.setAttribute(
                                                    "style",
                                                    "transform: translate3d("
                                                        .concat(b, "px, ")
                                                        .concat(L, "px, 0)")
                                                ),
                                                "text" === o && P)
                                            ) {
                                                var B = P.width + 65,
                                                    N = t.markupWidth - P.x,
                                                    U =
                                                        "\n                        width: "
                                                            .concat(
                                                                Math.min(B, N),
                                                                "px;\n                        height: "
                                                            )
                                                            .concat(
                                                                P.height,
                                                                "px;\n                        color: "
                                                            )
                                                            .concat(
                                                                C.getAttribute(
                                                                    "fill"
                                                                ),
                                                                ";\n                        font-family: "
                                                            )
                                                            .concat(
                                                                C.getAttribute(
                                                                    "font-family"
                                                                ),
                                                                ";\n                        font-size: "
                                                            )
                                                            .concat(
                                                                C.getAttribute(
                                                                    "font-size"
                                                                ).replace(
                                                                    /px/,
                                                                    ""
                                                                ),
                                                                "px;\n                        font-weight: "
                                                            )
                                                            .concat(
                                                                C.getAttribute(
                                                                    "font-weight"
                                                                ) || "normal",
                                                                ";\n                    "
                                                            );
                                                isIOS()
                                                    ? (U +=
                                                          "\n                            left: "
                                                              .concat(
                                                                  Math.round(
                                                                      t.markupX +
                                                                          P.x
                                                                  ),
                                                                  "px;\n                            top: "
                                                              )
                                                              .concat(
                                                                  Math.round(
                                                                      t.markupY +
                                                                          P.y
                                                                  ),
                                                                  "px;\n                        "
                                                              ))
                                                    : (U +=
                                                          "\n                            transform: translate3d("
                                                              .concat(
                                                                  Math.round(
                                                                      t.markupX +
                                                                          P.x
                                                                  ),
                                                                  "px,"
                                                              )
                                                              .concat(
                                                                  Math.round(
                                                                      t.markupY +
                                                                          P.y
                                                                  ),
                                                                  "px,0);\n                        "
                                                              )),
                                                    t.ref.input.setAttribute(
                                                        "style",
                                                        U
                                                    ),
                                                    C.setAttribute(
                                                        "fill",
                                                        "none"
                                                    );
                                            }
                                            if ("text" === o) return;
                                            if (!T) return;
                                            var F =
                                                "line" === o
                                                    ? LINE_CORNERS
                                                    : RECT_CORNERS;
                                            f.forEach(function (e, t) {
                                                var r = F[t];
                                                if (r) {
                                                    var n =
                                                            "line" === o
                                                                ? "move"
                                                                : "".concat(
                                                                      CORNER_CURSOR[
                                                                          r
                                                                      ],
                                                                      "-resize"
                                                                  ),
                                                        i = CORNER_COORDINATES[
                                                            r
                                                        ](C.rect);
                                                    setAttributes$1(e, {
                                                        cx: i.x,
                                                        cy: i.y,
                                                        style: "opacity:1;cursor:".concat(
                                                            n
                                                        ),
                                                    });
                                                }
                                            });
                                        }
                                        a.isDirty = !1;
                                    }
                                }),
                                G &&
                                    (destroyElementControls(t),
                                    "text" === L.type
                                        ? (t.ref.input.value = L.settings.text)
                                        : t.ref.selected.id &&
                                          setupElementControls(t, r.onResize));
                        }
                    }
                }
            }
        ),
    }),
    markAllAsDirty = function markAllAsDirty(e) {
        return e.forEach(function (e) {
            return (e[1].isDirty = !0);
        });
    },
    stateHasChanged = function stateHasChanged(e, t) {
        var r = t.markup,
            n = t.markupX,
            i = t.markupY,
            o = t.currentWidth,
            a = t.currentHeight,
            c = t.scale;
        return n !== e.ref.previousX
            ? (markAllAsDirty(r), !0)
            : i !== e.ref.previousY
            ? (markAllAsDirty(r), !0)
            : c !== e.ref.previousScale
            ? (markAllAsDirty(r), !0)
            : a !== e.ref.previousCurrentHeight
            ? (markAllAsDirty(r), !0)
            : o !== e.ref.previousCurrentWidth
            ? (markAllAsDirty(r), !0)
            : r.length !== e.ref.previousMarkupLength ||
              !!r.find(function (e) {
                  return e[1].isDirty;
              });
    },
    setupElementControls = function setupElementControls(e, t) {
        var r = e.ref.selected.id,
            n =
                "g" === e.ref.elements[r].nodeName
                    ? LINE_CORNERS
                    : RECT_CORNERS;
        e.ref.manipulators.forEach(function (i, o) {
            var a = n[o];
            if (a) {
                var c = null;
                i.dragger = createDragger(
                    i,
                    function () {
                        c = {
                            x: parseFloat(attr(i, "cx")),
                            y: parseFloat(attr(i, "cy")),
                        };
                    },
                    function (n, i) {
                        t(r, a, c, i, e.ref.viewSize);
                    },
                    null,
                    { stopPropagation: !0 }
                );
            }
        });
    },
    destroyElementControls = function destroyElementControls(e) {
        e.ref.manipulators.forEach(function (e) {
            e.dragger && (e.dragger.destroy(), (e.dragger = null));
        });
    },
    KEY_MAP = {
        38: "up",
        40: "down",
        37: "left",
        39: "right",
        189: "minus",
        187: "plus",
        72: "h",
        76: "l",
        81: "q",
        82: "r",
        84: "t",
        86: "v",
        90: "z",
        219: "left_bracket",
        221: "right_bracket",
    },
    createKeyboard = function createKeyboard(e, t, r, n, i) {
        var o = null,
            a = !0,
            c = { enabled: !0 },
            l = function l(e) {
                var i = KEY_MAP[e.keyCode] || e.keyCode;
                r[i] &&
                    (e.stopPropagation(),
                    a && ((o = t(i)), (a = !1)),
                    r[i](o),
                    n(o));
            },
            u = function u(e) {
                var t = KEY_MAP[e.keyCode] || e.keyCode;
                r[t] && (e.stopPropagation(), i(o), (a = !0));
            };
        return (
            e.addEventListener("keydown", l),
            e.addEventListener("keyup", u),
            {
                enable: function enable() {
                    c.enabled ||
                        (e.addEventListener("keydown", l),
                        e.addEventListener("keyup", u)),
                        (c.enabled = !0);
                },
                disable: function disable() {
                    c.enabled &&
                        (e.removeEventListener("keydown", l),
                        e.removeEventListener("keyup", u)),
                        (c.enabled = !1);
                },
                destroy: function destroy() {
                    e.removeEventListener("keydown", l),
                        e.removeEventListener("keyup", u);
                },
            }
        );
    },
    transforms = {
        1: function _() {
            return [1, 0, 0, 1, 0, 0];
        },
        2: function _(e) {
            return [-1, 0, 0, 1, e, 0];
        },
        3: function _(e, t) {
            return [-1, 0, 0, -1, e, t];
        },
        4: function _(e, t) {
            return [1, 0, 0, -1, 0, t];
        },
        5: function _() {
            return [0, 1, 1, 0, 0, 0];
        },
        6: function _(e, t) {
            return [0, 1, -1, 0, t, 0];
        },
        7: function _(e, t) {
            return [0, -1, -1, 0, t, e];
        },
        8: function _(e) {
            return [0, -1, 1, 0, 0, e];
        },
    },
    fixImageOrientation = function fixImageOrientation(e, t, r, n) {
        -1 !== n && e.transform.apply(e, transforms[n](t, r));
    },
    createPreviewImage = function createPreviewImage(e, t, r) {
        var n =
                arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : 1,
            i = arguments.length > 4 ? arguments[4] : void 0;
        (t = Math.round(t)), (r = Math.round(r));
        var o = i || document.createElement("canvas"),
            a = o.getContext("2d");
        return (
            n >= 5 && n <= 8
                ? ((o.width = r), (o.height = t))
                : ((o.width = t), (o.height = r)),
            a.save(),
            fixImageOrientation(a, t, r, n),
            a.drawImage(e, 0, 0, t, r),
            a.restore(),
            o
        );
    },
    BitmapWorker = function BitmapWorker() {
        self.onmessage = function (e) {
            createImageBitmap(e.data.message.file).then(function (t) {
                self.postMessage({ id: e.data.id, message: t }, [t]);
            });
        };
    },
    isBitmap = function isBitmap(e) {
        return /^image/.test(e.type) && !/svg/.test(e.type);
    },
    canCreateImageBitmap = function canCreateImageBitmap(e) {
        return "createImageBitmap" in window && isBitmap(e);
    },
    loadImage$2 = function loadImage$2(e) {
        return new Promise(function (t, r) {
            var n = new Image();
            (n.onload = function () {
                t(n);
            }),
                (n.onerror = function (e) {
                    r(e);
                }),
                (n.src = e);
        });
    },
    compileShader = function compileShader(e, t, r) {
        var n = e.createShader(r);
        return e.shaderSource(n, t), e.compileShader(n), n;
    },
    createProgram = function createProgram(e, t, r) {
        var n = e.createProgram();
        return (
            e.attachShader(n, compileShader(e, t, e.VERTEX_SHADER)),
            e.attachShader(n, compileShader(e, r, e.FRAGMENT_SHADER)),
            e.linkProgram(n),
            n
        );
    },
    createTexture = function createTexture(e, t, r, n, i) {
        var o = e.createTexture();
        e.activeTexture(e.TEXTURE0 + n),
            e.bindTexture(e.TEXTURE_2D, o),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MIN_FILTER, e.LINEAR),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MAG_FILTER, e.LINEAR),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_S, e.CLAMP_TO_EDGE),
            e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_T, e.CLAMP_TO_EDGE),
            e.uniform1i(t, n),
            e.uniform2f(r, i.width, i.height);
        try {
            e.texImage2D(e.TEXTURE_2D, 0, e.RGBA, e.RGBA, e.UNSIGNED_BYTE, i);
        } catch (t) {
            e.texImage2D(
                e.TEXTURE_2D,
                0,
                e.RGBA,
                i.width,
                i.height,
                0,
                e.RGBA,
                e.UNSIGNED_BYTE,
                null
            );
        }
        return o;
    },
    getAspectRatio = function getAspectRatio(e, t) {
        return e / t;
    },
    degToRad = function degToRad(e) {
        return (e * Math.PI) / 180;
    },
    rectCreate = function rectCreate() {
        return {
            x:
                arguments.length < 3
                    ? 0
                    : arguments.length <= 0
                    ? void 0
                    : arguments[0],
            y:
                arguments.length < 3
                    ? 0
                    : arguments.length <= 1
                    ? void 0
                    : arguments[1],
            width:
                arguments.length < 3
                    ? arguments.length <= 0
                        ? void 0
                        : arguments[0]
                    : arguments.length <= 2
                    ? void 0
                    : arguments[2],
            height:
                1 === arguments.length
                    ? arguments.length <= 0
                        ? void 0
                        : arguments[0]
                    : arguments.length < 3
                    ? arguments.length <= 1
                        ? void 0
                        : arguments[1]
                    : arguments.length <= 3
                    ? void 0
                    : arguments[3],
        };
    },
    rectScale$1 = function rectScale$1(e, t) {
        return {
            x: e.x * t,
            y: e.y * t,
            width: e.width * t,
            height: e.height * t,
        };
    },
    create = function create() {
        var e = new Float32Array(16);
        return (e[0] = 1), (e[5] = 1), (e[10] = 1), (e[15] = 1), e;
    },
    perspective = function perspective(e, t, r, n, i) {
        var o = 1 / Math.tan(t / 2),
            a = 1 / (n - i);
        (e[0] = o / r),
            (e[1] = 0),
            (e[2] = 0),
            (e[3] = 0),
            (e[4] = 0),
            (e[5] = o),
            (e[6] = 0),
            (e[7] = 0),
            (e[8] = 0),
            (e[9] = 0),
            (e[11] = -1),
            (e[12] = 0),
            (e[13] = 0),
            (e[15] = 0),
            (e[10] = (i + n) * a),
            (e[14] = 2 * i * n * a);
    },
    translate = function translate(e, t) {
        var r = t[0],
            n = t[1],
            i = t[2];
        (e[12] = e[0] * r + e[4] * n + e[8] * i + e[12]),
            (e[13] = e[1] * r + e[5] * n + e[9] * i + e[13]),
            (e[14] = e[2] * r + e[6] * n + e[10] * i + e[14]),
            (e[15] = e[3] * r + e[7] * n + e[11] * i + e[15]);
    },
    scale = function scale(e, t) {
        var r = t[0],
            n = t[1],
            i = t[2];
        (e[0] = e[0] * r),
            (e[1] = e[1] * r),
            (e[2] = e[2] * r),
            (e[3] = e[3] * r),
            (e[4] = e[4] * n),
            (e[5] = e[5] * n),
            (e[6] = e[6] * n),
            (e[7] = e[7] * n),
            (e[8] = e[8] * i),
            (e[9] = e[9] * i),
            (e[10] = e[10] * i),
            (e[11] = e[11] * i);
    },
    rotateX = function rotateX(e, t) {
        var r = Math.sin(t),
            n = Math.cos(t),
            i = e[4],
            o = e[5],
            a = e[6],
            c = e[7],
            l = e[8],
            u = e[9],
            s = e[10],
            d = e[11];
        (e[4] = i * n + l * r),
            (e[5] = o * n + u * r),
            (e[6] = a * n + s * r),
            (e[7] = c * n + d * r),
            (e[8] = l * n - i * r),
            (e[9] = u * n - o * r),
            (e[10] = s * n - a * r),
            (e[11] = d * n - c * r);
    },
    rotateY = function rotateY(e, t) {
        var r = Math.sin(t),
            n = Math.cos(t),
            i = e[0],
            o = e[1],
            a = e[2],
            c = e[3],
            l = e[8],
            u = e[9],
            s = e[10],
            d = e[11];
        (e[0] = i * n - l * r),
            (e[1] = o * n - u * r),
            (e[2] = a * n - s * r),
            (e[3] = c * n - d * r),
            (e[8] = i * r + l * n),
            (e[9] = o * r + u * n),
            (e[10] = a * r + s * n),
            (e[11] = c * r + d * n);
    },
    rotateZ = function rotateZ(e, t) {
        var r = Math.sin(t),
            n = Math.cos(t),
            i = e[0],
            o = e[1],
            a = e[2],
            c = e[3],
            l = e[4],
            u = e[5],
            s = e[6],
            d = e[7];
        (e[0] = i * n + l * r),
            (e[1] = o * n + u * r),
            (e[2] = a * n + s * r),
            (e[3] = c * n + d * r),
            (e[4] = l * n - i * r),
            (e[5] = u * n - o * r),
            (e[6] = s * n - a * r),
            (e[7] = d * n - c * r);
    },
    mat4 = {
        create: create,
        perspective: perspective,
        translate: translate,
        scale: scale,
        rotateX: rotateX,
        rotateY: rotateY,
        rotateZ: rotateZ,
    },
    imageFragmentShader =
        "\nprecision mediump float;\n\nuniform sampler2D uTexture;\nuniform vec2 uTextureSize;\n\nuniform float uColorOpacity;\nuniform mat4 uColorMatrix;\nuniform vec4 uColorOffset;\n\nuniform vec4 uOverlayColor;\nuniform vec2 uOverlayLeftTop;\nuniform vec2 uOverlayRightBottom;\n\n// received from vertex shader\nvarying vec2 vTexCoord;\nvarying vec4 vPosition;\n\nvoid main () {\n\n\t// get texture color\n\tvec4 color = texture2D(uTexture, vTexCoord);\n\t\n\t// apply color matrix\n\tcolor = color * uColorMatrix + uColorOffset;\n\n\t// test if falls within \n    if ((gl_FragCoord.x < uOverlayLeftTop.x || gl_FragCoord.x > uOverlayRightBottom.x) || \n        (gl_FragCoord.y > uOverlayLeftTop.y || gl_FragCoord.y < uOverlayRightBottom.y)) {\n\t\tcolor *= uOverlayColor;\n\t}\n\t\n    gl_FragColor = color * uColorOpacity;\n}\n",
    imageVertexShader =
        "\nattribute vec4 aPosition;\nattribute vec2 aTexCoord;\nuniform mat4 uMatrix;\n\n// send to fragment shader\nvarying vec2 vTexCoord;\nvarying vec4 vPosition;\n\nvoid main () {\n    vPosition = uMatrix * aPosition;\n    gl_Position = vPosition;\n    vTexCoord = aTexCoord;\n}\n",
    backgroundFragmentShader =
        "\nprecision mediump float;\n\nuniform vec2 uViewportSize;\nuniform vec3 uColorStart;\nuniform vec3 uColorEnd;\nuniform vec2 uOverlayLeftTop;\nuniform vec2 uOverlayRightBottom;\nuniform vec4 uColorCanvasBackground;\n\nvoid main() {\n\n\tfloat x = gl_FragCoord.x;\n\tfloat y = gl_FragCoord.y;\n\n\tvec2 center = vec2(.5, .5);\n\tvec2 st = vec2(x / uViewportSize.x, y / uViewportSize.y);\n\tfloat mixValue = distance(st, center) * 1.5; // expand outside view (same as doka--root::after)\n\tvec3 color = mix(uColorStart, uColorEnd, mixValue);\n\n\tif (uColorCanvasBackground[3] == 1.0) {\n\n\t\tfloat innerLeft = uOverlayLeftTop.x;\n\t\tfloat innerRight = uOverlayRightBottom.x;\n\t\tfloat innerTop = uOverlayRightBottom.y;\n\t\tfloat innerBottom = uOverlayLeftTop.y;\n\n\t\tif (x < innerLeft || x > innerRight || y < innerTop || y > innerBottom) {\n\t\t\tgl_FragColor = vec4(color, 1.0);\n\t\t\treturn;\n\t\t}\n\n\t\tgl_FragColor = uColorCanvasBackground;\n\t\treturn;\n\t}\n\t\n\tgl_FragColor = vec4(color, 1.0);\n}\n",
    outlineFragmentShader =
        "\nprecision mediump float;\n\nuniform vec2 uOverlayLeftTop;\nuniform vec2 uOverlayRightBottom;\nuniform vec4 uOutlineColor;\nuniform float uOutlineWidth;\n\nvoid main() {\n\n\tfloat x = gl_FragCoord.x;\n\tfloat y = gl_FragCoord.y;\n\n\tfloat innerLeft = uOverlayLeftTop.x;\n\tfloat innerRight = uOverlayRightBottom.x;\n\tfloat innerTop = uOverlayRightBottom.y;\n\tfloat innerBottom = uOverlayLeftTop.y;\n\n\tfloat outerLeft = innerLeft - uOutlineWidth;\n\tfloat outerRight = innerRight + uOutlineWidth;\n\tfloat outerTop = innerTop - uOutlineWidth;\n\tfloat outerBottom = innerBottom + uOutlineWidth;\n\t\n\tif (x < outerLeft || x > outerRight || y < outerTop || y > outerBottom) {\n\t\tdiscard;\n\t}\n\n\tif (x < innerLeft || x > innerRight || y < innerTop || y > innerBottom) {\n\t\tgl_FragColor = uOutlineColor;\n\t}\n}\n",
    simpleVertexShader =
        "\nattribute vec4 aPosition;\nvoid main() {\n\tgl_Position = aPosition;\n}\n",
    setup = function setup(e, t, r) {
        var n = rectCreate(),
            i = null,
            o = degToRad(30),
            a = Math.tan(o / 2),
            c = { antialias: !1, alpha: !1 },
            l =
                e.getContext("webgl", c) ||
                e.getContext("experimental-webgl", c);
        if (!l) return null;
        l.enable(l.BLEND), l.blendFunc(l.SRC_ALPHA, l.ONE_MINUS_SRC_ALPHA);
        var u = createProgram(l, simpleVertexShader, backgroundFragmentShader),
            s = l.getUniformLocation(u, "uColorStart"),
            d = l.getUniformLocation(u, "uColorEnd"),
            f = l.getUniformLocation(u, "uViewportSize"),
            p = l.getAttribLocation(u, "aPosition"),
            h = l.getUniformLocation(u, "uOverlayLeftTop"),
            g = l.getUniformLocation(u, "uOverlayRightBottom"),
            m = l.getUniformLocation(u, "uColorCanvasBackground"),
            v = l.createBuffer(),
            y = new Float32Array([1, -1, 1, 1, -1, -1, -1, 1]);
        l.bindBuffer(l.ARRAY_BUFFER, v),
            l.bufferData(l.ARRAY_BUFFER, y, l.STATIC_DRAW),
            l.bindBuffer(l.ARRAY_BUFFER, null);
        var E = createProgram(l, simpleVertexShader, outlineFragmentShader),
            T = l.getAttribLocation(E, "aPosition"),
            _ = l.getUniformLocation(E, "uOutlineWidth"),
            R = l.getUniformLocation(E, "uOutlineColor"),
            w = l.getUniformLocation(E, "uOverlayLeftTop"),
            I = l.getUniformLocation(E, "uOverlayRightBottom"),
            A = l.createBuffer(),
            C = new Float32Array([1, -1, 1, 1, -1, -1, -1, 1]);
        l.bindBuffer(l.ARRAY_BUFFER, A),
            l.bufferData(l.ARRAY_BUFFER, C, l.STATIC_DRAW),
            l.bindBuffer(l.ARRAY_BUFFER, null);
        var S = createProgram(l, imageVertexShader, imageFragmentShader);
        l.useProgram(S);
        var x = l.getUniformLocation(S, "uMatrix"),
            O = l.getUniformLocation(S, "uTexture"),
            b = l.getUniformLocation(S, "uTextureSize"),
            M = l.getUniformLocation(S, "uOverlayColor"),
            L = l.getUniformLocation(S, "uOverlayLeftTop"),
            P = l.getUniformLocation(S, "uOverlayRightBottom"),
            G = l.getUniformLocation(S, "uColorOpacity"),
            k = l.getUniformLocation(S, "uColorOffset"),
            D = l.getUniformLocation(S, "uColorMatrix"),
            V = l.getAttribLocation(S, "aPosition"),
            B = l.getAttribLocation(S, "aTexCoord"),
            N = createTexture(l, O, b, 0, t),
            U = t.width * r,
            F = t.height * r,
            z = -0.5 * U,
            W = 0.5 * F,
            q = 0.5 * U,
            Y = -0.5 * F,
            j = new Float32Array([z, W, z, Y, q, W, q, Y]),
            H = new Float32Array([0, 0, 0, 1, 1, 0, 1, 1]),
            Z = j.length / 2,
            $ = l.createBuffer();
        l.bindBuffer(l.ARRAY_BUFFER, $),
            l.bufferData(l.ARRAY_BUFFER, j, l.STATIC_DRAW),
            l.bindBuffer(l.ARRAY_BUFFER, null);
        var X = l.createBuffer();
        l.bindBuffer(l.ARRAY_BUFFER, X),
            l.bufferData(l.ARRAY_BUFFER, H, l.STATIC_DRAW),
            l.bindBuffer(l.ARRAY_BUFFER, null);
        return {
            release: function release() {
                (e.width = 1), (e.height = 1);
            },
            resize: function resize(t, o) {
                (e.width = t * r),
                    (e.height = o * r),
                    (e.style.width = "".concat(t, "px")),
                    (e.style.height = "".concat(o, "px")),
                    (n.width = t * r),
                    (n.height = o * r),
                    (i = getAspectRatio(n.width, n.height)),
                    l.viewport(0, 0, l.canvas.width, l.canvas.height);
            },
            update: function update(
                e,
                c,
                y,
                C,
                O,
                b,
                U,
                F,
                z,
                W,
                q,
                Y,
                j,
                H,
                K,
                Q,
                J,
                ee,
                te
            ) {
                q = q ? rectScale$1(q, r) : rectCreate(n.width, n.height);
                var re = 0.5 * n.width,
                    ne = 0.5 * n.height,
                    ie = t.width * r,
                    oe = t.height * r;
                (e *= r), (c *= r), (y *= r), (C *= r);
                var ae = (oe / 2 / a) * (n.height / q.height) * -1;
                ae /= (-a * ae * 2) / n.height;
                var ce = 0.5 * ie,
                    le = 0.5 * oe;
                (e -= ce), (c -= le);
                var ue = F,
                    se = -(re - ce) + y,
                    de = ne - le - C,
                    fe = mat4.create();
                mat4.perspective(fe, o, i, 1, 2 * -ae),
                    mat4.translate(fe, [se, de, ae]),
                    mat4.translate(fe, [e, -c, 0]),
                    mat4.scale(fe, [ue, ue, ue]),
                    mat4.rotateZ(fe, -U),
                    mat4.translate(fe, [-e, c, 0]),
                    mat4.rotateY(fe, b),
                    mat4.rotateX(fe, O),
                    l.clearColor(H[0], H[1], H[2], 1),
                    l.clear(l.COLOR_BUFFER_BIT),
                    l.useProgram(u),
                    l.uniform3fv(s, K),
                    l.uniform3fv(d, Q),
                    l.uniform4fv(
                        m,
                        te.map(function (e, t) {
                            return t < 3 ? e / 255 : e;
                        })
                    ),
                    l.uniform2f(f, n.width, n.height),
                    l.uniform2f(h, Y.x * r, n.height - Y.y * r),
                    l.uniform2f(
                        g,
                        (Y.x + Y.width) * r,
                        n.height - (Y.y + Y.height) * r
                    ),
                    l.bindBuffer(l.ARRAY_BUFFER, v),
                    l.vertexAttribPointer(p, 2, l.FLOAT, !1, 0, 0),
                    l.enableVertexAttribArray(p),
                    l.drawArrays(l.TRIANGLE_STRIP, 0, 4),
                    l.useProgram(S),
                    l.bindFramebuffer(l.FRAMEBUFFER, null),
                    l.bindTexture(l.TEXTURE_2D, N),
                    l.bindBuffer(l.ARRAY_BUFFER, $),
                    l.vertexAttribPointer(V, 2, l.FLOAT, !1, 0, 0),
                    l.enableVertexAttribArray(V),
                    l.bindBuffer(l.ARRAY_BUFFER, X),
                    l.vertexAttribPointer(B, 2, l.FLOAT, !1, 0, 0),
                    l.enableVertexAttribArray(B),
                    l.uniformMatrix4fv(x, !1, fe),
                    (Y = rectScale$1(Y, r)),
                    l.uniform2f(L, Y.x, n.height - Y.y),
                    l.uniform2f(P, Y.x + Y.width, n.height - (Y.y + Y.height)),
                    l.uniform4fv(M, j),
                    l.uniform1f(G, W),
                    l.uniform4f(k, z[4], z[9], z[14], z[19]),
                    l.uniformMatrix4fv(
                        D,
                        !1,
                        [].concat(
                            _toConsumableArray(z.slice(0, 4)),
                            _toConsumableArray(z.slice(5, 9)),
                            _toConsumableArray(z.slice(10, 14)),
                            _toConsumableArray(z.slice(15, 19))
                        )
                    ),
                    l.drawArrays(l.TRIANGLE_STRIP, 0, Z),
                    l.useProgram(E),
                    l.uniform1f(_, J),
                    l.uniform4fv(R, ee),
                    l.uniform2f(w, Y.x, n.height - Y.y),
                    l.uniform2f(I, Y.x + Y.width, n.height - (Y.y + Y.height)),
                    l.bindBuffer(l.ARRAY_BUFFER, A),
                    l.vertexAttribPointer(T, 2, l.FLOAT, !1, 0, 0),
                    l.enableVertexAttribArray(T),
                    l.drawArrays(l.TRIANGLE_STRIP, 0, 4);
            },
        };
    },
    createSpringRect = function createSpringRect(e) {
        var t = 0,
            r = {},
            n = spring(e),
            i = spring(e),
            o = spring(e),
            a = spring(e);
        return (
            (n.onupdate = function (e) {
                return (r.x = e);
            }),
            (n.oncomplete = function () {
                return t++;
            }),
            (i.onupdate = function (e) {
                return (r.y = e);
            }),
            (i.oncomplete = function () {
                return t++;
            }),
            (o.onupdate = function (e) {
                return (r.width = e);
            }),
            (o.oncomplete = function () {
                return t++;
            }),
            (a.onupdate = function (e) {
                return (r.height = e);
            }),
            (a.oncomplete = function () {
                return t++;
            }),
            {
                interpolate: function interpolate(e) {
                    n.interpolate(e),
                        i.interpolate(e),
                        o.interpolate(e),
                        a.interpolate(e);
                },
                setTarget: function setTarget(e) {
                    (t = 0),
                        (n.target = e ? e.x : null),
                        (i.target = e ? e.y : null),
                        (o.target = e ? e.width : null),
                        (a.target = e ? e.height : null);
                },
                getRect: function getRect() {
                    return _objectSpread({}, r);
                },
                isStable: function isStable() {
                    return 4 === t;
                },
            }
        );
    },
    createSpringColor = function createSpringColor(e) {
        var t = 0,
            r = {},
            n = spring(e),
            i = spring(e),
            o = spring(e);
        return (
            (n.onupdate = function (e) {
                return (r.r = e);
            }),
            (n.oncomplete = function () {
                return t++;
            }),
            (i.onupdate = function (e) {
                return (r.g = e);
            }),
            (i.oncomplete = function () {
                return t++;
            }),
            (o.onupdate = function (e) {
                return (r.b = e);
            }),
            (o.oncomplete = function () {
                return t++;
            }),
            {
                interpolate: function interpolate(e) {
                    n.interpolate(e), i.interpolate(e), o.interpolate(e);
                },
                setTarget: function setTarget(e) {
                    (t = 0),
                        (n.target = e ? e[0] : null),
                        (i.target = e ? e[1] : null),
                        (o.target = e ? e[2] : null);
                },
                getColor: function getColor() {
                    return [r.r, r.g, r.b];
                },
                isStable: function isStable() {
                    return 3 === t;
                },
            }
        );
    },
    ColorSpring = { stiffness: 0.25, damping: 0.25, mass: 2.5 },
    IdentityMatrix = [
        1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0,
    ],
    imageGL = createView({
        name: "image-gl",
        ignoreRect: !0,
        ignoreRectUpdate: !0,
        mixins: {
            apis: [
                "top",
                "left",
                "width",
                "height",
                "xOrigin",
                "yOrigin",
                "xTranslation",
                "yTranslation",
                "xRotation",
                "yRotation",
                "zRotation",
                "scale",
                "overlay",
                "stage",
                "colorMatrix",
                "colorOpacity",
                "overlayOpacity",
                "outlineWidth",
                "isDraft",
            ],
            animations: {
                xTranslation: imageOverlaySpring,
                yTranslation: imageOverlaySpring,
                xOrigin: imageOverlaySpring,
                yOrigin: imageOverlaySpring,
                scale: imageOverlaySpring,
                xRotation: {
                    type: "spring",
                    stiffness: 0.25,
                    damping: 0.25,
                    mass: 2.5,
                },
                yRotation: {
                    type: "spring",
                    stiffness: 0.25,
                    damping: 0.25,
                    mass: 2.5,
                },
                zRotation: {
                    type: "spring",
                    stiffness: 0.25,
                    damping: 0.25,
                    mass: 2.5,
                },
                colorOpacity: { type: "tween", delay: 150, duration: 750 },
                overlayOpacity: "spring",
                introScale: {
                    type: "spring",
                    stiffness: 0.25,
                    damping: 0.75,
                    mass: 15,
                },
                outlineWidth: imageOverlaySpring,
            },
        },
        create: function create(e) {
            var t = e.root;
            (t.ref.canvas = document.createElement("canvas")),
                (t.ref.canvas.width = 0),
                (t.ref.canvas.height = 0),
                t.appendChild(t.ref.canvas),
                (t.ref.gl = null),
                (t.introScale = 1),
                (t.ref.isPreview =
                    "preview" === t.query("GET_STYLE_LAYOUT_MODE")),
                (t.ref.shouldZoom = !t.ref.isPreview),
                (t.ref.didZoom = !1),
                (t.ref.backgroundColor = null),
                (t.ref.backgroundColorSpring = createSpringColor(ColorSpring)),
                (t.ref.backgroundColorCenter = null),
                (t.ref.backgroundColorCenterSpring =
                    createSpringColor(ColorSpring)),
                (t.ref.overlaySpring = createSpringRect(imageOverlaySpring)),
                (t.ref.stageSpring = createSpringRect(imageOverlaySpring)),
                (t.ref.outlineSpring = spring(imageOverlaySpring)),
                (t.ref.colorMatrixSpring = []),
                (t.ref.colorMatrixStable = !0),
                (t.ref.colorMatrixStableCount = 0),
                (t.ref.colorMatrixPositions = []);
            for (var r = 0; r < 20; r++) {
                !(function () {
                    var e = r,
                        n = spring(ColorSpring);
                    (n.target = IdentityMatrix[e]),
                        (n.onupdate = function (r) {
                            t.ref.colorMatrixPositions[e] = r;
                        }),
                        (n.oncomplete = function () {
                            t.ref.colorMatrixStableCount++;
                        }),
                        (t.ref.colorMatrixSpring[e] = n);
                })();
            }
            t.ref.dragger = createDragger(
                t.element,
                function () {
                    t.dispatch("CROP_IMAGE_DRAG_GRAB");
                },
                function (e, r) {
                    t.dispatch("CROP_IMAGE_DRAG", { value: r });
                },
                function () {
                    t.dispatch("CROP_IMAGE_DRAG_RELEASE");
                },
                { cancelOnMultiple: !0 }
            );
            var n = 0,
                i = 0;
            t.ref.keyboard = createKeyboard(
                t.element,
                function () {
                    return (n = 0), (i = 0), { x: 0, y: 0 };
                },
                {
                    up: function up(e) {
                        e.y -= 20;
                    },
                    down: function down(e) {
                        e.y += 20;
                    },
                    left: function left(e) {
                        e.x -= 20;
                    },
                    right: function right(e) {
                        e.x += 20;
                    },
                    plus: function plus() {
                        (n += 0.1),
                            t.dispatch("CROP_IMAGE_RESIZE_AMOUNT", {
                                value: n,
                            }),
                            t.dispatch("CROP_IMAGE_RESIZE_RELEASE");
                    },
                    minus: function minus() {
                        (n -= 0.1),
                            t.dispatch("CROP_IMAGE_RESIZE_AMOUNT", {
                                value: n,
                            }),
                            t.dispatch("CROP_IMAGE_RESIZE_RELEASE");
                    },
                    left_bracket: function left_bracket() {
                        (i -= Math.PI / 128),
                            t.dispatch("CROP_IMAGE_ROTATE_ADJUST", {
                                value: i,
                            });
                    },
                    right_bracket: function right_bracket() {
                        (i += Math.PI / 128),
                            t.dispatch("CROP_IMAGE_ROTATE_ADJUST", {
                                value: i,
                            });
                    },
                    h: function h() {
                        t.dispatch("CROP_IMAGE_FLIP_HORIZONTAL");
                    },
                    l: function l() {
                        t.dispatch("CROP_IMAGE_ROTATE_LEFT");
                    },
                    q: function q() {
                        t.dispatch("CROP_RESET");
                    },
                    r: function r() {
                        t.dispatch("CROP_IMAGE_ROTATE_RIGHT");
                    },
                    v: function v() {
                        t.dispatch("CROP_IMAGE_FLIP_VERTICAL");
                    },
                    z: function z() {
                        t.dispatch("CROP_ZOOM");
                    },
                },
                function (e) {
                    e && t.dispatch("CROP_IMAGE_DRAG", { value: e });
                },
                function (e) {
                    e && t.dispatch("CROP_IMAGE_DRAG_RELEASE");
                }
            );
            var o = t.query("GET_FILE"),
                a = URL.createObjectURL(o.data),
                c = function c(e) {
                    var r = scaleImageSize(e, {
                            width: t.query("GET_MAX_IMAGE_PREVIEW_WIDTH"),
                            height: t.query("GET_MAX_IMAGE_PREVIEW_HEIGHT"),
                        }),
                        n = createPreviewImage(
                            e,
                            r.width,
                            r.height,
                            o.orientation
                        ),
                        i = Math.max(1, 0.75 * window.devicePixelRatio),
                        a = n.height / n.width,
                        c = 96 * i,
                        l = createPreviewImage(
                            n,
                            a > 1 ? c : c / a,
                            a > 1 ? c * a : c
                        ),
                        u = n
                            .getContext("2d")
                            .getImageData(0, 0, n.width, n.height),
                        s = l
                            .getContext("2d")
                            .getImageData(0, 0, l.width, l.height);
                    canvasRelease(n),
                        canvasRelease(l),
                        (t.ref.gl = setup(t.ref.canvas, u, i)),
                        t.ref.gl
                            ? (t.dispatch("DID_RECEIVE_IMAGE_DATA", {
                                  previewData: u,
                                  thumbData: s,
                              }),
                              t.dispatch("DID_PRESENT_IMAGE"))
                            : t.dispatch("MISSING_WEBGL");
                },
                l = function l() {
                    loadImage$2(a).then(c);
                };
            if (canCreateImageBitmap(o.data)) {
                var u = createWorker(BitmapWorker);
                u.post({ file: o.data }, function (e) {
                    u.terminate(), e ? c(e) : l();
                });
            } else l();
            t.ref.previousLeft,
                t.ref.previousTop,
                t.ref.previousWidth,
                t.ref.previousHeight,
                (t.ref.canvasStyle = getComputedStyle(t.ref.canvas)),
                t.ref.previousBackgroundColor;
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.ref.gl && (t.ref.gl.release(), (t.ref.gl = null)),
                t.ref.dragger.destroy();
        },
        read: function read(e) {
            var t = e.root,
                r = t.ref.canvasStyle.backgroundColor,
                n = t.ref.canvasStyle.color;
            if (
                (("transparent" !== n && "" !== n) || (n = null),
                ("transparent" !== r && "" !== r) || (r = null),
                r && r !== t.ref.previousBackgroundColor)
            ) {
                var i = toRGBColorArray(r).map(function (e) {
                        return e / 255;
                    }),
                    o = (i[0] + i[1] + i[2]) / 3;
                (t.ref.backgroundColor = i),
                    (t.ref.backgroundColorCenter = i.map(function (e) {
                        return o > 0.5 ? e - 0.15 : e + 0.15;
                    })),
                    (t.ref.previousBackgroundColor = r);
            }
            n &&
                n !== t.ref.previousOutlineColor &&
                ((t.ref.outlineColor = toRGBColorArray(n)
                    .map(function (e) {
                        return e / 255;
                    })
                    .concat(1)),
                (t.ref.previousOutlineColor = n));
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root;
                    "crop" === e.action.id
                        ? (t.element.setAttribute("tabindex", 0),
                          t.ref.dragger.enable())
                        : (t.element.removeAttribute("tabindex"),
                          t.ref.dragger.disable());
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp;
                if (t.ref.gl && r.width && r.height) {
                    var i = t.ref,
                        o = i.gl,
                        a = i.shouldZoom,
                        c = i.stageSpring,
                        l = i.overlaySpring,
                        u = i.backgroundColorSpring,
                        s = i.backgroundColorCenterSpring;
                    (r.width === t.ref.previousWidth &&
                        r.height === t.ref.previousHeight) ||
                        (t.ref.gl.resize(r.width, r.height),
                        (t.ref.previousWidth = r.width),
                        (t.ref.previousHeight = r.height)),
                        (r.left === t.ref.previousLeft &&
                            r.top === t.ref.previousTop) ||
                            ((t.ref.canvas.style.transform = "translate("
                                .concat(-r.left, "px, ")
                                .concat(-r.top, "px)")),
                            (t.ref.previousLeft = r.left),
                            (t.ref.previousTop = r.top)),
                        a &&
                            !t.ref.didZoom &&
                            ((t.introScale = null),
                            (t.introScale = 1.15),
                            (t.introScale = 1),
                            (t.ref.didZoom = !0)),
                        u.setTarget(t.ref.backgroundColor),
                        u.interpolate(n);
                    var d = u.isStable();
                    s.setTarget(t.ref.backgroundColorCenter), s.interpolate(n);
                    var f = s.isStable();
                    t.ref.colorMatrixStableCount = 0;
                    var p = r.colorMatrix || IdentityMatrix,
                        h = t.ref.colorMatrixSpring.map(function (e, r) {
                            return (
                                (e.target = p[r]),
                                e.interpolate(n),
                                t.ref.colorMatrixPositions[r]
                            );
                        }),
                        g = 20 === t.ref.colorMatrixStableCount;
                    r.isDraft && l.setTarget(null),
                        l.setTarget(r.overlay),
                        l.interpolate(n);
                    var m = l.isStable();
                    r.isDraft && c.setTarget(null),
                        c.setTarget(r.stage),
                        c.interpolate(n);
                    var v = c.isStable();
                    return (
                        o.update(
                            t.xOrigin,
                            t.yOrigin,
                            t.xTranslation + r.left,
                            t.yTranslation + r.top,
                            t.xRotation,
                            t.yRotation,
                            t.zRotation,
                            t.scale * t.introScale,
                            h,
                            t.ref.isPreview ? 1 : t.colorOpacity,
                            c.getRect(),
                            l.getRect(),
                            [1, 1, 1, 1 - t.overlayOpacity],
                            u.getColor(),
                            s.getColor(),
                            u.getColor(),
                            t.outlineWidth,
                            t.ref.outlineColor,
                            t.query("GET_BACKGROUND_COLOR")
                        ),
                        m && v && g && d && f
                    );
                }
            }
        ),
    }),
    image = createView({
        name: "image",
        ignoreRect: !0,
        mixins: { apis: ["offsetTop"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (t.ref.imageGL = t.appendChildView(t.createChildView(imageGL))),
                (t.ref.createMarkup = function () {
                    return t.ref.markup
                        ? t.ref.markup
                        : ((t.ref.markup = t.appendChildView(
                              t.createChildView(imageMarkup, {
                                  id: r.id,
                                  opacity: 0,
                                  onSelect: function onSelect(e) {
                                      t.dispatch("MARKUP_SELECT", { id: e });
                                  },
                                  onDrag: function onDrag(e, r, n, i, o) {
                                      t.dispatch("MARKUP_ELEMENT_DRAG", {
                                          id: e,
                                          origin: r,
                                          offset: n,
                                          size: i,
                                          scale: o,
                                      });
                                  },
                                  onResize: function onResize(e, r, n, i, o) {
                                      t.dispatch("MARKUP_ELEMENT_RESIZE", {
                                          id: e,
                                          corner: r,
                                          origin: n,
                                          offset: i,
                                          size: o,
                                      });
                                  },
                                  onUpdate: function onUpdate(e, r) {
                                      t.dispatch("MARKUP_UPDATE", {
                                          id: e,
                                          props: r,
                                      });
                                  },
                                  isMarkupUtil: function isMarkupUtil(e) {
                                      var t = e;
                                      do {
                                          if (
                                              "doka--markup-tools" ===
                                              t.className
                                          )
                                              return !0;
                                      } while ((t = t.parentNode));
                                      return !1;
                                  },
                              })
                          )),
                          t.ref.markup);
                }),
                (t.ref.removeMarkup = function () {
                    t.ref.markup &&
                        (t.removeChildView(t.ref.markup),
                        (t.ref.markup = null));
                }),
                (t.ref.isModal = /modal/.test(
                    t.query("GET_STYLE_LAYOUT_MODE")
                ));
        },
        write: createRoute(
            {
                DID_PRESENT_IMAGE: function DID_PRESENT_IMAGE(e) {
                    e.root.ref.imageGL.colorOpacity = 1;
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp,
                    i = t.ref.imageGL,
                    o = t.ref.markup,
                    a = t.query("GET_CROP", r.id, n);
                if (a) {
                    var c = a.isDraft,
                        l = a.cropRect,
                        u = a.cropStatus,
                        s = a.origin,
                        d = a.translation,
                        f = a.translationBand,
                        p = a.scale,
                        h = a.scaleBand,
                        g = a.rotation,
                        m = a.rotationBand,
                        v = a.flip,
                        y = a.colorMatrix,
                        E = t.query("GET_ROOT"),
                        T = t.query("GET_STAGE"),
                        _ = T.x,
                        R = T.y;
                    c &&
                        ((i.scale = null),
                        (i.zRotation = null),
                        (i.xTranslation = null),
                        (i.yTranslation = null),
                        (i.xOrigin = null),
                        (i.yOrigin = null)),
                        (i.colorMatrix = y);
                    var w = t.query("IS_ACTIVE_VIEW", "crop"),
                        I = t.query("IS_ACTIVE_VIEW", "markup"),
                        A = w ? 0.75 : 0.95,
                        C = _objectSpread({}, l),
                        S = 1,
                        x = w ? 1 : 5;
                    if (t.query("IS_ACTIVE_VIEW", "resize")) {
                        var O = u.image.width,
                            b = u.image.height;
                        (S =
                            null === O && null === b
                                ? u.crop.width / l.width
                                : null === O
                                ? b / l.height
                                : O / l.width),
                            (S /= window.devicePixelRatio);
                        var M = l.width * S,
                            L = l.height * S;
                        (C.x = C.x + (0.5 * l.width - 0.5 * M)),
                            (C.y = C.y + (0.5 * l.height - 0.5 * L)),
                            (C.width = M),
                            (C.height = L);
                    }
                    var P = t.ref.isModal ? 0 : E.left,
                        G = t.ref.isModal ? 0 : E.top,
                        k = t.ref.isModal ? 0 : E.width - t.rect.element.width,
                        D = t.ref.isModal
                            ? 0
                            : E.height - t.rect.element.height - r.offsetTop,
                        V = (p + h) * S;
                    (i.isDraft = c),
                        (i.overlayOpacity = A),
                        (i.xOrigin = s.x),
                        (i.yOrigin = s.y),
                        (i.xTranslation = d.x + f.x + _),
                        (i.yTranslation = d.y + f.y + R),
                        (i.left = P),
                        (i.top = G + r.offsetTop),
                        (i.width = t.rect.element.width + k),
                        (i.height = t.rect.element.height + D + r.offsetTop),
                        (i.scale = V),
                        (i.xRotation = v.vertical ? Math.PI : 0),
                        (i.yRotation = v.horizontal ? Math.PI : 0),
                        (i.zRotation = g.main + g.sub + m),
                        (i.stage = {
                            x: T.x + P,
                            y: T.y + G + r.offsetTop,
                            width: T.width,
                            height: T.height,
                        }),
                        (i.overlay = {
                            x: C.x + _ + P,
                            y: C.y + R + G + r.offsetTop,
                            width: C.width,
                            height: C.height,
                        }),
                        (i.outlineWidth = x),
                        a.markup.length && !o
                            ? (o = t.ref.createMarkup())
                            : !a.markup.length &&
                              o &&
                              (t.ref.removeMarkup(), (o = null)),
                        o &&
                            (c &&
                                ((o.translateX = null),
                                (o.translateY = null),
                                (o.markupX = null),
                                (o.markupY = null),
                                (o.markupWidth = null),
                                (o.markupHeight = null)),
                            (o.opacity = w ? 0.3 : 1),
                            (o.markupX = C.x + _),
                            (o.markupY = C.y + R),
                            (o.markupWidth = C.width),
                            (o.markupHeight = C.height),
                            (o.allowInteraction = I));
                }
            }
        ),
    }),
    MAGIC = 312,
    createDiv = function createDiv(e, t) {
        return createView({ name: e, ignoreRect: !0, create: t });
    },
    cropRotatorLine = createView({
        name: "crop-rotator-line",
        ignoreRect: !0,
        ignoreRectUpdate: !0,
        mixins: {
            styles: ["translateX"],
            animations: { translateX: "spring" },
        },
        create: function create(e) {
            for (
                var t = e.root,
                    r =
                        '<svg viewBox="-90 -5 180 10" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">',
                    n = 0;
                n <= 180;
                n += 2
            ) {
                var i = n * (176 / 180) - 90 + 2,
                    o = n % 10 == 0 ? 0.5 : 0.2;
                if (
                    ((r += '<circle fill="currentColor" cx="'
                        .concat(i, '" cy="')
                        .concat(0, '" r="')
                        .concat(o, '"/>')),
                    n % 10 == 0)
                )
                    r += '<text fill="currentColor" x="'
                        .concat(
                            i + (i < 0 ? -2.25 : 0 === i ? -0.75 : -1.5),
                            '" y="'
                        )
                        .concat(3.5, '">')
                        .concat(-90 + n, "&deg;</text>");
            }
            (r += "</svg>"), (t.element.innerHTML = r);
        },
    }),
    cropRotator = createView({
        name: "crop-rotator",
        ignoreRect: !0,
        mixins: {
            styles: ["opacity", "translateY"],
            animations: {
                opacity: { type: "spring", damping: 0.5, mass: 5 },
                translateY: "spring",
            },
            apis: ["rotation", "animate", "setAllowInteraction"],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            t.element.setAttribute("tabindex", 0);
            var n = document.createElement("button");
            (n.innerHTML = "<span>".concat(
                t.query("GET_LABEL_BUTTON_CROP_ROTATE_CENTER"),
                "</span>"
            )),
                (n.className = "doka--crop-rotator-center"),
                n.addEventListener("click", function () {
                    t.dispatch("CROP_IMAGE_ROTATE_CENTER");
                }),
                t.appendChild(n);
            var i = null;
            t.appendChildView(
                t.createChildView(
                    createDiv("crop-rotator-line-mask", function (e) {
                        var t = e.root,
                            r = e.props;
                        i = t.appendChildView(
                            t.createChildView(cropRotatorLine, {
                                translateX: Math.round(r.rotation * MAGIC),
                            })
                        );
                    }),
                    r
                )
            ),
                (t.ref.line = i);
            var o = document.createElement("div");
            (o.className = "doka--crop-rotator-bar"), t.appendChild(o);
            var a = Math.PI / 4,
                c = 0;
            (t.ref.dragger = createDragger(
                o,
                function () {
                    (c = i.translateX / MAGIC),
                        t.dispatch("CROP_IMAGE_ROTATE_GRAB");
                },
                function (e, r) {
                    var n = (r.x / t.rect.element.width) * (Math.PI / 2),
                        i = limit(c + n, -a, a);
                    t.dispatch("CROP_IMAGE_ROTATE", { value: -i });
                },
                function () {
                    t.dispatch("CROP_IMAGE_ROTATE_RELEASE");
                },
                { stopPropagation: !0 }
            )),
                (r.setAllowInteraction = function (e) {
                    e ? t.ref.dragger.enable() : t.ref.dragger.disable();
                }),
                (t.ref.keyboard = createKeyboard(
                    t.element,
                    function () {
                        c = 0;
                    },
                    {
                        left: function left() {
                            (c += Math.PI / 128),
                                t.dispatch("CROP_IMAGE_ROTATE_ADJUST", {
                                    value: c,
                                });
                        },
                        right: function right() {
                            (c -= Math.PI / 128),
                                t.dispatch("CROP_IMAGE_ROTATE_ADJUST", {
                                    value: c,
                                });
                        },
                    },
                    function () {},
                    function () {}
                )),
                t.ref.prevRotation;
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.ref.dragger.destroy(), t.ref.keyboard.destroy();
        },
        write: function write(e) {
            var t = e.root,
                r = e.props,
                n = e.timestamp,
                i = r.animate,
                o = r.rotation;
            if (t.ref.prevRotation !== o) {
                (t.ref.prevRotation = o),
                    i || 0 === o || (t.ref.line.translateX = null);
                var a = 0,
                    c = t.query("GET_CROP", r.id, n);
                if (c && c.interaction && c.interaction.rotation) {
                    var l = splitRotation(c.interaction.rotation).sub - o;
                    a =
                        0.025 *
                        Math.sign(l) *
                        Math.log10(1 + Math.abs(l) / 0.025);
                }
                t.ref.line.translateX = Math.round((-o - a) * MAGIC);
            }
        },
    }),
    corners = ["nw", "ne", "se", "sw"],
    getOppositeCorner = function getOppositeCorner(e) {
        return corners[(corners.indexOf(e) + 2) % corners.length];
    },
    edges = ["n", "e", "s", "w"],
    getOppositeEdge = function getOppositeEdge(e) {
        return edges[(edges.indexOf(e) + 2) % edges.length];
    },
    autoPrecision =
        isBrowser() && 1 === window.devicePixelRatio
            ? function (e) {
                  return Math.round(e);
              }
            : function (e) {
                  return e;
              },
    line = createView({
        ignoreRect: !0,
        ignoreRectUpdate: !0,
        name: "crop-rect-focal-line",
        mixins: {
            styles: ["translateX", "translateY", "scaleX", "scaleY", "opacity"],
            animations: {
                translateX: "spring",
                translateY: "spring",
                scaleX: "spring",
                scaleY: "spring",
                opacity: "spring",
            },
        },
    }),
    createEdge = function createEdge(e) {
        return createView({
            ignoreRect: !0,
            ignoreRectUpdate: !0,
            tag: "div",
            name: "crop-rect-edge-".concat(e),
            mixins: {
                styles: ["translateX", "translateY", "scaleX", "scaleY"],
                apis: ["setAllowInteraction"],
            },
            create: function create(t) {
                var r = t.root,
                    n = t.props;
                r.element.classList.add("doka--crop-rect-edge"),
                    r.element.setAttribute("tabindex", 0),
                    r.element.setAttribute("role", "button");
                var i = e,
                    o = getOppositeEdge(e);
                (r.ref.dragger = createDragger(
                    r.element,
                    function () {
                        r.dispatch("CROP_RECT_DRAG_GRAB");
                    },
                    function (e, t) {
                        return r.dispatch("CROP_RECT_EDGE_DRAG", {
                            offset: t,
                            origin: i,
                            anchor: o,
                        });
                    },
                    function () {
                        return r.dispatch("CROP_RECT_DRAG_RELEASE");
                    },
                    { stopPropagation: !0, cancelOnMultiple: !0 }
                )),
                    (n.setAllowInteraction = function (e) {
                        e ? r.ref.dragger.enable() : r.ref.dragger.disable();
                    }),
                    (r.ref.keyboard = createKeyboard(
                        r.element,
                        function () {
                            return { x: 0, y: 0 };
                        },
                        {
                            up: function up(e) {
                                e.y -= 20;
                            },
                            down: function down(e) {
                                e.y += 20;
                            },
                            left: function left(e) {
                                e.x -= 20;
                            },
                            right: function right(e) {
                                e.x += 20;
                            },
                        },
                        function (e) {
                            r.dispatch("CROP_RECT_DRAG_GRAB"),
                                r.dispatch("CROP_RECT_EDGE_DRAG", {
                                    offset: e,
                                    origin: i,
                                    anchor: o,
                                });
                        },
                        function () {
                            r.dispatch("CROP_RECT_DRAG_RELEASE");
                        }
                    ));
            },
            destroy: function destroy(e) {
                var t = e.root;
                t.ref.keyboard.destroy(), t.ref.dragger.destroy();
            },
        });
    },
    createCorner = function createCorner(e, t, r) {
        return createView({
            ignoreRect: !0,
            ignoreRectUpdate: !0,
            tag: "div",
            name: "crop-rect-corner-".concat(e),
            mixins: {
                styles: ["translateX", "translateY", "scaleX", "scaleY"],
                animations: {
                    translateX: imageOverlaySpring,
                    translateY: imageOverlaySpring,
                    scaleX: { type: "spring", delay: r },
                    scaleY: { type: "spring", delay: r },
                    opacity: { type: "spring", delay: t },
                },
                apis: ["setAllowInteraction"],
            },
            create: function create(t) {
                var r = t.root,
                    n = t.props;
                r.element.classList.add("doka--crop-rect-corner"),
                    r.element.setAttribute("role", "button"),
                    r.element.setAttribute("tabindex", -1);
                var i = e,
                    o = getOppositeCorner(e);
                (r.ref.dragger = createDragger(
                    r.element,
                    function () {
                        r.dispatch("CROP_RECT_DRAG_GRAB");
                    },
                    function (e, t) {
                        r.dispatch("CROP_RECT_CORNER_DRAG", {
                            offset: t,
                            origin: i,
                            anchor: o,
                        });
                    },
                    function () {
                        r.dispatch("CROP_RECT_DRAG_RELEASE");
                    },
                    { stopPropagation: !0, cancelOnMultiple: !0 }
                )),
                    (n.setAllowInteraction = function (e) {
                        e ? r.ref.dragger.enable() : r.ref.dragger.disable();
                    });
            },
            destroy: function destroy(e) {
                e.root.ref.dragger.destroy();
            },
        });
    },
    cropRect = createView({
        ignoreRect: !0,
        ignoreRectUpdate: !0,
        name: "crop-rect",
        mixins: { apis: ["rectangle", "draft", "rotating", "enabled"] },
        create: function create(e) {
            var t = e.root;
            t.ref.wasRotating = !1;
            corners.forEach(function (e, r) {
                var n = 10 * r,
                    i = 250 + n + 50,
                    o = 250 + n;
                t.ref[e] = t.appendChildView(
                    t.createChildView(createCorner(e, i, o), {
                        opacity: 0,
                        scaleX: 0.5,
                        scaleY: 0.5,
                    })
                );
            }),
                edges.forEach(function (e) {
                    t.ref[e] = t.appendChildView(
                        t.createChildView(createEdge(e))
                    );
                }),
                (t.ref.lines = []);
            for (var r = 0; r < 10; r++) {
                t.ref.lines.push(
                    t.appendChildView(t.createChildView(line, { opacity: 0 }))
                );
            }
            (t.ref.animationDir = null),
                t.ref.previousRotating,
                (t.ref.previousRect = {}),
                t.ref.previousEnabled,
                t.ref.previousDraft;
        },
        write: function write(e) {
            var t = e.root,
                r = e.props,
                n = r.rectangle,
                i = r.draft,
                o = r.rotating,
                a = r.enabled;
            if (
                n &&
                (!rectEqualsRect(n, t.ref.previousRect) ||
                    o !== t.ref.previousRotating ||
                    a !== t.ref.previousEnabled ||
                    i !== t.ref.previousDraft)
            ) {
                (t.ref.previousRect = n),
                    (t.ref.previousRotating = o),
                    (t.ref.previousEnabled = a),
                    (t.ref.previousDraft = i);
                var c = t.ref,
                    l = c.n,
                    u = c.e,
                    s = c.s,
                    d = c.w,
                    f = c.nw,
                    p = c.ne,
                    h = c.se,
                    g = c.sw,
                    m = c.lines,
                    v = c.animationDir,
                    y = n.x,
                    E = n.y,
                    T = n.x + n.width,
                    _ = n.y + n.height,
                    R = _ - E,
                    w = T - y,
                    I = Math.min(w, R);
                (t.element.dataset.indicatorSize = I < 80 ? "none" : "default"),
                    edges.forEach(function (e) {
                        return t.ref[e].setAllowInteraction(a);
                    }),
                    corners.forEach(function (e) {
                        return t.ref[e].setAllowInteraction(a);
                    });
                var A = t.query("IS_ACTIVE_VIEW", "crop");
                if (
                    (A && "in" !== v
                        ? ((t.ref.animationDir = "in"),
                          corners
                              .map(function (e) {
                                  return t.ref[e];
                              })
                              .forEach(function (e) {
                                  (e.opacity = 1),
                                      (e.scaleX = 1),
                                      (e.scaleY = 1);
                              }))
                        : A ||
                          "out" === v ||
                          ((t.ref.animationDir = "out"),
                          corners
                              .map(function (e) {
                                  return t.ref[e];
                              })
                              .forEach(function (e) {
                                  (e.opacity = 0),
                                      (e.scaleX = 0.5),
                                      (e.scaleY = 0.5);
                              })),
                    transformTranslate(i, f, y, E),
                    transformTranslate(i, p, T, E),
                    transformTranslate(i, h, T, _),
                    transformTranslate(i, g, y, _),
                    transformTranslateScale(i, l, y, E, w / 100, 1),
                    transformTranslateScale(i, u, T, E, 1, R / 100),
                    transformTranslateScale(i, s, y, _, w / 100, 1),
                    transformTranslateScale(i, d, y, E, 1, R / 100),
                    o)
                ) {
                    t.ref.wasRotating = !0;
                    var C = m.slice(0, 5),
                        S = 1 / C.length;
                    C.forEach(function (e, t) {
                        transformTranslateScale(
                            i,
                            e,
                            y,
                            E + R * (S + t * S),
                            w / 100,
                            0.01
                        ),
                            (e.opacity = 0.5);
                    });
                    var x = m.slice(5);
                    (S = 1 / x.length),
                        x.forEach(function (e, t) {
                            transformTranslateScale(
                                i,
                                e,
                                y + w * (S + t * S),
                                E,
                                0.01,
                                R / 100
                            ),
                                (e.opacity = 0.5);
                        });
                } else if (i) {
                    t.ref.wasRotating = !1;
                    var O = m[0],
                        b = m[1],
                        M = m[2],
                        L = m[3];
                    transformTranslateScale(
                        i,
                        O,
                        y,
                        E + 0.333 * R,
                        w / 100,
                        0.01
                    ),
                        transformTranslateScale(
                            i,
                            b,
                            y,
                            E + 0.666 * R,
                            w / 100,
                            0.01
                        ),
                        transformTranslateScale(
                            i,
                            M,
                            y + 0.333 * w,
                            E,
                            0.01,
                            R / 100
                        ),
                        transformTranslateScale(
                            i,
                            L,
                            y + 0.666 * w,
                            E,
                            0.01,
                            R / 100
                        ),
                        (O.opacity = 0.5),
                        (b.opacity = 0.5),
                        (M.opacity = 0.5),
                        (L.opacity = 0.5);
                } else {
                    var P = m[0],
                        G = m[1],
                        k = m[2],
                        D = m[3];
                    !t.ref.wasRotating &&
                        P.opacity > 0 &&
                        (transformTranslateScale(
                            i,
                            P,
                            y,
                            E + 0.333 * R,
                            w / 100,
                            0.01
                        ),
                        transformTranslateScale(
                            i,
                            G,
                            y,
                            E + 0.666 * R,
                            w / 100,
                            0.01
                        ),
                        transformTranslateScale(
                            i,
                            k,
                            y + 0.333 * w,
                            E,
                            0.01,
                            R / 100
                        ),
                        transformTranslateScale(
                            i,
                            D,
                            y + 0.666 * w,
                            E,
                            0.01,
                            R / 100
                        )),
                        m.forEach(function (e) {
                            return (e.opacity = 0);
                        });
                }
            }
        },
    }),
    transformTranslateScale = function transformTranslateScale(
        e,
        t,
        r,
        n,
        i,
        o
    ) {
        e &&
            ((t.translateX = null),
            (t.translateY = null),
            (t.scaleX = null),
            (t.scaleY = null)),
            (t.translateX = autoPrecision(r)),
            (t.translateY = autoPrecision(n)),
            (t.scaleX = i),
            (t.scaleY = o);
    },
    transformTranslate = function transformTranslate(e, t, r, n) {
        e && ((t.translateX = null), (t.translateY = null)),
            (t.translateX = autoPrecision(r)),
            (t.translateY = autoPrecision(n));
    },
    setInnerHTML = function setInnerHTML(e, t) {
        if (!/svg/.test(e.namespaceURI) || "innerHTML" in e) e.innerHTML = t;
        else {
            var r = document.createElement("div");
            r.innerHTML = "<svg>" + t + "</svg>";
            for (var n = r.firstChild; n.firstChild; ) {
                e.appendChild(n.firstChild);
            }
        }
    },
    cropMask = createView({
        ignoreRect: !0,
        ignoreRectUpdate: !0,
        name: "crop-mask",
        tag: "svg",
        mixins: {
            styles: ["opacity", "translateX", "translateY"],
            animations: {
                scale: imageOverlaySpring,
                maskWidth: imageOverlaySpring,
                maskHeight: imageOverlaySpring,
                translateX: imageOverlaySpring,
                translateY: imageOverlaySpring,
                opacity: { type: "tween", delay: 0, duration: 1e3 },
            },
            apis: ["rectangle", "animate", "maskWidth", "maskHeight", "scale"],
        },
        create: function create(e) {
            var t = e.root;
            t.customWriter =
                t.query("GET_CROP_MASK")(t.element, setInnerHTML) ||
                function () {};
        },
        didWriteView: function didWriteView(e) {
            var t = e.root,
                r = e.props,
                n = r.maskWidth,
                i = r.maskHeight,
                o = r.scale;
            if (n && i) {
                t.element.setAttribute("width", autoPrecision(n)),
                    t.element.setAttribute("height", autoPrecision(i));
                var a = t.query("GET_CROP_MASK_INSET");
                t.customWriter(
                    {
                        x: o * a,
                        y: o * a,
                        width: n - o * a * 2,
                        height: i - o * a * 2,
                    },
                    { width: n, height: i }
                );
            }
        },
    }),
    updateText$1 = function updateText$1(e, t) {
        var r = e.childNodes[0];
        r
            ? t !== r.nodeValue && (r.nodeValue = t)
            : ((r = document.createTextNode(t)), e.appendChild(r));
    },
    cropSize = createView({
        ignoreRect: !0,
        name: "crop-size",
        mixins: {
            styles: ["translateX", "translateY", "opacity"],
            animations: {
                translateX: "spring",
                translateY: "spring",
                opacity: "spring",
            },
            listeners: !0,
        },
        create: function create(e) {
            var t = e.root,
                r = createElement("span");
            (r.className = "doka--crop-size-info doka--crop-resize-percentage"),
                (t.ref.resizePercentage = r),
                t.appendChild(r);
            var n = createElement("span");
            n.className = "doka--crop-size-info";
            var i = createElement("span");
            (i.className = "doka--crop-size-multiply"), (i.textContent = "×");
            var o = createElement("span"),
                a = createElement("span");
            (t.ref.outputWidth = o),
                (t.ref.outputHeight = a),
                n.appendChild(o),
                n.appendChild(i),
                n.appendChild(a),
                t.appendChild(n),
                (t.ref.previousValues = { width: 0, height: 0, percentage: 0 });
        },
        write: function write(e) {
            var t = e.root,
                r = e.props,
                n = e.timestamp,
                i = t.query("GET_CROP", r.id, n);
            if (i) {
                var o = i.cropStatus,
                    a = t.ref,
                    c = a.outputWidth,
                    l = a.outputHeight,
                    u = a.resizePercentage,
                    s = a.previousValues,
                    d = o.image,
                    f = o.crop,
                    p = o.currentWidth,
                    h = o.currentHeight,
                    g = d.width ? Math.round((d.width / f.width) * 100) : 0;
                p !== s.width && (updateText$1(c, p), (s.width = p)),
                    h !== s.height && (updateText$1(l, h), (s.height = h)),
                    g !== s.percentage &&
                        (d.width
                            ? updateText$1(u, "".concat(g, "%"))
                            : updateText$1(u, ""),
                        (s.percentage = g));
            }
        },
    }),
    centerZoomButton = function centerZoomButton(e) {
        var t = e.root,
            r = e.props,
            n = e.timestamp,
            i = t.query("GET_CROP", r.id, n),
            o = t.ref.btnZoom;
        if (o) {
            var a = i.cropRect;
            (o.translateX = a.x + 0.5 * a.width),
                (o.translateY = a.y + 0.5 * a.height);
        }
    },
    SPRING_TRANSLATE = {
        type: "spring",
        stiffness: 0.4,
        damping: 0.65,
        mass: 7,
    },
    cropSubject = createView({
        name: "crop-subject",
        ignoreRect: !0,
        mixins: {
            styles: ["opacity", "translateX", "translateY"],
            animations: {
                opacity: { type: "tween", duration: 250 },
                translateX: SPRING_TRANSLATE,
                translateY: SPRING_TRANSLATE,
            },
        },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (t.opacity = 1),
                t.query("GET_CROP_MASK") &&
                    (t.ref.maskView = t.appendChildView(
                        t.createChildView(cropMask)
                    )),
                (t.ref.cropView = t.appendChildView(
                    t.createChildView(cropRect)
                )),
                t.query("GET_CROP_SHOW_SIZE") &&
                    (t.ref.cropSize = t.appendChildView(
                        t.createChildView(cropSize, {
                            id: r.id,
                            opacity: 1,
                            scaleX: 1,
                            scaleY: 1,
                            translateX: null,
                        })
                    )),
                t.query("GET_CROP_ZOOM_TIMEOUT") ||
                    (t.ref.btnZoom = t.appendChildView(
                        t.createChildView(
                            wrapper("zoom-wrapper", {
                                styles: ["opacity", "translateX", "translateY"],
                                animations: {
                                    opacity: { type: "tween", duration: 250 },
                                },
                            }),
                            {
                                opacity: 0,
                                controls: [
                                    {
                                        view: button,
                                        label: t.query(
                                            "GET_LABEL_BUTTON_CROP_ZOOM"
                                        ),
                                        name: "zoom",
                                        icon: createIcon(
                                            '<g fill="currentColor" fill-rule="nonzero"><path d="M12.5 19a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zm0-2a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z"/><path d="M15.765 17.18a1 1 0 1 1 1.415-1.415l3.527 3.528a1 1 0 0 1-1.414 1.414l-3.528-3.527z"/></g>',
                                            26
                                        ),
                                        action: function action() {
                                            return t.dispatch("CROP_ZOOM");
                                        },
                                    },
                                ],
                            }
                        )
                    ));
        },
        write: createRoute(
            { CROP_RECT_DRAG_RELEASE: centerZoomButton },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp,
                    i = t.query("GET_CROP", r.id, n);
                if (i) {
                    var o = t.ref,
                        a = o.cropView,
                        c = o.maskView,
                        l = o.btnZoom,
                        u = o.cropSize,
                        s = i.cropRect,
                        d = i.isRotating,
                        f = i.isDraft,
                        p = i.scale;
                    if (t.query("IS_ACTIVE_VIEW", "crop")) {
                        var h = t.query("GET_STAGE");
                        if (
                            ((t.translateX = h.x - t.rect.element.left),
                            (t.translateY = h.y - t.rect.element.top),
                            (a.draft = f),
                            (a.rotating = d),
                            (a.rectangle = s),
                            (a.enabled = !0),
                            u)
                        ) {
                            f && ((u.translateX = null), (u.translateY = null));
                            var g = getCropSizeOffset(
                                t.rect.element,
                                u.rect.element,
                                s
                            );
                            (u.translateX = f ? g.x : autoPrecision(g.x)),
                                (u.translateY = f ? g.y : autoPrecision(g.y));
                        }
                        c &&
                            (f &&
                                ((c.translateX = null),
                                (c.translateY = null),
                                (c.maskWidth = null),
                                (c.maskHeight = null)),
                            (c.translateX = autoPrecision(s.x)),
                            (c.translateY = autoPrecision(s.y)),
                            (c.maskWidth = s.width),
                            (c.maskHeight = s.height),
                            (c.scale = p)),
                            l &&
                                (l.opacity =
                                    i.canRecenter && !i.isDraft ? 1 : 0);
                    } else a.enabled = !1;
                }
            }
        ),
    }),
    getCropSizeOffset = function getCropSizeOffset(e, t, r) {
        var n = r.x,
            i = r.x + r.width,
            o = r.y + r.height,
            a = i - t.width - 16,
            c = o - t.height - 16;
        return (
            t.width > r.width - 32 &&
                ((a = n + (0.5 * r.width - 0.5 * t.width)),
                (c = o + 16) > e.height - t.height && (c = o - t.height - 16)),
            { x: (a = Math.max(0, Math.min(a, e.width - t.width))), y: c }
        );
    },
    now = function now() {
        return performance.now();
    },
    throttle = function throttle(e, t) {
        var r = null,
            n = null;
        return function () {
            var i = arguments;
            if (!n)
                return e.apply(null, Array.from(arguments)), void (n = now());
            clearTimeout(r),
                (r = setTimeout(function () {
                    now() - n >= t &&
                        (e.apply(null, Array.from(i)), (n = now()));
                }, t - (now() - n)));
        };
    },
    climb = function climb(e, t) {
        for (; 1 === e.nodeType && !t(e); ) {
            e = e.parentNode;
        }
        return 1 === e.nodeType ? e : null;
    },
    isMyTarget = function isMyTarget(e, t) {
        var r = climb(t, function (e) {
            return e.classList.contains("doka--root");
        });
        return !!r && r.contains(e);
    },
    updateIndicators = function updateIndicators(e) {
        var t = e.root,
            r = e.props,
            n = e.action.position,
            i = r.pivotPoint,
            o = t.ref,
            a = o.indicatorA,
            c = o.indicatorB,
            l = i.x - n.x,
            u = i.y - n.y,
            s = { x: i.x + l, y: i.y + u },
            d = { x: i.x - l, y: i.y - u };
        (a.style.cssText = "transform: translate3d("
            .concat(s.x, "px, ")
            .concat(s.y, "px, 0)")),
            (c.style.cssText = "transform: translate3d("
                .concat(d.x, "px, ")
                .concat(d.y, "px, 0)"));
    },
    getPositionFromEvent = function getPositionFromEvent(e) {
        return { x: e.pageX, y: e.pageY };
    },
    cropResize = createView({
        ignoreRect: !0,
        ignoreRectUpdate: !0,
        name: "crop-resizer",
        mixins: { apis: ["pivotPoint", "scrollRect"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (t.ref.isActive = !1),
                (t.ref.isCropping = !1),
                (t.ref.indicatorA = document.createElement("div")),
                t.appendChild(t.ref.indicatorA),
                (t.ref.indicatorB = document.createElement("div")),
                t.appendChild(t.ref.indicatorB);
            var n = t.query("GET_CROP_RESIZE_KEY_CODES"),
                i = {
                    origin: { x: null, y: null },
                    position: { x: null, y: null },
                    selecting: !1,
                    enabled: !1,
                    scrollY: 0,
                    offsetX: 0,
                    offsetY: 0,
                },
                o = now();
            t.ref.state = i;
            var a = createPointerRegistry(),
                c = 0,
                l = !1;
            (t.ref.resizeStart = function (e) {
                if (
                    t.ref.isActive &&
                    (0 === a.count() && (l = !1),
                    a.push(e),
                    addEvent$1(document.documentElement, "up", t.ref.resizeEnd),
                    isMyTarget(t.element, e.target) && a.multiple())
                ) {
                    e.stopPropagation(), e.preventDefault();
                    var r = a.active(),
                        n = getPositionFromEvent(r[0]),
                        i = getPositionFromEvent(r[1]);
                    (c = vectorDistance(n, i)),
                        addEvent$1(
                            document.documentElement,
                            "move",
                            t.ref.resizeMove
                        ),
                        (l = !0);
                }
            }),
                (t.ref.resizeMove = function (e) {
                    if (
                        t.ref.isActive &&
                        l &&
                        (e.preventDefault(), 2 === a.count())
                    ) {
                        a.update(e);
                        var r = a.active(),
                            n = getPositionFromEvent(r[0]),
                            i = getPositionFromEvent(r[1]),
                            o = (vectorDistance(n, i) - c) / c;
                        t.dispatch("CROP_IMAGE_RESIZE", { value: o });
                    }
                }),
                (t.ref.resizeEnd = function (e) {
                    if (t.ref.isActive) {
                        a.pop(e);
                        var r = 0 === a.count();
                        r &&
                            (removeEvent$1(
                                document.documentElement,
                                "move",
                                t.ref.resizeMove
                            ),
                            removeEvent$1(
                                document.documentElement,
                                "up",
                                t.ref.resizeEnd
                            )),
                            l &&
                                (e.preventDefault(),
                                r && t.dispatch("CROP_IMAGE_RESIZE_RELEASE"));
                    }
                }),
                addEvent$1(document.documentElement, "down", t.ref.resizeStart);
            var u = performance.now(),
                s = 0,
                d = 1,
                f = throttle(function (e) {
                    if (!t.ref.isCropping) {
                        var r = Math.sign(e.wheelDelta || e.deltaY),
                            n = now(),
                            i = n - u;
                        (u = n),
                            (i > 750 || s !== r) && ((d = 1), (s = r)),
                            (d += 0.05 * r),
                            t.dispatch("CROP_IMAGE_RESIZE_MULTIPLY", {
                                value: Math.max(0.1, d),
                            }),
                            t.dispatch("CROP_IMAGE_RESIZE_RELEASE");
                    }
                }, 100);
            (t.ref.wheel = function (e) {
                if (t.ref.isActive && isMyTarget(t.element, e.target)) {
                    if (r.scrollRect) {
                        var n = r.scrollRect,
                            i = t.query("GET_ROOT"),
                            o = getPositionFromEvent(e),
                            a = { x: o.x - i.leftScroll, y: o.y - i.topScroll };
                        if (
                            a.x < n.x ||
                            a.x > n.x + n.width ||
                            a.y < n.y ||
                            a.y > n.y + n.height
                        )
                            return;
                    }
                    e.preventDefault(), f(e);
                }
            }),
                document.addEventListener("wheel", t.ref.wheel, {
                    passive: !1,
                }),
                (t.ref.move = function (e) {
                    if (
                        t.ref.isActive &&
                        !t.ref.isCropping &&
                        ((i.position.x = e.pageX - t.ref.state.offsetX),
                        (i.position.y =
                            e.pageY -
                            t.ref.state.scrollY -
                            t.ref.state.offsetY),
                        i.enabled)
                    )
                        if (isMyTarget(t.element, e.target)) {
                            "idle" === t.element.dataset.state &&
                                t.dispatch("RESIZER_SHOW", {
                                    position: _objectSpread({}, i.position),
                                }),
                                e.preventDefault(),
                                t.dispatch("RESIZER_MOVE", {
                                    position: _objectSpread({}, i.position),
                                });
                            var n = r.pivotPoint,
                                a = n.x - i.position.x,
                                l = n.y - i.position.y,
                                u = { x: n.x + a, y: n.y + l },
                                s = _objectSpread({}, i.position);
                            if (i.selecting) {
                                var d = (vectorDistance(u, s) - c) / c,
                                    f = performance.now();
                                f - o > 25 &&
                                    ((o = f),
                                    t.dispatch("CROP_IMAGE_RESIZE", {
                                        value: d,
                                    }));
                            }
                        } else t.dispatch("RESIZER_CANCEL");
                }),
                (t.ref.select = function (e) {
                    if (t.ref.isActive && isMyTarget(t.element, e.target)) {
                        var n = r.pivotPoint,
                            o = n.x - i.position.x,
                            a = n.y - i.position.y,
                            l = { x: n.x + o, y: n.y + a },
                            u = i.position;
                        (c = vectorDistance(l, u)),
                            (i.selecting = !0),
                            (i.origin.x = e.pageX),
                            (i.origin.y = e.pageY),
                            t.dispatch("CROP_IMAGE_RESIZE_GRAB");
                    }
                }),
                (t.ref.confirm = function (e) {
                    t.ref.isActive &&
                        isMyTarget(t.element, e.target) &&
                        ((i.selecting = !1),
                        t.dispatch("CROP_IMAGE_RESIZE_RELEASE"));
                }),
                (t.ref.blur = function () {
                    t.ref.isActive &&
                        ((i.selecting = !1),
                        (i.enabled = !1),
                        document.removeEventListener("mousedown", t.ref.select),
                        document.removeEventListener("mouseup", t.ref.confirm),
                        t.dispatch("RESIZER_CANCEL"));
                }),
                window.addEventListener("blur", t.ref.blur),
                document.addEventListener("mousemove", t.ref.move),
                (t.ref.keyDown = function (e) {
                    t.ref.isActive &&
                        n.includes(e.keyCode) &&
                        i.position &&
                        ((i.enabled = !0),
                        document.addEventListener("mousedown", t.ref.select),
                        document.addEventListener("mouseup", t.ref.confirm),
                        t.dispatch("RESIZER_SHOW", {
                            position: _objectSpread({}, i.position),
                        }));
                }),
                (t.ref.keyUp = function (e) {
                    t.ref.isActive &&
                        n.includes(e.keyCode) &&
                        ((i.enabled = !1),
                        document.removeEventListener("mousedown", t.ref.select),
                        document.removeEventListener("mouseup", t.ref.confirm),
                        t.dispatch("RESIZER_CANCEL"));
                }),
                document.body.addEventListener("keydown", t.ref.keyDown),
                document.body.addEventListener("keyup", t.ref.keyUp);
        },
        destroy: function destroy(e) {
            var t = e.root;
            document.removeEventListener("touchmove", t.ref.resizeMove),
                document.removeEventListener("touchend", t.ref.resizeEnd),
                document.removeEventListener("touchstart", t.ref.resizeStart),
                document.removeEventListener("wheel", t.ref.wheel),
                document.removeEventListener("mousemove", t.ref.move),
                document.removeEventListener("mousedown", t.ref.select),
                document.removeEventListener("mouseup", t.ref.confirm),
                document.body.removeEventListener("keydown", t.ref.keyDown),
                document.body.removeEventListener("keyup", t.ref.keyUp),
                window.removeEventListener("blur", t.ref.blur);
        },
        read: function read(e) {
            var t = e.root;
            t.ref.state.scrollY = window.scrollY;
            var r = t.element.getBoundingClientRect();
            (t.ref.state.offsetX = r.x), (t.ref.state.offsetY = r.y);
        },
        write: createRoute({
            CROP_RECT_DRAG_GRAB: function CROP_RECT_DRAG_GRAB(e) {
                e.root.ref.isCropping = !0;
            },
            CROP_RECT_DRAG_RELEASE: function CROP_RECT_DRAG_RELEASE(e) {
                e.root.ref.isCropping = !1;
            },
            SHOW_VIEW: function SHOW_VIEW(e) {
                var t = e.root,
                    r = e.action;
                t.ref.isActive = "crop" === r.id;
            },
            RESIZER_SHOW: function RESIZER_SHOW(e) {
                var t = e.root,
                    r = e.props,
                    n = e.action;
                (t.element.dataset.state = "multi-touch"),
                    updateIndicators({ root: t, props: r, action: n });
            },
            RESIZER_CANCEL: function RESIZER_CANCEL(e) {
                e.root.element.dataset.state = "idle";
            },
            RESIZER_MOVE: updateIndicators,
        }),
    }),
    createGroup = function createGroup() {
        var e =
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : "group",
            t =
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : ["opacity"],
            r =
                arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : {};
        return createView({
            ignoreRect: !0,
            name: e,
            mixins: {
                styles: ["opacity"].concat(_toConsumableArray(t)),
                animations: _objectSpread(
                    {
                        opacity: {
                            type: "spring",
                            stiffness: 0.25,
                            damping: 0.5,
                            mass: 5,
                        },
                    },
                    r
                ),
            },
            create: function create(e) {
                var t = e.root;
                e.props.controls.map(function (e) {
                    var r = t.createChildView(e.view, e);
                    e.didCreateView && e.didCreateView(r), t.appendChildView(r);
                });
            },
        });
    },
    list = createView({
        ignoreRect: !0,
        tag: "div",
        name: "dropdown-list",
        mixins: {
            styles: ["translateY", "opacity"],
            apis: ["selectedValue", "options", "onSelect"],
            animations: {
                translateY: "spring",
                opacity: { type: "tween", duration: 250 },
            },
        },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            t.element.setAttribute("role", "list"),
                (t.ref.handleClick = function () {
                    return r.action && r.action();
                }),
                t.element.addEventListener("click", t.ref.handleClick),
                (t.ref.activeOptions = null),
                t.ref.activeSelectedValue;
        },
        write: function write(e) {
            var t = e.root,
                r = e.props;
            if (
                (r.options !== t.ref.activeOptions &&
                    ((t.ref.activeOptions = r.options),
                    t.childViews.forEach(function (e) {
                        return t.removeChildView(e);
                    }),
                    r.options.map(function (e) {
                        var n = t.createChildView(
                            button,
                            _objectSpread({}, e, {
                                action: function action() {
                                    return r.onSelect(e.value);
                                },
                            })
                        );
                        return t.appendChildView(n);
                    })),
                r.selectedValue !== t.ref.activeSelectedValue)
            ) {
                t.ref.activeSelectedValue = r.selectedValue;
                var n = r.options.findIndex(function (e) {
                    return "object" === _typeof(e.value) && r.selectedValue
                        ? JSON.stringify(e.value) ===
                              JSON.stringify(r.selectedValue)
                        : e.value === r.selectedValue;
                });
                t.childViews.forEach(function (e, t) {
                    e.element.setAttribute("aria-selected", t === n);
                });
            }
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.element.removeEventListener("click", t.ref.handleClick);
        },
    }),
    dropdown = createView({
        ignoreRect: !0,
        tag: "div",
        name: "dropdown",
        mixins: {
            styles: ["opacity"],
            animations: { opacity: "spring" },
            apis: ["direction", "selectedValue", "options", "onSelect"],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            t.ref.open = !1;
            var n = function n(e) {
                (t.ref.open = e), t.dispatch("KICK");
            };
            (t.ref.button = t.appendChildView(
                t.createChildView(
                    button,
                    _objectSpread({}, r, {
                        action: function action() {
                            n(!t.ref.open);
                        },
                    })
                )
            )),
                (t.ref.list = t.appendChildView(
                    t.createChildView(
                        list,
                        _objectSpread({}, r, {
                            opacity: 0,
                            action: function action() {
                                n(!1);
                            },
                        })
                    )
                )),
                (t.ref.handleBodyClick = function (e) {
                    t.element.contains(e.target) || n(!1);
                }),
                t.element.addEventListener("focusin", function (e) {
                    e.target !== t.ref.button.element && n(!0);
                }),
                t.element.addEventListener("focusout", function (e) {
                    t.element.contains(e.relatedTarget) || n(!1);
                }),
                document.body.addEventListener("click", t.ref.handleBodyClick);
        },
        destroy: function destroy(e) {
            var t = e.root;
            document.body.removeEventListener("click", t.ref.handleBodyClick);
        },
        write: function write(e) {
            var t = e.root,
                r = e.props;
            if (
                ((t.ref.list.opacity = t.ref.open ? 1 : 0),
                (t.ref.list.selectedValue = r.selectedValue),
                (t.ref.list.options = r.options),
                "up" === r.direction)
            ) {
                var n = t.ref.list.rect.element.height;
                t.ref.list.translateY =
                    (t.ref.open ? -(n + 5) : -n) - t.rect.element.height;
            } else t.ref.list.translateY = t.ref.open ? 0 : -5;
        },
    }),
    setOpacity = function setOpacity(e, t) {
        return (e.style.opacity = t);
    },
    updateImageBoundsIcon = function updateImageBoundsIcon(e, t) {
        var r = Array.from(
            e.element.querySelectorAll(".doka--icon-crop-limit rect")
        );
        r.length &&
            (setOpacity(r[0], t ? 0.3 : 0),
            setOpacity(r[1], t ? 1 : 0),
            setOpacity(r[2], t ? 0 : 0.3),
            setOpacity(r[3], t ? 0 : 1));
    },
    updateAspectRatioIcon = function updateAspectRatioIcon(e, t) {
        var r = e.element.querySelectorAll(".doka--icon-aspect-ratio rect");
        if (r.length) {
            if (!t)
                return (
                    setOpacity(r[0], 0.2),
                    setOpacity(r[1], 0.3),
                    void setOpacity(r[2], 0.4)
                );
            setOpacity(r[0], t > 1 ? 1 : 0.3),
                setOpacity(r[1], 1 === t ? 0.85 : 0.5),
                setOpacity(r[2], t < 1 ? 1 : 0.3);
        }
    },
    updateTurnIcons = function updateTurnIcons(e, t) {
        Array.from(e.element.querySelectorAll(".doka--icon-turn rect")).forEach(
            function (e) {
                t > 1 &&
                    (e.setAttribute("x", e.previousElementSibling ? 5 : 4),
                    e.setAttribute("width", 9)),
                    t < 1 &&
                        (e.setAttribute("y", 11), e.setAttribute("height", 10));
            }
        );
    },
    createRectangle = function createRectangle(e) {
        var t, r;
        e > 1
            ? ((r = 14), (t = Math.round(r / e)))
            : ((t = 14), (r = Math.round(t * e)));
        var n = Math.round(0.5 * (23 - t)),
            i = Math.round(0.5 * (23 - r));
        return '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><g fill="currentColor"><rect x="'
            .concat(n, '" y="')
            .concat(i, '" width="')
            .concat(t, '" height="')
            .concat(r, '" rx="2.5"/></g></svg>');
    },
    cropRoot = createView({
        name: "crop",
        ignoreRect: !0,
        mixins: { apis: ["viewId", "stagePosition", "hidden", "offsetTop"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (r.viewId = "crop"), (r.hidden = !1), (t.ref.isHiding = !1);
            var n = [];
            t.query("GET_CROP_ALLOW_IMAGE_TURN_LEFT") &&
                n.push({
                    view: button,
                    name: "tool",
                    label: t.query("GET_LABEL_BUTTON_CROP_ROTATE_LEFT"),
                    icon: createIcon(
                        '<g transform="translate(3 2)" fill="currentColor" fill-rule="evenodd" class="doka--icon-turn"><rect y="9" width="12" height="12" rx="1"/><path d="M9.823 5H11a5 5 0 0 1 5 5 1 1 0 0 0 2 0 7 7 0 0 0-7-7H9.626l.747-.747A1 1 0 0 0 8.958.84L6.603 3.194a1 1 0 0 0 0 1.415l2.355 2.355a1 1 0 0 0 1.415-1.414L9.823 5z" fill-rule="nonzero" /></g>',
                        26
                    ),
                    action: function action() {
                        return t.dispatch("CROP_IMAGE_ROTATE_LEFT");
                    },
                }),
                t.query("GET_CROP_ALLOW_IMAGE_TURN_RIGHT") &&
                    n.push({
                        view: button,
                        name: "tool",
                        label: t.query("GET_LABEL_BUTTON_CROP_ROTATE_RIGHT"),
                        icon: createIcon(
                            '<g transform="translate(5 2)" fill="currentColor" fill-rule="evenodd" class="doka--icon-turn"><path d="M8.177 5H7a5 5 0 0 0-5 5 1 1 0 0 1-2 0 7 7 0 0 1 7-7h1.374l-.747-.747A1 1 0 0 1 9.042.84l2.355 2.355a1 1 0 0 1 0 1.415L9.042 6.964A1 1 0 0 1 7.627 5.55l.55-.55z" fill-rule="nonzero"/><rect x="6" y="9" width="12" height="12" rx="1"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("CROP_IMAGE_ROTATE_RIGHT");
                        },
                    }),
                t.query("GET_CROP_ALLOW_IMAGE_FLIP_HORIZONTAL") &&
                    n.push({
                        view: button,
                        name: "tool",
                        label: t.query("GET_LABEL_BUTTON_CROP_FLIP_HORIZONTAL"),
                        icon: createIcon(
                            '<g fill="currentColor" fill-rule="evenodd"><path d="M11.93 7.007V20a1 1 0 0 1-1 1H5.78a1 1 0 0 1-.93-1.368l5.15-12.993a1 1 0 0 1 1.929.368z"/><path d="M14 7.007V20a1 1 0 0 0 1 1h5.149a1 1 0 0 0 .93-1.368l-5.15-12.993A1 1 0 0 0 14 7.007z" opacity=".6"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("CROP_IMAGE_FLIP_HORIZONTAL");
                        },
                    }),
                t.query("GET_CROP_ALLOW_IMAGE_FLIP_HORIZONTAL") &&
                    n.push({
                        view: button,
                        name: "tool",
                        label: t.query("GET_LABEL_BUTTON_CROP_FLIP_VERTICAL"),
                        icon: createIcon(
                            '<g fill="currentColor" fill-rule="evenodd"><path d="M19.993 12.143H7a1 1 0 0 1-1-1V5.994a1 1 0 0 1 1.368-.93l12.993 5.15a1 1 0 0 1-.368 1.93z"/><path d="M19.993 14a1 1 0 0 1 .368 1.93L7.368 21.078A1 1 0 0 1 6 20.148V15a1 1 0 0 1 1-1h12.993z" opacity=".6"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("CROP_IMAGE_FLIP_VERTICAL");
                        },
                    });
            var i = t.query("GET_CROP_ASPECT_RATIO_OPTIONS");
            i &&
                i.length &&
                n.push({
                    view: dropdown,
                    name: "tool",
                    label: t.query("GET_LABEL_BUTTON_CROP_ASPECT_RATIO"),
                    icon: createIcon(
                        '<g class="doka--icon-aspect-ratio" fill="currentColor" fill-rule="evenodd"><rect x="2" y="4" opacity=".3" width="10" height="18" rx="1"/><rect opacity=".5" x="4" y="8" width="14" height="14" rx="1"/><rect x="6" y="12" width="17" height="10" rx="1"/></g>',
                        26
                    ),
                    options: null,
                    onSelect: function onSelect(e) {
                        e.width && e.height
                            ? t.dispatch("RESIZE_SET_OUTPUT_SIZE", {
                                  width: e.width,
                                  height: e.height,
                              })
                            : (t
                                  .query("GET_CROP_ASPECT_RATIO_OPTIONS")
                                  .find(function (e) {
                                      return (
                                          (e.value && e.value.width) ||
                                          e.value.height
                                      );
                                  }) &&
                                  t.dispatch("RESIZE_SET_OUTPUT_SIZE", {
                                      width: null,
                                      height: null,
                                  }),
                              t.dispatch("CROP_SET_ASPECT_RATIO", {
                                  value: e.aspectRatio,
                              }));
                    },
                    didCreateView: function didCreateView(e) {
                        t.ref.aspectRatioDropdown = e;
                    },
                }),
                t.query("GET_CROP_ALLOW_TOGGLE_LIMIT") &&
                    n.push({
                        view: dropdown,
                        name: "tool",
                        label: t.query("GET_LABEL_BUTTON_CROP_TOGGLE_LIMIT"),
                        icon: createIcon(
                            '<g class="doka--icon-crop-limit" fill="currentColor" fill-rule="evenodd">\n                    <rect x="2" y="3" width="20" height="20" rx="1"/>\n                    <rect x="7" y="8" width="10" height="10" rx="1"/>\n                    <rect x="4" y="8" width="14" height="14" rx="1"/>\n                    <rect x="12" y="4" width="10" height="10" rx="1"/>\n                </g>',
                            26
                        ),
                        options: [
                            {
                                value: !0,
                                label: t.query(
                                    "GET_LABEL_BUTTON_CROP_TOGGLE_LIMIT_ENABLE"
                                ),
                                icon: '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><g fill="currentColor"><rect x="3" y="3" width="17" height="17" rx="2.5" opacity=".3"/><rect x="7" y="7" width="9" height="9" rx="2.5"/></g></svg>',
                            },
                            {
                                value: !1,
                                label: t.query(
                                    "GET_LABEL_BUTTON_CROP_TOGGLE_LIMIT_DISABLE"
                                ),
                                icon: '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><g fill="currentColor"><rect x="3" y="6" width="13" height="13" rx="2.5" opacity=".3"/><rect x="10" y="3" width="9" height="9" rx="2.5"/></g></svg>',
                            },
                        ],
                        onSelect: function onSelect(e) {
                            t.dispatch("CROP_SET_LIMIT", { value: e });
                        },
                        didCreateView: function didCreateView(e) {
                            t.ref.cropToggleLimitDropdown = e;
                        },
                    }),
                (t.ref.menu = t.appendChildView(
                    t.createChildView(
                        createGroup("toolbar", ["opacity"], {
                            opacity: { type: "spring", mass: 15, delay: 50 },
                        }),
                        { opacity: 0, controls: n }
                    )
                )),
                (t.ref.menuItemsRequiredWidth = null),
                (t.ref.subject = t.appendChildView(
                    t.createChildView(cropSubject, _objectSpread({}, r))
                )),
                (t.ref.rotator = t.appendChildView(
                    t.createChildView(cropRotator, {
                        rotation: 0,
                        opacity: 0,
                        translateY: 20,
                        id: r.id,
                    })
                )),
                (t.ref.resizer = t.appendChildView(
                    t.createChildView(cropResize, {
                        pivotPoint: { x: 0, y: 0 },
                    })
                )),
                (t.ref.updateControls = function () {
                    var e = t.query("GET_IMAGE");
                    if (
                        (updateTurnIcons(t, e.height / e.width),
                        t.ref.cropToggleLimitDropdown &&
                            ((t.ref.isLimitedToImageBounds = t.query(
                                "GET_CROP_LIMIT_TO_IMAGE_BOUNDS"
                            )),
                            updateImageBoundsIcon(
                                t,
                                t.ref.isLimitedToImageBounds
                            ),
                            (t.ref.cropToggleLimitDropdown.selectedValue =
                                t.ref.isLimitedToImageBounds)),
                        t.ref.aspectRatioDropdown)
                    ) {
                        var r = t.query("GET_MIN_IMAGE_SIZE"),
                            n = i.filter(function (t) {
                                if (!t.value.aspectRatio) return !0;
                                if (t.value.aspectRatio < 1) {
                                    if (
                                        e.naturalWidth * t.value.aspectRatio <
                                        r.height
                                    )
                                        return !1;
                                } else if (e.naturalHeight / t.value.aspectRatio < r.width) return !1;
                                return !0;
                            });
                        t.ref.aspectRatioDropdown.options = n.map(function (e) {
                            return _objectSpread({}, e, {
                                icon: createRectangle(e.value.aspectRatio),
                            });
                        });
                    }
                }),
                (t.ref.isModal = /modal|fullscreen/.test(
                    t.query("GET_STYLE_LAYOUT_MODE")
                ));
        },
        read: function read(e) {
            var t = e.root,
                r = e.props;
            if (r.hidden) t.ref.menuItemsRequiredWidth = null;
            else {
                var n = t.rect;
                if (0 !== n.element.width && 0 !== n.element.height) {
                    if (null === t.ref.menuItemsRequiredWidth) {
                        var i = t.ref.menu.childViews.reduce(function (e, t) {
                            return e + t.rect.outer.width;
                        }, 0);
                        t.ref.menuItemsRequiredWidth = 0 === i ? null : i;
                    }
                    var o = t.ref.subject.rect.element,
                        a = o.left,
                        c = o.top,
                        l = o.width,
                        u = o.height;
                    r.stagePosition = { x: a, y: c, width: l, height: u };
                }
            }
        },
        shouldUpdateChildViews: function shouldUpdateChildViews(e) {
            var t = e.props,
                r = e.actions;
            return !t.hidden || (t.hidden && r && r.length);
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action,
                        n = e.props,
                        i = t.ref,
                        o = i.menu,
                        a = i.rotator,
                        c = i.subject;
                    n.viewId === r.id
                        ? ((c.opacity = 1),
                          (o.opacity = 1),
                          (a.opacity = 1),
                          (a.translateY = 0),
                          (n.hidden = !1),
                          (t.ref.isHiding = !1),
                          t.ref.updateControls())
                        : ((c.opacity = 0),
                          (o.opacity = 0),
                          (a.opacity = 0),
                          (a.translateY = 20),
                          (t.ref.isHiding = !0));
                },
                UNLOAD_IMAGE: function UNLOAD_IMAGE(e) {
                    var t = e.root.ref,
                        r = t.menu,
                        n = t.rotator;
                    (r.opacity = 0), (n.opacity = 0), (n.translateY = 20);
                },
                DID_PRESENT_IMAGE: function DID_PRESENT_IMAGE(e) {
                    var t = e.root,
                        r = t.ref,
                        n = r.menu,
                        i = r.rotator;
                    (n.opacity = 1),
                        (i.opacity = 1),
                        (i.translateY = 0),
                        t.ref.updateControls();
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp,
                    i = t.ref,
                    o = i.resizer,
                    a = i.subject,
                    c = i.menu,
                    l = i.rotator,
                    u = i.isHiding,
                    s = i.cropToggleLimitDropdown,
                    d = i.aspectRatioDropdown,
                    f = r.hidden,
                    p = 0 === a.opacity && 0 === c.opacity && 0 === l.opacity;
                if (
                    (!f && u && p && ((t.ref.isHiding = !1), (r.hidden = !0)),
                    !r.hidden)
                ) {
                    var h = t.query("GET_CROP", r.id, n);
                    if (h) {
                        if (d) {
                            var g = t.query("GET_ACTIVE_CROP_ASPECT_RATIO"),
                                m = t.query("GET_SIZE"),
                                v = d.selectedValue;
                            v
                                ? (v.aspectRatio !== g &&
                                      updateAspectRatioIcon(t, g),
                                  (v.aspectRatio === g &&
                                      v.width === m.width &&
                                      v.height === m.height) ||
                                      (d.selectedValue = {
                                          aspectRatio: g,
                                          width: m.width,
                                          height: m.height,
                                      }))
                                : ((d.selectedValue = {
                                      aspectRatio: g,
                                      width: m.width,
                                      height: m.height,
                                  }),
                                  updateAspectRatioIcon(t, g));
                        }
                        if (
                            (s &&
                                t.ref.isLimitedToImageBounds !==
                                    h.isLimitedToImageBounds &&
                                ((t.ref.isLimitedToImageBounds =
                                    h.isLimitedToImageBounds),
                                updateImageBoundsIcon(
                                    t,
                                    h.isLimitedToImageBounds
                                ),
                                (s.selectedValue = h.isLimitedToImageBounds)),
                            (o.pivotPoint = {
                                x: 0.5 * o.rect.element.width,
                                y: 0.5 * o.rect.element.height,
                            }),
                            (l.animate = !h.isDraft),
                            (l.rotation = h.rotation.sub),
                            l.setAllowInteraction(
                                t.query("IS_ACTIVE_VIEW", "crop")
                            ),
                            (c.element.dataset.layout =
                                t.ref.menuItemsRequiredWidth >
                                t.ref.menu.rect.element.width
                                    ? "compact"
                                    : "spacious"),
                            t.query("GET_CROP_RESIZE_SCROLL_RECT_ONLY"))
                        ) {
                            var y = t.query("GET_STAGE"),
                                E = y.x,
                                T = y.y,
                                _ = t.query("GET_ROOT"),
                                R = t.ref.isModal ? _.left : 0,
                                w = t.ref.isModal ? _.top : 0;
                            o.scrollRect = {
                                x: R + E + h.cropRect.x,
                                y: w + T + h.cropRect.y + r.offsetTop,
                                width: h.cropRect.width,
                                height: h.cropRect.height,
                            };
                        }
                    }
                }
            }
        ),
    }),
    sizeInput = createView({
        name: "size-input",
        mixins: {
            listeners: !0,
            apis: [
                "id",
                "value",
                "placeholder",
                "getValue",
                "setValue",
                "setPlaceholder",
                "hasFocus",
                "onChange",
            ],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props,
                n = r.id,
                i = r.min,
                o = r.max,
                a = r.value,
                c = r.placeholder,
                l = r.onChange,
                u = void 0 === l ? function () {} : l,
                s = r.onBlur,
                d = void 0 === s ? function () {} : s,
                f = "doka--".concat(n, "-").concat(getUniqueId()),
                p = createElement("input", {
                    type: "number",
                    step: 1,
                    id: f,
                    min: i,
                    max: o,
                    value: a,
                    placeholder: c,
                }),
                h = p.getAttribute("max").length,
                g = createElement("label", { for: f });
            g.textContent = r.label;
            var m = function m(e, t, r) {
                    return (
                        isString(e)
                            ? ((e = e.replace(/[^0-9]/g, "")).length > h &&
                                  (e = e.slice(0, h)),
                              (e = parseInt(e, 10)))
                            : (e = Math.round(e)),
                        isNaN(e) ? null : limit(e, t, r)
                    );
                },
                v = function v(e) {
                    return e.length ? parseInt(p.value, 10) : null;
                };
            (t.ref.handleInput = function () {
                (p.value = m(p.value, 1, o)), u(v(p.value));
            }),
                (t.ref.handleBlur = function () {
                    (p.value = m(p.value, i, o)), d(v(p.value));
                }),
                p.addEventListener("input", t.ref.handleInput),
                p.addEventListener("blur", t.ref.handleBlur),
                t.appendChild(p),
                t.appendChild(g),
                (t.ref.input = p),
                (r.hasFocus = function () {
                    return p === document.activeElement;
                }),
                (r.getValue = function () {
                    return v(p.value);
                }),
                (r.setValue = function (e) {
                    return (p.value = e ? m(e, 1, 999999) : null);
                }),
                (r.setPlaceholder = function (e) {
                    return (p.placeholder = e);
                });
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.ref.input.removeEventListener("input", t.ref.handleInput),
                t.ref.input.removeEventListener("blur", t.ref.handleBlur);
        },
    }),
    checkboxInput = createView({
        name: "checkable",
        tag: "span",
        mixins: {
            listeners: !0,
            apis: [
                "id",
                "checked",
                "onChange",
                "onSetValue",
                "setValue",
                "getValue",
            ],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props,
                n = r.id,
                i = r.checked,
                o = r.onChange,
                a = void 0 === o ? function () {} : o,
                c = r.onSetValue,
                l = void 0 === c ? function () {} : c,
                u = "doka--".concat(n, "-").concat(getUniqueId()),
                s = createElement("input", {
                    type: "checkbox",
                    value: 1,
                    id: u,
                });
            (s.checked = i), (t.ref.input = s);
            var d = createElement("label", { for: u });
            (d.innerHTML = r.label),
                t.appendChild(s),
                t.appendChild(d),
                (t.ref.handleChange = function () {
                    l(s.checked), a(s.checked);
                }),
                s.addEventListener("change", t.ref.handleChange),
                (r.getValue = function () {
                    return s.checked;
                }),
                (r.setValue = function (e) {
                    (s.checked = e), l(s.checked);
                }),
                setTimeout(function () {
                    l(s.checked);
                }, 0);
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.ref.input.removeEventListener("change", t.ref.handleChange);
        },
    }),
    testResult$1 = null,
    isAndroid = function isAndroid() {
        return (
            null === testResult$1 &&
                (testResult$1 = /Android/i.test(navigator.userAgent)),
            testResult$1
        );
    },
    resizeForm = createView({
        ignoreRect: !0,
        name: "resize-form",
        tag: "form",
        mixins: {
            styles: ["opacity"],
            animations: { opacity: { type: "spring", mass: 15, delay: 150 } },
        },
        create: function create(e) {
            var t = e.root;
            t.element.setAttribute("novalidate", "novalidate"),
                t.element.setAttribute("action", "#"),
                (t.ref.shouldBlurKeyboard = isIOS() || isAndroid());
            var r = t.query("GET_SIZE_MAX"),
                n = t.query("GET_SIZE_MIN"),
                i = function i() {
                    var e =
                            arguments.length > 0 && void 0 !== arguments[0]
                                ? arguments[0]
                                : {},
                        i = e.axisLock,
                        o = void 0 === i ? "none" : i,
                        a = e.enforceLimits,
                        c = void 0 !== a && a,
                        l = t.ref,
                        u = l.inputImageWidth,
                        s = l.inputImageHeight,
                        d = l.buttonConfirm,
                        f = t.query("GET_SIZE_ASPECT_RATIO_LOCK"),
                        p = t.query("GET_CROP_RECTANGLE_ASPECT_RATIO"),
                        h = { width: u.getValue(), height: s.getValue() },
                        g = limitSize(
                            h,
                            c ? n : { width: 1, height: 1 },
                            c ? r : { width: 999999, height: 999999 },
                            f ? p : null,
                            o
                        );
                    if (f)
                        "width" === o
                            ? s.setValue(g.width / p)
                            : "height" === o
                            ? u.setValue(g.height * p)
                            : (u.setValue(g.width || g.height * p),
                              s.setValue(g.height || g.width / p));
                    else if (g.width && !g.height) {
                        var m = Math.round(g.width / p),
                            v = limitSize(
                                { width: g.width, height: m },
                                c ? n : { width: 1, height: 1 },
                                c ? r : { width: 999999, height: 999999 },
                                p,
                                o
                            );
                        c && u.setValue(Math.round(v.width)),
                            s.setPlaceholder(Math.round(v.height));
                    } else if (g.height && !g.width) {
                        var y = Math.round(g.height * p);
                        u.setPlaceholder(y);
                    }
                    var E = t.query("GET_SIZE_INPUT"),
                        T = E.width,
                        _ = E.height,
                        R = isNumber(T) ? Math.round(T) : null,
                        w = isNumber(_) ? Math.round(_) : null,
                        I = u.getValue(),
                        A = s.getValue(),
                        C = I !== R || A !== w;
                    return (
                        (d.opacity = C ? 1 : 0),
                        t.dispatch("KICK"),
                        { width: u.getValue(), height: s.getValue() }
                    );
                },
                o = t;
            t.appendChildView(
                t.createChildView(
                    createFieldGroup("Image size", function (e) {
                        var t = e.root,
                            a = t.query("GET_SIZE"),
                            c = t.appendChildView(
                                t.createChildView(sizeInput, {
                                    id: "image-width",
                                    label: "Width",
                                    value: isNumber(a.width)
                                        ? Math.round(a.width)
                                        : null,
                                    min: n.width,
                                    max: r.width,
                                    placeholder: 0,
                                    onChange: function onChange() {
                                        return i({ axisLock: "width" });
                                    },
                                    onBlur: function onBlur() {
                                        return i({ enforceLimits: !1 });
                                    },
                                })
                            ),
                            l = t.appendChildView(
                                t.createChildView(checkboxInput, {
                                    id: "aspect-ratio-lock",
                                    label: createIcon(
                                        '<g fill="none" fill-rule="evenodd"><path stroke="currentColor" stroke-width="1.5" stroke-linecap="round" class="doka--aspect-ratio-lock-ring" d="M9.401 10.205v-.804a2.599 2.599 0 0 1 5.198 0V14"/><rect fill="currentColor" x="7" y="10" width="10" height="7" rx="1.5"/></g>'
                                    ),
                                    checked: t.query(
                                        "GET_SIZE_ASPECT_RATIO_LOCK"
                                    ),
                                    onSetValue: function onSetValue(e) {
                                        var t = e ? 0 : -3;
                                        l.element
                                            .querySelector(
                                                ".doka--aspect-ratio-lock-ring"
                                            )
                                            .setAttribute(
                                                "transform",
                                                "translate(0 ".concat(t, ")")
                                            );
                                    },
                                    onChange: function onChange(e) {
                                        t.dispatch(
                                            "RESIZE_SET_OUTPUT_SIZE_ASPECT_RATIO_LOCK",
                                            { value: e }
                                        ),
                                            i();
                                    },
                                })
                            ),
                            u = t.appendChildView(
                                t.createChildView(sizeInput, {
                                    id: "image-height",
                                    label: "Height",
                                    value: isNumber(a.height)
                                        ? Math.round(a.height)
                                        : null,
                                    min: n.height,
                                    max: r.height,
                                    placeholder: 0,
                                    onChange: function onChange() {
                                        return i({ axisLock: "height" });
                                    },
                                    onBlur: function onBlur() {
                                        return i({ enforceLimits: !1 });
                                    },
                                })
                            );
                        (o.ref.aspectRatioLock = l),
                            (o.ref.inputImageWidth = c),
                            (o.ref.inputImageHeight = u);
                    })
                )
            ),
                (t.ref.buttonConfirm = t.appendChildView(
                    t.createChildView(button, {
                        name: "app action-confirm icon-only",
                        label: "Apply",
                        action: function action() {},
                        opacity: 0,
                        icon: createIcon(
                            '><polyline fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" points="20 6 9 17 4 12"></polyline>'
                        ),
                        type: "submit",
                    })
                )),
                (t.ref.confirmForm = function (e) {
                    var r = i({ enforceLimits: !0 });
                    e.preventDefault();
                    var n = t.ref,
                        o = n.shouldBlurKeyboard,
                        a = n.buttonConfirm;
                    o && (document.activeElement.blur(), a.element.focus()),
                        (a.opacity = 0),
                        t.dispatch("RESIZE_SET_OUTPUT_SIZE", r);
                }),
                t.element.addEventListener("submit", t.ref.confirmForm);
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.element.removeEventListener("submit", t.ref.confirmForm);
        },
        write: createRoute(
            {
                EDIT_RESET: function EDIT_RESET(e) {
                    var t = e.root,
                        r = t.query("GET_SIZE"),
                        n = t.ref,
                        i = n.inputImageWidth,
                        o = n.inputImageHeight,
                        a = n.aspectRatioLock,
                        c = n.buttonConfirm;
                    i.setValue(r.width),
                        o.setValue(r.height),
                        a.setValue(t.query("GET_SIZE_ASPECT_RATIO_LOCK")),
                        (c.opacity = 0);
                },
                RESIZE_SET_OUTPUT_SIZE: function RESIZE_SET_OUTPUT_SIZE(e) {
                    var t = e.root,
                        r = e.action,
                        n = t.ref,
                        i = n.inputImageWidth,
                        o = n.inputImageHeight;
                    i.setValue(r.width), o.setValue(r.height);
                },
                CROP_SET_ASPECT_RATIO: function CROP_SET_ASPECT_RATIO(e) {
                    var t = e.root,
                        r = e.props,
                        n = e.action,
                        i = e.timestamp,
                        o = t.query("GET_CROP", r.id, i);
                    if (o) {
                        var a = o.cropStatus,
                            c = t.ref,
                            l = c.inputImageWidth,
                            u = c.inputImageHeight;
                        null !== n.value
                            ? (l.setValue(a.image.width),
                              l.setPlaceholder(a.crop.width),
                              u.setValue(a.image.height),
                              u.setPlaceholder(a.crop.height))
                            : l.getValue() &&
                              u.getValue() &&
                              (u.setValue(null),
                              u.setPlaceholder(a.crop.height));
                    }
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp,
                    i = t.query("GET_CROP", r.id, n);
                if (i) {
                    var o = i.cropStatus,
                        a = t.ref,
                        c = a.inputImageWidth,
                        l = a.inputImageHeight;
                    if (!c.hasFocus() && !l.hasFocus()) {
                        var u = t.query("GET_CROP_RECTANGLE_ASPECT_RATIO");
                        if (null === c.getValue() && null === l.getValue())
                            c.setPlaceholder(o.crop.width),
                                l.setPlaceholder(o.crop.height);
                        else if (
                            null === c.getValue() &&
                            null !== o.image.height
                        ) {
                            var s = Math.round(o.image.height * u);
                            c.setPlaceholder(s);
                        } else if (
                            null === l.getValue() &&
                            null !== o.image.width
                        ) {
                            var d = Math.round(o.image.width / u);
                            l.setPlaceholder(d);
                        }
                    }
                }
            }
        ),
    }),
    createFieldGroup = function createFieldGroup(e, t) {
        return createView({
            tag: "fieldset",
            create: function create(r) {
                var n = r.root,
                    i = createElement("legend");
                (i.textContent = e), n.element.appendChild(i), t({ root: n });
            },
        });
    },
    resizeRoot = createView({
        name: "resize",
        ignoreRect: !0,
        mixins: { apis: ["viewId", "stagePosition", "hidden"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (r.viewId = "resize"),
                (r.hidden = !1),
                (t.ref.isHiding = !1),
                (t.ref.form = t.appendChildView(
                    t.createChildView(resizeForm, { opacity: 0, id: r.id })
                ));
        },
        read: function read(e) {
            var t = e.root,
                r = e.props;
            if (!r.hidden) {
                var n = t.rect;
                if (0 !== n.element.width && 0 !== n.element.height) {
                    var i = t.ref.form.rect;
                    r.stagePosition = {
                        x: n.element.left,
                        y: n.element.top + i.element.height,
                        width: n.element.width,
                        height: n.element.height - i.element.height,
                    };
                }
            }
        },
        shouldUpdateChildViews: function shouldUpdateChildViews(e) {
            var t = e.props,
                r = e.actions;
            return !t.hidden || (t.hidden && r && r.length);
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action,
                        n = e.props;
                    r.id === n.viewId
                        ? ((t.ref.isHiding = !1), (t.ref.form.opacity = 1))
                        : ((t.ref.isHiding = !0), (t.ref.form.opacity = 0));
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = t.ref,
                    i = n.form,
                    o = n.isHiding,
                    a = r.hidden;
                o && 0 === i.opacity && !a ? (r.hidden = !0) : (r.hidden = !1);
            }
        ),
    }),
    rangeInput = createView({
        name: "range-input",
        tag: "span",
        mixins: {
            listeners: !0,
            apis: ["onUpdate", "setValue", "getValue", "setAllowInteraction"],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props,
                n = r.id,
                i = r.min,
                o = r.max,
                a = r.step,
                c = r.value,
                l = r.onUpdate,
                u = void 0 === l ? function () {} : l,
                s = "doka--".concat(n, "-").concat(getUniqueId()),
                d = createElement("input", {
                    type: "range",
                    id: s,
                    min: i,
                    max: o,
                    step: a,
                });
            (d.value = c), (t.ref.input = d);
            var f = createElement("span");
            f.className = "doka--range-input-inner";
            var p = createElement("label", { for: s });
            p.innerHTML = r.label;
            var h = i + 0.5 * (o - i);
            (t.element.dataset.centered = c === h),
                (t.ref.handleRecenter = function () {
                    r.setValue(h), t.ref.handleChange();
                });
            var g = createElement("button", { type: "button" });
            (g.textContent = "center"),
                g.addEventListener("click", t.ref.handleRecenter),
                (t.ref.recenter = g),
                f.appendChild(d),
                f.appendChild(g),
                t.appendChild(p),
                t.appendChild(f),
                (t.ref.handleChange = function () {
                    var e = r.getValue();
                    (t.element.dataset.centered = e === h), u(e);
                }),
                d.addEventListener("input", t.ref.handleChange);
            var m = null;
            (t.ref.dragger = createDragger(
                f,
                function () {
                    m = d.getBoundingClientRect();
                },
                function (e) {
                    var r = (e.pageX - m.left) / m.width;
                    (d.value = i + r * (o - i)), t.ref.handleChange();
                },
                function () {},
                { stopPropagation: !0 }
            )),
                (r.getValue = function () {
                    return parseFloat(d.value);
                }),
                (r.setValue = function (e) {
                    return (d.value = e);
                }),
                (r.setAllowInteraction = function (e) {
                    e ? t.ref.dragger.enable() : t.ref.dragger.disable();
                });
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.ref.dragger.destroy(),
                t.ref.recenter.removeEventListener(
                    "click",
                    t.ref.handleRecenter
                ),
                t.ref.input.removeEventListener("input", t.ref.handleChange);
        },
    }),
    COLOR_TOOLS$1 = {
        brightness: {
            icon: createIcon(
                '<g fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="7"/><line x1="12" y1="1" x2="12" y2="3"/><line x1="12" y1="21" x2="12" y2="23"/><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"/><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"/><line x1="1" y1="12" x2="3" y2="12"/><line x1="21" y1="12" x2="23" y2="12"/><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"/><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"/></g>'
            ),
        },
        contrast: {
            icon: createIcon(
                '<g fill="none" fill-rule="evenodd"><circle stroke="currentColor" stroke-width="3" cx="12" cy="12" r="10"/><path d="M12 2v20C6.477 22 2 17.523 2 12S6.477 2 12 2z" fill="currentColor"/></g>'
            ),
        },
        exposure: {
            icon: createIcon(
                '<g fill="none" fill-rule="evenodd"><rect stroke="currentColor" stroke-width="3" x="2" y="2" width="20" height="20" rx="4"/><path d="M20.828 3.172L3.172 20.828A3.987 3.987 0 0 1 2 18V6a4 4 0 0 1 4-4h12c1.105 0 2.105.448 2.828 1.172zM7 7H5v2h2v2h2V9h2V7H9V5H7v2zM12 15h5v2h-5z" fill="currentColor"/></g>'
            ),
        },
        saturation: {
            icon: createIcon(
                '<g fill="none" fill-rule="evenodd"><rect stroke="currentColor" stroke-width="3" x="2" y="2" width="20" height="20" rx="4"/><path fill="currentColor" opacity=".3" d="M7 2.5h5v18.75H7z"/><path fill="currentColor" opacity=".6" d="M12 2.5h5v18.75h-5z"/><path fill="currentColor" opacity=".9" d="M17 2.5h4v18.75h-4z"/></g>'
            ),
        },
    },
    colorForm = createView({
        ignoreRect: !0,
        name: "color-form",
        tag: "form",
        mixins: {
            styles: ["opacity"],
            animations: { opacity: { type: "spring", mass: 15 } },
        },
        create: function create(e) {
            var t = e.root;
            t.element.setAttribute("novalidate", "novalidate");
            var r = t.query("GET_COLOR_VALUES");
            t.ref.tools = Object.keys(COLOR_TOOLS$1).reduce(function (e, n) {
                var i = n,
                    o = COLOR_TOOLS$1[n].icon,
                    a = t.query("GET_LABEL_COLOR_".concat(n.toUpperCase())),
                    c = t.query("GET_COLOR_".concat(n.toUpperCase(), "_RANGE")),
                    l = r[n];
                return (
                    (e[i] = {
                        view: t.appendChildView(
                            t.createChildView(rangeInput, {
                                id: i,
                                label: ""
                                    .concat(o, "<span>")
                                    .concat(a, "</span>"),
                                min: c[0],
                                max: c[1],
                                step: 0.01,
                                value: l,
                                onUpdate: function onUpdate(e) {
                                    return t.dispatch("COLOR_SET_COLOR_VALUE", {
                                        key: i,
                                        value: e,
                                    });
                                },
                            })
                        ),
                    }),
                    e
                );
            }, {});
        },
        write: createRoute({
            COLOR_SET_VALUE: function COLOR_SET_VALUE(e) {
                var t = e.root,
                    r = e.action;
                t.ref.tools[r.key].view.setValue(r.value);
            },
            SHOW_VIEW: function SHOW_VIEW(e) {
                var t = e.root,
                    r = e.action;
                Object.keys(t.ref.tools).forEach(function (e) {
                    t.ref.tools[e].view.setAllowInteraction("color" === r.id);
                });
            },
        }),
    }),
    tilePreviewWorker = null,
    tilePreviewWorkerTerminationTimeout = null,
    updateColors = function updateColors(e, t) {
        var r = t.brightness,
            n = t.exposure,
            i = t.contrast,
            o = t.saturation;
        if (0 !== r) {
            var a = r < 0,
                c = a ? "multiply" : "overlay",
                l = a ? 0 : 255,
                u = a ? Math.abs(r) : 1 - r;
            e.ref.imageOverlay.style.cssText = "mix-blend-mode: "
                .concat(c, "; background: rgba(")
                .concat(l, ",")
                .concat(l, ",")
                .concat(l, ",")
                .concat(u, ")");
        }
        return (
            (e.ref.imageOverlay.style.cssText = "background:transparent"),
            (e.ref.image.style.cssText = "filter: brightness("
                .concat(n, ") contrast(")
                .concat(i, ") saturate(")
                .concat(o, ")")),
            t
        );
    },
    colorKeys = Object.keys(COLOR_TOOLS$1),
    colorEquals = function colorEquals(e, t) {
        return (
            colorKeys.findIndex(function (r) {
                return e[r] !== t[r];
            }) < 0
        );
    },
    createFilterTile = function createFilterTile(e) {
        return createView({
            ignoreRect: !0,
            tag: "li",
            name: "filter-tile",
            mixins: {
                styles: ["opacity", "translateY"],
                animations: {
                    translateY: { type: "spring", delay: 10 * e },
                    opacity: { type: "spring", delay: 30 * e },
                },
            },
            create: function create(e) {
                var t = e.root,
                    r = e.props,
                    n = "doka--filter-"
                        .concat(r.style, "-")
                        .concat(getUniqueId()),
                    i = createElement("input", {
                        id: n,
                        type: "radio",
                        name: "filter",
                    });
                t.appendChild(i),
                    (i.checked = r.selected),
                    (i.value = r.style),
                    i.addEventListener("change", function () {
                        i.checked && r.onSelect();
                    });
                var o = createElement("label", { for: n });
                (o.textContent = r.label), t.appendChild(o);
                var a = r.imageData,
                    c = Math.min(a.width, a.height),
                    l = c,
                    u = createElement("canvas");
                (u.width = c), (u.height = l);
                var s = u.getContext("2d");
                t.ref.image = u;
                var d = createElement("div");
                t.ref.imageOverlay = d;
                var f = {
                        x: 0.5 * c - 0.5 * a.width,
                        y: 0.5 * l - 0.5 * a.height,
                    },
                    p = createElement("div");
                p.appendChild(u),
                    p.appendChild(d),
                    t.appendChild(p),
                    (t.ref.imageWrapper = p),
                    r.matrix
                        ? (tilePreviewWorker ||
                              (tilePreviewWorker =
                                  createWorker(TransformWorker)),
                          clearTimeout(tilePreviewWorkerTerminationTimeout),
                          tilePreviewWorker.post(
                              {
                                  transforms: [
                                      { type: "filter", data: r.matrix },
                                  ],
                                  imageData: a,
                              },
                              function (e) {
                                  s.putImageData(e, f.x, f.y),
                                      clearTimeout(
                                          tilePreviewWorkerTerminationTimeout
                                      ),
                                      (tilePreviewWorkerTerminationTimeout =
                                          setTimeout(function () {
                                              tilePreviewWorker.terminate(),
                                                  (tilePreviewWorker = null);
                                          }, 1e3));
                              },
                              [a.data.buffer]
                          ),
                          (t.ref.activeColors = updateColors(
                              t,
                              t.query("GET_COLOR_VALUES")
                          )))
                        : s.putImageData(a, f.x, f.y);
            },
            write: function write(e) {
                var t = e.root,
                    r = t.query("GET_COLOR_VALUES"),
                    n = t.ref.activeColors;
                ((!n && r) || !colorEquals(n, r)) &&
                    ((t.ref.activeColors = r), updateColors(t, r));
            },
        });
    },
    cloneImageData = function cloneImageData(e) {
        var t;
        try {
            t = new ImageData(e.width, e.height);
        } catch (r) {
            t = document
                .createElement("canvas")
                .getContext("2d")
                .createImageData(e.width, e.height);
        }
        return t.data.set(new Uint8ClampedArray(e.data)), t;
    },
    arrayEqual = function arrayEqual(e, t) {
        return (
            Array.isArray(e) &&
            Array.isArray(t) &&
            e.length === t.length &&
            e.every(function (e, r) {
                return e === t[r];
            })
        );
    },
    filterList = createView({
        ignoreRect: !0,
        tag: "ul",
        name: "filter-list",
        mixins: { apis: ["filterOpacity"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            t.element.setAttribute("role", "list"), (t.ref.tiles = []);
            var n = t.query("GET_THUMB_IMAGE_DATA"),
                i = t.query("GET_FILTERS"),
                o = [];
            forin(i, function (e, t) {
                o.push(_objectSpread({ id: e }, t));
            }),
                (t.ref.activeFilter = t.query("GET_FILTER")),
                (t.ref.tiles = o.map(function (e, i) {
                    var o = e.matrix(),
                        a =
                            t.ref.activeFilter === e.id ||
                            arrayEqual(t.ref.activeFilter, o) ||
                            0 === i;
                    return t.appendChildView(
                        t.createChildView(createFilterTile(i), {
                            opacity: 0,
                            translateY: -5,
                            id: r.id,
                            style: e.id,
                            label: e.label,
                            matrix: o,
                            imageData: cloneImageData(n),
                            selected: a,
                            onSelect: function onSelect() {
                                return t.dispatch("FILTER_SET_FILTER", {
                                    value: o ? e.id : null,
                                });
                            },
                        })
                    );
                }));
        },
        write: function write(e) {
            var t = e.root,
                r = e.props,
                n = t.query("GET_FILTER");
            if (n !== t.ref.activeFilter) {
                t.ref.activeFilter = n;
                var i = t.query("GET_FILTERS"),
                    o = n
                        ? isString(n)
                            ? n
                            : isColorMatrix(n)
                            ? Object.keys(i).find(function (e) {
                                  return arrayEqual(i[e].matrix(), n);
                              })
                            : null
                        : "original";
                Array.from(t.element.querySelectorAll("input")).forEach(
                    function (e) {
                        return (e.checked = e.value === o);
                    }
                );
            }
            t.query("IS_ACTIVE_VIEW", "filter")
                ? t.ref.tiles.forEach(function (e) {
                      (e.opacity = 1), (e.translateY = 0);
                  })
                : t.ref.tiles.forEach(function (e) {
                      (e.opacity = 0), (e.translateY = -5);
                  }),
                (r.filterOpacity =
                    t.ref.tiles.reduce(function (e, t) {
                        return e + t.opacity;
                    }, 0) / t.ref.tiles.length);
        },
    }),
    filterRoot = createView({
        name: "filter",
        ignoreRect: !0,
        mixins: { apis: ["viewId", "stagePosition", "hidden"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (r.viewId = "filter"),
                (r.hidden = !1),
                (t.ref.isHiding = !1),
                (t.ref.filters = t.appendChildView(
                    t.createChildView(filterList, { id: r.id })
                ));
        },
        read: function read(e) {
            var t = e.root,
                r = e.props;
            if (t.ref.filters && !r.hidden) {
                var n = t.rect;
                if (0 !== n.element.width && 0 !== n.element.height) {
                    var i = t.ref.filters.rect,
                        o = 0 === i.element.top,
                        a = o
                            ? n.element.top + i.element.height
                            : n.element.top,
                        c = o
                            ? n.element.height - i.element.height
                            : n.element.height -
                              i.element.height -
                              n.element.top;
                    r.stagePosition = {
                        x: n.element.left + i.element.left,
                        y: a,
                        width: n.element.width - i.element.left,
                        height: c,
                    };
                }
            }
        },
        shouldUpdateChildViews: function shouldUpdateChildViews(e) {
            var t = e.props,
                r = e.actions;
            return !t.hidden || (t.hidden && r && r.length);
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action,
                        n = e.props;
                    t.ref.filters &&
                        (r.id === n.viewId
                            ? ((t.ref.isHiding = !1), (n.hidden = !1))
                            : (t.ref.isHiding = !0));
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props;
                t.ref.isHiding &&
                    0 === t.ref.filters.filterOpacity &&
                    ((t.ref.isHiding = !1), (r.hidden = !0));
            }
        ),
    }),
    colorRoot = createView({
        name: "color",
        ignoreRect: !0,
        mixins: { apis: ["viewId", "stagePosition", "hidden"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (r.viewId = "color"),
                (r.hidden = !1),
                (t.ref.isHiding = !1),
                (t.ref.form = t.appendChildView(
                    t.createChildView(colorForm, { opacity: 0, id: r.id })
                ));
        },
        read: function read(e) {
            var t = e.root,
                r = e.props;
            if (!r.hidden) {
                var n = t.rect;
                if (0 !== n.element.width && 0 !== n.element.height) {
                    var i = t.ref.form.rect,
                        o = i.element.height,
                        a = 0 === i.element.top,
                        c = a ? n.element.top + o : n.element.top,
                        l = a
                            ? n.element.height - o
                            : n.element.height - o - n.element.top;
                    r.stagePosition = {
                        x: n.element.left + i.element.left,
                        y: c,
                        width: n.element.width - i.element.left,
                        height: l,
                    };
                }
            }
        },
        shouldUpdateChildViews: function shouldUpdateChildViews(e) {
            var t = e.props,
                r = e.actions;
            return !t.hidden || (t.hidden && r && r.length);
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action,
                        n = e.props;
                    r.id === n.viewId
                        ? ((t.ref.isHiding = !1),
                          (t.ref.form.opacity = 1),
                          (n.hidden = !1))
                        : ((t.ref.isHiding = !0), (t.ref.form.opacity = 0));
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props;
                t.ref.isHiding &&
                    0 === t.ref.form.opacity &&
                    ((t.ref.isHiding = !1), (r.hidden = !0));
            }
        ),
    }),
    markupColor = createView({
        ignoreRect: !0,
        tag: "ul",
        name: "markup-color",
        mixins: {
            animations: { opacity: "spring" },
            styles: ["opacity"],
            apis: ["onSelect", "setSelectedValue"],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props,
                n = r.colors,
                i = r.name,
                o = r.onSelect;
            (r.setSelectedValue = function (e) {
                t.ref.inputs.forEach(function (t) {
                    t.checked = t.value === e;
                });
            }),
                t.element.addEventListener("change", function (e) {
                    o(e.target.value), e.stopPropagation();
                }),
                (t.ref.inputs = n.map(function (e) {
                    var r = "doka--color-" + getUniqueId(),
                        n = createElement("li"),
                        o = createElement("input");
                    (o.id = r),
                        o.setAttribute("type", "radio"),
                        o.setAttribute("name", i),
                        (o.value = e[1]);
                    var a = createElement("label");
                    return (
                        a.setAttribute("for", r),
                        (a.textContent = e[0]),
                        (a.title = e[0]),
                        (a.style.backgroundColor = e[2] || e[1]),
                        n.appendChild(o),
                        n.appendChild(a),
                        t.element.appendChild(n),
                        o
                    );
                }));
        },
    }),
    ALL_SETTINGS = [
        "fontFamily",
        "fontSize",
        "fontWeight",
        "textAlign",
        "backgroundColor",
        "fontColor",
        "borderColor",
        "borderWidth",
        "borderStyle",
        "lineColor",
        "lineWidth",
        "lineDecoration",
        "lineJoin",
        "lineCap",
    ],
    createSVG = function createSVG(e) {
        return '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">'.concat(
            e,
            "</svg>"
        );
    },
    createShapeStyleIcon = function createShapeStyleIcon(e) {
        var t = 0 === e ? "currentColor" : "none",
            r = e;
        return createSVG(
            '<rect stroke="'
                .concat(0 === e ? "none" : "currentColor", '" fill="')
                .concat(t, '" stroke-width="')
                .concat(r, '" x="2" y="3" width="17" height="17" rx="3"/>')
        );
    },
    createLineStyleIcon = function createLineStyleIcon(e) {
        return createSVG(
            '<line stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="'.concat(
                e,
                '" x1="3" y1="12" x2="20" y2="12"/>'
            )
        );
    },
    markupTools = createView({
        name: "markup-tools",
        ignoreRect: !0,
        mixins: {
            apis: ["onUpdate"],
            animations: { translateY: "spring", opacity: "spring" },
            styles: ["translateY", "opacity"],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props.onUpdate;
            t.ref.activeMarkup = null;
            var n = function n(e) {
                t.ref.activeMarkup && r(t.ref.activeMarkup[1].id, e);
            };
            (t.ref.colorSelect = t.appendChildView(
                t.createChildView(markupColor, {
                    onSelect: function onSelect(e) {
                        var r = {};
                        (r[getColorProperty(t.ref.activeMarkup[1])] = e), n(r);
                    },
                    name: "color-select",
                    colors: t.query("GET_MARKUP_COLOR_OPTIONS"),
                })
            )),
                (t.ref.shapeStyleSelect = t.appendChildView(
                    t.createChildView(dropdown, {
                        onSelect: function onSelect(e) {
                            t.ref.shapeStyleSelect.selectedValue = e;
                            var r = getColor(t.ref.activeMarkup[1]),
                                i = e[1],
                                o = e[2],
                                a = e[3] ? null : r;
                            n({
                                borderWidth: i,
                                borderStyle: o,
                                backgroundColor: a,
                                borderColor: r,
                            });
                        },
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_SELECT_SHAPE_STYLE"),
                        direction: "up",
                        options: t
                            .query("GET_MARKUP_SHAPE_STYLE_OPTIONS")
                            .map(function (e) {
                                return {
                                    value: e,
                                    label: e[0],
                                    icon: createShapeStyleIcon(e[3]),
                                };
                            }),
                    })
                )),
                (t.ref.lineStyleSelect = t.appendChildView(
                    t.createChildView(dropdown, {
                        onSelect: function onSelect(e) {
                            t.ref.lineStyleSelect.selectedValue = e;
                            var r = e[1],
                                i = e[2];
                            n({ lineWidth: r, lineStyle: i });
                        },
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_SELECT_LINE_STYLE"),
                        direction: "up",
                        options: t
                            .query("GET_MARKUP_LINE_STYLE_OPTIONS")
                            .map(function (e) {
                                return {
                                    value: e,
                                    label: e[0],
                                    icon: createLineStyleIcon(e[3]),
                                };
                            }),
                    })
                )),
                (t.ref.lineDecorationSelect = t.appendChildView(
                    t.createChildView(dropdown, {
                        onSelect: function onSelect(e) {
                            (t.ref.lineDecorationSelect.selectedValue = e),
                                n({ lineDecoration: e });
                        },
                        name: "tool",
                        label: t.query(
                            "GET_LABEL_MARKUP_SELECT_LINE_DECORATION"
                        ),
                        direction: "up",
                        options: t
                            .query("GET_MARKUP_LINE_DECORATION_OPTIONS")
                            .map(function (e) {
                                return { value: e[1], label: e[0] };
                            }),
                    })
                )),
                (t.ref.fontFamilySelect = t.appendChildView(
                    t.createChildView(dropdown, {
                        onSelect: function onSelect(e) {
                            (t.ref.fontFamilySelect.selectedValue = e),
                                n({ fontFamily: e });
                        },
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_SELECT_FONT_FAMILY"),
                        direction: "up",
                        options: t
                            .query("GET_MARKUP_FONT_FAMILY_OPTIONS")
                            .map(function (e) {
                                return {
                                    value: e[1],
                                    label: '<span style="font-family:'
                                        .concat(e[1], ';font-weight:600;">')
                                        .concat(e[0], "</span>"),
                                };
                            }),
                    })
                )),
                (t.ref.fontSizeSelect = t.appendChildView(
                    t.createChildView(dropdown, {
                        onSelect: function onSelect(e) {
                            (t.ref.fontSizeSelect.selectedValue = e),
                                n({ fontSize: e });
                        },
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_SELECT_FONT_SIZE"),
                        direction: "up",
                        options: t
                            .query("GET_MARKUP_FONT_SIZE_OPTIONS")
                            .map(function (e) {
                                return { value: e[1], label: e[0] };
                            }),
                    })
                ));
        },
        write: createRoute({
            MARKUP_SELECT: function MARKUP_SELECT(e) {
                var t = e.root,
                    r = e.action,
                    n = t.ref,
                    i = n.colorSelect,
                    o = n.fontFamilySelect,
                    a = n.fontSizeSelect,
                    c = n.shapeStyleSelect,
                    l = n.lineStyleSelect,
                    u = n.lineDecorationSelect;
                t.ref.activeMarkup = r.id
                    ? t.query("GET_MARKUP_BY_ID", r.id)
                    : null;
                var s = [i, o, a, c, l, u],
                    d = [];
                if (t.ref.activeMarkup) {
                    var f = _slicedToArray(t.ref.activeMarkup, 2),
                        p = f[0],
                        h = f[1],
                        g = Array.isArray(h.allowEdit)
                            ? h.allowEdit
                            : !1 === h.allowEdit
                            ? []
                            : ALL_SETTINGS,
                        m = ALL_SETTINGS.reduce(function (e, t) {
                            return (e[t] = -1 !== g.indexOf(t)), e;
                        }, {});
                    if (
                        ((m.color = !!g.find(function (e) {
                            return /[a-z]Color/.test(e);
                        })),
                        "image" !== p &&
                            m.color &&
                            (i.setSelectedValue(getColor(h)), d.push(i)),
                        "text" === p &&
                            (m.fontFamily &&
                                ((o.selectedValue = h.fontFamily), d.push(o)),
                            m.fontSize &&
                                ((a.selectedValue = h.fontSize), d.push(a))),
                        ("rect" === p || "ellipse" === p) && m.borderStyle)
                    ) {
                        var v = t
                            .query("GET_MARKUP_SHAPE_STYLE_OPTIONS")
                            .find(function (e) {
                                var t = h.borderWidth === e[1],
                                    r =
                                        h.borderStyle === e[2] ||
                                        arrayEqual(h.borderStyle, e[2]);
                                return t && r;
                            });
                        (c.selectedValue = v), d.push(c);
                    }
                    if ("line" === p) {
                        if (m.lineWidth) {
                            var y = t
                                .query("GET_MARKUP_LINE_STYLE_OPTIONS")
                                .find(function (e) {
                                    var t = h.lineWidth === e[1],
                                        r =
                                            h.lineStyle === e[2] ||
                                            arrayEqual(h.lineStyle, e[2]);
                                    return t && r;
                                });
                            (l.selectedValue = y), d.push(l);
                        }
                        m.lineDecoration &&
                            ((u.selectedValue = h.lineDecoration), d.push(u));
                    }
                    s.forEach(function (e) {
                        e.element.dataset.active = "false";
                    }),
                        d.forEach(function (e) {
                            e.element.dataset.active = "true";
                        });
                }
            },
        }),
    }),
    getColorProperty = function getColorProperty(e) {
        return e.borderWidth
            ? "borderColor"
            : e.lineWidth
            ? "lineColor"
            : e.fontColor
            ? "fontColor"
            : e.backgroundColor
            ? "backgroundColor"
            : void 0;
    },
    getColor = function getColor(e) {
        var t = e.fontColor,
            r = e.backgroundColor,
            n = e.lineColor,
            i = e.borderColor;
        return t || r || n || i;
    },
    markupRoot = createView({
        name: "markup",
        ignoreRect: !0,
        mixins: { apis: ["viewId", "stagePosition", "hidden"] },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (r.viewId = "markup"),
                (r.hidden = !1),
                (t.ref.isHiding = !1),
                (t.ref.defaultRect = {
                    x: 0.5,
                    y: 0.5,
                    width: 0.25,
                    height: 0.25,
                });
            var n = function n() {
                    return 0.5 * Math.random() - 0.25;
                },
                i = function i() {
                    return _objectSpread({}, t.ref.defaultRect, {
                        x:
                            t.ref.defaultRect.x -
                            0.5 * t.ref.defaultRect.width +
                            n(),
                        y:
                            t.ref.defaultRect.y -
                            0.5 * t.ref.defaultRect.height +
                            n(),
                    });
                },
                o = function o(e) {
                    return t.query("GET_MARKUP_".concat(e, "_OPTIONS"))[
                        t.query("GET_MARKUP_".concat(e, "_DEFAULT"))
                    ];
                },
                a = function a() {
                    return {
                        backgroundColor:
                            0 === o("SHAPE_STYLE")[3] ? o("COLOR")[1] : null,
                        borderWidth: o("SHAPE_STYLE")[1],
                        borderStyle: o("SHAPE_STYLE")[2]
                            ? o("SHAPE_STYLE")[2]
                            : null,
                        borderColor: o("COLOR")[1],
                    };
                },
                c = [
                    {
                        view: button,
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_TYPE_RECTANGLE"),
                        icon: createIcon(
                            '<g fill="currentColor"><rect x="5" y="5" width="16" height="16" rx="2"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("MARKUP_ADD", [
                                "rect",
                                _objectSpread({}, i(), a()),
                            ]);
                        },
                    },
                    {
                        view: button,
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_TYPE_ELLIPSE"),
                        icon: createIcon(
                            '<g fill="currentColor"><circle cx="13" cy="13" r="9"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("MARKUP_ADD", [
                                "ellipse",
                                _objectSpread({}, i(), a()),
                            ]);
                        },
                    },
                    {
                        view: button,
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_TYPE_TEXT"),
                        icon: createIcon(
                            '<g transform="translate(5 5)" fill="currentColor" fill-rule="evenodd"><<path d="M10 4v11a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-5z"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("MARKUP_ADD", [
                                "text",
                                _objectSpread({}, i(), {
                                    width: 0,
                                    height: 0,
                                    fontColor: o("COLOR")[1],
                                    fontSize: o("FONT_SIZE")[1],
                                    fontFamily: o("FONT_FAMILY")[1],
                                    text: "Text",
                                }),
                            ]);
                        },
                    },
                    {
                        view: button,
                        name: "tool",
                        label: t.query("GET_LABEL_MARKUP_TYPE_LINE"),
                        icon: createIcon(
                            '<g transform="translate(3 4.5)" fill-rule="nonzero" fill="currentColor" stroke="none"><path d="M15.414 9.414l-6.01 6.01a2 2 0 1 1-2.829-2.828L9.172 10H2a2 2 0 1 1 0-4h7.172L6.575 3.404A2 2 0 1 1 9.404.575l6.01 6.01c.362.363.586.863.586 1.415s-.224 1.052-.586 1.414z"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("MARKUP_ADD", [
                                "line",
                                _objectSpread({}, i(), {
                                    lineColor: o("COLOR")[1],
                                    lineWidth: o("LINE_STYLE")[1],
                                    lineStyle: o("LINE_STYLE")[2]
                                        ? o("LINE_STYLE")[2]
                                        : null,
                                    lineDecoration: o("LINE_DECORATION")[1],
                                }),
                            ]);
                        },
                    },
                ];
            t.query("GET_MARKUP_ALLOW_ADD_MARKUP") &&
                (t.ref.menu = t.appendChildView(
                    t.createChildView(
                        createGroup("toolbar", ["opacity"], {
                            opacity: { type: "spring", mass: 15, delay: 50 },
                        }),
                        { opacity: 0, controls: c }
                    )
                )),
                (t.ref.tools = t.appendChildView(
                    t.createChildView(markupTools, {
                        opacity: 0,
                        onUpdate: function onUpdate(e, r) {
                            t.dispatch("MARKUP_UPDATE", { id: e, props: r });
                        },
                    })
                )),
                (t.ref.menuItemsRequiredWidth = null);
        },
        read: function read(e) {
            var t = e.root,
                r = e.props;
            if (r.hidden) t.ref.menuItemsRequiredWidth = null;
            else {
                var n = t.rect;
                if (0 !== n.element.width && 0 !== n.element.height) {
                    if (t.ref.menu && null === t.ref.menuItemsRequiredWidth) {
                        var i = t.ref.menu.childViews.reduce(function (e, t) {
                            return e + t.rect.outer.width;
                        }, 0);
                        t.ref.menuItemsRequiredWidth = 0 === i ? null : i;
                    }
                    var o = t.ref.menu && t.ref.menu.rect,
                        a = t.ref.tools.rect.element.height,
                        c = o ? o.element.height : a,
                        l = !o || 0 === o.element.top,
                        u = l ? n.element.top + c : n.element.top,
                        s = l
                            ? n.element.height - c
                            : n.element.height - c - n.element.top;
                    r.stagePosition = {
                        x: n.element.left + 20,
                        y: u,
                        width: n.element.width - 40,
                        height: s - a,
                    };
                }
            }
        },
        shouldUpdateChildViews: function shouldUpdateChildViews(e) {
            var t = e.props,
                r = e.actions;
            return !t.hidden || (t.hidden && r && r.length);
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action,
                        n = e.props;
                    r.id === n.viewId
                        ? ((n.hidden = !1),
                          (t.ref.isHiding = !1),
                          t.ref.menu && (t.ref.menu.opacity = 1))
                        : ((t.ref.isHiding = !0),
                          t.ref.menu && (t.ref.menu.opacity = 0),
                          (t.ref.tools.opacity = 0),
                          (t.ref.tools.translateY = 5));
                },
                MARKUP_SELECT: function MARKUP_SELECT(e) {
                    var t = e.root,
                        r = e.action;
                    (t.ref.tools.opacity = r.id ? 1 : 0),
                        (t.ref.tools.translateY = r.id ? 0 : 5),
                        (t.ref.tools.element.dataset.active = r.id
                            ? "true"
                            : "false");
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp;
                if (
                    (t.ref.isHiding &&
                        t.ref.menu &&
                        0 === t.ref.menu.opacity &&
                        ((t.ref.isHiding = !1), (r.hidden = !0)),
                    !r.hidden)
                ) {
                    var i = t.query("GET_CROP", r.id, n);
                    if (i) {
                        var o = i.cropRect,
                            a = o.height / o.width;
                        (t.ref.defaultRect.height =
                            t.ref.defaultRect.width / a),
                            (t.ref.menu.element.dataset.layout =
                                t.ref.menuItemsRequiredWidth >
                                t.ref.menu.rect.element.width
                                    ? "compact"
                                    : "spacious");
                    }
                }
            }
        ),
    }),
    hasStagePositionChanged = function hasStagePositionChanged(e, t) {
        return !e || !t || !rectEqualsRect(e, t);
    },
    VIEW_MAP = {
        crop: cropRoot,
        resize: resizeRoot,
        filter: filterRoot,
        color: colorRoot,
        markup: markupRoot,
    },
    viewStack = createView({
        name: "view-stack",
        ignoreRect: !0,
        mixins: { apis: ["offsetTop"] },
        create: function create(e) {
            var t = e.root;
            (t.ref.activeView = null),
                (t.ref.activeStagePosition = null),
                (t.ref.shouldFocus = !1);
        },
        write: createRoute(
            {
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.props,
                        n = e.action,
                        i = 0 === t.childViews.length,
                        o = t.childViews.find(function (e) {
                            return e.viewId === n.id;
                        });
                    o ||
                        (o = t.appendChildView(
                            t.createChildView(
                                VIEW_MAP[n.id],
                                _objectSpread({}, r)
                            )
                        )),
                        (t.ref.activeView = o),
                        t.childViews
                            .map(function (e) {
                                return e.element;
                            })
                            .forEach(function (e) {
                                (e.dataset.viewActive = "false"),
                                    e.removeAttribute("tabindex");
                            });
                    var a = t.ref.activeView.element;
                    (a.dataset.viewActive = "true"),
                        a.setAttribute("tabindex", -1),
                        (t.ref.shouldFocus = !i);
                },
                DID_PRESENT_IMAGE: function DID_PRESENT_IMAGE(e) {
                    var t = e.root;
                    t.dispatch("CHANGE_VIEW", { id: t.query("GET_UTILS")[0] });
                },
                DID_SET_UTILS: function DID_SET_UTILS(e) {
                    var t = e.root;
                    t.dispatch("CHANGE_VIEW", { id: t.query("GET_UTILS")[0] });
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = t.ref,
                    i = n.activeView,
                    o = n.previousStagePosition;
                if (
                    i &&
                    i.stagePosition &&
                    (t.childViews.forEach(function (e) {
                        (e.offsetTop = r.offsetTop),
                            e.element.viewHidden !== e.hidden &&
                                ((e.element.viewHidden = e.hidden),
                                (e.element.dataset.viewHidden = e.hidden));
                    }),
                    hasStagePositionChanged(i.stagePosition, o))
                ) {
                    var a = i.stagePosition,
                        c = a.x,
                        l = a.y,
                        u = a.width,
                        s = a.height;
                    if (0 === u && 0 === s) return;
                    t.dispatch("DID_RESIZE_STAGE", {
                        offset: { x: c, y: l },
                        size: { width: u, height: s },
                        animate: !0,
                    }),
                        (t.ref.previousStagePosition = i.stagePosition);
                }
            }
        ),
        didWriteView: function didWriteView(e) {
            var t = e.root;
            t.ref.shouldFocus &&
                (t.ref.activeView.element.focus({ preventScroll: !0 }),
                (t.ref.shouldFocus = !1));
        },
    }),
    editContent = createView({
        name: "content",
        ignoreRect: !0,
        mixins: {
            styles: ["opacity"],
            animations: { opacity: { type: "tween", duration: 250 } },
        },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (t.opacity = 1),
                (t.ref.viewStack = t.appendChildView(
                    t.createChildView(viewStack, { id: r.id })
                )),
                (t.ref.image = null);
        },
        write: createRoute(
            {
                DID_LOAD_IMAGE: function DID_LOAD_IMAGE(e) {
                    var t = e.root,
                        r = e.props;
                    t.ref.image = t.appendChildView(
                        t.createChildView(image, { id: r.id })
                    );
                },
            },
            function (e) {
                var t = e.root,
                    r = t.ref,
                    n = r.image,
                    i = r.viewStack;
                if (n) {
                    var o = t.rect.element.top;
                    (i.offsetTop = o), (n.offsetTop = o);
                }
            }
        ),
    }),
    updateResizeButton = function updateResizeButton(e, t) {
        e.element.dataset.scaleDirection = null === t || t > 1 ? "up" : "down";
    },
    editUtils = createView({
        name: "utils",
        create: function create(e) {
            var t = e.root,
                r = {
                    crop: {
                        title: t.query("GET_LABEL_BUTTON_UTIL_CROP"),
                        icon: createIcon(
                            '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2"><path d="M23 17H9a2 2 0 0 1-2-2v-5m0-3V1"/><path d="M1 7h14a2 2 0 0 1 2 2v7m0 4v3"/></g>'
                        ),
                    },
                    filter: {
                        title: t.query("GET_LABEL_BUTTON_UTIL_FILTER"),
                        icon: createIcon(
                            '<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18.347 9.907a6.5 6.5 0 1 0-1.872 3.306M3.26 11.574a6.5 6.5 0 1 0 2.815-1.417"/><path d="M10.15 17.897A6.503 6.503 0 0 0 16.5 23a6.5 6.5 0 1 0-6.183-8.51"/></g>'
                        ),
                    },
                    color: {
                        title: t.query("GET_LABEL_BUTTON_UTIL_COLOR"),
                        icon: createIcon(
                            '<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 1v5.5m0 3.503V23M12 1v10.5m0 3.5v8M20 1v15.5m0 3.5v3M2 7h4M10 12h4M18 17h4"/></g>'
                        ),
                    },
                    markup: {
                        title: t.query("GET_LABEL_BUTTON_UTIL_MARKUP"),
                        icon: createIcon(
                            '<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17.086 2.914a2.828 2.828 0 1 1 4 4l-14.5 14.5-5.5 1.5 1.5-5.5 14.5-14.5z"/></g>'
                        ),
                    },
                    resize: {
                        title: t.query("GET_LABEL_BUTTON_UTIL_RESIZE"),
                        icon: createIcon(
                            '<g fill="none" fill-rule="evenodd" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="12" width="10" height="10" rx="2"/><path d="M4 11.5V4a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5"/><path d="M14 10l3.365-3.365M14 6h4v4" class="doka--icon-resize-arrow-ne"/><path d="M14 10l3.365-3.365M14 6v4h4" class="doka--icon-resize-arrow-sw"/></g>'
                        ),
                    },
                };
            (t.ref.utils = Object.keys(r).map(function (e) {
                return _objectSpread({ id: e }, r[e]);
            })),
                (t.ref.utilMenuRequiredWidth = null);
        },
        read: function read(e) {
            var t = e.root;
            if (null === t.ref.utilMenuRequiredWidth) {
                var r = t.childViews.reduce(function (e, t) {
                    return e + t.rect.outer.width;
                }, 0);
                t.ref.utilMenuRequiredWidth = 0 === r ? null : r;
            }
        },
        write: createRoute(
            {
                DID_SET_UTILS: function DID_SET_UTILS(e) {
                    var t = e.root,
                        r = _toConsumableArray(t.query("GET_UTILS"));
                    t.childViews.forEach(function (e) {
                        return t.removeChildView(e);
                    }),
                        (t.element.dataset.utilCount = r.length),
                        1 === r.length && (r.length = 0),
                        r.forEach(function (e) {
                            var r = t.ref.utils.find(function (t) {
                                    return t.id === e;
                                }),
                                n = t.appendChildView(
                                    t.createChildView(button, {
                                        name: "tab",
                                        view: button,
                                        label: r.title,
                                        opacity: 1,
                                        icon: r.icon,
                                        id: r.id,
                                        action: function action() {
                                            return t.dispatch("CHANGE_VIEW", {
                                                id: r.id,
                                            });
                                        },
                                    })
                                );
                            t.ref["util_button_".concat(r.id)] = n;
                        });
                },
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action;
                    t.childViews.forEach(function (e) {
                        e.element.dataset.active = e.id === r.id;
                    });
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp,
                    i = t.query("GET_CROP", r.id, n);
                if (i) {
                    var o = i.cropStatus;
                    t.ref.util_button_resize &&
                        updateResizeButton(
                            t.ref.util_button_resize,
                            o.image.width ? o.image.width / o.crop.width : null
                        ),
                        (t.element.dataset.layout =
                            t.ref.utilMenuRequiredWidth > t.rect.element.width
                                ? "compact"
                                : "spacious");
                }
            }
        ),
    }),
    HAS_WEBGL =
        isBrowser() &&
        (function () {
            try {
                var e = { antialias: !1, alpha: !1 },
                    t = document.createElement("canvas");
                return (
                    !!window.WebGLRenderingContext &&
                    (t.getContext("webgl", e) ||
                        t.getContext("experimental-webgl", e))
                );
            } catch (e) {
                return !1;
            }
        })(),
    hasWebGL = function hasWebGL() {
        return HAS_WEBGL;
    },
    editContainer = createView({
        name: "container",
        create: function create(e) {
            var t = e.root,
                r = [
                    {
                        view: button,
                        opacity: 0,
                        label: t.query("GET_LABEL_BUTTON_RESET"),
                        didCreateView: function didCreateView(e) {
                            return (t.ref.btnReset = e);
                        },
                        name: "app action-reset icon-only",
                        icon: createIcon(
                            '<g fill="currentColor" fill-rule="nonzero"><path d="M6.036 13.418L4.49 11.872A.938.938 0 1 0 3.163 13.2l2.21 2.209a.938.938 0 0 0 1.326 0l2.209-2.21a.938.938 0 0 0-1.327-1.326l-1.545 1.546zM12 10.216a1 1 0 0 1 2 0V13a1 1 0 0 1-2 0v-2.784z"/><path d="M15.707 14.293a1 1 0 0 1-1.414 1.414l-2-2a1 1 0 0 1 1.414-1.414l2 2z"/><path d="M8.084 19.312a1 1 0 0 1 1.23-1.577 6 6 0 1 0-2.185-3.488 1 1 0 0 1-1.956.412 8 8 0 1 1 2.912 4.653z"/></g>',
                            26
                        ),
                        action: function action() {
                            return t.dispatch("EDIT_RESET");
                        },
                    },
                ];
            t.query("GET_ALLOW_BUTTON_CANCEL") &&
                r.unshift({
                    view: button,
                    label: t.query("GET_LABEL_BUTTON_CANCEL"),
                    name: "app action-cancel icon-fallback",
                    opacity: 1,
                    icon: createIcon(
                        '<g fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12"/></g>'
                    ),
                    didCreateView: function didCreateView(e) {
                        t.ref.btnCancel = e;
                    },
                    action: function action() {
                        t.dispatch("EDIT_CANCEL");
                    },
                }),
                r.push({ view: editUtils }),
                t.query("GET_ALLOW_BUTTON_CONFIRM") &&
                    r.push({
                        view: button,
                        label: t.query("GET_LABEL_BUTTON_CONFIRM"),
                        name: "app action-confirm icon-fallback",
                        opacity: 1,
                        icon: createIcon(
                            '<polyline fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" points="20 6 9 17 4 12"></polyline>'
                        ),
                        didCreateView: function didCreateView(e) {
                            t.ref.btnConfirm = e;
                        },
                        action: function action() {
                            t.dispatch("EDIT_CONFIRM");
                        },
                    }),
                (t.ref.menu = t.appendChildView(
                    t.createChildView(createGroup("menu"), { controls: r })
                )),
                (t.ref.menu.opacity = 0),
                (t.ref.status = t.appendChildView(
                    t.createChildView(editStatus)
                )),
                (t.ref.hasWebGL = hasWebGL()),
                t.ref.hasWebGL
                    ? t.dispatch("AWAIT_IMAGE")
                    : t.dispatch("MISSING_WEBGL"),
                (t.ref.handleFocusOut = function () {
                    var e = t.ref.status;
                    "busy" === e.element.dataset.viewStatus &&
                        e.element.focus();
                }),
                (t.ref.handleFocusIn = function (e) {
                    var r = t.ref,
                        n = r.menu,
                        i = r.content,
                        o = e.target;
                    if (!n.element.contains(o) && i && i.element.contains(o)) {
                        if (
                            !Array.from(
                                t.element.querySelectorAll(
                                    "[data-view-active=false]"
                                )
                            ).reduce(function (e, t) {
                                return t.contains(o) && (e = !0), e;
                            }, !1)
                        )
                            return;
                        n.element
                            .querySelector("button,input,[tabindex]")
                            .focus();
                    }
                }),
                t.element.addEventListener("focusin", t.ref.handleFocusIn),
                t.element.addEventListener("focusout", t.ref.handleFocusOut),
                (t.ref.previousState = null);
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.element.removeEventListener("focusin", t.ref.handleFocusIn),
                t.element.removeEventListener("focusout", t.ref.handleFocusOut);
        },
        write: createRoute(
            {
                UNLOAD_IMAGE: function UNLOAD_IMAGE(e) {
                    var t = e.root;
                    t.ref.content &&
                        ((t.ref.content.opacity = 0), (t.ref.menu.opacity = 0));
                },
                DID_UNLOAD_IMAGE: function DID_UNLOAD_IMAGE(e) {
                    var t = e.root;
                    t.removeChildView(t.ref.content), (t.ref.content = null);
                },
                DID_LOAD_IMAGE: function DID_LOAD_IMAGE(e) {
                    var t = e.root,
                        r = e.props;
                    t.ref.hasWebGL &&
                        ((t.ref.content = t.appendChildView(
                            t.createChildView(editContent, {
                                opacity: null,
                                id: r.id,
                            })
                        )),
                        (t.ref.menu.opacity = 1));
                },
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action;
                    t.element.dataset.limitOverflow = "resize" === r.id;
                },
            },
            function (e) {
                var t = e.root,
                    r = e.props,
                    n = e.timestamp,
                    i = t.query("GET_CROP", r.id, n);
                if (i) {
                    var o = {
                        crop: {
                            width: i.cropStatus.currentWidth,
                            height: i.cropStatus.currentHeight,
                        },
                    };
                    hasStateChanged(t.ref.previousState, o) &&
                        (t.dispatch("DID_UPDATE", {
                            state: _objectSpread({}, o),
                        }),
                        (t.ref.previousState = o));
                    var a = t.ref,
                        c = a.btnCancel,
                        l = a.content,
                        u = i.canReset;
                    if (
                        ((t.ref.btnReset.opacity = u ? 1 : 0),
                        c && t.query("GET_UTILS").length > 1)
                    ) {
                        var s = t.query("GET_ROOT_SIZE");
                        c.opacity = u && s.width < 600 ? 0 : 1;
                    }
                    l && 0 === l.opacity && t.dispatch("DID_UNLOAD_IMAGE");
                }
            }
        ),
    }),
    hasStateChanged = function hasStateChanged(e, t) {
        return (
            !e ||
            e.crop.width !== t.crop.width ||
            e.crop.height !== t.crop.height
        );
    },
    createPointerEvents = function createPointerEvents(e) {
        var t = { destroy: function destroy() {} };
        if ("onpointerdown" in window || e.pointersPolyfilled) return t;
        e.pointersPolyfilled = !0;
        var r = 0,
            n = [],
            i = function i(e, t, r) {
                var n = new UIEvent(t.type, { view: window, bubbles: !r });
                Object.keys(t).forEach(function (e) {
                    Object.defineProperty(n, e, { value: t[e], writable: !1 });
                }),
                    e.dispatchEvent(n);
            },
            o = function o(e, t, _o) {
                return Array.from(t.changedTouches).map(function (a) {
                    var c = n[a.identifier],
                        l = {
                            type: e,
                            pageX: a.pageX,
                            pageY: a.pageY,
                            pointerId: a.identifier,
                            isPrimary: c ? c.isPrimary : 0 === r,
                            preventDefault: function preventDefault() {
                                return t.preventDefault();
                            },
                        };
                    return i(a.target, l, _o), l;
                });
            },
            a = function a(e) {
                o("pointerdown", e).forEach(function (e) {
                    (n[e.pointerId] = e), r++;
                });
            },
            c = function c(e) {
                o("pointermove", e);
            },
            l = function l(e) {
                o("pointerup", e).forEach(function (e) {
                    delete n[e.pointerId], r--;
                });
            },
            u = function u(e, t, r) {
                var n = {
                    type: e,
                    pageX: t.pageX,
                    pageY: t.pageY,
                    pointerId: 0,
                    isPrimary: !0,
                    preventDefault: function preventDefault() {
                        return t.preventDefault();
                    },
                };
                return i(t.target, n, r), n;
            },
            s = function s(e) {
                u("pointerdown", e);
            },
            d = function d(e) {
                u("pointermove", e);
            },
            f = function f(e) {
                u("pointerup", e);
            };
        return (
            "ontouchstart" in window
                ? (e.addEventListener("touchstart", a),
                  e.addEventListener("touchmove", c),
                  e.addEventListener("touchend", l))
                : "onmousedown" in window &&
                  (e.addEventListener("mousedown", s),
                  e.addEventListener("mousemove", d),
                  e.addEventListener("mouseup", f)),
            (t.destroy = function () {
                (n.length = 0),
                    (e.pointersPolyfilled = !1),
                    e.removeEventListener("touchstart", a),
                    e.removeEventListener("touchmove", c),
                    e.removeEventListener("touchend", l),
                    e.removeEventListener("mousedown", s),
                    e.removeEventListener("mousemove", d),
                    e.removeEventListener("mouseup", f);
            }),
            t
        );
    },
    prevent = function prevent(e) {
        return e.preventDefault();
    },
    editor = createView({
        name: "editor",
        ignoreRect: !0,
        mixins: {
            styles: ["opacity"],
            animations: { opacity: { type: "tween", duration: 350 } },
            apis: ["markedForRemoval"],
        },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            (r.markedForRemoval = !1),
                t.element.addEventListener("touchmove", prevent, {
                    passive: !1,
                }),
                t.element.addEventListener("gesturestart", prevent),
                (t.ref.pointerPolyfill = createPointerEvents(
                    "root" === t.query("GET_POINTER_EVENTS_POLYFILL_SCOPE")
                        ? t.element
                        : document.documentElement
                )),
                t.appendChildView(
                    t.createChildView(editContainer, _objectSpread({}, r))
                );
        },
        destroy: function destroy(e) {
            var t = e.root;
            t.ref.pointerPolyfill.destroy(),
                t.element.removeEventListener("touchmove", prevent, !0),
                t.element.removeEventListener("gesturestart", prevent);
        },
    }),
    createTouchDetector = function createTouchDetector() {
        function e() {
            t.fire("touch-detected"),
                window.removeEventListener("touchstart", e, !1);
        }
        var t = _objectSpread({}, on(), {
            destroy: function destroy() {
                window.removeEventListener("touchstart", e, !1);
            },
        });
        return window.addEventListener("touchstart", e, !1), t;
    },
    createFileCatcher = function createFileCatcher(e) {
        var t,
            r = { browseEnabled: !1 },
            n = function n() {
                t.files.length && i.fire("drop", Array.from(t.files));
            },
            i = _objectSpread({}, on(), {
                enableBrowse: function enableBrowse() {
                    r.browseEnabled ||
                        (((t = document.createElement("input")).style.display =
                            "none"),
                        t.setAttribute("type", "file"),
                        t.addEventListener("change", n),
                        e.appendChild(t),
                        e.addEventListener("click", o),
                        (r.browseEnabled = !0));
                },
                disableBrowse: function disableBrowse() {
                    r.browseEnabled &&
                        (t.removeEventListener("change", n),
                        t.parentNode.removeChild(t),
                        e.removeEventListener("click", o),
                        (r.browseEnabled = !1));
                },
                destroy: function destroy() {
                    e.removeEventListener("dragover", a),
                        e.removeEventListener("drop", c),
                        e.removeEventListener("click", o),
                        t.removeEventListener("change", n);
                },
            }),
            o = function o() {
                return t.click();
            },
            a = function a(e) {
                return e.preventDefault();
            },
            c = function c(e) {
                e.preventDefault();
                var t = Array.from(
                    e.dataTransfer.items || e.dataTransfer.files
                ).map(function (e) {
                    return e.getAsFile && "file" === e.kind ? e.getAsFile() : e;
                });
                i.fire("drop", t);
            };
        return (
            e.addEventListener("dragover", a), e.addEventListener("drop", c), i
        );
    },
    createFocusTrap = function createFocusTrap(e) {
        var t = function t(_t4) {
            if (9 === _t4.keyCode) {
                var r = Array.from(
                        e.querySelectorAll("button,input,[tabindex]")
                    ).filter(function (e) {
                        return (
                            "hidden" !== e.style.visibility && -1 !== e.tabIndex
                        );
                    }),
                    n = r[0],
                    i = r[r.length - 1];
                _t4.shiftKey
                    ? document.activeElement === n &&
                      (i.focus(), _t4.preventDefault())
                    : document.activeElement === i &&
                      (n.focus(), _t4.preventDefault());
            }
        };
        return (
            e.addEventListener("keydown", t),
            {
                destroy: function destroy() {
                    e.removeEventListener("keydown", t);
                },
            }
        );
    },
    isFullscreen = function isFullscreen(e) {
        return e.ref.isFullscreen;
    },
    shouldBeFullscreen = function shouldBeFullscreen(e) {
        return /fullscreen/.test(e.query("GET_STYLE_LAYOUT_MODE"));
    },
    isFloating = function isFloating(e) {
        return /fullscreen|preview/.test(e.query("GET_STYLE_LAYOUT_MODE"));
    },
    mayBeAutoClosed = function mayBeAutoClosed(e) {
        return e.query("GET_ALLOW_AUTO_CLOSE");
    },
    canBeAutoClosed = isFloating,
    canBeClosed = isFloating,
    updateStyleViewport = function updateStyleViewport(e) {
        var t = e.ref,
            r = t.environment,
            n = t.isSingleUtil,
            i = t.canBeControlled;
        e.element.dataset.styleViewport =
            getViewportBySize(e.rect.element.width, e.rect.element.height) +
            " " +
            r.join(" ") +
            (n ? " single-util" : " multi-util") +
            (i ? " flow-controls" : " no-flow-controls");
    },
    setupFullscreenMode = function setupFullscreenMode(e) {
        var t = e.element,
            r = e.ref,
            n = r.handleFullscreenUpdate,
            i = r.handleEscapeKey;
        t.setAttribute("tabindex", -1),
            n(),
            (e.ref.focusTrap = createFocusTrap(t)),
            t.addEventListener("keydown", i),
            window.addEventListener("resize", n),
            document.body.classList.add("doka--parent"),
            document.body.appendChild(t);
        var o = document.querySelector("meta[name=viewport]");
        (e.ref.defaultViewportContent = o ? o.getAttribute("content") : null),
            o ||
                ((o = document.createElement("meta")).setAttribute(
                    "name",
                    "viewport"
                ),
                document.head.appendChild(o)),
            o.setAttribute(
                "content",
                "width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=0"
            ),
            (e.opacity = 1),
            e.element.contains(document.activeElement) || t.focus(),
            e.dispatch("INVALIDATE_VIEWPORT"),
            (e.ref.isFullscreen = !0);
    },
    cleanFullscreenMode = function cleanFullscreenMode(e) {
        var t = e.element,
            r = e.ref,
            n = r.handleFullscreenUpdate,
            i = r.focusTrap,
            o = r.handleEscapeKey;
        t.removeAttribute("tabindex"),
            i.destroy(),
            t.removeEventListener("keydown", o),
            window.removeEventListener("resize", n),
            document.body.classList.remove("doka--parent");
        var a = document.querySelector("meta[name=viewport]");
        e.ref.defaultViewportContent
            ? (a.setAttribute("content", e.ref.defaultViewportContent),
              (e.ref.defaultViewportContent = null))
            : a.parentNode.removeChild(a),
            (e.ref.isFullscreen = !1);
    },
    root = createView({
        name: "root",
        ignoreRect: !0,
        mixins: {
            styles: ["opacity"],
            animations: { opacity: { type: "tween", duration: 350 } },
        },
        create: function create(e) {
            var t = e.root,
                r = e.props;
            t.element.id = t.query("GET_ID") || "doka-".concat(r.id);
            var n = t.query("GET_CLASS_NAME");
            n && t.element.classList.add(n),
                (t.ref.environment = []),
                (t.ref.shouldBeDestroyed = !1),
                (t.ref.isClosing = !1),
                (t.ref.isClosed = !1),
                (t.ref.isFullscreen = !1),
                t.query("GET_ALLOW_DROP_FILES") &&
                    ((t.ref.catcher = createFileCatcher(t.element)),
                    t.ref.catcher.on("drop", function (e) {
                        e.forEach(function (e) {
                            t.dispatch("REQUEST_LOAD_IMAGE", { source: e });
                        });
                    })),
                (t.ref.touchDetector = createTouchDetector()),
                t.ref.touchDetector.onOnce("touch-detected", function () {
                    t.ref.environment.push("touch");
                }),
                (t.ref.editor = t.appendChildView(
                    t.createChildView(editor, { id: r.id })
                )),
                t
                    .query("GET_STYLES")
                    .filter(function (e) {
                        return !isEmpty(e.value);
                    })
                    .map(function (e) {
                        var r = e.name,
                            n = e.value;
                        t.element.dataset[r] = n;
                    }),
                (t.ref.updateViewport = function () {
                    t.dispatch("INVALIDATE_VIEWPORT");
                }),
                window.addEventListener("resize", t.ref.updateViewport),
                window.addEventListener("scroll", t.ref.updateViewport),
                (t.ref.isSingleUtil = 1 === t.query("GET_UTILS").length),
                (t.ref.canBeControlled =
                    t.query("GET_ALLOW_BUTTON_CONFIRM") ||
                    t.query("GET_ALLOW_BUTTON_CANCEL")),
                updateStyleViewport(t);
            var i = document.createElement("div");
            (i.style.cssText = "position:fixed;height:100vh;top:0;"),
                (t.ref.measure = i),
                document.body.appendChild(i),
                (t.ref.handleEscapeKey = function (e) {
                    27 === e.keyCode && t.dispatch("EDIT_CANCEL");
                }),
                (t.ref.initialScreenMeasureHeight = null),
                (t.ref.handleFullscreenUpdate = function () {
                    t.element.dataset.styleFullscreen =
                        window.innerHeight === t.ref.initialScreenMeasureHeight;
                }),
                (t.ref.clientRect = { left: 0, top: 0 });
        },
        read: function read(e) {
            var t = e.root,
                r = t.ref.measure;
            r &&
                ((t.ref.initialScreenMeasureHeight = r.offsetHeight),
                r.parentNode.removeChild(r),
                (t.ref.measure = null)),
                (t.ref.clientRect = t.element.getBoundingClientRect()),
                (t.ref.clientRect.leftScroll =
                    t.ref.clientRect.left +
                    (window.scrollX || window.pageXOffset)),
                (t.ref.clientRect.topScroll =
                    t.ref.clientRect.top +
                    (window.scrollY || window.pageYOffset));
        },
        write: createRoute(
            {
                ENTER_FULLSCREEN: function ENTER_FULLSCREEN(e) {
                    var t = e.root;
                    setupFullscreenMode(t);
                },
                EXIT_FULLSCREEN: function EXIT_FULLSCREEN(e) {
                    var t = e.root;
                    cleanFullscreenMode(t);
                },
                SHOW_VIEW: function SHOW_VIEW(e) {
                    var t = e.root,
                        r = e.action;
                    t.element.dataset.view = r.id;
                },
                DID_SET_STYLE_LAYOUT_MODE: function DID_SET_STYLE_LAYOUT_MODE(
                    e
                ) {
                    var t = e.root,
                        r = e.action;
                    (t.element.dataset.styleLayoutMode = r.value || "none"),
                        /fullscreen/.test(r.value) &&
                            !/fullscreen/.test(r.prevValue) &&
                            t.dispatch("ENTER_FULLSCREEN");
                },
                AWAITING_IMAGE: function AWAITING_IMAGE(e) {
                    var t = e.root;
                    t.ref.catcher &&
                        t.query("GET_ALLOW_BROWSE_FILES") &&
                        t.ref.catcher.enableBrowse();
                },
                DID_REQUEST_LOAD_IMAGE: function DID_REQUEST_LOAD_IMAGE(e) {
                    var t = e.root;
                    if (
                        (t.ref.catcher &&
                            t.query("GET_ALLOW_BROWSE_FILES") &&
                            t.ref.catcher.disableBrowse(),
                        0 === t.opacity && (t.opacity = 1),
                        (t.ref.isClosing = !1),
                        (t.ref.isClosed = !1),
                        !shouldBeFullscreen(t) || isFullscreen(t))
                    ) {
                        var r = t.query("GET_STYLE_LAYOUT_MODE");
                        (null !== r && "modal" !== r) ||
                            t.element.parentNode ||
                            t.dispatch("SET_STYLE_LAYOUT_MODE", {
                                value: ("fullscreen " + (r || "")).trim(),
                            });
                    } else t.dispatch("ENTER_FULLSCREEN");
                },
                DID_CANCEL: function DID_CANCEL(e) {
                    var t = e.root;
                    canBeAutoClosed(t) &&
                        mayBeAutoClosed(t) &&
                        t.dispatch("EDIT_CLOSE");
                },
                DID_CONFIRM: function DID_CONFIRM(e) {
                    var t = e.root;
                    canBeAutoClosed(t) &&
                        mayBeAutoClosed(t) &&
                        t.dispatch("EDIT_CLOSE");
                },
                EDIT_CLOSE: function EDIT_CLOSE(e) {
                    var t = e.root;
                    canBeClosed(t) &&
                        ((t.opacity = t.opacity || 1),
                        (t.opacity = 0),
                        (t.ref.isClosed = !1),
                        (t.ref.isClosing = !0),
                        t.query("GET_ALLOW_AUTO_DESTROY") &&
                            (t.ref.shouldBeDestroyed = !0),
                        isFullscreen(t) && t.dispatch("EXIT_FULLSCREEN"));
                },
                DID_SET_UTILS: function DID_SET_UTILS(e) {
                    var t = e.root;
                    t.ref.isSingleUtil = 1 === t.query("GET_UTILS").length;
                },
            },
            function (e) {
                var t = e.root;
                updateStyleViewport(t);
                var r = t.query("GET_ROOT"),
                    n = t.rect.element;
                (r.width === n.width &&
                    r.height === n.height &&
                    r.y === t.ref.clientRect.top &&
                    r.topScroll === t.ref.clientRect.topScroll) ||
                    t.dispatch("UPDATE_ROOT_RECT", {
                        rect: {
                            x: t.ref.clientRect.left,
                            y: t.ref.clientRect.top,
                            left: t.ref.editor.rect.element.left,
                            top: t.ref.editor.rect.element.top,
                            leftScroll: t.ref.clientRect.leftScroll,
                            topScroll: t.ref.clientRect.topScroll,
                            width: t.rect.element.width,
                            height: t.rect.element.height,
                        },
                    });
            }
        ),
        didWriteView: function didWriteView(e) {
            var t = e.root,
                r = t.ref,
                n = r.isClosed,
                i = r.isClosing,
                o = r.shouldBeDestroyed;
            !n &&
                i &&
                0 === t.opacity &&
                (t.dispatch("DID_CLOSE"),
                (t.ref.isClosed = !0),
                (t.ref.isClosing = !1),
                shouldBeFullscreen(t) &&
                    t.element.parentNode &&
                    document.body.removeChild(t.element),
                o && t.dispatch("EDIT_DESTROY"));
        },
        destroy: function destroy(e) {
            var t = e.root;
            isFullscreen(t) && cleanFullscreenMode(t),
                shouldBeFullscreen(t) &&
                    t.element.parentNode &&
                    document.body.removeChild(t.element),
                window.removeEventListener("resize", t.ref.updateViewport),
                t.ref.touchDetector.destroy(),
                t.ref.catcher && t.ref.catcher.destroy();
        },
    }),
    getViewportBySize = function getViewportBySize(e, t) {
        var r = "";
        return 0 === e && 0 === t
            ? "detached"
            : ((r += t > e ? "portrait" : "landscape"),
              (r +=
                  e <= 600
                      ? " x-cramped"
                      : e <= 1e3
                      ? " x-comfortable"
                      : " x-spacious").trim());
    },
    createApp = function createApp() {
        var e =
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {},
            t = getOptions(),
            r = createStore(
                createInitialState(t),
                [queries, createOptionQueries(t)],
                [actions, createOptionActions(t)]
            );
        r.dispatch("SET_OPTIONS", { options: e });
        var n = function n() {
            document.hidden || r.dispatch("KICK");
        };
        document.addEventListener("visibilitychange", n);
        var i = getUniqueId();
        r.dispatch("SET_UID", { id: i });
        var o = null,
            a = root(r, { id: i }),
            c = !1,
            l = {
                _read: function _read() {
                    c || a._read();
                },
                _write: function _write(e) {
                    var t = r.processActionQueue().filter(function (e) {
                        return !/^SET_/.test(e.type);
                    });
                    (c && !t.length) ||
                        (d(t),
                        (c = a._write(e, t)) && r.processDispatchQueue(),
                        t.find(function (e) {
                            return "EDIT_DESTROY" === e.type;
                        }) && f());
                },
            },
            u = function u(e) {
                return function (t) {
                    var r = { type: e };
                    return t
                        ? (t.hasOwnProperty("error") &&
                              (r.error = isObject(t.error)
                                  ? _objectSpread({}, t.error)
                                  : t.error || null),
                          t.hasOwnProperty("output") && (r.output = t.output),
                          t.hasOwnProperty("image") && (r.image = t.image),
                          t.hasOwnProperty("source") && (r.source = t.source),
                          t.hasOwnProperty("state") && (r.state = t.state),
                          r)
                        : r;
                };
            },
            s = {
                DID_CONFIRM: u("confirm"),
                DID_CANCEL: u("cancel"),
                DID_REQUEST_LOAD_IMAGE: u("loadstart"),
                DID_LOAD_IMAGE: u("load"),
                DID_LOAD_IMAGE_ERROR: u("loaderror"),
                DID_UPDATE: u("update"),
                DID_CLOSE: u("close"),
                DID_DESTROY: u("destroy"),
                DID_INIT: u("init"),
            },
            d = function d(e) {
                e.length &&
                    e.forEach(function (e) {
                        if (s[e.type]) {
                            var t = s[e.type];
                            (Array.isArray(t) ? t : [t]).forEach(function (t) {
                                setTimeout(function () {
                                    !(function (e) {
                                        var t = _objectSpread({ doka: p }, e);
                                        delete t.type,
                                            a &&
                                                a.element.dispatchEvent(
                                                    new CustomEvent(
                                                        "Doka:".concat(e.type),
                                                        {
                                                            detail: t,
                                                            bubbles: !0,
                                                            cancelable: !0,
                                                            composed: !0,
                                                        }
                                                    )
                                                );
                                        var n = [];
                                        e.hasOwnProperty("error") &&
                                            n.push(e.error);
                                        var i = ["type", "error"];
                                        Object.keys(e)
                                            .filter(function (e) {
                                                return !i.includes(e);
                                            })
                                            .forEach(function (t) {
                                                return n.push(e[t]);
                                            }),
                                            p.fire.apply(p, [e.type].concat(n));
                                        var o = r.query(
                                            "GET_ON".concat(
                                                e.type.toUpperCase()
                                            )
                                        );
                                        o && o.apply(void 0, n);
                                    })(t(e.data));
                                }, 0);
                            });
                        }
                    });
            },
            f = function f() {
                p.fire("destroy", a.element),
                    document.removeEventListener("visibilitychange", n),
                    a._destroy(),
                    r.dispatch("DID_DESTROY");
            },
            p = _objectSpread({}, on(), l, createOptionAPI(r, t), {
                setOptions: function setOptions(e) {
                    return r.dispatch("SET_OPTIONS", { options: e });
                },
                setData: function setData(e) {
                    r.dispatch("SET_DATA", e);
                },
                getData: function getData(e) {
                    return new Promise(function (t, n) {
                        r.dispatch(
                            "GET_DATA",
                            _objectSpread({}, e, { success: t, failure: n })
                        );
                    });
                },
                open: function open(e) {
                    var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                            ? arguments[1]
                            : {};
                    return new Promise(function (n, i) {
                        e &&
                            r.dispatch("REQUEST_LOAD_IMAGE", {
                                source: e,
                                options: t,
                                success: n,
                                failure: i,
                                resolveOnConfirm: !!t && t.resolveOnConfirm,
                            });
                    });
                },
                edit: function edit(e, t) {
                    return p.open(
                        e,
                        _objectSpread({}, t, { resolveOnConfirm: !0 })
                    );
                },
                save: function save(e) {
                    return new Promise(function (t, n) {
                        r.dispatch(
                            "GET_DATA",
                            _objectSpread({}, e, { success: t, failure: n })
                        );
                    });
                },
                clear: function clear() {
                    return r.dispatch("REQUEST_REMOVE_IMAGE");
                },
                close: function close() {
                    return r.dispatch("EDIT_CLOSE");
                },
                destroy: f,
                insertBefore: function insertBefore(e) {
                    _insertBefore(a.element, e);
                },
                insertAfter: function insertAfter(e) {
                    _insertAfter(a.element, e);
                },
                appendTo: function appendTo(e) {
                    e.appendChild(a.element);
                },
                replaceElement: function replaceElement(e) {
                    _insertBefore(a.element, e),
                        e.parentNode.removeChild(e),
                        (o = e);
                },
                restoreElement: function restoreElement() {
                    o &&
                        (_insertAfter(o, a.element),
                        a.element.parentNode.removeChild(a.element),
                        (o = null));
                },
                isAttachedTo: function isAttachedTo(e) {
                    return !!a && (a.element === e || o === e);
                },
                element: {
                    get: function get() {
                        return a ? a.element : null;
                    },
                },
            });
        return r.dispatch("DID_INIT"), createObject(p);
    },
    createAppObject = function createAppObject() {
        var e =
                arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {},
            t = getOptions(),
            r = {};
        return (
            forin(t, function (e, t) {
                isString(t) || (r[e] = t[0]);
            }),
            createApp(_objectSpread({}, r, e))
        );
    },
    toCamels = function toCamels(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : "-";
        return e.replace(new RegExp("".concat(t, "."), "g"), function (e) {
            return e.charAt(1).toUpperCase();
        });
    },
    lowerCaseFirstLetter = function lowerCaseFirstLetter(e) {
        return e.charAt(0).toLowerCase() + e.slice(1);
    },
    attributeNameToPropertyName = function attributeNameToPropertyName(e) {
        return toCamels(e.replace(/^data-/, ""));
    },
    mapObject = function e(t, r) {
        forin(r, function (r, n) {
            forin(t, function (e, i) {
                var o = new RegExp(r);
                if (o.test(e) && (delete t[e], !1 !== n))
                    if (isString(n)) t[n] = i;
                    else {
                        var a = n.group;
                        isObject(n) && !t[a] && (t[a] = {}),
                            (t[a][lowerCaseFirstLetter(e.replace(o, ""))] = i);
                    }
            }),
                n.mapping && e(t[n.group], n.mapping);
        });
    },
    getAttributesAsObject = function getAttributesAsObject(e) {
        var t =
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : {},
            r = [];
        forin(e.attributes, function (t) {
            return r.push(e.attributes[t]);
        });
        var n = r
            .filter(function (e) {
                return e.name;
            })
            .reduce(function (t, r) {
                var n = attr(e, r.name);
                return (
                    (t[attributeNameToPropertyName(r.name)] =
                        n === r.name || n),
                    t
                );
            }, {});
        return mapObject(n, t), n;
    },
    createAppAtElement = function createAppAtElement(e) {
        var t = _objectSpread(
                {},
                arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : {}
            ),
            r = getAttributesAsObject(e, { "^class$": "className" });
        Object.keys(r).forEach(function (e) {
            isObject(r[e])
                ? (isObject(t[e]) || (t[e] = {}), Object.assign(t[e], r[e]))
                : (t[e] = r[e]);
        }),
            ("CANVAS" !== e.nodeName && "IMG" !== e.nodeName) ||
                (t.src = e.dataset.dokaSrc ? e.dataset.dokaSrc : e);
        var n = createAppObject(t);
        return n.replaceElement(e), n;
    },
    createApp$1 = function createApp$1() {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) {
            t[r] = arguments[r];
        }
        return isNode(t[0])
            ? createAppAtElement.apply(void 0, t)
            : createAppObject.apply(
                  void 0,
                  _toConsumableArray(
                      t.filter(function (e) {
                          return e;
                      })
                  )
              );
    },
    copyObjectPropertiesToObject = function copyObjectPropertiesToObject(
        e,
        t,
        r
    ) {
        Object.getOwnPropertyNames(e)
            .filter(function (e) {
                return !r.includes(e);
            })
            .forEach(function (r) {
                return Object.defineProperty(
                    t,
                    r,
                    Object.getOwnPropertyDescriptor(e, r)
                );
            });
    },
    PRIVATE_METHODS = ["fire", "_read", "_write"],
    createAppAPI = function createAppAPI(e) {
        var t = {};
        return copyObjectPropertiesToObject(e, t, PRIVATE_METHODS), t;
    },
    isOperaMini = function isOperaMini() {
        return (
            "[object OperaMini]" ===
            Object.prototype.toString.call(window.operamini)
        );
    },
    hasPromises = function hasPromises() {
        return "Promise" in window;
    },
    hasBlobSlice = function hasBlobSlice() {
        return "slice" in Blob.prototype;
    },
    hasCreateObjectURL = function hasCreateObjectURL() {
        return "URL" in window && "createObjectURL" in window.URL;
    },
    hasVisibility = function hasVisibility() {
        return "visibilityState" in document;
    },
    hasTiming = function hasTiming() {
        return "performance" in window;
    },
    supported = (function () {
        var e =
            isBrowser() &&
            !isOperaMini() &&
            hasVisibility() &&
            hasPromises() &&
            hasBlobSlice() &&
            hasCreateObjectURL() &&
            hasTiming();
        return function () {
            return e;
        };
    })(),
    state = { apps: [] },
    name = "doka",
    fn = function fn() {},
    OptionTypes = {},
    create$1 = fn,
    destroy = fn,
    parse = fn,
    find = fn,
    getOptions$1 = fn,
    setOptions$1 = fn;
if (supported()) {
    createPainter(
        function () {
            state.apps.forEach(function (e) {
                return e._read();
            });
        },
        function (e) {
            state.apps.forEach(function (t) {
                return t._write(e);
            });
        }
    );
    var dispatch = function e() {
        document.dispatchEvent(
            new CustomEvent("doka:loaded", {
                detail: {
                    supported: supported,
                    create: create$1,
                    destroy: destroy,
                    parse: parse,
                    find: find,
                    setOptions: setOptions$1,
                },
            })
        ),
            document.removeEventListener("DOMContentLoaded", e);
    };
    "loading" !== document.readyState
        ? setTimeout(function () {
              return dispatch();
          }, 0)
        : document.addEventListener("DOMContentLoaded", dispatch);
    var updateOptionTypes = function updateOptionTypes() {
        return forin(getOptions(), function (e, t) {
            OptionTypes[e] = t[1];
        });
    };
    (exports.OptionTypes = OptionTypes = {}),
        updateOptionTypes(),
        (exports.create = create$1 =
            function create$1() {
                var e = createApp$1.apply(void 0, arguments);
                return (
                    e.on("destroy", destroy),
                    state.apps.push(e),
                    createAppAPI(e)
                );
            }),
        (exports.destroy = destroy =
            function destroy(e) {
                var t = state.apps.findIndex(function (t) {
                    return t.isAttachedTo(e);
                });
                return (
                    t >= 0 && (state.apps.splice(t, 1)[0].restoreElement(), !0)
                );
            }),
        (exports.parse = parse =
            function parse(e) {
                return Array.from(e.querySelectorAll(".".concat(name)))
                    .filter(function (e) {
                        return !state.apps.find(function (t) {
                            return t.isAttachedTo(e);
                        });
                    })
                    .map(function (e) {
                        return create$1(e);
                    });
            }),
        (exports.find = find =
            function find(e) {
                var t = state.apps.find(function (t) {
                    return t.isAttachedTo(e);
                });
                return t ? createAppAPI(t) : null;
            }),
        (exports.getOptions = getOptions$1 =
            function getOptions$1() {
                var e = {};
                return (
                    forin(getOptions(), function (t, r) {
                        e[t] = r[0];
                    }),
                    e
                );
            }),
        (exports.setOptions = setOptions$1 =
            function setOptions$1(e) {
                return (
                    isObject(e) &&
                        (state.apps.forEach(function (t) {
                            t.setOptions(e);
                        }),
                        setOptions(e)),
                    getOptions$1()
                );
            });
}
exports.supported = supported;
exports.OptionTypes = OptionTypes;
exports.create = create$1;
exports.destroy = destroy;
exports.parse = parse;
exports.find = find;
exports.getOptions = getOptions$1;
exports.setOptions = setOptions$1;
