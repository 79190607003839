import { Box } from "@material-ui/core";
import { FC } from "react";
import Barcode from "react-barcode";

import AroggaLogo from "../assets/images/logo.png";

type BarcodeCardProps = {
    item: any;
    page: "orders" | "barcodes";
};

const BarcodeCard: FC<BarcodeCardProps> = ({ item, page }) => (
    <div
        style={
            page === "barcodes"
                ? {
                      width: "633px",
                      height: "235px",
                  }
                : {}
        }
    >
        <Box display="flex" justifyContent="space-between" mb={2}>
            <img
                src={AroggaLogo}
                alt="Logo"
                style={{
                    maxWidth: page === "barcodes" ? "100%" : 98,
                    height: page === "barcodes" ? "auto" : 44,
                }}
            />
            <span
                style={{
                    fontSize: page === "barcodes" ? "50px" : "20px",
                }}
            >
                {item.l_zone}
            </span>
            <span
                style={{
                    fontSize: page === "barcodes" ? "50px" : "20px",
                }}
            >
                ID:
                <span
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    {item.o_id}
                </span>
            </span>
        </Box>
        <Barcode
            value={`O-${item?.o_id?.toString()?.padStart(10, "0")}`}
            displayValue={false}
            margin={0}
            width={page === "barcodes" ? 5.1 : 2.9}
            height={page === "orders" ? 60 : 100}
        />
        <Box
            display="flex"
            justifyContent="space-between"
            pr={1}
            fontSize={page === "orders" ? 8 : 16}
        >
            {!!item.l_area && !!item.l_zone && <span>{item.l_area}</span>}
            {!!item.ul_mobile && <span>Phone: {item.ul_mobile}</span>}
            {!!item.o_created && (
                <span>Order Date: {item.o_created?.split(" ")[0]}</span>
            )}
            {!!item.o_item_count && (
                <span>
                    Total: {item.o_item_count}{" "}
                    {item.o_item_count === 1 ? "item" : "items"}
                </span>
            )}
        </Box>
        {!!item.ul_location && (
            <div
                style={{
                    paddingTop: 10,
                    fontSize: page === "orders" ? "8px" : "16px",
                }}
            >
                Address: {item.ul_location}
            </div>
        )}
    </div>
);

export default BarcodeCard;
