import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { Identifier, useRefresh, useUnselectAll } from "react-admin";

import useRequest from "../../../hooks/useRequest";
import AroggaDialogActions from "../../AroggaDialogActions";

type BarcodeDialogProps = {
    resource: string;
    selectedIds: Identifier[];
    action: string;
    open: boolean;
    handleClose: () => void;
};

const BarcodeDialog: FC<BarcodeDialogProps> = ({
    resource,
    selectedIds,
    action,
    open,
    handleClose,
}) => {
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    const { isLoading, refetch } = useRequest(
        "/v1/orders/updateMany",
        {
            method: "POST",
            body: { ids: selectedIds, "data[action]": action },
        },
        {
            onSuccess: () => {
                refresh();
                unselectAll(resource);
            },
        }
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {`Are you sure you want to ${
                    action === "enableBarcode" ? "enable" : "disable"
                } barcode for this orders?`}
            </DialogTitle>
            <DialogContent>{selectedIds?.join(", ")}</DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                confirmLabel="Save"
                onDialogClose={handleClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default BarcodeDialog;
