import { useEffect } from "react";
import { NumberInput, TextInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { convertToSnakeCase } from "../../../utils/helpers";

const ChangeMultiplierDialogContent = ({ record }) => {
    const form = useForm();
    const { values } = useFormState();

    const { m_multiplier = 0 } = record || ({} as any);

    useEffect(() => {
        initializeRecordValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (values.m_multiplier === m_multiplier)
            return initializeRecordValue();

        calculatedValueOnMultiplierChanged();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.m_multiplier]);

    const initializeRecordValue = () => {
        ["mUnit", "mPrice", "mDPrice", "mWPrice"].forEach((key) =>
            form.change(key, values[convertToSnakeCase(key)])
        );
    };

    const calculatedValueOnMultiplierChanged = () => {
        form.change(
            "mUnit",
            values.m_multiplier === 1
                ? `${values.m_multiplier || 0} ${values.m_form}`
                : `${values.m_multiplier || 0} ${values.m_form}s`
        );
        ["mPrice", "mDPrice", "mWPrice"].forEach((key) =>
            form.change(
                key,
                (values[convertToSnakeCase(key)] / m_multiplier) *
                    values.m_multiplier
            )
        );
    };

    return (
        <>
            <TextInput
                source="m_form"
                label="Form"
                variant="outlined"
                fullWidth
                disabled
            />
            <TextInput
                source="mUnit"
                label="Unit"
                variant="outlined"
                fullWidth
                disabled
            />
            <NumberInput
                source="mPrice"
                label="Price"
                variant="outlined"
                fullWidth
                disabled
            />
            <NumberInput
                source="mDPrice"
                label="Discount Price"
                variant="outlined"
                fullWidth
                disabled
            />
            <NumberInput
                source="mWPrice"
                label="Wholesale Price"
                variant="outlined"
                fullWidth
                disabled
            />
            <NumberInput
                source="m_multiplier"
                label="Multiplier"
                variant="outlined"
                fullWidth
            />
        </>
    );
};

export default ChangeMultiplierDialogContent;
