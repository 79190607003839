import {
    AutocompleteInput,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import TreeDropdownInput from "../../../components/TreeDropdownInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import YearSelectInput from "../../../components/YearSelectInput";
import { monthsWithId } from "../../../utils/constants";

const salaryFilter = [
    <TextInput
        label="Search"
        source="_search"
        variant="outlined"
        alwaysOn
        resettable
    />,
    <ReferenceInput
        source="_employee_id"
        label="Employee"
        variant="outlined"
        reference="v1/employee"
        filterToQuery={(searchText) => ({
            _search: searchText,
        })}
    >
        <AutocompleteInput
            matchSuggestion={() => true}
            optionValue="e_id"
            helperText={false}
            optionText={<UserEmployeeOptionTextRenderer isEmployee />}
            inputText={(record: { e_name: string; e_mobile: string }) =>
                !!record ? `${record.e_name} (${record.e_mobile})` : ""
            }
            resettable
        />
    </ReferenceInput>,
    <TreeDropdownInput
        reference="/v1/taxonomiesByVocabulary/department"
        source="_department"
        label="Department"
        variant="outlined"
        keyId="t_id"
        keyParent="t_parent_id"
        optionValue="t_machine_name"
        optionTextValue="t_title"
    />,
    <TreeDropdownInput
        reference="/v1/rank"
        filter={{ _page: 1, _perPage: 5000 }}
        source="_designation"
        label="Designation"
        variant="outlined"
        keyId="r_id"
        keyParent="r_parent"
        keyWeight="r_weight"
        optionTextValue="r_title"
    />,
    <YearSelectInput source="_year" variant="outlined" />,
    <SelectInput
        source="_month"
        label="Month"
        variant="outlined"
        choices={monthsWithId}
    />,
    <TaxonomiesByVocabularyInput
        fetchKey="salary_status_type?_authFilter=1"
        source="_status"
        label="Type"
    />,
    <TaxonomiesByVocabularyInput
        fetchKey="payment_mode?_authFilter=1"
        source="_payment_mode"
        label="Payment Mode"
    />,
];

export default salaryFilter;
