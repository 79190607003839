import { FC } from "react";
import { LinearProgress } from "react-admin";

const AroggaProgress: FC = () => (
    <div
        style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }}
    >
        <LinearProgress />
    </div>
);

export default AroggaProgress;
