import { FC, useState } from "react";
import { Edit, EditProps, SimpleForm, TextInput, Toolbar } from "react-admin";

import MedicineForm from "../../../components/manageOrder/orders/MedicineForm";
import MedicineTable from "../../../components/manageOrder/orders/medicineTable";
import { useDocumentTitle } from "../../../hooks";

const OfflineOrderEdit: FC<EditProps> = (props) => {
    useDocumentTitle("Arogga | Offline Order Edit");

    const [allItems, setAllItems] = useState([]);

    return (
        <Edit {...props}>
            <SimpleForm
                redirect="list"
                submitOnEnter={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
            >
                <TextInput
                    source="o_id"
                    label="Id"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TextInput
                    source="o_subtotal"
                    label="Subtotal"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TextInput
                    source="o_addition"
                    label="Addition"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TextInput
                    source="o_deduction"
                    label="Deduction"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TextInput
                    source="o_total"
                    label="Total"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <MedicineTable allItems={allItems} setAllItems={setAllItems} />
                <MedicineForm allItems={allItems} setAllItems={setAllItems} />
            </SimpleForm>
        </Edit>
    );
};

export default OfflineOrderEdit;
