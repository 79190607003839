import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

import AddressTypeInput from "../../../components/AddressTypeInput";
import LocationInput from "../../../components/LocationInput";
import UserOptionTextRenderer from "../../../components/UserOptionTextRenderer";

const AddressFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <ReferenceInput
            source="u_id"
            label="User"
            variant="outlined"
            reference="v1/users"
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserOptionTextRenderer />}
                inputText={(record: { u_name: string; u_mobile: string }) =>
                    !!record ? `${record.u_name} (${record.u_mobile})` : ""
                }
                resettable
            />
        </ReferenceInput>
        <LocationInput source="l_id" label="Location" variant="outlined" />
        <AddressTypeInput
            source="_ul_type"
            label="Address Type"
            variant="outlined"
        />
    </Filter>
);

export default AddressFilter;
