import dataProvider from "./dataProvider";
import { convertFileToBase64, imageCompress, logger } from "./utils/helpers";

const uploadDataProvider = {
    ...dataProvider,
    create: (resource: string, params: object) => {
        return createUpdateFunc("create", resource, params);
    },
    update: (resource: string, params: object) => {
        return createUpdateFunc("update", resource, params);
    },
};

const createUpdateFunc = (method: string, resource: string, params) => {
    let callbackFun: any = "";

    if (method === "create") {
        callbackFun = dataProvider.create;
    } else if (method === "update") {
        callbackFun = dataProvider.update;
    }

    const attachedFilesKeys = Object.keys(params.data).filter(
        (item) => item.startsWith("attachedFiles") && !!params.data[item]
    );

    if (!attachedFilesKeys?.length) {
        // fallback to the default implementation
        return callbackFun(resource, params);
    }

    const attachedFiles =
        !!attachedFilesKeys?.length &&
        attachedFilesKeys.map((key) => ({
            key: key,
            values: params.data[key],
        }));

    const filteredAttachedFiles =
        !!attachedFiles?.length &&
        attachedFiles.filter((item) => {
            if (item.values?.rawFile instanceof File) {
                item.values = [item.values];
            }

            return item;
        });

    return Promise.all(
        filteredAttachedFiles.map((item) => {
            return Promise.all(
                item.values?.map((file: File) =>
                    imageCompress(file, resource, item)
                )
            )
                .then((compressedFiles) =>
                    Promise.all(compressedFiles.map(convertFileToBase64))
                )
                .then((items) => ({ key: item.key, values: items }));
        })
    )
        .then((items) =>
            callbackFun(resource, {
                ...params,
                data: {
                    ...params.data,
                    ...Object.fromEntries(
                        items?.map((item) => [item.key, item.values])
                    ),
                },
            })
        )
        .catch((err) => logger(err));
};

export default uploadDataProvider;
