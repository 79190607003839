import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SettingsIcon from "@material-ui/icons/Settings";
import { MenuItemLink, usePermissions, UserMenu } from "react-admin";

const MyUserMenu = (props) => {
    const { permissions } = usePermissions();

    return (
        <UserMenu {...props}>
            {permissions?.includes("siteSettingsMenu") && (
                <MenuItemLink
                    to="/site-settings"
                    primaryText="Settings"
                    leftIcon={<SettingsIcon />}
                />
            )}
            {permissions?.includes("switchUser") && (
                <MenuItemLink
                    to="/switch-to"
                    primaryText="Switch To"
                    leftIcon={<ArrowForwardIcon />}
                />
            )}
        </UserMenu>
    );
};

export default MyUserMenu;
