import { FC } from "react";
import {
    Edit,
    EditProps,
    NumberInput,
    SimpleForm,
    TextInput,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import OrderMedicineAvailableStatusInput from "../../../components/manageOrder/ordersMedicines/OrderMedicineAvailableStatusInput";
import { useDocumentTitle } from "../../../hooks";

const OrderMedicinesEdit: FC<EditProps> = (props) => {
    useDocumentTitle("Arogga | Order Medicines Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...props}
        >
            <SimpleForm toolbar={<SaveDeleteToolbar isSave />}>
                <TextInput
                    source="o_id"
                    label="Order Id"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_name"
                    label="Name"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_form"
                    label="Form"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_strength"
                    label="Strength"
                    variant="outlined"
                    disabled
                />
                <TextInput
                    source="m_unit"
                    label="Unit"
                    variant="outlined"
                    disabled
                />
                <NumberInput
                    source="m_qty"
                    label="Quantity"
                    variant="outlined"
                    disabled
                />
                <NumberInput
                    source="m_price"
                    label="Price"
                    variant="outlined"
                    disabled
                />
                <NumberInput
                    source="m_d_price"
                    label="Discount Price"
                    variant="outlined"
                    disabled
                />
                <NumberInput
                    source="s_price"
                    label="Supplier Price"
                    variant="outlined"
                />
                <OrderMedicineAvailableStatusInput
                    source="om_status"
                    variant="outlined"
                />
            </SimpleForm>
        </Edit>
    );
};

export default OrderMedicinesEdit;
