import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { FC, useState } from "react";
import {
    Edit,
    EditProps,
    FormTab,
    ImageField,
    TabbedForm,
    TextInput,
    Toolbar,
} from "react-admin";

import UserLocationInput from "../../../components/UserLocationInput";
import CartStatusInput from "../../../components/manageOrder/carts/CartStatusInput";
import MedicineForm from "../../../components/manageOrder/orders/MedicineForm";
import MedicineTable from "../../../components/manageOrder/orders/medicineTable";
import { useDocumentTitle } from "../../../hooks";

const CartEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Cart Edit");

    const [allItems, setAllItems] = useState([]);

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <TabbedForm
                submitOnEnter={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
                redirect="list"
            >
                <FormTab label="Location">
                    <UserLocationInput source="ul_id" />
                </FormTab>
                <FormTab label="Medicines">
                    <MedicineTable
                        allItems={allItems}
                        setAllItems={setAllItems}
                    />
                    <MedicineForm
                        allItems={allItems}
                        setAllItems={setAllItems}
                    />
                </FormTab>
                <FormTab label="Prescriptions">
                    <MovableImageInput
                        source="attachedFiles"
                        accept="image/*"
                        maxSize={5000000}
                        multiple
                    >
                        <ImageField source="src" title="title" />
                    </MovableImageInput>
                </FormTab>
                <FormTab label="Settings">
                    <TextInput
                        source="c_d_code"
                        label="Discount Code"
                        variant="outlined"
                    />
                    <CartStatusInput
                        source="c_status"
                        label="Status"
                        variant="outlined"
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default CartEdit;
