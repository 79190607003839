import { FC } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    FunctionField,
    Record,
    ReferenceInput,
    SimpleForm,
    TextInput,
    minLength,
    required,
} from "react-admin";

import AllowedRoles from "../../../components/manageTaxonomy/AllowedRoles";
import { useDocumentTitle } from "../../../hooks";

const TaxonomyCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Taxonomy Term Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <ReferenceInput
                    source="t_v_id"
                    label="Vocabulary"
                    variant="outlined"
                    reference="v1/vocabulary"
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionText="v_title"
                        resettable
                    />
                </ReferenceInput>
                <TextInput
                    source="t_title"
                    label="Title"
                    variant="outlined"
                    validate={[
                        required(),
                        minLength(
                            3,
                            "Title must be at least 3 characters long"
                        ),
                    ]}
                />
                <TextInput
                    source="t_description"
                    label="Description"
                    variant="outlined"
                    validate={[
                        minLength(
                            5,
                            "Description must be at least 5 characters long"
                        ),
                    ]}
                    minRows={2}
                    multiline
                />
                <FunctionField
                    label="Allowed Roles"
                    render={({ t_allowed_roles }: Record) => (
                        <AllowedRoles
                            allowedRolesFromRecord={t_allowed_roles}
                            isChecked
                        />
                    )}
                />
            </SimpleForm>
        </Create>
    );
};

export default TaxonomyCreate;
