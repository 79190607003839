import { Box, Button } from "@material-ui/core";
import { FC, useCallback } from "react";
import { SaveButton, Toolbar } from "react-admin";
import { useForm, useFormState } from "react-final-form";

type PurchaseInvoiceToolbarProps = {
    page?: "create" | "edit";
    setTpPrice?: (tpPrice: string) => void;
    allItems?: object[];
    setAllItems?: (allItems) => void;
    [key: string]: any;
};

const PurchaseInvoiceToolbar: FC<PurchaseInvoiceToolbarProps> = ({
    page,
    setTpPrice,
    allItems,
    setAllItems,
    ...rest
}) => {
    const form = useForm();
    const { values } = useFormState();

    const handleClear = useCallback(() => {
        setTpPrice("");
        setAllItems([]);
        form.setConfig("keepDirtyOnReinitialize", false);
        form.reset();
        form.setConfig("keepDirtyOnReinitialize", true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    return (
        <Toolbar {...rest}>
            {page === "create" && (
                <Box ml={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                </Box>
            )}
            <SaveButton
                disabled={
                    (page === "create" && !allItems?.length) ||
                    !!values.isAmountMismatched
                }
            />
        </Toolbar>
    );
};

export default PurchaseInvoiceToolbar;
