import { FC } from "react";
import { Filter, FilterProps, TextInput } from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";

const BankFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <TextInput
            source="_branch"
            label="Branch"
            variant="outlined"
            alwaysOn
            resettable
        />
        <TextInput
            source="_name"
            label="Name"
            variant="outlined"
            alwaysOn
            resettable
        />
        <FormatedBooleanInput source="_active" label="Active" />
    </Filter>
);

export default BankFilter;
