import { Editor } from "@tinymce/tinymce-react";
import { FC, useState } from "react";
import {
    Create,
    CreateProps,
    FormDataConsumer,
    ImageField,
    ImageInput,
    Labeled,
    SimpleForm,
    TextInput,
    required,
} from "react-admin";

import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import TreeDropdownInput from "../../../components/TreeDropdownInput";
import JobStatusInput from "../../../components/manageHR/hiring/JobStatusInput";
import { useDocumentTitle } from "../../../hooks";
import { TINY_MCE_EDITOR_INIT } from "../../../utils/constants";

const JobCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Job Create");

    const [description, setDescription] = useState("");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="j_title" label="Title" variant="outlined" />
                <TreeDropdownInput
                    reference="/v1/taxonomiesByVocabulary/department"
                    source="j_department"
                    label="Department"
                    variant="outlined"
                    keyId="t_id"
                    keyParent="t_parent_id"
                    optionValue="t_machine_name"
                    optionTextValue="t_title"
                    validate={[required()]}
                />
                <TreeDropdownInput
                    reference="/v1/rank"
                    filter={{ _page: 1, _perPage: 5000 }}
                    source="j_designation"
                    label="Designation"
                    variant="outlined"
                    keyId="r_id"
                    keyParent="r_parent"
                    keyWeight="r_weight"
                    optionTextValue="r_title"
                    disabledChoice
                />
                <JobStatusInput source="j_status" variant="outlined" />
                <TextInput
                    source="j_location"
                    label="Location"
                    variant="outlined"
                    defaultValue="Arogga Limited, 6th floor (Lift-5) Plot D/15-1, Road-36, Block-D, Section-10, Mirpur, Dhaka-1216"
                    validate={[required()]}
                    minRows={2}
                    multiline
                />
                <TaxonomiesByVocabularyInput
                    fetchKey="employee_type"
                    source="j_type"
                    defaultValue="full_time"
                    label="Type"
                    validate={[required()]}
                />
                <FormDataConsumer>
                    {({ formData }) => {
                        formData.j_description = description;

                        return (
                            <Labeled label="Description" fullWidth>
                                <Editor
                                    tinymceScriptSrc={
                                        process.env.PUBLIC_URL +
                                        "/tinymce/tinymce.min.js"
                                    }
                                    init={TINY_MCE_EDITOR_INIT}
                                    value={description}
                                    onEditorChange={(newValue) =>
                                        setDescription(newValue)
                                    }
                                />
                            </Labeled>
                        );
                    }}
                </FormDataConsumer>
                <ImageInput
                    source="attachedFiles-j_banner"
                    label="Banner (1800*945 px)"
                    accept="image/*"
                    maxSize={5000000}
                    multiple
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export default JobCreate;
