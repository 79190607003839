import { Route } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import DepartmentsPage from "./pages/manageHR/departments";
import DesignationsPage from "./pages/manageHR/designations";
import DesignationPermissionPage from "./pages/manageHR/designations/designationPermission";
import GeneratePrescription from "./pages/manageOrder/orders/GeneratePrescription";
import { PromotionalMessagePage } from "./pages/managePromotion";
import InventoryFlagsPage from "./pages/manageStock/inventoryFlags";
import PermissionsPage from "./pages/manageUser/permissions";
import BarcodesPage from "./pages/otherSettings/barcodes";
import LiveInfoPage from "./pages/otherSettings/liveInfo";
import UserHistoryPage from "./pages/otherSettings/liveInfo/UserHistory";
import PrescriptionPage from "./pages/otherSettings/prescriptions";
import SuperAdminSettingsPage from "./pages/otherSettings/saSettings";
import SettingsPage from "./pages/otherSettings/settings";
import SiteSettingsPage from "./pages/siteSettings";
import SwitchToPage from "./pages/switchTo";

const routes = [
    <PrivateRoute exact path="/departments" component={DepartmentsPage} />,
    <PrivateRoute exact path="/designations" component={DesignationsPage} />,
    <PrivateRoute
        exact
        path="/designation-permission/:designationId"
        component={DesignationPermissionPage}
    />,
    <PrivateRoute
        exact
        path="/inventory-flags"
        component={InventoryFlagsPage}
    />,
    <PrivateRoute
        exact
        path="/sa-settings"
        component={SuperAdminSettingsPage}
    />,
    <PrivateRoute exact path="/settings" component={SettingsPage} />,
    <PrivateRoute exact path="/barcodes" component={BarcodesPage} />,
    <PrivateRoute exact path="/prescriptions" component={PrescriptionPage} />,
    <PrivateRoute exact path="/live-info" component={LiveInfoPage} />,
    <PrivateRoute exact path="/permissions" component={PermissionsPage} />,
    <PrivateRoute exact path="/switch-to" component={SwitchToPage} />,
    <PrivateRoute
        exact
        path="/promotional-messages"
        component={PromotionalMessagePage}
    />,
    <PrivateRoute
        exact
        path="/manageOrder/orders/:orderId/generate-prescription"
        component={GeneratePrescription}
    />,
    // @ts-ignore
    <Route
        exact
        path="/user-history/:userIdForSelectedHistory"
        component={UserHistoryPage}
    />,
    // @ts-ignore
    <Route exact path="/site-settings" component={SiteSettingsPage} />,
];

export default routes;
