import { Grid } from "@material-ui/core";
import { FC } from "react";
import {
    FunctionField,
    Record,
    ReferenceField,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const TaxonomyShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Taxonomy Term Show");

    return (
        <Show {...props}>
            <ColumnShowLayout>
                <TextField source="t_id" label="Id" />
                <TextField source="t_title" label="Title" />
                <TextField source="t_description" label="Description" />
                <TextField source="t_machine_name" label="Machine Name" />
                <ReferenceField
                    source="t_v_id"
                    label="Vocabulary"
                    reference="v1/vocabulary"
                    sortBy="t_v_id"
                >
                    <TextField source="v_title" />
                </ReferenceField>
                <TextField source="t_created_at" label="Created" />
                <ReferenceField
                    source="t_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="t_created_by"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <TextField source="t_modified_at" label="Modified" />
                <ReferenceField
                    source="t_modified_by"
                    label="Modified By"
                    reference="v1/users"
                    sortBy="t_modified_by"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <FunctionField
                    label="Allowed Roles"
                    render={({ t_allowed_roles }: Record) => (
                        <Grid container>
                            {!!t_allowed_roles?.length &&
                                t_allowed_roles.map((role, index) => (
                                    <Grid key={index} xs={6} lg={4} item>
                                        {role}
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                />
            </ColumnShowLayout>
        </Show>
    );
};

export default TaxonomyShow;
