import AttachFileIcon from "@material-ui/icons/AttachFile";
import { FC, useState } from "react";
import {
    FileField,
    FunctionField,
    IconButtonWithTooltip,
    List,
    ListProps,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
    useRefresh,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import CustomizableDatagrid from "../../../lib/ra-customizable-datagrid";
import { AroggaUploader } from "../../../services/uploader";
import PurchaseInvoiceFilter from "./PurchaseInvoiceFilter";

const PurchaseInvoiceList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Purchase Invoice List");

    const exporter = useExport(rest);

    const UploadFile = ({ id }: { id: number }) => {
        const refresh = useRefresh();
        const [open, setOpen] = useState(false);

        return (
            <SimpleForm toolbar={false}>
                <IconButtonWithTooltip
                    label="Upload File"
                    children={<AttachFileIcon />}
                    onClick={() => setOpen(true)}
                    style={{
                        height: 30,
                        width: 30,
                    }}
                />
                <AroggaUploader
                    open={open}
                    accept="image/*, application/pdf"
                    source={`v1/purchaseInvoice/attachedFileUpload/${id}`}
                    webCam={false}
                    refresh={refresh}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            </SimpleForm>
        );
    };

    return (
        <List
            {...rest}
            title="List of Purchase Invoice"
            filters={<PurchaseInvoiceFilter children={""} />}
            perPage={25}
            sort={{ field: "pi_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes(
                        "purchaseInvoiceDelete"
                    )}
                />
            }
        >
            <CustomizableDatagrid
                hidableColumns={[
                    "pi_c_id",
                    "pi_created_at",
                    "pi_created_by",
                    "pi_checked_at",
                    "pi_checked_by",
                ]}
                rowClick="edit"
            >
                <TextField source="pi_id" label="Invoice Id" />
                <ReferenceField
                    source="pi_c_id"
                    label="Company"
                    reference="v1/companies"
                    link="show"
                >
                    <TextField source="c_name" />
                </ReferenceField>
                <FunctionField
                    source="pi_created_at"
                    label="Created At"
                    render={(record: Record) => {
                        if (record.pi_created_at === "0000-00-00 00:00:00")
                            return;
                        return record.pi_created_at;
                    }}
                />
                <ReferenceField
                    source="pi_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="u_name"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <FunctionField
                    source="pi_checked_at"
                    label="Checked At"
                    render={(record: Record) => {
                        if (record.pi_checked_at === "0000-00-00 00:00:00")
                            return;
                        return record.pi_checked_at;
                    }}
                />
                <ReferenceField
                    source="pi_checked_by"
                    label="Checked By"
                    reference="v1/users"
                    sortBy="u_name"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <TextField source="pi_shelved_at" label="Shelved At" />
                <ReferenceField
                    source="pi_shelved_by"
                    label="Shelved By"
                    reference="v1/users"
                    sortBy="u_name"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <TextField source="pi_tp_price" label="Total TP" />
                <TextField source="pi_vat" label="Total Vat" />
                <TextField source="pi_discount" label="Total Discount" />
                <TextField source="pi_inv_price" label="Amount Payable" />
                <TextField source="pi_payment_method" label="Payment Method" />
                <TextField source="pi_paid_at" label="Paid On" />
                <TextField source="pi_item_count" label="Item Count" />
                <TextField source="pi_status" label="Status" />
                <TextField source="pi_note" label="Note" />
                <FileField
                    source="attachedFiles"
                    src="src"
                    title="title"
                    target="_blank"
                    label="Related Files"
                />
                <FunctionField
                    label="Upload"
                    onClick={(e: { stopPropagation: () => void }) =>
                        e.stopPropagation()
                    }
                    render={({ id }) => <UploadFile id={+id} />}
                />
            </CustomizableDatagrid>
        </List>
    );
};

export default PurchaseInvoiceList;
