import { CircularProgress, makeStyles } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import queryString from "query-string";
import { FC, memo, useEffect } from "react";
import { Button, TextInput } from "react-admin";
import { FormSpy } from "react-final-form";
import { FixedSizeList } from "react-window";

import { useDocumentTitle, useFocusNext, useRequest } from "../../../hooks";
import QtyLabel from "../../QtyLabel";

type GenerateOrderSheetProps = {
    companyId: string;
    requestDays: string;
    requestCount: string;
    isIncludeLaterMedicine: number;
    medicines: any[];
    setFormValues?: (values: object) => void;
    setMedicines: (medicines: object[]) => void;
};

const GenerateOrderSheet: FC<GenerateOrderSheetProps> = ({
    companyId,
    requestDays,
    requestCount,
    isIncludeLaterMedicine,
    medicines,
    setMedicines,
    setFormValues,
}) => {
    useDocumentTitle("Arogga | Generate Order List");

    const classes = useStyles();
    const focusNextRef = useFocusNext();

    const queryObj = {};

    if (companyId) queryObj["_c_id"] = companyId;
    if (requestDays) queryObj["_req_days"] = requestDays;
    if (requestCount) queryObj["_req_count"] = requestCount;
    if (isIncludeLaterMedicine)
        queryObj["_include_later"] = isIncludeLaterMedicine;

    const endpoint = `/v1/requireMedicines?${queryString.stringify(queryObj)}`;

    const { data, isLoading, isSuccess } = useRequest(
        endpoint,
        {},
        { isPreFetching: true }
    );

    useEffect(() => {
        if (data) setMedicines(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const RemoveRow = ({ i_m_id }) => {
        const handleClickRowRemove = () => {
            const updatedMedicines =
                !!medicines?.length &&
                medicines.filter((item) => item.i_m_id !== i_m_id);
            setMedicines(updatedMedicines);
        };
        return (
            <Button onClick={handleClickRowRemove}>
                <HighlightOffIcon />
            </Button>
        );
    };

    if (isLoading)
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                <CircularProgress size={30} />
            </div>
        );

    const RowList = ({ index, style }) => {
        const {
            m_name,
            m_form,
            m_strength,
            m_unit,
            m_company,
            i_m_id,
            i_qty,
            wkly_req,
            lm_id,
            m_r_count,
        } = medicines[index];

        return (
            <div style={style} className={classes.row} key={index}>
                <div className={classes.col} style={{ width: "70px" }}>
                    {index + 1}
                </div>
                <div
                    className={classes.col}
                    style={
                        !!lm_id
                            ? { color: "#EF1962", width: "300px" }
                            : { width: "300px" }
                    }
                >
                    {m_name}
                </div>
                <div className={classes.col} style={{ width: "100px" }}>
                    {m_form}
                </div>
                <div className={classes.col} style={{ width: "100px" }}>
                    {m_strength}
                </div>
                <div className={classes.col} style={{ width: "100px" }}>
                    <QtyLabel qty={i_qty} medicine={medicines[index]} />
                </div>
                <div className={classes.col} style={{ width: "100px" }}>
                    {m_unit}
                </div>
                <div className={classes.col} style={{ width: "110px" }}>
                    <QtyLabel qty={wkly_req} medicine={medicines[index]} />
                </div>
                <div className={classes.col} style={{ width: "80px" }}>
                    {m_r_count}
                </div>
                <div className={classes.col} style={{ width: "230px" }}>
                    {m_company}
                </div>
                <div className={classes.col} style={{ width: "200px" }}>
                    <TextInput
                        source={`qty_text-${i_m_id}`}
                        label=""
                        variant="outlined"
                        inputRef={focusNextRef}
                        style={{ marginTop: "25px" }}
                    />
                </div>
                <div className={classes.col} style={{ width: "60px" }}>
                    <RemoveRow i_m_id={i_m_id} />
                </div>
            </div>
        );
    };

    return (
        <div className={classes.table}>
            {!!medicines?.length && (
                <>
                    <div className={classes.thead}>
                        <div className={classes.col} style={{ width: "70px" }}>
                            Sl No
                        </div>
                        <div className={classes.col} style={{ width: "300px" }}>
                            Name
                        </div>
                        <div className={classes.col} style={{ width: "100px" }}>
                            Form
                        </div>
                        <div className={classes.col} style={{ width: "100px" }}>
                            Strenght
                        </div>
                        <div className={classes.col} style={{ width: "100px" }}>
                            Qty
                        </div>
                        <div className={classes.col} style={{ width: "100px" }}>
                            Unit
                        </div>
                        <div className={classes.col} style={{ width: "110px" }}>
                            Wkly Req
                        </div>
                        <div className={classes.col} style={{ width: "80px" }}>
                            Request Count
                        </div>
                        <div className={classes.col} style={{ width: "230px" }}>
                            Company
                        </div>
                        <div className={classes.col} style={{ width: "200px" }}>
                            Quantity
                        </div>
                        <div className={classes.col} style={{ width: "60px" }}>
                            Remove
                        </div>
                    </div>
                    {
                        // @ts-ignore
                        <FixedSizeList
                            itemCount={medicines.length}
                            height={620}
                            itemSize={90}
                            width={1580}
                        >
                            {RowList}
                        </FixedSizeList>
                    }
                </>
            )}
            <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) =>
                    // Fix bad setState() call inside `FormSpy` error using setTimeout
                    setTimeout(() => {
                        setFormValues(values);
                    }, 0)
                }
            />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "0.875rem",
        lineHeight: 1.43,
        margin: "25px",
    },
    thead: {
        display: "flex",
        padding: "10px 0",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    row: {
        display: "flex",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    col: {
        display: "flex",
        alignItems: "center",
        paddingRight: "10px",
    },
}));

export default memo(GenerateOrderSheet);
