import {
    Box,
    Checkbox,
    FormControlLabel,
    Link as MuiLink,
} from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import {
    AutocompleteInput,
    Datagrid,
    Edit,
    EditProps,
    FunctionField,
    Link,
    NumberInput,
    Pagination,
    ReferenceInput,
    ReferenceManyField,
    SimpleForm,
    TextField,
} from "react-admin";
import { useFormState } from "react-final-form";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import StatusField from "../../../components/manageDelivery/bags/StatusField";
import ZonesInput from "../../../components/manageDelivery/bags/ZonesInput";
import DurationField from "../../../components/manageOrder/orders/DurationField";
import { useDocumentTitle } from "../../../hooks";
import BagEditToolbar from "./BagEditToolbar";

type OrderGridProps = {
    isChecked: boolean;
    selectedIds?: number[];
    setFormValues: Function;
};

const OrderGrid: FC<OrderGridProps> = ({
    isChecked,
    selectedIds,
    setFormValues,
}) => {
    const fieldRef2 = useRef<HTMLInputElement>(null!);
    const { values } = useFormState();

    useEffect(() => {
        setFormValues(
            selectedIds?.length ? { ...values, move_ids: selectedIds } : values
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, selectedIds.length]);

    values.is_move_checked = isChecked;
    values.move_ids = selectedIds;

    return (
        <>
            <Datagrid hasBulkActions={isChecked} style={{ width: "100%" }}>
                <FunctionField
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    label="Id"
                    render={(record) => (
                        <Link to={`/v1/orders/${record.o_id}`}>
                            {record.o_id}
                        </Link>
                    )}
                />
                <TextField source="o_total" label="Total" />
                <DurationField />
                <StatusField />
                <TextField source="o_i_status" label="Internal Status" />
                <TextField source="o_is_status" label="Issue status" />
                <FunctionField
                    label="Is Cold"
                    render={(record) => {
                        if (!record.m_cold) return;
                        return <AcUnitIcon />;
                    }}
                />
                <TextField source="o_address" label="Address" />
                <FunctionField
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    label="Redx Link"
                    render={(record) => {
                        if (!record.redx_tracking_id) return;

                        return (
                            <MuiLink
                                href={`https://redx.com.bd/track-global-parcel/?trackingId=${record.redx_tracking_id}`}
                                target="_blank"
                                rel="noopener"
                            >
                                Redx Link
                            </MuiLink>
                        );
                    }}
                />
                <TextField source="o_i_note" label="Internal Notes" />
            </Datagrid>
            {isChecked && (
                <Box display="inline-flex" mt={4}>
                    <ZonesInput
                        source="move_zone"
                        variant="outlined"
                        initialValue={values.b_zone}
                        onSelect={() => fieldRef2.current.focus()}
                    />
                    <Box ml={2}>
                        <NumberInput
                            source="move_bag"
                            label="Bag No"
                            variant="outlined"
                            helperText={false}
                            inputRef={fieldRef2}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

const BagEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Bag Edit");

    const [isChecked, setIsChecked] = useState(false);
    const [formValues, setFormValues] = useState(null);

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                redirect="list"
                submitOnEnter={false}
                toolbar={
                    <BagEditToolbar
                        isChecked={isChecked}
                        formValues={formValues}
                    />
                }
            >
                <TextField source="b_zone" label="Zone" />
                <TextField source="b_no" label="Bag No" />
                <TextField source="o_count" label="Order Count" />
                <FormatedBooleanInput source="b_is_full" label="Is Full?" />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isChecked}
                            onChange={() =>
                                setIsChecked((prevState) => !prevState)
                            }
                            color="primary"
                        />
                    }
                    label="Move order to another bag"
                />
                <ReferenceManyField
                    label="Orders"
                    reference="v1/orders"
                    target="_bag_id"
                    pagination={
                        <Pagination rowsPerPageOptions={[5, 10, 25, 50]} />
                    }
                    perPage={50}
                    sort={{ field: "o_id", order: "ASC" }}
                >
                    <OrderGrid
                        isChecked={isChecked}
                        setFormValues={setFormValues}
                    />
                </ReferenceManyField>
                {!isChecked && (
                    <ReferenceInput
                        source="b_de_id"
                        label="Delivery Man"
                        variant="outlined"
                        reference="v1/users"
                        filter={{ _role: "delivery", _isAssigned: true }}
                        filterToQuery={(searchText) => ({
                            _search: searchText,
                        })}
                    >
                        <AutocompleteInput
                            matchSuggestion={() => true}
                            optionValue="u_id"
                            optionText={(value) =>
                                !!value?.isAssigned
                                    ? `${value?.u_name} (Assigned)`
                                    : value?.u_name
                            }
                            resettable
                        />
                    </ReferenceInput>
                )}
            </SimpleForm>
        </Edit>
    );
};

export default BagEdit;
