import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import MedicineCategoryInput from "../../../components/MedicineCategoryInput";
import MedicineTypeInput from "../../../components/MedicineTypeInput";

const PurchaseInvoiceItemFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <TextInput
            label="Invoice Id"
            source="_inv_id"
            variant="outlined"
            alwaysOn
            resettable
        />
        <SelectInput
            source="_status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "sync", name: "Sync" },
            ]}
            alwaysOn
        />
        <MedicineTypeInput source="_category" label="Type" variant="outlined" />
        <MedicineCategoryInput
            source="_cat_id"
            label="Category"
            variant="outlined"
        />
        <ReferenceInput
            source="_c_id"
            label="Company"
            variant="outlined"
            reference="v1/companies"
            filterToQuery={(searchText) => ({ _search: searchText })}
            sort={{ field: "c_name", order: "ASC" }}
        >
            <AutocompleteInput
                optionValue="c_id"
                optionText="c_name"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_warehouse_id"
            label="Warehouse"
            variant="outlined"
            reference="v1/warehouse"
            filter={{ _role: "warehouse" }}
            filterToQuery={(searchText) => ({ _search: searchText })}
        >
            <AutocompleteInput
                optionValue="w_id"
                optionText="w_title"
                resettable
            />
        </ReferenceInput>
        <SelectInput
            source="_expiry"
            variant="outlined"
            choices={[
                { id: "expired", name: "Expired" },
                { id: "n3", name: "Expire in 3 month" },
                { id: "n6", name: "Expire in 6 month" },
            ]}
        />
    </Filter>
);

export default PurchaseInvoiceItemFilter;
