import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { EmitTypes, SocketServer } from "../../../utils/enums";
import { logger } from "../../../utils/helpers";
import { axiosInstance } from "../../../utils/http";
import { socket } from "../../../utils/socketio";

dayjs.extend(localizedFormat);

const UserHistoryPage: FC = () => {
    let { userIdForSelectedHistory } = useParams<any>();

    const [state, setState] = useState<any>({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        if (process.env.REACT_APP_SOCKET_SERVER === SocketServer.ON) {
            axiosInstance
                .get(
                    `/users/history?userIdForSelectedHistory=${userIdForSelectedHistory}&sort=desc&sortBy=time&page=${
                        page + 1
                    }&limit=${rowsPerPage}`
                )
                .then(({ data }) => setState(data))
                .catch((err) => logger(err));

            socket.on(EmitTypes.USER_HISTORY, (data) => setState(data));
        }
    }, [userIdForSelectedHistory, page, rowsPerPage]);

    const handleChangePage = (_, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Box p={2}>
                {!!state?.data?.length ? (
                    <Typography>
                        History for {state?.data[0]?.userName}
                    </Typography>
                ) : (
                    <Typography>There have no history for this user</Typography>
                )}
                {!!state?.data?.length && (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!state?.data?.length &&
                                state.data.map((item) => (
                                    <TableRow key={item._id}>
                                        <TableCell>
                                            {dayjs(item.time).format("llll")}
                                        </TableCell>
                                        <TableCell>{item.action}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                )}
                {!!state?.total && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={state?.total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default UserHistoryPage;
