import AttachFileIcon from "@material-ui/icons/AttachFile";
import { FC, MouseEvent } from "react";
import {
    Datagrid,
    FileField,
    FileInput,
    FunctionField,
    List,
    ListProps,
    SimpleForm,
    TextField,
    useNotify,
    useRefresh,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import uploadDataProvider from "../../../dataProviderFileUpload";
import { useDocumentTitle, useExport } from "../../../hooks";
import { logger, transformFiles } from "../../../utils/helpers";
import LedgerFilter from "./LedgerFilter";
import LedgerPagination from "./LedgerPagination";

const LedgerList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Ledger List");
    const exporter = useExport(rest);

    const UploadFile = ({ id }: { id: number }) => {
        const refresh = useRefresh();
        const notify = useNotify();

        return (
            <SimpleForm toolbar={false}>
                <FileInput
                    source="filesAttached"
                    label=""
                    accept="image/*, application/pdf"
                    maxSize={10000000}
                    placeholder={<AttachFileIcon />}
                    multiple
                    options={{
                        onDrop: async (newFiles) => {
                            const modifiedFiles = transformFiles(
                                newFiles,
                                "attachedFiles"
                            );

                            try {
                                await uploadDataProvider.create(
                                    `v1/ledger/attachedFileUpload/${id}`,
                                    {
                                        data: { attachedFiles: modifiedFiles },
                                    }
                                );
                                notify("Successfully uploaded!", {
                                    type: "success",
                                });
                                refresh();
                            } catch (err) {
                                logger(err);
                                notify(
                                    "Something went wrong, Please try again!",
                                    {
                                        type: "error",
                                    }
                                );
                            }
                        },
                    }}
                >
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        );
    };

    return (
        <List
            {...rest}
            pagination={<LedgerPagination />}
            title="List of Ledger"
            filters={<LedgerFilter children={""} />}
            perPage={25}
            sort={{ field: "l_id", order: "DESC" }}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("ledgerDelete")}
                />
            }
            exporter={exporter}
        >
            <Datagrid rowClick="show">
                <TextField source="l_id" label="Id" />
                <TextField source="l_created" label="Date" />
                <TextField source="u_name" label="Added By" sortBy="l_uid" />
                <TextField source="l_reason" label="Reason" />
                <TextField source="l_type" label="Type" />
                <TextField source="l_method" label="Method" />
                <TextField source="l_amount" label="Amount" />
                <FileField
                    source="attachedFiles"
                    src="src"
                    title="title"
                    target="_blank"
                    label="Related Files"
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                    sortable={false}
                />
                <FunctionField
                    label="Upload"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    render={({ id }) => <UploadFile id={+id} />}
                />
            </Datagrid>
        </List>
    );
};

export default LedgerList;
