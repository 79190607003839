import { Box, Button, makeStyles } from "@material-ui/core";
import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import {
    FileField,
    FormDataConsumer,
    ImageField,
    SelectInput,
    TextInput,
    useNotify,
    useRefresh,
} from "react-admin";

import { useFormState } from "react-final-form";
import AroggaButton from "../../../../components/AroggaButton";
import LoaderOrButton from "../../../../components/LoaderOrButton";
import { useClipboard, useRequest } from "../../../../hooks";

type AdminTabProps = {
    record?: any;
    permissions: string[];
    [key: string]: any;
};

const AdminTab: FC<AdminTabProps> = ({ record, permissions, ...rest }) => {
    const { values } = useFormState();

    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();
    const clipboard = useClipboard();
    // const barcodeRef = useRef(null);

    // const [isPrintDialogOpen, setisPrintDialogOpen] = useState(false);
    const [isPaymentIssueShow, setIsPaymentIssueShow] = useState(false);

    const { isLoading, refetch: handlePaymentCheck } = useRequest(
        `/${rest.resource}/paymentCheck/${record?.o_id}/`,
        {
            method: "POST",
            body: {
                method: values.payment_issue_method,
                transaction_id: values.transaction_id,
            },
        },
        {
            onFinally: () => refresh(),
        }
    );

    const { refetch: handleUndoCashReceived } = useRequest(
        `/v1/adminActions/undoCashReceived/${record?.o_id}/`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
        }
    );

    const isRoleAdministrator = permissions?.includes("role:administrator");
    const isRoleWarehouse = permissions?.includes("role:warehouse");
    const isRoleOperator = permissions?.includes("role:operator");

    const handlePaymentIssue = () => {
        if (isRoleAdministrator) return handlePaymentCheck();

        if (
            values.payment_issue_method === "bKash" &&
            (values.transaction_id?.length < 8 ||
                values.transaction_id?.length > 10 ||
                values.transaction_id?.length === undefined)
        ) {
            notify("Transaction ID must be 8-10 digits!", {
                type: "error",
            });
        } else if (
            values.payment_issue_method === "nagad" &&
            (values.transaction_id?.length < 11 ||
                values.transaction_id?.length === undefined)
        ) {
            notify("Payment Ref ID must be greater than 10 digits!", {
                type: "error",
            });
        } else {
            handlePaymentCheck();
        }
    };

    // const barcodeItems = {
    // 	o_id: record?.o_id,
    // 	o_created: record?.o_created,
    // 	o_item_count: record?.o_item_count,
    // 	l_zone: record?.s_address?.zone,
    // 	l_area: record?.s_address?.area,
    // 	ul_mobile: record?.s_address?.mobile,
    // 	ul_location: record?.s_address?.location,
    // };

    // const handleBarcodePrint = () =>
    // 	html2canvas(barcodeRef.current).then((canvas) => {
    // 		const doc = new jsPDF({
    // 			orientation: "landscape",
    // 			unit: "in",
    // 			format: [4, 2],
    // 			compress: true,
    // 		});
    // 		const imgData = canvas.toDataURL("image/jpeg", 0.82);
    // 		doc.addImage(imgData, "JPEG", 0, 0, 0, 0, "", "FAST");
    // 		doc.autoPrint();
    // 		doc.output("dataurlnewwindow");
    // 	});

    const copyURLToClipboard = (paymentUrl) => {
        if (!paymentUrl) return;

        clipboard.copy(paymentUrl);
        notify("Payment URL copied to clipboard!", { type: "success" });
    };

    return (
        <>
            <Box position="relative">
                <TextInput
                    source="paymentUrl"
                    label="Payment URL"
                    variant="outlined"
                    disabled
                />
                <AroggaButton
                    label="Copy URL"
                    onClick={() => copyURLToClipboard(values.paymentUrl)}
                    style={{
                        position: "absolute",
                        left: 190,
                        marginTop: 7,
                        height: "42px",
                        borderColor: "Background",
                    }}
                />
            </Box>
            <FileField
                source="invoiceUrl"
                target="_blank"
                rel="noopener noreferrer"
                label="Invoice"
                title="Invoice"
            />
            {/* <Grid item md={2}>
					<Box display="flex" justifyContent="space-between" mt={1}>
						<FileField
							source="invoiceUrl"
							target="_blank"
							rel="noopener noreferrer"
							label="Invoice"
							title="Invoice"
						/>
						<div
							onClick={handleBarcodePrint}
							className={classes.link}>
							Barcode
						</div>
						{!isRoleOperator && (
							<>
								<div
									onClick={() => {
										localStorage.getItem("printer-url")
											? setisPrintDialogOpen(true)
											: redirect("/site-settings");
									}}
									className={classes.link}>
									Print
								</div>
								<PrintDialog
									open={isPrintDialogOpen}
									handleClose={() =>
										setisPrintDialogOpen(false)
									}
								/>
							</>
						)}
					</Box>
				</Grid> */}
            <p className={classes.label}>Payment Response</p>
            {values.paymentResponse && (
                <div
                    style={{
                        padding: 8,
                        border: "0.5px dashed #112950",
                        borderRadius: 4,
                        color: "#112950",
                        fontSize: 16,
                    }}
                >
                    {JSON.stringify(values.paymentResponse, undefined, 4)}
                </div>
            )}
            <p className={classes.label}>Packed</p>
            <FormDataConsumer>
                {({ formData }: any) => {
                    let packedDiffTime;
                    let checkedDiffTime;
                    let deliveredDiffTime;

                    if (formData?.packed_time !== "0000-00-00 00:00:00") {
                        packedDiffTime = DateTime.fromSQL(formData.packed_time)
                            .diff(
                                DateTime.fromSQL(
                                    formData.payment_eligible_time
                                ),
                                ["days", "hours", "minutes", "seconds"]
                            )
                            .toObject();
                    }

                    if (formData?.checked_time !== "0000-00-00 00:00:00") {
                        checkedDiffTime = DateTime.fromSQL(
                            formData.checked_time
                        )
                            .diff(DateTime.fromSQL(formData.packed_time), [
                                "days",
                                "hours",
                                "minutes",
                                "seconds",
                            ])
                            .toObject();
                    }

                    if (formData?.o_delivered !== "0000-00-00 00:00:00") {
                        deliveredDiffTime = DateTime.fromSQL(
                            formData.o_delivered
                        )
                            .diff(DateTime.fromSQL(formData.checked_time), [
                                "days",
                                "hours",
                                "minutes",
                                "seconds",
                            ])
                            .toObject();
                    }

                    return (
                        <div
                            className={
                                formData?.packed_time !==
                                    "0000-00-00 00:00:00" ||
                                formData?.checked_time !==
                                    "0000-00-00 00:00:00" ||
                                formData?.o_delivered !== "0000-00-00 00:00:00"
                                    ? classes.borderDashed
                                    : ""
                            }
                        >
                            {formData?.packed_time !==
                                "0000-00-00 00:00:00" && (
                                <div>
                                    {formData.packed_time} - packed by{" "}
                                    {formData?.packed_by_name} (
                                    {packedDiffTime.days}d-
                                    {packedDiffTime.hours}h-
                                    {packedDiffTime.minutes}m )
                                </div>
                            )}
                            {formData?.checked_time !==
                                "0000-00-00 00:00:00" && (
                                <div>
                                    {formData.checked_time} - checked by{" "}
                                    {formData?.checked_by_name} (
                                    {checkedDiffTime.days}d-
                                    {checkedDiffTime.hours}h-
                                    {checkedDiffTime.minutes}m)
                                </div>
                            )}
                            {formData?.o_delivered !==
                                "0000-00-00 00:00:00" && (
                                <div>
                                    {formData.o_delivered} - delivered by{" "}
                                    {formData?.delivered_by_name} (
                                    {deliveredDiffTime.days}d-
                                    {deliveredDiffTime.hours}h-
                                    {deliveredDiffTime.minutes}m)
                                </div>
                            )}
                        </div>
                    );
                }}
            </FormDataConsumer>
            {(isRoleAdministrator || isRoleOperator) &&
                (record?.o_payment_method === "online" ||
                    record?.o_payment_method === "cod") && (
                    <>
                        <div
                            onClick={() => setIsPaymentIssueShow(true)}
                            style={{
                                color: "#008069",
                                textDecorationLine: "underline",
                                cursor: "pointer",
                                marginTop: 18,
                            }}
                        >
                            Payment Issue?
                        </div>
                        {isPaymentIssueShow && (
                            <div
                                style={{
                                    padding: "20px 8px 0px 8px",
                                    marginTop: 18,
                                    border: "0.5px dashed #112950",
                                    borderRadius: 4,
                                    width: "max-content",
                                }}
                            >
                                <SelectInput
                                    source="payment_issue_method"
                                    label="Payment Method"
                                    variant="outlined"
                                    choices={[
                                        {
                                            id: "nagad",
                                            name: "Nagad",
                                        },
                                        {
                                            id: "bKash",
                                            name: "bKash",
                                        },
                                        {
                                            id: "sslCommerz",
                                            name: "SSL Commerz",
                                        },
                                    ]}
                                    style={{ width: 256 }}
                                />
                                {values.payment_issue_method !==
                                    "sslCommerz" && (
                                    <TextInput
                                        source="transaction_id"
                                        label={
                                            values.payment_issue_method ===
                                            "nagad"
                                                ? "Payment Ref ID"
                                                : "Transaction ID"
                                        }
                                        variant="outlined"
                                        style={{
                                            marginLeft: "5px",
                                            width: 256,
                                        }}
                                    />
                                )}
                                <LoaderOrButton
                                    label="Check"
                                    display="inline"
                                    btnStyle={classes.checkBtn}
                                    isLoadingLabel={isLoading}
                                    onClick={handlePaymentIssue}
                                    disabled={!values.payment_issue_method}
                                />
                            </div>
                        )}
                    </>
                )}
            {(isRoleAdministrator || isRoleWarehouse) &&
                values.o_status === "delivering" &&
                values.o_payment_method === "cash" && (
                    <Button
                        variant="contained"
                        onClick={handleUndoCashReceived}
                        style={{ marginTop: 15 }}
                        disableElevation
                    >
                        Undo Cash Received
                    </Button>
                )}
            <Box mt={1} />
            <MovableImageInput
                source="attachedFiles"
                label="Prescription Pictures"
                accept="image/*"
                maxSize={5000000}
                multiple
            >
                <ImageField source="src" title="title" />
            </MovableImageInput>
            {/* {!isRoleOperator && (
				<div
					ref={barcodeRef}
					style={{
						width: "362px",
						height: "192px",
						padding: "10px",
					}}>
					<BarcodeCard item={barcodeItems} page="orders" />
				</div>
			)} */}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    link: {
        color: "#008069",
        textDecorationLine: "underline",
        cursor: "pointer",
    },
    label: {
        color: "#7C8AA0",
        fontSize: 12,
    },
    labelValue: {
        color: "#112950",
        fontSize: 16,
    },
    borderDashed: {
        padding: 8,
        border: "0.5px dashed #112950",
        borderRadius: 4,
    },
    checkBtn: {
        marginTop: theme.spacing(1),
        padding: "6px 12px",
        height: 40,
        marginLeft: 5,
    },
}));

export default AdminTab;
