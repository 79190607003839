import { Paper } from "@material-ui/core";
import { SimpleForm, Title } from "react-admin";

import SwitchTo from "../../components/switchTo/SwitchTo";
import { useDocumentTitle } from "../../hooks";

const SwitchToPage = () => {
    useDocumentTitle("Arogga | Switch User");

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Switch User" />
            <SimpleForm toolbar={null}>
                <SwitchTo />
            </SimpleForm>
        </Paper>
    );
};

export default SwitchToPage;
