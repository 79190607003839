import { Box } from "@material-ui/core";
import { DeleteButton, SaveButton, Toolbar } from "react-admin";

const SaveDeleteToolbar = ({
    isSave = false,
    isDelete = false,
    ...rest
}: any) => (
    <Toolbar {...rest}>
        {isSave && <SaveButton redirect="list" submitOnEnter={false} />}
        {isDelete && (
            <Box display="flex" justifyContent="flex-end" width="100%">
                <DeleteButton {...rest} />
            </Box>
        )}
    </Toolbar>
);

export default SaveDeleteToolbar;
