import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import { FC } from "react";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type PrevMultiplierChangeDialogProps = {
    open: boolean;
    handleClose: () => void;
    formValues?: any;
};

const PrevMultiplierChangeDialog: FC<PrevMultiplierChangeDialogProps> = ({
    open,
    handleClose,
    formValues,
}) => {
    const { values } = useFormState();

    const { m_multiplier, m_unit, prev_multiplier, prev_unit } = values;

    const { isLoading, refetch } = useRequest(
        `/v1/adminActions/prevMultiplierChange/${formValues.m_id}`,
        {
            method: "POST",
            body: { prev_multiplier, prev_unit },
        },
        {
            isRefresh: true,
            onSuccess: () => handleClose(),
        }
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Are you sure you want to change?</DialogTitle>
            <DialogContent>
                <Typography>Multiplier</Typography>
                <Box mt={1} />
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Current</TableCell>
                            <TableCell>Previous</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{m_multiplier || "-"}</TableCell>
                            <TableCell>{prev_multiplier || "-"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={1}>
                    <Typography>Unit</Typography>
                </Box>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Current</TableCell>
                            <TableCell>Previous</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{m_unit || "-"}</TableCell>
                            <TableCell>{prev_unit || "-"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                confirmLabel="SEND"
                disabled={!prev_multiplier && !prev_unit}
                onDialogClose={handleClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default PrevMultiplierChangeDialog;
