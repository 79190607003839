import { FC, useState } from "react";
import {
    Button,
    Confirm,
    FileField,
    FunctionField,
    Record,
    ReferenceField,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle, useRequest } from "../../../hooks";

const EmployeeInfoShow: FC<ShowProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Employee Info Show");

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [employeeId, setEmployeeId] = useState(null);

    const { isLoading, refetch } = useRequest(
        `/v1/employee/employeeInfoApprove/${employeeId}`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    return (
        <Show {...rest}>
            <ColumnShowLayout>
                <TextField source="ei_id" label="Id" />
                <ReferenceField
                    source="ei_e_id"
                    label="Employee"
                    reference="v1/employee"
                    link="show"
                    sortBy="ei_e_id"
                >
                    <TextField source="e_name" />
                </ReferenceField>
                <TextField
                    source="ei_residential_address"
                    label="Residential Address"
                />
                <TextField source="ei_date_of_birth" label="Birth Date" />
                <TextField source="ei_blood_group" label="Blood Group" />
                <TextField
                    source="ei_birth_certificate"
                    label="Birth Certificate Number"
                />
                <FileField
                    source="attachedFiles-ei_birth_certificate_photo"
                    src="src"
                    title="Birth Certificate"
                    target="_blank"
                    label="Birth Certificate Files"
                />
                <TextField source="ei_nid" label="NID" />
                <FileField
                    source="attachedFiles-ei_nid_photo"
                    src="src"
                    title="NID Files"
                    target="_blank"
                    label="NID Files"
                />
                <TextField source="ei_tin" label="TIN" />
                <FileField
                    source="attachedFiles-ei_tin_photo"
                    src="src"
                    title="Tin Files"
                    target="_blank"
                    label="Tin Files"
                />
                <TextField source="ei_license" label="Driving License" />
                <FileField
                    source="attachedFiles-ei_license_photo"
                    src="src"
                    title="License Files"
                    target="_blank"
                    label="License Files"
                />
                <TextField source="ei_passport" label="Passport" />
                <FileField
                    source="attachedFiles-ei_passport_photo"
                    src="src"
                    title="Passport Files"
                    target="_blank"
                    label="Passport Files"
                />
                <ReferenceField
                    source="ei_bank_id"
                    label="Bank"
                    reference="v1/bank"
                    link="show"
                    sortBy="ei_e_id"
                >
                    <TextField source="b_name" />
                </ReferenceField>
                <TextField
                    source="ei_bank_account_title"
                    label="Bank Account Title"
                />
                <TextField
                    source="ei_bank_account_number"
                    label="Bank Account Number"
                />
                <TextField source="ei_card_number" label="Card Number" />
                <TextField source="ei_client_id" label="Client Id" />
                <FileField
                    source="attachedFiles-ei_cheque_photo"
                    src="src"
                    title="Cheque Files"
                    target="_blank"
                    label="Cheque Files"
                />
                {permissions?.includes("employeeInfoApproved") && (
                    <>
                        <FunctionField
                            addLabel={false}
                            render={({ ei_e_id, ei_approved }: Record) => {
                                if (ei_approved) return;

                                return (
                                    <Button
                                        label="Approve"
                                        variant="outlined"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#008069",
                                        }}
                                        onClick={() => {
                                            setEmployeeId(ei_e_id);
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                );
                            }}
                        />
                        <Confirm
                            isOpen={isDialogOpen}
                            loading={isLoading}
                            title="Are you sure you want to approve this employee info?"
                            content={false}
                            onConfirm={refetch}
                            onClose={() => setIsDialogOpen(false)}
                        />
                    </>
                )}
            </ColumnShowLayout>
        </Show>
    );
};

export default EmployeeInfoShow;
