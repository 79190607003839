import { FC } from "react";
import {
    FunctionField,
    Record,
    ReferenceField,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const WarehouseShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Warehouse Show");

    return (
        <Show {...props}>
            <ColumnShowLayout md={6}>
                <TextField source="w_id" label="Id" />
                <TextField source="w_title" label="Title" />
                <TextField source="w_lat" label="Latitude" />
                <TextField source="w_lon" label="Longitude" />
                <ReferenceField
                    source="w_l_id"
                    label="Location"
                    reference="v1/locations"
                    sortBy="w_l_id"
                    link={false}
                >
                    <FunctionField
                        render={(record: Record) =>
                            !!record
                                ? `${record.l_division} -> ${record.l_district} -> ${record.l_area}`
                                : ""
                        }
                    />
                </ReferenceField>
                <TextField source="w_address" label="Address" />
            </ColumnShowLayout>
        </Show>
    );
};

export default WarehouseShow;
