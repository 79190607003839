import { Box, Grid, Link, makeStyles } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
    useNotify,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import AddressTypeInput from "../../../../components/AddressTypeInput";
import AroggaButton from "../../../../components/AroggaButton";
import SendSmsDialog from "../../../../components/SendSmsDialog";
import ProfilePhotoPlaceholderIcon from "../../../../components/icons/ProfilePhotoPlaceholder";
import AreaInput from "../../../../components/manageOrder/orders/AreaInput";
import DistrictInput from "../../../../components/manageOrder/orders/DistrictInput";
import ShippingDialog from "../../../../components/manageOrder/orders/ShippingDialog";
import UserLocationAutocompleteInput from "../../../../components/manageOrder/orders/UserLocationAutocompleteInput";
import { useClipboard, useRequest } from "../../../../hooks";
import { Status } from "../../../../utils/enums";
import { isEmpty, isJSONParsable, logger } from "../../../../utils/helpers";
import { httpClient } from "../../../../utils/http";

type UserTabProps = {
    permissions: string[];
    setUserCash: (userCash: any) => void;
    [key: string]: any;
};

const UserTab: FC<UserTabProps> = ({ permissions, setUserCash, ...rest }) => {
    const classes = useStyles();
    const notify = useNotify();
    const form = useForm();
    const clipboard = useClipboard();
    const { values } = useFormState();

    const [locations, setLocations] = useState(null);
    const [isSmsDialogOpen, setIsSmsDialogOpen] = useState(false);
    const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
    const [isUserNoteUpdateButtonShow, setIsUserNoteUpdateButtonShow] =
        useState(false);
    const [isLocationEditBtnClick, setIsLocationEditBtnClick] = useState(false);
    const [isLocationAddBtnClick, setIsLocationAddBtnClick] = useState(false);

    const {
        data: userData,
        isSuccess,
        refetch: refetchUserData,
    } = useRequest(`/v1/users/${values.u_id}`, {}, { isPreFetching: true });

    useEffect(() => {
        !isEmpty(userData) && setUserCash(userData?.u_cash);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const { refetch: updateUserNote } = useRequest(
        `/v1/tinyUpdate/user/${values.u_id}`,
        {
            method: "POST",
            body: {
                u_note: values.userNote,
            },
        },
        {
            successNotify: "Successfully updated user note",
            onSuccess: () => {
                refetchUserData();
                setIsUserNoteUpdateButtonShow(false);
            },
        }
    );

    useEffect(() => {
        const locationsFromStroage = sessionStorage.getItem("locations");

        if (locationsFromStroage) {
            setLocations(
                isJSONParsable(locationsFromStroage)
                    ? JSON.parse(locationsFromStroage)
                    : {}
            );
        } else {
            httpClient("/v1/allLocations/", {}, true)
                .then(({ json }: any) => {
                    if (json.status === Status.SUCCESS) {
                        setLocations(json.data);
                        sessionStorage.setItem(
                            "locations",
                            JSON.stringify(json.data)
                        );
                    }
                })
                .catch((err) => logger(err));
        }
    }, []);

    useEffect(() => {
        form.change("userNote", userData?.u_note);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.u_note]);

    useEffect(() => {
        !isUserNoteUpdateButtonShow &&
            userData?.u_note !== values.userNote &&
            setIsUserNoteUpdateButtonShow(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.userNote]);

    const toChoices = (items: string[]) =>
        items.map((item: string) => ({ id: item, name: item }));

    const mobileNoCopyToClipboard = (mobileNo) => {
        if (!mobileNo) return;

        clipboard.copy(mobileNo);
        notify("Mobile no copied to clipboard!", { type: "success" });
    };

    const isAddressConfirmButtonShow =
        !rest.record?.address_checked &&
        rest.record?.o_status === "processing" &&
        rest.record?.o_i_status === "processing";

    values.isAddressConfirmButtonShow = isAddressConfirmButtonShow;

    return (
        <>
            <Grid container className={classes.profileContainer}>
                <Grid item sm={12} md={3}>
                    {userData?.u_pic_url ? (
                        <img
                            src={userData.u_pic_url}
                            alt="Profile_Photo"
                            className={classes.profilePhoto}
                        />
                    ) : (
                        <ProfilePhotoPlaceholderIcon />
                    )}
                </Grid>
                <Grid item sm={12} md={9}>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>Name:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                {userData?.u_name}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>User ID:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                {values.u_id}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>Mobile No:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                <span
                                    onClick={() =>
                                        mobileNoCopyToClipboard(
                                            userData?.u_mobile
                                        )
                                    }
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    {userData?.u_mobile}
                                </span>{" "}
                                <AroggaButton
                                    label="Send SMS"
                                    type="success"
                                    onClick={() => setIsSmsDialogOpen(true)}
                                />
                            </p>
                            <SendSmsDialog
                                pageName="orders"
                                open={isSmsDialogOpen}
                                handleClose={() => setIsSmsDialogOpen(false)}
                                {...rest}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>Order Count:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                {userData?.u_o_count}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>
                                Deliver Order Count:
                            </p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                {userData?.u_d_count}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>
                                Note about user:
                            </p>
                        </Grid>
                        <Grid item style={{ position: "relative" }}>
                            <TextInput
                                source="userNote"
                                label=""
                                variant="outlined"
                                style={{ width: 217, position: "relative" }}
                                minRows={2}
                                multiline
                            />
                            {isUserNoteUpdateButtonShow &&
                                userData?.u_note !== values.userNote && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: -15,
                                        }}
                                    >
                                        <AroggaButton
                                            label="Update"
                                            type="success"
                                            onClick={updateUserNote}
                                        />
                                    </div>
                                )}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>
                                Additional Info (For this order):
                            </p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                <TextInput
                                    source="o_additional_info"
                                    label=""
                                    variant="outlined"
                                    style={{ width: 217 }}
                                    minRows={2}
                                    multiline
                                />
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                {rest.record?.address_checked && !isLocationEditBtnClick && (
                    <Grid item sm={12} md={6}>
                        <Box display="flex" alignItems="center">
                            <ReferenceInput
                                source="o_ul_id"
                                label="Location"
                                variant="outlined"
                                reference="v1/userLocations"
                                filter={{
                                    _orderBy: "ul_default",
                                    u_id: values.u_id,
                                }}
                                filterToQuery={(searchText) => ({
                                    _search: searchText,
                                })}
                                fullWidth
                            >
                                <UserLocationAutocompleteInput
                                    matchSuggestion={() => true}
                                    optionValue="ul_id"
                                    helperText={false}
                                    resettable
                                />
                            </ReferenceInput>
                            {rest.record?.address_checked &&
                                rest.record?.o_ul_id === values.o_ul_id && (
                                    <AroggaButton
                                        label="Edit"
                                        type="success"
                                        onClick={() =>
                                            setIsLocationEditBtnClick(true)
                                        }
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    />
                                )}
                            {!isLocationAddBtnClick && (
                                <AroggaButton
                                    label="Add"
                                    type="success"
                                    onClick={() =>
                                        setIsLocationAddBtnClick(true)
                                    }
                                    style={{
                                        marginLeft: 10,
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                )}
            </Grid>
            {(!rest.record?.address_checked ||
                isLocationAddBtnClick ||
                isLocationEditBtnClick) && (
                <Grid container spacing={2} className={classes.locationArea}>
                    <Grid item sm={6} md={4}>
                        <TextInput
                            source="s_address.name"
                            label="Shipping Name"
                            variant="outlined"
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <TextInput
                            source="s_address.mobile"
                            label="Shipping Mobile"
                            variant="outlined"
                            onFocus={(e) => {
                                const newValue: string =
                                    e.target.value.startsWith("+88")
                                        ? e.target.value.replace(
                                              "+88",
                                              "858520939"
                                          )
                                        : `858520939${e.target.value}`;

                                clipboard.copy(newValue);
                                clipboard.isCopied &&
                                    notify(
                                        "Shipping Mobile copied to clipboard!",
                                        {
                                            type: "success",
                                        }
                                    );
                            }}
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <AddressTypeInput
                            source="s_address.addressType"
                            variant="outlined"
                            validate={[required()]}
                            allowEmpty
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <SelectInput
                            source="s_address.division"
                            label="Shipping Division"
                            variant="outlined"
                            choices={
                                !!locations
                                    ? toChoices(Object.keys(locations))
                                    : []
                            }
                            validate={[required()]}
                            allowEmpty
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <DistrictInput
                            source="s_address.district"
                            label="Shipping City"
                            variant="outlined"
                            validate={[required()]}
                            locations={locations}
                            setLocations={setLocations}
                            allowEmpty
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <AreaInput
                            source="s_address.area"
                            label="Shipping Area"
                            variant="outlined"
                            validate={[required()]}
                            locations={locations}
                            setLocations={setLocations}
                            allowEmpty
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <TextInput
                            source="s_address.homeAddress"
                            label="Shipping Home Address"
                            variant="outlined"
                            validate={[required()]}
                            minRows={2}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}></Grid>
                    {isAddressConfirmButtonShow && (
                        <>
                            <Grid
                                item
                                sm={6}
                                md={4}
                                style={{ position: "relative" }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 37,
                                        right: 0,
                                    }}
                                >
                                    <AroggaButton
                                        label="Confirm Address"
                                        type="success"
                                        onClick={() =>
                                            setIsShippingDialogOpen(true)
                                        }
                                    />
                                </div>
                            </Grid>
                            <ShippingDialog
                                formValues={values}
                                open={isShippingDialogOpen}
                                handleShippingDialogClose={() =>
                                    setIsShippingDialogOpen(false)
                                }
                                setIsLocationEditBtnClick={
                                    setIsLocationEditBtnClick
                                }
                                {...rest}
                            />
                        </>
                    )}
                </Grid>
            )}
            {!!values?.redx_tracking_id && (
                <Box mt={3}>
                    <Link
                        href={`https://redx.com.bd/track-global-parcel/?trackingId=${values.redx_tracking_id}`}
                        target="_blank"
                        rel="noopener"
                    >
                        REDX Link
                    </Link>
                </Box>
            )}
        </>
    );
};

const useStyles = makeStyles(() => ({
    profileContainer: {
        border: "1px solid #EAEBEC",
        borderRadius: 6,
        padding: 25,
        marginTop: 10,
    },
    profilePhoto: {
        width: 241,
        height: 262,
        borderRadius: 10,
    },
    profileField: {
        color: "#7C8AA0",
    },
    profileFieldResult: {
        color: "#112950",
    },
    location: {
        padding: 5,
        width: 205,
        wordWrap: "break-word",
        border: "0.5px solid #DCE0E4",
        background: "#F4F4F4",
        color: "#6C757D",
    },
    locationArea: {
        width: "100%",
        margin: "20px 0px 20px 0px",
        padding: "12px 12px 0px 12px",
        background: "#FFFFFF",
        border: "1px dashed #3ECBA5",
        borderRadius: 6,
    },
}));

export default UserTab;
