import { MouseEvent } from "react";
import {
    FunctionField,
    Record,
    ReferenceField,
    useRecordContext,
} from "react-admin";

import Tag from "../../Tag";

const UserNameField = (props) => {
    const record = useRecordContext(props);

    if (record?.u_id === 0) return <span>Offline</span>;

    const isB2BUser = record?.o_is_pharmacy_order === 1;

    return (
        <ReferenceField
            {...props}
            source="u_id"
            reference="v1/users"
            link="show"
        >
            <FunctionField
                onClick={(e: MouseEvent) => e.stopPropagation()}
                render={(record: Record) => (
                    <span>
                        {`${record?.u_name} (${record?.u_o_count})`}{" "}
                        {isB2BUser && <Tag name="B2B" />}
                    </span>
                )}
            />
        </ReferenceField>
    );
};

export default UserNameField;

UserNameField.defaultProps = { label: "User" };
