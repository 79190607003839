import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
    usePermissions,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import MedicineTypeInput from "../../../components/MedicineTypeInput";

const InventoryFilter: FC<FilterProps> = (props) => {
    const { permissions } = usePermissions();

    const isRoleAuditor = permissions?.includes("role:auditor");

    return (
        <Filter {...props}>
            <TextInput
                source="_search"
                label="Search"
                variant="outlined"
                autoComplete="off"
                alwaysOn
                resettable
            />
            <TextInput
                source="_rack_search"
                label="Search by rack"
                variant="outlined"
                autoComplete="off"
                alwaysOn
                resettable
            />
            <ReferenceInput
                source="_c_id"
                label="Company"
                variant="outlined"
                reference="v1/companies"
                filterToQuery={(searchText) => ({ _search: searchText })}
                sort={{ field: "c_name", order: "ASC" }}
                alwaysOn
            >
                <AutocompleteInput
                    optionValue="c_id"
                    optionText="c_name"
                    resettable
                />
            </ReferenceInput>
            <SelectInput
                source="_auditStatus"
                label="Audited Status"
                variant="outlined"
                choices={[
                    { id: 1, name: "Audited" },
                    { id: 0, name: "Not Audited" },
                ]}
                alwaysOn={isRoleAuditor}
            />
            {!isRoleAuditor && (
                <FormatedBooleanInput
                    source="_not_assigned"
                    label="Not Assigned"
                    alwaysOn
                />
            )}
            {!isRoleAuditor && (
                <FormatedBooleanInput
                    source="_assigned"
                    label="Assigned"
                    alwaysOn
                />
            )}
            <FormatedBooleanInput
                source="_correctionPending"
                label="Correction Pending"
                alwaysOn
            />
            <SelectInput
                source="_is_rack_available"
                label="Rack Available?"
                variant="outlined"
                choices={[
                    { id: "", name: "" },
                    { id: "yes", name: "Yes" },
                    { id: "no", name: "No" },
                ]}
            />
            <MedicineTypeInput
                source="_category"
                label="Type"
                variant="outlined"
            />
            <ReferenceInput
                source="_warehouse_id"
                label="Warehouse"
                variant="outlined"
                reference="v1/warehouse"
                filter={{ _role: "warehouse" }}
                filterToQuery={(searchText) => ({ _search: searchText })}
            >
                <AutocompleteInput
                    optionValue="w_id"
                    optionText="w_title"
                    resettable
                />
            </ReferenceInput>
            <SelectInput
                source="_qty"
                label="Quantity"
                variant="outlined"
                choices={[
                    { id: "<0", name: "Less than zero" },
                    { id: "zero", name: "Zero" },
                    { id: ">0", name: "More than zero" },
                    { id: "1-10", name: "One to Ten" },
                    { id: "11-100", name: "Eleven to Hundred" },
                    { id: ">100", name: "More than hundred" },
                ]}
            />
            <SelectInput
                source="_stock"
                label="Stock Days"
                variant="outlined"
                choices={[
                    { id: "<0", name: "Less than zero" },
                    { id: ">0", name: "More than zero" },
                    { id: "<7", name: "Less than Seven" },
                    { id: "7-10", name: "Seven to Ten" },
                    { id: "10-15", name: "Ten to Fifteen" },
                    { id: "15-30", name: "Fifteen to Thirty" },
                ]}
            />
            <ReferenceInput
                source="_u_id"
                label="Purchaser"
                variant="outlined"
                reference="v1/users"
                filter={{ _role: "purchaser" }}
                filterToQuery={(searchText) => ({
                    _search: searchText,
                })}
                resettable
            >
                <AutocompleteInput
                    optionValue="u_id"
                    optionText="u_name"
                    resettable
                />
            </ReferenceInput>
            <TextInput
                source="_m_r_count"
                label="Request Count"
                variant="outlined"
                resettable
            />
        </Filter>
    );
};

export default InventoryFilter;
