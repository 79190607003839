import { FC } from "react";
import { Record } from "react-admin";

type StatusFieldProps = {
    record?: Record;
    label?: string;
};

const StatusField: FC<StatusFieldProps> = ({ record }) => {
    const { o_status } = record;

    if (o_status === "processing")
        return <span style={{ color: "#EF1962" }}>{o_status}</span>;
    if (o_status === "confirmed")
        return <span style={{ color: "blue" }}>{o_status}</span>;
    if (o_status === "delivered")
        return <span style={{ color: "green" }}>{o_status}</span>;

    return o_status;
};

export default StatusField;

StatusField.defaultProps = {
    label: "Status",
};
