import { FC } from "react";
import { Datagrid, List, ListProps, NumberField, TextField } from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import { useDocumentTitle, useExport } from "../../../hooks";
import UserFilter from "./UserFilter";

const UserList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | User List");
    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of User"
            filters={<UserFilter children={""} />}
            perPage={25}
            sort={{ field: "u_created", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={
                <DeleteBulkActionButton
                    hasPermission={permissions?.includes("userDelete")}
                />
            }
        >
            <Datagrid rowClick="show">
                <TextField source="u_id" label="Id" />
                <TextField source="u_name" label="Name" />
                <TextField source="u_mobile" label="Mobile" />
                <TextField source="u_o_count" label="Order Count" />
                <TextField source="u_role" label="Role" />
                <TextField source="u_status" label="Status" />
                <NumberField source="u_cash" label="Cash" />
                <TextField source="u_created" label="User Created" />
                <TextField source="u_otp" label="OTP" />
                <TextField source="u_otp_time" label="OTP Created" />
                <TextField source="u_referrer" label="Referrer" />
            </Datagrid>
        </List>
    );
};

export default UserList;
