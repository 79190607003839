import { FC } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    FileField,
    FileInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    minLength,
    required,
} from "react-admin";

import LocationInput from "../../../components/LocationInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { useDocumentTitle } from "../../../hooks";

const PharmacyCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Pharmacy Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    source="p_name"
                    label="Pharmacy Name"
                    variant="outlined"
                    validate={[
                        required(),
                        minLength(
                            4,
                            "Pharmacies name must be at least 4 characters long"
                        ),
                    ]}
                />
                <ReferenceInput
                    source="p_user_id"
                    label="User"
                    variant="outlined"
                    reference="v1/users"
                    validate={[required()]}
                    filterToQuery={(searchText) => ({
                        _search: searchText,
                    })}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="u_id"
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={(record: {
                            u_name: string;
                            u_mobile: string;
                        }) =>
                            !!record
                                ? `${record.u_name} (${record.u_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
                <TextInput
                    source="p_drug_license_no"
                    label="Drug License No"
                    variant="outlined"
                    validate={[required()]}
                />
                <TextInput
                    source="p_trade_license_no"
                    label="Trade License No"
                    variant="outlined"
                    validate={[required()]}
                />
                <LocationInput
                    source="p_location_id"
                    label="Location"
                    variant="outlined"
                />
                <TextInput
                    source="p_address"
                    label="Address"
                    variant="outlined"
                    minRows={2}
                    multiline
                />
                <SelectInput
                    source="p_status"
                    label="Status"
                    variant="outlined"
                    choices={[
                        { id: "pending", name: "Pending" },
                        { id: "approved", name: "Approved" },
                        { id: "rejected", name: "Rejected" },
                        { id: "blocked", name: "Blocked" },
                    ]}
                />
                <FileInput
                    source="attachedFiles-p_drug_license_file"
                    label="Drug License Files"
                    accept="image/*, application/pdf"
                    maxSize={10000000}
                    options={{ maxSize: 5 }}
                    validate={[required()]}
                    multiple
                >
                    <FileField source="src" title="title" />
                </FileInput>
                <FileInput
                    source="attachedFiles-p_trade_license_file"
                    label="Trade License Files"
                    accept="image/*, application/pdf"
                    maxSize={10000000}
                    options={{ maxSize: 5 }}
                    multiple
                >
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};

export default PharmacyCreate;
