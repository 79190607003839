import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

const PurchaseRequestFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            autoComplete="off"
            resettable
            alwaysOn
        />
        <ReferenceInput
            source="_c_id"
            label="Company"
            variant="outlined"
            reference="v1/companies"
            filterToQuery={(searchText) => ({ _search: searchText })}
            sort={{ field: "c_name", order: "ASC" }}
            alwaysOn
        >
            <AutocompleteInput
                optionValue="c_id"
                optionText="c_name"
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_u_id"
            label="Purchaser"
            variant="outlined"
            reference="v1/users"
            filter={{ _role: "purchaser" }}
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
            resettable
            alwaysOn
        >
            <AutocompleteInput
                optionValue="u_id"
                optionText="u_name"
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default PurchaseRequestFilter;
