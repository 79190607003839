import { makeStyles } from "@material-ui/core";

const AuditedPercentage = ({ inShelf, auditedQty }) => {
    const classes = useStyles();

    if (!inShelf || inShelf === auditedQty) return null;
    if (auditedQty === "" || auditedQty === null || auditedQty === undefined)
        return null;

    const auditedPercentage = Math.round((auditedQty / inShelf) * 100);

    if (auditedPercentage > 100) {
        return (
            <span className={classes.colorRed}>{`(More: ${
                100 - auditedPercentage
            }%)`}</span>
        );
    } else {
        return (
            <span className={classes.colorRed}>{`(Less: ${
                100 - auditedPercentage
            }%)`}</span>
        );
    }
};

const useStyles = makeStyles({
    colorRed: {
        color: "#EF1962",
    },
});

export default AuditedPercentage;
