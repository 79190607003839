import AcUnitIcon from "@material-ui/icons/AcUnit";
import { Datagrid, FunctionField, Record, TextField } from "react-admin";

const OrdersDatagrid = () => (
    <Datagrid>
        <TextField source="o_id" label="Order ID" />
        <TextField source="o_created" label="Order Created" />
        <TextField source="o_total" label="Total" />
        <FunctionField
            label="Status"
            render={({ o_status }: Record) => {
                let color;

                if (o_status === "processing") color = "#EF1962";
                else if (o_status === "confirmed") color = "blue";
                else if (o_status === "delivered") color = "green";
                else color = "";

                return (
                    <span
                        style={{
                            color,
                        }}
                    >
                        {o_status}
                    </span>
                );
            }}
        />
        <TextField source="o_i_status" label="Internal Status" />
        <TextField source="o_is_status" label="Issue status" />
        <FunctionField
            label="Is Cold"
            sortBy="m_cold"
            render={(record: Record) => {
                if (!record.m_cold) return;
                return <AcUnitIcon />;
            }}
        />
        <TextField source="o_de_option" label="Delivery Option" />
        <TextField source="o_address" label="Address" />
        <TextField source="o_i_note" label="Internal Notes" />
    </Datagrid>
);

export default OrdersDatagrid;
