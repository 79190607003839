import { useForm } from "react-final-form";

import { required } from "../../../utils/helpers";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";

const CancelReasonInput = () => {
    const form = useForm();

    return (
        <TaxonomiesByVocabularyInput
            fetchKey="order_cancel_reasons"
            source="cancel_reason"
            label="Cancel Reason"
            onChange={(e) => {
                if (e.target.value === "Other") {
                    form.change("note", "");
                } else {
                    form.change("note", e.target.value);
                }
            }}
            validate={[required()]}
            title
            fullWidth
        />
    );
};

export default CancelReasonInput;
