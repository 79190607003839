import { SelectInput } from "react-admin";

const CartStatusInput = (props) => (
    <SelectInput
        choices={[
            { id: "current", name: "Current" },
            { id: "ordered", name: "Ordered" },
        ]}
        {...props}
    />
);

export default CartStatusInput;
