import { FC } from "react";
import { DateInput, Filter, FilterProps, TextInput } from "react-admin";

import OrderInternalStatusInput from "../../../components/manageOrder/orders/OrderInternalStatusInput";
import OrderStatusInput from "../../../components/manageOrder/orders/OrderStatusInput";

const OfflineOrderFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            alwaysOn
            resettable
        />
        <DateInput source="_o_created" label="Order Date" variant="outlined" />
        <OrderStatusInput
            source="_status"
            variant="outlined"
            choices={[{ id: "damaged", name: "Damaged" }]}
        />
        <OrderInternalStatusInput
            source="_i_status"
            label="Internal Status"
            variant="outlined"
            choices={[
                { id: "later", name: "Later" },
                { id: "never", name: "Never" },
            ]}
        />
    </Filter>
);

export default OfflineOrderFilter;
