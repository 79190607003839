import { Button as MuiButton, Paper, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    SimpleForm,
    TextField,
    Toolbar,
    ToolbarProps,
} from "react-admin";

import DeleteBulkActionButton from "../../../components/DeleteBulkActionButton";
import GenerateOrderDialog from "../../../components/manageStock/inventories/GenerateOrderDialog";
import GenerateOrderSaveDialog from "../../../components/manageStock/inventories/GenerateOrderSaveDialog";
import GenerateOrderSheet from "../../../components/manageStock/inventories/GenerateOrderSheet";
import { useDocumentTitle, useExport } from "../../../hooks";
import PurchaseRequestListActions from "../../manageStock/inventories/InventoryListActions";
import PurchaseRequestFilter from "./PurchaseRequestFilter";

const PurchaseRequestList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Purchase Request List");
    const exporter = useExport(rest);
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [isGenerateOrderDialogOpen, setIsGenerateOrderDialogOpen] =
        useState(false);
    const [isGenerateOrder, setIsGenerateOrder] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [requestDays, setRequestDays] = useState("");
    const [requestCount, setRequestCount] = useState("");
    const [isIncludeLaterMedicine, setIsIncludeLaterMedicine] = useState(0);
    const [medicines, setMedicines] = useState([]);

    interface ISaveToolbarProps extends ToolbarProps {
        setOpen: (value: boolean) => void;
    }

    const SaveToolbar = ({ setOpen, ...rest }: ISaveToolbarProps) => {
        const classes = useStyles();

        return (
            <Toolbar
                {...rest}
                className={classes.toolbarPosition}
                disableGutters
            >
                <MuiButton
                    color="primary"
                    onClick={() => setIsGenerateOrder(false)}
                >
                    Cancel
                </MuiButton>
                <MuiButton
                    color="primary"
                    variant="contained"
                    onClick={() => setOpen(true)}
                >
                    Save
                </MuiButton>
            </Toolbar>
        );
    };

    return (
        <>
            {!isGenerateOrder ? (
                <List
                    {...rest}
                    title="List of Purchase Request"
                    filters={<PurchaseRequestFilter children={""} />}
                    perPage={25}
                    sort={{ field: "tp_created", order: "DESC" }}
                    actions={
                        <PurchaseRequestListActions
                            setIsGenerateOrderDialogOpen={
                                setIsGenerateOrderDialogOpen
                            }
                        />
                    }
                    bulkActionButtons={
                        <DeleteBulkActionButton
                            hasPermission={permissions?.includes(
                                "purchaseRequestDelete"
                            )}
                        />
                    }
                    exporter={exporter}
                >
                    <Datagrid>
                        <TextField
                            source="tp_created"
                            label="Request Created"
                        />
                        <FunctionField
                            label="Duration"
                            sortBy="tp_created"
                            render={({ tp_created }: any) => {
                                if (tp_created === "0000-00-00 00:00:00")
                                    return "";

                                const diff = DateTime.local()
                                    .diff(DateTime.fromSQL(tp_created), [
                                        "days",
                                        "hours",
                                        "minutes",
                                    ])
                                    .toObject();

                                return (
                                    <span
                                        style={{
                                            color:
                                                diff.days >= 2
                                                    ? "#FF0000"
                                                    : "#000000",
                                        }}
                                    >
                                        {diff.days}d-{diff.hours}h
                                    </span>
                                );
                            }}
                        />
                        <TextField
                            source="purchaseAssigned"
                            label="Purchase Assigned"
                        />
                        <TextField source="m_name" label="Name" />
                        <TextField source="m_form" label="Form" />
                        <TextField source="m_strength" label="Strength" />
                        <TextField
                            source="m_company"
                            label="Company"
                            sortable={false}
                        />
                        <TextField source="qty_text" label="Qty Text" />
                    </Datagrid>
                </List>
            ) : (
                <Paper
                    style={{ marginTop: 25 }}
                    classes={{
                        root: classes.root,
                    }}
                >
                    <SimpleForm toolbar={<SaveToolbar setOpen={setOpen} />}>
                        <GenerateOrderSheet
                            companyId={companyId}
                            requestDays={requestDays}
                            requestCount={requestCount}
                            isIncludeLaterMedicine={isIncludeLaterMedicine}
                            medicines={medicines}
                            setMedicines={setMedicines}
                        />
                        <GenerateOrderSaveDialog
                            open={open}
                            setOpen={setOpen}
                            medicines={medicines}
                            setIsGenerateOrder={setIsGenerateOrder}
                        />
                    </SimpleForm>
                </Paper>
            )}
            <SimpleForm toolbar={false}>
                <GenerateOrderDialog
                    open={isGenerateOrderDialogOpen}
                    setOpen={setIsGenerateOrderDialogOpen}
                    setCompanyId={setCompanyId}
                    setRequestDays={setRequestDays}
                    setRequestCount={setRequestCount}
                    setIsIncludeLaterMedicine={setIsIncludeLaterMedicine}
                    setIsGenerateOrder={setIsGenerateOrder}
                />
            </SimpleForm>
        </>
    );
};

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    toolbarPosition: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "15px",
    },
});

export default PurchaseRequestList;
