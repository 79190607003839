import { FC } from "react";
import {
    DateField,
    FunctionField,
    ReferenceField,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const OrderShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Order Show");

    return (
        <Show {...props}>
            <ColumnShowLayout>
                <TextField source="o_id" label="Id" />
                <TextField source="u_name" label="User Name" />
                <TextField source="u_mobile" label="User Mobile" />
                <TextField source="o_subtotal" label="Subtotal" />
                <TextField source="o_total" label="Total" />
                <DateField source="o_created" label="Date" />
                <TextField source="o_status" label="Status" />
                <TextField source="o_i_status" label="Internal Status" />
                <TextField source="o_address" label="Address" />
                <TextField source="d_code" label="Discount Code" />
                <TextField source="o_addition" label="Addition" />
                <TextField source="o_deduction" label="Deduction" />
                <TextField source="o_additional_info" label="Additional Info" />
                <DateField source="o_updated" label="Updated" />
                <TextField source="o_payment_method" label="Payment Method" />
                <TextField source="o_de_id" label="Delivery Id" />
                <TextField source="o_warehouse_id" label="Warehouse Id" />
                <TextField source="o_note" label="Note" />
                <TextField source="paymentUrl" label="Payment Url" />
                <ReferenceField
                    source="oe_cancel_request_by"
                    label="Cancel Request By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <DateField
                    source="oe_cancel_request_at"
                    label="Cancel Request At"
                />
                <FunctionField
                    label="Payment Response"
                    render={({ paymentResponse }) => {
                        if (!paymentResponse) return "";

                        return JSON.stringify(paymentResponse, undefined, 4);
                    }}
                />
            </ColumnShowLayout>
        </Show>
    );
};

export default OrderShow;
