import { FC } from "react";
import {
    BulkActionProps,
    BulkDeleteButton,
    Button,
    useListContext,
    usePermissions,
} from "react-admin";

interface IInventoryListBulkActionButtonsProps extends BulkActionProps {
    deletePermissionKey: "inventoryDelete" | "laterMedicineDelete";
    setIsAssignTo: (value: boolean) => void;
    setSelectedItems: (value: object[]) => void;
    [key: string]: any;
}

const InventoryListBulkActionButtons: FC<
    IInventoryListBulkActionButtonsProps
> = ({
    selectedIds,
    deletePermissionKey,
    setIsAssignTo,
    setSelectedItems,
    ...rest
}) => {
    const { permissions } = usePermissions();
    const { data } = useListContext();

    const handleAssignTo = () => {
        setIsAssignTo(true);

        const selectedItems =
            !!Object.values(data)?.length &&
            Object.values(data).filter((item) => selectedIds.includes(item.id));

        setSelectedItems(selectedItems);
    };

    return (
        <>
            <Button label="Assign to" onClick={handleAssignTo} />
            {permissions?.includes(deletePermissionKey) && (
                <BulkDeleteButton {...rest} />
            )}
        </>
    );
};

export default InventoryListBulkActionButtons;
