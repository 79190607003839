import { FC } from "react";
import {
    ImageField,
    ReferenceField,
    RichTextField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const JobShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Job Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout simpleShowLayout={false}>
                    <TextField source="j_id" label="Id" />
                    <TextField source="j_created" label="Created" />
                    <ReferenceField
                        source="j_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <TextField source="j_title" label="Title" />
                    <TextField source="j_designation" label="Designation" />
                    <TextField source="j_department" label="Department" />
                    <TextField source="j_status" label="Status" />
                    <TextField source="j_location" label="Location" />
                    <TextField source="j_type" label="Type" />
                    <TextField source="j_a_count" label="Application Count" />
                    <TextField source="j_updated" label="Last Updated" />
                    <ReferenceField
                        source="j_updated_by"
                        label="Last Updated By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <ImageField
                        source="attachedFiles"
                        src="src"
                        title="title"
                        label="Banner"
                    />
                </ColumnShowLayout>
                <RichTextField source="j_description" label="Description" />
            </SimpleShowLayout>
        </Show>
    );
};

export default JobShow;
