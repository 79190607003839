import { SelectInput } from "react-admin";

const MedicineCategoryInput = (props) => (
    <SelectInput
        label="Category"
        choices={[
            { id: 11, name: "Prescription Medicines" },
            { id: 12, name: "OTC Medicines" },
            { id: 13, name: "Covid-19 Special" },
            { id: 14, name: "Devices" },
            { id: 15, name: "Diabetic Care" },
            { id: 16, name: "Supplements and Vitamins" },
            { id: 17, name: "Personal care" },
            { id: 18, name: "Herbal and Homeopathy" },
            { id: 19, name: "Baby & Mom care" },
            { id: 20, name: "Women Care" },
            { id: 21, name: "Sexual Wellness" },
            { id: 22, name: "Nutrition and drinks" },
            { id: 23, name: "Veterinary" },
            { id: 24, name: "Controlled Drugs" },
        ]}
        {...props}
    />
);

export default MedicineCategoryInput;
