import { FC } from "react";
import { Show, ShowProps, SimpleShowLayout, TextField } from "react-admin";

import { useDocumentTitle } from "../../../hooks";

const CurrencyRateShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Currency Rate Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="cr_id" label="Id" />
                <TextField source="cr_currency" label="Currency" />
                <TextField source="cr_rate" label="Rate" />
                <TextField source="cr_date" label="Date" />
            </SimpleShowLayout>
        </Show>
    );
};

export default CurrencyRateShow;
