import { FC } from "react";
import { Pagination, PaginationProps } from "react-admin";

import BellowList from "../../../components/BellowList";

const InventoryPagination: FC<PaginationProps> = (props) => (
    <>
        <Pagination {...props} />
        <BellowList {...props} context="Inventory" />
    </>
);

export default InventoryPagination;
