import { FC } from "react";
import {
    Datagrid,
    List,
    ListProps,
    NumberField,
    ReferenceField,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import EmployeeLoanFilter from "./EmployeeLoanFilter";

const EmployeeLoanList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Employee Loan List");

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Loan"
            perPage={25}
            filters={<EmployeeLoanFilter children={""} />}
            sort={{ field: "el_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="show">
                <TextField source="el_id" label="Id" />
                <ReferenceField
                    source="el_employee_id"
                    label="Employee"
                    reference="v1/employee"
                    link="show"
                    sortBy="el_employee_id"
                >
                    <TextField source="e_name" />
                </ReferenceField>
                <NumberField source="el_amount" label="Amount" />
                <NumberField source="el_due" label="Due" />
                <TextField source="el_installment" label="Installment" />
                <TextField source="el_reason" label="Reason" />
                <TextField
                    source="el_adjustment_type"
                    label="Adjustment Type"
                />
                <TextField source="el_created_at" label="Created At" />
                <ReferenceField
                    source="el_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                    sortBy="el_created_by"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default EmployeeLoanList;
