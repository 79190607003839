import { Box, Grid, Typography } from "@material-ui/core";
import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { FC, memo, useEffect, useState } from "react";
import {
    Create,
    CreateProps,
    ImageField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    TransformData,
    required,
} from "react-admin";
import { FormSpy } from "react-final-form";

import AddressTypeInput from "../../../components/AddressTypeInput";
import AreaInput from "../../../components/manageOrder/orders/AreaInput";
import DeliveryOptionInput from "../../../components/manageOrder/orders/DeliveryOptionInput";
import DistrictInput from "../../../components/manageOrder/orders/DistrictInput";
import MedicineCalculate from "../../../components/manageOrder/orders/MedicineCalculate";
import MedicineForm from "../../../components/manageOrder/orders/MedicineForm";
import PopulateUserInfo from "../../../components/manageOrder/orders/PopulateUserInfo";
import UserLocationAutocompleteInput from "../../../components/manageOrder/orders/UserLocationAutocompleteInput";
import MedicineTable from "../../../components/manageOrder/orders/medicineTable";
import { useDocumentTitle } from "../../../hooks";
import { Status } from "../../../utils/enums";
import { isEmpty, isJSONParsable, logger } from "../../../utils/helpers";
import { httpClient } from "../../../utils/http";

const OrderCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Order Create");

    const [allItems, setAllItems] = useState([]);
    const [locations, setLocations] = useState(null);
    const [formValues, setFormValues] = useState<any>({});
    const [isUserChecked, setIsUserChecked] = useState(false);
    const [hasLocationField, setHasLocationField] = useState(true);

    useEffect(() => {
        const locationsFromStroage = sessionStorage.getItem("locations");

        if (locationsFromStroage) {
            setLocations(
                isJSONParsable(locationsFromStroage)
                    ? JSON.parse(locationsFromStroage)
                    : {}
            );
        } else {
            httpClient("/v1/allLocations/", {}, true)
                .then(({ json }: any) => {
                    if (json.status === Status.SUCCESS) {
                        setLocations(json.data);
                        sessionStorage.setItem(
                            "locations",
                            JSON.stringify(json.data)
                        );
                    }
                })
                .catch((err) => logger(err));
        }
    }, []);

    const toChoiceDivisions = (items: string[]) =>
        items.map((item) => ({
            id: item,
            name: item,
        }));

    const transform: TransformData = ({ user, o_ul_id, ...rest }) => {
        const payload = { ...rest };
        !!o_ul_id && (payload.o_ul_id = o_ul_id);
        return payload;
    };

    return (
        <Create {...props} transform={transform}>
            <SimpleForm submitOnEnter={false} redirect="list">
                <PopulateUserInfo
                    isUserChecked={isUserChecked}
                    setIsUserChecked={setIsUserChecked}
                    setHasLocationField={setHasLocationField}
                />
                <>
                    <Grid container spacing={1}>
                        {isUserChecked && (
                            <>
                                <Grid item xl={2}>
                                    <TextInput
                                        source="u_name"
                                        label="Name"
                                        variant="outlined"
                                        style={{ width: "256px" }}
                                        validate={[required()]}
                                    />
                                </Grid>
                                <Grid item xl={2}>
                                    <Box mt={1}>
                                        <Typography>
                                            Total Order:{" "}
                                            {formValues.user?.u_o_count || 0}
                                        </Typography>
                                        <Typography>
                                            Total Delivered Order:{" "}
                                            {formValues.user?.u_d_count || 0}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        {!isEmpty(formValues.user) && (
                            <Grid
                                item
                                md={8}
                                style={{
                                    display: !hasLocationField
                                        ? "none"
                                        : "block",
                                }}
                            >
                                <ReferenceInput
                                    source="o_ul_id"
                                    label="Location"
                                    variant="outlined"
                                    reference="v1/userLocations"
                                    filter={{
                                        _orderBy: "ul_default",
                                        u_id: formValues.user?.u_id,
                                    }}
                                    filterToQuery={(searchText) => ({
                                        _search: searchText,
                                    })}
                                    emptyText="Add New Location"
                                    allowEmpty
                                    fullWidth
                                >
                                    <UserLocationAutocompleteInput
                                        matchSuggestion={() => true}
                                        optionValue="ul_id"
                                        helperText={false}
                                        setHasLocationField={
                                            setHasLocationField
                                        }
                                        resettable
                                    />
                                </ReferenceInput>
                            </Grid>
                        )}
                    </Grid>
                </>
                {(isEmpty(formValues.user) || !formValues.o_ul_id) &&
                    isUserChecked && (
                        <>
                            <Grid container spacing={1}>
                                <Grid item lg={6}>
                                    <TextInput
                                        source="s_address.name"
                                        label="Shipping Name"
                                        variant="outlined"
                                        validate={[required()]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <TextInput
                                        source="s_address.mobile"
                                        label="Shipping Mobile"
                                        variant="outlined"
                                        validate={[required()]}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item lg={2}>
                                    <SelectInput
                                        source="s_address.division"
                                        label="Shipping Division"
                                        variant="outlined"
                                        choices={
                                            !!locations
                                                ? toChoiceDivisions(
                                                      Object.keys(locations)
                                                  )
                                                : []
                                        }
                                        validate={[required()]}
                                        allowEmpty
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <DistrictInput
                                        source="s_address.district"
                                        label="Shipping City"
                                        variant="outlined"
                                        validate={[required()]}
                                        locations={locations}
                                        setLocations={setLocations}
                                        allowEmpty
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={3}>
                                    <AreaInput
                                        source="s_address.area"
                                        label="Shipping Area"
                                        variant="outlined"
                                        validate={[required()]}
                                        locations={locations}
                                        setLocations={setLocations}
                                        allowEmpty
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={3}>
                                    <TextInput
                                        source="s_address.homeAddress"
                                        label="Shipping Home Address"
                                        variant="outlined"
                                        validate={[required()]}
                                        minRows={2}
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <AddressTypeInput
                                        source="s_address.addressType"
                                        variant="outlined"
                                        validate={[required()]}
                                        initialValue="Home"
                                        allowEmpty
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                <>
                    <Grid container spacing={1}>
                        <Grid item lg={2}>
                            <DeliveryOptionInput
                                source="o_de_option"
                                variant="outlined"
                                initialValue="regular"
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={2}>
                            <TextInput
                                source="d_code"
                                variant="outlined"
                                label="Discount Code"
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={3}>
                            <TextInput
                                source="o_additional_info"
                                label="Additional Info"
                                variant="outlined"
                                minRows={2}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={3}>
                            <TextInput
                                source="o_i_note"
                                label="Internal Note"
                                variant="outlined"
                                minRows={2}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={2}>
                            <SelectInput
                                source="subscription_freq"
                                label="Subscription Frequency"
                                variant="outlined"
                                choices={[{ id: "monthly", name: "Monthly" }]}
                                allowEmpty
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </>
                <MedicineForm
                    createForm
                    allItems={allItems}
                    setAllItems={setAllItems}
                />
                <MedicineTable allItems={allItems} setAllItems={setAllItems} />
                <MedicineCalculate
                    formValues={formValues}
                    allItems={allItems}
                />
                <Box mt={2} />
                <MovableImageInput
                    source="attachedFiles"
                    label="Prescription Pictures"
                    accept="image/*"
                    maxSize={5000000}
                    multiple
                >
                    <ImageField source="src" title="title" />
                </MovableImageInput>
                <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) =>
                        // Fix bad setState() call inside `FormSpy` error using setTimeout
                        setTimeout(() => {
                            setFormValues(values);
                        }, 0)
                    }
                />
            </SimpleForm>
        </Create>
    );
};

export default memo(OrderCreate);
