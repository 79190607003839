import { makeStyles, Paper, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { SimpleForm, Title } from "react-admin";
import { useLocation, useParams } from "react-router-dom";

import CreatePrescription from "../../../components/manageOrder/orders/prescription/CreatePrescription";
import Preview from "../../../components/manageOrder/orders/prescription/Preview";
import { useDocumentTitle, useRequest } from "../../../hooks";

const GeneratePrescription = () => {
    useDocumentTitle("Arogga | Generate Prescription");

    const classes = useStyles();
    const { search } = useLocation();
    const { orderId } = useParams() as any;
    const [prescription, setPrescription] = useState<any>(null);
    const params = new URLSearchParams(search).get("m")?.split(",") || [];
    const mode = new URLSearchParams(search).get("dp_id") || false;
    const [showPreview, setShowPreview] = useState(false);
    const [completeFunction, setCompleteFunction] = useState<any>(null);

    const { data, isLoading } = useRequest(
        `/v1/orders/${orderId}`,
        {},
        { isPreFetching: true }
    );

    const {
        data: prescriptionData,
        isLoading: dpLoading,
        refetch: dpRefetch,
    } = useRequest(
        `/v1/digitalPrescription/${mode}`,
        {},
        { isWarningNotify: false }
    );

    useEffect(() => {
        if (mode) dpRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const onComplete = (funciton) => {
        setShowPreview(true);
        setCompleteFunction(funciton);
    };

    return (
        <div className={classes.root}>
            <Title title="Generate Prescription" />
            <Typography variant="h5">Doctor’s Medical Prescription</Typography>
            <Paper style={{ marginTop: 10 }}>
                <SimpleForm toolbar={null}>
                    <CreatePrescription
                        loading={isLoading || dpLoading}
                        orderData={data}
                        prescriptionData={prescriptionData}
                        setPrescription={setPrescription}
                        existingMedicines={params}
                        edit={mode ? true : false}
                        onComplete={onComplete}
                    />
                    <Preview
                        data={prescription}
                        open={showPreview}
                        setOpen={setShowPreview}
                        onComplete={completeFunction}
                    />
                </SimpleForm>
            </Paper>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        marginTop: 20,
    },
});

export default GeneratePrescription;
